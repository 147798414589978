import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Venue, ViolationError } from '@sweetspot/shared/types'
import { queryCourses } from '@sweetspot/sweetspot-js/features/courses/services/api-platform'
import { CONSTANTS } from '@sweetspot/club-portal-legacy/store/constants'
import { isViolationError } from '@sweetspot/shared/util/validators'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'

import { useManageCourseContext } from '../ManageCourseProvider'
import { _addGolfCourseToGolfClub } from '@sweetspot/club-portal-legacy/services/golfClubApi'
import { CreateCoursePayload } from '../types'

const useCreateCourseMutation = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const history = useHistory()
  const currentClubId = useSelector((state: RootState) => state.golfClub.selectedId)
  const { toggleCreateCourseSideBar, refetchCourses } = useManageCourseContext()

  const createCourseMutation = useMutation(
    (data: CreateCoursePayload) => _addGolfCourseToGolfClub(currentClubId, data),
    {
      onSuccess: async (response) => {
        addToast(t('toast.courseCreated', { type: t('words.course_one') }), {
          appearance: 'success',
        })
        toggleCreateCourseSideBar()
        const path = history.location.pathname
        history.push(`${path}#${response?.id}`)
        // the corresponding course needs a bit time to be created after the course is created
        // Immediately refetch courses to update the list
        refetchCourses()
        let attempts = 0
        const maxAttempts = 5

        const pollForCourse = async () => {
          try {
            const courses: Venue[] = await queryCourses({ uuid: response.uuid })
            if (courses?.length) {
              dispatch({
                type: CONSTANTS.GOLF_COURSE.CREATE,
                payload: courses[0],
              })
              return // Success, exit polling
            }
            // If no courses found and we haven't exceeded max attempts, try again
            attempts++
            if (attempts < maxAttempts) {
              setTimeout(pollForCourse, 2000)
            }
          } catch (error) {
            // Still refetch to ensure list is updated even if polling fails
            refetchCourses()
          }
        }

        pollForCourse()
      },
      onError: (error: { error: string } | ViolationError) => {
        if (isViolationError(error)) {
          addToast(t('toast.defaultError'), { appearance: 'error' })
        } else {
          if (error?.error?.includes('name: Name should be unique per golf club')) {
            addToast(t('sentences.academyNameAlreadyExists'), { appearance: 'error' })
          } else {
            addToast(t('toast.defaultError'), { appearance: 'error' })
          }
        }
      },
    }
  )

  return createCourseMutation
}

export default useCreateCourseMutation
