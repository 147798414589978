import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { getMemberships } from '@sweetspot/club-portal-legacy/store/actions'
import MembershipWizardModal from '@sweetspot/club-portal-legacy/modals/MembershipWizardModal'
import { Button } from '@sweetspot/scramble-ds/atoms'
import {
  DataTable,
  DataTableFilters,
  Input,
  InputBase,
  InputContainer,
  InputLeadingContainer,
  Section,
  SectionContent,
  SectionHeader,
  SectionTitle,
  TableSortItem,
} from '@sweetspot/scramble-ds'
import { cn } from '@sweetspot/scramble-ds/utils'
import { useTranslation } from 'react-i18next'
import { columns } from './columns'
import { useDebounce } from '@sweetspot/sweetspot-js/common/hooks/useDebounce'
import { MembershipItem, RootState } from '@sweetspot/club-portal-legacy/store/types'
import { FILTER_QUERIES, filters } from './filters'
import { FilterType } from './types'
import { updateMembershipPriority } from '@sweetspot/sweetspot-js/features/memberships/services/api-platform'
import { useMutation } from 'react-query'
import { useToasts } from 'react-toast-notifications'

export const MembershipsTable = () => {
  const [activeFilters, setActiveFilters] = useState<FilterType[]>(['ACTIVE'])
  const [sortItems, setSortItems] = useState<TableSortItem[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [searchString, setSearchString] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [localMemberships, setLocalMemberships] = useState<MembershipItem[]>([])

  const clubId = useSelector((state: RootState) => state?.golfClub?.selectedId)

  const currentClub = useSelector((state: RootState) => {
    return state?.golfClub?.list.find((club) => club.id === state?.golfClub?.selectedId)
  })

  const hasCdhNumber = !!currentClub?.cdh_id

  const {
    list: memberships,
    itemsPerPage,
    totalItems,
  } = useSelector((state: RootState) => state.membership)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const { path } = useRouteMatch()
  const location = useLocation()
  const { addToast } = useToasts()

  const shouldReload = (location.state as { shouldReload?: boolean })?.shouldReload || false

  const debouncedSearchString = useDebounce(searchString, 500)

  const updateMembershipPriorityMutation = useMutation(
    ({
      membershipId,
      payload,
    }: {
      membershipId: string | number
      payload: { put_below?: string; put_above?: string }
    }) => updateMembershipPriority(membershipId, payload),
    {
      onError: () => {
        addToast(t('Could not be moved'), { appearance: 'error' })
      },
    }
  )

  const getMembershipList = useCallback(
    async (searchQuery = '', currentPage = 0, pageSize = 20) => {
      let query = `?page=${currentPage + 1}&limit=${pageSize}&club.id=${clubId}`

      if (searchQuery) {
        query += `&name=${searchQuery}`
      }

      query += getFilterQuery(activeFilters)

      if (sortItems.length > 0) {
        sortItems.forEach(({ name, type }) => {
          if (type) {
            query += `&order[${name}.sort]=${type}`
          }
        })
      } else {
        query += `&order[priority]=desc`
      }

      try {
        await dispatch(getMemberships(query))
      } finally {
        setIsLoading(false)
      }
    },
    [dispatch, clubId, activeFilters, sortItems]
  )

  useEffect(() => {
    if (shouldReload) {
      getMembershipList()
    }
  }, [shouldReload, getMembershipList])

  useEffect(() => {
    setLocalMemberships(memberships)
  }, [memberships])

  useEffect(() => {
    getMembershipList(debouncedSearchString, currentPage, pageSize)
  }, [
    currentPage,
    debouncedSearchString,
    activeFilters,
    pageSize,
    getMembershipList,
    itemsPerPage,
    sortItems,
  ])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value)
    setCurrentPage(0)
    setIsLoading(true)
  }

  const getFilterQuery = (filters: FilterType[]) => {
    if (!filters || filters.length === 0) {
      return ''
    }
    return filters.map((filter) => FILTER_QUERIES[filter] || '').join('')
  }

  const onFilterChange = (filter: FilterType) => {
    setCurrentPage(0)
    if (!filter) {
      setActiveFilters([])
      setSearchString('')
      getMembershipList('', 1)
      return
    }

    setActiveFilters((prevFilters) => {
      const updatedFilters = [...prevFilters]
      if (!updatedFilters.includes(filter as FilterType)) {
        updatedFilters.push(filter as FilterType)
      } else {
        updatedFilters.splice(updatedFilters.indexOf(filter as FilterType), 1)
      }
      return updatedFilters
    })
  }

  const createNewMembership = () => {
    history.push('/memberships/new')
  }

  const handleRowClick = (membershipId: string | number) => {
    history.push(`/memberships/${membershipId}`)
  }

  const onRowMoveHandler = ({
    activeId,
    overId,
  }: {
    activeId: string | number
    overId: string | number
  }) => {
    if (activeId && overId) {
      const activeIndex = localMemberships.findIndex((m) => m.id === activeId)
      const overIndex = localMemberships.findIndex((m) => m.id === overId)

      if (activeIndex === -1 || overIndex === -1) return

      const isMovingDown = activeIndex < overIndex

      const updatedMemberships = [...localMemberships]
      const [movedItem] = updatedMemberships.splice(activeIndex, 1)
      updatedMemberships.splice(overIndex, 0, movedItem)

      setLocalMemberships(updatedMemberships)

      const payload = {
        [isMovingDown ? 'put_below' : 'put_above']: `/api/memberships/${activeId}`,
      }

      updateMembershipPriorityMutation.mutate({
        membershipId: overId,
        payload,
      })
    }
  }

  return (
    <Section className="overflow-x-hidden">
      <SectionHeader>
        <SectionTitle>{t('membership.memberships')}</SectionTitle>
      </SectionHeader>
      <SectionContent>
        <DataTable
          columns={columns(t, hasCdhNumber, sortItems, setSortItems)}
          data={memberships}
          onRowClick={handleRowClick}
          enableRowSelection={false}
          onRowMove={onRowMoveHandler}
          sortItems={sortItems}
          inputSearch={() => (
            <div className="flex w-2/5 flex-row">
              <InputBase className="flex w-full flex-row gap-2 px-4 py-6">
                <InputContainer>
                  <InputLeadingContainer>
                    <i className="fa-regular fa-magnifying-glass" />
                  </InputLeadingContainer>
                  <Input
                    rounding="pill"
                    className="h-10 px-10"
                    placeholder={t('membership.search')}
                    value={searchString}
                    onChange={handleSearchChange}
                  />
                </InputContainer>
              </InputBase>
              <div className="flex w-[20px] items-center justify-center ">
                <i
                  className={cn(
                    'fa-regular fa-loader animate-[spin_3s_linear_infinite] text-xl opacity-0',
                    {
                      'opacity-100': isLoading,
                    }
                  )}
                />
              </div>
            </div>
          )}
          extraControls={(table) => (
            <>
              <Button
                size="small"
                variant="clear-dark"
                rounding="pill"
                onClick={createNewMembership}
                className="hover:!text-text-dark hover:bg-transparent hover:shadow-none focus:!outline-none"
              >
                <i className="fa-regular fa-square-plus text-lg" />
              </Button>
              <DataTableFilters
                table={table}
                filters={filters}
                onFilterChange={onFilterChange}
                activeFilters={activeFilters}
              />
            </>
          )}
          pagination={{
            currentPage,
            pageSize,
            totalRows: totalItems,
            onPageChange: (pageIndex: number) => setCurrentPage(pageIndex),
            onPageSizeChange: (size: number) => {
              setCurrentPage(0)
              setPageSize(size)
            },
          }}
        />
      </SectionContent>
      <Route path={`${path}/:membershipId`} component={MembershipWizardModal} />
    </Section>
  )
}
