import { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import {
  ListItem,
  ListItemLeading,
  ListItemMainContainer,
  ListItemMainContent,
  ListItemMainLabel,
} from '@sweetspot/scramble-ds'
import { Popover, Toggle } from '@sweetspot/scramble-ds/atoms'

import SimpleTabs from '@sweetspot/sweetspot-js/common/components/SimpleTabs'
import DiscardDialog from '@sweetspot/club-portal-legacy/Partials/Dialogs/DiscardDialog/DiscardDialog'
import { GolfCourse } from '@sweetspot/shared/types'
import DeleteCourseDialog from '@sweetspot/club-portal-legacy/Partials/Dialogs/DeleteCourseDialog/DeleteCourseDialog'
import ActivateCourseDialog from '@sweetspot/club-portal-legacy/Partials/Dialogs/ActivateCourseDialog/ActivateCourseDialog'

import { CourseTabs as Tabs } from '../types'
import GeneralTab from './GeneralTab/GeneralTab'
import BookingTab from './BookingTab/BookingTab'
import PaymentTab from './PaymentTab/PaymentTab'
import useUpdateCourse from '../hooks/useUpdateCourse'
import { useManageCourseContext } from '../ManageCourseProvider'
import useDeleteCourseMutation from '../hooks/useDeleteCourseMutation'

type CourseTabsProps = {
  currentCourse?: GolfCourse
  refetchCourses: () => void
}

const CourseTabs = ({ currentCourse, refetchCourses }: CourseTabsProps) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.GENERAL)
  const [isCourseActive, setIsCourseActive] = useState<boolean>(false)
  const [openActivateDialog, setOpenActivateDialog] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const history = useHistory()
  const { updateCourse } = useUpdateCourse({ currentCourse })
  const { discardDialogCallbacks, openDiscardDialog } = useManageCourseContext()
  const deleteCourseMutation = useDeleteCourseMutation()

  useEffect(() => {
    setIsCourseActive(currentCourse?.is_active ?? false)
  }, [currentCourse?.is_active])

  // set active tab based on hash
  useEffect(() => {
    const hash = (history.location.hash?.slice(1) as Tabs) || Tabs.GENERAL
    if (hash && Object.values(Tabs).includes(hash)) setActiveTab(hash)
  }, [history.location.hash])

  const activateDialogTexts = useMemo(
    () => ({
      title: isCourseActive
        ? t('settings.deactivateDialogTitle', {
            type: t('words.course_one').toLowerCase(),
          })
        : t('settings.activateDialogTitle', { type: t('words.course_one').toLowerCase() }),
      description: isCourseActive
        ? t('settings.course.deactivateDialogText')
        : t('settings.course.activateDialogText'),
    }),
    [t, isCourseActive]
  )

  const toggleActive = useCallback(() => {
    const isActive = !isCourseActive
    setOpenActivateDialog(false)
    setIsCourseActive(isActive)
    updateCourse({ isActive })
      .catch(() => {
        addToast(t(t('toast.defaultError')), { appearance: 'error' })
      })
      .finally(() => {
        refetchCourses()
      })
  }, [updateCourse, refetchCourses, isCourseActive, addToast, t])

  const onDelete = useCallback(async () => {
    if (currentCourse?.id) {
      deleteCourseMutation.mutateAsync(currentCourse.id).then(() => {
        setOpenDeleteDialog(false)
      })
    }
  }, [currentCourse?.id, deleteCourseMutation])

  const renderContent = useCallback(() => {
    if (activeTab === Tabs.GENERAL)
      return <GeneralTab currentCourse={currentCourse} refetchCourses={refetchCourses} />
    if (activeTab === Tabs.BOOKING)
      return <BookingTab currentCourse={currentCourse} refetchCourses={refetchCourses} />
    if (activeTab === Tabs.PAYMENT)
      return <PaymentTab currentCourse={currentCourse} refetchCourses={refetchCourses} />
    return null
  }, [activeTab, currentCourse, refetchCourses])

  return (
    <div className="flex h-full flex-1 flex-col gap-8">
      <DiscardDialog
        open={openDiscardDialog}
        onClose={() => discardDialogCallbacks?.onClose()}
        onDiscard={() => discardDialogCallbacks?.onDiscard()}
        onSave={() => discardDialogCallbacks?.onSave()}
      />
      <ActivateCourseDialog
        open={openActivateDialog}
        setOpen={setOpenActivateDialog}
        onSave={toggleActive}
        title={activateDialogTexts.title}
        description={activateDialogTexts.description}
      />
      <DeleteCourseDialog
        courseText={t('words.course_one').toLowerCase()}
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        onDelete={onDelete}
      />
      <div className="border-b pt-2">
        <div className="flex items-center justify-between px-6">
          <SimpleTabs
            className="h-8 !w-auto gap-0.5 pb-0 [&_span]:!hidden"
            labelClassName="w-[106px] after:!scale-x-100 !mb-[-1px]"
            activeLabelClassName="!font-bold !text-text-dark after:!border-[#171717]"
            inActiveLabelClassName="!font-medium !text-text-subtle after:!border-[#D1D5DB]"
            currentItem={activeTab}
            onClick={(tab) => history.push(`#${tab}`)}
            items={[
              { id: Tabs.GENERAL, label: t('words.general') },
              { id: Tabs.BOOKING, label: t('words.booking_one') },
              { id: Tabs.PAYMENT, label: t('words.payment_one') },
            ]}
          />

          <div className="flex w-fit items-center gap-4">
            <div className="pl-md flex min-w-fit items-center gap-2">
              <p className="text-content-sm text-text-dark">
                <span className="font-medium">
                  {isCourseActive
                    ? t('settings.courseActive', { type: t('words.course_one') })
                    : t('settings.courseInactive', { type: t('words.course_one') })}
                </span>
                {isCourseActive
                  ? ` (${t('settings.courseVisible').toLowerCase()})`
                  : ` (${t('settings.courseNotVisible').toLowerCase()})`}
              </p>
              <Toggle
                checked={isCourseActive}
                onCheckedChange={() => setOpenActivateDialog(true)}
              />
            </div>

            <Popover.Root open={isDropdownOpen}>
              <Popover.Trigger asChild>
                <div
                  className="border-border-stroke-clean cursor-pointer border-l px-4"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <i className="fa-regular fa-ellipsis" />
                </div>
              </Popover.Trigger>
              <Popover.Content
                align="end"
                className="w-fit p-2 drop-shadow"
                onInteractOutside={() => setIsDropdownOpen(false)}
              >
                <ListItem
                  className="text-text-dark w-full min-w-fit cursor-pointer gap-5 px-2 py-3 hover:bg-gray-50"
                  onClick={() => setOpenDeleteDialog(true)}
                >
                  <ListItemLeading className="px-0">
                    <i className="fa-regular fa-trash-can" />
                  </ListItemLeading>
                  <ListItemMainContainer>
                    <ListItemMainContent className="py-0">
                      <ListItemMainLabel className="h-auto min-h-6 font-bold">
                        {t('sentences.deleteCourse', {
                          type: t('words.course_one').toLowerCase(),
                        })}
                      </ListItemMainLabel>
                    </ListItemMainContent>
                  </ListItemMainContainer>
                </ListItem>

                <Popover.Arrow className="fill-white" width={16} height={10} />
              </Popover.Content>
            </Popover.Root>
          </div>
        </div>
      </div>
      <div className="px-6 pb-[88px]">{renderContent()}</div>
    </div>
  )
}

export default CourseTabs
