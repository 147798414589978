import { useTranslation } from 'react-i18next'

import { Button } from '@sweetspot/scramble-ds/atoms'

import { useManageAcademySpacesContext } from './ManageAcademySpacesProvider'
import { SpaceSideBarMode } from './types'

const Header = () => {
  const { t } = useTranslation()

  const { selectedAcademy, toggleSpaceSideBar } = useManageAcademySpacesContext()

  return (
    <div className={'flex flex-row items-center border-b-[1px] px-6 py-[14px]'}>
      <p className={'flex-1 text-lg font-bold'}>
        {t('words.settings')}: {t('words.space_other')}
      </p>
      <Button
        variant={'clear-dark'}
        size={'small'}
        onClick={() => toggleSpaceSideBar(SpaceSideBarMode.CREATE)}
        disabled={!selectedAcademy}
        className={'py-sm px-lg border-stroke-stone gap-2 text-sm font-medium'}
      >
        <i className="fa-regular fa-plus" />
        {t('words.new')}
      </Button>
    </div>
  )
}

export default Header
