import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { ReactComponent as AddIcon } from '@sweetspot/sweetspot-js/assets/svgs/plus-icon-2.svg'
import { ReactComponent as GreenCheck } from '@sweetspot/sweetspot-js/assets/svgs/green-check.svg'

import ButtonOld from '@sweetspot/sweetspot-js/common/components/Button'

import styles from './styles.module.scss'
import { PaymentCreateSheet } from '@sweetspot/club-portal/feature/payment-links'
import { AMPLITUDE_EVENTS } from '@sweetspot/shared/util/constants'
import { track } from '@amplitude/analytics-browser'
import { AddPaymentLinkButtonProps } from './types'
import { ReactNode } from 'react'
import { Tooltip } from '@sweetspot/scramble-ds/atoms'
import { calculateDaysDifferenceBetweenStartAndExpiryDate } from '../../utils'

export const AddPaymentLinkButton = ({
  className,
  loading,
  canEdit,
  disabled,
  booking,
  course,
  handleUpdateBooking,
}: AddPaymentLinkButtonProps) => {
  const { t } = useTranslation()

  const { daysDifference } = calculateDaysDifferenceBetweenStartAndExpiryDate(
    booking?.booking?.start_time,
    booking?.course?.timezone
  )

  const startTimeExceedsLimit = daysDifference >= 70

  const renderPaymentLinkButton = (children: ReactNode) => {
    if (!disabled && daysDifference < 70) {
      return (
        <PaymentCreateSheet
          booking={booking}
          handleUpdateBooking={handleUpdateBooking}
          course={course}
        >
          {children}
        </PaymentCreateSheet>
      )
    } else if (startTimeExceedsLimit) {
      return (
        <Tooltip.Provider>
          <Tooltip.Root delayDuration={0}>
            <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
            <Tooltip.Content sideOffset={0}>
              <p>{t('paymentLinks.70daysExceeds')}</p>
              <Tooltip.Arrow />
            </Tooltip.Content>
          </Tooltip.Root>
        </Tooltip.Provider>
      )
    }
    return children
  }

  const hasPaymentLinkBeenIssued = booking?.payments?.some((payment) => payment.payment_link)

  const children = (
    <div className={cx(styles.container, className)}>
      <ButtonOld
        className={styles.itemWrapper}
        size="default"
        theme="gray"
        disabled={!canEdit || disabled || startTimeExceedsLimit}
        loading={loading}
        loaderStyle="pulse"
        onClick={() => {
          track(AMPLITUDE_EVENTS.PAYMENT_LINKS.BUTTON_IN_BOOKING_WINDOW_TAPPED)
        }}
      >
        {hasPaymentLinkBeenIssued ? (
          <GreenCheck className={cx(styles.icon, styles.addIcon)} />
        ) : (
          <AddIcon className={cx(styles.icon, styles.addIcon)} />
        )}

        {t('paymentLinks.singular')}
      </ButtonOld>
    </div>
  )

  return renderPaymentLinkButton(children)
}
