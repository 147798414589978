import { Control, Controller, FieldError } from 'react-hook-form'
import {
  InputSelect,
  InputBase,
  InputSelectTrigger,
  InputContainer,
  InputSelectStatefulInput,
  InputSelectContent,
  InputSelectItem,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  InputLabelContainer,
  InputSelectContentView,
  InputSelectTrailingContainer,
} from '@sweetspot/scramble-ds'

interface SelectFieldProps {
  name: string
  label: string
  control: Control<any>
  options: { id: string; name: string }[]
  required: boolean
  error: FieldError | undefined
  disabled?: boolean
}

export const SelectField = ({
  name,
  label,
  control,
  options,
  required,
  error,
  disabled,
}: SelectFieldProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <InputSelect onValueChange={(value) => field.onChange(value)} value={field.value}>
        <InputBase required={required} error={!!error} disabled={disabled}>
          <InputLabelContainer>{label}</InputLabelContainer>
          <InputSelectTrigger asChild disabled={disabled}>
            <InputContainer>
              <InputSelectStatefulInput
                value={options.find((option) => option.id === field.value)?.name || ''}
                className="mt-1"
              />
              <InputSelectTrailingContainer />
            </InputContainer>
          </InputSelectTrigger>
        </InputBase>
        <InputSelectContent className="max-h-56">
          <InputSelectContentView>
            {options.map((option) => (
              <InputSelectItem key={option.id} value={option.id}>
                <ListItem className="ml-2 h-full justify-center gap-2">
                  <ListItemMainContent className="justify-center py-1">
                    <ListItemParagraph className="text-content-base">
                      {option.name}
                    </ListItemParagraph>
                  </ListItemMainContent>
                </ListItem>
              </InputSelectItem>
            ))}
          </InputSelectContentView>
        </InputSelectContent>
      </InputSelect>
    )}
  />
)
