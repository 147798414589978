import { z } from 'zod'
import { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'

export const StoreSchema = z.object({
  store_code: z.string().min(5, { message: 'settings.billing.storeCodeMinLength' }),
  shopper_statement: z.string().min(5, { message: 'settings.billing.shopperStatementMinLength' }),
  phone_number: z.string().refine(
    (value) => {
      if (isPossiblePhoneNumber(value) && isValidPhoneNumber(value)) {
        const phoneNumber = parsePhoneNumber(value, 'SE')
        return phoneNumber.formatInternational()
      }
      return false
    },
    {
      message: `settings.billing.invalidCountryCode`,
    }
  ),
  address_line1: z.string().min(1, { message: 'settings.billing.addressLine1Required' }),
  address_line2: z.string().optional(),
  address_line3: z.string().optional(),
  address_city: z.string().min(1, { message: 'settings.billing.cityRequired' }),
  address_country: z.string().min(1, { message: 'settings.billing.countryRequired' }),
  address_postal_code: z.string().min(1, { message: 'settings.billing.postalCodeRequired' }),
  merchant_account_type: z
    .string()
    .min(1, { message: 'settings.billing.merchantAccountTypeRequired' }),
})
