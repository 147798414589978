import { useCallback, useEffect, useMemo, useState } from 'react'
import { decimalPercentToNumber, to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { priceToLocal } from '@sweetspot/shared/util/functions'
import {
  removeCouponFromOrder,
  removePartnershipFromBooking,
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useQuery, useQueryClient } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'

import styles from './styles.module.scss'
import cx from 'classnames'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { ReactComponent as VoucherIcon } from '@sweetspot/sweetspot-js/assets/svgs/voucher.svg'
import { ReactComponent as PartnershipIcon } from '@sweetspot/sweetspot-js/assets/svgs/shake-hands-default.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleMinus } from '@fortawesome/pro-solid-svg-icons'
import { queryPromotionCoupons } from '@sweetspot/sweetspot-js/features/players/services/api-platform'
import { useExtraPlayerContext } from '@sweetspot/sweetspot-js/features/bookings/functions/extraPlayerProvider'

const AppliedActionList = ({ className, orderBooking, orderBookingRefetch, paid }) => {
  const [assignedVouchers, setAssignedVouchers] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedVoucherId, setSelectedVoucherId] = useState(null)
  const extraPlayerContext = useExtraPlayerContext()

  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const customer = useMemo(() => {
    return orderBooking?.customer || null
  }, [orderBooking])

  const toLocal = (price) =>
    typeof price === 'number' ? priceToLocal(price, orderBooking?.currency_code, true) : '-'

  const { data: assignedPromotionCoupons, isFetching: isQueryAssignedVouchersFetching } = useQuery(
    ['assignedPromotionCoupons', orderBooking],
    () => queryPromotionCoupons({ 'orders.uuid': orderBooking?.uuid }),
    {
      enabled: !!orderBooking?.uuid,
    }
  )

  /**
   * @returns Promotion coupons array with discount prop or false of there are no promotion coupons
   */
  const getPercentageOfVouchers = useCallback(() => {
    if (
      !assignedPromotionCoupons ||
      !assignedPromotionCoupons?.length ||
      isQueryAssignedVouchersFetching
    ) {
      return false
    }

    const adjustments = orderBooking.items.reduce(
      (store, { adjustments }) => [...store, ...adjustments],
      []
    )

    const updatedPromotionCoupons = assignedPromotionCoupons.map((promotion_coupon) => {
      let discount = null
      let totalAmount = 0
      adjustments
        .filter((adjustment) => adjustment.origin_code === promotion_coupon.promotion?.code)
        .some((adjustment) => {
          const percentage_coefficient =
            adjustment?.details?.configuration?.percentage_with_uses?.[0]?.percentage_coefficient ||
            adjustment?.details?.configuration?.percentage_with_discounted_value?.[0]
              ?.percentage_coefficient
          if (adjustment?.type === 'order_item_new_price_adjustment') {
            return true
          } else if (percentage_coefficient >= 0) {
            discount = ` ${decimalPercentToNumber(percentage_coefficient)}%`
            return true
          } else if (adjustment?.amount) {
            totalAmount += adjustment.amount
            discount = toLocal(totalAmount)
          }
          return false
        })
      return {
        discount,
        ...promotion_coupon,
      }
    })

    return updatedPromotionCoupons
  }, [orderBooking, orderBooking.items, assignedPromotionCoupons, isQueryAssignedVouchersFetching])

  useEffect(() => {
    if (getPercentageOfVouchers()) {
      setAssignedVouchers(getPercentageOfVouchers())
    } else {
      setAssignedVouchers(null)
    }
  }, [getPercentageOfVouchers, orderBooking])

  const removeVoucherByCouponCode = async (couponCode) => {
    const [res, err] = await to(removeCouponFromOrder(orderBooking.uuid, couponCode))
    if (!res || err) {
      setLoading(false)
      addToast(t('sentences.couldNotRemoveVoucher'), { appearance: 'error' })
      return
    }

    queryClient.invalidateQueries([CLUB_QUERIES.PLAYER_VOUCHERS, { player: customer?.id }])
    await orderBookingRefetch()
    await extraPlayerContext?.calculatePrice()
    setLoading(false)
  }

  const removeVoucher = (voucherId, couponCode) => {
    if (loading) return
    setLoading(true)
    setSelectedVoucherId(voucherId)
    removeVoucherByCouponCode(couponCode)
  }

  const removePartnership = async () => {
    setLoading(true)
    const [res, err] = await to(removePartnershipFromBooking(orderBooking.uuid))
    if (!res || err) {
      setLoading(false)
      addToast(t('sentences.couldNotUnassignPartnership'), { appearance: 'error' })
      return
    }
    await extraPlayerContext?.calculatePrice()
    await orderBookingRefetch()
    setLoading(false)
  }

  const renderItem = (type, item) => {
    return (
      <div className={styles.item} key={item.id}>
        <div className={styles.label}>
          <div className={styles.iconWrapper}>
            {type === 'voucher' && <VoucherIcon className={styles.icon} />}
            {type === 'partnership' && <PartnershipIcon className={styles.icon} />}
          </div>
          {type === 'voucher' && (
            <p className={styles.voucherName}>{`${item.promotion.name} ${item.discount ?? ''}`}</p>
          )}
          {type === 'partnership' && <p className={styles.voucherName}>{`${item.name}`}</p>}
        </div>
        <div className={styles.removeicon}>
          {((type === 'voucher' && loading && selectedVoucherId === item.id) ||
            (type === 'partnership' && loading)) && (
            <PulseLoader showIf={true} dotStyles={{ width: 10, height: 10 }} />
          )}
          {!paid && !loading && (
            <span
              onClick={
                type === 'voucher' ? () => removeVoucher(item.id, item.code) : removePartnership
              }
            >
              <FontAwesomeIcon icon={faCircleMinus} className={'text-sm'} />
            </span>
          )}
        </div>
      </div>
    )
  }

  if (!assignedVouchers?.length && !orderBooking?.partnership) return null

  return (
    <div className={cx(styles.container, className)}>
      {assignedVouchers?.map((voucher) => renderItem('voucher', voucher))}
      {orderBooking?.partnership && renderItem('partnership', orderBooking?.partnership)}
    </div>
  )
}

export default AppliedActionList
