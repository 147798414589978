import Table from '@sweetspot/club-portal-legacy/components/Table'
import headers from './headers'
import { useSelector } from 'react-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'

interface PaymentPlanPropsType {
  paymentsData: [
    {
      amount: {
        value: number
        currency: string
      }
      installment_loan: {
        month: number | null
        term: number | null
        payment_date: string | null
      }
      state: string
    }
  ]
}

const PaymentPlanTable = (props: PaymentPlanPropsType) => {
  const { paymentsData } = props
  const currentUser = useSelector((state: RootState) => state.auth)

  const filteredHeaders = currentUser?.isSuperAdmin
    ? headers
    : Object.fromEntries(Object.entries(headers).filter(([key]) => key !== 'uuid'))

  return (
    <div>
      <Table headers={filteredHeaders} values={paymentsData} hasCheckbox />
    </div>
  )
}

export default PaymentPlanTable
