import { Stepper } from '@sweetspot/scramble-ds'
import { RadioGroup } from '@sweetspot/scramble-ds/atoms'
import { Controller, ControllerRenderProps, useWatch } from 'react-hook-form'
import { DURATION_TIME } from '../../consts'
import { SettingsFormScema } from './validation'
import { z } from 'zod'
import { FirstPaymentDurationProps } from '../types'
import { useTranslation } from 'react-i18next'
import { calculateDaysDifferenceBetweenStartAndExpiryDate } from '../../utils'
import { getDayDisablingStatus } from './utils'

export const FirstPaymentDuration = ({ control, setValue, booking }: FirstPaymentDurationProps) => {
  const { t } = useTranslation()
  const { daysDifference } = calculateDaysDifferenceBetweenStartAndExpiryDate(
    booking?.booking?.start_time,
    booking?.course?.timezone
  )
  const { isDayDisabled, isTwoDaysDisabled, isCustomDaysDisabled, upperLimit } =
    getDayDisablingStatus(daysDifference)

  const firstPaymentDuration = useWatch({
    control,
    name: 'first_payment_duration',
  })

  const firstPaymentCustomDays = useWatch({
    control,
    name: 'first_payment_duration_days',
  })

  const incrementCustomDaysFirstPayment = () => {
    if (firstPaymentDuration !== DURATION_TIME.CUSTOM_DAYS) return
    if (firstPaymentCustomDays >= upperLimit) return
    setValue('first_payment_duration_days', firstPaymentCustomDays + 1)
  }

  const decrementCustomDaysFirstPayment = () => {
    if (firstPaymentDuration !== DURATION_TIME.CUSTOM_DAYS) return
    if (firstPaymentCustomDays < 4) return
    setValue('first_payment_duration_days', firstPaymentCustomDays - 1)
  }

  const handleStepperChange =
    (
      field: ControllerRenderProps<
        z.infer<typeof SettingsFormScema>,
        'first_payment_duration_days'
      >,
      upperLimit: number
    ) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      if (/^\d*$/.test(value)) {
        const numValue = Number(value)
        field.onChange(
          numValue === 0 ? '' : numValue > 0 && numValue <= upperLimit ? numValue : field.value
        )
      }
    }

  return (
    <Controller
      name="first_payment_duration"
      control={control}
      render={({ field }) => (
        <RadioGroup.Root
          className="flex flex-row"
          onValueChange={(value) => {
            field.onChange(value)
            if (value === DURATION_TIME.DAY) {
              setValue('first_payment_duration_days', 1)
            } else if (value === DURATION_TIME.TWO_DAYS) {
              setValue('first_payment_duration_days', 2)
            } else if (value === DURATION_TIME.CUSTOM_DAYS) {
              setValue('first_payment_duration_days', upperLimit)
            }
          }}
          value={field.value}
          defaultValue={field.value}
        >
          <div className="border-stroke-pale py-md px-sm h-full w-full items-start">
            <RadioGroup.Item
              value={DURATION_TIME.DAY}
              id={DURATION_TIME.DAY}
              label={
                <p>
                  {t('paymentLinks.validForNext')} <b>{t('paymentLinks.24hours')}</b>
                </p>
              }
              isActive={field.value === DURATION_TIME.DAY}
              disabled={isDayDisabled}
            />
            <RadioGroup.Item
              value={DURATION_TIME.TWO_DAYS}
              id={DURATION_TIME.TWO_DAYS}
              label={
                <p>
                  {t('paymentLinks.validForNext')} <b>{t('paymentLinks.48hours')}</b>
                </p>
              }
              isActive={field.value === DURATION_TIME.TWO_DAYS}
              disabled={isTwoDaysDisabled}
            />
            <div className="pb-md flex items-center ">
              <RadioGroup.Item
                value={DURATION_TIME.CUSTOM_DAYS}
                id={DURATION_TIME.CUSTOM_DAYS}
                label={t('paymentLinks.validNext')}
                isActive={field.value === DURATION_TIME.CUSTOM_DAYS}
                className="pr-2"
                disabled={isCustomDaysDisabled}
              />
              <Controller
                name="first_payment_duration_days"
                control={control}
                render={({ field: fieldStepper }) => {
                  return (
                    <Stepper
                      value={
                        field.value === DURATION_TIME.CUSTOM_DAYS ? fieldStepper.value : upperLimit
                      }
                      increment={incrementCustomDaysFirstPayment}
                      decrement={decrementCustomDaysFirstPayment}
                      className={`w-[140px] ${
                        (firstPaymentDuration !== DURATION_TIME.CUSTOM_DAYS ||
                          isCustomDaysDisabled) &&
                        'opacity-70'
                      }`}
                      readOnly={
                        firstPaymentDuration !== DURATION_TIME.CUSTOM_DAYS || isCustomDaysDisabled
                      }
                      onChange={handleStepperChange(fieldStepper, upperLimit)}
                      onBlur={() => {
                        const value = Number(fieldStepper.value)
                        if (value < 3) {
                          fieldStepper.onChange(3)
                        } else if (value > upperLimit) {
                          fieldStepper.onChange(upperLimit)
                        }
                      }}
                    />
                  )
                }}
              />
              <label className="ml-2 text-base font-medium">{t('days').toLowerCase()}</label>
            </div>
          </div>
        </RadioGroup.Root>
      )}
    />
  )
}
