import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import { createAdyenAccount } from '@sweetspot/sweetspot-js/features/billingSettings/services/api-platform'
import { CreatAdyenAccountPayload } from '@sweetspot/sweetspot-js/features/billingSettings/types'

import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeaderLeftIcon,
  SheetTitle,
  SheetHeader,
  SheetCustomContent,
  SheetClose,
  SheetFooter,
} from '@sweetspot/scramble-ds'
import { Button, Tooltip } from '@sweetspot/scramble-ds/atoms'

import { useMutation, useQueryClient } from 'react-query'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'

import { useForm } from 'react-hook-form'
import { CreateAccountHolderSchema } from './validation'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { QUERY_KEYS } from '@sweetspot/shared/util/constants'
import { COUNTRY_OPTIONS } from '../../consts'
import { InputField } from '../shared/InputField'
import { SelectField } from '../shared/SelectFields'
import { useState } from 'react'

interface CreateAccountHolderSheetProps {
  children: React.ReactNode
}

export const CreateAccountHolderSheet = ({ children }: CreateAccountHolderSheetProps) => {
  const [isSheetOpen, setIsSheetOpen] = useState(false)

  const { t } = useTranslation()
  const { addToast } = useToasts()

  const golfClub = useSelector(({ golfClub }: RootState) =>
    golfClub?.list.find((club) => club?.id === golfClub?.selectedId)
  )

  const ACCOUNT_TYPE_OPTIONS = [
    { id: 'individual', name: t('words.individual') },
    { id: 'organization', name: t('words.business') },
  ]

  const queryClient = useQueryClient()

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<z.infer<typeof CreateAccountHolderSchema>>({
    resolver: zodResolver(CreateAccountHolderSchema),
    defaultValues: {
      name: '',
      email: '',
      country: 'SE',
      accountType: 'organization',
    },
    mode: 'onSubmit',
  })

  const createAdyenAccountMutation = useMutation(
    ({ clubId, payload }: { clubId: number; payload: CreatAdyenAccountPayload }) =>
      createAdyenAccount({ clubId, payload }),
    {
      onSuccess: (response) => {
        if (response?.onboardingUrl) {
          window.open(response.onboardingUrl, '_blank')
          queryClient.invalidateQueries([QUERY_KEYS.ACCOUNT_HOLDER])
          setIsSheetOpen(false)
        }
      },
      onError: () => {
        addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
      },
    }
  )

  const onSubmit = (data: z.infer<typeof CreateAccountHolderSchema>) => {
    if (!golfClub?.id) return

    const payload: CreatAdyenAccountPayload = {
      type: data.accountType,
      legal_name: data.accountType === 'organization' ? data.name : '',
      first_name: data.accountType === 'organization' ? '' : data.name,
      last_name: 'lastname',
      email: data.email,
      country: data.country,
      redirect_url: `${window.origin}/settings/billing-settings`,
    }

    createAdyenAccountMutation.mutate({ clubId: golfClub.id, payload })
  }

  const formValues = watch()

  const isFormFilled = Object.values(formValues).every((value) => value !== '')

  return (
    <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
      <SheetTrigger asChild className="cursor-pointer">
        {children}
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetClose asChild>
            <SheetHeaderLeftIcon>
              <i className="fa-regular fa-close" />
            </SheetHeaderLeftIcon>
          </SheetClose>
          <SheetTitle className="text-lg">{t('settings.billing.setUpAccount')}</SheetTitle>
          <Tooltip.Provider delayDuration={100}>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <i className="fa-regular fa-circle-info cursor-pointer" />
              </Tooltip.Trigger>
              <Tooltip.Content
                align="start"
                side="bottom"
                className="max-w-[300px] rounded-sm bg-black p-2 text-white"
              >
                <Tooltip.Arrow />
                {t('settings.billing.storeLinkedInfo')}
              </Tooltip.Content>
            </Tooltip.Root>
          </Tooltip.Provider>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-4">
          <SelectField
            name="accountType"
            label={t('sentences.accountType')}
            control={control}
            options={ACCOUNT_TYPE_OPTIONS}
            required={watch('accountType') === ''}
            error={errors.accountType}
          />

          <InputField
            name="name"
            label={t('sentences.legalName')}
            control={control}
            error={errors.name}
            required={watch('name') === ''}
          />

          <InputField
            name="email"
            label={t('words.email')}
            control={control}
            error={errors.email}
            required={watch('email') === ''}
          />

          <SelectField
            name="country"
            label={t('words.country')}
            control={control}
            options={COUNTRY_OPTIONS}
            required={watch('country') === ''}
            error={errors.country}
          />
        </SheetCustomContent>
        <SheetFooter className="pt-0">
          <Button
            className="w-full"
            variant="primary"
            disabled={
              !isFormFilled ||
              Object.keys(errors).length > 0 ||
              createAdyenAccountMutation.isLoading
            }
            onClick={() => {
              handleSubmit(onSubmit)()
            }}
          >
            {t('words.continue')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}
