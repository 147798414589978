import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ListItem,
  ListItemLeading,
  ListItemMainContainer,
  ListItemMainContent,
  ListItemMainLabel,
} from '@sweetspot/scramble-ds'

type Props = {
  label: string
  iconName: string
  color?: string
  handleClick: (e: MouseEvent<HTMLDivElement>) => void
}

export const ActionItem = ({ label, iconName, handleClick, color = 'text-dark' }: Props) => {
  const { t } = useTranslation()

  return (
    <ListItem
      onClick={handleClick}
      className="text-text-dark text-content-base gap-lg h-10 min-h-10 w-48 min-w-48 cursor-pointer"
    >
      <ListItemLeading className="px-0">
        <i className={`fa-regular fa-${iconName} [&>svg]:h-4 [&>svg]:w-4 text-${color}`} />
      </ListItemLeading>
      <ListItemMainContainer>
        <ListItemMainContent className="py-0">
          <ListItemMainLabel className={`text-${color}`}>{t(label)}</ListItemMainLabel>
        </ListItemMainContent>
      </ListItemMainContainer>
    </ListItem>
  )
}
