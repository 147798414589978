import { useTranslation } from 'react-i18next'
import { Button } from '@sweetspot/scramble-ds/atoms'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@sweetspot/scramble-ds'

type ConfirmDialogProps = {
  onCancel: () => void
  onSave: () => void
  open?: boolean
}

const ConfirmDialog = ({ open, onCancel, onSave }: ConfirmDialogProps) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onOpenChange={onCancel}>
      <DialogContent variant="default">
        <DialogHeader>
          <DialogTitle>{t('sentences.savePricesTitle')}</DialogTitle>
          <DialogDescription>{t('sentences.savePricesText')}</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="ghost-dark" type="button" size="large" onClick={onCancel}>
              {t('words.cancel')}
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="primary" size="large" onClick={onSave}>
              {t('words.save')}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmDialog
