/* eslint-disable @nx/enforce-module-boundaries */
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faSliders } from '@fortawesome/pro-regular-svg-icons'
import {
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
  SheetTrigger,
} from '@sweetspot/scramble-ds'
import { DeviceType, useDeviceType } from '@sweetspot/scramble-ds/utils'
import { Divider } from '@sweetspot/scramble-ds/atoms'
import FilterOption from './components/FilterOption'

import { CourseFilterType } from './types'
import { PLAYER_TYPES_VALUES, UNBOOKED_SLOT_VALUES } from './consts'

interface CourseFilterProps {
  filters: CourseFilterType
  setFilters: (filters: CourseFilterType) => void
}

export const CourseFilter = ({ filters, setFilters }: CourseFilterProps) => {
  const { t } = useTranslation()
  const { deviceType } = useDeviceType()
  const [open, setOpen] = useState(false)

  const sheetSide = useMemo(
    () => (deviceType === DeviceType.LAPTOP ? 'right' : 'bottom'),
    [deviceType]
  )

  const handleCheckedChange = (
    checked: boolean,
    value: string | number,
    type: keyof CourseFilterType
  ) => {
    const updatedFilters = {
      ...filters,
      [type]: checked
        ? [...(filters[type] || []), value]
        : (filters[type] || []).filter((item) => item !== value),
    }

    if (type === 'playerTypes') {
      const updatedPlayerType = updatedFilters['playerTypes'] || []
      localStorage.setItem('symbol_setting', JSON.stringify(updatedPlayerType))
    }

    setFilters(updatedFilters)
  }

  return (
    <Sheet open={open}>
      <SheetTrigger asChild className="cursor-pointer px-4" onClick={() => setOpen(!open)}>
        <FontAwesomeIcon icon={faSliders} size="1x" />
      </SheetTrigger>
      <SheetContent
        className="h-[95%] gap-0 rounded-t-xl lg:h-auto lg:rounded-none"
        side={sheetSide}
        onInteractOutside={() => setOpen(false)}
      >
        <SheetHeader className="mb-4">
          <SheetHeaderLeftIcon onClick={() => setOpen(false)}>
            <FontAwesomeIcon icon={faClose} className="w-3" />
          </SheetHeaderLeftIcon>
          <SheetTitle className="text-content-lg">{t('words.filter_one')}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="w-full">
          <div className="mb-5 mt-2 flex flex-col">
            <span className="text-text-dark text-base font-bold">{t('filterTeeTimes')}</span>
            <span className="text-text-subtle mb-3 text-sm">{t('golf.unbookedSlots')}</span>
            {UNBOOKED_SLOT_VALUES.map(({ value, label }) => (
              <FilterOption
                key={value}
                label={t(`golf.${label}`)}
                checked={filters?.unbookedSlots?.includes(value)}
                onCheckedChange={(checked) => handleCheckedChange(checked, value, 'unbookedSlots')}
              />
            ))}
          </div>
          <Divider variant="basic" />
          <div className="mt-5 flex flex-col">
            <span className="text-text-dark text-base font-bold">
              {t('teeSheet.playerTypeSetting')}
            </span>
            <span className="text-text-subtle mb-3 text-sm">{t('teeSheet.selectPlayerTypes')}</span>
            {PLAYER_TYPES_VALUES.map(({ value, icon: Icon }) => (
              <FilterOption
                key={value}
                icon={<Icon className="h-5 w-5" />}
                label={t(value)}
                checked={filters?.playerTypes?.includes(value)}
                onCheckedChange={(checked) => handleCheckedChange(checked, value, 'playerTypes')}
              />
            ))}
          </div>
        </SheetCustomContent>
      </SheetContent>
    </Sheet>
  )
}
