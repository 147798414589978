import {
  useCallback,
  createContext,
  useMemo,
  FC,
  PropsWithChildren,
  useContext,
  useState,
  useEffect,
} from 'react'
import { useHistory } from 'react-router-dom'
import { ManageCourseContextValue } from './types'
import useCourses from '@sweetspot/club-portal-legacy/hooks/useCourses'
import { DiscardDialogCallbacks } from '@sweetspot/club-portal-legacy/Partials/Dialogs/DiscardDialog/types'

export const ManageCourseContext = createContext<ManageCourseContextValue>({
  areCoursesLoading: false,
  courses: [],
  openCreateCourseSidebar: false,
  refetchCourses: () => {
    return
  },
  toggleCreateCourseSideBar: () => {
    return
  },
  getSpecificCourse: () => {
    return
  },
})

export const ManageCourseProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openCreateCourseSidebar, setOpenBaySideBar] = useState(false)
  const [openDiscardDialog, setOpenDiscardDialog] = useState<boolean>(false)
  const [discardDialogCallbacks, setDiscardDialogCallbacks] = useState<DiscardDialogCallbacks>()
  const {
    courses,
    areCoursesLoading,
    selectedCourse,
    setSelectedCourse,
    refetchCourses,
    getSpecificCourse,
  } = useCourses()
  const history = useHistory()

  // set openCreateCourseSidebar tab based on hash
  useEffect(() => {
    const hash = history.location.hash
    setOpenBaySideBar(hash === '#new')
  }, [history.location.hash])

  const toggleCreateCourseSideBar = useCallback(() => {
    const isOpen = !openCreateCourseSidebar
    const path = history.location.pathname
    history.push(isOpen ? `${path}#new` : path)
  }, [openCreateCourseSidebar, history])

  const value = useMemo(
    () => ({
      areCoursesLoading,
      courses,
      selectedCourse,
      setSelectedCourse,
      refetchCourses,
      openCreateCourseSidebar,
      toggleCreateCourseSideBar,
      openDiscardDialog,
      setOpenDiscardDialog,
      discardDialogCallbacks,
      setDiscardDialogCallbacks,
      getSpecificCourse,
    }),
    [
      areCoursesLoading,
      courses,
      selectedCourse,
      setSelectedCourse,
      refetchCourses,
      openCreateCourseSidebar,
      toggleCreateCourseSideBar,
      openDiscardDialog,
      setOpenDiscardDialog,
      discardDialogCallbacks,
      setDiscardDialogCallbacks,
      getSpecificCourse,
    ]
  )

  return <ManageCourseContext.Provider value={value}>{children}</ManageCourseContext.Provider>
}

export const useManageCourseContext = () => useContext(ManageCourseContext)
