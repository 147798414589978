import React, { useCallback, useMemo } from 'react'
import { PeriodPricingTableDataTimeSlot } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/types'
import { useAvailabilityAndPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/AvailabilityAndPricingProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsCross } from '@fortawesome/pro-solid-svg-icons'
import { GolfCourseTypes } from '@sweetspot/shared/types'
import { useTranslation } from 'react-i18next'
import { cn } from '@sweetspot/scramble-ds/utils'
import { Tooltip } from '@sweetspot/scramble-ds/atoms'

import PricePopoverContent from './PricePopoverConent'

const PriceCell = ({
  day,
  timeSlotIndex,
  dayIndex,
  isLastRow,
}: {
  day: string
  timeSlotIndex: number
  dayIndex: number
  isLastRow: boolean
}) => {
  const { tableData, currentCourse, selectedCells, onCellClick } =
    useAvailabilityAndPricingContext()

  const { t } = useTranslation()
  const hasExtraPlayers = useMemo(
    () => currentCourse?.type !== GolfCourseTypes.COURSE,
    [currentCourse?.type]
  )

  const getPrices = useCallback((timeSlot: PeriodPricingTableDataTimeSlot) => {
    let price: number | string | null = null
    let pricePerExtraPlayer: number | string | null = null

    if (timeSlot) {
      if (!timeSlot.price) {
        price = '-'
      } else {
        price =
          timeSlot.price?.amount === 0
            ? timeSlot.price?.amount
            : timeSlot.price?.amount / 100 || '-'
      }

      pricePerExtraPlayer = timeSlot?.price_per_extra_player
        ? `+${timeSlot?.price_per_extra_player / 100}` || '-'
        : '-'
    }

    return { price, pricePerExtraPlayer }
  }, [])

  const getMergedPricesForTimeSlot = (day: string, timeSlotIndex: number) => {
    const pricingByBay: Record<
      string,
      { price: number | string | null; pricePerExtraPlayer: number | string | null }
    > = Object.keys(tableData).reduce(
      (
        acc: Record<
          string,
          { price: number | string | null; pricePerExtraPlayer: number | string | null }
        >,
        bayKey: string
      ) => {
        const dayData = tableData[bayKey]?.[day]
        if (dayData && dayData.length > timeSlotIndex) {
          const timeSlot = dayData[timeSlotIndex]
          acc[bayKey] = getPrices(timeSlot)
        }
        return acc
      },
      {}
    )

    if (Object.keys(pricingByBay).length === 0) {
      return { price: null, pricePerExtraPlayer: null }
    }

    const priceByBay: Record<string, number | string | null> = {}
    const extraPriceByBay: Record<string, number | string | null> = {}

    for (const key in pricingByBay) {
      if (pricingByBay[key]) {
        priceByBay[key] = pricingByBay[key].price
        extraPriceByBay[key] = pricingByBay[key].pricePerExtraPlayer
      }
    }

    const uniquePrices = Array.from(new Set(Object.values(priceByBay)))
    const uniqueExtraPrices = Array.from(new Set(Object.values(extraPriceByBay)))

    const mergedPrice = uniquePrices.length === 1 ? uniquePrices[0] : priceByBay
    const mergedPricePerExtra =
      uniqueExtraPrices.length === 1 ? uniqueExtraPrices[0] : extraPriceByBay

    return {
      price: mergedPrice,
      pricePerExtraPlayer: mergedPricePerExtra,
    }
  }

  const { price, pricePerExtraPlayer } = getMergedPricesForTimeSlot(day, timeSlotIndex)

  const isPriceObject = typeof price === 'object' && price !== null && !Array.isArray(price)

  const isExtraPriceObject =
    typeof pricePerExtraPlayer === 'object' &&
    pricePerExtraPlayer !== null &&
    !Array.isArray(pricePerExtraPlayer)

  const cellId = `${timeSlotIndex}-${dayIndex}`

  if (!isPriceObject && !isExtraPriceObject) {
    return (
      <td
        id={price || pricePerExtraPlayer ? cellId : ''}
        key={dayIndex}
        className={cn(
          'bg-background-base-clean w-[154px] min-w-[100px] transition-colors',
          'border-border-stroke-pale border border-l-0 border-t-0',
          isLastRow && 'last:rounded-br-md',
          selectedCells.has(cellId) && (price || pricePerExtraPlayer) && 'bg-blue-50'
        )}
      >
        <div
          className="flex h-10 cursor-pointer items-center justify-center gap-2"
          onClick={() => onCellClick(cellId, selectedCells.has(cellId))}
        >
          <span className="text-content-sm text-text-dark font-semibold">{price}</span>
          {hasExtraPlayers && (
            <span className="text-content-xs text-text-subtle font-medium">
              {pricePerExtraPlayer}
            </span>
          )}
        </div>
      </td>
    )
  }

  const bayKeysSet = new Set<string>()
  if (isPriceObject) {
    Object.keys(price).forEach((key) => bayKeysSet.add(key))
  }
  if (isExtraPriceObject) {
    Object.keys(pricePerExtraPlayer).forEach((key) => bayKeysSet.add(key))
  }
  const bayKeys = Array.from(bayKeysSet)

  return (
    <td
      id={price || pricePerExtraPlayer ? cellId : ''}
      key={dayIndex}
      className={cn(
        'bg-background-base-clean w-[154px] min-w-[100px] transition-colors',
        'border-border-stroke-pale border border-l-0 border-t-0',
        isLastRow && 'last:rounded-br-md',
        selectedCells.has(cellId) && (price || pricePerExtraPlayer) && 'bg-blue-50'
      )}
    >
      <Tooltip.Provider key="pricesTooltip">
        <Tooltip.Root>
          <React.Fragment key="pricesTooltip">
            <Tooltip.Trigger asChild>
              <div
                className="flex h-10 cursor-pointer items-center justify-center gap-2"
                onClick={() => onCellClick(cellId, selectedCells.has(cellId))}
              >
                {isPriceObject ? (
                  <div className="group relative">
                    <FontAwesomeIcon
                      icon={faArrowsCross}
                      className="text-text-warning cursor-pointer"
                    />
                  </div>
                ) : (
                  <span className="text-content-sm text-text-dark font-semibold">{price}</span>
                )}

                {isExtraPriceObject ? (
                  <div className="group relative">
                    <FontAwesomeIcon
                      icon={faArrowsCross}
                      className="text-text-warning cursor-pointer"
                    />
                    <div className="absolute z-10 hidden border bg-white p-2 shadow-md group-hover:block"></div>
                  </div>
                ) : (
                  hasExtraPlayers && (
                    <span className="text-content-xs text-text-subtle font-medium">
                      {pricePerExtraPlayer}
                    </span>
                  )
                )}
              </div>
            </Tooltip.Trigger>
            <Tooltip.Content>
              <div className="flex w-[263px] flex-col gap-4 py-2">
                <p className="text-content-sm text-text-light font-bold">
                  {t('sentences.varyingPricesOnBays')}
                </p>
                <div className="text-content-sm text-text-light font-regular">
                  <PricePopoverContent
                    bayKeys={bayKeys}
                    price={price}
                    pricePerExtraPlayer={pricePerExtraPlayer}
                    isPriceObject={isPriceObject}
                    isExtraPriceObject={isExtraPriceObject}
                  />
                </div>
              </div>
              <Tooltip.Arrow />
            </Tooltip.Content>
          </React.Fragment>
        </Tooltip.Root>
      </Tooltip.Provider>
    </td>
  )
}

export default PriceCell
