import { ColumnDef, Table } from '@tanstack/react-table'
import React from 'react'

import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'

import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useTranslation } from 'react-i18next'
import { Button, Tooltip, Popover } from '../../../../atoms'

import { DataTableColumnListEditor } from './DataTableColumnListEditor'

interface DataTableToolbarProps<TData, TValue> {
  table: Table<TData>
  columns: ColumnDef<TData, TValue>[]
  setColumns: React.Dispatch<React.SetStateAction<ColumnDef<TData, TValue>[]>>
  extraControls?: (table: Table<TData>) => React.ReactNode
  inputSearch?: (table: Table<TData>) => React.ReactNode
}

export const DataTableToolbar = <TData, TValue>({
  table,
  columns,
  setColumns,
  extraControls,
  inputSearch,
}: DataTableToolbarProps<TData, TValue>) => {
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation()

  const [isTooltipOpen, setIsTooltipOpen] = React.useState(true)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsTooltipOpen(false)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  const handleColumnDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active && over && active.id !== over.id) {
      const activeIndex = table.getAllColumns().findIndex((col) => col.id === active.id)
      const overIndex = table.getAllColumns().findIndex((col) => col.id === over.id)

      if (activeIndex !== -1 && overIndex !== -1) {
        const reorderedColumns = arrayMove(columns, activeIndex, overIndex)
        setColumns(reorderedColumns)
      }
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor)
  )

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen)
  }

  return (
    <Popover.Root open={open} onOpenChange={handleOpenChange}>
      <div className="border-border-stroke-pale sticky top-0 z-10 flex w-full flex-row items-center justify-between border-b bg-white">
        {inputSearch && inputSearch(table)}

        <div className="ml-auto mr-2 flex items-center justify-center">
          <Tooltip.Provider>
            <Tooltip.Root delayDuration={0} open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
              <Tooltip.Trigger asChild>
                <Popover.Trigger asChild>
                  <Button
                    size="small"
                    variant="clear-dark"
                    rounding="pill"
                    className="hover:!text-text-dark !text-content-base hover:bg-transparent hover:shadow-none focus:!outline-none"
                  >
                    <i className="fa-regular fa-table-columns" />
                  </Button>
                </Popover.Trigger>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content side="bottom" className="max-w-[230px]" align="end">
                  <p className="font-bold">{t('sentences.toggleContentToViewDetailsInTable')}</p>
                  <Tooltip.Arrow width={20} height={10} />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
          {extraControls && extraControls(table)}
        </div>
      </div>

      <Popover.Content
        className="border-border-stroke-clean z-20 flex w-[350px] flex-col border px-0 py-0"
        align="end"
      >
        <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
          <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={handleColumnDragEnd}
            sensors={sensors}
          >
            <SortableContext
              items={table.getAllColumns().map((column) => column.id)}
              strategy={verticalListSortingStrategy}
            >
              {table
                .getAllColumns()
                .filter((column) => typeof column.accessorFn !== 'undefined' && column.getCanHide())
                .map((column, index) => (
                  <DataTableColumnListEditor key={column.id} column={column} index={index} />
                ))}
            </SortableContext>
          </DndContext>
        </div>
      </Popover.Content>
    </Popover.Root>
  )
}
