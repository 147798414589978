import React from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { IOrderSummaryFooter } from '../../types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faTrashCan,
  faDollarSign,
  faSwap,
  faRotateLeft,
  faXmark,
  faQrcode,
} from '@fortawesome/pro-regular-svg-icons'
import { Button } from '@sweetspot/scramble-ds/atoms'

import styles from './styles.module.scss'

const OrderSummaryFooter = ({
  showConfirm,
  showMarkAsPaid,
  showCancel,
  showCancelRefund,
  showIsPaidOnSite,
  showMoveBooking,
  onConfirm,
  onMarkAsPaid,
  onCancel,
  onCancelRefund,
  onMoveBooking,
  onCancelMove,
  confirmDisabled,
  markAsPaidDisabled,
  cancelDisabled,
  cancelRefundDisabled,
  saveChangesDisabled,
  cancelMoveDisabled,
  onBookSpaces,
  bookSpacesBtnLoading,
  bookSpacesDisabled,
  showMove,
  handleMoveBookingButtonClick,
  showSaveChanges,
  onSaveChanges,
  onRevertChanges,
  revertChangesDisabled,
  showEditSpacesButtons,
  onCancelEditSpacesChanges,
  showQRCode,
  handleQRCodeClick,
}: IOrderSummaryFooter) => {
  const { t } = useTranslation()

  return (
    <div className={cx(styles.footer, 'gap-4')}>
      {showEditSpacesButtons && (
        <>
          <Button onClick={onBookSpaces} className={'w-full'} disabled={bookSpacesDisabled}>
            {bookSpacesBtnLoading ? (
              <PulseLoader loaderClassName={'flex align-middle'} showIf={true} />
            ) : (
              <>
                <FontAwesomeIcon icon={faCheck} color={'#FFF'} />
                {t('sentences.saveChanges')}
              </>
            )}
          </Button>
          <Button
            className={'w-full'}
            disabled={bookSpacesBtnLoading}
            onClick={onCancelEditSpacesChanges}
            variant={'ghost-dark'}
          >
            <FontAwesomeIcon icon={faXmark} />
            {t('words.cancel')}
          </Button>
        </>
      )}
      {showConfirm && !showEditSpacesButtons && (
        <Button className={'w-full'} onClick={onConfirm} disabled={confirmDisabled}>
          <FontAwesomeIcon icon={faCheck} color={'#FFF'} />
          {t('sentences.confirmBooking')}
        </Button>
      )}

      {showSaveChanges && (
        <>
          <Button className={'w-full'} onClick={onSaveChanges} disabled={saveChangesDisabled}>
            <FontAwesomeIcon icon={faCheck} color={'#FFF'} />
            {t('sentences.saveChanges')}
          </Button>
          <Button
            className={'w-full'}
            onClick={onRevertChanges}
            disabled={revertChangesDisabled}
            variant={'ghost-dark'}
          >
            <FontAwesomeIcon icon={faRotateLeft} />
            {t('words.revert')}
          </Button>
        </>
      )}

      {showMarkAsPaid && !showMoveBooking && (
        <Button className={'w-full'} onClick={onMarkAsPaid} disabled={markAsPaidDisabled}>
          <FontAwesomeIcon icon={faDollarSign} color={'#FFF'} />
          {t('sentences.markAsPaid')}
        </Button>
      )}

      {showMove && !showMoveBooking && (
        <Button className={'w-full'} variant={'inverted'} onClick={handleMoveBookingButtonClick}>
          <FontAwesomeIcon icon={faSwap} />
          {t('sentences.moveBooking')}
        </Button>
      )}

      {showQRCode && (
        <Button className={'w-full'} variant={'inverted'} onClick={handleQRCodeClick}>
          <FontAwesomeIcon icon={faQrcode} />
          {t('words.qrCode')}
        </Button>
      )}

      {showIsPaidOnSite && (
        <div className={cx(styles.paidOnSiteBox)}>
          <p>{t('sentences.paidOnSite')}</p>
        </div>
      )}

      {showCancel && !showMoveBooking && !showEditSpacesButtons && (
        <Button
          className={'w-full'}
          onClick={onCancel}
          disabled={cancelDisabled}
          variant={'ghost-dark'}
        >
          <FontAwesomeIcon icon={faTrashCan} />
          {t('sentences.cancelBooking')}
        </Button>
      )}

      {showCancelRefund && (
        <Button
          className={'w-full'}
          onClick={onCancelRefund}
          disabled={cancelRefundDisabled}
          variant={'ghost-dark'}
        >
          {t('sentences.cancelAndRefund')}
        </Button>
      )}

      {showMoveBooking && (
        <>
          <Button className={'w-full'} onClick={onMoveBooking} disabled={saveChangesDisabled}>
            <FontAwesomeIcon icon={faCheck} color={'#FFF'} />
            {t('sentences.saveChanges')}
          </Button>
          <Button
            className={'w-full'}
            onClick={onCancelMove}
            disabled={cancelMoveDisabled}
            variant={'ghost-dark'}
          >
            <FontAwesomeIcon icon={faXmark} />
            {t('words.cancel')}
          </Button>
        </>
      )}
    </div>
  )
}

export default OrderSummaryFooter
