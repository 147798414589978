import { useCallback, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useSelector } from 'react-redux'

import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { Space, ViolationError } from '@sweetspot/shared/types'
import { isInteger } from '@sweetspot/shared/util/validators'
import {
  createSpace,
  deleteSpace,
  getSpaces,
  updateSpace,
} from '@sweetspot/sweetspot-js/features/spaces/services/api-platform'

import { SpacePayload } from './types'
import { RootState } from '../store/types'

type UseAcademySpacesProps = {
  selectedAcademyId?: number
}

const useAcademySpaces = ({ selectedAcademyId }: UseAcademySpacesProps) => {
  const currentClubId = useSelector(({ golfClub }: RootState) => golfClub?.selectedId || null)
  const [selectedSpace, setSelectedSpace] = useState<Space>()
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const {
    data,
    isLoading: areSpacesLoading,
    isFetching: areSpacesFetching,
    refetch,
  } = useQuery(
    [CLUB_QUERIES.SPACES, selectedAcademyId],
    () => getSpaces({ course: selectedAcademyId }),
    {
      enabled: !!selectedAcademyId,
    }
  )

  const checkForErrors = useCallback(
    (payload?: SpacePayload) => {
      if (!payload) return ''

      const { name, description, floor } = payload
      if (!name || typeof name !== 'string' || name.length < 2 || name.length > 150) {
        return `${t('words.name')} ${t('errors.stringBetween', { min: 2, max: 150 }).toLowerCase()}`
      }
      if (description && (typeof description !== 'string' || description.length > 150)) {
        return `${t('words.description')} ${t('errors.maxString_other', {
          count: 150,
        }).toLowerCase()}`
      }
      if (floor && !isInteger(floor)) {
        return t('errors.fieldMustBeANumber')
      }
      return ''
    },
    [t]
  )

  const handleOnError = (error: { error: string } | ViolationError) => {
    const errorObj = (error as ViolationError)?.violations?.[0]
    const defaultErrorMessage = errorObj
      ? `${errorObj.propertyPath}: ${errorObj.message}`
      : t('toast.defaultError')
    addToast(defaultErrorMessage, { appearance: 'error' })
  }

  const updateSpaceMutation = useMutation(
    (data: SpacePayload) => updateSpace(`${selectedSpace?.uuid}`, data),
    {
      onError: handleOnError,
    }
  )

  const createSpaceMutation = useMutation(
    (data: SpacePayload) => createSpace(`${currentClubId}`, data),
    {
      onError: handleOnError,
    }
  )

  const deleteSpaceMutation = useMutation((uuid: string) => deleteSpace(uuid), {
    onError: (error: { error: string } | ViolationError) => {
      if (
        (error as ViolationError)?.detail ===
        "Space can't be deleted or archived, there are upcoming bookings"
      ) {
        addToast(t('sentences.youHaveToCancelAllUpcomingBookingsToBeAbleToDeleteThisSpace'), {
          appearance: 'error',
        })
      } else {
        const errorObj = (error as ViolationError)?.violations?.[0]
        const defaultErrorMessage = errorObj
          ? `${errorObj.propertyPath}: ${errorObj.message}`
          : t('toast.defaultError')
        addToast(defaultErrorMessage, { appearance: 'error' })
      }
    },
  })

  const handleSpaceUpdate = useCallback(
    async (data: SpacePayload) => {
      const response = await updateSpaceMutation.mutateAsync(data)
      return response
    },
    [updateSpaceMutation]
  )

  const handleSpaceCreate = useCallback(
    async (data: SpacePayload) => {
      const response = await createSpaceMutation.mutateAsync(data)
      return response
    },
    [createSpaceMutation]
  )

  const handleSpaceDelete = useCallback(
    async (uuid: string) => {
      const response = await deleteSpaceMutation.mutateAsync(uuid)
      return response
    },
    [deleteSpaceMutation]
  )

  return {
    spaces: data || [],
    areSpacesLoading,
    areSpacesFetching,
    selectedSpace,
    setSelectedSpace,
    refetch,
    updateSpace: handleSpaceUpdate,
    createSpace: handleSpaceCreate,
    deleteSpace: handleSpaceDelete,
    checkForErrors,
  }
}

export default useAcademySpaces
