import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import ItemBox from '../ItemBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StatusHeader from '@sweetspot/club-portal-legacy/pages/SimulatorsRangesOrder/components/StatusHeader'
import { faCalendar, faClock } from '@fortawesome/pro-regular-svg-icons'

const OrderSummaryBox = ({
  orderBooking,
  venue,
  children,
  isConfirmed,
  isConsideredPaid,
  isDispenseComplete,
  isProcessing,
  formattedStartDate,
  bookingTime,
}) => {
  const { t } = useTranslation()

  if (!orderBooking || !venue) return null

  return (
    <ItemBox style="dark" className={cx(styles.container)}>
      <StatusHeader
        isConfirmed={isConfirmed}
        isPaid={isConsideredPaid}
        isAwaitingPayment={!isConsideredPaid}
        isDispenseComplete={isDispenseComplete}
        isProcessing={isProcessing}
      />
      <div className={cx(styles.header)}>
        <h2 className={cx(styles.bookingId)}>
          {t('words.booking_one')} <span>{orderBooking.token}</span>
        </h2>
        <h2 className={cx(styles.title)}>{orderBooking.course?.name}</h2>
        <div className={cx(styles.descriptionRow)}>
          <p className={cx(styles.text)}>
            <FontAwesomeIcon icon={faCalendar} className={cx(styles.calendarIcon)} />
            {formattedStartDate}
          </p>
          <p className={cx(styles.text)}>
            <FontAwesomeIcon icon={faClock} className={cx(styles.calendarIcon)} />
            {bookingTime}
          </p>
        </div>
      </div>
      {children}
    </ItemBox>
  )
}

OrderSummaryBox.propTypes = {
  orderBooking: PropTypes.object,
  orderBookingRefetch: PropTypes.func,
  venue: PropTypes.object,
  isConfirmed: PropTypes.bool,
  isConsideredPaid: PropTypes.bool,
}

OrderSummaryBox.defaultProps = {
  orderBooking: null,
  orderBookingRefetch: () => {},
  venue: null,
  isConfirmed: false,
  isConsideredPaid: false,
}

export default OrderSummaryBox
