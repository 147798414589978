import { DataTableColumnHeader } from '@sweetspot/scramble-ds'
import { Toggle } from '@sweetspot/scramble-ds/atoms'
import { ColumnDef } from '@tanstack/react-table'
import { FederationCredential } from '@sweetspot/shared/types'
import { format } from 'date-fns'

export const columns = (
  t: (key: string) => string,
  handleUpdateFederationCredential: (federationId: number, isActive: boolean) => void
): ColumnDef<FederationCredential>[] => {
  return [
    {
      accessorKey: 'git_id',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('settings.federationClubApi.clubGuid') },
      cell: ({ row }) => row.getValue('git_id'),
    },
    {
      accessorKey: 'first_sync_at',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('settings.federationClubApi.connectionDate') },
      cell: ({ row }) => {
        const dateValue = row.getValue('first_sync_at') as string
        return dateValue ? format(new Date(dateValue), 'dd-MM-yy') : '-'
      },
      enableSorting: false,
    },
    {
      accessorKey: 'is_active',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('words.status') },
      cell: ({ row }) => {
        const isActive = !!row.getValue('is_active')

        const handleToggleChange = () => {
          const federationId = row.original.id
          const newStatus = !isActive

          handleUpdateFederationCredential(federationId, newStatus)
        }

        return <Toggle checked={isActive} onCheckedChange={handleToggleChange} />
      },
      enableSorting: false,
    },
  ]
}
