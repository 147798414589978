import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

import {
  Input,
  InputBase,
  InputContainer,
  InputHint,
  InputLabelContainer,
  InputTrailingContainer,
  Textarea,
  TextareaContainer,
} from '@sweetspot/scramble-ds'
import { Button, Divider, Toggle } from '@sweetspot/scramble-ds/atoms'
import ButtonDock from '@sweetspot/sweetspot-js/common/components/ButtonDock/ButtonDock'
import { GolfCourse } from '@sweetspot/shared/types'
import useRoutingBlock from '@sweetspot/club-portal-legacy/hooks/useRoutingBlock'

import { UpdateAcademyData } from '../../types'
import useUpdateAcademy from '../../hooks/useUpdateAcademy'
import { useManageAcademyContext } from '../../ManageAcademyProvider'

type PaymentFormFields = Pick<
  UpdateAcademyData,
  'vat' | 'isPayOnSiteEnabled' | 'payOnSiteTitle' | 'payOnSiteDescription' | 'isUseDynamicPricing'
>

type PaymentTabProps = {
  currentCourse?: GolfCourse
  refetchCourses: () => void
}

const PaymentTab = ({ currentCourse, refetchCourses }: PaymentTabProps) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { updateAcademy, paymentInitialData: initialData } = useUpdateAcademy({ currentCourse })
  const { setDiscardDialogCallbacks, setOpenDiscardDialog } = useManageAcademyContext()
  const { register, watch, setValue, formState, reset } = useForm<PaymentFormFields>({
    defaultValues: initialData,
  })
  const formValues = watch()
  const isDirty = formState?.isDirty

  // reset form initialData
  useEffect(() => {
    reset(initialData)
  }, [initialData, reset])

  const onSubmit = useCallback(() => {
    updateAcademy(formValues)?.then(() => {
      addToast(t('toast.updateAcademySuccess'), { appearance: 'success' })
      reset(formValues)
      refetchCourses()
    })
  }, [formValues, updateAcademy, reset, refetchCourses, addToast, t])

  // block navigation when form is dirty
  useRoutingBlock({
    isDirty,
    onReset: reset,
    onSubmit,
    setOpenDiscardDialog,
    setDiscardDialogCallbacks,
  })

  return (
    <form onSubmit={(e) => e.preventDefault()} className="flex max-w-[456px] flex-col gap-6">
      <InputBase>
        <InputLabelContainer>{t('words.vat')}</InputLabelContainer>
        <InputContainer>
          <Input className="text-content-base h-8 border" {...register('vat')} />
          <InputTrailingContainer className="right-2">
            <p>%</p>
          </InputTrailingContainer>
        </InputContainer>
        <InputHint
          className="[&_i]:text-content-xs items-center [&_:first-child]:w-auto"
          variant="default"
          hintText={t('settings.vatHint')}
        />
      </InputBase>

      <Divider />

      <div className="flex min-h-12 items-center justify-between gap-4">
        <div>
          <label className="text-text-dark text-content-sm px-md font-bold">
            {t('settings.enablePayOnSiteLabel')}
          </label>
          <div className="text-text-subtle text-content-sm pl-2">
            {t('settings.academy.enablePayOnSiteHint')}
          </div>
        </div>
        <Toggle
          checked={!!formValues.isPayOnSiteEnabled}
          onCheckedChange={(newValue) =>
            setValue('isPayOnSiteEnabled', !!newValue, { shouldDirty: true })
          }
        />
      </div>

      {!!formValues.isPayOnSiteEnabled && (
        <>
          <InputBase>
            <InputLabelContainer>{t('settings.customPayOnSiteTitleLabel')}</InputLabelContainer>
            <InputContainer>
              <Input className="text-content-base h-8 border" {...register('payOnSiteTitle')} />
            </InputContainer>
          </InputBase>

          <InputBase>
            <InputLabelContainer className="mb-0.5">
              {t('settings.customPayOnSiteDescriptionLabel')}
            </InputLabelContainer>
            <TextareaContainer>
              <Textarea
                {...register('payOnSiteDescription')}
                max={undefined}
                min={undefined}
                className="text-content-base border"
              />
            </TextareaContainer>
          </InputBase>
        </>
      )}

      {initialData.isUseDynamicPricing && (
        <>
          <Divider />

          <div className="flex min-h-12 items-center justify-between gap-4">
            <div>
              <label className="text-text-dark text-content-sm px-md font-bold">
                {t('settings.useDynamicPricing')}
              </label>
              <div className="text-text-subtle text-content-sm pl-2">
                {t('settings.infoHoversGolfcourse.dynamicPricing')}
              </div>
            </div>
            <Toggle
              checked={!!formValues.isUseDynamicPricing}
              onCheckedChange={(newValue) =>
                setValue('isUseDynamicPricing', !!newValue, { shouldDirty: true })
              }
            />
          </div>
        </>
      )}

      <ButtonDock className="max-w-[480px]" dockClassName="left-[15px] px-0" isShown={isDirty}>
        <Button variant="ghost-dark" size="small" type="button" onClick={() => reset()}>
          {t('words.cancel')}
        </Button>
        <Button variant="primary" size="small" onClick={onSubmit}>
          {t('words.save')}
        </Button>
      </ButtonDock>
    </form>
  )
}

export default PaymentTab
