import { MouseEvent, useCallback, useState } from 'react'
import { Button, Popover } from '@sweetspot/scramble-ds/atoms'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { ActionItem } from '../../../components'
import { useResourcesStore } from '../../../../../../hooks/resources/useResourcesStore'
import { SideBarMode } from '../../../../../../types'

const ActionsItemsPopover = () => {
  const { toggleSideBar } = useResourcesStore()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const toggleDropdown = useCallback(
    (e?: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>) => {
      e?.preventDefault()
      setIsDropdownOpen(!isDropdownOpen)
    },
    [isDropdownOpen]
  )

  const handleAboutResourcesItemClick = useCallback(() => {
    toggleSideBar(SideBarMode.ABOUT_RESOURCES)
    toggleDropdown()
  }, [toggleDropdown, toggleSideBar])

  return (
    <Popover.Root open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <Popover.Trigger asChild>
        <Button variant="link-dark" className="cursor-pointer outline-0">
          <FontAwesomeIcon icon={faEllipsis} />
        </Button>
      </Popover.Trigger>
      <Popover.Content align="end" className="z-50 w-fit p-2 px-4 drop-shadow">
        <ActionItem
          iconName="plus"
          label="sentences.newResource"
          handleClick={() => setIsDropdownOpen(false)}
        />
        <ActionItem
          iconName="circle-info"
          label="sentences.aboutResources"
          handleClick={handleAboutResourcesItemClick}
        />
      </Popover.Content>
    </Popover.Root>
  )
}

export default ActionsItemsPopover
