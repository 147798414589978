import { useTranslation } from 'react-i18next'

import { EmptyState, EmptyStateTitle } from '@sweetspot/scramble-ds'
import { cn } from '@sweetspot/scramble-ds/utils'

const Loader = () => {
  const { t } = useTranslation()

  return (
    <EmptyState
      className={cn(
        '[&_i]:bg-background-mono-lighter [&_i]:mb-4 [&_i]:flex [&_i]:rounded-full [&_i]:p-5',
        'bg-translucent-light-900 fixed inset-0 left-[calc(100%-384px)] z-10 h-full max-w-full'
      )}
      loading
    >
      <EmptyStateTitle className="text-text-dark">{t('sentences.savingChanges')}</EmptyStateTitle>
    </EmptyState>
  )
}

export default Loader
