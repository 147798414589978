import React, { useContext, useRef, useState } from 'react'

import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import ButtonBasic from '@sweetspot/sweetspot-js/common/components/ButtonBasic'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import DropdownBox from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/DropdownBox'
import { RULE_TYPES, TYPES_OBJ } from '../../constants/rules'
import SingleRule from '../SingleRule'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { createNewRule } from '../../services/api-platform'
import PromotionsContext from '../../containers/PromotionsManager/PromotionsContext'
import { useToasts } from 'react-toast-notifications'
import { FlagNames, useFlag } from '@sweetspot/shared/util/feature-flag'

const RulesWrapper = ({ promotion, disabled, golfClubID = false }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const isDispenserPromotionsEnabled = useFlag(FlagNames.DispenserPromotions)

  const [rules, setRules] = useState(promotion?.rules || [])
  const [showSelectRule, setShowSelectRule] = useState(false)
  const { values } = useContext(PromotionsContext)

  const dropdownRef = useRef(null)
  useOnClickOutside(dropdownRef, () => {
    setShowSelectRule(false)
  })

  const addNewRule = async (rule) => {
    if (rules.find((x) => x.type === rule.value)) {
      return
    }

    const type = rule.value
    const newRuleId = 'new-' + crypto.randomUUID()

    let newRule = {
      id: newRuleId,
      type: rule.value,
    }

    if (type === TYPES_OBJ.SOURCE) {
      newRule = {
        ...newRule,
        configuration: {
          sources: [],
        },
      }
    } else if (type === TYPES_OBJ.COURSESANDSPACES) {
      newRule = {
        ...newRule,
        configuration: {
          venues: {},
        },
      }
    } else if (type === TYPES_OBJ.DAYSANDTIMES) {
      newRule = {
        ...newRule,
        configuration: {},
      }
    } else if (type === TYPES_OBJ.PRIMETIME) {
      newRule = {
        ...newRule,
        configuration: {
          prime_time: null,
        },
      }
    } else if (type === TYPES_OBJ.PERIOD) {
      newRule = {
        ...newRule,
        configuration: {
          periods: [],
        },
      }
    } else if (type === TYPES_OBJ.BOOKING_WINDOW) {
      newRule = {
        ...newRule,
        configuration: {
          number: 1,
          period_duration: 'day',
        },
      }
    } else if (type === TYPES_OBJ.RANGE_DISPENSER) {
      newRule = {
        ...newRule,
        configuration: {
          range_dispenser: [],
        },
      }
    }

    setRules((prev) => [...prev, newRule])
    setShowSelectRule(false)

    const [res, err] = await to(createNewRule(promotion.id, newRule))

    if (err) {
      addToast(t('sentences.couldNotAddRule'), { appearance: 'error' })
      setRules((prev) => prev.filter((r) => r.id !== newRuleId))
    }

    if (res) {
      setRules((prev) =>
        prev.map((rule) => {
          if (rule.id === newRuleId) {
            return res
          } else {
            return rule
          }
        })
      )
    }
  }

  const removeRule = (rule) => {
    setRules((prev) => {
      return prev.filter((item) => item.id !== rule.id)
    })
  }

  const OPTIONS = golfClubID
    ? RULE_TYPES
    : RULE_TYPES.filter((r) => {
        // Remove id: 6 (range_dispenser_checker) if the flag is false
        if (!isDispenserPromotionsEnabled && r.id === 6) {
          return false
        }
        return r.context.includes(values.context)
      })

  return (
    <div className={cx(styles.container)}>
      {rules?.length <= 0 && <h2 className={cx(styles.title)}>{t('sentences.noRules')}</h2>}

      {rules.map((rule, index) => (
        <SingleRule
          key={rule.id}
          index={index}
          rule={rule}
          promotionId={promotion.id}
          onRemoveRule={() => removeRule(rule)}
          disabled={disabled}
          golfClubID={golfClubID}
        />
      ))}

      <div className={cx(styles.newContainer)}>
        <ButtonBasic
          text={t('sentences.addRule')}
          icon="plus"
          className={cx(styles.addRule)}
          onClick={() => setShowSelectRule(true)}
          disabled={disabled}
        />
        <div className={cx(styles.dropdownContainer)}>
          <DropdownBox
            ref={dropdownRef}
            dropdownOpen={!disabled && showSelectRule}
            options={OPTIONS.map((rule) => {
              if (rules.find((x) => x.type === rule.value)) {
                return {
                  ...rule,
                  disabled: true,
                }
              }
              return rule
            })}
            className={styles.dropdown}
            onOptionClick={addNewRule}
          />
        </div>
      </div>
    </div>
  )
}

RulesWrapper.propTypes = {
  promotion: PropTypes.shape({
    rules: PropTypes.array.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  disabled: PropTypes.bool,
  golfClubID: PropTypes.number,
}

RulesWrapper.defaultProps = {}

export default RulesWrapper
