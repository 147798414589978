import AllPeriods from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/AllPeriods'
import CreatePeriod from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/CreatePeriod'
import CreateAdjustment from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/CreateAdjustment'

const SideBar = () => (
  <>
    <CreatePeriod />
    <AllPeriods />
    <CreateAdjustment />
  </>
)

export default SideBar
