import { useEffect } from 'react'
import { useResourcesLoader } from '../../../hooks/resources/useResourcesLoader'
import Header from './Header'
import Body from './Body'
import SideBar from './SideBar'
import { useSelectedResourceSync } from '../../../hooks/resources/useSelectedResourceSync'
import { useResourcesStore } from '../../../hooks/resources/useResourcesStore'

export const Resources = ({ timezone = 'UTC' }: { timezone?: string }) => {
  useResourcesLoader()
  useSelectedResourceSync()

  const { setTimezone } = useResourcesStore()

  useEffect(() => {
    setTimezone(timezone)
  }, [setTimezone, timezone])

  return (
    <div className="bg-background-base-neutral flex h-full w-full flex-col">
      <Header />
      <Body />
      <SideBar />
    </div>
  )
}
