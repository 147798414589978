import { Resource } from './types'
import { resources } from './mockData'

export const getResources = (): Promise<Resource[]> => {
  try {
    return Promise.resolve(resources as Resource[])
  } catch (error) {
    return Promise.reject(error)
  }
}
