import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { _deleteGolfCourse } from '@sweetspot/club-portal-legacy/services/golfCourseApi'
import { CONSTANTS } from '@sweetspot/club-portal-legacy/store/constants'

import { useManageExtrasContext } from '../ManageExtrasProvider'

const useDeleteExtrasMutation = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const history = useHistory()
  const { refetchExtras, extras } = useManageExtrasContext()

  const deleteExtrasMutation = useMutation((id: number) => _deleteGolfCourse(id), {
    onSuccess: (_, id) => {
      addToast(t('toast.courseDeleted', { type: t('words.extras_one') }), {
        appearance: 'success',
      })
      const path = history.location.pathname
      const selectedExtra = extras.find((extra) => extra.id !== id)
      history.push(`${path}#${selectedExtra?.id}`)
      // the corresponding course needs a bit time to be created after the extra is created
      setTimeout(async () => {
        refetchExtras()
        dispatch({
          type: CONSTANTS.GOLF_COURSE.DELETE,
          payload: id,
        })
      }, 2000)
    },
    onError: () => {
      addToast(t('toast.defaultError'), { appearance: 'error' })
    },
  })

  return deleteExtrasMutation
}

export default useDeleteExtrasMutation
