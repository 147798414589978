import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import style from './style.module.scss'

import Alert from '@sweetspot/club-portal-legacy/components/Alert'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'
import InfoHover from '@sweetspot/sweetspot-js/common/components/InfoHover'
import SettingComponent from '@sweetspot/club-portal-legacy/pages/Settings/components/SettingComponent'
import Text from '@sweetspot/club-portal-legacy/components/Text'

import { getLang } from '@sweetspot/club-portal-legacy/languages'
import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import { Hint } from '@sweetspot/scramble-ds/atoms'

export default class ModifyUser extends SettingComponent {
  state = {
    showAlert: false,
    errorText: '',
    showConfirmUnlink: false,
  }

  loadValues = () => {
    const { user } = this.props

    try {
      this._firstName.value = user.first_name || ''
      this._lastName.value = user.last_name || ''
      this._email.value = user.email || ''
      this._phone.value = user.phone || ''
      this._hcp.value = user.hcp || null
    } catch (e) {
      console.log('catched ' + e)
    }
  }

  resetValues = () => {
    this._firstName.value = ''
    this._lastName.value = ''
    this._email.value = ''
    this._phone.value = ''
    this._hcp.value = null
    this._password.value = ''
    this._repeatPassword.value = ''
  }

  toggleAlert = () => {
    this.setState({ showAlert: !this.state.showAlert })
  }

  toggleConfirmUnlink = () => {
    this.setState({ showConfirmUnlink: !this.state.showConfirmUnlink })
  }

  validateEmail(email) {
    return Utilities.validateEmail(email)
  }

  checkForErrors = (payload) => {
    let error = false,
      errorText = ''
    if (
      payload.first_name &&
      payload.last_name &&
      (payload.first_name.trim() === '' || payload.last_name.trim() === '')
    ) {
      error = true
      errorText = 'settings.nameRequired'
    } else if (!this.validateEmail()) {
      error = true
      errorText = 'settings.invalidEmail'
    } else if (payload.password && payload.password.length < 6) {
      error = true
      errorText = 'settings.clubAdmins.passwordLength'
    } else if (payload.password && payload.password !== this._repeatPassword.value) {
      error = true
      errorText = 'settings.clubAdmins.passwordsDontMatchError'
    }
    return { error, errorText }
  }

  getPayloadData = (currentData, userData) => {
    const payload = {}
    for (const key in currentData) {
      if (Object.prototype.hasOwnProperty.call(userData, key)) {
        if (currentData[key] !== userData[key]) {
          payload[key] = currentData[key]
        }
      } else {
        payload[key] = currentData[key]
      }
    }

    return payload
  }

  showUnlinkButton = () => {
    const { user, isSuperAdmin } = this.props

    return user && isSuperAdmin && (!!user.golfId || !!user.cdh_id)
  }

  isUnlinkEnabled = () => {
    const { user } = this.props

    return user?.is_registered && !!user.federationInfo && !!user.federationInfo[0]?.golf_id
  }

  handleUnlinkClick = () => {
    const { user, onClickUnlink } = this.props

    if (user) {
      onClickUnlink(user.id)
    }
    this.toggleConfirmUnlink()
  }

  saveSettings = () => {
    const { user, updateUser } = this.props
    let stateData = {
      email: this._email.value,
      first_name: this._firstName.value,
      last_name: this._lastName.value,
      phone: this._phone.value,
      hcp: this._hcp.value || null,
    }

    let payload = this.getPayloadData(stateData, user)

    payload.id = user.id

    if (this._password.value) payload.password = this._password.value

    let { error, errorText } = this.checkForErrors(payload)

    if (error) {
      this.setState({ errorText })
      this.toggleAlert()
    } else {
      updateUser(payload)
    }
  }

  renderMemberships() {
    const { memberships } = this.props

    return (
      <div>
        <h5>
          <Text textId="membership.memberships" />
        </h5>
        <ul>
          {memberships.map((x) => (
            <li key={x.id}>
              <b>{x.membership.name}</b>
              <br /> Klubb: {x.club.name}
              <br /> Aktiv till: {DateHelpers.toDateString(new Date(x.expires_at))}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  renderForm() {
    const { user, lang, memberships } = this.props

    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="ss-form-group">
          <label className="ss-label">{t('firstName')}</label>
          <input
            className="ss-input"
            type="text"
            ref={(input) => (this._firstName = input)}
            placeholder={getLang(lang)['firstName']}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">{t('lastName')}</label>
          <input
            className="ss-input"
            type="text"
            ref={(input) => (this._lastName = input)}
            placeholder={getLang(lang)['lastName']}
          />
        </div>
        {this.props.hasCdhNumber ? (
          <div className="ss-form-group">
            <label className="ss-label">{t('cdhNumber')}</label>
            <InfoHover textId="settings.members.cdhNumberHover" />
            <div className={style.formControl}>
              <label>{user?.cdh_id}</label>
              {this.showUnlinkButton() && (
                <button
                  className="system-button danger-outline md-32"
                  disabled={!this.isUnlinkEnabled()}
                  onClick={this.toggleConfirmUnlink}
                >
                  {t('settings.unlinkCdhNumber')}
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="ss-form-group">
            <label className="ss-label">{t('golfId')}</label>
            <InfoHover textId="settings.members.golfIdHover" />
            <div className={style.formControl}>
              <label>{user?.golfId}</label>
              {this.showUnlinkButton() && (
                <button
                  className="system-button danger-outline md-32"
                  disabled={!this.isUnlinkEnabled()}
                  onClick={this.toggleConfirmUnlink}
                >
                  {t('settings.unlinkGolfId')}
                </button>
              )}
            </div>
          </div>
        )}

        <div className="ss-form-group">
          <label className="ss-label">{t('settings.phone')}</label>
          <input
            className="ss-input"
            type="text"
            ref={(input) => (this._phone = input)}
            placeholder={getLang(lang)['settings']['phone']}
            onChange={Utilities.autoFormatNumber}
          />
        </div>
        <div className="ss-form-group" style={{ position: 'relative' }}>
          <label className="ss-label">{t('settings.email')}</label>
          <input
            className="ss-input"
            type="text"
            disabled={this.props.user?.email_verified_at}
            ref={(input) => (this._email = input)}
            placeholder={getLang(lang)['settings']['email']}
          />
          {this.props.user && this.props.user?.email_verified_at && (
            <i className="fa-solid fa-circle-check absolute right-[10px] top-[25px] text-green-500" />
          )}
          {this.props.user && !this.props.user?.email_verified_at && (
            <Hint variant="error" hintText={getLang(lang)['settings']['emailNotVerified']} />
          )}
        </div>
        <div className="ss-form-group">
          <label className="ss-label">{t('hcp')}</label>
          <input
            className="ss-input"
            type="text"
            ref={(input) => (this._hcp = input)}
            placeholder={getLang(lang)['hcp']}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">{t('settings.clubAdmins.password')}</label>
          <input
            className="ss-input"
            type="text"
            ref={(input) => (this._password = input)}
            placeholder={getLang(lang)['password']}
          />
        </div>
        <div className="ss-form-group">
          <label className="ss-label">{t('settings.clubAdmins.repeatPassword')}</label>
          <input
            className="ss-input"
            type="text"
            ref={(input) => (this._repeatPassword = input)}
            placeholder={getLang(lang)['password']}
          />
        </div>
        {memberships && this.renderMemberships()}

        <div className={style.formButtons}>
          <button
            onClick={this.saveSettings}
            disabled={!user}
            className="system-button primary ss-block-button ss-round-button md-32"
          >
            {t('save')}
          </button>
        </div>
      </form>
    )
  }

  render() {
    const { showConfirmUnlink } = this.state
    const { lang } = this.props

    return (
      <div className={style.wrapper}>
        {this.renderForm()}
        {this.props.user && this.loadValues()}
        <Alert
          showIf={this.state.showAlert}
          onClick={() => {
            this.setState({
              showAlert: false,
            })
          }}
        >
          <Text textId={this.state.errorText} />
        </Alert>

        {showConfirmUnlink && (
          <ConfirmPopup
            visible={showConfirmUnlink}
            onReject={this.toggleConfirmUnlink}
            onClose={this.toggleConfirmUnlink}
            onAccept={this.handleUnlinkClick}
            acceptTheme="danger"
            rejectTextKey="cancel"
            title={
              getLang(lang)['settings'][
                this.props.hasCdhNumber ? 'unlinkCdhNumber' : 'unlinkGolfId'
              ]
            }
            text={
              getLang(lang)['settings'][
                this.props.hasCdhNumber ? 'unlinkCdhNumberDesc' : 'unlinkGolfIdDesc'
              ]
            }
            acceptTextKey="confirm"
          />
        )}
      </div>
    )
  }
}

ModifyUser.propTypes = {
  hasCdhNumber: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  user: PropTypes.object,
  updateUser: PropTypes.func.isRequired,
  onClickUnlink: PropTypes.func.isRequired,
  memberships: PropTypes.array,
  isSuperAdmin: PropTypes.bool.isRequired,
}
