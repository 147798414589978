import { useTranslation } from 'react-i18next'

import { TableFilter } from '../../types'
import {
  ListItem,
  ListItemLeading,
  ListItemMainContent,
  ListItemMainLabel,
} from '../../../../molecules'
import { Check } from '../../../../atoms'
import { cn } from '../../../../../utils'

interface DataTableFilterItemProps {
  filterItem: TableFilter
  selectedValues: Set<string>
  handleToggle: (value: string) => void
  hideHeader?: boolean
  className?: string
}

export const DataTableFilterItem = ({
  filterItem,
  selectedValues,
  handleToggle,
  hideHeader,
  className,
}: DataTableFilterItemProps) => {
  const { t } = useTranslation()

  return (
    <div className={cn('flex flex-col', className)}>
      {!hideHeader && (
        <span className="text-text-dark mb-3 text-sm font-semibold">{t(filterItem.label)}</span>
      )}

      {filterItem.options.map(({ value, label }) => (
        <ListItem className="w-full [&:not(:last-child)]:mb-1">
          <ListItemLeading className="flex flex-row px-0">
            <Check
              className="px-0 text-base"
              checked={selectedValues.has(value)}
              onCheckedChange={() => handleToggle(value)}
            />
          </ListItemLeading>
          <ListItemMainContent className="flex flex-row items-center gap-2 py-3">
            <ListItemMainLabel className="text-text-dark content-start">
              {t(label)}
            </ListItemMainLabel>
          </ListItemMainContent>
        </ListItem>
      ))}
    </div>
  )
}
