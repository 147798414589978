import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import style from './style.module.scss'

import Container from '@sweetspot/club-portal-legacy/components/Container'
import PriceControl from './components/PriceControl'
import PriceSimulator from './components/PriceSimulator'
import GolfCourseSelect from '@sweetspot/club-portal-legacy/components/GolfCourseSelect'

import PriceHelpers from '@sweetspot/club-portal-legacy/helpers/PriceHelpers'

import { selectGolfCourse, addToast } from '@sweetspot/club-portal-legacy/store/actions'
import {
  createPricePeriod,
  createPriceSegment,
  getPricingPeriods,
  removePricePeriod,
  updatePriceChart,
  updatePricePeriod,
  updatePriceSegment,
} from '@sweetspot/shared/data-access/api-platform'
import { formatInTimeZone } from 'date-fns-tz'

class Pricing extends Component {
  constructor() {
    super()
    this.state = {
      isLoading: true,
      isWeekend: !![0, 6].includes(new Date()),
      dailyOccupancy: 0,
      priceTableData: [],
      pricePeriods: [],
      originalPeriods: [],
      activePeriodId: 0,
      activePeriod: null,
      maxPrice: 0,
      avgPrice: 0,
      simulationDate: new Date(),
    }

    this.toggleWorkdays = this.toggleWorkdays.bind(this)
    this.setSimulationDate = this.setSimulationDate.bind(this)
    this.handleChangeOccupancy = this.handleChangeOccupancy.bind(this)
    this.handleCreatePeriod = this.handleCreatePeriod.bind(this)
    this.handleChangePeriod = this.handleChangePeriod.bind(this)
    this.handleUpdatePeriod = this.handleUpdatePeriod.bind(this)
    this.handleRemovePeriod = this.handleRemovePeriod.bind(this)
    this.handleUpdateChart = this.handleUpdateChart.bind(this)
    this.handleChangePeriodId = this.handleChangePeriodId.bind(this)
  }

  componentDidMount() {
    this.getPricePeriods()
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedGolfCourse !== prevProps.selectedGolfCourse) {
      this.setState({ activePeriodId: 0 }, () => {
        this.getPricePeriods()
      })
    }
  }

  getPricePeriods() {
    const { selectedGolfCourse } = this.props
    let { activePeriodId } = this.state
    if (!selectedGolfCourse) return
    this.setState({ isLoading: true })

    getPricingPeriods(selectedGolfCourse)
      .then((response) => {
        const pricePeriods = PriceHelpers.getPricePeriod(response)
        activePeriodId = activePeriodId ? activePeriodId : response[0] ? response[0].id : 0
        const activePeriod = pricePeriods.find((pricePeriod) => pricePeriod.id === activePeriodId)

        this.setState(
          () => ({
            isLoading: false,
            pricePeriods,
            originalPeriods: response,
            activePeriodId,
            activePeriod,
            isCreateNew: false,
          }),
          this.getSimulationData
        )
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          pricePeriods: [],
          originalPeriods: [],
          activePeriodId: 0,
        })
      })
  }

  getSimulationData() {
    const { pricePeriods, activePeriodId } = this.state
    if (!activePeriodId) {
      this.setState({
        priceTableData: [],
      })
      return
    }

    const currentPeriod = pricePeriods.find((period) => period.id === activePeriodId)
    this.handleUpdateChart(currentPeriod)
  }

  toggleWorkdays() {
    this.setState({ isWeekend: !this.state.isWeekend }, () => {
      const { activePeriod } = this.state
      if (activePeriod) {
        this.handleUpdateChart(activePeriod)
      } else {
        this.getSimulationData()
      }
    })
  }

  handleChangeOccupancy(value) {
    this.setState({ dailyOccupancy: 100 - value }, () => {
      this.getSimulationData()
    })
  }

  handleChangePeriodId(id) {
    const { pricePeriods } = this.state
    // const date = DateHelpers.toDateFromDateString(
    //   pricePeriods.filter(item => item.id === id)[0].fromDate
    // );

    this.setState(
      {
        activePeriodId: id,
        activePeriod: pricePeriods.filter((period) => period.id === id)[0],
        // simulationDate: date
      },
      () => {
        this.getSimulationData()
      }
    )
  }

  handleChangePeriod(period) {
    this.setState({ activePeriod: period })
  }

  handleCreatePeriod() {
    const { selectedGolfCourse, addToast } = this.props

    this.setState({
      isLoading: true,
    })
    const pricePeriod = PriceHelpers.getPeriodForCreate(this.state.pricePeriods.length)
    const segments = PriceHelpers.getDefaultPriceSegments()
    createPricePeriod({ courseId: selectedGolfCourse, pricePeriod })
      .then((response) => {
        const periodId = response.id

        // create price segments
        const createSegmentPromises = segments.map((priceSegment) =>
          createPriceSegment({ periodId, priceSegment })
        )

        Promise.all(createSegmentPromises)
          .then(() => {
            addToast('createPricePeriodSuccess', 'success')
            this.setState({ activePeriodId: periodId })
            this.getPricePeriods()
          })
          .catch(() => {
            addToast('createPricePeriodError', 'error')
            this.setState({ isLoading: false })
          })
      })
      .catch(() => {
        addToast('createPricePeriodError', 'error')
        this.setState({ isLoading: false })
      })
  }

  handleUpdatePeriod(currentPeriod = this.state.activePeriod) {
    if (
      !currentPeriod?.avgPriceWork ||
      currentPeriod?.avgPriceWork < 1 ||
      currentPeriod?.avgPriceWork > 100
    ) {
      this.props.addToast('priceRoundingError', 'error')
      return
    }

    const { originalPeriods } = this.state

    this.setState({ isLoading: true })
    const { pricePeriod, priceSegments } = PriceHelpers.formatForUpdate(
      currentPeriod,
      originalPeriods
    )
    this.handleUpdatePeriodRequest(pricePeriod, priceSegments)
  }

  handleUpdatePeriodRequest(pricePeriod, priceSegments) {
    const { addToast } = this.props

    // update price period
    updatePricePeriod({ pricePeriod })
      .then(() => {
        // update price segments
        const updateSegmentPromises = priceSegments.map((priceSegment) =>
          updatePriceSegment({
            segmentId: priceSegment.id,
            priceSegment: {
              priority: priceSegment.priority,
              configuration: {
                ...priceSegment.configuration,
              },
            },
          })
        )

        Promise.all(updateSegmentPromises)
          .then(() => {
            addToast('updatePricePeriodSuccess', 'success')
            this.getPricePeriods()
          })
          .catch(() => {
            addToast('updatePricePeriodError', 'error')
            this.setState({ isLoading: false })
          })
      })
      .catch((err) => {
        addToast('updatePricePeriodError', 'error')
        this.setState({ isLoading: false })
      })
  }

  handleUpdateChart(currentPeriod) {
    if (
      !currentPeriod?.avgPriceWork ||
      currentPeriod?.avgPriceWork < 1 ||
      currentPeriod?.avgPriceWork > 100
    ) {
      this.props.addToast('priceRoundingError', 'error')
      return
    }
    const { originalPeriods, isWeekend, dailyOccupancy, simulationDate } = this.state
    const { currentCourse } = this.props
    const { pricePeriod, priceSegments } = PriceHelpers.formatForUpdate(
      currentPeriod,
      originalPeriods
    )
    const timezone = currentCourse?.timezone || 'Europe/Stockholm'

    this.setState({
      isLoading: true,
    })

    const { addToast } = this.props

    // update price period
    updatePricePeriod({ pricePeriod })
      .then(() => {
        // update price segments
        const updateSegmentPromises = priceSegments.map((priceSegment) =>
          updatePriceSegment({
            segmentId: priceSegment.id,
            priceSegment: {
              priority: priceSegment.priority,
              configuration: {
                ...priceSegment.configuration,
              },
            },
          })
        )

        Promise.all(updateSegmentPromises)
          .then(() => {
            // update chart
            updatePriceChart(pricePeriod.id, {
              date: formatInTimeZone(simulationDate, timezone, "yyyy-MM-dd'T'HH:mm:ss"),
              is_weekend: isWeekend,
              daily_occupancy: dailyOccupancy,
            })
              .then((response) => {
                const updatedChartData = PriceHelpers.getTableData(response, timezone)
                this.setState({
                  isLoading: false,
                  priceTableData: updatedChartData.tableData,
                  maxPrice: updatedChartData.maxPrice,
                  avgPrice: updatedChartData.avgPrice,
                })
              })
              .catch(() => {
                this.setState({
                  isLoading: false,
                  priceTableData: [],
                })
              })
            // addToast('updatePricePeriodSuccess', 'success')
          })
          .catch(() => {
            addToast('updatePricePeriodError', 'error')
            this.setState({ isLoading: false })
          })
      })
      .catch((err) => {
        addToast('updatePricePeriodError', 'error')
        this.setState({ isLoading: false })
      })
  }

  handleRemovePeriod(periodId) {
    const { addToast } = this.props
    const { pricePeriods } = this.state

    this.setState({ isLoading: true })
    removePricePeriod(periodId)
      .then(() => {
        addToast('removePricePeriodSuccess', 'success')
        this.setState({ activePeriodId: 0 }, () => {
          this.getPricePeriods()
        })
      })
      .catch(() => {
        addToast('removePricePeriodError', 'error')
        this.setState({ activePeriodId: pricePeriods[0].id })
        this.setState({ isLoading: false })
      })
  }

  setSimulationDate(date) {
    this.setState(
      {
        simulationDate: date,
        isWeekend: !![0, 6].includes(date.getDay()),
      },
      () => {
        this.getSimulationData()
      }
    )
  }

  renderGolfCourseSelect() {
    const { golfCourses, selectGolfCourse, currentCourse } = this.props

    return (
      <GolfCourseSelect
        courses={golfCourses}
        selectedCourse={currentCourse}
        onSelectCourse={selectGolfCourse}
      />
    )
  }

  renderBody() {
    const {
      isWeekend,
      priceTableData,
      activePeriodId,
      pricePeriods,
      isLoading,
      maxPrice,
      avgPrice,
      simulationDate,
      originalPeriods,
    } = this.state
    const { token, lang, addToast, golfClubs } = this.props
    const selectedGolfClub = golfClubs?.list.find((club) => club?.id === golfClubs.selectedId)

    return (
      <div className={style.content}>
        <div className={style.priceControl}>
          <PriceControl
            isLoading={isLoading}
            token={token}
            lang={lang}
            activePeriodId={activePeriodId}
            pricePeriods={pricePeriods}
            maxPrice={maxPrice}
            selectedGolfClubId={selectedGolfClub?.id}
            avgPrice={avgPrice}
            isWeekend={isWeekend}
            onChangePeriodId={this.handleChangePeriodId}
            onPeriodChange={this.handleChangePeriod}
            onCreatePeriod={this.handleCreatePeriod}
            onUpdatePeriod={this.handleUpdatePeriod}
            onUpdateChart={this.handleUpdateChart}
            onRemovePeriod={this.handleRemovePeriod}
            addToast={addToast}
          />
        </div>
        <div className={style.priceSimulator}>
          <PriceSimulator
            lang={lang}
            isWeekend={isWeekend}
            date={simulationDate}
            activePeriod={pricePeriods.filter((item) => item.id === activePeriodId)[0]}
            tableData={priceTableData}
            priceModules={(originalPeriods && originalPeriods[0]?.price_modules) || []}
            onToggleWorkdays={this.toggleWorkdays}
            onChangeOccupancy={this.handleChangeOccupancy}
            setDate={this.setSimulationDate}
          />
        </div>
      </div>
    )
  }

  render() {
    const { golfCourses } = this.props

    return (
      <Container>
        <div className={style.container}>
          <div>{golfCourses?.length > 1 && this.renderGolfCourseSelect()}</div>
          <div className={style.subContainer}>{this.renderBody()}</div>
        </div>
      </Container>
    )
  }
}

Pricing.propTypes = {
  token: PropTypes.string,
  lang: PropTypes.string,
  golfClubs: PropTypes.object,
  golfCourses: PropTypes.array,
  selectGolfCourse: PropTypes.func.isRequired,
  selectedGolfCourse: PropTypes.number,
  addToast: PropTypes.func.isRequired,
  currentCourse: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.me.lang,
    token: state.auth.token,
    golfClubs: state.golfClub,
    golfCourses: state.golfCourse.list,
    selectedGolfCourse: state.golfCourse.selectedId,
    currentCourse: state.golfCourse.list.find((c) => c.id === state.golfCourse.selectedId),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectGolfCourse: (id) => dispatch(selectGolfCourse(id)),
    addToast: (message, type) => dispatch(addToast(message, type)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pricing)
