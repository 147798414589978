import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import style from './style.module.scss'
import { t } from 'i18next'

import { ReactComponent as IconCaret } from '@sweetspot/club-portal-legacy/resources/images/caret.svg'

import Alert from '@sweetspot/club-portal-legacy/components/Alert'
import ButtonDropdown from '@sweetspot/club-portal-legacy/components/ButtonDropdown'
import Checkbox from '@sweetspot/club-portal-legacy/components/Checkbox'
import CheckboxSelect from '@sweetspot/club-portal-legacy/components/CheckboxSelect'
import ConfirmWindow from '@sweetspot/club-portal-legacy/Partials/Dialogs/ConfirmWindow'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import Pagination from '@sweetspot/club-portal-legacy/components/Pagination'
import Table from '@sweetspot/club-portal-legacy/components/Table'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import XPanel from '@sweetspot/club-portal-legacy/components/XPanel'
import SearchInput from '@sweetspot/club-portal-legacy/components/SearchInput'
import DOMHelpers from '@sweetspot/club-portal-legacy/helpers/DOMHelpers'

const _checkboxSelect = React.createRef()

class Grid extends Component {
  state = {
    activeFilter: null,
    checkBoxOptions: [],
    chosenAction: null,
    currentPage: 1,
    dialog: '',
    headers: {},
    selectedRows: [],
    showAlert: false,
    showConfirmWindow: false,
    showHandleData: false,
    showMoreButton: false,
  }

  componentDidMount = () => {
    // NOTE: Always add { selected: true } to props.headers
    // otherwise it will not render them after deployment (in dev mode works OK)
    this.setCheckBoxOptions()
    // document.addEventListener('click', this.handleClick);
  }

  componentDidUpdate = () => {
    const { totalPages } = this.props
    if (this.state.currentPage > totalPages) this.setState({ currentPage: totalPages })
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClick)
  }

  handleClick = (event) => {
    if (
      !DOMHelpers.didClickInsideArray(
        [_checkboxSelect && _checkboxSelect.current, this._button],
        event
      )
    ) {
      this.setState({ showHandleData: false })
    }
  }

  saveDataPersistent = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data))
  }

  getDataPersistent = (key) => {
    let data = localStorage.getItem(key)
    if (data === null || data === undefined) return null

    return JSON.parse(data)
  }

  setCheckBoxOptions = (headers = this.props.headers) => {
    const { pageTitle } = this.props
    const savedOptions = this.getDataPersistent(pageTitle)

    if (savedOptions) {
      this.setState({ checkBoxOptions: savedOptions }, this.setHeaders)
    } else {
      let keys = Object.keys(headers)
      const checkBoxOptions = keys.map((key) => {
        return {
          id: key,
          name: headers[key].name,
          selected: !!headers[key].selected,
          disabled: headers[key].disabled,
        }
      })

      this.setState({ checkBoxOptions }, this.setHeaders)
    }
  }

  setHeaders = () => {
    let headers = {}
    const { checkBoxOptions } = this.state

    checkBoxOptions
      .filter((option) => option.selected)
      .forEach((option) => {
        headers[option.id] = this.props.headers[option.id]
      })

    this.setState({ headers })
  }

  toggleConfirmWindow = (id) => {
    const action = this.props.actions.find((action) => action.id === id)

    if (!action) this.setState({ showConfirmWindow: !this.state.showConfirmWindow })
    else if (!action.confirmDialog && !action.alertDialog) this.props.onActionConfirm(action)
    else if (action.confirmDialog)
      this.setState({ dialog: action.confirmDialog }, () => {
        this.setState({
          showConfirmWindow: !this.state.showConfirmWindow,
        })
      })
    else if (action.alertDialog)
      this.setState({ dialog: action.alertDialog }, () => {
        this.props.onActionConfirm(action)
        this.toggleAlert()
      })
    else {
      this.setState({
        showConfirmWindow: !this.state.showConfirmWindow,
        dialog: action.confirmDialog,
      })
    }
  }

  toggleAlert = () => {
    this.setState({ showAlert: !this.state.showAlert })
  }

  handleKeyPress = (e) => {
    if (e.charCode === 13) this.handleSearch()
  }

  handleSearch = () => {
    this.setState({ currentPage: 1 }, () => this.props.onSearch('search'))
  }

  handleSelectData = (selectedData) => {
    let headers = {}
    let { checkBoxOptions } = this.state
    const { pageTitle, onColumnUpdate } = this.props

    checkBoxOptions = checkBoxOptions.map((option) => {
      if (selectedData.includes(option.id)) {
        headers[option.id] = this.props.headers[option.id]
        return { ...option, selected: true }
      } else {
        return { ...option, selected: false }
      }
    })

    this.saveDataPersistent(pageTitle, checkBoxOptions)

    if (onColumnUpdate) onColumnUpdate(checkBoxOptions)

    this.setState({
      headers,
      checkBoxOptions,
    })
  }

  handlePageChange = (pageNum) => {
    const { totalPages } = this.props
    if (pageNum < 1 || pageNum > totalPages) return
    this.setState({ currentPage: pageNum }, this.props.setCurrentPage(pageNum))
  }

  handleDrop = ({ removedIndex: start, addedIndex: end, payload }) => {
    let { checkBoxOptions } = this.state
    const { pageTitle } = this.props
    let headers = {}

    const dragItem = checkBoxOptions[payload]
    if (checkBoxOptions[end].disabled || dragItem.disabled) return
    const temp = checkBoxOptions[start]
    checkBoxOptions.splice(start, 1)
    checkBoxOptions.splice(end, 0, temp)

    checkBoxOptions
      .filter((option) => option.selected)
      .forEach((option) => {
        headers[option.id] = this.props.headers[option.id]
      })

    this.saveDataPersistent(pageTitle, checkBoxOptions)

    this.setState({
      headers,
      checkBoxOptions,
    })
  }

  renderFilters = () => {
    const { activeFilters, filters, filtersTitle, handleFilters, filterStyles, onShowMore } =
      this.props

    return (
      <div className={cx(style.filters, filterStyles)}>
        <div className={style.filtersTitle}>
          <Text textId={filtersTitle} />
        </div>
        <div className={style.filtersList}>
          {filters.map((filter) => {
            return (
              <div
                key={filter.id || filter.name}
                className={cx(style.filter, { [style.hasDivider]: !!filter.divider })}
              >
                <div className={style.filterName}>
                  <Text textId={filter.name} />
                </div>
                <div className={style.filterList}>
                  {filter.options.map((option) => {
                    return (
                      <Checkbox
                        key={option.id || option.filter}
                        append={
                          option.name && option.name.substring(0, 1) === '.' ? (
                            <Text textId={option.name} />
                          ) : (
                            <div>{option.name}</div>
                          )
                        }
                        title={option.name}
                        checked={activeFilters.includes(option.filter)}
                        onChange={() =>
                          this.setState({ currentPage: 1 }, handleFilters(option.filter))
                        }
                      />
                    )
                  })}
                  {filter.name === 'players.membership' && this.props.shouldShowMore && (
                    <button
                      className={`system-button info-outline md-32 ${style.showMore}`}
                      onClick={onShowMore}
                    >
                      {t('players.showMore')}
                    </button>
                  )}
                </div>
              </div>
            )
          })}
        </div>
        <button
          className="system-button info-outline md-32"
          onClick={() => handleFilters(null)}
          disabled={activeFilters.length === 0}
        >
          {t('players.clearFilters')}
        </button>
      </div>
    )
  }

  render() {
    const {
      actions,
      actionsEnabled,
      values,
      displayRangeOfRows,
      filters,
      filtersEnabled,
      hasCheckbox,
      pageTitle,
      searchEnabled,
      searchPlaceholder,
      totalPages,
      totalRows,
      rowsPerPage,
      isLoading,
      hideArrows,
      pagination,
      shouldHideColumnEdit,
      onSearchChange,
      onHeaderClick,
      onRowClick,
      onSelect,
      deselectAll,
      rightClickOptions,
      sortItems,
      hideSearchButton,
      allowFunctionValues,
      showHorizontalScrollBar,
      useSingleColumnSort,
    } = this.props
    const { showHandleData, headers, currentPage, checkBoxOptions, dialog, showAlert } = this.state
    const showEmptySearchResult = values.find((val) => val._id === -1)

    return (
      <XPanel fillHeight noPadding>
        <div className={style.container}>
          <div>{filtersEnabled && filters && this.renderFilters()}</div>
          <div className={style.content}>
            <div className={style.gridHeader}>
              <div className={style.row}>
                {pageTitle && (
                  <div className={style.pageTitle}>
                    <Text textId={pageTitle} />
                  </div>
                )}

                {searchEnabled && (
                  <div className={style.searchInput}>
                    <SearchInput
                      placeholder={searchPlaceholder}
                      onKeyPress={this.handleKeyPress}
                      onChange={(value) => onSearchChange(value)}
                      isBordered
                    />
                    {!hideSearchButton && (
                      <div className={style.searchBtn}>
                        <button
                          className="system-button light with-border md-32"
                          onClick={this.handleSearch}
                        >
                          {t('search')}
                        </button>
                      </div>
                    )}
                  </div>
                )}

                <PulseLoader showIf={isLoading} />
              </div>
              {actionsEnabled && actions.length > 1 ? (
                <div className={style.actionsDropdown}>
                  <ButtonDropdown
                    text="actions"
                    disabled={isLoading}
                    values={actions}
                    onClick={this.toggleConfirmWindow}
                  />
                </div>
              ) : actionsEnabled ? (
                <div className={style.headerButton}>
                  <button
                    className="system-button primary md-32"
                    disabled={isLoading || actions[0].disabled}
                    onClick={() => this.toggleConfirmWindow(actions[0].id)}
                  >
                    {t(actions[0].label)}
                  </button>
                </div>
              ) : null}

              {!shouldHideColumnEdit && (
                <button
                  className={`system-button info-outline md-32 ${style.playersColumnSelector}`}
                  active={showHandleData}
                  onClick={() => this.setState({ showHandleData: !showHandleData })}
                >
                  {t('columns')}
                  &nbsp;
                  {`• ${Object.keys(headers).length}`}
                  <IconCaret className={style.image} />
                </button>
              )}
            </div>
            <Table
              headers={headers}
              values={values}
              fullWidth
              onSelect={onSelect}
              deselectAll={deselectAll}
              fullHeight
              sortItems={sortItems}
              hasCheckbox={!showEmptySearchResult && hasCheckbox}
              hideScrollbar={!showHorizontalScrollBar}
              hideArrows={hideArrows}
              onHeaderClick={onHeaderClick}
              onRowClick={!showEmptySearchResult && onRowClick && onRowClick}
              rightClickOptions={rightClickOptions}
              allowFunctionValues={allowFunctionValues}
              useSingleColumnSort={useSingleColumnSort}
            />
            {pagination && (
              <div className={style.pagination}>
                <Pagination
                  currentPage={currentPage > totalPages ? totalPages : currentPage}
                  totalPages={totalPages}
                  totalRows={totalRows}
                  rowsPerPage={rowsPerPage}
                  onChange={this.handlePageChange}
                  displayRangeOfRows={displayRangeOfRows}
                />
              </div>
            )}
            <CheckboxSelect
              ref={_checkboxSelect}
              options={checkBoxOptions}
              showIf={showHandleData}
              onAccept={this.handleSelectData}
              onDrop={this.handleDrop}
              saveChangesOnCheck
              hideConfirmButtons
            />
          </div>

          {this.state.showConfirmWindow && (
            <ConfirmWindow
              textId={dialog}
              confirmText="confirm"
              cancelText="cancel"
              isInline
              onConfirm={() => {
                this.props.onActionConfirm(actions[0])
                this.setState({ dialog: actions[0].successConfirmDialog })
                this.toggleAlert()
                this.setState({
                  showConfirmWindow: false,
                })
              }}
              onCancel={() =>
                this.setState({
                  showConfirmWindow: false,
                })
              }
            />
          )}
          {showAlert && (
            <Alert
              showIf={showAlert}
              onClick={() => {
                this.setState({
                  showAlert: false,
                })
              }}
            >
              <div className={style.inlineText}>
                <span className={style.number}>{totalRows}</span>
                <Text textId={dialog} />
              </div>
            </Alert>
          )}
        </div>
      </XPanel>
    )
  }
}

Grid.propTypes = {
  actions: PropTypes.array,
  activeFilters: PropTypes.array,
  actionsEnabled: PropTypes.bool,
  displayRangeOfRows: PropTypes.bool,
  filters: PropTypes.array,
  filtersEnabled: PropTypes.bool,
  filtersTitle: PropTypes.string,
  hasCheckbox: PropTypes.bool,
  handleFilters: PropTypes.func,
  headers: PropTypes.object,
  hideArrows: PropTypes.bool,
  isLoading: PropTypes.bool,
  onActionConfirm: PropTypes.func,
  onHeaderClick: PropTypes.func,
  onSearchChange: PropTypes.func,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  onRowClick: PropTypes.func,
  onShowMore: PropTypes.func,
  rightClickOptions: PropTypes.array,
  pagination: PropTypes.bool,
  shouldHideColumnEdit: PropTypes.bool,
  pageTitle: PropTypes.string,
  searchEnabled: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  setCurrentPage: PropTypes.func,
  onColumnUpdate: PropTypes.func,
  totalPages: PropTypes.number,
  totalRows: PropTypes.number,
  rowsPerPage: PropTypes.number,
  values: PropTypes.array,
  sortItems: PropTypes.array,
  hideSearchButton: PropTypes.bool,
  allowFunctionValues: PropTypes.bool,
  filterStyles: PropTypes.object,
  showHorizontalScrollBar: PropTypes.bool,
  useSingleColumnSort: PropTypes.bool,
}

export default Grid
