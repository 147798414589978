import { Divider } from '@sweetspot/scramble-ds/atoms'
import { Header, LeftView, RightView } from './partials'

export const PaymentLinksOverview = () => {
  return (
    <div>
      <Header />
      <div className="relative flex h-[calc(100vh-140px)]">
        <LeftView />
        <Divider variant="basic" orientation="vertical" className="h-full" />
        <RightView />
      </div>
    </div>
  )
}
