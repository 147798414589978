import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { utcToZonedTime } from 'date-fns-tz'

import { localizedDaysOfWeek } from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import { BayTypeV2, Period, TeeTime, Range } from '@sweetspot/shared/types'
import { GolfCourseItem } from '@sweetspot/club-portal-legacy/store/types'
import getTeeTimes from '@sweetspot/club-portal-legacy/pages/TeeTimePeriods/context/useWeekPeriod/getTeeTimes'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'
import flatSlots from '@sweetspot/club-portal-legacy/pages/TeeTimePeriods/context/useTableSelector/flatSlots'
import { getStartAndEndOfDateTZ } from '@sweetspot/sweetspot-js/common/functions/dateUtils'

import {
  AllCheckboxValues,
  AllTableData,
  PriceTableDataFlatSlot,
  PeriodPricingTableData,
  WeekPeriodOption,
  PeriodPricingTimeSlots,
  PeriodPricingDaysMap,
  PeriodPricingTableDataTimeSlot,
  WHOLE_PERIOD_TABLE_DATA_KEY,
  PriceFormState,
} from '../types'
import { divideIntoWeeks, generateTableDataForAllBays, getWeekPeriodOptions } from '../utils'

import usePeriodsPricingMutations from './usePeriodsPricingMutations'

type UsePeriodsPricingProps = {
  selectedPeriod: Period | null
  setSelectedPeriod: (period: Period) => void
  currentCourse: GolfCourseItem | null
  bays: BayTypeV2[]
  refetchPeriods: () => void
  selectedRange?: Range
}

const usePeriodsPricing = ({
  selectedPeriod,
  setSelectedPeriod,
  currentCourse,
  bays,
  refetchPeriods,
  selectedRange,
}: UsePeriodsPricingProps) => {
  const { t } = useTranslation()
  const [activePeriodTab, setActivePeriodTab] = useState<number>()
  const [selectedBayIds, setSelectedBayIds] = useState<string[]>([])
  const [isUpdating, setIsUpdating] = useState(false)
  const [price, setPrice] = useState<number>(0)
  const [pricePerExtraPlayer, setPricePerExtraPlayer] = useState<number>(0)
  const [selectedCells, setSelectedCells] = useState<Set<string>>(new Set())
  const [allCells, setAllCells] = useState<Set<string> | null>(null)
  const [allCheckbox, setAllCheckbox] = useState<AllCheckboxValues | null>(null)
  const [tableData, setTableData] = useState<PeriodPricingTableData | null>(null)
  const [timeSlots, setTimeSlots] = useState<PeriodPricingTimeSlots>([])
  const [daysMap, setDaysMap] = useState<PeriodPricingDaysMap | null>(null)
  const [selectedCols, setSelectedCols] = useState<boolean[] | null>(null)
  const [selectedRows, setSelectedRows] = useState<boolean[] | null>(null)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [currency] = useClubCurrency()

  // Refresh the main data when selectedPeriod changes
  useEffect(() => {
    if (selectedPeriod) {
      // Clear table-related state
      setTableData(null)
      setSelectedCells(new Set())
      setTimeSlots([])
      setDaysMap(null)
    }
  }, [selectedPeriod, refetchPeriods])

  const allTableData: AllTableData = JSON.parse(localStorage.getItem('ALL_TABLE_DATA') || '{}')

  const weekPeriodOptions: WeekPeriodOption[] = useMemo(() => {
    if (!selectedPeriod) return []
    const weeks = divideIntoWeeks(selectedPeriod?.start_date, selectedPeriod?.end_date)
    return getWeekPeriodOptions(selectedPeriod, t, currentCourse?.timezone).map(
      (option: WeekPeriodOption, index: number) => {
        return {
          ...option,
          name:
            option.id === -1
              ? option.name
              : `${weeks[index - 1].startDate} - ${weeks[index - 1].endDate}`,
        }
      }
    )
  }, [selectedPeriod, t, currentCourse])

  const selectedWeek = useMemo(
    () =>
      activePeriodTab
        ? weekPeriodOptions.find((option) => option.id === activePeriodTab) || weekPeriodOptions[0]
        : null,
    [weekPeriodOptions, activePeriodTab]
  )

  const DAYS_OF_WEEK = useMemo(() => localizedDaysOfWeek('en'), [])

  const canSavePrices = useMemo(() => {
    if (selectedPeriod && activePeriodTab) {
      const key = activePeriodTab === -1 ? WHOLE_PERIOD_TABLE_DATA_KEY : selectedBayIds[0]
      const hasChanged = allTableData[selectedPeriod.id]?.[activePeriodTab]?.[key]?.hasChanged
      if (!hasChanged || isUpdating) return false
      const selectedWeek = allTableData?.[selectedPeriod.id]?.[activePeriodTab]

      if (!selectedWeek) return false

      for (const space of Object.values(selectedWeek)) {
        for (const weekDay of Object.values(space)) {
          if (!Array.isArray(weekDay)) continue
          for (const slot of weekDay) {
            if ((slot?.price && slot?.price?.amount !== 0) || slot?.hasChanged) {
              return true
            }
          }
        }
      }
    }
    return false
  }, [isUpdating, allTableData, selectedPeriod, activePeriodTab, selectedBayIds])

  // initialize activePeriodTab and selectedBayIds
  useEffect(() => {
    if (selectedPeriod?.period_price_status) {
      if (weekPeriodOptions.length && !activePeriodTab) {
        setActivePeriodTab(weekPeriodOptions[1].id)
      }

      if (bays.length > 0 && !selectedBayIds.length) {
        setSelectedBayIds([bays[0].id])
      }
    }
  }, [selectedPeriod, weekPeriodOptions, activePeriodTab, bays, selectedBayIds])

  const {
    data: teeTimes,
    isLoading,
    isFetching,
    refetch: refetchTeeTimes,
  } = useQuery(
    [
      'TEE_TIMES',
      currentCourse?.id,
      selectedPeriod?.id,
      selectedWeek?.id,
      selectedBayIds,
      activePeriodTab,
    ],
    () => {
      setIsDirty(true)

      const zonedStartDate = selectedWeek?.fromDate
        ? utcToZonedTime(new Date(selectedWeek.fromDate), currentCourse?.timezone as string)
        : null
      const zonedEndDate = selectedWeek?.toDate
        ? utcToZonedTime(new Date(selectedWeek.toDate), currentCourse?.timezone as string)
        : null

      const startDates = getStartAndEndOfDateTZ(zonedStartDate, currentCourse?.timezone)
      const endDates = getStartAndEndOfDateTZ(zonedEndDate, currentCourse?.timezone)
      const times = { startDate: startDates[0], endDate: endDates[1] }

      return getTeeTimes(currentCourse, selectedWeek, selectedBayIds, times)
    },
    {
      enabled: !!currentCourse && !!selectedWeek,
    }
  )

  const { updateTeeTimes, setTeeTimes } = usePeriodsPricingMutations({
    selectedPeriod,
    setSelectedPeriod,
    currentCourse,
    setActivePeriodTab,
    setSelectedBayIds,
    setIsUpdating,
    bays,
    refetchPeriods,
    refetchTeeTimes,
  })

  const groupedTeeTimes: { [key: string]: TeeTime[] } = useMemo(() => {
    if (!teeTimes?.length) {
      return {}
    }

    return teeTimes.reduce((acc, teeTime) => {
      if (!teeTime?.space?.uuid) {
        return acc
      }

      if (acc[teeTime.space.uuid]) {
        acc[teeTime.space.uuid].push(teeTime)
      } else {
        acc[teeTime.space.uuid] = [teeTime]
      }
      return acc
    }, {})
  }, [teeTimes])

  const stringifiedTableData = useMemo(() => JSON.stringify(tableData), [tableData])

  // reset activePeriodTab if selectedPeriod is changed
  useEffect(() => {
    setActivePeriodTab(undefined)
  }, [selectedPeriod])

  // reset selectedBayIds if selectedRange is changed
  useEffect(() => {
    setSelectedBayIds([])
  }, [selectedRange])

  // initialize allSells and selectedCells
  useEffect(() => {
    const data: {
      [key: string]: PeriodPricingTableDataTimeSlot[]
    } | null = tableData && Object.values(tableData) && Object.values(tableData)[0]
    if (data) {
      const allCells: Set<string> = new Set()
      for (let i = 0; i < DAYS_OF_WEEK.length; i++) {
        const dayOfWeek = DAYS_OF_WEEK[i]
        const slots = data[dayOfWeek]
        if (!slots) continue
        for (let j = 0; j < slots.length; j++) {
          allCells.add(`${j}-${i}`)
        }
      }
      setAllCells(allCells)
      setSelectedCells(new Set())
    }
  }, [DAYS_OF_WEEK, tableData])

  // set values for allCheckbox, selectedRows and selectedCols
  useEffect(() => {
    const allCheckbox =
      allCells && selectedCells.size === allCells.size
        ? AllCheckboxValues.ALL
        : selectedCells.size > 0
        ? AllCheckboxValues.SOME
        : null
    setAllCheckbox(allCheckbox)

    const firstTableData = tableData && Object.values(tableData) && Object.values(tableData)[0]

    setSelectedRows(
      Array(timeSlots?.length)
        .fill(null)
        .map((_, idxRow) => {
          if (!timeSlots?.length) return false
          for (let idxCol = 0; idxCol < 7; idxCol++) {
            if (
              firstTableData &&
              firstTableData[DAYS_OF_WEEK[idxCol]] &&
              !selectedCells.has(`${idxRow}-${idxCol}`)
            ) {
              return false
            }
          }
          return true
        })
    )

    const selectedCols = Array(7)
      .fill(null)
      .map((_, idxCol) => {
        if (!timeSlots?.length) return false
        for (let idxRow = 0; idxRow < timeSlots.length; idxRow++) {
          if (!selectedCells.has(`${idxRow}-${idxCol}`)) {
            return false
          }
        }
        return true
      })
    setSelectedCols(selectedCols)
  }, [allCells, selectedCells, DAYS_OF_WEEK, tableData, timeSlots?.length])

  // populate table with data
  useEffect(() => {
    const parsedTableData = JSON.parse(stringifiedTableData)
    if (
      selectedWeek &&
      groupedTeeTimes &&
      (!parsedTableData || !Object.keys(parsedTableData)?.length || isDirty)
    ) {
      setIsDirty(false)

      const generatedData = generateTableDataForAllBays(
        groupedTeeTimes,
        selectedPeriod,
        selectedWeek,
        currency,
        currentCourse?.timezone
      )

      setTimeSlots(generatedData.timeSlots)
      setDaysMap(generatedData.daysMap)
      setTableData(generatedData.tableData)
    }
  }, [
    selectedPeriod,
    selectedWeek,
    selectedBayIds,
    isDirty,
    stringifiedTableData, // Passing table data here would cause infinite loop, so we're using stringified version
    currency,
    currentCourse?.timezone,
    groupedTeeTimes,
    teeTimes,
  ])

  const onAllCheckboxChange = useCallback(
    (value: boolean) => {
      if (value) {
        setSelectedCells(new Set(allCells))
      } else {
        setSelectedCells(new Set())
      }
    },
    [allCells]
  )

  const onColumnClick = useCallback(
    (col: number, isSelected?: boolean) => {
      const firstTableData = tableData && Object.values(tableData) && Object.values(tableData)[0]
      if (firstTableData && firstTableData[DAYS_OF_WEEK[col]]) {
        setSelectedCells((curr) => {
          const setCopy = new Set(curr)
          Array(timeSlots?.length)
            .fill(null)
            .forEach((_, idx) => {
              setCopy[isSelected ? 'delete' : 'add'](`${idx}-${col}`)
            })
          return setCopy
        })
      }
    },
    [DAYS_OF_WEEK, tableData, timeSlots?.length]
  )

  const onRowClick = useCallback(
    (row: number, isSelected?: boolean) => {
      setSelectedCells((curr) => {
        const setCopy = new Set(curr)
        Array(7)
          .fill(null)
          .forEach((_, idx) => {
            const cellId = `${row}-${idx}`
            if (allCells?.has(cellId)) {
              setCopy[isSelected ? 'delete' : 'add'](cellId)
            }
          })
        return setCopy
      })
    },
    [allCells]
  )

  const onCellClick = useCallback(
    (cellId: string, isSelected?: boolean) => {
      setSelectedCells((curr) => {
        const setCopy = new Set(curr)
        if (isSelected) {
          setCopy.delete(cellId)
        } else {
          if (allCells?.has(cellId)) setCopy.add(cellId)
        }
        return setCopy
      })
    },
    [allCells]
  )

  const onPricesApply = useCallback(() => {
    if (selectedPeriod && activePeriodTab && tableData && Object.values(tableData)?.length) {
      setTableData((tableData) => {
        if (tableData) {
          allTableData[selectedPeriod.id] = allTableData[selectedPeriod.id] || {}
          allTableData[selectedPeriod.id][activePeriodTab] =
            allTableData[selectedPeriod.id][activePeriodTab] || {}
          Object.keys(tableData).forEach((bayId) => {
            const bayTableData = tableData[bayId]
            if (bayTableData) {
              const newData = JSON.parse(JSON.stringify(bayTableData))
              ;[...selectedCells].forEach((cell) => {
                const [row, col] = cell.split('-')
                const dayOfWeek = DAYS_OF_WEEK[+col]
                const slot = newData[dayOfWeek][+row]
                if (activePeriodTab !== -1) {
                  slot.hasChanged = true
                }
                slot.price = slot.price || {}
                slot.price.currency = slot.price.currency || currency
                slot.price.amount = Math.round(price * 100)

                slot.price_per_extra_player =
                  pricePerExtraPlayer || pricePerExtraPlayer === 0
                    ? Math.round(pricePerExtraPlayer * 100)
                    : Math.round(price * 100)
              })

              allTableData[selectedPeriod.id][activePeriodTab][bayId] =
                allTableData[selectedPeriod.id][activePeriodTab][bayId] || {}
              allTableData[selectedPeriod.id][activePeriodTab][bayId] = newData
              allTableData[selectedPeriod.id][activePeriodTab][bayId].hasChanged = true
              tableData[bayId] = newData
            }
          })
        }

        localStorage.setItem('ALL_TABLE_DATA', JSON.stringify(allTableData))
        return tableData
      })
      setSelectedCells(new Set())
      setPrice(0)
      setPricePerExtraPlayer(0)
    }
  }, [
    tableData,
    selectedPeriod,
    activePeriodTab,
    selectedCells,
    allTableData,
    DAYS_OF_WEEK,
    currency,
    price,
    pricePerExtraPlayer,
  ])

  const onPricesSubmit = useCallback(
    async (isAllChanges?: boolean) => {
      if (!selectedPeriod || !activePeriodTab) return

      if (activePeriodTab === -1) {
        return tableData && setTeeTimes(tableData[WHOLE_PERIOD_TABLE_DATA_KEY])
      }

      let selecteData: PeriodPricingTableData | null = tableData

      if (isAllChanges) {
        selecteData = allTableData[selectedPeriod.id][
          activePeriodTab
        ] as PeriodPricingTableData | null
      }

      const slots: PriceTableDataFlatSlot[] = selecteData
        ? Object.entries(selecteData).reduce((accumulator, [, arr]) => {
            accumulator.push(...flatSlots(arr))
            return accumulator
          }, [])
        : []

      if (
        selectedPeriod.period_price_status === 'weekly_price_set' ||
        selectedPeriod.period_price_status === 'all_period_price_set'
      ) {
        const filteredFinalSlots: PriceTableDataFlatSlot[] = slots.filter(
          (teeTime) => !!teeTime.price
        )

        const formState = selectedBayIds.reduce((acc, spaceId) => {
          acc[spaceId] = {
            week: activePeriodTab,
            space: spaceId,
            price,
            price_per_extra_player: pricePerExtraPlayer,
            hasUpdated: true,
            isAllChanges,
          }
          return acc
        }, {} as Record<string, PriceFormState>)

        return updateTeeTimes(formState, filteredFinalSlots)
      }
    },
    [
      activePeriodTab,
      allTableData,
      price,
      pricePerExtraPlayer,
      selectedBayIds,
      selectedPeriod,
      setTeeTimes,
      tableData,
      updateTeeTimes,
    ]
  )

  const onPricesDiscard = useCallback(() => {
    setTableData(null)
    setSelectedCells(new Set())
    setPrice(0)
    setPricePerExtraPlayer(0)

    if (selectedPeriod?.id) {
      const allTableData = JSON.parse(localStorage.getItem('ALL_TABLE_DATA') || '{}')
      delete allTableData[selectedPeriod.id]
      localStorage.setItem('ALL_TABLE_DATA', JSON.stringify(allTableData))
    }

    return
  }, [selectedPeriod?.id])

  return {
    groupOptions: [],
    selectedBayIds,
    setSelectedBayIds,
    price,
    setPrice,
    pricePerExtraPlayer,
    setPricePerExtraPlayer,
    selectedCells,
    setSelectedCells,
    allCheckbox,
    onAllCheckboxChange,
    DAYS_OF_WEEK,
    activePeriodTab,
    setActivePeriodTab,
    weekPeriodOptions,
    timeSlots: timeSlots || [],
    tableData: tableData || {},
    daysMap: daysMap || {},
    onColumnClick,
    selectedCols,
    onRowClick,
    selectedRows,
    onCellClick,
    isPricingFetching: isFetching,
    isPricingLoading: isLoading,
    isPricingUpdating: isUpdating,
    hasPricings: !!selectedPeriod?.period_price_status,
    onPricesApply,
    canSavePrices,
    onPricesSubmit,
    onPricesDiscard,
  }
}

export default usePeriodsPricing
