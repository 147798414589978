import { useTranslation } from 'react-i18next'
import { Button } from '@sweetspot/scramble-ds/atoms'
import {
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'

import DetailsView from './Details/DetailsView'
import { useManageExtrasSpacesContext } from '../ManageExtrasSpacesProvider'
import { SpaceSideBarMode } from '../types'

const SpaceDetails = () => {
  const { t } = useTranslation()
  const {
    openSpaceSideBar,
    selectedSpace,
    toggleSpaceSideBar,
    changeSpaceSideBarMode,
    spaceSideBarMode,
  } = useManageExtrasSpacesContext()

  if (!selectedSpace) {
    return null
  }

  return (
    <Sheet
      open={spaceSideBarMode === SpaceSideBarMode.VIEW && openSpaceSideBar}
      onOpenChange={(open) => toggleSpaceSideBar(SpaceSideBarMode.VIEW)}
    >
      <SheetContent>
        <SheetHeader>
          <SheetHeaderLeftIcon onClick={() => toggleSpaceSideBar()}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>{t('settings.spaces.spaceDetails')}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-6">
          <DetailsView
            name={selectedSpace.name}
            description={selectedSpace.description}
            floor={selectedSpace.floor}
          />
        </SheetCustomContent>
        <SheetFooter>
          <Button onClick={() => changeSpaceSideBarMode(SpaceSideBarMode.EDIT)} className="w-full">
            {t('settings.spaces.editSpace')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

export default SpaceDetails
