import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { DataTable, EmptyState, EmptyStateTitle } from '@sweetspot/scramble-ds'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'

import { QUERY_KEYS } from '@sweetspot/shared/util/constants'
import {
  getFederationCredentials,
  updateFederationCredential,
} from '@sweetspot/shared/data-access/api-platform'
import { FederationCredential } from '@sweetspot/shared/types'
import { CreateGitClubCredential } from '../CreateGitClubCredential/CreateGitClubCredential'
import { columns } from './columns'
import { UpdateFederationCredentialPayload } from '@sweetspot/shared/data-access-api-platform/src/clubs/types'
import { useToasts } from 'react-toast-notifications'

export const GitClubCredentialsTable = () => {
  const { t } = useTranslation()

  const golfClub = useSelector(({ golfClub }: RootState) =>
    golfClub?.list.find((club) => club?.id === golfClub?.selectedId)
  )
  const { addToast } = useToasts()
  const queryClient = useQueryClient()

  const { data, isLoading, refetch } = useQuery<FederationCredential[]>(
    [QUERY_KEYS.FEDERATION_CREDENTIALS, golfClub?.id],
    async () => {
      const response = await getFederationCredentials({ club: golfClub?.id })
      return response['hydra:member']
    }
  )

  const updateFederationCredentialMutation = useMutation(
    ({
      federationId,
      payload,
    }: {
      federationId: number
      payload: UpdateFederationCredentialPayload
    }) => {
      queryClient.setQueryData<FederationCredential[]>(
        [QUERY_KEYS.FEDERATION_CREDENTIALS, golfClub?.id],
        (oldData): FederationCredential[] => {
          if (!oldData) return []
          return oldData.map((cred) =>
            cred.id === federationId ? { ...cred, is_active: payload.is_active } : cred
          )
        }
      )

      return updateFederationCredential(federationId, payload)
    },
    {
      onSuccess: () => {
        addToast(t('settings.federationClubApi.federationCredentialUpdated'), {
          appearance: 'success',
        })
      },
      onError: (_error, { federationId, payload }) => {
        queryClient.setQueryData<FederationCredential[]>(
          [QUERY_KEYS.FEDERATION_CREDENTIALS, golfClub?.id],
          (oldData = []) => {
            return oldData.map((cred) =>
              cred.id === federationId ? { ...cred, is_active: !payload.is_active } : cred
            )
          }
        )
        refetch()
        addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
      },
    }
  )

  const handleUpdateFederationCredential = (federationId: number, isActive: boolean) => {
    const payload = { is_active: isActive }
    updateFederationCredentialMutation.mutate({ federationId, payload })
  }

  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <EmptyState
          iconClassName="fa-light !bg-background-mono-lighter mb-4 flex rounded-full p-4"
          loading
        >
          <EmptyStateTitle>{t('words.loading')}...</EmptyStateTitle>
        </EmptyState>
      </div>
    )
  }

  if (!data || data.length === 0) {
    return <CreateGitClubCredential />
  }

  return (
    <div className="w-full">
      <DataTable
        columns={columns(t, handleUpdateFederationCredential)}
        data={data}
        hideTableControls
      />
    </div>
  )
}
