import React from 'react'
import {
  InputBase,
  InputContainer,
  Input,
  InputTrailingContainer,
  InputLeadingContainer,
} from '@sweetspot/scramble-ds'
import { Check, Hint } from '@sweetspot/scramble-ds/atoms'
import { Controller } from 'react-hook-form'
import cx from 'classnames'
import { EditableInputProps } from '../../types'

export const EditableInput = ({
  handleToggleChecked,
  checked,
  control,
  isEditMode,
  error,
  handleToggleEdit,
  isLoading,
  name,
  inputHintText,
  hintText,
}: EditableInputProps) => {
  return (
    <InputBase className="mb-lg">
      <InputContainer>
        {!isEditMode && (
          <InputLeadingContainer className="left-0" onClick={handleToggleChecked}>
            <Check checked={checked} />
          </InputLeadingContainer>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const handleChange = (e: any) => {
              if (isEditMode) {
                field.onChange(e.target.value)
              }
            }
            return (
              <Input
                {...field}
                rounding="rectangular"
                type="text"
                className={cx(
                  'text-content-base text-text-medium bg-background-mono-lighter',
                  'hover:bg-background-mono-lighter pl-10 pr-10',
                  'overflow-hidden text-ellipsis whitespace-nowrap',
                  {
                    'text-text-dark bg-background-base-clean mr-3 pl-3 pr-[70px]': isEditMode,
                  }
                )}
                readOnly={!isEditMode}
                value={isEditMode ? field.value : `${inputHintText}: ${field.value}`}
                onChange={handleChange}
                state={
                  (error && checked) || (error && isEditMode && !checked) ? 'error' : 'default'
                }
              />
            )
          }}
        />
        <InputTrailingContainer
          className="cursor-pointer"
          onClick={() => {
            if (isLoading || (isEditMode && error)) return
            handleToggleEdit()
          }}
        >
          {isLoading ? (
            <div className="animate-[spin_3s_linear_infinite]">
              <i className="fa-regular text-text-dark text-brand-sm  fa-loader" />
            </div>
          ) : isEditMode && !isLoading ? (
            <div
              className={cx('flex items-center', {
                'cursor-default opacity-30':
                  (error && checked) || (error && isEditMode && !checked),
              })}
            >
              <p className="text-content-xs mr-2 font-semibold">SAVE</p>
              <i className="fa-regular fa-save" />
            </div>
          ) : (
            <i className="fa-regular fa-pen-to-square" />
          )}
        </InputTrailingContainer>
      </InputContainer>
      {((error && checked) || (error && isEditMode && !checked)) && (
        <Hint variant="error" className="mt-1" hintText={error?.message || ''} />
      )}
      {!error && isEditMode && <Hint variant="default" className="mt-1" hintText={hintText} />}
    </InputBase>
  )
}
