import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-thin-svg-icons'
import { faCircleCheck, faCircleExclamation } from '@fortawesome/pro-solid-svg-icons'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import { Country } from '@sweetspot/shared/types'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import style from './style.module.scss'
import { Tooltip } from '@sweetspot/scramble-ds/atoms'

type Props = {
  id: number
  disabled?: boolean
  isEmailVerified?: boolean
  value: string | boolean | number | null
  fieldName: string
  gender: string | null
  memberType: string | null
  membershipCategory: string
  membershipType: string
  handleClick: (id: number) => void
  countries: Country[]
}

const DisplayField: FC<Props> = ({
  id,
  isEmailVerified,
  disabled,
  value,
  fieldName,
  gender,
  memberType,
  membershipCategory,
  membershipType,
  countries,
  handleClick,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const fieldContent = useMemo(() => {
    if (fieldName === 'country') {
      return countries.find((country) => country.code === value)?.name || '-'
    }
    if (fieldName === 'gender') {
      return gender ? t(`settings.playerCard.${gender}`) : '-'
    }
    if (fieldName === 'memberType') {
      return memberType ? t(`settings.playerCard.${memberType}`) : '-'
    }
    if (fieldName === 'club_membership_category') {
      return membershipCategory ? t(`settings.playerCard.${membershipCategory}`) : '-'
    }
    if (fieldName === 'membership_type') {
      return membershipType ? t(`settings.playerCard.${membershipType}`) : '-'
    }
    if (fieldName === 'birthday') {
      return value ? DateHelpers.onlyDateString(value) : '-'
    }
    if (typeof value === 'string') return value || '-'
    if (typeof value === 'boolean') return value ? <Text textId="yes" /> : <Text textId="no" />
    return '-'
  }, [countries, fieldName, gender, t, value, isEmailVerified])

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(value)
      addToast(t('settings.emailCopySuccess'), { appearance: 'success' })
    } catch (err) {
      addToast(t('Failed to copy'), { appearance: 'error' })
    }
  }

  const handleFieldClick = useCallback(() => {
    if (disabled || (isEmailVerified && fieldName === 'email')) return

    handleClick(id)
  }, [fieldName, isEmailVerified, id, disabled])

  return fieldName !== 'email' ? (
    <div className={!disabled ? style.field : style.disabledField} onClick={handleFieldClick}>
      {fieldContent}
    </div>
  ) : (
    <div className="flex">
      <div className="w-36 truncate" onClick={handleFieldClick}>
        <Tooltip.Provider>
          <Tooltip.Root delayDuration={0}>
            <Tooltip.Trigger asChild>
              <div className="w-36 truncate">{value}</div>
            </Tooltip.Trigger>
            {value?.length > 16 && (
              <Tooltip.Content
                side="bottom"
                align="start"
                className="h-12 content-center items-center"
                sideOffset={3}
              >
                <p>{value}</p>
                <Tooltip.Arrow />
              </Tooltip.Content>
            )}
          </Tooltip.Root>
        </Tooltip.Provider>
      </div>
      <div className="z-[99] flex gap-2">
        <Tooltip.Provider>
          <Tooltip.Root delayDuration={0}>
            <Tooltip.Trigger asChild>
              <div className="h-5">
                {isEmailVerified ? (
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="mx-[1px] h-4 w-4 text-green-500"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="mx-[1px] h-4 w-4 text-red-500"
                  />
                )}
              </div>
            </Tooltip.Trigger>
            <Tooltip.Content className="h-12 content-center items-center" sideOffset={0}>
              <p>
                {isEmailVerified ? t('settings.emailVerified') : t('settings.emailNotVerified')}
              </p>
              <Tooltip.Arrow />
            </Tooltip.Content>
          </Tooltip.Root>
        </Tooltip.Provider>
      </div>
      <div className="ml-[4px] flex h-5 items-center">
        <FontAwesomeIcon
          icon={faCopy}
          onClick={handleCopy}
          className="h-3 w-3 cursor-pointer text-[#9CA3AF]"
        />
      </div>
    </div>
  )
}

export default DisplayField
