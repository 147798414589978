import { useCallback } from 'react'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
  SheetCustomContent,
} from '@sweetspot/scramble-ds'
import { Trans, useTranslation } from 'react-i18next'
import { useResourcesStore } from '../../../../../hooks/resources/useResourcesStore'
import { SideBarMode } from '../../../../../types'
import { useIsMobile } from '../../../../../hooks/useIsMobile'

const AboutResources = () => {
  const { t } = useTranslation()
  const { toggleSideBar } = useResourcesStore()
  const isMobile = useIsMobile()

  const toggleAboutResourcesSideBar = useCallback(() => {
    toggleSideBar(SideBarMode.ABOUT_RESOURCES)
  }, [toggleSideBar])

  return (
    <Sheet open onOpenChange={toggleAboutResourcesSideBar}>
      <SheetContent
        side={isMobile ? 'bottom' : 'right'}
        className="h-auto rounded-b-none rounded-t-xl pb-12 md:rounded-none"
      >
        <SheetHeader className="rounded-b-none rounded-t-xl md:rounded-none">
          <SheetHeaderLeftIcon onClick={toggleAboutResourcesSideBar}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>{t('sentences.aboutResources')}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="text-content-base text-text-dark font-regular">
          <Trans i18nKey="sentences.aboutResourcesContent" components={{ br: <br /> }} />
        </SheetCustomContent>
      </SheetContent>
    </Sheet>
  )
}

export default AboutResources
