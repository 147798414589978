import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '@sweetspot/scramble-ds/atoms'

import styles from './styles.module.scss'
import InputSelectOptionSimple from '@sweetspot/sweetspot-js/ui-kit/components/InputSelectOptionSimple'
import InputSearchOptionSimple from '@sweetspot/sweetspot-js/ui-kit/components/InputSearchOptionSimple'
import { useQuery } from 'react-query'
import {
  isBeginningOfGolfId,
  isCdhNumberFormat,
  isGolfId,
  validateEmail,
} from '@sweetspot/sweetspot-js/common/functions/utils'
import {
  importPlayerFromFederation,
  searchPlayers,
} from '@sweetspot/sweetspot-js/features/players/services/api-platform'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import parsePhoneNumberFromString, { isValidPhoneNumber } from 'libphonenumber-js'

const defaultStubPlayer = {
  name: '',
  email: '',
  phone: '',
}

const PlayerSearch = ({
  className = '',
  onSelectPlayer = () => {},
  disabled = false,
  hiddenOptions = [],
  loading = false,
  club = null,
  searchPlaceholder = '',
  clubFederationType = null,
}) => {
  const { t } = useTranslation()

  const [type, setType] = useState({ key: 0, label: '' })
  const [searchValue, setSearchValue] = useState('')
  const [searchInputText, setSearchInputText] = useState('')
  const [selectedPlayer, setSelectedPlayer] = useState(null)

  const [stubPlayer, setStubPlayer] = useMergeState(defaultStubPlayer)

  const noResultsData = [
    {
      id: 0,
      label: t('sentences.noResultsFor', { value: searchValue }),
      notSelectable: true,
    },
  ]

  useEffect(() => {
    let timeout
    clearTimeout(timeout)
    timeout = setTimeout(() => setSearchValue(searchInputText), 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [searchInputText])

  const dropdownOptions = useMemo(() => {
    const DROPDOWN_OPTIONS = [
      {
        key: 1,
        label: t('sentences.allPlayers'),
      },
      {
        key: 2,
        label: t('words.members'),
      },
      {
        key: 3,
        label: t('sentences.addManually'),
      },
    ]
    return DROPDOWN_OPTIONS.filter((x) => !hiddenOptions.includes(x.key))
  }, [hiddenOptions, t])

  useEffect(() => {
    if (!type || type?.key === 0) {
      setType(dropdownOptions[0])
    }
  }, [dropdownOptions, type])

  const inputValue = useMemo(() => {
    if (!searchInputText && selectedPlayer) {
      if (type.key === 1 || type.key === 2) {
        return `${selectedPlayer.first_name} ${selectedPlayer.last_name}${
          selectedPlayer?.email ? `, ${selectedPlayer.email}` : ''
        }`
      }
    }
    return searchInputText
  }, [searchInputText, selectedPlayer, type])

  const applyIsDisabled = useMemo(() => {
    if (disabled) return true

    if ((type.key === 1 || type.key === 2) && !selectedPlayer?.id) {
      return true
    } else if (type.key === 3) {
      const { name, email, phone } = stubPlayer
      let isInValid = true
      if (name) {
        isInValid = name?.length < 3
      }
      if (email) {
        isInValid = !validateEmail(email) && isInValid
      }
      if (phone) {
        isInValid = phone?.length < 8 && !isValidPhoneNumber(phone) && isInValid
      }

      return isInValid
    }
    return false
  }, [selectedPlayer, type, disabled, stubPlayer])

  const {
    data: searchResults,
    isFetching: resultsIsFetching,
    error: searchError,
  } = useQuery(
    [searchValue, type],
    () => {
      const isFederationIdSearch =
        clubFederationType === 'git'
          ? isGolfId(searchValue)
          : clubFederationType === 'cdh'
          ? isCdhNumberFormat(searchValue)
          : false

      if (isFederationIdSearch) {
        return importPlayerFromFederation({
          club: club?.uuid,
          federation: clubFederationType,
          identifier: searchValue,
        })
      }

      if (type.key === 1) {
        if (isGolfId(searchValue)) {
          return searchPlayers({
            golfId: searchValue,
            page: 1,
          })
        }
        return searchPlayers({
          search: searchValue,
          limit: isGolfId(searchValue) || isCdhNumberFormat(searchValue) ? 1 : 30,
          page: 1,
        })
      }

      if (type.key === 2) {
        return searchPlayers({
          search: searchValue,
          'type[members]': club?.id,
          relatedGolfClub: club?.id,
          golfClub: club?.id,
        })
      }
    },
    {
      retry: 1,
      enabled: !!searchValue && searchValue?.length >= 3 && type.key !== 3,
      select: (data) => {
        if (!data?.length && !data?.first_name) {
          return noResultsData
        }

        const isFederationIdSearch =
          clubFederationType === 'git'
            ? isGolfId(searchValue)
            : clubFederationType === 'cdh'
            ? isCdhNumberFormat(searchValue)
            : false

        if (isFederationIdSearch) {
          return [
            {
              ...data,
              label: `${data.first_name} ${data.last_name} ${data.email ? `, ${data.email}` : ''}`,
            },
          ]
        }

        if (type.key === 1 || type.key === 2) {
          return data.map((item) => ({
            ...item,
            label: `${item.first_name} ${item.last_name}${item.email ? ', ' + item.email : ''}`,
          }))
        } else {
          return data
        }
      },
    }
  )

  const handleOnOptionChange = (option) => {
    setType(option)
    setSearchInputText('')
    setSelectedPlayer(null)
    setStubPlayer(defaultStubPlayer)
  }

  const handleOnSelect = (player) => {
    setSearchInputText('')
    setSelectedPlayer(player)
  }

  const handleSearchText = (e) => {
    const value = e.target.value
    let output = value
    if (
      clubFederationType !== 'cdh' &&
      isBeginningOfGolfId(value) &&
      value?.length > 6 &&
      value?.charAt(6) !== '-'
    ) {
      output = [value.slice(0, 6), '-', value.slice(6)].join('')
    }
    setSearchInputText(output)

    if (value?.length <= 0) {
      setSelectedPlayer(null)
    }
  }

  const handleApply = () => {
    if (type.key === 1 || type.key === 2) {
      onSelectPlayer(selectedPlayer)
    } else if (type.key === 3) {
      onSelectPlayer({ type: 'stub', ...stubPlayer })
    }
  }

  const handlePhoneNumberChange = useCallback(
    (phone) => {
      const phoneNumber = parsePhoneNumberFromString(phone, club?.address?.country?.code || 'SE')
      if (phoneNumber) {
        setStubPlayer({ phone: phoneNumber.formatInternational() })
      } else {
        setStubPlayer({ phone })
      }
    },
    [club?.address?.country?.code, setStubPlayer]
  )

  return (
    <div className={cx(styles.container, className)}>
      <div>
        <InputSelectOptionSimple
          className={cx(styles.typeDropdown)}
          options={dropdownOptions}
          onOptionSelect={handleOnOptionChange}
          value={type.label}
          disabled={disabled}
        />

        {(type?.key === 1 || type?.key === 2) && (
          <InputSearchOptionSimple
            className={cx(styles.searchInputRow)}
            value={inputValue}
            onChange={handleSearchText}
            placeholder={searchPlaceholder}
            options={searchError ? noResultsData : searchResults}
            loading={resultsIsFetching}
            onOptionSelect={handleOnSelect}
            disabled={disabled}
          />
        )}
      </div>
      {type?.key === 3 && (
        <div className={cx(styles.stubInputsRow)}>
          <div className={cx(styles.inputContainer)}>
            <label className="ss-label">{t('words.name')}</label>
            <input
              className={cx('ss-input', styles.input)}
              value={stubPlayer.name}
              onChange={(e) => setStubPlayer({ name: e.target.value })}
              placeholder={t('words.name')}
              type="text"
              disabled={disabled}
            />
          </div>
          <div className={cx(styles.inputContainer)}>
            <label className="ss-label">{t('words.email')}</label>
            <input
              className={cx('ss-input', styles.input)}
              value={stubPlayer.email}
              onChange={(e) => setStubPlayer({ email: e.target.value })}
              placeholder={t('words.email')}
              type="email"
              disabled={disabled}
            />
          </div>
          <div className={cx(styles.inputContainer)}>
            <label className="ss-label">{t('words.phone')}</label>
            <input
              className={cx('ss-input', styles.input)}
              value={stubPlayer.phone}
              onChange={(e) => handlePhoneNumberChange(e.target.value)}
              placeholder={t('words.phone')}
              type="tel"
              disabled={disabled}
            />
          </div>
          <Button
            className={cx(styles.addManuallyApplyButton)}
            variant="primary"
            disabled={applyIsDisabled}
            size="large"
            onClick={handleApply}
          >
            {t('sentences.addToBooking')}
          </Button>
        </div>
      )}
      {(type?.key === 1 || type?.key === 2) && (
        <Button
          className={cx(styles.applyButton)}
          variant="primary"
          disabled={applyIsDisabled}
          size="large"
          onClick={handleApply}
        >
          {t('sentences.addToBooking')}
        </Button>
      )}
    </div>
  )
}

PlayerSearch.propTypes = {
  className: PropTypes.string,
  onSelectPlayer: PropTypes.func,
  disabled: PropTypes.bool,
  hiddenOptions: PropTypes.array,
  loading: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  clubFederationType: PropTypes.oneOf(['cdh', 'git', null]),
}

export default PlayerSearch
