import { cn } from '@sweetspot/scramble-ds/utils'
import { Divider } from '@sweetspot/scramble-ds/atoms'

import { statusColorMap } from '../../constants'
import useTableHeader from '../../hooks/useTableHeader'
import TableBodyRow from '../TableBodyRow'
import { useBallPricingContext } from '../../BallPricingProvider'
import { PriceObject } from '../../types'

type PricesTableProps = {
  data?: PriceObject[][]
}

export const PricesTable = ({ data }: PricesTableProps) => {
  const {
    demandModel: { timeSections },
    bucketConfiguration: { isExperienceBasedPricing },
  } = useBallPricingContext()
  const tableHeader = useTableHeader()

  return (
    <div className={cn('flex h-full flex-1 flex-col', { 'mt-1': !isExperienceBasedPricing })}>
      {tableHeader}
      <table>
        <tbody>
          {timeSections.map(([from, to], rowIndex) => {
            const isLastRow = rowIndex === timeSections.length - 1
            return (
              <TableBodyRow key={`${from}-${to}`} from={from} to={to} isLastRow={isLastRow}>
                {data?.[rowIndex]?.map((priceObj, colIndex) => {
                  const isFirstColumn = colIndex === 0
                  const isLastColumn = colIndex === 6
                  const statusColorClass = statusColorMap[priceObj.status]
                  const borderRightClass = isLastColumn ? 'border-r' : ''
                  const borderBottomClass = isLastRow ? 'border-b' : ''
                  const roundedBottomRightClass = isLastColumn && isLastRow ? 'rounded-br-sm' : ''

                  return (
                    <td key={colIndex} className="h-8 !w-[114px]">
                      <div
                        className={`border-Borders-stroke-clean h-full w-full ${statusColorClass} ${borderRightClass} ${borderBottomClass} ${roundedBottomRightClass}`}
                      >
                        <div
                          className={`flex h-full w-full items-center justify-center ${
                            !isLastColumn ? 'border-background-mono-lighter' : ''
                          }`}
                        >
                          {!isFirstColumn && (
                            <div className="bg-background-mono-lighter h-full w-[2px]" />
                          )}
                          <div className="text-text-dark text-content-xs w-full text-center">
                            {priceObj.price}
                          </div>
                          {!isLastColumn && (
                            <div className="bg-background-mono-lighter flex h-full w-[3px] items-center justify-end">
                              <Divider className="h-4" orientation="vertical" />
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  )
                })}
              </TableBodyRow>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
