import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  BaySideBarMode,
  BayTableRowData,
} from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/types'
import { BayType, BayTypeV2 } from '@sweetspot/shared/types'
import { Button } from '@sweetspot/scramble-ds/atoms'
import { useBaysContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/BaysProvider'
import Table from '@sweetspot/club-portal-legacy/components/Table'

import getHeaders from './headers'

type BaysTableProps = {
  bayData: BayTableRowData[]
}

const BaysTable = ({ bayData }: BaysTableProps) => {
  const { t } = useTranslation()
  const [selectedRows, setSelectedRows] = useState<BayType[]>([])
  const { setSelectedBays, toggleBaySideBar, bays, getAllocationLabel } = useBaysContext()

  const handleRowClick = useCallback(
    (selectedValue: BayType) => {
      setSelectedBays([bays.find((bay) => bay.id === selectedValue.id) as BayTypeV2])
      toggleBaySideBar(BaySideBarMode.VIEW)
    },
    [setSelectedBays, toggleBaySideBar, bays]
  )

  const handleEditClick = useCallback(() => {
    setSelectedBays(selectedRows.map((row) => bays.find((bay) => bay.id === row.id) as BayTypeV2))
    toggleBaySideBar(selectedRows.length > 1 ? BaySideBarMode.EDIT_BULK : BaySideBarMode.EDIT)
  }, [selectedRows, setSelectedBays, toggleBaySideBar, bays])

  return (
    <div className="h-full w-full">
      <div className="mb-lg flex justify-end">
        <Button
          variant={'clear-dark'}
          size={'small'}
          onClick={handleEditClick}
          disabled={!selectedRows?.length}
          className={'py-sm px-lg border-stroke-stone gap-2 text-sm font-medium'}
        >
          <i className="fa-regular fa-edit" />
          {t('words.edit')}
        </Button>
      </div>
      <Table
        headers={getHeaders(t, getAllocationLabel)}
        values={bayData}
        fullWidth
        fullHeight
        hasCheckbox
        hideScrollbar
        onRowClick={handleRowClick}
        onSelect={setSelectedRows}
      />
    </div>
  )
}

export default BaysTable
