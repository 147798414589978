import { EmptyState, EmptyStateTitle } from '@sweetspot/scramble-ds'
import { useTranslation } from 'react-i18next'
import { useResourcesStore } from '../../../../hooks/resources/useResourcesStore'
import NoResourcesComponent from './EmptyState'
import Resources from './Resources'

const Body = () => {
  const { t } = useTranslation()
  const { resources, areResourcesLoading } = useResourcesStore()

  if (areResourcesLoading) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <EmptyState
          iconName="fa-light fa-flip-both fa-loader bg-background-mono-lighter mb-4 flex rounded-full p-4"
          loading
        >
          <EmptyStateTitle>{t('words.loading')}</EmptyStateTitle>
        </EmptyState>
      </div>
    )
  }

  if (!resources?.length) {
    return (
      <div className="flex flex-1 items-center justify-center px-4">
        <NoResourcesComponent />
      </div>
    )
  }

  return <Resources />
}

export default Body
