import { forwardRef, HTMLAttributes, useState } from 'react'
import { cn } from '@sweetspot/scramble-ds/utils'
import { Accordion } from '@sweetspot/scramble-ds/atoms'

const Dock = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    isShown?: boolean
    isExpandable?: boolean
    expandableContent?: React.ReactNode
    collapsedContent?: React.ReactNode
  }
>(
  (
    { className, isShown, children, isExpandable, expandableContent, collapsedContent, ...props },
    ref
  ) => {
    const [isExpanded, setIsExpanded] = useState(false)

    return (
      <div
        ref={ref}
        className={cn(
          'bg-base-white p-lg absolute inset-x-0 bottom-0 z-50 origin-bottom scale-y-0 rounded-t-xl shadow-2xl transition-transform md:rounded-none',
          className,
          {
            'scale-y-100': isShown,
          }
        )}
        {...props}
      >
        {isExpandable && (
          <Accordion.Root
            type="single"
            collapsible
            className="w-full"
            onValueChange={(value) => setIsExpanded(value === 'dock-accordion')}
          >
            <Accordion.Item value="dock-accordion" className="w-full max-w-full border-b-0">
              <Accordion.Trigger
                className="flex-col-reverse items-center gap-2"
                direction="up"
                childrenClassName="w-full pr-0"
                headerClassName="px-0"
              >
                {!isExpanded && collapsedContent}
              </Accordion.Trigger>
              <Accordion.Content className="bg-background-base-clean max-h-[500px] overflow-y-auto">
                {expandableContent}
              </Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
        )}
        {children}
      </div>
    )
  }
)

export default Dock
