import OverlayContainer from '@sweetspot/sweetspot-js/common/components/OverlayContainer'
import React, { useState, useLayoutEffect, createRef } from 'react'
import styles from './styles.module.scss'

import { ReactComponent as CloseIcon } from '@sweetspot/sweetspot-js/assets/svgs/cross.svg'
import Button from '@sweetspot/sweetspot-js/common/components/Button'

import cx from 'classnames'
import { useTranslation } from 'react-i18next'

/**
 * Popup for error messages and popups,
 * buttons have the following props:
 * buttons=[{text: string, Theme: buttons.theme, disabled: bool , onClick: func, hideOnMobile:bool}]
 * title= JSX
 *
 * Number of children decides number of grid cols
 *
 *
 */
const PopupModal = ({
  onClose,
  children,
  title,
  buttons,
  maxWidth,
  gridStyles = {},
  isFullsizeOverlay,
  containerClassName,
  disableShadow,
  breakpoint,
  wrapperClassName,
}) => {
  const { t } = useTranslation()
  const gridRef = createRef()
  const [showShadow, setShowShadow] = useState(false)
  const screenWidth = window.innerWidth
  const [animation, setAnimation] = useState(screenWidth > (breakpoint || 1024) ? null : 'in')

  useLayoutEffect(() => {
    if (gridRef.current.clientHeight < gridRef.current.scrollHeight) {
      if (screenWidth <= breakpoint || 1024) {
        setShowShadow(true)
      } else {
        setShowShadow(false)
      }
    } else {
      setShowShadow(false)
    }
  }, [gridRef])

  const handleOnClose = () => {
    if (screenWidth > breakpoint || 1024) {
      onClose()
      return
    }
    setAnimation('out')
    setTimeout(() => {
      onClose()
    }, 700)
  }

  const getGridStyle = () => {
    const style = {}
    if (screenWidth <= (breakpoint || 1024)) {
      style.gridTemplateColumns = '1fr'
    } else {
      style.gridTemplateColumns = '1fr '.repeat(children.length || 1)
    }

    style.maxWidth = maxWidth || (!Array.isArray(children) && '90%')
    return { ...style, ...gridStyles }
  }

  return (
    <div className={cx(styles.wrapper, wrapperClassName)}>
      <OverlayContainer onClick={handleOnClose} bottom isFullsize={isFullsizeOverlay}>
        <div
          onClick={(e) => e.stopPropagation()}
          className={cx(
            styles.container,
            containerClassName,
            animation === 'in' && styles.animateIn,
            animation === 'out' && styles.animateOut
          )}
        >
          <div className={styles.innerContainer}>
            <div className={styles.titleContainer}>
              {title}
              <CloseIcon className={styles.closeIcon} onClick={handleOnClose} />
            </div>
            <div
              className={cx(
                styles.shadowContainer,
                showShadow && !disableShadow && styles.withShadow
              )}
            >
              <div ref={gridRef} className={styles.gridContainer} style={getGridStyle()}>
                {children}
              </div>
            </div>

            <div className={styles.buttonContainer}>
              {buttons &&
                buttons.map((button, index) => {
                  if (button.hideOnMobile && screenWidth <= (breakpoint || 1024)) {
                    return null
                  }

                  if (button?.custom === true) {
                    return (
                      <button
                        key={button?.text ? button.text + index : index}
                        className={button.className}
                        onClick={() => button.onClick()}
                        disabled={button?.disabled}
                      >
                        {button?.text || button?.children}
                      </button>
                    )
                  } else {
                    return (
                      <Button
                        key={button.text + index}
                        className={styles.button}
                        onClick={() => button.onClick()}
                        width="auto"
                        size={button?.size || 'large'}
                        disabled={button.disabled}
                        platformTheme={button.platformTheme || 'club'}
                        theme={button.theme}
                      >
                        {button.text}
                      </Button>
                    )
                  }
                })}
            </div>
            <div className={styles.closeButton}>
              <p className={styles.closeText} onClick={handleOnClose}>
                {t('words.close')}
              </p>
            </div>
          </div>
        </div>
      </OverlayContainer>
    </div>
  )
}

export default PopupModal
