import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import { Button } from '@sweetspot/scramble-ds/atoms'
import {
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { cn } from '@sweetspot/scramble-ds/utils'
import DiscardDialog from '@sweetspot/club-portal-legacy/Partials/Dialogs/DiscardDialog/DiscardDialog'
import SpinnerLoader from '@sweetspot/club-portal-legacy/components/SpinnerLoader/SpinnerLoader'
import { SpacePayload } from '@sweetspot/club-portal-legacy/hooks/types'

import DetailsForm from './Details/DetailsForm'
import { useManageAcademySpacesContext } from '../ManageAcademySpacesProvider'
import { SpaceSideBarMode } from '../types'

const CreateSpace = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>()
  const [floor, setFloor] = useState<number>()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {
    selectedAcademy,
    openSpaceSideBar,
    toggleSpaceSideBar,
    refetchSpaces,
    openDiscardDialog,
    setOpenDiscardDialog,
    checkForErrors,
    createSpace,
    spaceSideBarMode,
  } = useManageAcademySpacesContext()

  const dirtify = useCallback(<T,>(setter: (value: T) => void, value: T) => {
    setter(value)
    setIsDirty(true)
  }, [])

  const handleClose = useCallback(() => {
    if (isDirty) {
      setOpenDiscardDialog(true)
      return
    }
    setOpenDiscardDialog(false)
    toggleSpaceSideBar(SpaceSideBarMode.CREATE)
  }, [setOpenDiscardDialog, toggleSpaceSideBar, isDirty])

  const handleCreateSpace = useCallback(async () => {
    if (!selectedAcademy?.uuid) {
      return
    }

    const payload: SpacePayload = {
      course: selectedAcademy?.uuid,
      name,
      floor,
      description,
    }

    const errorText = checkForErrors?.(payload)
    if (errorText) {
      addToast(t(errorText), { appearance: 'error' })
    } else {
      setIsLoading(true)
      createSpace?.(payload)
        ?.then(() => {
          setIsLoading(false)
          addToast(t('toast.createSpaceSuccess'), { appearance: 'success' })
          setIsDirty(false)
          refetchSpaces()
          toggleSpaceSideBar()
        })
        ?.catch(() => {
          setIsLoading(false)
          setIsDirty(false)
        })
    }
  }, [
    addToast,
    name,
    description,
    floor,
    refetchSpaces,
    selectedAcademy?.uuid,
    toggleSpaceSideBar,
    t,
    checkForErrors,
    createSpace,
  ])

  return (
    <Sheet
      open={spaceSideBarMode === SpaceSideBarMode.CREATE && openSpaceSideBar}
      onOpenChange={handleClose}
    >
      <SheetContent className={cn(isLoading && 'overflow-hidden')}>
        {isLoading && (
          <SpinnerLoader
            className="fixed left-[calc(100%-384px)]"
            text={t('sentences.creatingSpace')}
            description={t('sentences.creatingSpaceDescription')}
            isOverlay
          />
        )}
        <SheetHeader>
          <SheetHeaderLeftIcon onClick={handleClose}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>{t('sentences.createSpace_one')}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-6">
          <DetailsForm
            name={name}
            setName={(name) => dirtify<string>(setName, name)}
            description={description}
            setDescription={(description) => dirtify<string>(setDescription, description)}
            floor={floor}
            setFloor={(floor) => dirtify<number>(setFloor, floor)}
          />
        </SheetCustomContent>
        <SheetFooter>
          <Button
            onClick={handleCreateSpace}
            className="min-h-touch-height-lg w-full"
            disabled={!isDirty}
          >
            {t('create')}
          </Button>
        </SheetFooter>
      </SheetContent>
      <DiscardDialog
        open={openDiscardDialog}
        onClose={() => setOpenDiscardDialog(false)}
        onDiscard={() => {
          setOpenDiscardDialog(false)
          toggleSpaceSideBar(SpaceSideBarMode.CREATE)
        }}
        onSave={() => {
          setOpenDiscardDialog(false)
          handleCreateSpace()
        }}
      />
    </Sheet>
  )
}

export default CreateSpace
