import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  getBalanceAccount,
  getLegalEntity,
} from '@sweetspot/sweetspot-js/features/billingSettings/services/api-platform'
import { useQuery } from 'react-query'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'

import { COUNTRY_OPTIONS } from '../../consts'
import { getCapitilizeWord, getNextPaymentDue } from '../../utils'
import { priceToLocal } from '@sweetspot/shared/util/functions'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'
import { EnitityDetailsTable } from './EntityDetailsTable'
import { BillingsTable } from './BillingsTable'
import { QUERY_KEYS } from '@sweetspot/shared/util/constants'
import { AccountHolder, BalanceAccountData, EntityData, VerificationStatus } from '../../types'
import { LoadingView } from '../shared/Loading'

interface AccountHolderPageProps {
  accountHolder: AccountHolder
  setForceSweepsDisabled: Dispatch<SetStateAction<boolean>>
}
export const AccountHolderPage = ({
  accountHolder,
  setForceSweepsDisabled,
}: AccountHolderPageProps) => {
  const { t } = useTranslation()
  const [clubCurrency] = useClubCurrency()

  const golfClub = useSelector(({ golfClub }: RootState) =>
    golfClub?.list.find((club) => club?.id === golfClub?.selectedId)
  )

  const [verificationStatus, setVerificationStatus] = useState<VerificationStatus>(
    VerificationStatus.INVALID
  )

  const handleVerificationStatus = (status: string) => {
    setVerificationStatus(
      {
        valid: VerificationStatus.VALID,
        pending: VerificationStatus.PENDING,
        rejected: VerificationStatus.REJECTED,
        invalid: VerificationStatus.INVALID,
      }[status] || VerificationStatus.INVALID
    )
  }

  const { data: entityData, isLoading: isLoadingEntity } = useQuery<EntityData | null>(
    [golfClub?.id],
    async () => {
      const { legal_entity } = await getLegalEntity(golfClub?.id)
      const { sendToTransferInstrument } = legal_entity?.capabilities || {}
      const isTransferInstrumentAllowed = sendToTransferInstrument?.allowed
      handleVerificationStatus(sendToTransferInstrument?.verificationStatus)
      setForceSweepsDisabled(!isTransferInstrumentAllowed)

      return {
        name:
          legal_entity?.type === 'individual'
            ? legal_entity.individual?.name?.firstName
            : legal_entity.organization?.legalName,
        email: legal_entity?.individual?.email || legal_entity?.organization?.email || '-',
        type: legal_entity?.type === 'individual' ? 'Individual' : 'Business',
        address:
          COUNTRY_OPTIONS.find(
            (country) =>
              country.id ===
              (legal_entity?.individual?.residentialAddress?.country ||
                legal_entity?.organization?.registeredAddress?.country)
          )?.name || '-',
      }
    },
    { enabled: !!accountHolder }
  )

  const { data: adyenAccountBalanceData, isLoading: isLoadingBalanceAccount } = useQuery<{
    tableData: BalanceAccountData[]
    totalBalance: number
  }>(
    [QUERY_KEYS.BALANCE_ACCOUNTS, golfClub?.id, verificationStatus],
    async () => {
      const { balance_account } = await getBalanceAccount(golfClub?.id)
      let totalBalance = 0

      const tableData = balance_account.map((ba) => {
        const { id, type, balances, sweep } = ba
        totalBalance += balances[0].balance

        return {
          id,
          name: getCapitilizeWord(type, true),
          payout_frequency: sweep
            ? getCapitilizeWord(t(`words.${sweep.schedule_type}`), false)
            : '-',
          schedule_type: sweep?.schedule_type || '-',
          next_payment_due: getNextPaymentDue(verificationStatus, sweep?.schedule_type || null),
          trigger_amount: sweep ? priceToLocal(sweep.trigger_amount, clubCurrency, true) : '-',
          min_amount: sweep ? priceToLocal(sweep.target_amount, clubCurrency, true) : '-',
          total_amount: priceToLocal(balances[0].balance, clubCurrency, true),
        }
      })
      return { tableData, totalBalance }
    },
    { enabled: !!accountHolder }
  )

  return (
    <div className="flex w-full flex-col">
      {isLoadingEntity || isLoadingBalanceAccount ? (
        <LoadingView />
      ) : (
        <div>
          <h3 className="mb-4 text-sm font-bold">{t('settings.billing.accountHolder')}</h3>
          {entityData && (
            <EnitityDetailsTable
              verificationStatus={verificationStatus}
              entityData={entityData}
              totalBalance={adyenAccountBalanceData?.totalBalance ?? 0}
            />
          )}

          {adyenAccountBalanceData?.tableData?.length && (
            <BillingsTable data={adyenAccountBalanceData?.tableData} />
          )}
        </div>
      )}
    </div>
  )
}
