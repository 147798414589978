import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  EmptyState,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'
import { cn } from '@sweetspot/scramble-ds/utils'

import { PricesTabs } from './PricesTabs'
import { useBallPricingContext } from '../../BallPricingProvider'

type PricesTabProps = {
  handleOnOpenBucketConfiguration: () => void
}

const PricesTab = ({ handleOnOpenBucketConfiguration }: PricesTabProps) => {
  const { t } = useTranslation()
  const {
    bucketConfiguration: { getBucketConfiguration, isFetching, isFetched },
    priceModel: {
      getPriceModelTabs,
      priceModelId,
      hasPriceModel,
      isPriceModelError,
      isPriceModelFetching,
      priceModel,
    },
  } = useBallPricingContext()

  useEffect(() => {
    getBucketConfiguration?.()
    getPriceModelTabs?.()
  }, [getBucketConfiguration, getPriceModelTabs])

  const isLoading = useMemo(
    () => (!isFetched && isFetching) || isPriceModelFetching,
    [isFetching, isFetched, isPriceModelFetching]
  )

  const noPriceModel = useMemo(
    () => !priceModelId || !hasPriceModel || isPriceModelError,
    [priceModelId, hasPriceModel, isPriceModelError]
  )

  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <EmptyState
          key="price-tab-loading"
          iconName="fa-light fa-flip-both fa-loader bg-background-mono-lighter mb-4 flex rounded-full p-4"
          loading
        >
          <EmptyStateTitle>{t('words.loading')}</EmptyStateTitle>
        </EmptyState>
      </div>
    )
  }

  if (noPriceModel) {
    return (
      <div className="bg-background-base-neutral border-border-stroke-clean mb-4 flex h-full w-full items-center justify-center rounded-sm border">
        <EmptyState
          key="price-tab-empty"
          iconName="fa-regular fa-table"
          className={cn(
            '[&_i]:bg-background-mono-lighter [&_i]:mb-4 [&_i]:flex [&_i]:rounded-full [&_i]:p-[22px]'
          )}
        >
          <EmptyStateTitle>{t('sentences.noPricesAvailableTitle')}</EmptyStateTitle>
          <EmptyStateDescription>{t('sentences.noPricesAvailableText')}</EmptyStateDescription>
          <EmptyStateButton
            children={t('sentences.configureBallBuckets')}
            onClick={handleOnOpenBucketConfiguration}
            variant="primary"
          />
        </EmptyState>
      </div>
    )
  }

  return (
    <div className="flex h-full flex-1 flex-col gap-4">
      {Object.keys(priceModel || {})?.map?.((nrOfBalls) => (
        <div>
          <div className="px-md py-sm bg-background-mono-darker text-text-light">
            {nrOfBalls} {t('words.balls')}
          </div>
          <PricesTabs data={priceModel?.[parseInt(nrOfBalls)]} />
        </div>
      ))}
    </div>
  )
}

export default PricesTab
