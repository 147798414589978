import { useRef } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useToasts } from 'react-toast-notifications'

import {
  setTeeTimes,
  updateTeeTimes,
} from '@sweetspot/sweetspot-js/features/teeTimes/services/api-platform'
import { BayTypeV2, Period } from '@sweetspot/shared/types'
import { GolfCourseItem } from '@sweetspot/club-portal-legacy/store/types'
import {
  PeriodPricingTableData,
  PriceFormState,
  PriceTableDataFlatSlot,
} from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/types'
import { useTranslation } from 'react-i18next'

type UsePeriodsPricingMutationsProps = {
  selectedPeriod: Period | null
  setSelectedPeriod: (period: Period) => void
  currentCourse: GolfCourseItem | null
  bays: BayTypeV2[]
  setActivePeriodTab: (tab: number) => void
  setSelectedBayIds: (bayIds: string[]) => void
  setIsUpdating: (isUpdating: boolean) => void
  refetchPeriods: () => void
  refetchTeeTimes: () => void
}

const usePeriodsPricingMutations = ({
  selectedPeriod,
  setSelectedPeriod,
  currentCourse,
  setActivePeriodTab,
  setSelectedBayIds,
  setIsUpdating,
  bays,
  refetchPeriods,
  refetchTeeTimes,
}: UsePeriodsPricingMutationsProps) => {
  const formStateRef = useRef<Record<string, PriceFormState>>()
  const queryClient = useQueryClient()
  const { addToast } = useToasts()
  const { t } = useTranslation()

  const onSuccess = () => {
    const allTableData = JSON.parse(localStorage.getItem('ALL_TABLE_DATA') || '{}')

    const newPeriod = { ...(selectedPeriod || {}) }
    newPeriod.period_price_status = newPeriod.period_price_status || 'all_period_price_set'
    setSelectedPeriod(newPeriod as Period)

    refetchPeriods()

    if (formStateRef.current && Object.keys(formStateRef.current).length && selectedPeriod) {
      Object.entries(formStateRef.current).forEach(([space, { week, isAllChanges }]) => {
        delete allTableData[selectedPeriod.id]?.[week]?.[space]
        if (isAllChanges || Object.keys(allTableData[selectedPeriod.id][week]).length === 0) {
          delete allTableData[selectedPeriod.id][week]
        }
        if (Object.keys(allTableData[selectedPeriod.id]).length === 0) {
          delete allTableData[selectedPeriod.id]
        }
      })

      addToast(t('toast.pricingAppliedSuccess'), { appearance: 'success' })
    } else if (selectedPeriod) {
      delete allTableData[selectedPeriod.id]
      setSelectedBayIds([bays?.[0]?.id])
      setIsUpdating(true)
      addToast(t('toast.pricingAppliedSuccess'), { appearance: 'success' })
    }
    setActivePeriodTab(1)
    queryClient.invalidateQueries({ queryKey: ['TEE_TIMES'] })
    refetchTeeTimes()
    localStorage.setItem('ALL_TABLE_DATA', JSON.stringify(allTableData))
    setIsUpdating(false)
  }

  const onError = () => {
    if (formStateRef.current) {
      addToast('updateTeeTimesError', { appearance: 'error' })
    } else {
      addToast('setTeeTimesError', { appearance: 'error' })
    }
    setIsUpdating(false)
  }

  const onSetTeeTimesError = () => {
    addToast('setTeeTimesNotReadyError', { appearance: 'error' })
    setIsUpdating(false)
  }

  const updateTeeTimesMutation = useMutation(
    (data: PriceTableDataFlatSlot[]) => updateTeeTimes({ data }),
    {
      onSuccess,
      onError,
    }
  )

  const setTeeTimesMutation = useMutation(
    (payload: { club: string; data: PeriodPricingTableData['data'] }) =>
      setTeeTimes(selectedPeriod?.uuid || '', payload),
    {
      onSuccess,
      onError: onSetTeeTimesError,
      enabled: selectedPeriod?.uuid,
    }
  )

  const updateTeeTimesHandler = async (
    formState: Record<string, PriceFormState>,
    slots: PriceTableDataFlatSlot[]
  ) => {
    setIsUpdating(true)
    formStateRef.current = { ...formState }
    updateTeeTimesMutation.mutate(slots, {
      onSuccess: () => {
        slots.splice(0)
      },
    })
  }

  const setTeeTimesHandler = async (tableData: PeriodPricingTableData['data']) => {
    if (!currentCourse) {
      return
    }
    setIsUpdating(true)
    const data = JSON.parse(JSON.stringify(tableData))
    delete data.hasChanged
    const payload = {
      club: currentCourse.club.uuid,
      data,
    }

    setTeeTimesMutation.mutate(payload)
  }

  return { updateTeeTimes: updateTeeTimesHandler, setTeeTimes: setTeeTimesHandler }
}

export default usePeriodsPricingMutations
