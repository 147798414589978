import { useIsMobile } from '../../../../../hooks/useIsMobile'
import { useResourcesStore } from '../../../../../hooks/resources/useResourcesStore'
import ResourceActionItems from './ResourceActionItems'
import ResourcesActionItems from './ResourcesActionItems'

const ActionItems = () => {
  const isMobile = useIsMobile()
  const { selectedResource } = useResourcesStore()

  return isMobile && selectedResource ? <ResourceActionItems /> : <ResourcesActionItems />
}

export default ActionItems
