import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useSelector } from 'react-redux'

import { syncTeesFromGit } from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'

const useTeeSyncMutation = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const currentClubId = useSelector((state: RootState) => state.golfClub.selectedId)

  const syncTeesMutation = useMutation(() => syncTeesFromGit(currentClubId), {
    onSuccess: () => {
      addToast(t('toast.syncTeesSuccess'), { appearance: 'success' })
    },
    onError: () => {
      addToast(t('toast.syncTeesError'), { appearance: 'error' })
    },
  })

  return syncTeesMutation
}

export default useTeeSyncMutation
