import { useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Button } from '@sweetspot/scramble-ds/atoms'
import {
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputLeadingContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  ListItemTrailing,
} from '@sweetspot/scramble-ds'
import { VenuesIcon } from '@sweetspot/club-portal/feature/venues'
import { GolfCourse, GolfCourseTypes } from '@sweetspot/shared/types'

import { useManageAcademyContext } from '../ManageAcademyProvider'

const AcademySelector = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { academies, selectedAcademy, setSelectedAcademy, toggleCreateAcademySideBar } =
    useManageAcademyContext()

  useEffect(() => {
    if (!!setSelectedAcademy && !selectedAcademy && academies?.length) {
      setSelectedAcademy(academies[0])
    }
  }, [academies, selectedAcademy, setSelectedAcademy])

  // set selectedAcademy based on hash
  useEffect(() => {
    const hash = history.location.hash
    const selected = academies.find((academy) => `#${academy.id}` === hash)
    if (setSelectedAcademy && selected) {
      setSelectedAcademy(selected)
    }
  }, [history.location.hash, academies, setSelectedAcademy])

  const handleValueChange = useCallback(
    (newValue: string) => {
      if (newValue === 'new') {
        toggleCreateAcademySideBar()
        return
      }
      const selected = academies.find((academy: GolfCourse) => `${academy.id}` === newValue)
      if (setSelectedAcademy && selected) {
        const path = history.location.pathname
        history.push(`${path}#${newValue}`)
      }
    },
    [academies, setSelectedAcademy, toggleCreateAcademySideBar, history]
  )

  const value = useMemo(
    () => academies.find((academy) => academy.id === selectedAcademy?.id)?.name,
    [academies, selectedAcademy?.id]
  )

  return (
    <InputSelect value={value} onValueChange={handleValueChange}>
      <InputBase>
        <InputLabelContainer className="mb-0.5">{t('words.academy')}</InputLabelContainer>
        <InputSelectTrigger asChild>
          <InputContainer className="cursor-pointer">
            <InputLeadingContainer>
              <VenuesIcon courseType={GolfCourseTypes.PRO} type="default" />
            </InputLeadingContainer>
            <InputSelectStatefulInput
              value={value}
              onChange={() => null}
              className="text-content-base text-ellipsis px-10 "
              readOnly
            />
            <InputSelectTrailingContainer />
          </InputContainer>
        </InputSelectTrigger>
      </InputBase>

      <InputSelectContent>
        <InputSelectContentView>
          {academies?.map((academy) => (
            <InputSelectItem key={academy.id} value={`${academy.id}`}>
              <ListItem className="ml-4 h-full w-[376px] justify-center gap-2">
                <ListItemMainContent className="flex-row justify-between pr-2">
                  <ListItemParagraph className="text-content-base">
                    {academy.name}
                  </ListItemParagraph>
                  <ListItemTrailing>
                    {selectedAcademy?.id === academy.id && (
                      <i className="fa-regular fa-check h-4 w-4" />
                    )}
                  </ListItemTrailing>
                </ListItemMainContent>
              </ListItem>
            </InputSelectItem>
          ))}
        </InputSelectContentView>
        <InputSelectContentView className="mt-0.5 min-h-12">
          <InputSelectItem value="new" className="flex justify-center">
            <Button
              variant="link-dark"
              className="text-content-lg text-text-dark h-12 w-full font-medium hover:no-underline focus:no-underline"
            >
              <i className="fa-regular fa-circle-plus " />
              {t('words.new')}
            </Button>
          </InputSelectItem>
        </InputSelectContentView>
      </InputSelectContent>
    </InputSelect>
  )
}

export default AcademySelector
