import { Resource } from './types'

export const resources: Partial<Resource>[] = [
  {
    resourceId: '1',
    name: 'Resource 1',
    organizationId: '1',
    userId: '1',
    createdAt: '2025-03-06T00:00:00.000Z',
    lastModifiedAt: '2025-03-06T10:26:00.000Z',
    lastModifiedBy: 'Tobias Sernhede',
    editHistory: [
      {
        actionType: 'Created',
        actor: 'Tobias Sernhede',
        timestamp: '2025-03-06T00:00:00.000Z',
      },
      {
        actionType: 'Updated',
        actor: 'Tobias Sernhede',
        timestamp: '2025-03-06T10:26:00.000Z',
      },
    ],
  },
  {
    resourceId: '2',
    name: 'Resource 2',
    organizationId: '1',
    userId: '1',
    createdAt: '2025-03-05T00:00:00.000Z',
    lastModifiedAt: '2025-03-05T21:13:00.000Z',
    lastModifiedBy: 'Tobias Sernhede',
    editHistory: [
      {
        actionType: 'Created',
        actor: 'Tobias Sernhede',
        timestamp: '2025-03-05T00:00:00.000Z',
      },
      {
        actionType: 'Updated',
        actor: 'Tobias Sernhede',
        timestamp: '2025-03-05T21:13:00.000Z',
      },
    ],
  },
  {
    resourceId: '3',
    name: 'Resource 3',
    organizationId: '1',
    userId: '1',
    createdAt: '2025-03-04T00:00:00.000Z',
    lastModifiedAt: '2025-03-05T22:32:00.000Z',
    lastModifiedBy: 'Tobias Sernhede',
    editHistory: [],
  },
]
