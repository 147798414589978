import { useEffect, useState } from 'react'
import { Badge, Divider } from '@sweetspot/scramble-ds/atoms'
import LanguageSelect from '@sweetspot/club-portal-legacy/pages/Settings/components/LanguageSelect'
import { getLocalStorage, setLocalStorage } from '@sweetspot/shared/util/local-storage'

const UNIVERSITY_LINK = 'https://university.sweetspot.io/'
const HELP_DESK = 'https://intercom.help/sweetspot/sv/'
const RELEASE_NOTES = 'https://release-notes.sweetspot.io/'

const ButtonGroup = () => {
  const [showReleaseNotes, setReleaseNotes] = useState(false)
  const isReleaseNoteClicked = getLocalStorage('release-note-clicked')

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date()
      // Check if it's Thursday 12:00 UTC
      if (now.getUTCDay() === 4 && now.getUTCHours() === 12) {
        if (isReleaseNoteClicked === 'true') setLocalStorage('release-note-clicked', 'false')
        setReleaseNotes(true)
      } else {
        setReleaseNotes(false)
      }
    }, 60000) // Check every minute

    return () => {
      clearInterval(interval)
    }
  }, [])

  const hanldeReleaseNoteClick = () => {
    setLocalStorage('release-note-clicked', 'true')
  }

  return (
    <div className="z-50 mr-4 flex items-center justify-center gap-4">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={RELEASE_NOTES}
        aria-label="Release Notes"
        onClick={hanldeReleaseNoteClick}
      >
        <div className="relative p-[5px]">
          <i className="fa-light fa-megaphone text-xl" />
          {showReleaseNotes && isReleaseNoteClicked === 'false' && (
            <Badge className="absolute right-0 top-[1px] h-2 w-2" />
          )}
        </div>
      </a>
      <Divider orientation="vertical" className="h-6" />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={UNIVERSITY_LINK}
        className="mr-2"
        aria-label="Sweetspot University"
      >
        <i className="fa-light fa-graduation-cap text-xl" />
      </a>

      <a target="_blank" rel="noopener noreferrer" href={HELP_DESK} aria-label="Sweetspot Help">
        <i className="fa-light fa-circle-question text-xl" />
      </a>

      <LanguageSelect />
    </div>
  )
}
export default ButtonGroup
