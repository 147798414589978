import { Route, Switch, useRouteMatch } from 'react-router'
import { FailedPayments } from './FailedPayments'
import { MembershipImport } from './MembershipImport'
import { MembershipSignupsGrid } from './MembershipSignupsGrid'
import { MembershipsTable } from './MembershipsTable'

const Memberships = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/failed-payments`} component={FailedPayments} />
      <Route
        exact
        path={`${path}/import-members/start-membership-import`}
        component={MembershipImport}
      />
      <Route path={`${path}/membership-signups`} component={MembershipSignupsGrid} />
      <Route path={`${path}`} component={MembershipsTable} />
    </Switch>
  )
}

export { Memberships }
