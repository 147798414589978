import { useTranslation } from 'react-i18next'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'
import { priceToLocal } from '@sweetspot/shared/util/functions'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@sweetspot/scramble-ds'
import { EntityData, VerificationStatus } from '../../types'

interface EnitityDetailsTableProps {
  verificationStatus: VerificationStatus
  entityData: EntityData
  totalBalance: number
}

export const EnitityDetailsTable = ({
  verificationStatus,
  entityData,
  totalBalance,
}: EnitityDetailsTableProps) => {
  const [currencyCode] = useClubCurrency()
  const { t } = useTranslation()

  return (
    <div className="border-border-stroke-clean mb-6 rounded-md border-2 p-2 pb-3">
      <Table>
        <TableHeader>
          <TableRow className="cursor-default !border-0">
            <TableHead className="bg-white font-bold uppercase">
              {t('sentences.accountType')}
            </TableHead>
            <TableHead className="bg-white font-bold uppercase">{t('words.country')}</TableHead>
            <TableHead className="bg-white font-bold uppercase">
              {t('sentences.legalName')}
            </TableHead>
            <TableHead className="bg-white font-bold uppercase">{t('words.email')}</TableHead>
            <TableHead className="bg-white font-bold uppercase">
              {t('sentences.payoutStatus')}
            </TableHead>
            <TableHead className="bg-white font-bold uppercase">
              {t('sentences.totalBalance')}
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow className="cursor-default !border-0 hover:bg-white">
            <TableCell>{entityData.type}</TableCell>
            <TableCell>{entityData.address}</TableCell>
            <TableCell>{entityData.name}</TableCell>
            <TableCell>{entityData.email}</TableCell>
            <TableCell>{verificationStatus}</TableCell>
            <TableCell>
              {totalBalance ? priceToLocal(totalBalance, currencyCode, true) : '-'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}
