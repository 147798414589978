import { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import {
  ListItem,
  ListItemLeading,
  ListItemMainContainer,
  ListItemMainContent,
  ListItemMainLabel,
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetHeaderRightIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { cn } from '@sweetspot/scramble-ds/utils'
import { Button, Popover } from '@sweetspot/scramble-ds/atoms'
import DiscardDialog from '@sweetspot/club-portal-legacy/Partials/Dialogs/DiscardDialog/DiscardDialog'
import SpinnerLoader from '@sweetspot/club-portal-legacy/components/SpinnerLoader/SpinnerLoader'
import DeleteCourseDialog from '@sweetspot/club-portal-legacy/Partials/Dialogs/DeleteCourseDialog/DeleteCourseDialog'

import DetailsForm from './Details/DetailsForm'
import { useManageSimulatorSpacesContext } from '../ManageSimulatorSpacesProvider'
import { SpaceSideBarMode } from '../types'

const UpdateSpace = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const {
    selectedSimulator,
    openSpaceSideBar,
    selectedSpace,
    toggleSpaceSideBar,
    refetchSpaces,
    setSelectedSpace,
    openDiscardDialog,
    setOpenDiscardDialog,
    updateSpace,
    checkForErrors,
    deleteSpace,
    spaceSideBarMode,
  } = useManageSimulatorSpacesContext()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)

  const [name, setName] = useState<string>(selectedSpace?.name || '')
  const [description, setDescription] = useState<string | undefined>(selectedSpace?.description)
  const [floor, setFloor] = useState<number>(
    selectedSpace?.floor !== undefined ? selectedSpace.floor : 1
  )

  useEffect(() => {
    setName(selectedSpace?.name || '')
    setDescription(selectedSpace?.description)
    setFloor(selectedSpace?.floor !== undefined ? selectedSpace.floor : 1)
  }, [selectedSpace])

  const dirtify = useCallback(<T,>(setter: (value: T) => void, value: T) => {
    setter(value)
    setIsDirty(true)
  }, [])

  const handleClose = useCallback(() => {
    if (isDirty) {
      setOpenDiscardDialog(true)
      return
    }
    setOpenDiscardDialog(false)
    toggleSpaceSideBar(SpaceSideBarMode.CREATE)
  }, [setOpenDiscardDialog, toggleSpaceSideBar, isDirty])

  const handleUpdateSpace = useCallback(async () => {
    if (!selectedSimulator?.id) {
      return
    }

    const payload = {
      name,
      floor,
      description,
    }

    const errorText = checkForErrors?.(payload)
    if (errorText) {
      addToast(t(errorText), { appearance: 'error' })
    } else {
      setIsLoading(true)
      updateSpace?.(payload)
        ?.then(() => {
          setIsLoading(false)
          addToast(t('toast.updateSpaceSuccess'), { appearance: 'success' })
          setIsDirty(false)
          refetchSpaces()
          setSelectedSpace()
          toggleSpaceSideBar()
        })
        ?.catch(() => {
          setIsLoading(false)
          setIsDirty(false)
        })
    }
  }, [
    selectedSimulator?.id,
    name,
    description,
    floor,
    refetchSpaces,
    toggleSpaceSideBar,
    addToast,
    t,
    checkForErrors,
    updateSpace,
    setSelectedSpace,
  ])

  const handleDeleteSpace = useCallback(async () => {
    if (selectedSpace?.uuid) {
      deleteSpace(selectedSpace.uuid).then(() => {
        setOpenDeleteDialog(false)
        handleClose()
        addToast(t('sentences.spaceDeleted'), { appearance: 'success' })
        // we need to wait a bit before refetching spaces
        // beacuse the space needs some time to be deleted
        setTimeout(() => {
          refetchSpaces()
        }, 2000)
      })
    }
  }, [selectedSpace?.uuid, deleteSpace, addToast, t, refetchSpaces, handleClose])

  return (
    <Sheet
      open={spaceSideBarMode === SpaceSideBarMode.EDIT && openSpaceSideBar}
      onOpenChange={handleClose}
    >
      <SheetContent className={cn(isLoading && 'overflow-hidden')}>
        {isLoading && (
          <SpinnerLoader
            className="fixed left-[calc(100%-384px)]"
            text={t('sentences.updatingSpace')}
            description={t('sentences.updatingSpaceDescription')}
            isOverlay
          />
        )}
        <SheetHeader>
          <SheetHeaderLeftIcon onClick={handleClose}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>{t('settings.spaces.editSpace')}</SheetTitle>
          <SheetHeaderRightIcon>
            <Popover.Root open={isDropdownOpen}>
              <Popover.Trigger asChild>
                <div
                  className="cursor-pointer px-4"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <i className="fa-regular fa-ellipsis" />
                </div>
              </Popover.Trigger>
              <Popover.Content
                align="end"
                className="z-50 w-fit p-2 drop-shadow"
                onInteractOutside={() => setIsDropdownOpen(false)}
              >
                <ListItem
                  className="w-full min-w-fit cursor-pointer gap-5 px-2 py-3"
                  onClick={() => setOpenDeleteDialog(true)}
                >
                  <ListItemLeading className="px-0">
                    <i className="fa-regular fa-trash-can" />
                  </ListItemLeading>
                  <ListItemMainContainer>
                    <ListItemMainContent className="py-0">
                      <ListItemMainLabel className="h-auto min-h-6 font-bold">
                        {t('sentences.deleteSpace')}
                      </ListItemMainLabel>
                    </ListItemMainContent>
                  </ListItemMainContainer>
                </ListItem>

                <Popover.Arrow className="fill-white" width={16} height={10} />
              </Popover.Content>
            </Popover.Root>
          </SheetHeaderRightIcon>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-6">
          <DetailsForm
            name={name}
            setName={(name) => dirtify<string>(setName, name)}
            description={description}
            setDescription={(description) => dirtify<string>(setDescription, description)}
            floor={floor}
            setFloor={(floor) => dirtify<number>(setFloor, floor)}
          />
        </SheetCustomContent>

        <SheetFooter>
          <Button
            onClick={handleUpdateSpace}
            className="min-h-touch-height-lg w-full"
            disabled={!isDirty}
          >
            {t('sentences.updateSpace')}
          </Button>
        </SheetFooter>
      </SheetContent>
      <DiscardDialog
        open={openDiscardDialog}
        onClose={() => setOpenDiscardDialog?.(false)}
        onDiscard={() => {
          setOpenDiscardDialog(false)
          toggleSpaceSideBar(SpaceSideBarMode.EDIT)
        }}
        onSave={() => {
          setOpenDiscardDialog(false)
          handleUpdateSpace()
        }}
      />
      <DeleteCourseDialog
        courseText={t('words.space_one').toLowerCase()}
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        onDelete={handleDeleteSpace}
      />
    </Sheet>
  )
}

export default UpdateSpace
