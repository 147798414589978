import { DataTable } from '@sweetspot/scramble-ds'
import { columns } from './columns'
import { useState } from 'react'
import { BalancesModal } from './BalancesModal'
import { updateSweep } from '@sweetspot/sweetspot-js/features/billingSettings/services/api-platform'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import useRoles from '@sweetspot/sweetspot-js/common/hooks/useRoles'
import { parseToNumber } from '../../utils'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'
import { useSelector } from 'react-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import { useQueryClient } from 'react-query'
import { QUERY_KEYS } from '@sweetspot/shared/util/constants'
import { BalanceAccountData } from '../../types'

interface BillingsTableProps {
  data: BalanceAccountData[]
}

export const BillingsTable = ({ data }: BillingsTableProps) => {
  const [rowData, setRowData] = useState<BalanceAccountData | null>(null)
  const [modalState, setModalState] = useState<boolean>(false)
  const [payoutFrequency, setPayoutFrequency] = useState<string | undefined>(undefined)
  const [triggerAmount, setTriggerAmount] = useState<number>(0)
  const [minAmount, setMinAmount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const { addToast } = useToasts()
  const { t } = useTranslation()
  const roles = useRoles()
  const [clubCurrency] = useClubCurrency()

  const golfClub = useSelector(({ golfClub }: RootState) =>
    golfClub?.list.find((club) => club?.id === golfClub?.selectedId)
  )

  const queryClient = useQueryClient()

  const handleRowClick = (rowId: string | number) => {
    if (roles.includes('ROLE_SA') && rowId) {
      const selectedRow = data.find((item) => item.id === rowId)
      if (selectedRow) {
        setRowData(selectedRow)
        setModalState(true)

        const { payout_frequency, trigger_amount, min_amount } = selectedRow
        const parsedToNumberTA = parseToNumber(trigger_amount)
        const parsedToNumberMA = parseToNumber(min_amount)

        setPayoutFrequency(payout_frequency)
        setTriggerAmount(parsedToNumberTA)
        setMinAmount(parsedToNumberMA)
      }
    }
  }

  const handleFrequncyChange = (frequency: string) => setPayoutFrequency(frequency)

  const validateInputValue = (number: string | number) => {
    const regExLeadingZeros = /^0[0-9].*$/
    return !regExLeadingZeros.test(number.toString())
  }

  const handleTriggerAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    if (value === '') {
      setTriggerAmount(value)
      return
    }

    const triggerValue = Number(value)

    if (triggerValue >= 0 && validateInputValue(triggerValue)) {
      setTriggerAmount(triggerValue)
    }
  }

  const handleMinAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    if (value === '') {
      setMinAmount(value)
      return
    }

    const minValue = Number(value)

    if (minValue >= 0 && validateInputValue(minValue)) {
      setMinAmount(minValue)
    }
  }

  const handleSaveBtn = async () => {
    if (!rowData) return

    const frequencyValues = [
      { id: 'daily', name: t('words.daily') },
      { id: 'weekly', name: t('words.weekly') },
      { id: 'monthly', name: t('words.monthly') },
    ]

    const selectedFreq = frequencyValues.find((freq) => freq.name === payoutFrequency)
    if (!selectedFreq) return

    setLoading(true)

    try {
      await updateSweep({
        clubId: golfClub?.id,
        balance_account_id: rowData.id,
        schedule_type: selectedFreq.id,
        trigger_amount: Math.round(triggerAmount * 100),
        target_amount: Math.round(minAmount * 100),
        currency: clubCurrency,
      })
    } catch (error) {
      addToast(t('sentences.failedToSaveChanges'), { appearance: 'error' })
    }

    setLoading(false)
    setModalState(false)
    queryClient.invalidateQueries([QUERY_KEYS.BALANCE_ACCOUNTS])
  }

  const handleCancelBtn = () => {
    setLoading(false)
    setModalState(false)
  }

  return (
    <>
      <DataTable onRowClick={handleRowClick} columns={columns(t)} data={data} hideTableControls />

      {rowData && (
        <BalancesModal
          rowData={rowData}
          triggerAmount={triggerAmount}
          onTriggerChange={handleTriggerAmount}
          minAmount={minAmount}
          onMinChange={handleMinAmount}
          modalState={modalState}
          payoutFrequency={payoutFrequency}
          onFrequncyChange={handleFrequncyChange}
          onSave={handleSaveBtn}
          loading={loading}
          onCancel={handleCancelBtn}
        />
      )}
    </>
  )
}
