import { ReactNode } from 'react'
import {
  ListItem,
  ListItemLeading,
  ListItemMainContent,
  ListItemMainLabel,
} from '@sweetspot/scramble-ds'
import { Check } from '@sweetspot/scramble-ds/atoms'

interface FilterOptionProps {
  icon?: ReactNode
  label: string
  checked: boolean
  onCheckedChange: (checked: boolean) => void
}

const FilterOption = ({ icon, label, checked, onCheckedChange }: FilterOptionProps) => (
  <ListItem className="w-full [&:not(:last-child)]:mb-1">
    <ListItemLeading className="px -0 flex flex-row">
      <Check className="px-0" checked={checked} onCheckedChange={onCheckedChange} />
    </ListItemLeading>
    <ListItemMainContent className="flex flex-row items-center gap-2 py-3">
      {icon}
      <ListItemMainLabel className="text-text-dark content-start">{label}</ListItemMainLabel>
    </ListItemMainContent>
  </ListItem>
)

export default FilterOption
