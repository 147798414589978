import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import OverlayContainer from '@sweetspot/sweetspot-js/common/components/OverlayContainer'
import Text from '@sweetspot/club-portal-legacy/components/Text'

import style from './style.module.scss'
import { Button } from '@sweetspot/scramble-ds/atoms'

/**
 * Takes an array of Text ids and numbers and returns a div with the text displayed correctly
 * E.g. "Mark 10 players as paid?"
 */
const renderArrayOfText = (array, number, isInline) => {
  return (
    <div className={classnames(style.displayInline, { [style.inline]: isInline })}>
      {array.map((word, key) => {
        if (word === 'replaceByNumber') return <div key={key++}>&nbsp;{number}&nbsp;</div>
        else if (word.charAt(0) === '@')
          return (
            <div key={key++}>
              <b>{word.slice(1)}</b>&nbsp;
            </div>
          )
        else
          return (
            <React.Fragment key={key++}>
              <Text textId={word} />
              &nbsp;
            </React.Fragment>
          )
      })}
    </div>
  )
}

const ConfirmWindow = ({
  textId,
  textArray,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  number,
  isInline,
  switchBtnPosition = false,
}) => {
  return (
    <OverlayContainer>
      <div className={style.container}>
        <h5>
          {' '}
          {textArray ? renderArrayOfText(textArray, number, isInline) : <Text textId={textId} />}
        </h5>
        <div className="mt-[50px] flex w-full justify-end gap-[10px]">
          {!switchBtnPosition && (
            <Button size="medium" variant="ghost-dark" onClick={onCancel}>
              <Text className="text-base" textId={cancelText || 'no'} />
            </Button>
          )}
          <Button size="medium" onClick={onConfirm}>
            <Text className="text-base" textId={confirmText || 'yes'} />
          </Button>
          {switchBtnPosition && (
            <Button size="medium" variant="ghost-dark" onClick={onCancel}>
              <Text className="text-base" textId={cancelText || 'no'} />
            </Button>
          )}
        </div>
      </div>
    </OverlayContainer>
  )
}

ConfirmWindow.propTypes = {
  textId: PropTypes.string,
  textArray: PropTypes.array,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isInline: PropTypes.bool,
  number: PropTypes.number,
  switchBtnPosition: PropTypes.bool,
}

export default ConfirmWindow
