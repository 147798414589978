import * as React from 'react'
import { cn } from '../../../utils'
import { Button, ButtonProps } from '../../atoms'

const Section = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div className={cn('bg-background-base-clean h-full w-full', className)} ref={ref} {...props} />
  )
)
Section.displayName = 'Section'

const SectionHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      className={cn(
        'border-border-stroke-clean flex min-h-[60px] w-full flex-row items-center gap-3 border-b px-6 py-[14px]',
        className
      )}
      ref={ref}
      {...props}
    />
  )
)
SectionHeader.displayName = 'SectionHeader'

const SectionTitle = React.forwardRef<HTMLHeadingElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <h3 className={cn('text-text-dark text-content-lg font-bold', className)} ref={ref} {...props}>
      {props.children}
    </h3>
  )
)

SectionTitle.displayName = 'SectionTitle'

const SectionButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        size="medium"
        variant="clear-dark"
        rounding="pill"
        className={cn('hover:!text-text-dark hover:bg-transparent hover:shadow-none', className)}
        {...props}
      />
    )
  }
)

SectionButton.displayName = 'SectionButton'

const SectionActionButtons = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div className={cn('ml-auto flex gap-3', className)} ref={ref} {...props} />
  )
)
SectionActionButtons.displayName = 'SectionActionButtons'

const SectionContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => <div className={cn('w-full', className)} ref={ref} {...props} />
)
SectionContent.displayName = 'SectionContent'

export { Section, SectionTitle, SectionHeader, SectionContent, SectionButton, SectionActionButtons }
