import { useTranslation } from 'react-i18next'

import {
  EmptyStateDescription,
  EmptyState,
  EmptyStateButton,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'
import { CreateStoreSheet } from './CreateStoreSheet'

export const CreateStore = () => {
  const { t } = useTranslation()

  return (
    <div className="flex w-full items-center justify-center">
      <EmptyState
        className="mb-10 max-w-[480px]"
        iconName={`fa-light fa-store bg-background-mono-lighter mb-4 flex rounded-full p-[22px]`}
      >
        <EmptyStateTitle>{t('settings.billing.noStoresCreated')}</EmptyStateTitle>
        <EmptyStateDescription>
          {t('settings.billing.storesWillBeDisplayedHere')}
        </EmptyStateDescription>
        <CreateStoreSheet>
          <EmptyStateButton className="text-content-lg h-touch-height-md font-medium hover:no-underline focus:no-underline">
            {t('settings.billing.createStore')}
          </EmptyStateButton>
        </CreateStoreSheet>
      </EmptyState>
    </div>
  )
}
