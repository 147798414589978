import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'
import { DataTableColumnHeader, TableSortItem } from '@sweetspot/scramble-ds'
import { MembershipCard } from '@sweetspot/shared/types'
import { ColumnDef } from '@tanstack/react-table'
import SubscriptionTooltip from './components/SubscriptionTooltip'
import { Check } from '@sweetspot/scramble-ds/atoms'

export const columns = (
  t: (key: string) => string,
  hasCdhNumber: boolean,
  sortItems: TableSortItem[],
  setSortItems: (items: TableSortItem[]) => void
): ColumnDef<MembershipCard>[] => {
  return [
    {
      id: 'select',
      header: ({ table }) => (
        <Check
          indeterminate={table.getIsSomePageRowsSelected()}
          checked={table.getIsAllPageRowsSelected()}
          onClick={(event) => event.stopPropagation()}
          onCheckedChange={(value: boolean) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
          className="translate-y-[2px] px-0"
        />
      ),
      cell: ({ row }) => (
        <Check
          checked={row.getIsSelected()}
          onClick={(event) => event.stopPropagation()}
          onCheckedChange={(value: boolean) => row.toggleSelected(!!value)}
          aria-label="Select row"
          className="translate-y-[2px] px-0"
        />
      ),
      enableSorting: false,
    },
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} sortItems={sortItems} setSortItems={setSortItems} />
      ),
      meta: { title: t('name') },
      cell: ({ row }) => row.getValue('name'),
    },
    {
      accessorKey: 'email',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('email') },
      cell: ({ row }) => row.getValue('email'),
      enableSorting: false,
    },
    {
      accessorKey: 'phone',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('phone') },
      cell: ({ row }) => row.getValue('phone'),
      enableSorting: false,
    },
    {
      accessorKey: 'golfId',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: hasCdhNumber ? t('cdhNumber') : t('golfId') },
      cell: ({ row }) => row.getValue('golfId'),
      enableSorting: false,
    },
    {
      accessorKey: 'hcp',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} sortItems={sortItems} setSortItems={setSortItems} />
      ),
      meta: { title: t('hcp') },
      cell: ({ row }) => Utilities.displayHCP(row.getValue('hcp')),
    },
    {
      accessorKey: 'gender',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('settings.playerCard.gender') },
      cell: ({ row }) => row.getValue('gender'),
      enableSorting: false,
    },
    {
      accessorKey: 'age',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} sortItems={sortItems} setSortItems={setSortItems} />
      ),
      meta: { title: t('age') },
      cell: ({ row }) => row.getValue('age'),
    },
    {
      accessorKey: 'lockState',
      header: () => <i className="fa fa-lock fa-solid" />,
      meta: { title: <i className="fa fa-lock fa-solid" /> },
      cell: ({ row }) => (
        <div>
          {row.getValue('lockState') ? (
            <i className="fa fa-lock fa-solid" aria-hidden="true" />
          ) : (
            '-'
          )}
        </div>
      ),
      enableSorting: false,
    },
    {
      accessorKey: 'isRegistered',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} sortItems={sortItems} setSortItems={setSortItems} />
      ),
      meta: { title: t('SSUser') },
      cell: ({ row }) => row.getValue('isRegistered'),
    },
    {
      accessorKey: 'membership',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('membershipPassText') },
      cell: ({ row }) => {
        const rawMembership = row.getValue('membership')
        const subscriptionsList = Array.isArray(rawMembership) ? rawMembership : []

        const passes = subscriptionsList?.filter(
          (subscription) => subscription?.membership?.type === 'pass'
        )
        const memberships = subscriptionsList?.filter(
          (subscription) => subscription?.membership?.type === 'membership'
        )

        return (
          <div className="flex gap-1">
            <SubscriptionTooltip subscriptions={memberships} tagIcon="fa-regular fa-badge-check" />
            <SubscriptionTooltip subscriptions={passes} tagIcon="fa-regular fa-ticket-perforated" />
          </div>
        )
      },
      enableSorting: false,
    },
    {
      accessorKey: 'federationMembership',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} sortItems={sortItems} setSortItems={setSortItems} />
      ),
      meta: { title: hasCdhNumber ? t('cdhMembership') : t('gitMembership') },
      cell: ({ row }) => row.getValue('federationMembership'),
    },
    {
      accessorKey: 'homeClub',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} sortItems={sortItems} setSortItems={setSortItems} />
      ),
      meta: { title: t('homeClub') },
      cell: ({ row }) => row.getValue('homeClub'),
    },
    {
      accessorKey: 'playRight',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} sortItems={sortItems} setSortItems={setSortItems} />
      ),
      meta: { title: t('playRight') },
      cell: ({ row }) => row.getValue('playRight'),
    },
    {
      accessorKey: 'numberOfRounds',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} sortItems={sortItems} setSortItems={setSortItems} />
      ),
      meta: { title: t('playerRounds') },
      cell: ({ row }) => row.getValue('numberOfRounds'),
    },
    {
      accessorKey: 'playValue',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} sortItems={sortItems} setSortItems={setSortItems} />
      ),
      meta: { title: t('playValue') },
      cell: ({ row }) => row.getValue('playValue'),
    },
    {
      accessorKey: 'paidValue',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} sortItems={sortItems} setSortItems={setSortItems} />
      ),
      meta: { title: t('paidAmount') },
      cell: ({ row }) => row.getValue('paidValue'),
    },
    {
      accessorKey: 'lastPlayed',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} sortItems={sortItems} setSortItems={setSortItems} />
      ),
      meta: { title: t('lastPlayed') },
      cell: ({ row }) => row.getValue('lastPlayed'),
    },
  ]
}
