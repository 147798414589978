import * as React from 'react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  getFilteredRowModel,
  VisibilityState,
  getFacetedRowModel,
  getFacetedUniqueValues,
  ColumnFiltersState,
  Table as TanstackTable,
} from '@tanstack/react-table'

import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'

import _ from 'lodash'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './Table'
import { DataTableDraggableRow } from './DataTableDraggableRow'
import { DataTableToolbar } from './Toolbar/DataTableToolbar'
import { DataTablePagination } from './Footer/DataTablePagination'
import { TablePagination, TableSortItem } from '../types'

interface DataTableProps<TData extends { id: UniqueIdentifier }, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  extraControls?: (table: TanstackTable<TData>) => React.ReactNode
  inputSearch?: (table: TanstackTable<TData>) => React.ReactNode
  onRowClick?: (rowId: UniqueIdentifier) => void
  pagination?: TablePagination
  enableRowSelection?: boolean
  hideTableControls?: boolean
  onRowMove?: ({ activeId, overId }: { activeId: string | number; overId: string | number }) => void
  defaultColumnVisibility?: VisibilityState
  sortItems?: TableSortItem[]
}

export const DataTable = <TData extends { id: UniqueIdentifier }, TValue>({
  columns,
  data,
  extraControls,
  inputSearch,
  onRowClick,
  onRowMove,
  pagination,
  enableRowSelection = true,
  hideTableControls = false,
  defaultColumnVisibility = {},
  sortItems,
}: DataTableProps<TData, TValue>) => {
  const [tableData, setTableData] = React.useState(data)
  const [columnsState, setColumnsState] = React.useState(columns)
  const dataIds = React.useMemo<UniqueIdentifier[]>(
    () => tableData?.map(({ id }) => id),
    [tableData]
  )

  const [rowSelection, setRowSelection] = React.useState({})
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>(defaultColumnVisibility)
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])

  React.useEffect(() => {
    setColumnsState((prevColumns) => {
      const prevColumnTitles = prevColumns.map((col) => col.meta?.title || '').join(',')
      const newColumnTitles = columns.map((col) => col.meta?.title || '').join(',')
      return prevColumnTitles !== newColumnTitles ? columns : prevColumns
    })
  }, [columns])

  React.useEffect(() => {
    if (sortItems) setColumnsState(columns)
  }, [sortItems])

  React.useEffect(() => {
    setTableData((prevTableData) => {
      const isDataEqual = _.isEqual(prevTableData, data)

      return isDataEqual ? prevTableData : data
    })
  }, [data])

  const isBackendPagination = !!pagination

  const table = useReactTable({
    data: tableData,
    columns: columnsState,
    manualPagination: isBackendPagination,
    state: {
      rowSelection,
      sorting,
      columnVisibility,
      columnFilters,
      ...(isBackendPagination && {
        pagination: {
          pageIndex: pagination?.currentPage || 0,
          pageSize: pagination?.pageSize || 20,
        },
      }),
    },
    initialState: {
      pagination: {
        pageSize: isBackendPagination ? pagination?.pageSize || 20 : 20,
      },
    },
    pageCount: isBackendPagination
      ? Math.ceil((pagination?.totalRows || 0) / (pagination?.pageSize || 20))
      : undefined,
    getRowId: (row) => row.id.toString(),
    enableRowSelection,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })

  const handleDragEnd = (event: DragEndEvent) => {
    if (sorting.length > 0) return

    const { active, over } = event

    if (active && over && active.id !== over.id) {
      setTableData((prevData: TData[]) => {
        const oldIndex = dataIds.indexOf(active.id)
        const newIndex = dataIds.indexOf(over.id)

        if (onRowMove) {
          onRowMove({
            activeId: active.id,
            overId: over.id,
          })
        }

        return arrayMove(prevData, oldIndex, newIndex)
      })
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor)
  )

  return (
    <div>
      {!hideTableControls && (
        <DataTableToolbar
          table={table}
          columns={columnsState}
          setColumns={setColumnsState}
          extraControls={extraControls}
          inputSearch={inputSearch}
        />
      )}
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
                {table.getRowModel().rows.map((row) => (
                  <DataTableDraggableRow key={row.id} row={row} onRowClick={onRowClick} />
                ))}
              </SortableContext>
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </DndContext>
      {!hideTableControls && (
        <DataTablePagination
          table={table}
          isBackendPagination={isBackendPagination}
          onPageChange={pagination?.onPageChange}
          onPageSizeChange={pagination?.onPageSizeChange}
        />
      )}
    </div>
  )
}
