/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react'
import * as SelectPrimitive from '@radix-ui/react-select'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import { Command as CommandPrimitive } from 'cmdk'
import { useStore } from 'zustand'
import { InputTrailingContainer, Input, InputProps } from '../InputBase'
import { InputSelectContext, createInputSelectStore, useInputSelectStoreContext } from './store'

import { cn } from '../../../utils'
import { UniversalProps } from '../types'

type InputSelectRootProps =
  | React.ComponentPropsWithoutRef<typeof SelectPrimitive.Root>
  | React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Root>

const InputSelectRoot = ({ children, ...props }: InputSelectRootProps) => {
  const inputBaseStore = useInputSelectStoreContext()
  const { open, setOpen, withSearch } = useStore(
    inputBaseStore,
    (inputSelectStore) => inputSelectStore
  )

  const RootComponent = withSearch ? PopoverPrimitive.Root : SelectPrimitive.Root

  return (
    <RootComponent open={open} onOpenChange={setOpen} {...props}>
      {children}
    </RootComponent>
  )
}

interface InputSelectProps extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Root> {
  withSearch?: boolean
}

const InputSelect = ({ children, withSearch = false, ...props }: InputSelectProps) => {
  const [store] = React.useState(
    createInputSelectStore({
      withSearch,
    })
  )

  return (
    <InputSelectContext.Provider value={store}>
      <InputSelectRoot {...props}>{children}</InputSelectRoot>
    </InputSelectContext.Provider>
  )
}

InputSelect.displayName = SelectPrimitive.Root.displayName

const InputSelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ children, ...props }, ref) => {
  return (
    <SelectPrimitive.Trigger ref={ref} {...props}>
      {children}
    </SelectPrimitive.Trigger>
  )
})
InputSelectTrigger.displayName = SelectPrimitive.Trigger.displayName

interface InputSelectTrailingContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  loading?: boolean
}

const InputSelectTrailingContainer = React.forwardRef<
  HTMLDivElement,
  InputSelectTrailingContainerProps
>(({ children, loading, ...props }, ref) => {
  const inputBaseStore = useInputSelectStoreContext()
  const { open } = useStore(inputBaseStore, (inputSelectStore) => inputSelectStore)

  const getInputSelectTrailingState = () => {
    if (loading)
      return (
        <div className="animate-[spin_3s_linear_infinite]">
          <i className="fa-regular fa-loader " />
        </div>
      )

    if (open) return <span className="fa-regular fa-angle-up" />

    return <i className="fa-regular fa-angle-down" />
  }

  return (
    <InputTrailingContainer {...props} ref={ref}>
      {getInputSelectTrailingState()}
    </InputTrailingContainer>
  )
})

InputSelectTrailingContainer.displayName = 'InputSelectTrailingContainer'

const InputSelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      'focus:bg-accent focus:text-accent-foreground relative flex w-full cursor-pointer items-center  rounded-sm pr-2 text-sm outline-none hover:bg-gray-100 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className
    )}
    {...props}
  >
    <SelectPrimitive.ItemText className="w-full">{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
))
InputSelectItem.displayName = SelectPrimitive.Item.displayName

const InputSelectContentHeader = React.forwardRef<HTMLDivElement, UniversalProps>(
  ({ className, children, ...props }, ref) => (
    <div className={cn('bg-background-mono-light w-full', className)} {...props} ref={ref}>
      <p className="text-content-xs px-md py-1 font-semibold uppercase">{children}</p>
    </div>
  )
)

InputSelectContentHeader.displayName = 'InputSelectContentHeader'

const InputSelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Portal data-testid="input-select-content">
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        'bg-background-base-clean text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 relative z-50 max-h-96 w-[var(--radix-select-trigger-width)] overflow-hidden rounded-md',
        position === 'popper' &&
          'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
        className
      )}
      position={position}
      {...props}
    >
      {children}
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
))
InputSelectContent.displayName = SelectPrimitive.Content.displayName

const InputSelectContentView = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Viewport>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Viewport>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Viewport
    ref={ref}
    {...props}
    className={cn(
      'border-border-stroke-subtle h-[var(--radix-select-trigger-height)] rounded-md border-2 outline-none',
      className
    )}
  >
    {children}
  </SelectPrimitive.Viewport>
))

InputSelectContentView.displayName = SelectPrimitive.Viewport.displayName

interface InputSelectStatefulInputProps extends InputProps {
  openClassName?: string
}

const InputSelectStatefulInput = React.forwardRef<HTMLInputElement, InputSelectStatefulInputProps>(
  ({ className, openClassName, ...props }, ref) => {
    const inputBaseStore = useInputSelectStoreContext()
    const { open } = useStore(inputBaseStore, (inputSelectStore) => inputSelectStore)

    return (
      <Input
        className={cn(
          'cursor-pointer',
          {
            [`!border-border-stroke-subtle !border-2 outline-none ${openClassName}`]: open,
          },
          className
        )}
        {...props}
        ref={ref}
      />
    )
  }
)

InputSelectStatefulInput.displayName = 'InputSelectStatefulInput'

interface InputMultiSelectInputProps extends InputProps {
  openClassName?: string
}

const InputMultiSelectInput = React.forwardRef<HTMLInputElement, InputMultiSelectInputProps>(
  ({ className, openClassName, ...props }, ref) => {
    const inputBaseStore = useInputSelectStoreContext()
    const { open } = useStore(inputBaseStore, (inputSelectStore) => inputSelectStore)
    return (
      <Input
        className={cn(
          {
            [`border-border-stroke-subtle !border-2 outline-none ${openClassName}`]: open,
          },
          'flex h-full min-h-12 cursor-pointer flex-wrap items-center justify-start gap-x-2 gap-y-1 pr-14',
          className
        )}
        {...props}
        ref={ref}
      />
    )
  }
)

InputMultiSelectInput.displayName = 'InputMultiSelectInput'

interface InputMultiSelectClearIconProps extends React.HTMLAttributes<HTMLDivElement> {
  loading?: boolean
}

const InputMultiSelectClearIcon = React.forwardRef<HTMLDivElement, InputMultiSelectClearIconProps>(
  ({ children, loading, onClick, ...props }, ref) => {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation()
          onClick?.(e)
        }}
        role="button"
        aria-label="Clear all tags"
        tabIndex={0}
        className="absolute right-[54px] top-6 -translate-y-1/2 transform cursor-pointer"
        {...props}
        ref={ref}
      >
        <i className="fa-regular fa-close" />
      </div>
    )
  }
)

InputMultiSelectClearIcon.displayName = 'InputMultiSelectClearIcon'

const InputSelectSearchableTrigger = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger> & { disabled?: boolean }
>(({ children, disabled, ...props }, ref) => {
  return (
    <PopoverPrimitive.Trigger
      ref={ref}
      {...props}
      disabled={disabled}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault()
          e.stopPropagation()
        }
      }}
    >
      {children}
    </PopoverPrimitive.Trigger>
  )
})

InputSelectSearchableTrigger.displayName = PopoverPrimitive.Trigger.displayName

const InputSelectSearchableContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & { usePortal?: boolean }
>(({ className, align = 'center', sideOffset = 4, children, usePortal = true, ...props }, ref) => {
  const Content = (
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        'bg-background-base-clean text-popover-foreground relative z-50 max-h-96 w-[var(--radix-popover-trigger-width)] overflow-hidden rounded-md',
        'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95',
        'border-border-stroke-subtle overflow-y-auto rounded-md border-2 outline-none [&::-webkit-scrollbar]:hidden',
        className
      )}
      {...props}
    >
      <CommandPrimitive>{children}</CommandPrimitive>
    </PopoverPrimitive.Content>
  )

  return usePortal ? <PopoverPrimitive.Portal>{Content}</PopoverPrimitive.Portal> : Content
})

InputSelectSearchableContent.displayName = PopoverPrimitive.Content.displayName

const InputSelectSearchableItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, children, onSelect, value, ...props }, ref) => {
  const inputBaseStore = useInputSelectStoreContext()
  const { setOpen } = useStore(inputBaseStore, (inputSelectStore) => inputSelectStore)

  const handleSelect = () => {
    if (onSelect) {
      onSelect(value)
    }
    setOpen(false)
  }

  return (
    <CommandPrimitive.Item
      ref={ref}
      role="presentation"
      className={cn(
        'focus:bg-accent focus:text-accent-foreground relative flex w-full cursor-pointer items-center  rounded-sm pr-2 text-sm outline-none hover:bg-gray-100 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
        className
      )}
      onSelect={handleSelect}
      {...props}
    >
      {children}
    </CommandPrimitive.Item>
  )
})
InputSelectSearchableItem.displayName = 'InputSelectSearchableItem'

export {
  InputSelect,
  InputSelectTrigger,
  InputSelectTrailingContainer,
  InputSelectItem,
  InputSelectContentHeader,
  InputSelectContent,
  InputSelectStatefulInput,
  InputSelectContentView,
  InputSelectSearchableTrigger,
  InputSelectSearchableContent,
  InputSelectSearchableItem,
  InputMultiSelectInput,
  InputMultiSelectClearIcon,
}
