import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  EmptyState,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'

import { useManageExtrasContext } from '../ManageExtrasProvider'
import ExtrasTabs from './ExtrasTabs'

const ExtrasTabsContainer = () => {
  const { t } = useTranslation()
  const { extras, areExtrasLoading, selectedExtra, toggleCreateExtrasSideBar, refetchExtras } =
    useManageExtrasContext()
  const currentClubName = useSelector((state: RootState) => {
    const currentClub = state?.golfClub?.list?.find(
      (club) => club.id === state?.golfClub?.selectedId || ''
    )
    return currentClub?.name as string
  })

  if (areExtrasLoading) {
    return (
      <EmptyState
        key="loading"
        iconName="fa-light fa-flip-both fa-loader bg-background-mono-lighter mb-4 flex rounded-full p-4"
        loading
      >
        <EmptyStateTitle>
          {t('settings.loadingCourses', { type: t('words.extras_other').toLowerCase() })}
        </EmptyStateTitle>
        <EmptyStateDescription>{t('settings.bays.hangOnTight')}</EmptyStateDescription>
      </EmptyState>
    )
  }

  if (!extras?.length) {
    return (
      <EmptyState
        key="empty-extras"
        className="max-w-[432px]"
        iconName="fa-regular fa-calendar-range bg-background-mono-lighter mb-4 flex rounded-full p-4"
      >
        <EmptyStateTitle>
          {t('settings.noCoursesCreated', { type: t('words.extras_one').toLowerCase() })}
        </EmptyStateTitle>
        <EmptyStateDescription>
          {t('settings.createAtLeastOneCourse', {
            name: currentClubName,
            type: t('words.extras_one').toLowerCase(),
          })}
        </EmptyStateDescription>
        <EmptyStateButton
          className="text-content-sm font-medium hover:no-underline focus:no-underline"
          onClick={toggleCreateExtrasSideBar}
        >
          {t('settings.createFirstCourse', { type: t('words.extras_one').toLowerCase() })}
        </EmptyStateButton>
      </EmptyState>
    )
  }

  return <ExtrasTabs currentCourse={selectedExtra} refetchCourses={refetchExtras} />
}

export default ExtrasTabsContainer
