import { differenceInCalendarMonths, subMonths, formatISO } from 'date-fns'
import { useMutation } from 'react-query'
import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import Grid from '@sweetspot/club-portal-legacy/components/Grid'
import { useFailedPayments } from '@sweetspot/club-portal/feature/payment'
import Portal from '@sweetspot/sweetspot-js/common/components/Portal'
import PlayerCardModal from '@sweetspot/club-portal-legacy/modals/PlayerCard'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'
import { _lockUnlockMemberships } from '@sweetspot/club-portal-legacy/services/membershipApi'
import ConfirmModal from '@sweetspot/club-portal-legacy/components/ConfirmModal'
import { HEADERS } from './headers'

const retryFilter = [
  {
    id: 1,
    name: 'transactions.header_retries',
    options: [
      { id: 0, name: '0', filter: '0' },
      { id: 1, name: '1', filter: '1' },
      { id: 2, name: '2', filter: '2' },
      { id: 3, name: '3', filter: '3' },
      { id: 4, name: '4', filter: '4' },
      { id: 5, name: '5', filter: '5' },
    ],
  },
]

const FailedPayments = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const [activeFilters, setActiveFilters] = useState([1, 2, 3, 4, 5])
  const [showPlayerCard, setShowPlayerCard] = useState(false)
  const [playerId, setPlayerId] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedRows, setSelectedRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [deselectAll, setDeselectAll] = useState(false)
  const [modalType, setModalType] = useState(null)
  const lockMultipleMemberships = useMutation((selectedFailedPayments) =>
    _lockUnlockMemberships(selectedFailedPayments)
  )

  const LOCK_MEMBERSHIPS = 1
  const NINE_MONTHS_AGO = formatISO(subMonths(new Date(), 9))

  const { data, isFetched, refetch } = useFailedPayments({
    query: {
      'retry.attempts': activeFilters,
      page: currentPage,
      'retry.last_time[strictly_after]': NINE_MONTHS_AGO,
      limit: 50,
    },
    queryOptions: {
      keepPreviousData: true,
    },
  })

  const handleSelect = (selectedFailedPayments) => {
    const copySelectedFailedPayments = [...selectedFailedPayments]
    const noDublicates = copySelectedFailedPayments.reduce((container, failedPayment) => {
      if (
        failedPayment?.membership_card &&
        !container.includes(failedPayment?.membership_card?.uuid)
      ) {
        container.push(failedPayment?.membership_card?.uuid)
      }

      return container
    }, [])

    setSelectedRows(noDublicates)
  }

  const handleLockMultipleMemberships = useCallback(
    (selectedFailedPayments) => {
      setIsLoading(true)

      lockMultipleMemberships.mutate(selectedFailedPayments, {
        onSuccess: () => {
          addToast(t('membershipLocked'), {
            appearance: 'success',
          })
          refetch()
        },
        onError: () => {
          addToast(t('toast.defaultError'), { appearance: 'error' })
        },
        onSettled: () => {
          setIsLoading(false)
          refetch()
        },
      })

      setDeselectAll(true)
    },
    [lockMultipleMemberships, addToast, t, refetch]
  )

  const renderLockMembershipsModalContent = () => {
    return <div className="mb-8 text-base">{t('membership.lockMultipleMembershipsModal')}</div>
  }

  const cancelLockMemberships = () => {
    setModalType(null)
    setDeselectAll(true)
  }

  const confirmLockMemberships = () => {
    setModalType(null)
    handleLockMultipleMemberships(selectedRows)
    setDeselectAll(true)
  }

  const modalWrapper = () => {
    switch (modalType) {
      case LOCK_MEMBERSHIPS:
        return (
          <ConfirmModal
            headerText="membership.lockMemberships"
            children={renderLockMembershipsModalContent()}
            cancelText="cancel"
            confirmText="confirm"
            onCancel={cancelLockMemberships}
            onConfirm={confirmLockMemberships}
          />
        )

      default:
        return
    }
  }

  const handleActionConfirm = (action) => {
    switch (action?.action) {
      case 'lockMemberships':
        if (selectedRows?.length === 0) return
        setModalType(LOCK_MEMBERSHIPS)
        break

      default:
        break
    }
  }

  const handleFilters = (filter) => {
    if (!filter) {
      setActiveFilters([])
    } else if (activeFilters.includes(filter)) {
      const current = activeFilters.filter((item) => item !== filter)
      setActiveFilters(current)
    } else {
      setActiveFilters((prev) => [...prev, filter])
    }
    setCurrentPage(1)
  }

  const handleRowClick = (FailedPayment) => {
    if (!FailedPayment?.player) return

    const {
      player: { id },
    } = FailedPayment

    setPlayerId(id)
    setShowPlayerCard(true)
  }

  return (
    <div className={'relative grid h-full gap-8 p-[10px] text-base'}>
      {modalWrapper()}
      <Grid
        actions={[
          {
            id: 1,
            label: 'membership.lockMemberships',
            disabled: selectedRows.length === 0,
            action: 'lockMemberships',
            accessKey: ACCESS_KEYS.PAGES.PLAYERS.EXPORT,
          },
        ]}
        actionsEnabled
        activeFilters={activeFilters}
        onRowClick={handleRowClick}
        handleFilters={handleFilters}
        filters={retryFilter}
        hasCheckbox
        onSelect={handleSelect}
        deselectAll={deselectAll}
        onActionConfirm={handleActionConfirm}
        filtersEnabled
        filtersTitle={'transactions.header_filter'}
        values={data['hydra:member']}
        pageTitle={'transactions.header'}
        headers={HEADERS}
        isLoading={!isFetched || isLoading}
        pagination
        totalPages={Math.ceil(data['hydra:totalItems'] / data['hydra:itemsPerPage'])}
        rowsPerPage={
          data['hydra:itemsPerPage'] > data['hydra:totalItems']
            ? data['hydra:totalItems']
            : data['hydra:itemsPerPage']
        }
        totalRows={data['hydra:member']?.length}
        displayRangeOfRows
        setCurrentPage={(val) => setCurrentPage(val)}
      />
      {showPlayerCard && playerId && (
        <Portal id="player-card-modal">
          <PlayerCardModal playerId={playerId} onClose={() => setShowPlayerCard(false)} />
        </Portal>
      )}
    </div>
  )
}

export { FailedPayments }
