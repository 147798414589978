import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { format, isSameDay, isValid } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCalendarDay } from '@fortawesome/pro-regular-svg-icons'

import { Button, ButtonVariantKeys } from '@sweetspot/scramble-ds/atoms'
import InputSelectDateSimple from '@sweetspot/sweetspot-js/ui-kit/components/InputSelectDateSimple'
import { changeTimezone } from '@sweetspot/shared/util/date'
import m from 'moment/moment'
import DateHelpers from '@sweetspot/sweetspot-js/common/functions/DateHelpers'
import { capitalize } from '@sweetspot/sweetspot-js/common/functions/utils'
import { DeviceType, useDeviceType } from '@sweetspot/scramble-ds/utils'

interface SheetHeaderDateSelectProps {
  currentDate: Date
  handleSetDate: (date: Date) => void
  timezone: string
  startDate?: string | Date
  endDate?: string | Date
  disableBeforeToday?: boolean
  calendarMobileResponsive?: boolean
  hideTodayButton?: boolean
  color?: string
  helperText?: string
  availableDays?: string[]
  onTodayClick?: () => void
  onPrevClick?: () => void
  onNextClick?: () => void
  disabled: boolean
}

const SheetHeaderDateSelect = ({
  currentDate,
  handleSetDate,
  timezone,
  onTodayClick,
  onPrevClick,
  onNextClick,
  disableBeforeToday = false,
  startDate,
  endDate,
  availableDays,
  color,
  helperText,
  calendarMobileResponsive = false,
  hideTodayButton = false,
  disabled,
}: SheetHeaderDateSelectProps) => {
  const { t } = useTranslation()

  const { deviceType } = useDeviceType()
  const dateIsDisabled = useMemo(() => !isValid(currentDate) || disabled, [currentDate, disabled])

  const currentDateFormated = useMemo(() => {
    if (isValid(currentDate)) {
      const today = new Date()

      if (isSameDay(currentDate, today)) {
        return `${t('dateTime.words.today')}, ${format(currentDate, 'd MMM')}`
      }
      return capitalize(format(currentDate, 'EEEE, d MMM'))
    }
    return ''
  }, [currentDate, t])

  const availableDaysList = useMemo(
    () => availableDays || DateHelpers.getDaysArray().map((day) => capitalize(day)),
    [availableDays]
  )

  const handleSetDayBack = useCallback(() => {
    onPrevClick?.()
    const prevDay = new Date(currentDate.getTime())
    prevDay.setDate(currentDate.getDate() - 1)
    handleSetDate(prevDay)
  }, [currentDate, handleSetDate, onPrevClick])

  const handleSetDayForward = useCallback(() => {
    onNextClick?.()
    const nextDay = new Date(currentDate.getTime())
    nextDay.setDate(currentDate.getDate() + 1)
    handleSetDate(nextDay)
  }, [currentDate, handleSetDate, onNextClick])

  const handleSetToday = useCallback(() => {
    onTodayClick?.()
    const today = changeTimezone(new Date(), timezone)
    handleSetDate(today)
  }, [handleSetDate, onTodayClick, timezone])

  const disablePrevButton = useMemo(
    () => disableBeforeToday && m(currentDate).isSame(m(), 'day'),
    [currentDate, disableBeforeToday]
  )

  const disableNextButton = useMemo(() => {
    return (
      !!startDate &&
      !!endDate &&
      !DateHelpers.isInsidePeriodAndValidDay(
        startDate,
        endDate,
        availableDaysList,
        m(currentDate).add(1, 'day').toDate()
      )
    )
  }, [startDate, endDate, availableDaysList, currentDate])

  const arrowButtonsVariant: (typeof ButtonVariantKeys)[keyof typeof ButtonVariantKeys] =
    useMemo(() => {
      const colorVariant = color === 'light' ? 'light' : 'dark'
      const type = [DeviceType.LAPTOP, DeviceType.MOBILE].includes(deviceType) ? 'link' : 'ghost'
      return `${type}-${colorVariant}` as (typeof ButtonVariantKeys)[keyof typeof ButtonVariantKeys]
    }, [color, deviceType])

  return (
    <div className="flex w-full items-center gap-6 lg:w-auto">
      {!hideTodayButton && (
        <Button
          variant={color === 'light' ? 'link-light' : 'link-dark'}
          onClick={handleSetToday}
          className="px-3"
          data-testId="sheet-header-date-select-today-button"
          disabled={dateIsDisabled}
        >
          <FontAwesomeIcon size="1x" icon={faCalendarDay} className="px-[1px]" />
        </Button>
      )}

      <div className="flex flex-1 items-center gap-4">
        <Button
          onClick={handleSetDayBack}
          rounding="pill"
          disabled={dateIsDisabled || disablePrevButton}
          variant={arrowButtonsVariant}
          size="medium"
          className="hover:text-text-dark hover-none:text-text-light disabled:opacity-1 disabled:text-border-stroke-clean min-w-8 !bg-transparent px-3 py-2 hover:bg-transparent hover:shadow-none focus:!outline-none"
          data-testId="sheet-header-date-select-prev-button"
        >
          <FontAwesomeIcon className="px-[1px]" icon={faArrowLeft} />
        </Button>
        <InputSelectDateSimple
          className="order-none !w-[180px] flex-grow items-start lg:order-2 lg:my-0 lg:flex-grow-0"
          inputClassName="!w-auto"
          newDesign
          currentDate={currentDate}
          onSetDate={handleSetDate}
          disableBeforeToday={disableBeforeToday}
          placeholder={t('sentences.selectDate')}
          value={currentDateFormated}
          disabled={dateIsDisabled}
          startDate={startDate}
          endDate={endDate}
          color={color}
          helperText={helperText}
          calendarMobileResponsive={calendarMobileResponsive}
          testId="sheet-header-date-select"
          portalBoxTestId="sheet-header-date-select-calendar"
        />
        <Button
          onClick={handleSetDayForward}
          rounding="pill"
          disabled={dateIsDisabled || disableNextButton}
          variant={arrowButtonsVariant}
          size="medium"
          className="hover:text-text-dark hover-none:text-text-light min-w-8 !bg-transparent px-3 py-2 hover:bg-transparent hover:shadow-none focus:!outline-none lg:mr-4"
          data-testId="sheet-header-date-select-next-button"
        >
          <FontAwesomeIcon className="px-[1px]" icon={faArrowRight} />
        </Button>
      </div>
    </div>
  )
}

export default SheetHeaderDateSelect
