import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { inRange } from 'lodash'

import styles from './styles.module.scss'
import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'

import { useHistory } from 'react-router-dom'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import { queryCourses } from '@sweetspot/sweetspot-js/features/courses/services/api-platform'
import moment from 'moment'
import { getSpaces } from '@sweetspot/sweetspot-js/features/spaces/services/api-platform'
import { queryRanges } from '@sweetspot/sweetspot-js/features/ranges/services'
import { queryBays } from '@sweetspot/sweetspot-js/features/bays/services'
import SlotsCalendar from './Common/components/SlotsCalendar'
import produce from 'immer'
import EmptyVenue from './Common/components/EmptyVenue/EmptyVenue'
import SpinnerLoader from '@sweetspot/club-portal-legacy/components/SpinnerLoader/SpinnerLoader'
import {
  fetchBookings,
  reserveBookingWithSpaces,
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { useToasts } from 'react-toast-notifications'
import SpaceColumn from './Common/components/SpaceColumn'
import SimulatorRangesConflictHeader from './Common/components/SimulatorRangesConflictHeader'
import useQueryParams from '@sweetspot/sweetspot-js/common/hooks/useQueryParams'
import { setSessionStorage } from '@sweetspot/shared/util/session-storage'
import {
  SimRangeHeader,
  RangeFilterProvider,
  SimRangeFooter,
  EditTimeSlotsSideBar,
} from '@sweetspot/club-portal/feature/venues'
import { getTeeTimesCollection } from '@sweetspot/sweetspot-js/features/teeTimes/js/getTeeTimesCollection'
import { getStartAndEndOfDateTZ } from '@sweetspot/sweetspot-js/common/functions/dateUtils'
import { getAllPagesRequest } from '@sweetspot/sweetspot-js/common/functions/getAllPagesRequest'
import { BOOKING_GROUPS } from '@sweetspot/sweetspot-js/features/bookings/constants/bookingRelations'
import { RangeFilterType, RoofFilter } from '@sweetspot/shared/types'
import VenueTitle from './Common/components/VenueTitle/VenueTitle'
import { getStoredAuthToken } from '@sweetspot/shared/data-access/api-platform-auth'
import { RangeStanceFilterMap } from '@sweetspot/club-portal-legacy/pages/Venues/Common/constants'
import useRanges from '@sweetspot/club-portal-legacy/hooks/useRanges'
import { useAvailableCourseTypes } from '@sweetspot/shared/util/hooks'

const Venues = ({ courseType }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const history = useHistory()
  const currentClubId = useSelector((state) => state?.golfClub?.selectedId)
  const courses = useSelector((state) => state.golfCourse?.list)

  const authToken = getStoredAuthToken()

  let query = useQueryParams()
  const [filters, setFilters] = useState({
    [RangeFilterType.TECHNOLOGIES]: {},
    [RangeFilterType.FLOORS]: {},
    [RangeFilterType.ROOFS]: {},
    [RangeFilterType.STANCES]: {},
  })

  const [state, setState] = useMergeState({
    currentVenue: null,
    currentDate: null,
    currentVenueSpaces: null,
    teeTimesPerSpace: null,
    bookingsPerSpace: null,
    currentRange: null,
  })

  const isUpdatingSelectedClub = useSelector((state) => state?.golfClub?.isUpdating)
  const [selectedFrom, setSelectedFrom] = useState(null)
  const [selectedTo, setSelectedTo] = useState(null)
  const [potentialFrom, setPotentialFrom] = useState(null)
  const [potentialTo, setPotentialTo] = useState(null)
  const [selectedSpaces, setSelectedSpaces] = useState([])
  const [potentialSelectedSpaces, setPotentialSelectedSpaces] = useState([])
  const [reservingBooking, setReservingBooking] = useState(false)
  const [teeTimesGenerated, setTeeTimesGenerated] = useState(false)
  const [pricingExists, setPricingExists] = useState(false)
  const [hoveredBookingBoxesOrderIds, setHoveredBookingsBoxesOrderIds] = useState([])
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const availableCourseTypes = useAvailableCourseTypes({ courses })
  const [hoveredTeeTime, setHoveredTeeTime] = useState(null)
  const [isEditTimeSlotsOpen, setIsEditTimeSlotsOpen] = useState(false)

  const isNewRange = useMemo(() => {
    return state.currentVenue?.belongs_to_range_context && !!state.currentRange?.id
  }, [state.currentVenue?.belongs_to_range_context, state.currentRange?.id])

  const { ranges, areRangesLoading } = useRanges()

  const hasTeeTimes = useMemo(
    () => Object.values(state.teeTimesPerSpace || {}).some((times) => times.length > 0),
    [state.teeTimesPerSpace]
  )

  const selectedTeeTimes = useMemo(() => {
    if (selectedSpaces?.length && selectedFrom && selectedTo && state.teeTimesPerSpace) {
      let teeTimes = []
      Object.keys(state.teeTimesPerSpace).forEach((spaceId) => {
        if (selectedSpaces.find((x) => `${x.id}` === spaceId)) {
          state.teeTimesPerSpace[spaceId].forEach((teeTime) => {
            if (state.currentVenue?.is_use_dynamic_pricing && teeTime.dynamic_price === null) return
            if (state.currentVenue?.is_use_dynamic_pricing === false && teeTime.price === null)
              return
            const { from_unix, to_unix } = teeTime
            if (from_unix >= selectedFrom && to_unix <= selectedTo) teeTimes.push(teeTime)
          })
        }
      })
      return teeTimes
    }
    return []
  }, [selectedSpaces, selectedFrom, selectedTo, state.teeTimesPerSpace, state.currentVenue])

  const isFooterShown = useMemo(
    () => !!selectedTeeTimes?.length && !isEditTimeSlotsOpen,
    [selectedTeeTimes, isEditTimeSlotsOpen]
  )

  const { data: venues, isFetching: venuesIsFetching } = useQuery(
    [
      CLUB_QUERIES.COURSES,
      authToken,
      {
        'club.id': currentClubId,
        'type[]': [courseType],
        'order[name]': 'asc',
      },
    ],
    () =>
      queryCourses({
        'club.id': currentClubId,
        'type[]': [courseType],
        'order[name]': 'asc',
      }),
    {
      enabled: !!currentClubId && !!authToken,
    }
  )

  // fetchRanges
  const { isFetching: rangesIsFetching } = useQuery(
    [CLUB_QUERIES.RANGES, currentClubId, state.currentVenue?.id, authToken],
    async () => {
      return queryRanges(state.currentVenue?.club?.uuid)
    },
    {
      enabled:
        !!state.currentVenue?.club?.uuid &&
        !!state.currentVenue?.belongs_to_range_context &&
        !!authToken,
      onSuccess: (data) => {
        if (data) {
          const range = data.ranges?.find(
            (range) => range?.external_reference === state.currentVenue?.uuid
          )
          setState({ currentRange: range })
        }
      },
      onError: () => {
        setState({ currentRange: null })
      },
    }
  )

  // fetchBays
  const { isFetching: baysIsFetching } = useQuery(
    [CLUB_QUERIES.BAYS, state.currentVenue?.id, authToken],
    () => queryBays({ drivingRangeId: state.currentRange?.id, bookable: true }),
    {
      enabled: !!state.currentVenue?.id && !!isNewRange && !rangesIsFetching,
      onSuccess: (data) => {
        setState({
          currentVenueSpaces: data?.data?.map((bay) => ({ ...bay, uuid: bay.id })) || [],
        })
      },
    }
  )

  // fetchSpaces
  const { isFetching: spacesIsFetching } = useQuery(
    [CLUB_QUERIES.SPACES, { course: state.currentVenue?.id }, authToken],
    () => getSpaces({ course: state.currentVenue?.id }),
    {
      enabled: !!state.currentVenue?.id && !isNewRange && !rangesIsFetching && !!authToken,
      onSuccess: (data) => {
        setState({ currentVenueSpaces: data, teeTimesPerSpace: null, bookingsPerSpace: null })
      },
    }
  )
  const { isFetching: teeTimesIsLoading, refetch: refetchTeeTimes } = useQuery(
    [CLUB_QUERIES.TEE_TIMES, state.currentDate, currentClubId, state.currentVenue, authToken],
    () => {
      const [startDay, endDay] = getStartAndEndOfDateTZ(
        state.currentDate,
        state.currentVenue?.timezone ?? 'Europe/Stockholm'
      )

      return getTeeTimesCollection({
        courseUuid: state.currentVenue?.uuid,
        startDate: startDay,
        endDate: endDay,
        is_use_dynamic_pricing: state.currentVenue.is_use_dynamic_pricing,
      })
    },
    {
      enabled: !!state.currentVenue?.id && !!state.currentDate && !!authToken,
      onSuccess: (data) => {
        setTeeTimesGenerated(!!data?.length)
        if (
          (state.currentVenue?.is_use_dynamic_pricing &&
            (data?.[0]?.dynamic_price || data?.[0]?.price)) ||
          !state.currentVenue?.is_use_dynamic_pricing
        ) {
          setPricingExists(true)
        } else {
          setPricingExists(false)
        }

        const groupedBySpace = data.reduce((acc, item) => {
          const spaceId = isNewRange ? item.space?.uuid : item.space?.id
          if (!spaceId) {
            return acc
          }
          if (!acc[spaceId]) {
            acc[spaceId] = []
          }
          acc[spaceId].push(item)
          return acc
        }, {})

        setState((prev) => ({
          teeTimesPerSpace: {
            ...prev.teeTimesPerSpace,
            ...groupedBySpace,
          },
        }))
      },
      onError: (error) => {
        setPricingExists(false)
        if (error?.detail === 'Price period not found') {
          setTeeTimesGenerated(true)
        } else {
          setTeeTimesGenerated(false)
        }
      },
    }
  )

  useQuery(
    [
      CLUB_QUERIES.BOOKINGS,
      state.currentDate,
      state.currentVenue,
      authToken,
      {
        'state[]': ['new', 'fulfilled', 'reopened', 'partially_paid', 'partially_refunded'],
      },
    ],
    () => {
      const [startDay, endDay] = getStartAndEndOfDateTZ(
        state.currentDate,
        state.currentVenue?.timezone
      )
      return getAllPagesRequest(fetchBookings, {
        'course.uuid': state.currentVenue?.uuid,
        'booking.start_time[after]': new Date(startDay).toISOString(),
        'booking.start_time[before]': new Date(endDay).toISOString(),
        'state[]': ['new', 'fulfilled', 'reopened', 'partially_paid', 'partially_refunded'],
        'groups[]': [BOOKING_GROUPS.LEGACY_BOOKING, BOOKING_GROUPS.CUSTOMER],
        page: 1,
        limit: 50,
      })
    },
    {
      enabled: !!state.currentVenue?.uuid && !!state.currentDate && !!authToken,
      refetchInterval: 30000,
      onSuccess: (data) => {
        const groupedBySpace = data.reduce((acc, item) => {
          item.spaces?.forEach((space) => {
            const spaceId = isNewRange ? space?.uuid : space?.id
            if (!spaceId) {
              return acc
            }
            if (!acc[spaceId]) {
              acc[spaceId] = []
            }
            acc[spaceId].push(item)
          })
          return acc
        }, {})

        setState((prev) => ({
          bookingsPerSpace: {
            ...prev.bookingsPerSpace,
            ...groupedBySpace,
          },
        }))
      },
    }
  )

  const updateCurrentDate = useCallback(() => {
    const urlDate = query.get('date')
    let date = moment.tz(state.currentVenue?.timezone)
    if (urlDate) {
      date = moment.unix(parseInt(urlDate)).tz(state.currentVenue?.timezone)
    }
    setState({ currentDate: date })
  }, [state.currentVenue?.timezone])

  useEffect(() => {
    if (state.currentVenue && !state.currentDate) {
      updateCurrentDate()
    }
  }, [state.currentVenue, state.currentDate])

  useEffect(() => {
    if (!isUpdatingSelectedClub && venues?.length && (!isNewRange || ranges?.length)) {
      const urlVenue = query.get('venue')
      const urlTrmsSiteId = query.get('trms_site_id')
      let matchingVenue = null

      if (urlTrmsSiteId) {
        const matchingRange =
          ranges.find((range) => range.toptracer_site_id === urlTrmsSiteId) || null
        matchingVenue =
          matchingRange && venues.find((venue) => venue.uuid === matchingRange.external_reference)
      } else if (urlVenue) {
        matchingVenue = venues.find((venue) => venue.uuid === urlVenue)
      }
      if (urlTrmsSiteId || urlVenue) {
        if (matchingVenue) {
          setState({ currentVenue: matchingVenue })
          updateCurrentDate()
          query.set('venue', `${matchingVenue?.uuid}`)
          query.delete('trms_site_id')
          history.replace(`${history?.location?.pathname}?${query.toString()}`)
        } else {
          history.push('/404')
        }
      } else {
        setState({ currentVenue: venues[0] })
        updateCurrentDate()
      }
    }
  }, [isNewRange, isUpdatingSelectedClub, ranges, venues])

  const categories = useMemo(() => {
    if (state.teeTimesPerSpace) {
      let cats = []
      Object.keys(state.teeTimesPerSpace)
        .map((key) => state.teeTimesPerSpace[key])
        .flat()
        .map((teeTime) => {
          const teeTimeCategory = teeTime.category
          if (!cats.find((x) => x.id === teeTimeCategory.id)) {
            cats.push(teeTimeCategory)
          }
        })
      return cats
    }
    return []
  }, [state.teeTimesPerSpace])

  const filteredSpacesToRender = useMemo(() => {
    if (!isNewRange || !state.currentVenueSpaces || !filters) {
      return state.currentVenueSpaces
    }
    let filteredSpaces = [...state.currentVenueSpaces]
    const filteredFloors = Object.keys(filters.floors).filter(
      (floor) => filters.floors[parseInt(floor)]
    )
    const filteredTechnologies = Object.keys(filters.technologies).filter(
      (technology) => filters.technologies[technology]
    )

    if (filteredFloors.length > 0) {
      filteredSpaces = filteredSpaces.filter((space) =>
        filteredFloors.includes(space.floor.toString())
      )
    }
    if (filteredTechnologies.length > 0) {
      filteredSpaces = filteredSpaces.filter((space) =>
        filteredTechnologies.includes(space.ball_tracking_technology?.tracking_provider)
      )
    }

    if (
      Object.values(filters.roofs).includes(true) &&
      !(filters.roofs[RoofFilter.WithRoof] && filters.roofs[RoofFilter.WithoutRoof])
    ) {
      filteredSpaces = filteredSpaces.filter((space) => {
        if (filters.roofs[RoofFilter.WithRoof]) {
          return space.amenities?.roof
        }
        if (filters.roofs[RoofFilter.WithoutRoof]) {
          return !space.amenities?.roof
        }
        return true
      })
    }
    if (Object.values(filters[RangeFilterType.STANCES]).includes(true)) {
      filteredSpaces = filteredSpaces.filter(
        (space) => filters[RangeFilterType.STANCES][RangeStanceFilterMap[space.stance]]
      )
    }
    return filteredSpaces
  }, [filters, state.currentVenueSpaces, isNewRange])

  const showSheet = useMemo(() => {
    return (
      !rangesIsFetching &&
      !spacesIsFetching &&
      !baysIsFetching &&
      !venuesIsFetching &&
      !areRangesLoading &&
      venues?.length &&
      filteredSpacesToRender?.length &&
      teeTimesGenerated &&
      pricingExists
    )
  }, [
    rangesIsFetching,
    spacesIsFetching,
    baysIsFetching,
    venuesIsFetching,
    areRangesLoading,
    venues?.length,
    filteredSpacesToRender?.length,
    teeTimesGenerated,
    pricingExists,
  ])

  // Show loader if fetching data for the first time
  const showLoader = useMemo(() => {
    return (
      (venuesIsFetching ||
        spacesIsFetching ||
        teeTimesIsLoading ||
        baysIsFetching ||
        rangesIsFetching) &&
      Object.keys(state.teeTimesPerSpace || {}).length === 0
    )
  }, [
    venuesIsFetching,
    spacesIsFetching,
    teeTimesIsLoading,
    baysIsFetching,
    rangesIsFetching,
    state.teeTimesPerSpace,
  ])

  // Show empty venue
  const showEmptyVenue = useMemo(() => {
    return (
      !venuesIsFetching &&
      !spacesIsFetching &&
      !baysIsFetching &&
      !teeTimesIsLoading &&
      !rangesIsFetching &&
      (venues?.length <= 0 ||
        state.currentVenueSpaces?.length <= 0 ||
        filteredSpacesToRender?.length <= 0 ||
        !teeTimesGenerated ||
        !pricingExists)
    )
  }, [
    venuesIsFetching,
    spacesIsFetching,
    baysIsFetching,
    teeTimesIsLoading,
    rangesIsFetching,
    venues,
    state.currentVenueSpaces,
    filteredSpacesToRender,
    teeTimesGenerated,
    pricingExists,
  ])

  // booking conflict
  const isConflict = useMemo(() => {
    if (
      !state.bookingsPerSpace ||
      !Object.keys(state.bookingsPerSpace)?.length ||
      !selectedFrom ||
      !selectedTo ||
      !selectedSpaces?.length
    )
      return false

    let bookings = []
    Object.keys(state.bookingsPerSpace).forEach((spaceId) => {
      state.bookingsPerSpace[spaceId].forEach((booking) =>
        bookings.push({
          startTime: booking.booking.start_time,
          endTime: booking.booking.end_time,
          spaceId: parseInt(spaceId),
        })
      )
    })

    return bookings.some((booking) => {
      const { startTime, endTime, spaceId } = booking
      const bookingStartTime = moment(startTime).unix()
      const bookingEndTime = moment(endTime).unix()

      const includesSelectedSpace = selectedSpaces.some((x) => x.id === spaceId)

      if (!includesSelectedSpace) return false

      if (
        inRange(selectedFrom, bookingStartTime, bookingEndTime) ||
        inRange(selectedTo - 1, bookingStartTime, bookingEndTime) ||
        (selectedFrom <= bookingStartTime && selectedTo >= bookingEndTime)
      )
        return true

      return false
    })
  }, [selectedFrom, selectedTo, state.bookingsPerSpace, selectedSpaces])

  useEffect(() => {
    if (!selectedSpaces?.length) {
      setSelectedFrom(null)
      setSelectedTo(null)
      setPotentialTo(null)
      setPotentialFrom(null)
    }
  }, [selectedSpaces])

  // reset selection if venue is changed
  useEffect(() => {
    resetSheetSelection()
  }, [state.currentVenue?.id])

  const handleSelectFrom = (from) => {
    setSelectedFrom(from)
  }
  const handleSelectTo = (to) => {
    setSelectedTo(to)
  }

  const handleSelectSpace = (space) => {
    setSelectedSpaces(
      produce((draft) => {
        const index = draft.findIndex((x) => x.id === space.id)

        if (index !== -1) {
          draft.splice(index, 1)
        } else {
          draft.push(space)
        }
      })
    )
  }

  const resetSelectPotentialSpaces = () => {
    setPotentialSelectedSpaces(selectedSpaces)
  }

  const handleSelectPotentialSpace = (space) => {
    setPotentialSelectedSpaces(
      produce((draft) => {
        const index = draft.findIndex((x) => x.id === space.id)

        if (index !== -1) {
          draft.splice(index, 1)
        } else {
          draft.push(space)
        }
      })
    )
  }

  const onTimeSlotsUpdate = (teeTimePeriodArray) => {
    setShouldAnimate(false)
    const tempTeeTimes = { ...state.teeTimesPerSpace }
    Object.keys(tempTeeTimes).forEach((spaceId) => {
      for (let teeTime of tempTeeTimes[spaceId]) {
        delete teeTime.category.playAnimation
        delete teeTime.category.previousColor
      }
    })

    for (let periodData of teeTimePeriodArray) {
      const periodTeeTimes =
        tempTeeTimes[periodData.space.id] || tempTeeTimes[periodData.space.uuid] || []
      periodTeeTimes.forEach((teeTime) => {
        const { from_unix, to_unix } = teeTime
        if (from_unix >= selectedFrom && to_unix <= selectedTo) {
          setShouldAnimate(true)
          teeTime.category = {
            ...teeTime.category,
            ...periodData.category,
            playAnimation: true,
            previousColor: teeTime.category.color,
          }
          teeTime.is_prime_time = periodData.is_prime_time ?? teeTime.is_prime_time
          teeTime.max_slots = periodData.slots ?? teeTime.max_slots
        }
      })
    }
    setState({ teeTimesPerSpace: { ...tempTeeTimes } })
    resetSheetSelection()
    setIsEditTimeSlotsOpen(false)
  }

  const resetSheetSelection = () => {
    setSelectedSpaces([])
    setPotentialSelectedSpaces([])
    setSelectedTo(null)
    setSelectedFrom(null)
    setPotentialTo(null)
    setPotentialFrom(null)
  }

  const onCancelHandler = () => {
    resetSheetSelection()
    setIsEditTimeSlotsOpen(false)
  }

  const onBookHandler = async () => {
    setReservingBooking(true)
    const dateFormat = 'YYYY-MM-DD HH:mm:ss'
    const teeTimesPerSpace = state.teeTimesPerSpace

    let payload = {
      from: moment.unix(selectedFrom).utc().format(dateFormat),
      to: moment.unix(selectedTo).utc().format(dateFormat),
      data: [],
    }

    try {
      selectedSpaces.forEach((space) => {
        const { uuid, id } = space
        let teeTimes = []
        teeTimesPerSpace[id].forEach((teeTime) => {
          if (selectedTeeTimes.some((x) => x.id === teeTime.id)) {
            teeTimes.push({
              uuid: teeTime.uuid,
            })
          }
        })

        payload.data.push({
          space_uuid: uuid,
          tee_times: teeTimes,
        })
      })
    } catch (error) {
      setReservingBooking(false)
      addToast(t('sentences.failedToReserveBooking'), { appearance: 'error' })
      // Refetch teetimes in case our optimistic updates of UI caused problems
      refetchTeeTimes()
      return
    }

    const [res] = await to(
      reserveBookingWithSpaces(payload, (headers, data) => {
        setSessionStorage(`cancel-${data.order.uuid}`, JSON.stringify(headers))
      })
    )

    setReservingBooking(false)
    if (res) {
      history.push(`/simulators-ranges/orders/${res.order.uuid}`)
    } else {
      addToast(t('sentences.failedToReserveBooking'), { appearance: 'error' })
    }
  }

  const handleOnMouseEnterBookingBox = (orderId) => {
    setHoveredBookingsBoxesOrderIds(
      produce((draft) => {
        const index = draft.indexOf(orderId)
        if (index === -1) {
          draft.push(orderId)
        }
      })
    )
  }
  const handleOnMouseLeaveBookingBox = (orderId) => {
    setHoveredBookingsBoxesOrderIds(
      produce((draft) => {
        const index = draft.indexOf(orderId)
        if (index !== -1) {
          draft.splice(index, 1)
        }
      })
    )
  }

  const handleOnRefreshTeeTimes = useCallback(() => {
    refetchTeeTimes()
  }, [refetchTeeTimes])

  const renderVenues = () => (
    <div className={cx(styles.container)}>
      <div className="border-border-stroke-clean text-text-dark text-content-lg w-full border-b px-6 py-4 font-bold">
        <VenueTitle courseType={courseType} count={availableCourseTypes[courseType] || 0} />
      </div>

      {venues?.length > 0 && (
        <div className={cx(styles.headersContainer)}>
          <SimRangeHeader
            venues={venues}
            venuesIsLoading={venuesIsFetching}
            currentVenue={{ ...(state.currentVenue || {}), rangeId: state.currentRange?.id }}
            setVenue={(venue) => setState({ currentVenue: venue })}
            currentDate={state.currentDate ? new Date(state.currentDate) : null}
            setDate={(date) => setState({ currentDate: moment(date) })}
            setIsEditTimeSlotsOpen={setIsEditTimeSlotsOpen}
            courseType={courseType}
            hasTeeTimes={hasTeeTimes}
            teeTimesIsLoading={teeTimesIsLoading}
            onRefreshTeeTimes={handleOnRefreshTeeTimes}
            areActionsDisabled={isEditTimeSlotsOpen || !!selectedTeeTimes?.length}
          />

          <SimulatorRangesConflictHeader isConflict={isConflict} onCancel={resetSheetSelection} />

          {categories?.length ? (
            <div className={cx(styles.categoriesRow)}>
              {categories.map((category) => (
                <div className={cx(styles.category)} key={category.id}>
                  <div
                    className={cx(styles.categoryDot)}
                    style={{ backgroundColor: category.color }}
                  ></div>
                  <p className={cx(styles.categoryName)}>{category.name}</p>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      )}

      {showLoader && (
        <div className={cx(styles.centeredContainer)}>
          <SpinnerLoader text={t('sentences.loadingTimeSlots')} />
        </div>
      )}

      {showEmptyVenue && (
        <EmptyVenue
          courseType={courseType}
          noVenues={!venues?.length}
          noSpaces={!isNewRange && !state.currentVenueSpaces?.length}
          noTeeTimes={!teeTimesGenerated}
          noPricing={!pricingExists}
          noBays={!filteredSpacesToRender?.length}
        />
      )}

      {showSheet ? (
        <SlotsCalendar
          currentVenue={state.currentVenue}
          className={cx(
            styles.spacesContainer,
            isEditTimeSlotsOpen && '!w-[calc(100%-364px)]',
            isFooterShown && '!pb-[90px]'
          )}
          spaces={filteredSpacesToRender}
          teeTimesPerSpace={state.teeTimesPerSpace || {}}
          render={(slotWidth, slotHeight, slotsPerHour, intervalMinutes) =>
            filteredSpacesToRender?.map((space) => (
              <SpaceColumn
                slotWidth={slotWidth}
                slotHeight={slotHeight}
                slotsPerHour={slotsPerHour}
                intervalMinutes={intervalMinutes}
                key={space.id}
                space={space}
                onSelectFrom={handleSelectFrom}
                onSelectTo={handleSelectTo}
                onSelectSpace={handleSelectSpace}
                onSelectPotentialSpace={handleSelectPotentialSpace}
                onResetSelectPotentialSpaces={resetSelectPotentialSpaces}
                selectedFrom={selectedFrom}
                selectedTo={selectedTo}
                potentialFrom={potentialFrom}
                potentialTo={potentialTo}
                onPotentialToUpdate={setPotentialTo}
                onPotentialFromUpdate={setPotentialFrom}
                hoveredTeeTime={hoveredTeeTime}
                setHoveredTeeTime={setHoveredTeeTime}
                isSpaceSelected={!!selectedSpaces.find((x) => x.id === space.id)}
                isSpacePotentialSelected={!!potentialSelectedSpaces.find((x) => x.id === space.id)}
                teeTimes={state.teeTimesPerSpace?.[space.id]}
                bookings={state.bookingsPerSpace?.[space.id] || []}
                venue={state.currentVenue}
                onMouseEnterBookingBox={handleOnMouseEnterBookingBox}
                onMouseLeaveBookingBox={handleOnMouseLeaveBookingBox}
                hoveredOrderIds={hoveredBookingBoxesOrderIds}
                editingCategory={isEditTimeSlotsOpen}
                shouldAnimate={shouldAnimate}
                isBay={isNewRange}
                bays={state.currentVenueSpaces}
              />
            ))
          }
        />
      ) : null}

      <SimRangeFooter
        course={state.currentVenue}
        onConfirm={onBookHandler}
        onCancel={onCancelHandler}
        spaces={state.currentVenueSpaces}
        isReserving={reservingBooking}
        isNewRange={isNewRange}
        selectedTeeTimes={selectedTeeTimes}
        isShown={isFooterShown}
        isConflict={isConflict}
      />

      <EditTimeSlotsSideBar
        isShown={isEditTimeSlotsOpen}
        handleClose={onCancelHandler}
        selectedFrom={selectedFrom}
        selectedTo={selectedTo}
        selectedSpaces={selectedSpaces}
        currentVenue={state.currentVenue}
        selectedTeeTimes={selectedTeeTimes}
        resetSheet={resetSheetSelection}
        onTimeSlotsUpdate={onTimeSlotsUpdate}
      />
    </div>
  )

  if (isNewRange) {
    return (
      <RangeFilterProvider
        rangeId={state.currentRange?.id}
        bays={state.currentVenueSpaces}
        filters={filters}
        setFilters={setFilters}
      >
        {renderVenues()}
      </RangeFilterProvider>
    )
  } else {
    return renderVenues()
  }
}

Venues.propTypes = {}

Venues.defaultProps = {}

export default Venues
