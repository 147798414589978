import { useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/pro-regular-svg-icons'
import { ColumnDef, Table } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { DeviceType, useDeviceType } from '../../../../../utils'
import { TableFilter } from '../../types'
import { DataTableFilterItem } from './DataTableFilterItem'
import {
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
  SheetTrigger,
  SheetFooter,
} from '../../../../molecules'
import { Accordion, Button } from '../../../../atoms'

interface DataTableFilterProps<TData> {
  table: Table<TData>
  filters: TableFilter[]
  onFilterChange?: (filter: string) => void
  activeFilters?: string[]
}

export const DataTableFilters = <TData,>({
  table,
  filters,
  onFilterChange,
  activeFilters,
}: DataTableFilterProps<TData>) => {
  const { deviceType } = useDeviceType()
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const [accordionValue, setAccordionValue] = useState<string>(undefined)

  const sheetSide = useMemo(
    () => (deviceType === DeviceType.LAPTOP ? 'right' : 'bottom'),
    [deviceType]
  )

  const handleToggle = (
    value: string,
    column: ColumnDef<TData, unknown> | undefined,
    selectedValues: Set<string>
  ) => {
    if (onFilterChange) {
      onFilterChange(value)
      return
    }

    if (selectedValues.has(value)) {
      selectedValues.delete(value)
    } else {
      selectedValues.add(value)
    }
    column?.setFilterValue(selectedValues.size ? Array.from(selectedValues) : undefined)
  }

  return (
    <Sheet open={open}>
      <SheetTrigger asChild>
        <Button
          size="small"
          variant="clear-dark"
          rounding="pill"
          className="hover:!text-text-dark w-full !text-base hover:bg-transparent hover:shadow-none focus:!outline-none"
          onClick={() => setOpen(!open)}
        >
          <i className="fa-regular fa-sliders" />
        </Button>
      </SheetTrigger>
      <SheetContent
        className="h-[95%] gap-0 rounded-t-xl lg:h-auto lg:rounded-none"
        side={sheetSide}
        disableOverlay
        onInteractOutside={() => setOpen(false)}
      >
        <SheetHeader className="mb-4 px-4">
          <SheetHeaderLeftIcon onClick={() => setOpen(false)}>
            <FontAwesomeIcon icon={faClose} className="w-3" />
          </SheetHeaderLeftIcon>
          <SheetTitle className="text-content-lg">
            {t('words.filters')} {activeFilters?.length ? `(${activeFilters?.length})` : ''}
          </SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="flex w-full flex-col px-4 pt-0">
          {filters.map(({ id, name, label, options, accordion }) => {
            const column = name ? table.getColumn(name) : undefined
            const selectedValues: Set<string> = onFilterChange
              ? new Set(activeFilters || [])
              : new Set((column?.getFilterValue() as string[]) || [])

            return accordion ? (
              <Accordion.Root
                key={id}
                type="multiple"
                orientation="horizontal"
                value={accordionValue}
                onValueChange={setAccordionValue}
                className="mx-[-14px] border-t last:border-b"
              >
                <Accordion.Item value={label} className="w-full max-w-full border-0">
                  <Accordion.Trigger className="py-2">
                    <Accordion.Label>{t(label)}</Accordion.Label>
                  </Accordion.Trigger>
                  <Accordion.Content className="bg-background-base-neutral mt-1 border-t px-4 pb-0">
                    <DataTableFilterItem
                      hideHeader
                      key={id}
                      filterItem={{ id, label, options }}
                      selectedValues={selectedValues}
                      handleToggle={(value) => handleToggle(value, column, selectedValues)}
                    />
                  </Accordion.Content>
                </Accordion.Item>
              </Accordion.Root>
            ) : (
              <DataTableFilterItem
                className="mb-4"
                key={id}
                filterItem={{ id, label, options }}
                selectedValues={selectedValues}
                handleToggle={(value) => handleToggle(value, column, selectedValues)}
              />
            )
          })}
        </SheetCustomContent>
        <SheetFooter>
          <Button
            className="flex w-full py-4 font-semibold focus:!ring-2"
            variant="ghost-dark"
            onClick={() => (onFilterChange ? onFilterChange('') : table.resetColumnFilters())}
          >
            {t('players.clearFilters')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}
