import { create } from 'zustand'
import { FeatureFlagValue } from '@sweetspot/shared/data-access/api-platform'

interface FlagsStore {
  areFlagsLoading: boolean
  flags: Record<string, FeatureFlagValue>
  setFlags: (flags: Record<string, FeatureFlagValue>) => void
  setAreFlagsLoading: (areFlagsLoading: boolean) => void
}

export const useFlagsStore = create<FlagsStore>((set) => ({
  areFlagsLoading: true,
  flags: {},
  setFlags: (flags: Record<string, FeatureFlagValue>) => set({ flags }),
  setAreFlagsLoading: (areFlagsLoading: boolean) => set({ areFlagsLoading }),
}))
