import * as React from 'react'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { tv } from 'tailwind-variants'
import { cn } from '../../../utils'

type RootProps = React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>

const Root = React.forwardRef<React.ElementRef<typeof RadioGroupPrimitive.Root>, RootProps>(
  ({ className, ...props }, ref) => {
    return (
      <RadioGroupPrimitive.Root className={cn('flex flex-col', className)} {...props} ref={ref} />
    )
  }
)
Root.displayName = 'RadioGroup.Root'

const radioGroupItemVariants = tv({
  base: 'flex justify-center gap-4 h-touch-height-lg min-h-touch-height-sm items-center px-4',
  variants: {
    disabled: {
      true: 'text-text-pale',
      false:
        'hover:text-text-subtle active:text-text-medium focus-visible:ring-border-stroke-focused cursor-pointer focus-visible:ring-2 focus-visible:ring-inset outline-none',
    },
    error: {
      true: 'text-text-danger hover:text-text-danger active:text-text-danger focus-visible:ring-none focus-visible:ring-0',
      false: '',
    },
  },
  defaultVariants: {
    disabled: false,
    error: false,
  },
})

interface ItemProps extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> {
  label?: string | React.ReactNode
  error?: boolean
  isActive: boolean
}

const Item = React.forwardRef<React.ElementRef<typeof RadioGroupPrimitive.Item>, ItemProps>(
  ({ className, label, value, id, disabled, isActive, error, ...props }, ref) => {
    return (
      <RadioGroupPrimitive.Item
        ref={ref}
        className={cn(radioGroupItemVariants({ disabled, error }), className)}
        disabled={disabled}
        id={id}
        value={value}
        {...props}
      >
        {isActive ? (
          <span className="fa-regular fa-circle-dot text-text-dark" />
        ) : (
          <i className="fa-regular fa-circle text-text-dark" />
        )}

        {label && (
          <label
            htmlFor={id}
            className={cn('text-text-dark text-base font-medium', {
              'cursor-pointer': !disabled,
            })}
          >
            {label}
          </label>
        )}
      </RadioGroupPrimitive.Item>
    )
  }
)
Item.displayName = 'RadioGroup.Item'

export const RadioGroup = { Root, Item }
