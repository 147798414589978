import { Control, Controller, FieldError } from 'react-hook-form'
import {
  Input,
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputTrailingContainer,
} from '@sweetspot/scramble-ds'
import { Hint } from '@sweetspot/scramble-ds/atoms'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

interface InputFieldProps {
  name: string
  label: string
  control: Control<any>
  error: FieldError | undefined
  required: boolean
  className?: string
  placeholder?: string
  type?: string
}

export const InputField = ({
  name,
  label,
  control,
  error,
  required,
  className,
  placeholder,
  type = 'text',
}: InputFieldProps) => {
  const { t } = useTranslation()

  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev)

  return (
    <InputBase required={required} error={!!error} className={className}>
      <InputLabelContainer>{label}</InputLabelContainer>
      <InputContainer>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              rounding="rectangular"
              type={type === 'password' && showPassword ? 'text' : type}
              className="text-content-base mt-1"
              placeholder={placeholder}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
        {type === 'password' && (
          <InputTrailingContainer className="cursor-pointer" onClick={togglePasswordVisibility}>
            {showPassword ? (
              <i className="fa-regular fa-eye" />
            ) : (
              <span className="fa-regular fa-eye-slash" />
            )}
          </InputTrailingContainer>
        )}
      </InputContainer>

      {error?.message && <Hint variant="error" className="mt-1" hintText={t(error.message)} />}
    </InputBase>
  )
}
