import { FeatureFlagValue } from '@sweetspot/shared/data-access/api-platform'
import { useFlagsStore } from './useFlagsStore'

interface WithFeatureFlagsProps {
  featureFlags: Record<string, FeatureFlagValue>
}

/**
 * This higher order component is used to pass the feature flags to the wrapped component.
 * To be used in cases where a class component needs to access the feature flags.
 * @param WrappedComponent - The component to wrap.
 * @returns The wrapped component with featureFlags prop.
 */
export const withFeatureFlags =
  <P extends object>(WrappedComponent: React.ComponentType<P & WithFeatureFlagsProps>) =>
  (props: P) => {
    const { flags } = useFlagsStore()

    return <WrappedComponent {...props} featureFlags={flags} />
  }
