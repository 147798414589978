import { UniqueIdentifier } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { flexRender, Row } from '@tanstack/react-table'
import { CSS } from '@dnd-kit/utilities'
import { TableCell, TableRow } from './Table'
import { DataTableMovableCell } from './DataTableMovableCell'

export const DataTableDraggableRow = <TData extends { id: UniqueIdentifier }>({
  row,
  onRowClick,
}: {
  row: Row<TData>
  onRowClick?: (rowId: UniqueIdentifier) => void
}) => {
  const { setNodeRef, transform, isDragging, listeners, attributes } = useSortable({
    id: row.original.id,
  })

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: isDragging ? 'none' : 'opacity 0.2s ease',
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  }

  return (
    <TableRow
      ref={setNodeRef}
      style={style}
      data-state={row.getIsSelected() && 'selected'}
      onClick={() => onRowClick?.(row.original.id)}
    >
      {row.getVisibleCells().map((cell) => {
        if (cell.column.id === 'move') {
          return (
            <TableCell key={cell.id}>
              <DataTableMovableCell dragHandleProps={{ ...attributes, ...listeners }} />
            </TableCell>
          )
        }

        return (
          <TableCell key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        )
      })}
    </TableRow>
  )
}
