import ActionItemsPopover from './ActionItemsPopover'
import { useIsMobile } from '../../../../../../hooks/useIsMobile'
import ActionItemsBottomBar from './ActionItemsBottomBar'

const ResourcesActionItems = () => {
  const isMobile = useIsMobile()

  return isMobile ? <ActionItemsBottomBar /> : <ActionItemsPopover />
}

export default ResourcesActionItems
