import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  EmptyState,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'

import { useManageCourseContext } from '../ManageCourseProvider'
import CourseTabs from './CourseTabs'

const CourseTabsContainer = () => {
  const { t } = useTranslation()
  const { courses, areCoursesLoading, selectedCourse, toggleCreateCourseSideBar, refetchCourses } =
    useManageCourseContext()
  const currentClubName = useSelector((state: RootState) => {
    const currentClub = state?.golfClub?.list?.find(
      (club) => club.id === state?.golfClub?.selectedId || ''
    )
    return currentClub?.name as string
  })

  if (areCoursesLoading) {
    return (
      <EmptyState
        key="loading"
        iconName="fa-light fa-flip-both fa-loader bg-background-mono-lighter mb-4 flex rounded-full p-4"
        loading
      >
        <EmptyStateTitle>
          {t('settings.loadingCourses', { type: t('words.course_other').toLowerCase() })}
        </EmptyStateTitle>
        <EmptyStateDescription>{t('settings.bays.hangOnTight')}</EmptyStateDescription>
      </EmptyState>
    )
  }

  if (!courses?.length) {
    return (
      <EmptyState
        key="empty-course"
        className="max-w-[432px]"
        iconName="fa-kit fa-tee-regular bg-background-mono-lighter mb-4 flex rounded-full p-4"
      >
        <EmptyStateTitle>
          {t('settings.noCoursesCreated', { type: t('words.course_one').toLowerCase() })}
        </EmptyStateTitle>
        <EmptyStateDescription>
          {t('settings.createAtLeastOneCourse', {
            name: currentClubName,
            type: t('words.course_one').toLowerCase(),
          })}
        </EmptyStateDescription>
        <EmptyStateButton
          className="text-content-sm font-medium hover:no-underline focus:no-underline"
          onClick={toggleCreateCourseSideBar}
        >
          {t('settings.createFirstCourse', { type: t('words.course_one').toLowerCase() })}
        </EmptyStateButton>
      </EmptyState>
    )
  }

  return <CourseTabs currentCourse={selectedCourse} refetchCourses={refetchCourses} />
}

export default CourseTabsContainer
