import { useTranslation } from 'react-i18next'

import {
  EmptyStateDescription,
  EmptyState,
  EmptyStateButton,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'
import { CreateGitClubCredentialSheet } from './CreateGitClubCredentialSheet'

export const CreateGitClubCredential = () => {
  const { t } = useTranslation()

  return (
    <div className="flex w-full items-center justify-center">
      <EmptyState
        className="mb-10 max-w-[480px]"
        iconName={`fa-light fa-square-list bg-background-mono-lighter mb-4 flex rounded-full p-[22px]`}
      >
        <EmptyStateTitle>{t('settings.federationClubApi.gitCredentialListEmpty')}</EmptyStateTitle>
        <EmptyStateDescription>
          {t('settings.federationClubApi.gitCredentialListEmptyDesc')}
        </EmptyStateDescription>
        <CreateGitClubCredentialSheet>
          <EmptyStateButton className="text-content-lg h-touch-height-md font-medium hover:no-underline focus:no-underline">
            {t('settings.federationClubApi.gitCredentialListEmptyAdd')}
          </EmptyStateButton>
        </CreateGitClubCredentialSheet>
      </EmptyState>
    </div>
  )
}
