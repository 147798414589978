import {
  InputSelect,
  InputSelectContentHeader,
  InputSelectSearchableContent,
  InputSelectSearchableItem,
  InputSelectSearchableTrigger,
  ListItem,
  ListItemLeading,
  ListItemMainContent,
  ListItemParagraph,
  ListItemTrailing,
} from '@sweetspot/scramble-ds'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBadgeCheck, faTicketPerforated } from '@fortawesome/pro-light-svg-icons'
import {
  assignMembership,
  removeMembership,
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import { useMutation } from 'react-query'
import { useToasts } from 'react-toast-notifications'
import { Booking, MembershipCard } from '@sweetspot/shared/types'
import { useEligibleMembershipCards } from '@sweetspot/sweetspot-js/common/react-query/hooks/useMembershipCard'
import { Button } from '@sweetspot/scramble-ds/atoms'

interface AddSubscriptionSelectProps {
  orderBooking: Booking
  handleOrderBookingRefetch: () => void
  disabled?: boolean
}

export const AddSubscriptionSelect = ({
  orderBooking,
  handleOrderBookingRefetch,
  disabled,
}: AddSubscriptionSelectProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const hasAssignedRef = useRef(false)
  const userMembership = orderBooking?.items?.[0]?.slot?.membership
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [selectedSubscription, setSelectedSubscription] = useState<MembershipCard | null>(null)

  const customer = useMemo(() => {
    return orderBooking?.customer || null
  }, [orderBooking])

  const { data: eligiblePlayerSubscriptions } = useEligibleMembershipCards(orderBooking?.uuid, {
    enabled: !!orderBooking?.uuid && !!customer,
  })

  useEffect(() => {
    if (userMembership) {
      setSelectedSubscription({
        ...userMembership,
        membership: {
          name: userMembership?.name,
          type: userMembership?.type,
        },
      } as unknown as MembershipCard)
    } else {
      setSelectedSubscription(null)
    }
  }, [userMembership])

  const assignMembershipMutation = useMutation({
    mutationFn: (membershipCard: string) => assignMembership({ membershipCard }, orderBooking.uuid),
    onSuccess: async () => {
      addToast(t('editPeriodsNew.updated'), { appearance: 'success' })
      handleOrderBookingRefetch()
      setIsLoading(false)
    },
    onError: () => {
      addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
      setIsLoading(false)
    },
  })

  const removeMembershipMutation = useMutation({
    mutationFn: () => removeMembership(orderBooking.uuid),
    onSuccess: async () => {
      addToast(t('editPeriodsNew.updated'), { appearance: 'success' })
      handleOrderBookingRefetch()
      setIsLoading(false)
      hasAssignedRef.current = true
    },
    onError: () => {
      addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
      setIsLoading(false)
      hasAssignedRef.current = true
    },
  })

  const memberships = eligiblePlayerSubscriptions?.filter(
    (sub) => sub.membership.type === 'membership'
  )
  const passes = eligiblePlayerSubscriptions?.filter((sub) => sub.membership.type === 'pass')

  const handleSelectSubscription = (selectedMembership: MembershipCard | null) => {
    setSelectedSubscription(selectedMembership)
    if (!selectedMembership || !selectedMembership.membership.name) {
      removeMembershipMutation.mutate()
      return
    }
    assignMembershipMutation.mutate(selectedMembership.uuid)
  }

  if (!eligiblePlayerSubscriptions?.length) return

  return (
    <InputSelect withSearch>
      <InputSelectSearchableTrigger asChild>
        <Button
          variant="ghost-dark"
          size="small"
          type="button"
          disabled={!customer || isLoading || disabled}
          className="min-w-[170px]"
        >
          {isLoading ? (
            <div className="animate-[spin_3s_linear_infinite]">
              <i className="fa-regular text-text-dark text-brand-base fa-loader" />
            </div>
          ) : (
            <>
              <i className="fa-regular fa-plus" />
              {t('membershipPassText')}
            </>
          )}
        </Button>
      </InputSelectSearchableTrigger>
      <InputSelectSearchableContent
        usePortal={false}
        className="z-[10] max-h-full min-w-[300px] max-w-[var(--radix-popover-trigger-width)] overflow-hidden"
      >
        {(memberships ?? []).length > 0 && (
          <>
            <InputSelectContentHeader>{t('words.membership_other')}</InputSelectContentHeader>
            {memberships?.map((membership) => (
              <InputSelectSearchableItem
                key={membership.id}
                onSelect={() => {
                  if (selectedSubscription?.membership.name === membership?.membership.name) return
                  handleSelectSubscription(membership)
                  setIsLoading(true)
                }}
              >
                <ListItem className="ml-2 h-full w-full min-w-fit justify-between gap-2">
                  <ListItemLeading className="px-0">
                    <FontAwesomeIcon icon={faBadgeCheck} className="h-[18px] w-[18px]" />
                  </ListItemLeading>
                  <ListItemMainContent className="justify-center py-1">
                    <ListItemParagraph className="text-content-base">
                      {membership.membership.name}
                    </ListItemParagraph>
                  </ListItemMainContent>
                  {selectedSubscription?.membership.name === membership?.membership.name && (
                    <ListItemTrailing>
                      <i className="fa-regular fa-check mr-2 h-4 w-4" />
                    </ListItemTrailing>
                  )}
                </ListItem>
              </InputSelectSearchableItem>
            ))}
          </>
        )}
        {(passes ?? []).length > 0 && (
          <>
            <InputSelectContentHeader>{t('words.pass_other')}</InputSelectContentHeader>
            {passes?.map((pass) => (
              <InputSelectSearchableItem
                key={pass.id}
                onSelect={() => {
                  if (selectedSubscription?.membership.name === pass?.membership.name) return
                  handleSelectSubscription(pass)
                  setIsLoading(true)
                }}
              >
                <ListItem className="ml-2 h-full !w-full !min-w-full justify-between gap-2">
                  <ListItemLeading className="mt-1.5 px-0">
                    <FontAwesomeIcon icon={faTicketPerforated} className="h-5 w-5" />
                  </ListItemLeading>
                  <ListItemMainContent className="justify-center py-1">
                    <ListItemParagraph className="text-content-base">
                      {pass.membership.name}
                    </ListItemParagraph>
                  </ListItemMainContent>
                  {selectedSubscription?.membership.name === pass?.membership.name && (
                    <ListItemTrailing>
                      <i className="fa-regular fa-check mr-2 h-4 w-4" />
                    </ListItemTrailing>
                  )}
                </ListItem>
              </InputSelectSearchableItem>
            ))}
          </>
        )}
        <InputSelectSearchableItem
          onSelect={() => {
            if (!selectedSubscription) return
            handleSelectSubscription(null)
            setIsLoading(true)
          }}
        >
          <ListItem className="ml-3 h-full !w-full !min-w-full justify-between gap-2">
            <ListItemMainContent className="justify-center py-1">
              <ListItemParagraph className="text-content-base">{t('words.none')}</ListItemParagraph>
            </ListItemMainContent>
            {!selectedSubscription && (
              <ListItemTrailing>
                <i className="fa-regular fa-check mr-4 h-4 w-4" />
              </ListItemTrailing>
            )}
          </ListItem>
        </InputSelectSearchableItem>
      </InputSelectSearchableContent>
    </InputSelect>
  )
}
