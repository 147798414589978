import React, { useMemo, useEffect, useState } from 'react'

import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'
import cx from 'classnames'

import { ReactComponent as MembershipIcon } from '@sweetspot/club-portal-legacy/resources/images/membership-icon.svg'

import useWizard from '@sweetspot/club-portal-legacy/hooks/useWizard'
import usePrevious from '@sweetspot/club-portal-legacy/hooks/usePrevious'

// Components
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import ModalContainer from '@sweetspot/sweetspot-js/common/components/ModalContainer'
import SimpleTabs from '@sweetspot/sweetspot-js/common/components/SimpleTabs'

import GeneralSettings from '@sweetspot/club-portal-legacy/components/Wizard/containers/membership/GeneralSettings'
import PromotionsSettings from '@sweetspot/club-portal-legacy/components/Wizard/containers/membership/PromotionsSettings'
import PricingSettings from '@sweetspot/club-portal-legacy/components/Wizard/containers/membership/PricingSettings'
import ReservationPolicySettings from '@sweetspot/club-portal-legacy/components/Wizard/containers/membership/ReservationPolicySettings'

import styles from './styles.module.scss'

import { CopyToClipboard } from 'react-copy-to-clipboard'

// Validation
import {
  validateMembership,
  validatePromotions,
  validateMembershipPrePublish,
} from './validation.js'
import { Tooltip } from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { getClubWebBookingSetting } from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
import { QUERY_KEYS } from '@sweetspot/shared/util/constants'

// Default values
const DEFAULT_MEMBERSHIP = require('./defaultValues/defaultMembership.json')

const MembershipWizardModal = () => {
  const history = useHistory()
  const [isCopied, setIsCopied] = useState(false)

  const handleOnCloseModal = () => {
    history.push('/memberships', { shouldReload: true })
  }

  const currentClubId = useSelector((state) => state.golfClub.selectedId)

  const { data: webBookingSlug } = useQuery(
    [QUERY_KEYS.WEB_BOOKING_SETTINGS, currentClubId],
    () => getClubWebBookingSetting(currentClubId),
    {
      select: (data) => data?.slug,
    }
  )

  const {
    currentStep,
    loading,
    currentEntity: currentMembership,
    values,
    errors,
    globalErrors,
    setEntityValue,
    updateExistingEntity: updateMembership,
    createNewEntity: createNewMembership,
    setStep,
    deleteExistingEntity,
    activateExistingEntity,
    clearGlobalErrors,
    migrateMembershipAutorenewalSettings,
  } = useWizard({
    handleCloseModal: handleOnCloseModal,
    defaultEntity: DEFAULT_MEMBERSHIP,
    type: 'membership',
    entityIdParamKey: 'membershipId',
    validateOnNew: validateMembership,
    validateOnUpdate: validateMembership,
    validatePromotions: validatePromotions,
    validateOnActivate: validateMembershipPrePublish,
    reviewStep: 1,
  })
  const [disableHeaderButtons, setdisableHeaderButtons] = useState(false)

  const { t } = useTranslation()
  const { addToast } = useToasts()

  const prevGlobalErrors = usePrevious(globalErrors)

  /********************************/
  /******* Memoized Values ********/
  /********************************/
  const PROGRESS_STEPS = useMemo(
    () => [
      {
        id: 1,
        stepNumber: 1,
        label: t('wizard.general'),
        longLabel: t('wizard.generalSettings'),
      },
      {
        id: 2,
        stepNumber: 2,
        label: t('wizard.reservationPolicies'),
      },
      {
        id: 3,
        stepNumber: 3,
        label: t('wizard.promotions'),
      },
      {
        id: 4,
        stepNumber: 4,
        label: t('wizard.pricing'),
      },
    ],
    [t]
  )

  const getErrorString = (string) => {
    if (string === 'validation-error') return t('wizard.validationError')
    if (string === 'fees-required') return t('errors.atleastOneFeeIsRequired')
    if (string === 'promotions-required') return t('errors.atleastOnePromotionIsRequired')
    return ''
  }

  const displayGlobalErrorToast = () => {
    if (!globalErrors.length) return
    if (globalErrors.length > 2)
      addToast(<p>{getErrorString('validation-error')}</p>, { appearance: 'error' })
    else addToast(<p>{getErrorString(globalErrors[0])}</p>, { appearance: 'error' })
  }

  useEffect(() => {
    if (globalErrors.length && globalErrors[0] !== prevGlobalErrors[0]) {
      displayGlobalErrorToast()
    }
  }, [globalErrors, prevGlobalErrors])

  const handleOnSave = () => {
    const { state } = currentMembership
    if (loading) return

    switch (currentStep) {
      case 1:
        if (!state) {
          createNewMembership()
        } else {
          updateMembership()
        }
        break
      case 2:
        // Save booking policies
        break
      case 3:
        // Save promotions
        break
      default:
        break
    }
  }

  const hasErrors = () => {
    let errorTabs = []

    if (!globalErrors.length) return errorTabs
    if (globalErrors.includes('validation-error')) errorTabs.push(1)
    if (globalErrors.includes('promotions-required')) errorTabs.push(3)
    else if (
      Object.keys(errors).filter((key) => key.includes('promotions.') && errors[key].length > 0)
        .length > 0
    )
      errorTabs.push(3)

    if (globalErrors.includes('fees-required')) errorTabs.push(4)
    else if (
      Object.keys(errors).filter((key) => key.includes('fees.') && errors[key].length > 0).length >
      0
    )
      errorTabs.push(4)

    return errorTabs
  }

  const handleOnActivate = () => {
    setdisableHeaderButtons(true)

    clearGlobalErrors()
    activateExistingEntity((success) => {
      if (success) {
        const { name } = currentMembership
        addToast(
          <p>
            {name ? <span> {name} </span> : ''}
            {t('wizard.isPublished').toLowerCase()}
          </p>,
          { appearance: 'success' }
        )
      } else {
        setdisableHeaderButtons(false)
        displayGlobalErrorToast()
      }
    })
  }

  const handleOnDelete = () => {
    setdisableHeaderButtons(true)
    deleteExistingEntity((success) => {
      if (success) {
        addToast(<p>{t('wizard.membershipDeleted')}.</p>, { appearance: 'warning' })
        history.push('/memberships', { shouldReload: true })
      } else setdisableHeaderButtons(false)
    })
  }

  const handleCopy = () => {
    setIsCopied(true)

    setTimeout(() => {
      setIsCopied(false)
    }, 1500)
  }

  const getMembershipPath = () => {
    return currentMembership?.type === 'pass' ? 'passes' : 'memberships'
  }

  /**********************************/
  /*********** Render  **************/
  /**********************************/

  const renderModalHeader = () => {
    const errorItems = hasErrors()
    return (
      <div className={styles.tabsContainer}>
        <SimpleTabs
          currentItem={currentStep}
          onClick={setStep}
          items={PROGRESS_STEPS}
          globalErrors={globalErrors}
          errors={errors}
          errorItems={errorItems}
          width={70}
          disabled={!currentMembership.state}
        />
        <div className="flex w-auto flex-row items-center justify-end">
          {currentMembership?.is_sell_membership_enabled &&
            currentMembership?.is_active &&
            currentMembership?.state === 'published' && (
              <div className={cx(styles.checkoutLinkCopy, 'mr-3')}>
                <div
                  data-tooltip-content={
                    isCopied ? `${t('words.copied')}!` : t('sentences.copyCheckoutLink')
                  }
                  data-tooltip-id={'clipboard_copy'}
                >
                  <CopyToClipboard
                    text={`https://${
                      process.env.REACT_APP_API_ENV === 'DEV' ? 'dev.' : ''
                    }book.sweetspot.io/clubs/${webBookingSlug}/${getMembershipPath()}/${
                      currentMembership.uuid
                    }?private=true`}
                    onCopy={() => handleCopy()}
                  >
                    <p className={'space-x-2'}>
                      <FontAwesomeIcon icon={faCopy} />
                      <span>{t('sentences.checkoutLink')}</span>
                    </p>
                  </CopyToClipboard>
                </div>
                <Tooltip
                  id="clipboard_copy"
                  place="bottom"
                  className={styles.tooltip}
                  delayShow={100}
                />
              </div>
            )}
          <div className={styles.uuidCopy}>
            <div
              data-tooltip-content={
                isCopied ? `${t('words.copied')}!` : t('sentences.copyMemberUuid')
              }
              data-tooltip-id={'clipboard_copy'}
            >
              <CopyToClipboard text={currentMembership.uuid} onCopy={() => handleCopy()}>
                <p className={'space-x-2'}>
                  <FontAwesomeIcon icon={faCopy} />
                  <span>UUID</span>
                </p>
              </CopyToClipboard>
            </div>
            <Tooltip
              id="clipboard_copy"
              place="bottom"
              className={styles.tooltip}
              delayShow={100}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderHeaderButtons = () => {
    let buttons = []

    if (currentMembership.state && currentMembership.state === 'draft') {
      buttons = [
        <Button
          key={'delete-button'}
          size="small"
          theme="danger"
          width="third"
          text={t('delete')}
          disabled={disableHeaderButtons}
          onClick={() => handleOnDelete()}
        />,
        <Button
          key={'publish-button'}
          size="small"
          width="third"
          text={t('pricing.publish')}
          disabled={disableHeaderButtons}
          onClick={() => handleOnActivate()}
        />,
      ]
    }
    return buttons
  }

  return (
    <ModalContainer
      isFullsize={false}
      width={'half'}
      height={'auto'}
      onClose={handleOnCloseModal}
      theme="wizard"
      headerChildren={renderModalHeader()}
      headerBackgroundColor={'#4F5B6D'}
      headerColor="#FFFFFF"
      headerTitle={
        <div className={styles.membershipTitle}>
          <MembershipIcon />
          <span>{values?.name || t('wizard.membershipTitle')}</span>
        </div>
      }
      headerButtons={renderHeaderButtons()}
    >
      {currentStep === 1 ? (
        <GeneralSettings
          values={values}
          currentMembership={currentMembership}
          errors={errors}
          onSetMembershipValue={setEntityValue}
          disabled={!currentMembership?.state}
          loading={loading}
          handleOnSave={handleOnSave}
          migrateMembershipAutorenewalSettings={migrateMembershipAutorenewalSettings}
        />
      ) : null}
      {currentStep === 2 ? (
        <ReservationPolicySettings membershipId={currentMembership?.id} />
      ) : null}
      {currentStep === 3 ? (
        <PromotionsSettings
          membershipId={currentMembership.id}
          membershipType={currentMembership.type}
        />
      ) : null}
      {currentStep === 4 ? (
        <PricingSettings
          membershipFees={values?.fees || []}
          membershipId={values.id}
          disabled={!currentMembership?.state}
          membershipType={currentMembership?.type}
          updateMembership={setEntityValue}
        />
      ) : null}
    </ModalContainer>
  )
}

export default MembershipWizardModal
