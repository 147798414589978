import { request, apiPlatformClient } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import { Me } from './types'

export const getMe = (): Promise<Me> => {
  const URL = `${getApiPlatformBaseUrl()}/auth/club-users/me`

  return request(URL)
}

export const getMyRoles = (): Promise<unknown> => {
  const URL = `${getApiPlatformBaseUrl()}/club-users/my-roles`

  return request(URL)
}
/**
 * Logs in a user using their email address.
 * @param email - The email address of the user.
 * @returns A Promise that resolves to the login response.
 */
export const loginWithEmail = ({ email }: { email: string }) => {
  return apiPlatformClient().POST(`/api/auth/player/2fa-email-login`, {
    body: { email },
  })
}

/**
 * Verifies the passcode for a given email.
 * @param email - The email address to verify.
 * @param code - The passcode to verify.
 * @returns A Promise that resolves to the result of the verification.
 */
export const verifyPasscode = ({ email, code }: { email: string; code: string }) => {
  return apiPlatformClient().POST(`/api/auth/player/2fa-email-check`, {
    body: { email, code },
  })
}

/**
 * Uploads a profile image file.
 * @param file - The file to be uploaded.
 * @returns A Promise that resolves to the response from the API.
 */
export const uploadProfileImage = (file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  return apiPlatformClient({ requestContentType: 'multipart/form-data' }).POST(
    `/api/auth/player/change-profile-picture`,
    {
      body: formData,
    }
  )
}

/**
 * Changes the email address of the player.
 * @param {Object} options - The options for changing the email address.
 * @param {string} options.email - The new email address.
 * @returns {Promise} A promise that resolves when the email address is successfully changed.
 */
export const changeEmail = ({ email, token }: { email: string; token: string }) => {
  return apiPlatformClient().POST(`/api/auth/player/change-email`, {
    headers: { Authorization: `Bearer ${token}` },
    body: { new_email: email },
  })
}

/**
 * Verifies the passcode for a changed email.
 * @param {Object} params - The parameters for verifying the passcode.
 * @param {string} params.code - The passcode to verify.
 * @param {string} params.token - The token for authorization.
 * @returns {Promise<any>} - A promise that resolves to the verification result.
 */
export const verifyPasscodeForChangedEmail = ({ code, token }: { code: string; token: string }) => {
  return apiPlatformClient().POST(`/api/auth/player/verify-change-email`, {
    headers: { Authorization: `Bearer ${token}` },
    body: { code },
  })
}

export const getPlayerProfileQRCodeByUuid = ({
  playerUuid,
}: {
  playerUuid: string
}): Promise<{
  qr_code: {
    id: string
    player_id: string
    qr_code_url: string
    expired_at: string
    is_expired: boolean
    account: {
      id: string
      first_name: string
      last_name: string
      email: string
    }
  }
}> => {
  const url = `${getApiPlatformBaseUrl()}/auth/players/${playerUuid}/qr-code`
  return request(url)
}
