import { useState, useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Button } from '@sweetspot/scramble-ds/atoms'
import {
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputLeadingContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  ListItemTrailing,
} from '@sweetspot/scramble-ds'
import { VenuesIcon } from '@sweetspot/club-portal/feature/venues'
import { GolfCourse, GolfCourseTypes } from '@sweetspot/shared/types'

import { useManageCourseContext } from '../ManageCourseProvider'

const CourseSelector = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { courses, selectedCourse, setSelectedCourse, toggleCreateCourseSideBar } =
    useManageCourseContext()

  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!!setSelectedCourse && !selectedCourse && courses?.length) {
      setSelectedCourse(courses[0])
    }
  }, [courses, selectedCourse, setSelectedCourse])

  // set selectedCourse based on hash
  useEffect(() => {
    const hash = history.location.hash
    const selected = courses?.find((course) => `#${course.id}` === hash)
    if (setSelectedCourse && selected) {
      setSelectedCourse(selected)
      setSearch('')
    }
  }, [history.location.hash, courses, setSelectedCourse])

  const coursesToRender = useMemo(
    () => courses?.filter((course) => course.name.toLowerCase().includes(search.toLowerCase())),
    [search, courses]
  )

  const handleValueChange = useCallback(
    (newValue: string) => {
      if (newValue === 'new') {
        toggleCreateCourseSideBar()
        return
      }
      const selected = courses?.find((course: GolfCourse) => `${course.id}` === newValue)
      if (setSelectedCourse && selected) {
        const path = history.location.pathname
        history.push(`${path}#${newValue}`)
      }
    },
    [courses, setSelectedCourse, toggleCreateCourseSideBar, history]
  )

  const value = useMemo(
    () => courses?.find((course) => course.id === selectedCourse?.id)?.name,
    [courses, selectedCourse?.id]
  )

  return (
    <InputSelect value={value} onValueChange={handleValueChange}>
      <InputBase>
        <InputLabelContainer className="mb-0.5">{t('words.course_one')}</InputLabelContainer>
        <InputSelectTrigger asChild>
          <InputContainer className="cursor-pointer">
            <InputLeadingContainer>
              <VenuesIcon courseType={GolfCourseTypes.COURSE} type="default" />
            </InputLeadingContainer>
            <InputSelectStatefulInput
              value={value}
              onChange={() => null}
              onClick={() => setSearch('')}
              className="text-content-base text-ellipsis px-10 "
              readOnly
            />
            <InputSelectTrailingContainer />
          </InputContainer>
        </InputSelectTrigger>
      </InputBase>

      <InputSelectContent>
        <InputSelectContentView>
          {coursesToRender?.map((course) => (
            <InputSelectItem key={course.id} value={`${course.id}`}>
              <ListItem className="ml-4 h-full w-[376px] justify-center gap-2">
                <ListItemMainContent className="flex-row justify-between pr-2">
                  <ListItemParagraph className="text-content-base">{course.name}</ListItemParagraph>
                  <ListItemTrailing>
                    {selectedCourse?.id === course.id && (
                      <i className="fa-regular fa-check h-4 w-4" />
                    )}
                  </ListItemTrailing>
                </ListItemMainContent>
              </ListItem>
            </InputSelectItem>
          ))}
        </InputSelectContentView>
        <InputSelectContentView className="mt-0.5 min-h-12">
          <InputSelectItem value="new" className="flex justify-center">
            <Button
              variant="link-dark"
              className="text-content-lg text-text-dark h-12 w-full font-medium hover:no-underline focus:no-underline"
            >
              <i className="fa-regular fa-circle-plus " />
              {t('words.new')}
            </Button>
          </InputSelectItem>
        </InputSelectContentView>
      </InputSelectContent>
    </InputSelect>
  )
}

export default CourseSelector
