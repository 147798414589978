import jwtDecode from 'jwt-decode'
import i18n from 'i18next'
import { ROLES_DEF } from '@sweetspot/sweetspot-js/features/userAccess/constants/roles'
import { CONSTANTS } from '../constants'
import { removeLocalStorage } from '@sweetspot/shared/util/local-storage'
import { APP_NAMES, API_PORTAL_VALUES, STORAGE_KEYS } from '@sweetspot/shared/util/constants'
import { clubUserLogin } from '@sweetspot/sweetspot-js/features/auth/services/api-platform'
import { getMe } from '@sweetspot/shared/data-access/api-platform'
import { persistor } from './../index.js'

import { addToast, setLoading } from '@sweetspot/club-portal-legacy/store/actions/appStateActions'
import { selectGolfClub } from '@sweetspot/club-portal-legacy/store/actions/golfClubActions'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { queryClubs } from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
import { trackClubPropertiesToAmplitude } from '@sweetspot/club-portal-legacy/utils/amplitude'
import { AMPLITUDE_USER_PROPERTIES } from '@sweetspot/shared/util/constants'
import { getCurrentAppName } from '@sweetspot/shared/util/functions'
import {
  clearAllStoredTokens,
  storeAuthToken,
  storeRefreshToken,
} from '@sweetspot/shared/data-access/api-platform-auth'
import { queryRanges } from '@sweetspot/sweetspot-js/features/ranges/services'
import { updateUserProperty } from '@sweetspot/shared/util/amplitude'

export function login(email, password, locationState) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading(true))
      const portal = getCurrentAppName() === APP_NAMES.CLUB_PORTAL ? API_PORTAL_VALUES.CLUB : null
      clubUserLogin({ email, password, portal })
        .then(async (res) => {
          persistor.flush()

          const loginData = {
            loggedIn: true,
            isSuperAdmin: false,
          }

          const { token, refresh_token: refreshToken } = res
          storeAuthToken(token)
          storeRefreshToken(refreshToken)

          const { roles_current_club: roles, current_club_id } = jwtDecode(token)
          const isSuperAdmin = roles && roles.includes('ROLE_SA')
          if (isSuperAdmin) loginData.isSuperAdmin = true

          const params = new URLSearchParams(locationState?.search || window.location.search)
          const clubIdFromUrl = params.get('club_id')
          const toptracerSiteId = params.get('trms_site_id')

          const meRes = await dispatch(me())
          dispatch({
            type: CONSTANTS.AUTH.MY_ROLES,
            payload: roles,
          })

          let range

          if (toptracerSiteId) {
            const [rangesRes, rangesErr] = await to(queryRanges({ toptracerSiteId }))
            if (!rangesErr && rangesRes?.ranges) {
              range = rangesRes.ranges[0]
            }
          }

          const clubId = range?.organization_id || clubIdFromUrl || current_club_id

          if (isSuperAdmin) {
            // SA
            const [res, err] = await to(
              queryClubs({
                page: null,
                limit: 9999,
              })
            )
            if (!res || err) {
              dispatch(addToast('fetchGolfclubsError', 'error'))
              throw err
            } else {
              dispatch({
                type: CONSTANTS.GOLF_CLUB.GET_LIST,
                payload: res,
              })
              const golfClub =
                res.find((club) => club.id === clubId || club.uuid === clubId) || res?.[0] || null
              if (!golfClub) {
                locationState = {
                  pathname: '/404',
                }
              } else {
                dispatch(selectGolfClub(golfClub?.id, false, true, locationState))
                trackClubPropertiesToAmplitude(golfClub)
              }
            }
          } else {
            // CA
            const [res, err] = await to(
              queryClubs({
                page: null,
                limit: 9999,
                'admins.id': meRes?.id,
              })
            )
            if (!res || err) {
              dispatch(addToast('fetchGolfclubsError', 'error'))
              throw err
            } else {
              dispatch({
                type: CONSTANTS.GOLF_CLUB.GET_LIST,
                payload: res,
              })
              const golfClub =
                res.find((club) => club.id === clubId || club.uuid === clubId) || res?.[0] || null
              if (!golfClub) {
                locationState = {
                  pathname: '/404',
                }
              } else {
                dispatch(selectGolfClub(golfClub?.id, false, true, locationState))
                trackClubPropertiesToAmplitude(golfClub)
              }
            }
          }

          dispatch({
            type: CONSTANTS.AUTH.LOGIN,
            payload: loginData,
          })
          dispatch(setLoading(false))

          updateUserProperty(
            AMPLITUDE_USER_PROPERTIES.USER_ROLE,
            i18n.t(ROLES_DEF[roles[0]].label, { lng: 'en' })
          )
          return resolve(loginData)
        })
        .catch((err) => {
          dispatch(setLoading(false))
          return reject(err)
        })
    })
  }
}

export function logout() {
  return (dispatch) => {
    return new Promise((resolve) => {
      removeLocalStorage('selected-statistics-courses')
      clearAllStoredTokens()
      removeLocalStorage(STORAGE_KEYS.PERSIST)

      dispatch({
        type: CONSTANTS.AUTH.LOGOUT,
      })

      persistor.flush()

      return resolve()
    })
  }
}

export function me() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      getMe()
        .then((res) => {
          let meObject = {
            id: res.id,
            uuid: res.uuid,
            email: res.email,
            firstName: res.first_name,
            lastName: res.last_name,
            lang: res.language,
          }

          dispatch({
            type: CONSTANTS.AUTH.ME,
            payload: meObject,
          })

          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('userCheckError', 'error'))
          dispatch(logout())

          return reject(err)
        })
    })
  }
}
