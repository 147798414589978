import { useCallback, useMemo } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { formatInTimeZone } from 'date-fns-tz'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation } from 'swiper/modules'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { Button } from '@sweetspot/scramble-ds/atoms'
import {
  ListItem,
  ListItemLeading,
  ListItemMainContainer,
  ListItemMainContent,
  ListItemMainLabel,
  ListItemSecondaryLabel,
} from '@sweetspot/scramble-ds'
import { cn } from '@sweetspot/scramble-ds/utils'
import { GolfCourseTypes, TeeTime, TrackingTech } from '@sweetspot/shared/types'
import { priceToLocal } from '@sweetspot/shared/util/functions'
import { GolfCourse, Space } from '@sweetspot/shared/types'
import { getPriceObject } from '@sweetspot/sweetspot-js/features/teeTimes/js/utils'
import ButtonDock from '@sweetspot/sweetspot-js/common/components/ButtonDock/ButtonDock'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'

import { SelectedTeeTimesMap } from '../../types'
import BayTechLogo from '../BayTechLogo/BayTechLogo'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'

type SimRangeFooterProps = {
  course?: GolfCourse
  onConfirm: () => void
  onCancel: () => void
  isReserving: boolean
  selectedTeeTimes: TeeTime[]
  spaces: Space[]
  isNewRange: boolean
  isShown: boolean
  isConflict: boolean
}

const SimRangeFooter = ({
  course,
  selectedTeeTimes,
  spaces,
  onConfirm,
  onCancel,
  isReserving,
  isNewRange,
  isShown,
  isConflict,
}: SimRangeFooterProps) => {
  const { t } = useTranslation()
  const [currencyCode] = useClubCurrency()

  const selectedTeeTimesMap: SelectedTeeTimesMap = useMemo(
    () =>
      selectedTeeTimes.reduce<SelectedTeeTimesMap>((acc, teeTime) => {
        const spaceId = isNewRange ? teeTime.space.uuid : teeTime.space.id
        return {
          ...acc,
          [spaceId]: [...(acc[spaceId] || []), teeTime],
        }
      }, {}),
    [selectedTeeTimes, isNewRange]
  )

  const [selectedTeeTimesArr, selectedSpacesCount]: [TeeTime[], number] = useMemo(() => {
    const teeTimes: TeeTime[] = []
    let spaceCount = 0

    Object.keys(selectedTeeTimesMap).forEach((spaceId: string) => {
      if (selectedTeeTimesMap[spaceId].length === 0) {
        return
      }

      spaceCount++
      selectedTeeTimesMap[spaceId].forEach((teeTime) => {
        teeTimes.push({
          ...teeTime,
          space: spaces.find((s) =>
            isNewRange ? s.uuid === teeTime.space.uuid : s.id === teeTime.space.id
          ) as Space,
        })
      })
    })
    return [teeTimes, spaceCount]
  }, [selectedTeeTimesMap, spaces, isNewRange])

  const proceedBtnText = useMemo(() => {
    let text = `${t('sentences.proceedWith')} ${selectedSpacesCount}`
    if (course?.type === GolfCourseTypes.DRIVING_RANGE) {
      text += ` ${t('words.bay', { count: selectedSpacesCount }).toLowerCase()}`
      return text
    }
    if (course?.type === GolfCourseTypes.SIMULATOR) {
      text += ` ${t('words.simulator', { count: selectedSpacesCount }).toLowerCase()}`
      return text
    }
    return t('words.proceed')
  }, [selectedSpacesCount, course?.type, t])

  const getBayIcon = useCallback(
    (teeTime: TeeTime) => {
      if (isNewRange) {
        return (
          <ListItemLeading>
            <BayTechLogo
              technology={
                teeTime.space?.ball_tracking_technology?.tracking_provider as TrackingTech
              }
              noneClassName="text-xl"
              className="h-6 w-6"
            />
          </ListItemLeading>
        )
      }

      if (course?.type === GolfCourseTypes.DRIVING_RANGE) {
        return (
          <ListItemLeading>
            <i className={'fa-kit fa-range-regular text-xl [&_svg]:transform'} />
          </ListItemLeading>
        )
      }

      if (course?.type === GolfCourseTypes.SIMULATOR) {
        return (
          <ListItemLeading>
            <i className={'fa-kit fa-sim-regular text-xl [&_svg]:transform'} />
          </ListItemLeading>
        )
      }

      if (course?.type === GolfCourseTypes.PRO) {
        return (
          <ListItemLeading>
            <i className={'fa-kit fa-pro-light text-xl [&_svg]:scale-125 [&_svg]:transform'} />
          </ListItemLeading>
        )
      }

      return null
    },
    [course?.type, isNewRange]
  )

  const showBayFloorLabel = useMemo(
    () => selectedTeeTimesArr?.some((teeTime) => teeTime.space?.floor > 0),
    [selectedTeeTimesArr]
  )

  const totalPriceString = useMemo(() => {
    if (selectedTeeTimes) {
      let total = 0
      let currency = ''
      selectedTeeTimes.forEach((teeTime) => {
        const priceObject = getPriceObject(teeTime, course?.is_use_dynamic_pricing)
        total += priceObject?.amount || 0

        if (!currency) currency = priceObject?.currency || currencyCode
      })

      return priceToLocal(total, currency, true)
    }
    return '-'
  }, [selectedTeeTimes, course, currencyCode])

  const getSpaceName = useCallback(
    (teeTime: TeeTime) => {
      if (isNewRange) {
        return (
          <>
            {`${t('words.bay')} ${teeTime.space?.bay_number}`}
            {showBayFloorLabel && (
              <span className="text-text-subtle">{` (${t('words.floor').toLowerCase()} ${
                teeTime.space?.floor
              })`}</span>
            )}
          </>
        )
      }
      return teeTime.space?.name
    },
    [isNewRange, showBayFloorLabel, t]
  )

  return (
    <ButtonDock
      className="justify-between md:justify-between"
      dockClassName="p-4 pr-10"
      isShown={isShown}
    >
      <div className="flex items-center gap-2 overflow-hidden [&_.swiper-button-lock]:!hidden">
        <Button
          size="small"
          variant="inverted"
          rounding="pill"
          className="px-md ss-swiper-button-prev"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="h-4 w-4" />
        </Button>

        <Swiper
          slidesPerView="auto"
          spaceBetween={8}
          freeMode={{ sticky: true }}
          navigation={{
            nextEl: '.ss-swiper-button-next',
            prevEl: '.ss-swiper-button-prev',
          }}
          modules={[FreeMode, Navigation]}
        >
          {selectedTeeTimesArr.map((teeTime) => {
            const bayIcon = getBayIcon(teeTime)
            return (
              <SwiperSlide key={teeTime.id} className={isNewRange ? '!w-auto' : '!w-[190px]'}>
                <ListItem
                  className={cn(
                    'border-border-stroke-clean w-full min-w-fit gap-2 rounded-md border',
                    bayIcon ? 'pl-2' : 'pl-4'
                  )}
                >
                  {bayIcon}
                  <ListItemMainContainer>
                    <ListItemMainContent className={cx('py-md pr-lg gap-1')}>
                      <ListItemMainLabel className="text-text-dark inline w-[120px] truncate">
                        {getSpaceName(teeTime)}
                      </ListItemMainLabel>
                      <ListItemSecondaryLabel className="text-text-subtle font-normal">
                        {formatInTimeZone(teeTime.from, course?.timezone as string, 'HH:mm')}
                        {' - '}
                        {formatInTimeZone(teeTime.to, course?.timezone as string, 'HH:mm')}
                      </ListItemSecondaryLabel>
                    </ListItemMainContent>
                  </ListItemMainContainer>
                </ListItem>
              </SwiperSlide>
            )
          })}
        </Swiper>

        <Button
          size="small"
          variant="inverted"
          rounding="pill"
          className="px-md ss-swiper-button-next"
        >
          <FontAwesomeIcon icon={faArrowRight} className="h-4 w-4" />
        </Button>
      </div>

      <div className="flex w-auto flex-row justify-end gap-4">
        <div className="min-w-[200px]">
          <div className="flex h-full flex-col justify-center text-right font-semibold">
            <p className="text-nowrap">
              {t('pricing.basePrice')}
              {': '}
              <span data-testid="base-price">{totalPriceString}</span>
            </p>
          </div>
        </div>

        <div className="flex items-center gap-4">
          <Button
            size="large"
            variant="ghost-dark"
            onClick={onCancel}
            disabled={isReserving}
            className="grow"
            data-testid="cancel-button"
          >
            {t('words.cancel')}
          </Button>
          <Button
            size="large"
            onClick={onConfirm}
            disabled={isReserving || isConflict}
            className="grow"
          >
            <span className="inline" data-testid="proceed-button">
              {proceedBtnText}
            </span>
          </Button>
        </div>
      </div>
    </ButtonDock>
  )
}

export default SimRangeFooter
