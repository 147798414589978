import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useMutation, useQuery } from 'react-query'
import _isEqual from 'lodash/isEqual'

import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import { GolfCourse, GolfCourseTypes } from '@sweetspot/shared/types'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { queryCourses } from '@sweetspot/sweetspot-js/features/courses/services/api-platform'
import { _getSpecificGolfCourse } from '@sweetspot/club-portal-legacy/services/golfCourseApi'

const useCourses = () => {
  const currentClubId = useSelector((state: RootState) => state.golfClub.selectedId)
  const [selectedCourse, setSelectedCourse] = useState<GolfCourse | undefined>()

  const {
    data,
    isLoading: areCoursesLoading,
    refetch,
  } = useQuery(
    [CLUB_QUERIES.COURSES, GolfCourseTypes.COURSE, currentClubId],
    () => queryCourses({ 'club.id': currentClubId, type: GolfCourseTypes.COURSE }),
    {
      enabled: !!currentClubId,
    }
  )

  const getSpecificCourseMutation = useMutation((id?: number) => _getSpecificGolfCourse(id))

  const getSpecificCourse = useCallback(
    async (id?: number) => {
      const response = await getSpecificCourseMutation.mutateAsync(id)
      return response
    },
    [getSpecificCourseMutation]
  )

  // if courses are updated, update the selected course if needed
  useEffect(() => {
    if (selectedCourse) {
      const updatedCourse = data?.find((course: GolfCourse) => course.id === selectedCourse.id)
      if (updatedCourse && !_isEqual(selectedCourse, updatedCourse)) {
        setSelectedCourse(updatedCourse)
      }
    }
  }, [data, selectedCourse])

  return {
    courses: (data as GolfCourse[]) || [],
    areCoursesLoading,
    selectedCourse,
    setSelectedCourse,
    refetchCourses: refetch,
    getSpecificCourse,
  }
}

export default useCourses
