import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSliders } from '@fortawesome/pro-regular-svg-icons'

import { useRangeFilter } from '../../hooks'
import { Badge } from '@sweetspot/scramble-ds/atoms'

interface FilterButtonProps {
  disabled: boolean
}

const FilterButton = ({ disabled }: FilterButtonProps) => {
  const { filtersCount, setIsFiltersModalOpen } = useRangeFilter()
  const buttonClassName = cx(
    'relative flex h-8 items-center justify-center px-4',
    disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
  )

  return (
    <div className={buttonClassName} onClick={() => !disabled && setIsFiltersModalOpen(true)}>
      <FontAwesomeIcon icon={faSliders} size="1x" />
      {filtersCount > 0 && (
        <Badge className="absolute -right-1 -top-1 bg-black">{filtersCount}</Badge>
      )}
    </div>
  )
}

export default FilterButton
