import { useMemo } from 'react'
import { useManageExtrasContext } from '../ManageExtrasProvider'
import ExtrasSelector from './ExtrasSelector'

const FilterToolbar = () => {
  const { extras } = useManageExtrasContext()
  const hasExtras = useMemo(() => extras?.length > 0, [extras])

  if (!hasExtras) return null

  return (
    <div className={'flex items-end justify-between px-6 py-4'}>
      <div className={'w-[398px]'}>
        <ExtrasSelector />
      </div>
    </div>
  )
}

export default FilterToolbar
