import { useState } from 'react'
import { useNextCalendarApp, ScheduleXCalendar } from '@schedule-x/react'
import {
  createViewDay,
  createViewMonthAgenda,
  createViewMonthGrid,
  createViewWeek,
} from '@schedule-x/calendar'
import { createEventsServicePlugin } from '@schedule-x/events-service'
import { createDragAndDropPlugin } from '@schedule-x/drag-and-drop'
import { createEventModalPlugin } from '@schedule-x/event-modal'
import '@schedule-x/theme-default/dist/index.css'
import { FlagNames, useFlag } from '@sweetspot/shared/util/feature-flag'
import { Redirect } from 'react-router'

export const EventsCalendar = () => {
  const isAcademyV2Enabled = useFlag(FlagNames.AcademyV2)
  const eventsService = useState(() => createEventsServicePlugin())[0]

  const calendar = useNextCalendarApp({
    views: [createViewDay(), createViewWeek(), createViewMonthGrid(), createViewMonthAgenda()],
    events: [
      {
        id: '1',
        title: 'Event 1',
        start: '2025-03-05 11:00',
        end: '2025-03-05 12:00',
      },
    ],
    plugins: [eventsService, createDragAndDropPlugin(), createEventModalPlugin()],
    callbacks: {
      onRender: () => {
        eventsService.getAll()
      },
    },
  })

  if (!isAcademyV2Enabled) {
    return <Redirect to={{ pathname: '/404' }} />
  }

  return (
    <div>
      <ScheduleXCalendar calendarApp={calendar} />
    </div>
  )
}
