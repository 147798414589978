import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import m from 'moment'
import cx from 'classnames'
import _uniqBy from 'lodash/uniqBy'
import styles from '../styles.module.scss'

import BookingPaymentsTable from '@sweetspot/sweetspot-js/features/bookings/components/BookingPaymentsTable'
import PlayersControls from '@sweetspot/sweetspot-js/features/players/components/PlayersControls'
import PartnershipBookingSelect from '@sweetspot/sweetspot-js/features/bookings/components/PartnershipBookingSelect'
import AddPlayerButton from '@sweetspot/sweetspot-js/features/bookings/components/AddPlayerButton'
import AddGolfCartButton from '@sweetspot/sweetspot-js/features/bookings/components/AddGolfCartButton'
import AddGolfCartModal from '@sweetspot/sweetspot-js/features/bookings/components/AddGolfCartModal'

import Button from '@sweetspot/sweetspot-js/common/components/Button'
import OnHover from '@sweetspot/sweetspot-js/common/components/OnHover'

import DateHelpers from '@sweetspot/sweetspot-js/common/functions/DateHelpers'

import { ReactComponent as CaretDown } from '@sweetspot/sweetspot-js/assets/svgs/caret-down.svg'
import { ReactComponent as BellIcon } from '@sweetspot/sweetspot-js/assets/svgs/bell_icon.svg'
import { ReactComponent as BellInactiveIcon } from '@sweetspot/sweetspot-js/assets/svgs/bell_inactive_icon.svg'
import { ReactComponent as TimerIcon } from '@sweetspot/sweetspot-js/assets/svgs/timer-red.svg'

import BookingPlayersGrid from '@sweetspot/club-portal-legacy/components/BookingPlayersGrid'

import { getNextChar } from '@sweetspot/sweetspot-js/common/functions/utils'

import {
  isBookingConfirmed,
  isBookingConsideredPaid,
  isBookingRefunded,
  isBookingWaitingForPayment,
  bookingHasAtleastOneAssigned,
  getNumberOfSlots,
  getBookingTotal,
  getBookingSlotItems,
  getBookingCartItems,
  isBookingPartiallyRefunded,
  isBookingCancelled,
  isBookingPartiallyPaid,
  isBookingPaid,
  isBookingReOpened,
  isBookingFilled,
  isBookingActivePaymentLink,
  bookingStartHasPassed,
} from '@sweetspot/sweetspot-js/features/bookings/functions/utils'

import BasicTimer from '@sweetspot/sweetspot-js/common/components/BasicTimer'
import BasicBox from '@sweetspot/sweetspot-js/common/components/BasicBox'
import BookingNotesCourses from '@sweetspot/sweetspot-js/features/bookings/components/BookingNotesCourses'
import PaymentLinkNotes from '@sweetspot/sweetspot-js/features/bookings/components/PaymentLinkNotes'
import AddVoucherButton from '@sweetspot/sweetspot-js/features/bookings/components/AddVoucherButton'
import useIsPartnerPortal from '@sweetspot/sweetspot-js/common/hooks/useIsPartnerPortal'
import { priceToLocal } from '@sweetspot/shared/util/functions'
import { AddPaymentLinkButton } from '@sweetspot/club-portal/feature/payment-links'

export const BookingRow = memo(
  ({
    booking,
    index,
    openBookings,
    state,
    handleUpdateBooking,
    accessTable,
    autoCancelData,
    cancelingBooking,
    addOpenBooking,
    removeOpenBooking,
    handleRemoveCart,
    hasLoadingExtraSlots,
    hasLoadingGolfCartSlots,
    loadingSlots,
    handleOnAddExtraSlot,
    confirmingBooking,
    isAddingGolfCartToBooking,
    showGolfCartSelectionModal,
    removedGolfCart,
    handleAddGolfCart,
    handleAssignCopiedPlayers,
    getPlayersForCopy,
    teeTime,
    isFetching,
    onNotesUpdate,
    canUseCancelBooking,
    handleMarkAsPaid,
    handleOnConfirmBooking,
    handleButtonClick,
    handleChangeNotification,
    handleOnCancelBooking,
    hasCdhNumber,
    setShowGolfCartSelectionModal,
  }) => {
    const { t } = useTranslation()
    const isPartnerPortal = useIsPartnerPortal()

    let isOnMute = booking.muteNotification
    if (typeof isOnMute !== 'boolean') {
      isOnMute = DateHelpers.isPastBooking(new Date(booking?.booking?.start_time))
    }
    const bookingOpen = openBookings.includes(booking.id)
    const numberOfSlots = getNumberOfSlots(booking)
    const isPartnershipBooking = Boolean(booking?.partnership)

    const bookingPayments = booking?.payments || []

    return (
      <div key={booking.uuid} className={cx(styles.singleBookingContainer)}>
        <div className={cx(styles.topRow)}>
          <p className={cx(styles.bookingName)}>
            {getNextChar('@', index + 1)} - {numberOfSlots}{' '}
            {t('words.player', { count: numberOfSlots })}
          </p>
          <PartnershipBookingSelect
            className={cx(styles.partnershipSelect)}
            partnerships={state.partnerships}
            loading={state.loaders.partnerships}
            booking={booking}
            onRequestBookingUpdate={handleUpdateBooking}
            canEdit={!isBookingConsideredPaid(booking) && accessTable.SET_PARTNERSHIP}
          />
          {autoCancelData?.[booking.uuid] && (
            <BasicTimer
              expirationDate={autoCancelData[booking.uuid].xDelayTo}
              render={(timeLeft) => {
                const oneMinuteInMilliseconds = 60000
                if (!m(timeLeft).isValid() || cancelingBooking) return null
                if (
                  !isBookingCancelled(booking) &&
                  !cancelingBooking &&
                  isBookingConfirmed(booking) === false &&
                  timeLeft <= 2000
                ) {
                  handleOnCancelBooking(booking.uuid, true)
                  return null
                }
                return (
                  <p
                    className={cx(
                      styles.timer,
                      timeLeft <= oneMinuteInMilliseconds && styles.visible
                    )}
                  >
                    <TimerIcon className={cx(styles.timerIcon)} />
                    {m.utc(timeLeft).format('mm:ss')}
                  </p>
                )
              }}
            />
          )}
          <div className={cx(styles.statusContainer)}>
            {isBookingConfirmed(booking) && (
              <div className={cx(styles.statusBox)}>
                <div className={cx(styles.statusDot, styles.green)}></div>
                <p className={cx(styles.statusText)}>{t('words.confirmed')}</p>
              </div>
            )}
            {isBookingWaitingForPayment(booking) && (
              <div className={cx(styles.statusBox)}>
                <div className={cx(styles.statusDot, styles.orange)}></div>
                <p className={cx(styles.statusText)}>{t('sentences.awaitingPayment')}</p>
              </div>
            )}
            {isBookingConsideredPaid(booking) && (
              <div className={cx(styles.statusBox)}>
                <div className={cx(styles.statusDot, styles.green)}></div>
                <p className={cx(styles.statusText)}>{t('words.paid')}</p>
              </div>
            )}
            {(isBookingRefunded(booking) || isBookingPartiallyRefunded(booking)) && (
              <div className={cx(styles.statusBox)}>
                <div className={cx(styles.statusDot, styles.orange)}></div>
                <p className={cx(styles.statusText)}>{t('words.refunded')}</p>
              </div>
            )}
            {isBookingPartiallyPaid(booking) && (
              <div className={cx(styles.statusBox)}>
                <div className={cx(styles.statusDot, styles.orange)}></div>
                <p className={cx(styles.statusText)}>{t('sentences.partiallyPaid')}</p>
              </div>
            )}
          </div>
          <div className={styles.bell}>
            <OnHover
              position="left"
              text={
                !isOnMute ? t('sentences.notificationEnabled') : t('sentences.notificationDisabled')
              }
            >
              {!isOnMute ? (
                <BellIcon onClick={() => handleChangeNotification(booking, true)} />
              ) : (
                <BellInactiveIcon onClick={() => handleChangeNotification(booking, false)} />
              )}
            </OnHover>
          </div>
        </div>
        <BookingPlayersGrid
          onExpandBooking={addOpenBooking}
          onCloseBooking={removeOpenBooking}
          teeTimePrice={state.teeTimePrice}
          booking={booking}
          bookingSlotItems={getBookingSlotItems(booking)}
          bookingCartItems={getBookingCartItems(booking)}
          golfCarts={state.golfCarts}
          onRemoveCart={handleRemoveCart}
          onRequestBookingUpdate={(bookingUuid, updateTeeTime) =>
            handleUpdateBooking(bookingUuid, updateTeeTime)
          }
          onRequestOpenPaymentFold={() => addOpenBooking(booking.id)}
          addingExtraSlot={hasLoadingExtraSlots(booking.id)}
          addingGolfCart={hasLoadingGolfCartSlots(booking.id)}
          loadingSlots={loadingSlots}
          canEdit={!isBookingConsideredPaid(booking)}
          tags={state.tags}
          tagsLoading={state.loaders.tags}
          timezone={state.course?.timezone}
          hasCdhNumber={hasCdhNumber}
        />
        <div className={cx(styles.actionsRowsOne)}>
          <AddPlayerButton
            className={cx(styles.playerControls)}
            onAddPlayer={() => handleOnAddExtraSlot(booking)}
            canEdit={
              !isBookingCancelled(booking) &&
              !isBookingActivePaymentLink(booking) &&
              !state.loaders.bookings &&
              !confirmingBooking
            }
            availableSlots={state.teeTime?.available_slots}
          />
          <AddGolfCartButton
            className={cx(styles.playerControls)}
            onAddGolfCart={() => setShowGolfCartSelectionModal(booking.uuid)}
            loading={state.loaders.golfCarts || isAddingGolfCartToBooking}
            canEdit={
              !isBookingCancelled(booking) &&
              !isBookingActivePaymentLink(booking) &&
              state?.golfCarts?.variants?.length > 0
            }
          />
          {showGolfCartSelectionModal === booking?.uuid && state.golfCarts?.product && (
            <AddGolfCartModal
              golfCarts={state.golfCarts}
              golfCourse={state.course}
              booking={booking}
              handleAddGolfCart={handleAddGolfCart}
              removedGolfCart={removedGolfCart}
              isConfirmed={isBookingConfirmed(booking)}
            />
          )}
          <PlayersControls
            className={cx(styles.playerControls)}
            onAddCopiedPlayers={(players) => handleAssignCopiedPlayers(booking, players)}
            keyModifier="bookingPlayerGrid"
            players={getPlayersForCopy(booking)}
            canEdit={!isBookingConsideredPaid(booking) && !isBookingActivePaymentLink(booking)}
            availableSlots={state.teeTime?.available_slots}
          />
          {!isPartnerPortal && !isPartnershipBooking && !isBookingConsideredPaid(booking) && (
            <AddVoucherButton
              className={cx(styles.playerControls)}
              booking={booking}
              canEdit={
                !isBookingCancelled(booking) &&
                !isBookingActivePaymentLink(booking) &&
                !!booking.customer
              }
              onUpdateBooking={() => handleUpdateBooking(booking.uuid)}
              buttonText={t('words.voucher_one')}
            />
          )}
          <AddPaymentLinkButton
            className={cx(styles.playerControls)}
            booking={booking}
            disabled={
              !isBookingConfirmed(booking) ||
              booking?.total === 0 ||
              isBookingPaid(booking) ||
              isPartnershipBooking ||
              bookingStartHasPassed(booking)
            }
            handleUpdateBooking={() => handleUpdateBooking(booking.uuid)}
            course={state.course}
            canEdit={!isBookingCancelled(booking) && !state.loaders.bookings && !confirmingBooking}
          />

          <p
            className={cx(styles.bookingTotal)}
            onClick={() =>
              openBookings.includes(booking.id)
                ? removeOpenBooking(booking.id)
                : addOpenBooking(booking.id)
            }
          >
            {t('sentences.bookingTotal')}:{' '}
            <span>
              {priceToLocal(getBookingTotal(booking, true), booking?.currency_code, true)}
            </span>
          </p>
        </div>
        <div
          className={cx(styles.bookingFold, {
            [styles.open]: bookingOpen,
          })}
        >
          <BasicBox
            title={t('bookingNotes.title')}
            styles={{
              container: cx(styles.notesBox),
            }}
          >
            {!booking || !state.teeTime ? null : (
              <>
                <PaymentLinkNotes isLoading={isFetching} payments={bookingPayments} />
                <BookingNotesCourses
                  isLoading={isFetching}
                  notes={state.teeTime?.notes}
                  onNotesUpdate={onNotesUpdate}
                />
              </>
            )}
          </BasicBox>
          <BookingPaymentsTable
            className={cx(styles.paymentsBox)}
            booking={booking}
            canEdit={!isBookingConsideredPaid(booking)}
          />
        </div>
        <div className={cx(styles.bookingFooter)}>
          <div className={cx(styles.leftContainer)}>
            <div
              className={cx(styles.openBookingWrapper)}
              onClick={() =>
                openBookings.includes(booking.id)
                  ? removeOpenBooking(booking.id)
                  : addOpenBooking(booking.id)
              }
            >
              <CaretDown className={cx(styles.icon, { [styles.open]: bookingOpen })} />
              <p className={cx(styles.text)}>
                {t('words.notes')} &nbsp;{' | '}&nbsp; {t('words.payment_other')}
              </p>
            </div>
          </div>
          <div className={cx(styles.rightContainer)}>
            <Button
              theme="danger-outline"
              width="auto"
              size="default"
              onClick={() => handleButtonClick(booking)}
              className={cx(styles.abortButton)}
              disabled={
                cancelingBooking || isBookingReOpened(booking) || !canUseCancelBooking(booking)
              }
              text={
                isBookingConfirmed(booking)
                  ? isBookingPartiallyPaid(booking) ||
                    isBookingPaid(booking) ||
                    isBookingPartiallyRefunded(booking)
                    ? t('sentences.refundAndcancelBooking')
                    : t('sentences.cancelBooking')
                  : t('sentences.abortBooking')
              }
            />
            {isBookingConfirmed(booking) && !isBookingConsideredPaid(booking) ? (
              <Button
                className={cx(styles.confirmButton)}
                theme="default-outline"
                width="auto"
                size="default"
                text={t('sentences.markAsPaid')}
                loading={
                  confirmingBooking ||
                  hasLoadingExtraSlots(booking.id) ||
                  hasLoadingGolfCartSlots(booking.id)
                }
                loaderStyle="pulse"
                disabled={!isBookingFilled(booking)}
                onClick={() => handleMarkAsPaid(booking)}
              />
            ) : null}
            {!isBookingConfirmed(booking) ? (
              <Button
                className={cx(styles.confirmButton)}
                theme="default-outline"
                disabledTheme="gray-outline"
                width="auto"
                size="default"
                text={t('sentences.confirmBooking')}
                disabled={confirmingBooking || !bookingHasAtleastOneAssigned(booking)}
                loading={confirmingBooking}
                loaderStyle="pulse"
                onClick={() => handleOnConfirmBooking(booking)}
              />
            ) : null}
          </div>
        </div>
      </div>
    )
  }
)
