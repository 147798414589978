import { useEffect } from 'react'
import { getResources } from '@sweetspot/shared/data-access/api-platform'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useResourcesStore } from './useResourcesStore'

export const useResourcesLoader = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { setResources, setAreResourcesLoading } = useResourcesStore()

  useEffect(() => {
    let isMounted = true
    const fetchResources = async () => {
      setAreResourcesLoading(true)
      try {
        const resources = await getResources()
        if (isMounted) {
          setResources(resources)
        }
      } catch (error) {
        if (isMounted) {
          addToast(t('sentences.errorFetchingResources'), { appearance: 'error' })
        }
      } finally {
        if (isMounted) {
          setAreResourcesLoading(false)
        }
      }
    }
    fetchResources()
    return () => {
      isMounted = false
    }
  }, [addToast, setAreResourcesLoading, setResources, t])
}
