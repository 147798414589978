import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { format, utcToZonedTime } from 'date-fns-tz'

import Table from '@sweetspot/club-portal-legacy/components/Table'
import { useAvailabilityAndPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/AvailabilityAndPricingProvider'
import getHeaders from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/Period/AdjustmentsTab/AdjustmentsTable/headers'
import { formatPeriodDatesWithTimezone } from '@sweetspot/sweetspot-js/common/functions/DateHelpers'
import { weekDaysOptions } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/types'
import { capitalize } from '@sweetspot/sweetspot-js/common/functions/utils'

const AdjustmentsTable = () => {
  const { adjustments, currentCourse, bays } = useAvailabilityAndPricingContext()

  const { t } = useTranslation()

  const headers = useMemo(() => getHeaders(t), [t])

  const timezone = currentCourse?.timezone || 'UTC'

  const tableData = useMemo(
    () =>
      adjustments.map((adjustment) => {
        const createdAt = format(
          utcToZonedTime(new Date(adjustment.created_at), timezone),
          'yyyy-MM-dd HH:mm',
          { timeZone: timezone }
        )

        const created = `${createdAt} ${adjustment.created_by}`
        const days = weekDaysOptions.find(
          (weekDaysOption) => weekDaysOption.value === adjustment.repeat_on
        )?.label
        const bay = bays.find((bay) => bay.id === adjustment.space?.uuid)
        const bayName = bay
          ? `${capitalize(t('words.bay_one'))} ${bay?.bay_number}`
          : t('sentences.allBays')

        return {
          created,
          name: adjustment.name,
          duration: formatPeriodDatesWithTimezone(
            adjustment.start_date,
            adjustment.end_date,
            timezone,
            'yyyy-MM-dd'
          ),
          betweenTime: `${adjustment.start_time_from?.substr(
            11,
            5
          )} - ${adjustment.start_time_to?.substr(11, 5)}`,
          days,
          category: adjustment.category,
          slots: adjustment.slots,
          isPrimeTime: adjustment.is_prime_time ? t('words.yes') : t('words.no'),
          bay: bayName,
        }
      }),
    [adjustments, t, timezone, bays]
  )

  return (
    <div className="relative mt-2 h-[calc(100%-61px)] w-full">
      <Table headers={headers} values={tableData} fullWidth fullHeight hideScrollbar />
    </div>
  )
}

export default AdjustmentsTable
