import { useTranslation } from 'react-i18next'
import { Button } from '@sweetspot/scramble-ds/atoms'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@sweetspot/scramble-ds'

type ConfirmRemoveDialogProps = {
  name?: string
  open?: boolean
  onClose: () => void
  onConfirm: () => void
}

const ConfirmRemoveDialog = ({ name, open, onClose, onConfirm }: ConfirmRemoveDialogProps) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent variant="default">
        <DialogHeader>
          <DialogTitle>{t('sentences.dispenserRemoveConfirm', { name })}</DialogTitle>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="ghost-dark" type="button" size="large" onClick={onClose}>
              {t('words.cancel')}
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="primary" size="large" onClick={onConfirm}>
              {t('words.confirm')}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmRemoveDialog
