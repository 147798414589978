import { useCallback, useState } from 'react'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
  SheetCustomContent,
} from '@sweetspot/scramble-ds'
import { Button } from '@sweetspot/scramble-ds/atoms'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { useResourcesStore } from '../../../../../../hooks/resources/useResourcesStore'
import { SideBarMode } from '../../../../../../types'
import { ActionItem } from '../../../components'

const ResourceActionItems = () => {
  const { toggleSideBar, selectedResource } = useResourcesStore()
  const [isSheetOpen, setIsSheetOpen] = useState(false)

  const toggleBottomBar = useCallback(() => {
    setIsSheetOpen(!isSheetOpen)
    toggleSideBar(SideBarMode.RESOURCE_ACTION_ITEMS)
  }, [isSheetOpen, toggleSideBar])

  if (!selectedResource) {
    return null
  }

  return (
    <>
      <Button variant="link-dark" className="cursor-pointer outline-0" onClick={toggleBottomBar}>
        <FontAwesomeIcon icon={faEllipsis} />
      </Button>
      <Sheet open={isSheetOpen} onOpenChange={toggleBottomBar}>
        <SheetContent side="bottom" className="h-auto rounded-b-none rounded-t-xl pb-12">
          <SheetHeader className="rounded-b-none rounded-t-xl">
            <SheetHeaderLeftIcon onClick={toggleBottomBar}>
              <i className="fa-regular fa-close" />
            </SheetHeaderLeftIcon>
            <SheetTitle>{selectedResource.name}</SheetTitle>
          </SheetHeader>
          <SheetCustomContent className="text-content-base text-text-dark font-regular">
            <ActionItem iconName="edit" label="words.edit" handleClick={toggleBottomBar} />
            <ActionItem iconName="clone" label="words.duplicate" handleClick={toggleBottomBar} />
            <ActionItem
              iconName="box-archive"
              label="words.archive"
              color="text-danger"
              handleClick={toggleBottomBar}
            />
          </SheetCustomContent>
        </SheetContent>
      </Sheet>
    </>
  )
}

export default ResourceActionItems
