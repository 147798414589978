import * as React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { cn } from '../../../utils'

const { Provider, Root, Trigger, Arrow, Portal } = TooltipPrimitive

export interface ContentProps
  extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> {
  className?: string
  sideOffset?: number
}

const Content = React.forwardRef<React.ElementRef<typeof TooltipPrimitive.Content>, ContentProps>(
  ({ className, sideOffset = 4, ...props }, ref) => (
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        'bg-surface-primary text-text-light text-content-xs px-lg py-md overflow-hidden rounded-sm font-bold',
        'animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50',
        className
      )}
      {...props}
      data-testid="scramble-tooltip"
    />
  )
)
Content.displayName = TooltipPrimitive.Content.displayName

export const Tooltip = { Root, Trigger, Content, Provider, Arrow, Portal }
