import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import { createAdyenStore } from '@sweetspot/sweetspot-js/features/billingSettings/services/api-platform'
import { AdyenStorePayload } from '@sweetspot/sweetspot-js/features/billingSettings/types'

import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeaderLeftIcon,
  SheetTitle,
  SheetHeader,
  SheetCustomContent,
  SheetClose,
  SheetFooter,
} from '@sweetspot/scramble-ds'

import { useMutation, useQueryClient } from 'react-query'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'

import { useForm } from 'react-hook-form'
import { StoreSchema } from './validation'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { QUERY_KEYS } from '@sweetspot/shared/util/constants'
import { useState } from 'react'
import { StoreFormFields } from './StoreSheetContent'
import { Button } from '@sweetspot/scramble-ds/atoms'

interface CreateStoreSheetProps {
  children: React.ReactNode
}

export const CreateStoreSheet = ({ children }: CreateStoreSheetProps) => {
  const [isSheetOpen, setIsSheetOpen] = useState(false)

  const { t } = useTranslation()
  const { addToast } = useToasts()

  const golfClub = useSelector(({ golfClub }: RootState) =>
    golfClub?.list.find((club) => club?.id === golfClub?.selectedId)
  )

  const queryClient = useQueryClient()

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<z.infer<typeof StoreSchema>>({
    resolver: zodResolver(StoreSchema),
    defaultValues: {
      store_code: '',
      shopper_statement: '',
      phone_number: '',
      address_line1: '',
      address_line2: '',
      address_line3: '',
      address_city: '',
      address_country: 'SE',
      address_postal_code: '',
      merchant_account_type: 'pos',
    },
    mode: 'onChange',
  })

  const createAdyenStoreMutation = useMutation(
    ({ clubId, payload }: { clubId: number; payload: AdyenStorePayload }) =>
      createAdyenStore({ clubId, payload }),
    {
      onSuccess: () => {
        addToast(t('settings.billing.storeCreatedSuccess'), { appearance: 'success' })
        reset()
        queryClient.invalidateQueries([QUERY_KEYS.ACCOUNT_HOLDER])
        queryClient.invalidateQueries([QUERY_KEYS.STORES])
        setIsSheetOpen(false)
      },
      onError: () => {
        addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
      },
    }
  )

  const onSubmit = (data: z.infer<typeof StoreSchema>) => {
    if (!golfClub?.id) return

    const formattedData = {
      ...data,
      address_line2: data.address_line2?.trim() === '' ? '-' : data.address_line2,
      address_line3: data.address_line3?.trim() === '' ? '-' : data.address_line3,
    }

    createAdyenStoreMutation.mutate({ clubId: golfClub.id, payload: formattedData })
  }

  const isFormFilled = isDirty && isValid

  return (
    <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
      <SheetTrigger asChild className="cursor-pointer">
        {children}
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetClose asChild>
            <SheetHeaderLeftIcon>
              <i className="fa-regular fa-close" />
            </SheetHeaderLeftIcon>
          </SheetClose>
          <SheetTitle className="text-lg">{t('settings.billing.createStore')}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-4">
          <StoreFormFields control={control} errors={errors} watch={watch} />
        </SheetCustomContent>
        <SheetFooter className="pt-0">
          <Button
            className="w-full"
            variant="primary"
            disabled={
              !isFormFilled || Object.keys(errors).length > 0 || createAdyenStoreMutation.isLoading
            }
            onClick={() => {
              handleSubmit(onSubmit)()
            }}
          >
            {t('words.create')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}
