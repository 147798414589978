import Breadcrumb from './Breadcrumb'
import ActionItems from './ActionItems'
import BackButton from './BackButton'
import Title from './Title'

const Header = () => (
  <div className="bg-background-base-clean py-lg px-xl flex flex-row items-center justify-between border-b-[1px]">
    <div className="flex gap-4">
      <BackButton />
      <div>
        <Breadcrumb />
        <Title />
      </div>
    </div>
    <ActionItems />
  </div>
)

export default Header
