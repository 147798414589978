import React from 'react'
import { useTranslation } from 'react-i18next'

import Container from '@sweetspot/club-portal-legacy/components/Container'
import { EmptyState, EmptyStateDescription, EmptyStateTitle } from '@sweetspot/scramble-ds'

import styles from './styles.module.scss'
import { useFlagsStore } from '@sweetspot/shared/util/feature-flag'

const NotFound = () => {
  const { t } = useTranslation()
  const { areFlagsLoading } = useFlagsStore()

  if (areFlagsLoading) {
    return (
      <Container className={styles.container}>
        <EmptyState loading />
      </Container>
    )
  }

  return (
    <Container className={styles.container}>
      <EmptyState>
        <EmptyStateTitle>{t('sentences.foreOhFore')}</EmptyStateTitle>
        <EmptyStateDescription>{t('sentences.cantFindPage')}</EmptyStateDescription>
      </EmptyState>
    </Container>
  )
}

export default NotFound
