import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import {
  getAdyenStore,
  updateAdyenStore,
} from '@sweetspot/sweetspot-js/features/billingSettings/services/api-platform'

import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeaderLeftIcon,
  SheetTitle,
  SheetHeader,
  SheetCustomContent,
  SheetClose,
  SheetFooter,
} from '@sweetspot/scramble-ds'

import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useForm } from 'react-hook-form'
import { StoreSchema } from './validation'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { AdyenStorePayload } from '@sweetspot/sweetspot-js/features/billingSettings/types'
import { StoreFormFields } from './StoreSheetContent'
import { QUERY_KEYS } from '@sweetspot/shared/util/constants'
import { Button } from '@sweetspot/scramble-ds/atoms'

interface EditStoreSheetProps {
  children: React.ReactNode
  storeId: number
  setIsPopoverOpen: Dispatch<SetStateAction<boolean>>
  accountType: string
}

export const EditStoreSheet = ({
  children,
  storeId,
  setIsPopoverOpen,
  accountType,
}: EditStoreSheetProps) => {
  const [isSheetOpen, setIsSheetOpen] = useState(false)

  const { t } = useTranslation()
  const { addToast } = useToasts()

  const queryClient = useQueryClient()

  const { data: store } = useQuery([storeId], () => getAdyenStore(storeId), {
    select: (data) => ({
      store_code: data.reference || '',
      shopper_statement: data.shopperStatement || '',
      phone_number: data.phoneNumber || '',
      address_line1: data.address?.line1 || '',
      address_line2: data.address?.line2 || '',
      address_line3: data.address?.line3 || '',
      address_city: data.address?.city || '',
      address_country: data.address?.country || 'SE',
      address_postal_code: data.address?.postalCode || '',
      merchant_account_type: accountType || 'pos',
    }),
  })

  const {
    control,
    handleSubmit,
    watch,
    reset,
    trigger,
    formState: { errors, isValid },
  } = useForm<z.infer<typeof StoreSchema>>({
    resolver: zodResolver(StoreSchema),
    defaultValues: store || undefined,
    mode: 'onChange',
  })

  useEffect(() => {
    if (store) {
      reset(store)
      trigger()
    }
  }, [store, reset, trigger])

  const updateAdyenStoreMutation = useMutation(
    ({ payload }: { payload: AdyenStorePayload }) => updateAdyenStore({ storeId, payload }),
    {
      onSuccess: () => {
        addToast(t('settings.billing.storeUpdatedSuccess'), { appearance: 'success' })
        setIsSheetOpen(false)
        queryClient.invalidateQueries([QUERY_KEYS.STORES])
        setIsPopoverOpen(false)
      },
      onError: () => {
        addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
      },
    }
  )

  const onSubmit = (data: z.infer<typeof StoreSchema>) => {
    const {
      store_code,
      shopper_statement,
      merchant_account_type,
      address_country,
      ...filteredData
    } = data

    const formattedData = {
      ...filteredData,
      address_line2: filteredData.address_line2?.trim() === '' ? '-' : filteredData.address_line2,
      address_line3: filteredData.address_line3?.trim() === '' ? '-' : filteredData.address_line3,
    }

    updateAdyenStoreMutation.mutate({ payload: formattedData })
  }

  return (
    <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
      <SheetTrigger asChild className="cursor-pointer">
        {children}
      </SheetTrigger>
      <SheetContent onInteractOutside={() => setIsPopoverOpen(false)}>
        <SheetHeader>
          <SheetClose asChild>
            <SheetHeaderLeftIcon onClick={() => setIsPopoverOpen(false)}>
              <i className="fa-regular fa-close" />
            </SheetHeaderLeftIcon>
          </SheetClose>
          <SheetTitle className="text-lg">{t('settings.billing.editStore')}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-4">
          <StoreFormFields control={control} errors={errors} watch={watch} isFromEditSheet />
        </SheetCustomContent>
        <SheetFooter className="pt-0">
          <Button
            className="w-full"
            variant="primary"
            disabled={
              !isValid || Object.keys(errors).length > 0 || updateAdyenStoreMutation.isLoading
            }
            onClick={() => {
              handleSubmit(onSubmit)()
            }}
          >
            {t('update')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}
