import { useCallback, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'

import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import {
  queryMembershipCards,
  cancelMembershipAutoRenewal as _cancelMembershipAutoRenewal,
  enableMembershipAutoRenewal as _enableMembershipAutoRenewal,
} from '@sweetspot/sweetspot-js/features/memberships/services/api-platform'
import { useToasts } from 'react-toast-notifications'
import {
  _addPlayerToMembership,
  _markMembershipAsPaid,
  _removeMembershipCard,
  _sendPaymentLink,
  _unmarkMembershipAsPaid,
  _lockUnlockMembership,
} from '@sweetspot/club-portal-legacy/services/membershipApi'
import { cancelFutureMembershipCardPayments } from '@sweetspot/shared/data-access/api-platform'
import { Membership, MembershipCard } from '@sweetspot/shared/types'
import { usePlayerCardContext } from '@sweetspot/club-portal/data-access/context-providers'
import { useTranslation } from 'react-i18next'
import { refundMembershipCard as _refundMembershipCard } from '@sweetspot/shared/data-access/api-platform'
import {
  MEMBERSHIP_REFUND_VIOLATION_ERRORS,
  MEMBERSHIP_REFUND_VIOLATION_ERRORS_MAP,
} from '@sweetspot/club-portal-legacy/modals/PlayerCard/constants'

type Props = {
  selectedId: number
  playerId?: number
}

type AddMembershipPayload = {
  player?: number
  club: number
  is_send_payment_link?: boolean
  duration_type?: string
  calendar_year?: string
}

/**
 * `useMembershipCard` is a custom hook that facilitates the management of membership cards
 * in the SweetSpot platform, especially within the context of clubs and players.
 */
const useMembershipCard = ({
  selectedId,
  playerId,
}: Props): {
  membershipCards: MembershipCard[]
  isLoading: boolean
  handleMarkAsPaid: (id: number) => void
  handleUnmarkAsPaid: (
    id: number,
    UUID: string,
    shouldRefund: boolean,
    shouldCancelUpcomingPayments: boolean
  ) => void
  handleRemove: (id: number) => void
  handleCancelMembershipAutoRenewal: (id: number) => void
  handleEnableMembershipAutoRenewal: (id: number) => void
  handleCancelUpcomingPayments: (id: number) => void
  addMembership: (activeMembership?: Membership | null) => void
  handleSendLink: (orderId: number) => void
  handleRefund: (id: number, UUID: string, isThereUpcomingPayments: boolean) => void
  handleLockOrUnlockMembership: (uuid: string) => void
  isMembershipPlayerCardUpdated: boolean
} => {
  const [membershipCards, setMembershipCards] = useState<MembershipCard[]>([])
  const [isMembershipPlayerCardUpdated, setIsMembershipPlayerCardUpdated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { setDialogType, duration, year } = usePlayerCardContext()
  const sendPaymentLinkMutation = useMutation((orderId: number) => _sendPaymentLink(orderId))
  const addPlayerToMembershipMutation = useMutation(
    ({ membershipId, payload }: { membershipId: number; payload: AddMembershipPayload }) =>
      _addPlayerToMembership(membershipId, payload)
  )
  const cancelMembershipAutoRenewal = useMutation((id: number) => _cancelMembershipAutoRenewal(id))
  const enableMembershipAutoRenewal = useMutation((id: number) => _enableMembershipAutoRenewal(id))
  const cancelUpcomingPaymentsMutation = useMutation((id: number) =>
    cancelFutureMembershipCardPayments(id)
  )
  const removeMembershipCardMutation = useMutation((id: number) => _removeMembershipCard(id))
  const unmarkMembershipAsPaidMutation = useMutation((id: number) => _unmarkMembershipAsPaid(id))
  const markMembershipAsPaidMutation = useMutation((id: number) => _markMembershipAsPaid(id))
  const refundMembershipCard = useMutation((id: number) => _refundMembershipCard(id))
  const toggleMembershipState = useMutation((uuid: string) => _lockUnlockMembership(uuid))

  const { addToast } = useToasts()

  // Fetching membership cards based on selected club and player.
  const { isFetching, refetch } = useQuery(
    [CLUB_QUERIES.MEMBERSHIP_CARDS],
    () =>
      queryMembershipCards({
        'club.id': selectedId,
        'player.id': playerId,
        page: 1,
        limit: 999,
      }),
    {
      onSuccess: (data: MembershipCard[]) => {
        setMembershipCards(data)
      },
      enabled: !!playerId && !!selectedId,
    }
  )

  const handleMarkAsPaid = useCallback(
    (id: number) => {
      setIsLoading(true)

      markMembershipAsPaidMutation.mutate(id, {
        onSuccess: () => {
          setIsMembershipPlayerCardUpdated(true)
          addToast(t('toast.membershipMarkasPaidSuccess'), { appearance: 'success' })
          refetch()
        },
        onError: () => {
          addToast(t('toast.membershipMarkasPaidError'), { appearance: 'error' })
        },
        onSettled: () => {
          setIsLoading(false)
        },
      })
    },
    [addToast, markMembershipAsPaidMutation, refetch, t]
  )

  const handleUnmarkAsPaid = useCallback(
    (id: number, UUID: string, shouldRefund = false, shouldCancelUpcomingPayments = false) => {
      setIsLoading(true)

      unmarkMembershipAsPaidMutation.mutate(id, {
        onSuccess: () => {
          if (shouldRefund) handleRefund(id, UUID, false)
          if (shouldCancelUpcomingPayments) handleCancelUpcomingPayments(id)
          setIsMembershipPlayerCardUpdated(true)
          addToast(t('toast.membershipUnmarkasPaidSuccess'), { appearance: 'success' })
          refetch()
        },
        onError: () => {
          addToast(t('toast.membershipUnmarkasPaidError'), { appearance: 'error' })
        },
        onSettled: () => {
          setIsLoading(false)
        },
      })
    },
    [unmarkMembershipAsPaidMutation, addToast, refetch, t]
  )

  const handleCancelUpcomingPayments = useCallback(
    (id: number) => {
      setIsLoading(true)
      cancelUpcomingPaymentsMutation.mutate(id, {
        onSuccess: () => {
          setIsMembershipPlayerCardUpdated(true)
          addToast(t('Upcoming payments cancelled'), { appearance: 'success' })
          refetch()
        },
        onError: () => {
          addToast(t('Failed to cancel upcoming payments'), { appearance: 'error' })
        },
        onSettled: () => {
          setIsLoading(false)
        },
      })
    },
    [cancelUpcomingPaymentsMutation, addToast, refetch, t]
  )

  const handleRefund = useCallback(
    (id: number, UUID = '', isThereUpcomingPayments: boolean) => {
      setIsLoading(true)

      refundMembershipCard.mutate(id, {
        onSuccess: () => {
          setIsMembershipPlayerCardUpdated(true)
          if (UUID) {
            queryClient.invalidateQueries([CLUB_QUERIES.MEMBERSHIP_CARDS_PAYMENT, UUID])
          }
          if (isThereUpcomingPayments) handleCancelUpcomingPayments(id)
          addToast(t('toast.membershipCardRefundInitiatedSuccess'), { appearance: 'success' })
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (error: any) => {
          const errorName: MEMBERSHIP_REFUND_VIOLATION_ERRORS | undefined = error?.violations?.find(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (err: any) => Object.values(MEMBERSHIP_REFUND_VIOLATION_ERRORS).includes(err.errorName)
          )?.errorName

          if (errorName) {
            addToast(t(MEMBERSHIP_REFUND_VIOLATION_ERRORS_MAP[errorName]), { appearance: 'error' })
          } else {
            addToast(t('toast.defaultError'), { appearance: 'error' })
          }
        },
        onSettled: () => {
          setIsLoading(false)
        },
      })
    },
    [refundMembershipCard, addToast, t]
  )

  const handleRemove = useCallback(
    (id: number) => {
      setIsLoading(true)

      removeMembershipCardMutation.mutate(id, {
        onSuccess: () => {
          setIsMembershipPlayerCardUpdated(true)
          setMembershipCards(membershipCards.filter((item) => item.id !== id))
          addToast(t('toast.removeMembershipCardSuccess'), { appearance: 'success' })
        },
        onError: () => {
          addToast(t('toast.removeMembershipCardError'), { appearance: 'error' })
        },
        onSettled: () => {
          setIsLoading(false)
        },
      })
    },
    [addToast, membershipCards, removeMembershipCardMutation, t]
  )

  const handleCancelMembershipAutoRenewal = useCallback(
    async (id: number) => {
      setIsLoading(true)

      try {
        return await cancelMembershipAutoRenewal.mutateAsync(id)
      } finally {
        setIsLoading(false)
      }
    },
    [cancelMembershipAutoRenewal]
  )

  const handleEnableMembershipAutoRenewal = useCallback(
    async (id: number) => {
      setIsLoading(true)

      try {
        return await enableMembershipAutoRenewal.mutateAsync(id)
      } finally {
        setIsLoading(false)
      }
    },
    [enableMembershipAutoRenewal]
  )

  const handleLockOrUnlockMembership = useCallback(
    (uuid: string, is_locked: boolean) => {
      setIsLoading(true)

      toggleMembershipState.mutate(uuid, {
        onSuccess: () => {
          setIsMembershipPlayerCardUpdated(true)
          addToast(t(is_locked ? 'membershipUnlocked' : 'membershipLocked'), {
            appearance: 'success',
          })
          refetch()
        },
        onError: () => {
          addToast(t('Could not change membership status'), { appearance: 'error' })
        },
        onSettled: () => {
          setIsLoading(false)
        },
      })
    },
    [toggleMembershipState, addToast, t, refetch]
  )

  const addMembership = useCallback(
    async (activeMembership?: Membership | null) => {
      if (!activeMembership) return

      const payload: AddMembershipPayload = {
        player: playerId,
        club: selectedId,
        is_send_payment_link: false,
      }

      if (duration === 1) {
        payload.duration_type = 'annual_duration'
        payload.calendar_year = `${year}-01-01`
      } else if (duration === 2) {
        payload.duration_type = '12_month'
      } else if (duration === 3) {
        payload.duration_type = 'period'
      }

      setIsLoading(true)

      const response = await addPlayerToMembershipMutation.mutateAsync(
        { membershipId: activeMembership.id, payload },
        {
          onSuccess: (data) => {
            setIsMembershipPlayerCardUpdated(true)
            addToast(t('toast.addMembershipSuccess'), { appearance: 'success' })
            setMembershipCards(membershipCards.concat(data as MembershipCard))

            setDialogType(null)
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onError: (err: any) => {
            let errMsg = 'addMembershipError'
            if (err?.violations?.length) {
              switch (err.violations[0].message) {
                case 'There is future or upcoming membership already':
                  errMsg = 'addMembershipErrorExisting'
                  break
                case 'Player should have email':
                  errMsg = 'addMembershipErrorEmail'
                  break
                case 'Invalid year. You can add membership to either current or next year only':
                  errMsg = 'addMembershipErrorInvalidYear'
                  break
                case 'Invalid year. A membership is already exist for this year':
                  errMsg = 'addMembershipErrorInvalidYear2'
                  break
                // eslint-disable-next-line
                case "Player can't be added to inactive or not published membership":
                  errMsg = 'addMembershipErrorInactive'
                  break
                case 'The player should have play right in this club':
                  errMsg = 'addMembershipErrorPlayRight'
                  break
                default:
                  errMsg = 'addMembershipError'
              }
            }
            addToast(t(`toast.${errMsg}`), { appearance: 'error' })
          },
          onSettled: () => {
            setIsLoading(false)
          },
        }
      )
      return response
    },
    [
      playerId,
      selectedId,
      duration,
      addPlayerToMembershipMutation,
      year,
      addToast,
      membershipCards,
      setDialogType,
      t,
    ]
  )

  const handleSendLink = useCallback(
    (orderId: number) => {
      setIsLoading(true)

      sendPaymentLinkMutation.mutate(orderId, {
        onSuccess: () => {
          setIsMembershipPlayerCardUpdated(true)
          addToast(t('toast.sendPaymentLinkSuccess'), { appearance: 'success' })
        },
        onError: () => {
          addToast(t('toast.sendPaymentLinkError'), { appearance: 'error' })
        },
        onSettled: () => {
          setIsLoading(false)
        },
      })
    },
    [addToast, sendPaymentLinkMutation, t]
  )

  return {
    membershipCards,
    isLoading: isLoading || isFetching,
    handleMarkAsPaid,
    handleUnmarkAsPaid,
    handleRemove,
    addMembership,
    handleSendLink,
    handleRefund,
    handleCancelMembershipAutoRenewal,
    handleEnableMembershipAutoRenewal,
    handleCancelUpcomingPayments,
    handleLockOrUnlockMembership,
    isMembershipPlayerCardUpdated,
  }
}

export default useMembershipCard
