import { useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Button } from '@sweetspot/scramble-ds/atoms'
import {
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputLeadingContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  ListItemTrailing,
} from '@sweetspot/scramble-ds'
import { VenuesIcon } from '@sweetspot/club-portal/feature/venues'
import { GolfCourse, GolfCourseTypes } from '@sweetspot/shared/types'

import { useManageExtrasContext } from '../ManageExtrasProvider'

const ExtrasSelector = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { extras, selectedExtra, setSelectedExtra, toggleCreateExtrasSideBar } =
    useManageExtrasContext()

  useEffect(() => {
    if (!!setSelectedExtra && !selectedExtra && extras?.length) {
      setSelectedExtra(extras[0])
    }
  }, [extras, selectedExtra, setSelectedExtra])

  // set selectedExtra based on hash
  useEffect(() => {
    const hash = history.location.hash
    const selected = extras.find((extra) => `#${extra.id}` === hash)
    if (setSelectedExtra && selected) {
      setSelectedExtra(selected)
    }
  }, [history.location.hash, extras, setSelectedExtra])

  const handleValueChange = useCallback(
    (newValue: string) => {
      if (newValue === 'new') {
        toggleCreateExtrasSideBar()
        return
      }
      const selected = extras.find((extra: GolfCourse) => `${extra.id}` === newValue)
      if (setSelectedExtra && selected) {
        const path = history.location.pathname
        history.push(`${path}#${newValue}`)
      }
    },
    [extras, setSelectedExtra, toggleCreateExtrasSideBar, history]
  )

  const value = useMemo(
    () => extras.find((extra) => extra.id === selectedExtra?.id)?.name,
    [extras, selectedExtra?.id]
  )

  return (
    <InputSelect value={value} onValueChange={handleValueChange}>
      <InputBase>
        <InputLabelContainer className="mb-0.5">{t('words.extras_one')}</InputLabelContainer>
        <InputSelectTrigger asChild>
          <InputContainer className="cursor-pointer">
            <InputLeadingContainer>
              <VenuesIcon courseType={GolfCourseTypes.OTHER} type="default" />
            </InputLeadingContainer>
            <InputSelectStatefulInput
              value={value}
              onChange={() => null}
              className="text-content-base text-ellipsis px-10 "
              readOnly
            />
            <InputSelectTrailingContainer />
          </InputContainer>
        </InputSelectTrigger>
      </InputBase>

      <InputSelectContent>
        <InputSelectContentView>
          {extras?.map((extra) => (
            <InputSelectItem key={extra.id} value={`${extra.id}`}>
              <ListItem className="ml-4 h-full w-[376px] justify-center gap-2">
                <ListItemMainContent className="flex-row justify-between pr-2">
                  <ListItemParagraph className="text-content-base">{extra.name}</ListItemParagraph>
                  <ListItemTrailing>
                    {selectedExtra?.id === extra.id && (
                      <i className="fa-regular fa-check h-4 w-4" />
                    )}
                  </ListItemTrailing>
                </ListItemMainContent>
              </ListItem>
            </InputSelectItem>
          ))}
        </InputSelectContentView>
        <InputSelectContentView className="mt-0.5 min-h-12">
          <InputSelectItem value="new" className="flex justify-center">
            <Button
              variant="link-dark"
              className="text-content-lg text-text-dark h-12 w-full font-medium hover:no-underline focus:no-underline"
            >
              <i className="fa-regular fa-circle-plus " />
              {t('words.new')}
            </Button>
          </InputSelectItem>
        </InputSelectContentView>
      </InputSelectContent>
    </InputSelect>
  )
}

export default ExtrasSelector
