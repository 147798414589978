import useTranslation from '@sweetspot/club-portal-legacy/hooks/useTranslation'
import { GOLF_ID_OPTIONS } from '@sweetspot/shared/util/constants'
import {
  InputBase,
  InputLabelContainer,
  Sheet,
  SheetClose,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
  SheetTrigger,
  Stepper,
} from '@sweetspot/scramble-ds'
import { EditTeeTimesInputSelect } from '../EditTeeTimesSelect/EditTeeTimesSelect'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { EditTeeTimesSchema } from './validation'
import { TeeTimeCategory, Venue } from '@sweetspot/shared/types'
import { Button } from '@sweetspot/scramble-ds/atoms'

type EditTeeTimesSchemaType = z.infer<typeof EditTeeTimesSchema>

interface EditTeeTimesHeaderProps {
  handleEditSave: (data: EditTeeTimesSchemaType) => void
  teeTimeCategories: TeeTimeCategory[]
  handleEditCancel: () => void
  course: Venue
  isVisible: boolean
  editBtnDisabled: boolean
  isLoading: boolean
  children: React.ReactNode
}

const EditTeeTimesHeader = ({
  handleEditSave,
  teeTimeCategories,
  handleEditCancel,
  course,
  isVisible,
  editBtnDisabled,
  isLoading,
  children,
}: EditTeeTimesHeaderProps) => {
  const { t } = useTranslation()
  const hasCdhNumber = Boolean(course?.cdh_id)

  const { handleSubmit, watch, control, reset } = useForm<EditTeeTimesSchemaType>({
    resolver: zodResolver(EditTeeTimesSchema),
    defaultValues: {
      status: null,
      is_golf_id_required: null,
      slots: null,
      is_prime_time: null,
    },
    mode: 'onChange',
  })

  const isFormValid = Object.values(watch()).some((value) => value !== null)
  const isDisabled = editBtnDisabled || isLoading

  return (
    <Sheet open={isVisible} modal={false}>
      <SheetTrigger asChild className="cursor-pointer">
        {children}
      </SheetTrigger>
      <SheetContent disableOverlay>
        <SheetHeader>
          <SheetClose onClick={handleEditCancel}>
            <SheetHeaderLeftIcon>
              <i className="fa-regular fa-close" />
            </SheetHeaderLeftIcon>
          </SheetClose>
          <SheetTitle className="text-lg text-black">{t('edit_time_slots')}</SheetTitle>
        </SheetHeader>

        <SheetCustomContent className="flex flex-col gap-4">
          {/* Status Select */}
          <EditTeeTimesInputSelect
            name="status"
            control={control}
            disabled={isDisabled}
            placeholder="-"
            options={[
              { name: '-', value: null },
              ...teeTimeCategories.map(({ color, name, id }) => ({
                color,
                name,
                value: id,
              })),
            ]}
            label={t('words.category')}
          />

          {/* Golf ID Select */}
          <EditTeeTimesInputSelect
            name="is_golf_id_required"
            control={control}
            options={[{ name: '-', value: null }, ...GOLF_ID_OPTIONS]}
            disabled={isDisabled}
            placeholder="-"
            label={hasCdhNumber ? t('cdhNumber') : t('golf_id')}
          />

          {/* Slots Stepper */}
          <Controller
            name="slots"
            control={control}
            render={({ field }) => (
              <InputBase disabled={isDisabled}>
                <InputLabelContainer>{t('slots')}</InputLabelContainer>
                <Stepper
                  disabled={isDisabled}
                  className="h-12"
                  inputClassName="h-12 px-8 !text-content-base font-medium"
                  value={field.value ?? 'N/A'}
                  increment={() => {
                    if (field.value === null || typeof field.value !== 'number') {
                      field.onChange(1)
                    } else {
                      field.onChange(Math.min(field.value + 1, 10))
                    }
                  }}
                  decrement={() => {
                    if (field.value === null || typeof field.value !== 'number') {
                      field.onChange(1)
                    } else {
                      field.onChange(Math.max(field.value - 1, 1))
                    }
                  }}
                />
              </InputBase>
            )}
          />

          {/* Prime Time Select */}
          <EditTeeTimesInputSelect
            name="is_prime_time"
            control={control}
            options={[
              { name: '-', value: null },
              { name: t('yes'), value: true },
              { name: t('no'), value: false },
            ]}
            disabled={isDisabled}
            placeholder="-"
            label={t('primeTime')}
          />
        </SheetCustomContent>

        <SheetFooter className="pt-0">
          <Button
            className="w-full"
            variant="primary"
            disabled={!isFormValid || isDisabled}
            onClick={() => {
              handleSubmit((data) => {
                handleEditSave(data)
                reset()
              })()
            }}
          >
            {isLoading ? (
              <div className="animate-spin">
                <i className="fa-regular fa-loader" />
              </div>
            ) : (
              t('words.save')
            )}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

export default EditTeeTimesHeader
