import { useCallback } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/pro-regular-svg-icons'

// @ts-expect-error InputSelectDateSimple is not in typescript
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { cn } from '@sweetspot/scramble-ds/utils'

interface RefreshButtonProps {
  isLoading: boolean
  handleRefresh: () => void
  disabled: boolean
}

const RefreshButton = ({ isLoading, handleRefresh, disabled }: RefreshButtonProps) => {
  const onRefresh = useCallback(() => {
    if (disabled) return
    handleRefresh()
  }, [disabled, handleRefresh])

  return (
    <div className="flex">
      {isLoading && (
        <PulseLoader showIf className="min-w-12" contentClassName="!px-3" dotClassName="w-2 h-2" />
      )}
      <FontAwesomeIcon
        icon={faRefresh}
        size="1x"
        className={cn(
          'flex cursor-pointer items-center justify-center px-3',
          disabled && 'cursor-not-allowed opacity-40'
        )}
        onClick={onRefresh}
      />
    </div>
  )
}

export default RefreshButton
