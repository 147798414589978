import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'
import { currencyCodeInCurrentLanguage } from '@sweetspot/sweetspot-js/common/functions/utils'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  InputSelect,
  InputBase,
  InputSelectTrigger,
  InputContainer,
  InputSelectStatefulInput,
  InputSelectContent,
  InputSelectItem,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  InputLabelContainer,
  InputSelectContentView,
  InputSelectTrailingContainer,
  Input,
  InputTrailingContainer,
} from '@sweetspot/scramble-ds'
import { BalanceAccountData } from '../../types'
import { Button } from '@sweetspot/scramble-ds/atoms'

interface BalancesModalProps {
  modalState: boolean
  payoutFrequency: string | undefined
  triggerAmount: number
  onTriggerChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  minAmount: number
  onMinChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowData: BalanceAccountData
  onFrequncyChange: (frequency: string) => void
  onSave: () => void
  loading: boolean
  onCancel: () => void
}

export const BalancesModal = ({
  modalState,
  payoutFrequency,
  triggerAmount,
  onTriggerChange,
  minAmount,
  onMinChange,
  rowData,
  onFrequncyChange,
  onSave,
  loading,
  onCancel,
}: BalancesModalProps) => {
  const [currencyCode] = useClubCurrency()
  const { t } = useTranslation()
  const frequencyValues = [
    { id: t('words.daily'), name: t('words.daily') },
    { id: t('words.weekly'), name: t('words.weekly') },
    { id: t('words.monthly'), name: t('words.monthly') },
  ]
  const { name } = rowData
  const isDisabled = useMemo(() => {
    const regexToCheckNumber = /^[0-9]*.[0-9]{0,2}$/
    if (regexToCheckNumber.test(triggerAmount) && regexToCheckNumber.test(minAmount)) {
      const parsedTrigger = Number.parseFloat(triggerAmount).toFixed(2)
      const parsedMin = Number.parseFloat(minAmount).toFixed(2)
      if (parsedTrigger * 100 >= parsedMin * 100) return false
    }

    return true
  }, [triggerAmount, minAmount])

  return (
    <Dialog open={modalState} onOpenChange={onCancel}>
      <DialogContent variant="default" className="w-[450px]">
        <div className="flex flex-col gap-5 py-4">
          <DialogHeader>
            <DialogTitle>
              {t('words.manage')} "{name}"
            </DialogTitle>
          </DialogHeader>

          <InputSelect onValueChange={onFrequncyChange} value={payoutFrequency}>
            <InputBase>
              <InputLabelContainer>{t('words.frequency')}</InputLabelContainer>
              <InputSelectTrigger asChild>
                <InputContainer>
                  <InputSelectStatefulInput
                    placeholder={`${t('words.select')} ${t('words.frequency').toLowerCase()}`}
                    value={payoutFrequency}
                    className="mt-1"
                  />
                  <InputSelectTrailingContainer />
                </InputContainer>
              </InputSelectTrigger>
            </InputBase>
            <InputSelectContent className="max-h-56">
              <InputSelectContentView>
                {frequencyValues.map((option) => (
                  <InputSelectItem key={option.id} value={option.id}>
                    <ListItem className="ml-2 h-full justify-center gap-2">
                      <ListItemMainContent className="justify-center py-1">
                        <ListItemParagraph className="text-content-base">
                          {option.name}
                        </ListItemParagraph>
                      </ListItemMainContent>
                    </ListItem>
                  </InputSelectItem>
                ))}
              </InputSelectContentView>
            </InputSelectContent>
          </InputSelect>

          <InputBase className="full">
            <InputLabelContainer className="mb-1">
              {t('sentences.triggerAmount_wo_currency')}
            </InputLabelContainer>
            <InputContainer>
              <Input
                placeholder="0"
                type="number"
                onChange={onTriggerChange}
                value={triggerAmount}
              />
              <InputTrailingContainer className="font-medium">
                {currencyCodeInCurrentLanguage(currencyCode as string)}
              </InputTrailingContainer>
            </InputContainer>
          </InputBase>

          <InputBase className="full">
            <InputLabelContainer className="mb-1">
              {t('sentences.minAmount_wo_currency')}
            </InputLabelContainer>
            <InputContainer>
              <Input placeholder="0" onChange={onMinChange} value={minAmount} type="number" />
              <InputTrailingContainer className="font-medium">
                {currencyCodeInCurrentLanguage(currencyCode as string)}
              </InputTrailingContainer>
            </InputContainer>
          </InputBase>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              variant="clear-dark"
              type="button"
              size="large"
              onClick={onCancel}
              className="hover:!text-text-dark hover:bg-transparent hover:shadow-none focus:!outline-none"
            >
              {t('words.cancel')}
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              variant="primary"
              size="large"
              onClick={onSave}
              disabled={isDisabled || loading}
            >
              {t('sentences.saveChanges')}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
