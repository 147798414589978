import React from 'react'
import { useTranslation } from 'react-i18next'
import { capitalize } from '@sweetspot/sweetspot-js/common/functions/utils'
import { useAvailabilityAndPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/AvailabilityAndPricingProvider'

interface PricePopoverContentProps {
  bayKeys: string[]
  price: number | string | null | Record<string, number | string | null>
  pricePerExtraPlayer: number | string | null | Record<string, number | string | null>
  isPriceObject: boolean
  isExtraPriceObject: boolean
}

interface BayData {
  key: string
  bayNumber: number
  priceVal: number | string | null
  extraPriceVal: number | string | null
}

type GroupType = {
  pricing: { priceVal: number | string | null; extraPriceVal: number | string | null }
  bayNumbers: number[]
}

const PricePopoverContent: React.FC<PricePopoverContentProps> = ({
  bayKeys,
  price,
  pricePerExtraPlayer,
  isPriceObject,
  isExtraPriceObject,
}) => {
  const { bays } = useAvailabilityAndPricingContext()
  const { t } = useTranslation()

  const bayData: BayData[] = bayKeys.map((key) => {
    const bay = bays.find((b) => b.id === key)
    const bayNumber = bay ? bay.bay_number : parseInt(key, 10) || 0
    const priceVal = isPriceObject ? (price as Record<string, any>)[key] : price
    const extraPriceVal = isExtraPriceObject
      ? (pricePerExtraPlayer as Record<string, any>)[key]
      : pricePerExtraPlayer
    return { key, bayNumber, priceVal, extraPriceVal }
  })

  bayData.sort((a, b) => a.bayNumber - b.bayNumber)

  const groups: GroupType[] = []
  let currentGroup: GroupType | null = null

  for (const item of bayData) {
    if (!currentGroup) {
      currentGroup = {
        pricing: { priceVal: item.priceVal, extraPriceVal: item.extraPriceVal },
        bayNumbers: [item.bayNumber],
      }
    } else {
      if (
        item.priceVal === currentGroup.pricing.priceVal &&
        item.extraPriceVal === currentGroup.pricing.extraPriceVal
      ) {
        currentGroup.bayNumbers.push(item.bayNumber)
      } else {
        groups.push(currentGroup)
        currentGroup = {
          pricing: { priceVal: item.priceVal, extraPriceVal: item.extraPriceVal },
          bayNumbers: [item.bayNumber],
        }
      }
    }
  }
  if (currentGroup) {
    groups.push(currentGroup)
  }

  const getRangeString = (bayNumbers: number[]) => {
    if (bayNumbers.length === 1) {
      return capitalize(`${t('words.bay_one')} ${bayNumbers[0]}`)
    } else {
      return capitalize(
        `${t('words.bay_one')} ${bayNumbers[0]}-${bayNumbers[bayNumbers.length - 1]}`
      )
    }
  }

  return (
    <div>
      {groups.map((group, index) => (
        <div key={index} className="flex items-center gap-1">
          <span className="text-text-light select-none text-lg">&#8226;</span>
          <p className="m-0">{getRangeString(group.bayNumbers)}:</p>
          <p className="m-0 font-bold">
            {group.pricing.priceVal}
            {group.pricing.extraPriceVal ? ` / ${group.pricing.extraPriceVal}` : ''}
          </p>
        </div>
      ))}
    </div>
  )
}

export default PricePopoverContent
