import { AMPLITUDE_OPERATION_METHODS, API_ENV_KEYS } from '@sweetspot/shared/util/constants'
import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'

const { REACT_APP_API_ENV } = process.env as unknown as {
  REACT_APP_API_ENV: string
}

const SAMPLE_RATE_PRODUCTION = 0.7 // Covers 70% of users sessions
const SAMPLE_RATE_DEV = 0 // Covers 0% of users sessions

export const sessionReplayTracking = sessionReplayPlugin({
  forceSessionTracking: false,
  sampleRate: REACT_APP_API_ENV === API_ENV_KEYS.PROD ? SAMPLE_RATE_PRODUCTION : SAMPLE_RATE_DEV,
})

export const startAmplitudeSessionTracking = async (): Promise<void> => {
  await amplitude.add(sessionReplayTracking).promise
}

export const endAmplitudeSessionTracking = async (): Promise<void> => {
  await amplitude.remove(sessionReplayTracking?.name).promise
}

export const setAmplitudeUserId = (userId: string) => {
  amplitude.setUserId(userId)
}

export const updateUserProperty = (
  userProperty: string,
  userValue: string | string[],
  operation = AMPLITUDE_OPERATION_METHODS.SET
) => {
  const identifyEvent = new amplitude.Identify()

  if (operation === AMPLITUDE_OPERATION_METHODS.POST_INSERT) {
    identifyEvent.postInsert(userProperty, userValue)
  } else {
    identifyEvent.set(userProperty, userValue)
  }

  amplitude.identify(identifyEvent)
}
