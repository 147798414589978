import React, { useMemo, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

import {
  RULE_TYPES as PROMOTION_RULE_TYPES,
  TYPES_OBJ as PROMOTION_TYPES_OBJ,
} from '@sweetspot/sweetspot-js/features/promotions/constants/rules'
import {
  RULE_TYPES as POLICY_RULE_TYPES,
  TYPES_OBJ as POLICY_TYPES_OBJ,
} from '@sweetspot/sweetspot-js/features/reservationPolicies/constants/rules'

import CoursesAndSpaces from './Rules/CoursesAndSpaces'
import BookingSource from './Rules/BookingSource'
import DaysAndTimes from './Rules/DaysAndTimes'
import PrimeTime from './Rules/PrimeTime'
import DropdownHeader from '../DropdownHeader'
import { useTranslation } from 'react-i18next'
import BookingPeriod from '@sweetspot/sweetspot-js/features/promotions/components/SingleRule/Rules/BookingPeriod'
import BookingWindow from '@sweetspot/sweetspot-js/features/promotions/components/SingleRule/Rules/BookingWindow'
import BallDispensersRule from '@sweetspot/sweetspot-js/features/promotionsReservationsCommon/Rules/BallDispensersRule'

const MembershipCardRules = ({ rules, context }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const RULE_TYPES = context === 'reservationPolicies' ? POLICY_RULE_TYPES : PROMOTION_RULE_TYPES
  const TYPES_OBJ = context === 'reservationPolicies' ? POLICY_TYPES_OBJ : PROMOTION_TYPES_OBJ
  const sortedRules = useMemo(() => {
    return rules.sort((a, b) => {
      const aId = RULE_TYPES.find((x) => x.value === a.type).id
      const bId = RULE_TYPES.find((x) => x.value === b.type).id

      return aId > bId ? 1 : -1
    })
  }, [rules])

  return (
    <div className={cx(styles.container)}>
      <DropdownHeader open={open} setOpen={setOpen} title={t('words.rules')} />

      {open && (
        <div className={cx(styles.fold, open && styles.open)}>
          {sortedRules.map((rule) => (
            <React.Fragment key={rule.id}>
              {rule.type === TYPES_OBJ.SOURCE && <BookingSource rule={rule} />}
              {rule.type === TYPES_OBJ.COURSESANDSPACES && <CoursesAndSpaces rule={rule} />}
              {rule.type === TYPES_OBJ.DAYSANDTIMES && <DaysAndTimes rule={rule} />}
              {rule.type === TYPES_OBJ.PRIMETIME && <PrimeTime rule={rule} context={context} />}
              {rule.type === TYPES_OBJ.PERIOD && <BookingPeriod rule={rule} isMini />}
              {rule.type === TYPES_OBJ.BOOKING_WINDOW && <BookingWindow rule={rule} isMini />}
              {rule.type === TYPES_OBJ.RANGE_DISPENSER && <BallDispensersRule rule={rule} isMini />}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  )
}

MembershipCardRules.propTypes = {
  rules: PropTypes.array,
  context: PropTypes.string,
}

MembershipCardRules.defaultProps = {
  rules: [],
  context: 'promotions',
}

export default MembershipCardRules
