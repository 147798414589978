import React from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

type SimulatorRangesConflictHeaderProps = {
  isConflict: boolean
  onCancel: () => void
}

const SimulatorRangesConflictHeader = ({
  isConflict,
  onCancel,
}: SimulatorRangesConflictHeaderProps) => {
  const { t } = useTranslation()

  return (
    <div className={cx(styles.container, isConflict && styles.visible)}>
      <div className={cx(styles.inner)}>
        <span className={cx('material-icons-round md-32 md-red', styles.icon)}>warning</span>
        <p className={cx(styles.text)}>{t('sentences.thereIsAConflictWithAnotherBooking')}</p>
        <span onClick={onCancel} className={cx('material-icons md-32 md-red', styles.close)}>
          close
        </span>
      </div>
    </div>
  )
}

export default SimulatorRangesConflictHeader
