import { create } from 'zustand'
import { SideBarMode, Resource } from '../../types'

interface ResourcesStore {
  timezone: string
  setTimezone: (timezone: string) => void
  isSideBarOpen: boolean
  toggleSideBar: (mode?: SideBarMode) => void
  sideBarMode?: SideBarMode
  areResourcesLoading: boolean
  resources: Resource[]
  selectedResource: Resource | null
  setResources: (resources: Resource[]) => void
  setSelectedResource: (resource: Resource | null) => void
  setAreResourcesLoading: (areResourcesLoading: boolean) => void
}

export const useResourcesStore = create<ResourcesStore>((set) => ({
  timezone: 'UTC',
  setTimezone: (timezone: string) => set({ timezone }),
  isSideBarOpen: false,
  toggleSideBar: (mode?: SideBarMode) => {
    set((state) => ({
      ...state,
      isSideBarOpen: !state.isSideBarOpen,
      sideBarMode: state.isSideBarOpen ? undefined : mode,
    }))
  },
  areResourcesLoading: true,
  resources: [],
  selectedResource: null,
  setResources: (resources: Resource[]) => set({ resources }),
  setSelectedResource: (selectedResource: Resource | null) => set({ selectedResource }),
  setAreResourcesLoading: (areResourcesLoading: boolean) => set({ areResourcesLoading }),
}))
