import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { t } from 'i18next'

import CloseIcon from '@sweetspot/sweetspot-js/common/components/CloseIcon'
import OverlayContainer from '@sweetspot/sweetspot-js/common/components/OverlayContainer'
import Text from '@sweetspot/club-portal-legacy/components/Text'

import style from './style.module.scss'
import { Button } from '@sweetspot/scramble-ds/atoms'

/**
 * Takes an array of Text ids and numbers and returns a div with the text displayed correctly
 * E.g. "Mark 10 players as paid?"
 */
const renderArrayOfText = (array, number, isInline) => {
  return (
    <div className={classnames(style.displayInline, { [style.inline]: isInline })}>
      {array.map((word, key) => {
        if (word === 'replaceByNumber') return <div key={key++}>&nbsp;{number}&nbsp;</div>
        else if (word?.charAt(0) === '.')
          return (
            <React.Fragment key={key++}>
              <Text textId={word.slice(1)} />
              &nbsp;
            </React.Fragment>
          )
        else
          return (
            <div key={key++}>
              <b>{word}</b>&nbsp;
            </div>
          )
      })}
    </div>
  )
}

const ConfirmWindow = ({
  headerIcon,
  headerText,
  textArray,
  number,
  isInline,
  disableConfirmButton = false,
  confirmText,
  cancelText,
  onConfirm,
  confirmVariant = 'primary',
  onCancel,
  children,
}) => {
  return (
    <OverlayContainer>
      <div className={style.container}>
        <div className={style.header}>
          {headerIcon}
          <Text textId={headerText} />

          <div className={style.closeBtn} onClick={onCancel}>
            <CloseIcon />
          </div>
        </div>

        <div className={style.body}>
          {textArray && renderArrayOfText(textArray, number, isInline)}
          {children}

          <div className={style.buttons}>
            <Button size="medium" variant="ghost-dark" onClick={onCancel}>
              {t(cancelText || 'cancel')}
            </Button>
            <Button
              size="medium"
              disabled={disableConfirmButton}
              variant={confirmVariant}
              className={`md-32 w-full`}
              onClick={onConfirm}
            >
              {t(confirmText || 'yes')}
            </Button>
          </div>
        </div>
      </div>
    </OverlayContainer>
  )
}

ConfirmWindow.propTypes = {
  headerIcon: PropTypes.object,
  headerText: PropTypes.string,
  textArray: PropTypes.array,
  number: PropTypes.number,
  isInline: PropTypes.bool,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default ConfirmWindow
