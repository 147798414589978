import {
  Input,
  InputBase,
  InputContainer,
  InputLeadingContainer,
  InputTrailingContainer,
} from '@sweetspot/scramble-ds'
import { Toggle, Tooltip } from '@sweetspot/scramble-ds/atoms'
import { Controller } from 'react-hook-form'
import { ToggleInputProps } from '../types'

export const ToggleInput = ({
  control,
  name,
  text,
  infoText,
  onToggle,
  disabled,
}: ToggleInputProps) => {
  return (
    <InputBase className="mb-lg">
      <InputContainer>
        <InputLeadingContainer>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Toggle
                checked={field.value}
                onCheckedChange={(e) => {
                  if (onToggle) {
                    onToggle(e, field)
                  } else {
                    field.onChange(e)
                  }
                }}
                disabled={disabled}
              />
            )}
          />
        </InputLeadingContainer>
        <Input
          rounding="rectangular"
          className="text-content-base bg-background-mono-lighter cursor-default pl-16"
          readOnly
          value={text}
        />
        <Tooltip.Provider delayDuration={100}>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <InputTrailingContainer>
                <i className="fa-regular fa-circle-info cursor-info" />
              </InputTrailingContainer>
            </Tooltip.Trigger>
            <Tooltip.Content
              align="start"
              side="bottom"
              className="mr-2 rounded-sm bg-black p-2 text-white"
            >
              <Tooltip.Arrow />
              {infoText}
            </Tooltip.Content>
          </Tooltip.Root>
        </Tooltip.Provider>
      </InputContainer>
    </InputBase>
  )
}
