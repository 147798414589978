import { cn } from '@sweetspot/scramble-ds/utils'
import { Tag } from '@sweetspot/scramble-ds/atoms'
import { TeeTimeCategory } from '@sweetspot/shared/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/pro-solid-svg-icons'
import React from 'react'

const renderText = ({
  value,
  noPadding,
  italic,
}: {
  value: string
  noPadding?: boolean
  italic?: boolean
}) => (
  <p
    className={cn(
      'text-content-base font-regular text-text-dark overflow-hidden text-ellipsis whitespace-nowrap',
      {
        '!px-2': !noPadding,
        italic,
      }
    )}
  >
    {value}
  </p>
)

const renderTag = ({ value, t }: { value: string; t: (key: string) => string }) => {
  return <Tag.Root className="text-content-xs mx-2 !px-2 !py-0.5 font-medium">{t(value)}</Tag.Root>
}

const getHeaders = (t: (key: string) => string) => ({
  created: {
    name: '.words.created',
    type: 'label',
    valueType: 'string',
    width: '300px',
    height: '40px',
    render: (value: string) =>
      renderText({
        value: value,
      }),
  },
  name: {
    name: '.words.name',
    type: 'label',
    valueType: 'string',
    height: '40px',
    render: (value: string) => renderText({ value: value || '-' }),
  },
  duration: {
    name: '.words.duration',
    type: 'label',
    valueType: 'string',
    width: '200px',
    height: '40px',
    render: (value: string) => renderText({ value }),
  },
  days: {
    name: '.dateTime.words.days',
    type: 'label',
    valueType: 'string',
    height: '40px',
    render: (value: string) => renderTag({ value, t }),
  },
  betweenTime: {
    name: '.sentences.betweenTime',
    type: 'label',
    valueType: 'string',
    width: '200px',
    height: '40px',
    render: (value: string) => renderText({ value }),
  },
  bay: {
    name: '.settings.bays.bays',
    type: 'label',
    valueType: 'string',
    height: '40px',
    render: (value: string) => renderTag({ value, t }),
  },
  category: {
    name: '.words.category',
    type: 'label',
    valueType: 'string',
    width: '200px',
    height: '40px',
    render: (value: TeeTimeCategory) => (
      <div className="flex items-center">
        <div className="flex h-6 items-center justify-center pr-2">
          <FontAwesomeIcon icon={faSquare} color={value?.color} />
        </div>
        {renderText({ value: value?.name || '' })}
      </div>
    ),
  },
  slots: {
    name: '.sentences.mapPl',
    type: 'label',
    valueType: 'string',
    height: '40px',
    render: (value: string) => renderText({ value }),
  },
  isPrimeTime: {
    name: '.sentences.primeTime',
    type: 'label',
    valueType: 'string',
    height: '40px',
    render: (value: string) => renderText({ value }),
  },
})

export default getHeaders
