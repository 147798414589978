import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { useToasts } from 'react-toast-notifications'
import debounce from 'lodash/debounce'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

// @ts-expect-error PulseLoader is not in typescript
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { PlayersSearchPayload } from '@sweetspot/shared/data-access/api-platform'
import { Club, GolfCourseTypes, Venue } from '@sweetspot/shared/types'
import {
  InputSelectSearchableContent,
  InputBase,
  InputSelect,
  InputSelectSearchableTrigger,
} from '@sweetspot/scramble-ds'
import { cn } from '@sweetspot/scramble-ds/utils'

import { useTeeSheetSearch } from '../../hooks'
import { FilteredBooking } from '../../types'
import HeaderSearchInput from './components/HeaderSearchInput'
import HeaderSearchOption from './components/HeaderSearchOption'

interface SheetHeaderSearchProps {
  onOptionSelect?: (booking: FilteredBooking) => void
  course: Venue
  currentDate: Date
  disabled: boolean
}

const SheetHeaderSearch = ({
  onOptionSelect,
  course,
  currentDate,
  disabled,
}: SheetHeaderSearchProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [searchValue, setSearchValue] = useState<string>('')
  const [bookings, setBookings] = useState<FilteredBooking[]>([])
  const { addToast } = useToasts()
  const { searchTeeSheet, extractErrorMessageKey, constructPayload } = useTeeSheetSearch()
  const { currentClubCountry } = useSelector(
    (state: { golfClub: { list: Club[]; selectedId: number } }) => ({
      currentClubCountry: state?.golfClub?.list?.find(
        (club) => club.id === state?.golfClub?.selectedId
      )?.address?.country?.code,
    })
  )
  const hasGolfId = useMemo(() => currentClubCountry === 'SE', [currentClubCountry])

  const handleResetSearch = useCallback(() => {
    setSearchValue('')
    setBookings([])
  }, [])

  const handleSearch = useCallback(
    async (inputValue: string) => {
      if (!inputValue || inputValue.length < 3) return
      const value = inputValue.trim()
      // Unexpected search value entered
      if (!value) {
        setSearchValue('')
        addToast(t('toast.invalidSearchError'), { appearance: 'error' })
        return
      }

      const searchPayload: PlayersSearchPayload = constructPayload(value, hasGolfId)
      searchTeeSheet.mutate(
        {
          searchValue: value,
          searchPayload,
          courseType: course?.type as GolfCourseTypes,
          courseUuid: course?.uuid as string,
          currentDate,
        },
        {
          onSuccess: async (data) => {
            setBookings(data)
          },
          onError: (error) => {
            setBookings([])
            addToast(t(extractErrorMessageKey(error as unknown as Error)), { appearance: 'error' })
          },
        }
      )
    },
    [
      t,
      addToast,
      searchTeeSheet,
      extractErrorMessageKey,
      course?.type,
      course?.uuid,
      hasGolfId,
      constructPayload,
      currentDate,
    ]
  )

  // Search after 400ms of inactivity
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchDebounce = useCallback(debounce(handleSearch, 400), [course, currentDate])

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const inputValue = e.target.value
      setSearchValue(inputValue)
      handleSearchDebounce(inputValue)
    },
    [handleSearchDebounce]
  )

  const handleOptionSelect = useCallback(
    (id: string) => {
      const booking = bookings?.find((booking) => booking.id === parseInt(id))

      if (!id || !booking?.startTime || !booking?.courseUuid) return
      if (course?.type === GolfCourseTypes.COURSE) {
        history.push(`/booking/${booking?.uuid}?origin=search`)
      } else {
        history.push(`/simulators-ranges/orders/${booking?.uuid}`)
      }

      setBookings([])
      onOptionSelect?.(booking)
    },
    [bookings, history, onOptionSelect, course?.type]
  )

  return (
    <InputSelect withSearch disabled={disabled}>
      <InputBase>
        <InputSelectSearchableTrigger asChild onClick={handleResetSearch} disabled={disabled}>
          <div className={cn('cursor-pointer px-4', disabled && 'cursor-not-allowed opacity-40')}>
            <FontAwesomeIcon icon={faMagnifyingGlass} size="1x" />
          </div>
        </InputSelectSearchableTrigger>
      </InputBase>
      <InputSelectSearchableContent className="top-2 w-[547px] border drop-shadow" align="end">
        <HeaderSearchInput
          handleInputChange={handleInputChange}
          searchValue={searchValue}
          handleResetSearch={handleResetSearch}
          hasGolfId={hasGolfId}
        />
        {!bookings?.length && searchTeeSheet.isLoading && (
          <PulseLoader loaderClassName="mb-4 flex w-full justify-center" showIf />
        )}
        <div className="max-h-72 overflow-y-scroll">
          {bookings?.map((booking) => (
            <HeaderSearchOption
              key={booking.id}
              {...booking}
              courseType={course?.type}
              hasGolfId={hasGolfId}
              handleOptionSelect={handleOptionSelect}
            />
          ))}
        </div>
      </InputSelectSearchableContent>
    </InputSelect>
  )
}

export default SheetHeaderSearch
