import {
  useCallback,
  createContext,
  useMemo,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'
import useAcademies from '@sweetspot/club-portal-legacy/hooks/useAcademies'
import useAcademySpaces from '@sweetspot/club-portal-legacy/hooks/useAcademySpaces'

import { ManageAcademySpacesContextValue, SpaceSideBarMode } from './types'

export const ManageAcademySpaces = createContext<ManageAcademySpacesContextValue>({
  areAcademiesLoading: false,
  academies: [],
  areSpacesLoading: false,
  areSpacesFetching: false,
  spaces: [],
  openSpaceSideBar: false,
  openDiscardDialog: false,
  setSelectedSpace: () => {
    return
  },
  toggleSpaceSideBar: () => {
    return
  },
  refetchSpaces: () => {
    return
  },
  changeSpaceSideBarMode: () => {
    return
  },
  setOpenDiscardDialog: () => {
    return
  },
  refetchAcademies: () => {
    return
  },
  updateSpace: () => {
    return Promise.resolve()
  },
  createSpace: () => {
    return Promise.resolve()
  },
  deleteSpace: () => {
    return Promise.resolve()
  },
})

export const ManageAcademySpacesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openSpaceSideBar, setOpenSpaceSideBar] = useState(false)
  const [spaceSideBarMode, setSpaceSideBarMode] = useState<SpaceSideBarMode | undefined>()
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false)
  const { academies, areAcademiesLoading, selectedAcademy, setSelectedAcademy, refetchAcademies } =
    useAcademies()
  const {
    spaces,
    areSpacesLoading,
    setSelectedSpace,
    selectedSpace,
    refetch: refetchSpaces,
    areSpacesFetching,
    updateSpace,
    createSpace,
    deleteSpace,
    checkForErrors,
  } = useAcademySpaces({
    selectedAcademyId: selectedAcademy?.id,
  })

  const toggleSpaceSideBar = useCallback(
    (mode?: SpaceSideBarMode) => {
      setSpaceSideBarMode(openSpaceSideBar ? undefined : mode)
      setOpenSpaceSideBar((prev) => !prev)
    },
    [openSpaceSideBar]
  )

  const value = useMemo(
    () => ({
      academies,
      areAcademiesLoading,
      selectedAcademy,
      setSelectedAcademy,
      refetchAcademies,
      spaces,
      areSpacesLoading,
      areSpacesFetching,
      setSelectedSpace,
      selectedSpace,
      openSpaceSideBar,
      toggleSpaceSideBar,
      refetchSpaces,
      spaceSideBarMode,
      changeSpaceSideBarMode: setSpaceSideBarMode,
      openDiscardDialog,
      setOpenDiscardDialog,
      updateSpace,
      createSpace,
      deleteSpace,
      checkForErrors,
    }),
    [
      areAcademiesLoading,
      academies,
      selectedAcademy,
      setSelectedAcademy,
      refetchAcademies,
      spaces,
      areSpacesFetching,
      areSpacesLoading,
      setSelectedSpace,
      selectedSpace,
      openSpaceSideBar,
      toggleSpaceSideBar,
      refetchSpaces,
      spaceSideBarMode,
      setSpaceSideBarMode,
      openDiscardDialog,
      setOpenDiscardDialog,
      updateSpace,
      createSpace,
      deleteSpace,
      checkForErrors,
    ]
  )

  return <ManageAcademySpaces.Provider value={value}>{children}</ManageAcademySpaces.Provider>
}

export const useManageAcademySpacesContext = () => useContext(ManageAcademySpaces)
