import {
  addDays,
  endOfWeek,
  isAfter,
  isBefore,
  parseISO,
  setHours,
  setMinutes,
  startOfWeek,
  parse,
  addMonths,
  fromUnixTime,
  isDate,
  differenceInDays,
  add,
  getDay,
} from 'date-fns'
import { utcToZonedTime, format, zonedTimeToUtc } from 'date-fns-tz'

import { formatPeriodDatesWithTimezone } from '@sweetspot/sweetspot-js/common/functions/DateHelpers'
import {
  getHoursMinsFromISOString,
  getUnixSeconds,
} from '@sweetspot/sweetspot-js/common/functions/dateUtils'
import { Period, TeeTime } from '@sweetspot/shared/types'
import {
  PeriodPricingDaysMap,
  PeriodPricingTableData,
  PeriodPricingTableDataTimeSlot,
  PeriodPricingTimeSlots,
  WeekPeriodOption,
  WHOLE_PERIOD_TABLE_DATA_KEY,
} from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/types'
import { generateTimeSlots } from '@sweetspot/club-portal-legacy/pages/TeeTimePeriods/context/useWeekPeriod/generate-table-data'
import { localizedDaysOfWeek } from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import { leftpad } from '@sweetspot/club-portal-legacy/utils/date'

const MONDAY = 1
const FULL_WEEK_LENGTH = 7
const DAYS_OF_WEEK = localizedDaysOfWeek('en', 0)
const MONDAY_TO_SUNDAY = localizedDaysOfWeek('en', 1)

type GenerateTableDataProps = {
  timePeriod: Period | null
  selectedWeek: WeekPeriodOption
  space?: string
  currency: string | null
  amount?: number | null
  teeTimes: TeeTime[]
  timezone?: string
}

function addTimeWithTimezone(
  weekdays: { startDate: string; endDate: string }[],
  startDate: string,
  endDate: string,
  startHours: string,
  startMinutes: string,
  endHours: string,
  endMinutes: string,
  timezone: string
): WeekPeriodOption[] {
  const periodStartDate = startDate.split('T')[0]
  const periodEndDate = endDate.split('T')[0]

  const parsedPeriodStartDate = parseISO(periodStartDate)
  const parsedPeriodEndDate = parseISO(periodEndDate)

  return weekdays.map((weekday, idx) => {
    const parsedWeekdayStartDate = parse(weekday.startDate, 'yyyy-MM-dd', new Date())
    let parsedWeekdayEndDate = parse(weekday.endDate, 'yyyy-MM-dd', parsedWeekdayStartDate)

    if (isBefore(parsedWeekdayEndDate, parsedWeekdayStartDate)) {
      parsedWeekdayEndDate = addMonths(parsedWeekdayEndDate, 1)
    }

    const start = isAfter(parsedPeriodStartDate, parsedWeekdayStartDate)
      ? parsedPeriodStartDate
      : parsedWeekdayStartDate

    const end = isBefore(parsedPeriodEndDate, parsedWeekdayEndDate)
      ? parsedPeriodEndDate
      : parsedWeekdayEndDate

    const fromZoned = setMinutes(setHours(start, +startHours), +startMinutes)
    const toZoned = setMinutes(setHours(end, +endHours), +endMinutes)

    // Convert the zoned time back to UTC
    const fromDate = zonedTimeToUtc(fromZoned, timezone)
    const toDate = zonedTimeToUtc(toZoned, timezone)

    return {
      id: idx + 1,
      name: `W${idx + 1} ${weekday.startDate} - ${weekday.endDate}`,
      fromDate,
      toDate,
    }
  })
}

export const divideIntoWeeks = (
  startDate?: string,
  endDate?: string,
  startDateFormat = 'MMM d',
  endDateFormat = 'd'
) => {
  if (!startDate || !endDate) return []
  // Parse the ISO string dates as Date objects in UTC
  const start = utcToZonedTime(startDate, 'UTC')
  const end = utcToZonedTime(endDate, 'UTC')

  // Move the start date back to the nearest Monday
  let currentWeekStart = startOfWeek(start, { weekStartsOn: MONDAY }) // Monday start
  const weeks = []

  while (isBefore(currentWeekStart, end) || currentWeekStart.getTime() === end.getTime()) {
    // Get the end of the current week (Sunday)
    const currentWeekEnd = endOfWeek(currentWeekStart, { weekStartsOn: MONDAY })

    // Ensure the current week's end does not go beyond the specified end date
    const weekEnd = isAfter(currentWeekEnd, end) ? end : currentWeekEnd

    // Format dates as 'YYYY-MM-DD' without relying on timezone conversions
    weeks.push({
      startDate: format(currentWeekStart, startDateFormat),
      endDate: format(weekEnd, endDateFormat),
    })

    // Move to the next week
    currentWeekStart = addDays(currentWeekStart, FULL_WEEK_LENGTH)
  }

  return weeks
}

export const formatPeriodStartEndDatesWithTimezone = (
  startDate: string,
  endDate: string,
  timezone = 'UTC'
): [string, string] => {
  if (!startDate || !endDate) return ['', '']

  return [
    formatPeriodDatesWithTimezone(startDate, endDate, timezone),
    formatPeriodDatesWithTimezone(startDate, endDate, timezone, 'yyyy-MM-dd'),
  ]
}

export const getWeekPeriodOptions = (
  timePeriod: Period,
  t: (str: string) => string,
  courseTimezone = 'UTC'
): WeekPeriodOption[] => {
  if (!timePeriod?.id) return []

  const [startHours, startMinutes] = getHoursMinsFromISOString(timePeriod.start_time_from)
  const [endHours, endMinutes] = getHoursMinsFromISOString(timePeriod.start_time_to)
  const { start_date, end_date } = timePeriod

  const weekPeriodOptions = divideIntoWeeks(start_date, end_date, 'yyyy-MM-dd', 'yyyy-MM-dd')

  const periodStartDate = start_date.split('T')[0]
  const periodEndDate = end_date.split('T')[0]

  const start = parseISO(periodStartDate)
  const end = parseISO(periodEndDate)

  const fromZoned = setMinutes(setHours(start, startHours), startMinutes)
  const toZoned = setMinutes(setHours(end, endHours), endMinutes)

  // Convert the zoned time back to UTC
  const fromDate = zonedTimeToUtc(fromZoned, courseTimezone)
  const toDate = zonedTimeToUtc(toZoned, courseTimezone)

  const allPeriod: WeekPeriodOption = {
    id: -1,
    name: t('editPeriodsNew.allPeriod'),
    fromDate,
    toDate,
  }

  return [
    allPeriod,
    ...addTimeWithTimezone(
      weekPeriodOptions,
      start_date,
      end_date,
      startHours,
      startMinutes,
      endHours,
      endMinutes,
      courseTimezone
    ),
  ]
}

export const generateTableDataForAllBays = (
  groupedTeeTimes: { [key: string]: TeeTime[] },
  timePeriod: Period | null,
  selectedWeek: WeekPeriodOption,
  currency: string | null,
  timezone?: string
): {
  timeSlots: PeriodPricingTimeSlots
  daysMap: PeriodPricingDaysMap
  tableData: PeriodPricingTableData
} => {
  const tableData: PeriodPricingTableData = {}

  let timeSlots: PeriodPricingTimeSlots = []
  let daysMap: PeriodPricingDaysMap = {}

  if (selectedWeek?.id === -1) {
    const {
      timeSlots: generatedTimeSlots,
      daysMap: generatedDaysMap,
      data: bayTableData,
    } = generateTableData({
      timePeriod,
      teeTimes: [],
      selectedWeek,
      currency,
      timezone,
    })
    tableData[WHOLE_PERIOD_TABLE_DATA_KEY] = bayTableData as {
      [key: string]: PeriodPricingTableDataTimeSlot[]
    }
    timeSlots = generatedTimeSlots
    daysMap = generatedDaysMap as PeriodPricingDaysMap
    return { tableData, timeSlots, daysMap }
  }

  if (!!timePeriod && !!selectedWeek) {
    Object.keys(groupedTeeTimes).forEach((key) => {
      const teeTimes = groupedTeeTimes[key]
      const {
        timeSlots: generatedTimeSlots,
        daysMap: generatedDaysMap,
        data: bayTableData,
      } = generateTableData({
        timePeriod,
        teeTimes,
        selectedWeek,
        space: key,
        currency,
        timezone,
      })
      tableData[key] = bayTableData as {
        [key: string]: PeriodPricingTableDataTimeSlot[]
      }
      if (!timeSlots.length) {
        timeSlots = generatedTimeSlots
      }
      if (!Object.keys(daysMap).length) {
        daysMap = generatedDaysMap as PeriodPricingDaysMap
      }
    })
  }

  return { tableData, timeSlots, daysMap }
}

export const generateTableData = (options: GenerateTableDataProps) => {
  const { timePeriod, selectedWeek, space, currency, amount = null, timezone } = options
  if (selectedWeek.id !== -1) {
    return generateTableDataFromDB(options)
  }
  const daysMap = generateDaysMap({ selectedWeek, timezone })
  const timeSlots = generateTimeSlots(timePeriod, true)

  const ALL_TABLE_DATA = JSON.parse(localStorage.getItem('ALL_TABLE_DATA') || '{}')

  if (timePeriod && space && ALL_TABLE_DATA[timePeriod.id]?.[-1]?.[space]) {
    const data = ALL_TABLE_DATA[timePeriod.id][-1][space]
    return { data, timeSlots, daysMap }
  }

  let startDate = new Date(selectedWeek.fromDate)
  let endDate = new Date(selectedWeek.toDate)

  /* Defining variables for full Week */
  const monday = new Date(startDate)
  monday.setDate(monday.getDate() - monday.getDay() + 1)
  const sunday = new Date(endDate)
  sunday.setDate(sunday.getDate() + (FULL_WEEK_LENGTH - (sunday.getDay() % FULL_WEEK_LENGTH)))

  if (selectedWeek.id === -1) {
    startDate = monday
    endDate = sunday
  }

  const data: {
    [key: string]: PeriodPricingTableDataTimeSlot[]
  } = {}
  const [startHours, startMins] = timePeriod?.start_time_from.split('T')[1].split(':') || []

  const currentDate = new Date(startDate)
  currentDate.setHours(+startHours)
  currentDate.setMinutes(+startMins)

  const { interval } = timePeriod as Period
  while (!data.Monday || currentDate.getDay() !== 1) {
    const dayOfWeek = DAYS_OF_WEEK[currentDate.getDay()]
    data[dayOfWeek] = data[dayOfWeek] || []
    while (data[dayOfWeek].length < timeSlots.length) {
      let hours = leftpad(currentDate.getHours())
      let mins = leftpad(currentDate.getMinutes())
      const from = `${hours}:${mins}`
      currentDate.setMinutes(+mins + interval)
      hours = leftpad(currentDate.getHours())
      mins = leftpad(currentDate.getMinutes())
      const to = `${hours}:${mins}`

      const timeSlot = {
        from,
        to,
        price: {
          amount,
          currency,
        },
        price_per_extra_player: 0,
      } as PeriodPricingTableDataTimeSlot

      data[dayOfWeek].push(timeSlot)
    }
    currentDate.setDate(currentDate.getDate() + 1)
    currentDate.setHours(+startHours)
    currentDate.setMinutes(+startMins)
  }
  // remove days that dont have time slots
  Object.keys(daysMap).forEach((day: string) => {
    if (!data[day]?.length) {
      delete daysMap[day]
    }
  })
  return { timeSlots, data, daysMap }
}

const generateTableDataFromDB = (options: GenerateTableDataProps) => {
  const ALL_TABLE_DATA = JSON.parse(localStorage.getItem('ALL_TABLE_DATA') || '{}')
  const { timePeriod, teeTimes, space, selectedWeek, timezone } = options
  const { id } = timePeriod as Period

  const daysMap = generateDaysMap(options)
  const timeSlots = generateTimeSlots(timePeriod, true)

  if (space && ALL_TABLE_DATA[id]?.[selectedWeek.id]?.[space]) {
    const data = ALL_TABLE_DATA[id][selectedWeek.id][space]
    return { timeSlots, daysMap, data }
  }

  const data: { [key: string]: { from: string; to: string }[] } = {}
  const teeTimesCopy = [...teeTimes]
  let currentIndex = 0

  for (let i = 0; i < FULL_WEEK_LENGTH; i++) {
    const dayOfWeek = MONDAY_TO_SUNDAY[i]
    if (!(dayOfWeek in daysMap)) {
      continue
    }

    const teeTimesWithNoOffset = teeTimesCopy.map((teeTime) => {
      const elementWithNoOffSet = { ...teeTime }
      elementWithNoOffSet.from = format(
        utcToZonedTime(
          fromUnixTime(getUnixSeconds(new Date(teeTime.from)) as number),
          timezone as string
        ),
        "yyyy-MM-dd'T'HH:mm:ssXXX",
        { timeZone: timezone }
      )

      elementWithNoOffSet.to = format(
        utcToZonedTime(
          fromUnixTime(getUnixSeconds(new Date(teeTime.to)) as number),
          timezone as string
        ),
        "yyyy-MM-dd'T'HH:mm:ssXXX",
        { timeZone: timezone }
      )

      return elementWithNoOffSet
    })

    let dayTimeSlots = teeTimesWithNoOffset.slice(currentIndex, currentIndex + timeSlots.length)
    currentIndex += timeSlots.length

    if (dayTimeSlots.length > 0) {
      data[dayOfWeek] = dayTimeSlots
    }

    dayTimeSlots = []
  }
  // remove days that dont have time slots
  Object.keys(daysMap).forEach((day) => {
    if (!data[day]?.length) {
      delete daysMap[day]
    }
  })

  return { data, timeSlots, daysMap }
}

const generateDaysMap = ({
  selectedWeek,
  timezone,
}: {
  selectedWeek: WeekPeriodOption
  timezone?: string
}): { [key: string]: string } => {
  const daysMap: { [key: string]: string } = {}
  const { fromDate: startDate, toDate: finalDate } = selectedWeek
  let zonedStartDate = utcToZonedTime(startDate, timezone as string)
  const zonedFinalDate = utcToZonedTime(finalDate, timezone as string)

  const startWeekDay = isDate(startDate) ? zonedStartDate.getDay() : new Date(startDate).getDay()
  const finalWeekDay = isDate(finalDate) ? zonedFinalDate.getDay() : new Date(finalDate).getDay()

  if (differenceInDays(finalDate, startDate) < FULL_WEEK_LENGTH - 1) {
    //WEEKLY_PERIOD_SET for a partial week
    //Populating the object daysMap with key-value pairs regarding a non-complete week, starting on "${startWeekDay}" and ending on a "${finalWeekDay}"
    if (startWeekDay <= finalWeekDay) {
      for (let i = startWeekDay; i <= finalWeekDay; i++) {
        // const utcStartDate = utcToZonedTime(startDate, 'UTC')
        daysMap[DAYS_OF_WEEK[getDay(zonedStartDate)]] = format(zonedStartDate, 'yyyy-MM-dd')
        zonedStartDate = add(zonedStartDate, { days: 1 })
      }
    } else {
      //Handle the case where the week starts on Saturday and ends on Sunday
      for (let i = startWeekDay - 1; i <= FULL_WEEK_LENGTH - 1; i++) {
        // const utcStartDate = utcToZonedTime(startDate, 'UTC')
        daysMap[DAYS_OF_WEEK[getDay(zonedStartDate)]] = format(zonedStartDate, 'yyyy-MM-dd')
        zonedStartDate = add(zonedStartDate, { days: 1 })
      }
    }
  } else {
    //Independent if it is an initial ALL_PERIOD_SET or if it is just a WEEKLY_PERIOD_SET for a complete week (not partial week)
    //Populating the object daysMap with key-value pairs regarding a complete week, starting on a Monday and ending on a Sunday
    for (let i = 0; i <= FULL_WEEK_LENGTH - 1; i++) {
      // const utcStartDate = utcToZonedTime(startDate, 'UTC')
      daysMap[DAYS_OF_WEEK[getDay(zonedStartDate)]] = format(zonedStartDate, 'yyyy-MM-dd')
      zonedStartDate = add(zonedStartDate, { days: 1 })
    }
  }

  return daysMap
}
