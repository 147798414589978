import React, { useMemo } from 'react'
import ManageBookingOwnerBox from '../../../../ManageBookingOwnerBox'
import AssignBookingOwnerBox from '../../../../AssignBookingOwnerBox'
import SpaceBox from '../../../../SpaceBox'
import PlayerSearch from '@sweetspot/sweetspot-js/ui-kit/components/PlayerSearch'
import BookingNotesSims from '../../../../BookingNotesSims'
import styles from './styles.module.scss'
import cx from 'classnames'
import { Button } from '@sweetspot/scramble-ds/atoms'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import BallsBox from './BallsBox/BallsBox'
import { FlagNames, useFlag } from '@sweetspot/shared/util/feature-flag'

export const DetailsStep = ({
  orderBooking,
  hasCustomer,
  hasStubCustomer,
  orderBookingIsFetching,
  addedSpaces,
  removingSpace,
  handleRemoveSpace,
  assignOwner,
  isAssigningOwner,
  handleEditSpacesButton,
  isBookingConfirmed,
  isConsideredPaid,
  timezone,
  teeTimes,
  spaceItems,
  freeBallsCount,
  totalNrOfBalls,
}) => {
  const currentClub = useSelector((state) => {
    return state?.golfClub?.list.find((club) => club.id === state?.golfClub?.selectedId)
  })
  const { t } = useTranslation()
  const isIncludeBallsInBookingEnabled = useFlag(FlagNames.IncludeBallsInBooking)

  const clubFederationType = useMemo(() => {
    if (currentClub?.git_id) return 'git'
    if (currentClub?.cdh_id) return 'cdh'
    return null
  }, [currentClub])

  const getSearchPlaceholderText = () => {
    if (clubFederationType === 'cdh') {
      return t('sentences.searchPlayersByNamePhoneEmailOrCDHNumber')
    }
    if (clubFederationType === 'git') {
      return t('sentences.searchPlayersByNamePhoneEmailOrGolfId')
    }
    return t('sentences.searchByNameEmailPhone')
  }

  return (
    <>
      <ManageBookingOwnerBox
        orderBooking={orderBooking}
        isOpen={hasCustomer || hasStubCustomer}
        shouldRender={!!orderBooking}
      />
      <AssignBookingOwnerBox
        isOpen={!hasCustomer && !hasStubCustomer}
        shouldRender={!!orderBooking}
      >
        <PlayerSearch
          className={cx(styles.customPlayerSearch)}
          onSelectPlayer={assignOwner}
          disabled={isAssigningOwner || orderBookingIsFetching}
          club={currentClub}
          clubFederationType={clubFederationType}
          searchPlaceholder={getSearchPlaceholderText()}
        />
      </AssignBookingOwnerBox>
      <hr className={styles.divider} />
      <div className={'mb-8 flex items-center justify-between'}>
        <p className={'text-content-base font-bold'}>{t('words.space_other')}</p>
        <Button
          onClick={handleEditSpacesButton}
          disabled={isConsideredPaid || isBookingConfirmed}
          variant={'ghost-dark'}
          size={'sm'}
          className={cx(styles.editSpaceButton, 'px-4 py-1')}
        >
          <FontAwesomeIcon icon={faPen} />
          {t('sentences.editSpaces')}
        </Button>
      </div>
      {!!addedSpaces?.length && (
        <div className={styles.spacesContainer}>
          {addedSpaces.map((space) => (
            <SpaceBox
              key={space.id}
              space={space}
              canRemove={addedSpaces.length > 1}
              removeDisabled={removingSpace}
              currencyCode={orderBooking.currency_code}
              hasOwner={hasCustomer || hasStubCustomer}
              items={spaceItems[space.id]}
              onRemove={() => handleRemoveSpace(space.id, space.uuid)}
              timezone={timezone}
              teeTimes={teeTimes[space.id]}
            />
          ))}
        </div>
      )}
      {isIncludeBallsInBookingEnabled &&
        orderBooking?.course?.belongs_to_range_context &&
        freeBallsCount && <BallsBox nrOfBalls={freeBallsCount} totalNrOfBalls={totalNrOfBalls} />}
      <BookingNotesSims bookingID={orderBooking.uuid} />
    </>
  )
}
