import { useTranslation } from 'react-i18next'
import { useResourcesStore } from '../../../../hooks/resources/useResourcesStore'
import { useIsMobile } from '../../../../hooks/useIsMobile'

const Breadcrumb = () => {
  const { selectedResource } = useResourcesStore()
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const crumbs = [
    t('words.settings'),
    t('words.academy'),
    isMobile && selectedResource ? t('words.resources') : null,
  ].filter(Boolean)

  return (
    <p className="text-text-dark text-content-xs font-regular overflow-hidden text-ellipsis whitespace-nowrap">
      {crumbs.join(' / ')}
    </p>
  )
}

export default Breadcrumb
