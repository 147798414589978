import { useSelector } from 'react-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import { useEffect, useState } from 'react'
import _isEqual from 'lodash/isEqual'
import { GolfCourse, GolfCourseTypes } from '@sweetspot/shared/types'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { queryCourses } from '@sweetspot/sweetspot-js/features/courses/services/api-platform'

const useExtras = () => {
  const currentClubId = useSelector((state: RootState) => state.golfClub.selectedId)
  const [selectedExtra, setSelectedExtra] = useState<GolfCourse | undefined>()

  const {
    data,
    isLoading: areExtrasLoading,
    refetch,
  } = useQuery(
    [CLUB_QUERIES.COURSES, GolfCourseTypes.OTHER, currentClubId],
    () => queryCourses({ 'club.id': currentClubId, type: GolfCourseTypes.OTHER }),
    {
      enabled: !!currentClubId,
    }
  )

  // if extras are updated, update the selected extra if needed
  useEffect(() => {
    if (selectedExtra) {
      const updatedExtra = data?.find((extra: GolfCourse) => extra.id === selectedExtra.id)
      if (updatedExtra && !_isEqual(selectedExtra, updatedExtra)) {
        setSelectedExtra(updatedExtra)
      }
    }
  }, [data, selectedExtra])

  return {
    extras: (data as GolfCourse[]) || [],
    areExtrasLoading,
    selectedExtra,
    setSelectedExtra,
    refetchExtras: refetch,
  }
}

export default useExtras
