import { Divider } from '@sweetspot/scramble-ds/atoms'
import { useIsMobile } from '../../../../hooks/useIsMobile'
import ResourcesList from './ResourcesList'
import ResourceDetails from './ResourceDetails'
import { useResourcesStore } from '../../../../hooks/resources/useResourcesStore'

const Resources = () => {
  const isMobile = useIsMobile()
  const { selectedResource, setSelectedResource, timezone } = useResourcesStore()

  if (isMobile) {
    return (
      <div className="flex flex-1">
        {selectedResource ? (
          <ResourceDetails resource={selectedResource} timezone={timezone} />
        ) : (
          <ResourcesList
            handleResourceSelect={setSelectedResource}
            selectedResource={selectedResource}
          />
        )}
      </div>
    )
  }

  return (
    <div className="flex flex-1">
      <ResourcesList
        handleResourceSelect={setSelectedResource}
        selectedResource={selectedResource}
      />
      <Divider orientation="vertical" className="bg-surface-inverted-primary" />
      <div className="flex h-full w-[375px]">
        <ResourceDetails resource={selectedResource} timezone={timezone} />
      </div>
    </div>
  )
}

export default Resources
