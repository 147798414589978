import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Accordion, Divider } from '@sweetspot/scramble-ds/atoms'
import { useAvailabilityAndPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/AvailabilityAndPricingProvider'
import { faCalendarRange } from '@fortawesome/pro-regular-svg-icons'
import { faSquare } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PeriodActionsPopover from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/PeriodActionsPopover'
import { formatPeriodStartEndDatesWithTimezone } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/utils'

const SelectedPeriodInformation = ({
  label,
  value,
  children,
}: {
  label: string
  value?: string | number
  children?: ReactNode
}) => {
  return (
    <div className="py-2">
      <p className="text-content-sm text-text-subtle overflow-hidden text-ellipsis whitespace-nowrap font-medium">
        {label}
      </p>
      {!!value && (
        <p className="text-content-base text-text-dark overflow-hidden text-ellipsis whitespace-nowrap font-medium">
          {value}
        </p>
      )}
      {children}
    </div>
  )
}

const SelectedPeriod = () => {
  const { selectedPeriod } = useAvailabilityAndPricingContext()
  const { t } = useTranslation()

  const [formattedDateRangePrimary, formattedDateRangeSecondary] = useMemo(() => {
    if (!selectedPeriod) {
      return ['', '']
    }

    const { start_date, end_date } = selectedPeriod

    return formatPeriodStartEndDatesWithTimezone(start_date, end_date)
  }, [selectedPeriod])

  const [startTime, endTime] = useMemo(() => {
    if (!selectedPeriod) {
      return ['', '']
    }

    const { start_time_from, start_time_to } = selectedPeriod

    if (!start_time_from || !start_time_to) {
      return ['', '']
    }

    return [start_time_from.substr(11, 5), start_time_to.substr(11, 5)]
  }, [selectedPeriod])

  if (!selectedPeriod) {
    return null
  }
  return (
    <div className="flex flex-1 flex-col gap-0.5">
      <p className="text-content-sm font-bold">{t('words.period')}</p>
      <Accordion.Root type="multiple">
        <Accordion.Item
          value="selectedPeriod"
          className="bg-background-base-neutral border-border-stroke-clean flex min-h-12 flex-col justify-center rounded-md border"
        >
          <Accordion.Trigger childrenClassName="flex flex-1 flex-row pr-0">
            <Accordion.Label className="flex flex-1">
              <p className="text-content-lg overflow-hidden text-ellipsis whitespace-nowrap font-bold">
                {selectedPeriod.name}
              </p>
              <div className="px-lg flex flex-1 items-center justify-between gap-4">
                <div className="flex items-center gap-4">
                  <div className="flex h-6 w-6 items-center justify-center">
                    <FontAwesomeIcon icon={faCalendarRange} size={16} />
                  </div>
                  <p className="text-text-dark text-content-base overflow-hidden text-ellipsis whitespace-nowrap font-medium">
                    {formattedDateRangePrimary}
                  </p>
                </div>
                {!selectedPeriod.is_frozen && (
                  <PeriodActionsPopover periodUuid={selectedPeriod.uuid} />
                )}
              </div>
            </Accordion.Label>
          </Accordion.Trigger>
          <Accordion.Content className="bg-background-base-neutral">
            <div className="flex flex-col justify-center self-stretch px-4">
              <Divider />
              <div className="flex flex-wrap items-center gap-x-8 gap-y-0">
                <SelectedPeriodInformation
                  label={t('words.dates')}
                  value={formattedDateRangeSecondary}
                />
                <SelectedPeriodInformation
                  label={t('sentences.openingHours')}
                  value={`${startTime} - ${endTime}`}
                />
                <SelectedPeriodInformation
                  label={t('sentences.bookingDuration')}
                  value={`${selectedPeriod.interval} min`}
                />
                <SelectedPeriodInformation label={t('words.category')}>
                  <div className="flex items-center">
                    <div className="flex h-6 items-center justify-center pr-2">
                      <FontAwesomeIcon icon={faSquare} color={selectedPeriod.category?.color} />
                    </div>
                    <p className="text-content-base text-text-dark overflow-hidden text-ellipsis whitespace-nowrap font-medium">
                      {selectedPeriod.category?.name}
                    </p>
                  </div>
                </SelectedPeriodInformation>
                <SelectedPeriodInformation
                  label={t('sentences.maxPlayers')}
                  value={selectedPeriod.slots}
                />
              </div>
            </div>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
    </div>
  )
}
export default SelectedPeriod
