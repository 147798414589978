import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import { InputTrailingContainer } from '@sweetspot/scramble-ds'
import { Popover } from '@sweetspot/scramble-ds/atoms'

type PopoverHintProps = {
  i18nKey: string
}

const PopoverHint = ({ i18nKey }: PopoverHintProps) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <Popover.Root open={open}>
      <Popover.Trigger asChild>
        <InputTrailingContainer
          className="relative bottom-0 left-0 right-0 top-0 flex w-6 transform-none items-center"
          onClick={() => setOpen(true)}
        >
          <i className="fa-regular fa-circle-info text-text-subtle" />
        </InputTrailingContainer>
      </Popover.Trigger>
      <Popover.Content
        align="end"
        className="text-content-sm bg-base-black text-base-white z-[99] w-[306px] px-4 py-6"
        onInteractOutside={() => setOpen(false)}
      >
        <Trans i18nKey={i18nKey} components={{ strong: <strong />, br: <br /> }} />
        <Popover.Arrow className="fill-black" />
      </Popover.Content>
    </Popover.Root>
  )
}

export default PopoverHint
