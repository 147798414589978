import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import m from 'moment'
import style from './style.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import dh from '../../helpers/DateHelpers'
import sh from '../../helpers/StringHelpers'
import Text from '../Text'

/**
 * This component creates a mini calendar with selectable dates
 */
class MiniCalendar extends Component {
  constructor(props) {
    super(props)

    let date = this.props.initialDate ? this.props.initialDate : dh.today()

    this.state = {
      currentDate: date,
      selectedDate: date,
      isOpen: true,
      style: style,
    }

    this.nextMonth = this.nextMonth.bind(this)
    this.previousMonth = this.previousMonth.bind(this)
    this.getDate = this.getDate.bind(this)
    this.getDateString = this.getDateString.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { initialDate } = this.props
    if (initialDate && m(initialDate).isValid()) {
      if (!prevProps.initialDate || !m(initialDate).isSame(prevProps.initialDate, 'day')) {
        this.setDate(initialDate)
      }
    }
  }

  handleOnClickDate = (date) => {
    const { selectedDate } = this.state
    const { onDateChange, startDate, endDate } = this.props

    if (startDate && !m(date).isSameOrAfter(startDate, 'day')) return
    if (endDate && m(date).isSameOrAfter(endDate, 'day')) return

    if (selectedDate !== date && onDateChange) {
      this.setState(
        {
          selectedDate: date,
          currentDate: date,
        },
        () => {
          onDateChange(date, this.getDateString())
        }
      )
    }
  }

  /**
   * Returns the selected date
   *
   * @returns {Date} selected date
   * @public
   */
  getDate() {
    return this.state.selectedDate
  }

  /**
   * Returns the selected date as a string in the selected user language
   *
   * @returns {String} selected date as a string
   * @public
   */
  getDateString() {
    const { returnDateFormat, lang } = this.props
    const { selectedDate } = this.state
    if (returnDateFormat && m(selectedDate).format(returnDateFormat) !== 'Invalid date') {
      return m(selectedDate).format(returnDateFormat)
    }
    return dh.toWeekdayDateString(selectedDate, lang)
  }

  /**
   * Sets the selected date
   *
   * @param {Date} date
   * @public
   */
  setDate(date) {
    this.setState({
      currentDate: date,
      selectedDate: date,
    })
  }

  /**
   * Sets the selected date to the first of the next month
   */
  nextMonth() {
    this.setState({
      currentDate: dh.nextMonth(this.state.currentDate),
    })
  }

  /**
   * Sets the selected date to the first of the previous month
   */
  previousMonth() {
    this.setState({
      currentDate: dh.previousMonth(this.state.currentDate),
    })
  }

  renderHeader() {
    let monthString =
      sh.capitalize(dh.getMonthName(this.state.currentDate, this.props.lang)) +
      ' ' +
      this.state.currentDate.getFullYear()

    return (
      <div className={style.headerContainer}>
        <div className={style.arrowContainer} onClick={this.previousMonth}>
          <div className={style.arrow}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
        </div>

        <div className={style.monthContainer}>
          <div className={style.month}>{monthString}</div>
        </div>

        <div className={style.arrowContainer} onClick={this.nextMonth}>
          <div className={style.arrow}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </div>
    )
  }

  renderCalendar() {
    const { startDate, endDate } = this.props

    let _style = {
      container: {},
    }

    if (!this.state.isOpen) _style.container.height = 0

    let labels = [
      'date.days.monday',
      'date.days.tuesday',
      'date.days.wednesday',
      'date.days.thursday',
      'date.days.friday',
      'date.days.saturday',
      'date.days.sunday',
    ]
    let days = dh.getMonthCalendar(this.state.currentDate)

    return (
      <div className={style.calendarContainer} style={_style.container}>
        <div className={style.calendarLabels}>
          {labels.map((label, key) => {
            return (
              <Text textId={label} substring={[0, 3]} key={key} className={style.textUpperCase} />
            )
          })}
        </div>
        <div className={style.calendarDays}>
          {days.map((day, key) => {
            let classNames = [style.dayPanel]
            if (day.getMonth() !== this.state.currentDate.getMonth()) {
              classNames.push(style.inactive)
            }
            if (dh.isSameDate(day, this.state.selectedDate)) {
              classNames.push(style.selected)
            }
            if (dh.isSameDate(day, new Date())) {
              classNames.push(style.today)
            }
            if (startDate && !m(day).isSameOrAfter(startDate, 'day')) {
              classNames.push(style.disabled)
            }
            if (endDate && m(day).isSameOrAfter(endDate, 'day')) {
              classNames.push(style.disabled)
            }

            return (
              <div
                className={classNames.join(' ')}
                onClick={() => this.handleOnClickDate(day)}
                key={key}
              >
                <div className={style.dayText}>{day.getDate()}</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  render() {
    let classNames = [style.container]
    if (this.props.blueStyle) classNames.push(style.blueStyle)

    return (
      <div className={classNames.join(' ')} ref={this.props.onRef}>
        {this.renderHeader()}
        {this.renderCalendar()}
      </div>
    )
  }
}

MiniCalendar.propTypes = {
  /**
   * Initially selected date (optional)
   */
  initialDate: PropTypes.instanceOf(Date),
  /**
   * Function to run when the date changes
   *
   * @param {Date} date selected date
   */
  onDateChange: PropTypes.func,
  /**
   * User language
   *
   * **This props does not need to be provided**
   */
  lang: PropTypes.string.isRequired,
  /**
   * Determines if blue colors should be used for months and days and set background to white
   * **This props does not need to be provided**
   */
  blueStyle: PropTypes.bool,
  /**
   * Define the format the date should be returned in
   */
  returnDateFormat: PropTypes.string,
  /**
   * On ref
   */
  onRef: PropTypes.func,
  /**
   * Determines if the calender should have a start date (all dates before this date vill not be visible)
   */
  startDate: PropTypes.object,
  /**
   * Determines if the calender should have a end date (all dates after this date will be disabled)
   */
  endDate: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.me.lang,
  }
}

const mapDispatchToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  MiniCalendar
)
