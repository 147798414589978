import moment from 'moment'

export const parseToNumber = (val: string) => {
  if (!val) return 0

  const splitted = val.split('.')
  const result = splitted[0].split(' ').join('') + '.' + splitted[1]

  return result
}

export const getNextPaymentDue = (verificationStatus, payoutFrequency) => {
  const postFix = '07:00 AM CET'

  if (verificationStatus === 'Valid') {
    if (payoutFrequency === 'daily') {
      const today = moment().day()
      // 5-Friday 6-Saturday 0-Sunday
      const setMondayAsPaymentDate = [5, 6, 0]
      let nextDayPayment = ''
      if (setMondayAsPaymentDate.includes(today)) {
        nextDayPayment = moment().add(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')
      } else {
        nextDayPayment = moment().add(1, 'days').format('YYYY-MM-DD')
      }

      return nextDayPayment + ' ' + postFix
    }

    if (payoutFrequency === 'weekly') {
      const nextMondayOfWeek = moment().add(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')
      return nextMondayOfWeek + ' ' + postFix
    }

    if (payoutFrequency === 'monthly') {
      let firstDayOfNextMonth = moment().add(1, 'M').startOf('month')
      // if next payment date is saturday or sunday
      if (firstDayOfNextMonth.day() === 6 || firstDayOfNextMonth.day() === 0) {
        const currentYear = moment().year()
        const currentMonth = moment().month()
        firstDayOfNextMonth = moment()
          .year(currentYear)
          .month(currentMonth + 1)
          .startOf('month')
          .isoWeekday(8)
          .format('YYYY-MM-DD')
      } else {
        firstDayOfNextMonth = firstDayOfNextMonth.format('YYYY-MM-DD')
      }
      return firstDayOfNextMonth + ' ' + postFix
    }
  }

  return null
}

export const getCapitilizeWord = (word: string, makeItPlural: boolean) => {
  word = word.charAt(0).toUpperCase() + word.slice(1)
  if (word && makeItPlural) {
    if (word[word.length - 1] !== 's') return `${word}s`

    return word
  }

  if (word && !makeItPlural) return word

  return ''
}
