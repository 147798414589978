import { z } from 'zod'

export const CreateAccountHolderSchema = z.object({
  name: z
    .string()
    .min(3, { message: 'settings.billing.legalNameMinLength' })
    .refine((value) => value.trim().length > 0, { message: 'settings.billing.legalNameRequired' }),
  email: z.string().email('settings.billing.invalidEmailFormat'),
  country: z.string(),
  accountType: z.string(),
})
