import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { utcToZonedTime, format } from 'date-fns-tz'
import { isAfter } from 'date-fns'

import styles from './styles.module.scss'

import { ReactComponent as ARIcon } from '@sweetspot/sweetspot-js/assets/svgs/gps-icon.svg'
import { ReactComponent as TrashIcon } from '@sweetspot/sweetspot-js/assets/svgs/trash-icon.svg'
import { ReactComponent as DeleteIcon } from '@sweetspot/sweetspot-js/assets/svgs/stop-icon.svg'
import { ReactComponent as ReservedSlot } from '@sweetspot/sweetspot-js/assets/svgs/reserved-slot.svg'
import { ReactComponent as OwnerIcon } from '@sweetspot/sweetspot-js/assets/svgs/owner-slot.svg'
import { ReactComponent as MemberIcon } from '@sweetspot/sweetspot-js/assets/svgs/member-slot.svg'
import { ReactComponent as GuestIcon } from '@sweetspot/sweetspot-js/assets/svgs/guest-slot.svg'
import { ReactComponent as StubIcon } from '@sweetspot/sweetspot-js/assets/svgs/stub-slot.svg'
import { ReactComponent as PartnerIcon } from '@sweetspot/sweetspot-js/assets/svgs/partner-slot.svg'
import { ReactComponent as CaretDown } from '@sweetspot/sweetspot-js/assets/svgs/caret-down.svg'
import { ReactComponent as GolfCartTypeIcon } from '@sweetspot/sweetspot-js/assets/svgs/golf-cart-icon-dark.svg'
import { ReactComponent as EditPen } from '@sweetspot/sweetspot-js/assets/svgs/edit-pen.svg'
import { ReactComponent as WarningIcon } from '@sweetspot/sweetspot-js/assets/svgs/warning-icon.svg'
import { ReactComponent as TicketIcon } from '@sweetspot/sweetspot-js/assets/svgs/ticket-solid.svg'

import Checkbox from '@sweetspot/sweetspot-js/common/components/FormElements/Checkbox'
import Skeleton from '@sweetspot/sweetspot-js/common/components/SkeletonLoader'
import SimpleGrid from '@sweetspot/sweetspot-js/common/components/SimpleGrid'
import RightClickMenu from '@sweetspot/sweetspot-js/common/components/RightClickMenu'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import OnHover from '@sweetspot/sweetspot-js/common/components/OnHover'
import EllipsedText from '@sweetspot/sweetspot-js/common/components/EllipsedText'

import SelectPlayerPopup from '@sweetspot/sweetspot-js/features/players/components/SelectPlayerPopup'
import BookingItemsTable from '@sweetspot/sweetspot-js/features/bookings/components/BookingItemsTable'

import PlayerName from '../PlayerName'

import {
  assignPlayerToSlot,
  assignStubPlayerToSlot,
  setSlotOwner,
  removeSlot,
  updateArrivalRegistration,
  setSlotPartnerType,
  removeSlotPartnerType,
  queryRefunds,
  setGolfCartStatus,
  removeCouponFromOrder,
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'

import { GOLF_CART_STATUSES } from '@sweetspot/sweetspot-js/features/bookings/constants/golfCartStatuses'

import {
  to,
  decimalPercentToNumber,
  getFederationId,
} from '@sweetspot/sweetspot-js/common/functions/utils'
import { priceToLocal } from '@sweetspot/shared/util/functions'

import DateHelpers from '@sweetspot/sweetspot-js/common/functions/DateHelpers'

import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import usePrevious from '@sweetspot/sweetspot-js/common/hooks/usePrevious'

import {
  itemIsAssigned,
  getPlayerName,
  bookingHasOwner,
  isPartner,
  getMembershipName,
  hasAR,
  isStub,
  isMember,
  isOwner,
  isSweetspot,
  getItemGender,
  getItemTotal,
  bookingItemCanBeRemoved,
  isBookingItemPaid,
} from '@sweetspot/sweetspot-js/features/bookings/functions/utils'

import useIsPartnerPortal from '@sweetspot/sweetspot-js/common/hooks/useIsPartnerPortal'
import useIsClubPortal from '@sweetspot/sweetspot-js/common/hooks/useIsClubPortal'
import useStatusArray from '@sweetspot/sweetspot-js/common/hooks/useStatusArray'
import useViolationToasts from '@sweetspot/sweetspot-js/common/hooks/useViolationToasts'
import { removeProductItemFromOrder } from '@sweetspot/sweetspot-js/features/products/services/api-platform'
import useRoles from '@sweetspot/sweetspot-js/common/hooks/useRoles'
import {
  getFirstMatchingRole,
  hasAccess,
} from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { queryPromotionCoupons } from '@sweetspot/sweetspot-js/features/players/services/api-platform'
import { AddSubscriptionSelect } from '@sweetspot/sweetspot-js/features/bookings/components/AddSubscriptionSelect'

const BookingPlayersGrid = ({
  booking,
  tags,
  tagsLoading,
  loading,
  onRequestBookingUpdate,
  onRequestOpenPaymentFold,
  canEdit,
  isCancelled,
  addingExtraSlot,
  addingGolfCart,
  loadingSlots: loadingSlotsProp,
  bookingSlotItems,
  bookingCartItems,
  golfCarts,
  onRemoveCart,
  timezone,
  hasCdhNumber,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const isPartnerPortal = useIsPartnerPortal()
  const isClubPortal = useIsClubPortal()
  const { displayToasts } = useViolationToasts()
  const queryClient = useQueryClient()

  const [addPlayerSlotTarget, setAddPlayerSlotTarget] = useState(null)
  const [showMustChangeOwnerPopup, setShowMustChangeOwnerPopup] = useState(false)
  const [playerToConfirmAssign, setPlayerToConfirmAssign] = useState(null)
  const [bookingViolations, setBookingViolations] = useState([])
  const [tempSlotUuid, setTempSlotUuid] = useState('')
  const prevBooking = usePrevious(booking)
  const prevBookingSlotItems = usePrevious(bookingSlotItems)
  const [bookingRefunds, setBookingRefunds] = useState([])
  const [bookingRefundsLoading, setBookingRefundsLoading] = useState(true)
  const [disableAutomaticAddPlayer, setDisableAutomaticAddPlayer] = useState(false)
  const [bookingOwnerLoading, setbookingOwnerLoading] = useState(false)

  const { toggle: toggleOpenedItem, hasItem: hasOpenItem, add: addOpenedItem } = useStatusArray()
  const [withFocusEndPrice, setWithFocusEndprice] = useState(false)
  const [scrolledSlot, setScrolledSlot] = useState(null)
  const {
    add: addLoadingItem,
    remove: removeLoadingItem,
    reset: resetLoadingItems,
    array: loadingItems,
  } = useStatusArray()

  const fakeInputsRefs = useRef([])

  const toLocal = (price) =>
    typeof price === 'number' ? priceToLocal(price, booking?.currency_code, true) : '-'

  const handleAddPlayerModal = (slot) => {
    if (!addPlayerSlotTarget) {
      setAddPlayerSlotTarget(slot)
      return
    }
    if (slot === 'manual') {
      setDisableAutomaticAddPlayer(true)
    }
    setAddPlayerSlotTarget(null)
  }

  const getGolfCartName = (uuid) => {
    const golfCart = golfCarts?.product.product_variants.filter((el) => el.uuid === uuid)
    return golfCart ? golfCart[0].name : ''
  }

  useEffect(() => {
    if (!isClubPortal) {
      setBookingRefundsLoading(false)
    } else if (booking?.id) {
      const loadRefunds = async () => {
        const [res] = await to(queryRefunds({ order: booking.id }))
        if (res) {
          setBookingRefunds(res)
        }
        setBookingRefundsLoading(false)
      }
      loadRefunds()
    }
  }, [booking, isClubPortal])

  useEffect(() => {
    if (bookingSlotItems?.length > prevBooking?.items?.length) {
      setDisableAutomaticAddPlayer(false)
    }
  }, [bookingSlotItems, prevBookingSlotItems])

  useEffect(() => {
    if (isCancelled) return
    if (disableAutomaticAddPlayer) return

    let currentSlots =
      bookingSlotItems
        ?.map(({ slot }) => slot)
        .filter((slot) => !!slot && !itemIsAssigned(slot) && !loadingItems.includes(slot.uuid)) ||
      []
    const currentSlotsLength = currentSlots?.length

    for (let index = 0; index < currentSlotsLength; index++) {
      const slot = currentSlots[index]
      const slotRef = fakeInputsRefs?.current?.[slot.id]
      setAddPlayerSlotTarget(slot)

      if (slotRef && scrolledSlot !== slot.id) {
        slotRef.scrollIntoView()
        setScrolledSlot(slot.id)
      }
      break
    }
  }, [
    prevBookingSlotItems,
    bookingSlotItems,
    disableAutomaticAddPlayer,
    loadingItems,
    scrolledSlot,
    isCancelled,
  ])

  const roles = useRoles()
  const accessTable = useMemo(() => {
    const role = getFirstMatchingRole(roles)
    if (!role)
      return {
        PRICE_ADJUSTMENT: false,
      }
    return {
      PRICE_ADJUSTMENT: hasAccess(ACCESS_KEYS.FEATURES.BOOKING.EDIT.PRICE_ADJUSTMENT, role?.value),
    }
  }, [roles])

  const getGitHomeclubName = (item) => {
    return item?.slot?.player?.federation_player_profiles?.[0]?.git_home_club?.name || '-'
  }

  const handleAssignPlayerToSlot = async (slotUuid, playerObject) => {
    const { player, isOwner, source } = playerObject
    addLoadingItem(slotUuid)

    let isOnMute = booking.muteNotification
    if (typeof isOnMute !== 'boolean') {
      isOnMute = DateHelpers.isPastBooking(new Date(booking.booking.start_time))
    }
    const shouldOverrideViolations = !isPartnerPortal ? 1 : null
    const [res, err] = await to(
      assignPlayerToSlot(slotUuid, player.uuid, true, isOnMute, shouldOverrideViolations)
    )
    if (err || !res) {
      if (err?.violations) {
        displayToasts(err?.violations)
      } else addToast(t('sentences.couldNotAssignPlayerToSlot'), { appearance: 'error' })
      removeLoadingItem(slotUuid)
      return
    }

    if (
      (source === 'copy' && isOwner && !bookingHasOwner(booking)) ||
      (source !== 'copy' && !bookingHasOwner(booking))
    ) {
      const [resOwner, errOwner] = await to(setSlotOwner(slotUuid))
      if (errOwner || !resOwner) {
        removeLoadingItem(slotUuid)
        addToast(t('sentences.couldNotAssignPlayerToSlot'), { appearance: 'error' })
        return
      }
    }
    onRequestBookingUpdate(booking.uuid).then(() => {
      removeLoadingItem(slotUuid)
    })
  }

  const handleAssignPlayer = async (slot, playerObject, updateBooking = true) => {
    const { type, player, isOwner, source } = playerObject
    const { uuid: slotUuid } = slot

    setDisableAutomaticAddPlayer(false)

    addLoadingItem(slotUuid)

    if (type === 'player') {
      let isOnMute = booking.muteNotification
      if (typeof isOnMute !== 'boolean') {
        isOnMute = DateHelpers.isPastBooking(new Date(booking.booking.start_time))
      }
      const [res, err] = await to(assignPlayerToSlot(slotUuid, player.uuid, false, isOnMute))
      if (err || !res) {
        if (err?.violations) {
          if (!isPartnerPortal) {
            const overridableViolations = err?.violations.filter((x) => x?.is_overridable)
            if (overridableViolations.length) {
              setPlayerToConfirmAssign(playerObject)
            }
            setBookingViolations(overridableViolations)
            setTempSlotUuid(slotUuid)
            displayToasts(err?.violations.filter((x) => !x?.is_overridable))
            removeLoadingItem(slotUuid)
          } else displayToasts(err?.violations)
        } else if (err?.detail === 'Player has reached a limit of simultaneous bookings') {
          setPlayerToConfirmAssign(playerObject)
          setTempSlotUuid(slotUuid)
          return
        } else if (err?.detail === 'Player has already been added to a tee time') {
          addToast(
            t('sentences.thisPlayerIsAlreadyInABookingInThisTeeTime', {
              name: getPlayerName(player),
            }),
            {
              appearance: 'error',
            }
          )
        } else {
          addToast(t('sentences.couldNotAssignPlayerToSlot'), { appearance: 'error' })
        }
        removeLoadingItem(slotUuid)
        return
      }
    } else if (type === 'stub') {
      addLoadingItem(slotUuid)

      const name = player.name
      const email = player.email
      const phone = player.phone
      let isOnMute = booking.muteNotification
      if (typeof isOnMute !== 'boolean') {
        isOnMute = DateHelpers.isPastBooking(new Date(booking.booking.start_time))
      }
      const [res, err] = await to(
        assignStubPlayerToSlot(slotUuid, { name, email, phone }, isOnMute)
      )
      if (err || !res) {
        addToast(t('sentences.couldNotAssignPlayerToSlot'), { appearance: 'error' })
        removeLoadingItem(slotUuid)
        return
      }
    }

    if (
      !bookingOwnerLoading &&
      ((source === 'copy' && isOwner && !bookingHasOwner(booking)) ||
        (source !== 'copy' && !bookingHasOwner(booking)))
    ) {
      setbookingOwnerLoading(true)
      const [resOwner, errOwner] = await to(setSlotOwner(slotUuid))
      setbookingOwnerLoading(false)
      if (errOwner || !resOwner) {
        addToast(t('sentences.couldNotAssignPlayerToSlot'), { appearance: 'error' })
        removeLoadingItem(slotUuid)
        return
      }
    }
    if (updateBooking) {
      onRequestBookingUpdate(booking.uuid).then(() => {
        removeLoadingItem(slotUuid)
      })
    }
  }

  const handleCloseConfirmAssign = () => {
    removeLoadingItem(tempSlotUuid)
    setPlayerToConfirmAssign(null)
    setTempSlotUuid('')
    setBookingViolations([])
  }

  const handleRegisterAr = async (slot, value, skipBookingUpdate = false) => {
    const { uuid } = slot
    addLoadingItem(uuid)

    if (uuid) {
      const [res, err] = await to(updateArrivalRegistration(uuid, value))
      if (!res || err) {
        addToast(t('sentences.couldNotRegisterArrival'), { appearance: 'error' })
      }
      if (skipBookingUpdate) {
        return
      }
      onRequestBookingUpdate(booking.uuid).then(() => {
        removeLoadingItem(uuid)
      })
    }
  }

  const handleRegisterArAll = async () => {
    let arrivalRegistrationStatus = true

    if (
      bookingSlotItems.filter(({ slot }) => hasAR(slot)).length >
      bookingSlotItems.filter(({ slot }) => !hasAR(slot)).length
    ) {
      // If more are arrival registered than not arrived then unregister arrival on all
      arrivalRegistrationStatus = false
    }

    Promise.all(
      bookingSlotItems.map(({ slot }) => {
        if (itemIsAssigned(slot)) return handleRegisterAr(slot, arrivalRegistrationStatus, true)
        return true
      })
    ).then(() => {
      onRequestBookingUpdate(booking.uuid).then(() => {
        resetLoadingItems()
      })
    })
  }

  const handleOnDeleteCart = async (item) => {
    const { uuid, id } = item

    addLoadingItem(uuid)

    const [res, err] = await to(removeProductItemFromOrder(booking.uuid, { order_item: id }))

    if (!res || err) {
      removeLoadingItem(uuid)
      addToast(t('sentences.couldNotRemoveGolfCart'), { appearance: 'error' })
      return
    }

    onRequestBookingUpdate(booking.uuid).then(() => {
      onRemoveCart(item)
      removeLoadingItem(uuid)
    })
  }

  const handleOnDeleteVoucher = async ({ couponCode, uuid }) => {
    addLoadingItem(uuid)

    const [res, err] = await to(removeCouponFromOrder(booking.uuid, couponCode))

    if (!res || err) {
      removeLoadingItem(uuid)
      addToast(t('sentences.couldNotRemoveVoucher'), { appearance: 'error' })
      return
    }

    queryClient.invalidateQueries(CLUB_QUERIES.PLAYER_VOUCHERS)
    onRequestBookingUpdate(booking.uuid).then(() => {
      removeLoadingItem(uuid)
    })
  }

  const handleOnDeleteSlot = async (slot) => {
    const { uuid } = slot

    if (isOwner(slot)) {
      setShowMustChangeOwnerPopup(true)
      return
    }

    if (bookingSlotItems?.length === 1) {
      addToast(t('sentences.canNotDeleteLastSlotPleaseCancelBooking'), { appearance: 'warning' })
      return
    }
    addLoadingItem(uuid)
    if (uuid) {
      let isOnMute = booking.muteNotification
      if (typeof isOnMute !== 'boolean') {
        isOnMute = DateHelpers.isPastBooking(new Date(booking.booking.start_time))
      }
      const [res, err] = await to(removeSlot(uuid, isOnMute))
      if (!res || err) {
        addToast(t('sentences.couldNotRemoveSlot'), { appearance: 'error' })
      }
      onRequestBookingUpdate(booking.uuid, true)
    }
  }

  const changeBookingOwnerTo = async (slot) => {
    const { uuid: slotUuid } = slot

    addLoadingItem(slotUuid)

    const [resOwner, errOwner] = await to(setSlotOwner(slotUuid))
    if (errOwner || !resOwner) {
      addToast(t('sentences.couldNotChangeBookingOwner'), { appearance: 'error' })
      removeLoadingItem(slotUuid)
      return
    }

    onRequestBookingUpdate(booking.uuid).then(() => {
      removeLoadingItem(slotUuid)
    })
  }

  const getCurrentGolfCartValue = (slot) => {
    if (slot?.golf_cart_status) {
      return getGolfCartDropdownOptions(slot).find((x) => x.value === slot.golf_cart_status).label
    }
    return t('words.none')
  }
  const getGolfCartDropdownOptions = (slot) => {
    const options = [
      {
        id: 0,
        label: t('words.none'),
        value: 'none',
      },
      {
        id: 1,
        label: t('words.request'),
        value: 'requested',
      },
      {
        id: 2,
        label: t('words.paid'),
        value: 'paid',
      },
      {
        id: 3,
        label: t('words.confirm'),
        value: 'confirmed',
      },
      {
        id: 4,
        label: t('words.deny'),
        value: 'denied',
      },
    ]
    return options.map((o) => ({
      ...o,
      selected: slot?.golf_cart_status === o.value,
    }))
  }
  const onSelectGolfCart = async (item, slot) => {
    let status

    switch (item.id) {
      case 1:
        status = GOLF_CART_STATUSES.REQUESTED
        break
      case 2:
        status = GOLF_CART_STATUSES.PAID
        break
      case 3:
        status = GOLF_CART_STATUSES.CONFIRMED
        break
      case 4:
        status = GOLF_CART_STATUSES.DENIED
        break
      case 0:
        status = GOLF_CART_STATUSES.NONE
        break
      default:
        break
    }

    addLoadingItem(slot.uuid)

    const [res, err] = await to(setGolfCartStatus(slot.uuid, status))

    if (!res || err) {
      addToast(t('sentences.couldNotUpdateGolfCartStatus'), { appearance: 'error' })
    }
    onRequestBookingUpdate(booking.uuid).then(() => {
      removeLoadingItem(slot.uuid)
    })
  }

  const getTagDropdownOptions = (slot) => {
    if (tags) {
      let items = []
      if (slot?.partner_type) {
        items.push({
          id: 0,
          label: t('words.none'),
        })
      }
      return [
        ...items,
        ...(tags?.map
          ? tags.map((p) => ({
              id: p.id,
              uuid: p.uuid,
              label: p.name,
              selected: getCurrentTagValue(slot)?.id === p.id,
            }))
          : []),
      ]
    } else {
      return [
        {
          id: 0,
          label: t('sentences.noPartnersFound'),
        },
      ]
    }
  }
  const getCurrentTagValue = (slot) => {
    if (slot?.partner_type) {
      return {
        id: slot.partner_type.id,
        label: tags?.find((x) => x.id === slot.partner_type.id).name,
      }
    }
    return {
      id: 0,
      label: t('words.none'),
    }
  }
  const onSelectTag = async (item, slot) => {
    addLoadingItem(slot.uuid)

    let res, err

    if (item.id === 0) {
      ;[res, err] = await to(removeSlotPartnerType(slot.uuid))
    } else {
      ;[res, err] = await to(setSlotPartnerType(slot.uuid, item.uuid))
    }

    if (!res || err) {
      addToast(t('sentences.couldNotAssignPartner'), { appearance: 'error' })
    }

    onRequestBookingUpdate(booking.uuid).then(() => {
      removeLoadingItem(slot.uuid)
    })
  }

  const renderCartHeader = (cartItems) => {
    if (!cartItems || !cartItems.length) return
    return (
      <div className={'row-sub-header'}>
        <p className={cx(styles.product)}>{t('words.product')}</p>
        <p className={cx(styles.time)}>{t('words.from')}</p>
        <p className={cx(styles.time)}>{t('words.to')}</p>
      </div>
    )
  }

  const renderVoucherHeader = () => {
    return (
      <div className={'row-sub-header'}>
        <p className={cx(styles.voucherNameHeader)}>{t('vouchers.label_VoucherName')}</p>
        <p className={cx(styles.code)}>{t('vouchers.label_Code')}</p>
        <p className={cx(styles.code)}>{t('vouchers.label_Discount')}</p>
      </div>
    )
  }

  const { data: assignedPromotionCoupons, isFetching: isQueryAssignedVouchersFetching } = useQuery(
    ['assignedPromotionCoupons', booking],
    () => queryPromotionCoupons({ 'orders.uuid': booking?.uuid }),
    {
      enabled: !!booking?.uuid,
    }
  )

  const renderVouchers = (promotion_coupons) => {
    if (!promotion_coupons || !promotion_coupons.length || isQueryAssignedVouchersFetching)
      return null
    const adjustments = booking.items.reduce(
      (store, { adjustments }) => [...store, ...adjustments],
      []
    )

    const updatedPromotionCoupons = promotion_coupons.map((promotion_coupon) => {
      let discount = null
      let totalAmount = 0

      adjustments
        .filter(
          (adjustment) => adjustment.label?.split(' ')[0] === promotion_coupon.promotion?.name
        )
        .some((adjustment) => {
          const percentage_coefficient =
            adjustment?.details?.configuration?.percentage_with_uses?.[0]?.percentage_coefficient ||
            adjustment?.details?.configuration?.percentage_with_discounted_value?.[0]
              ?.percentage_coefficient
          if (adjustment?.type === 'order_item_new_price_adjustment') {
            return true
          } else if (percentage_coefficient >= 0) {
            discount = `-${decimalPercentToNumber(percentage_coefficient)}%`
            return true
          } else if (adjustment?.amount) {
            totalAmount += adjustment.amount
            discount = toLocal(totalAmount)
          }
          return false
        })
      return {
        discount,
        ...promotion_coupon,
      }
    })

    return [
      renderVoucherHeader(),
      updatedPromotionCoupons.map((promotion_coupon) => {
        const { uuid, code: couponCode } = promotion_coupon
        if (loadingItems.includes(uuid) || loadingSlotsProp.includes(uuid)) {
          return (
            <div className={cx(styles.loaderRow, 'row-body')} key={uuid}>
              <Skeleton width="100%" height="25px" />
            </div>
          )
        }
        return (
          <div key={uuid} className={cx('row-body', styles.row)}>
            <div className={cx(styles.coupon, 'center')}>
              <TicketIcon className={cx(styles.ticketIcon)} />
            </div>
            <p className={cx(styles.voucherNameCell)}>{promotion_coupon.promotion?.name}</p>
            <p className={cx(styles.code)}>{couponCode}</p>
            <p className={cx(styles.code)}>{promotion_coupon.discount}</p>
            <div className={cx(styles.removeContainer, 'fixed-right')}>
              <div className={cx(styles.removeWrapper)}>
                {bookingItemCanBeRemoved(booking, promotion_coupon) && (
                  <DeleteIcon
                    className={cx(styles.deleteIcon)}
                    onClick={() => handleOnDeleteVoucher({ couponCode, uuid })}
                  />
                )}
              </div>
            </div>
          </div>
        )
      }),
    ]
  }

  if (!booking || loading) {
    return (
      <SimpleGrid>
        <div style={{ height: '200px', backgroundColor: '#FFFFFF' }}>
          <Skeleton width="100%" height="170px" />
        </div>
      </SimpleGrid>
    )
  }

  return (
    <React.Fragment>
      <SimpleGrid
        wrapperClassName={styles.gridContainer}
        headerClassName={cx(styles.gridHeader, isCancelled && styles.isCancelled)}
        key={booking.id}
        rowFolds={[
          ...bookingSlotItems.map((item) => {
            if (!itemIsAssigned(item)) return null

            const slot = item?.slot
            const { player } = slot
            const playerIsStub = isStub(slot)
            const isOpen = hasOpenItem(item.uuid)

            return (
              <div
                key={slot?.id}
                className={cx(styles.rowFold, {
                  [styles.open]: isOpen,
                })}
              >
                <div className={cx(styles.playerInformationContainer)}>
                  <p className={styles.text}>
                    {t('words.email')}:{' '}
                    <span className={styles.value}>
                      {playerIsStub ? slot?.stub_player?.email || '-' : player?.email || '-'}
                    </span>
                  </p>
                  <p className={styles.text}>
                    {t('words.homeClub')}:{' '}
                    <span className={cx(styles.value)}>{player?.home_club?.name || '-'}</span>
                  </p>
                  <p className={cx(styles.text)}>
                    {t('words.membership_one')}:{' '}
                    <span className={styles.value}>{getMembershipName(item)}</span>
                  </p>
                  <p className={cx(styles.text)}>
                    {t('words.gender')}: <span className={styles.value}>{getItemGender(item)}</span>
                  </p>
                </div>
                <BookingItemsTable
                  isCancelled={isCancelled}
                  className={cx(styles.adjustments)}
                  booking={booking}
                  item={item}
                  canEdit={canEdit}
                  onRequestBookingUpdate={onRequestBookingUpdate}
                  focusEndPrice={isOpen && withFocusEndPrice}
                  onRequestOpenPaymentFold={onRequestOpenPaymentFold}
                  refunds={bookingRefunds}
                  refundsLoading={bookingRefundsLoading}
                />
              </div>
            )
          }),
          ...bookingCartItems.map((item) => {
            const isOpen = hasOpenItem(item.uuid)
            return (
              <div
                key={item?.id}
                className={cx(styles.rowFold, {
                  [styles.open]: isOpen,
                })}
              >
                <div className={cx(styles.playerInformationContainer)}></div>
                <BookingItemsTable
                  sCancelled={isCancelled}
                  className={cx(styles.adjustments)}
                  booking={booking}
                  item={item}
                  canEdit={canEdit}
                  onRequestBookingUpdate={onRequestBookingUpdate}
                  focusEndPrice={isOpen && withFocusEndPrice}
                  onRequestOpenPaymentFold={onRequestOpenPaymentFold}
                  refunds={bookingRefunds}
                  refundsLoading={bookingRefundsLoading}
                />
              </div>
            )
          }),
        ]}
      >
        <div className={'row-header'}>
          <p className={cx(styles.toggle, 'fixed-left')}></p>
          <p className={cx(styles.name)}>{t('words.name')}</p>
          <p className={cx(styles.type)}>{t('words.type')}</p>
          {!isPartnerPortal && (
            <div className={cx(styles.arWrapper)}>
              <div className={cx(styles.arContainer, isCancelled && styles.isCancelled)}>
                <ARIcon
                  className={cx(styles.arIcon, isCancelled && styles.isCancelled)}
                  onClick={() => !isCancelled && handleRegisterArAll()}
                />
              </div>
            </div>
          )}
          <p className={cx(styles.hcp)}>{t('words.hcp')}</p>
          <p className={cx(styles.golfId)}>
            {hasCdhNumber ? t('words.cdhNumber') : t('words.golfId')}
          </p>
          <p className={cx(styles.phone)}>{t('words.phone')}</p>
          <p className={cx(styles.homeClub)}>{t('words.homeClub')}</p>
          {!isPartnerPortal && (
            <p className={cx(styles.membershipsWrapper)}>{t('membershipPassText')}</p>
          )}
          {!isPartnerPortal && (
            <p className={cx(styles.golfCartWrapper)}>{t('words.golfCart_one')}</p>
          )}
          {!isPartnerPortal && <p className={cx(styles.partnerTypeWrapper)}>{t('words.tag')}</p>}
          <p className={cx(styles.gitHomeClub)}>
            {hasCdhNumber ? t('words.cdhHomeClub') : t('words.gitHomeClub')}
          </p>
          <p className={cx(styles.price, 'fixed-right')}>{t('words.price')}</p>
          <div className={cx(styles.removeContainer, 'fixed-right')}>
            <div className={cx(styles.removeWrapper)}>
              {!isCancelled && <TrashIcon className={cx(styles.removeIcon)} />}
            </div>
          </div>
        </div>
        {[
          ...bookingSlotItems.map((item) => {
            const slot = item?.slot
            if (!slot) return null
            const { player } = slot

            const itemPrice = getItemTotal(item, bookingRefunds)

            if (loadingItems.includes(slot.uuid) || loadingSlotsProp.includes(slot.uuid)) {
              return (
                <div className={cx(styles.loaderRow, 'row-body')} key={slot.id}>
                  <Skeleton width="100%" height="25px" />
                </div>
              )
            }

            if (!itemIsAssigned(slot)) {
              return (
                <div key={slot.id} className={cx('row-body', styles.row)}>
                  <div className={cx(styles.toggle, 'fixed-left')}></div>
                  <div className={cx(styles.name)}>
                    <p
                      ref={(ref) => (fakeInputsRefs.current[slot.id] = ref)}
                      onClick={() => !isCancelled && handleAddPlayerModal(slot)}
                      className={cx(
                        styles.addPlayerFakeInput,
                        {
                          [styles.active]: addPlayerSlotTarget?.id === slot.id,
                        },
                        isCancelled && styles.isCancelled
                      )}
                    >
                      {t('words.reserved_one')}
                    </p>
                  </div>

                  <div className={cx(styles.type)}>
                    <ReservedSlot className={cx(styles.typeIcon)} />
                  </div>
                  {!isPartnerPortal && <div className={cx(styles.arWrapper)}></div>}
                  <div className={cx(styles.hcp)}></div>
                  <div className={cx(styles.golfId)}></div>
                  <div className={cx(styles.phone)}></div>
                  <div className={cx(styles.homeClub)}></div>
                  {!isPartnerPortal && <div className={cx(styles.membershipsWrapper)}></div>}
                  {!isPartnerPortal && <div className={cx(styles.golfCartWrapper)}></div>}
                  {!isPartnerPortal && <div className={cx(styles.partnerTypeWrapper)}></div>}
                  <div className={cx(styles.gitHomeClub)}></div>
                  <p className={cx(styles.price, 'fixed-right')}>{toLocal(itemPrice)}</p>
                  <div className={cx(styles.removeContainer, 'fixed-right')}>
                    <div className={cx(styles.removeWrapper)}>
                      {bookingItemCanBeRemoved(booking, item) && (
                        <DeleteIcon
                          className={cx(styles.deleteIcon)}
                          onClick={() => handleOnDeleteSlot(slot)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )
            }

            const playerIsStub = isStub(slot)
            const playerIsMember = isMember(slot)
            const playerIsOwner = isOwner(slot)
            const playerIsSweetspot = isSweetspot(slot)
            const playerIsPartner = isPartner(booking, slot)
            return (
              <div
                id={`bookingPlayersGrid-${slot.id}`}
                key={slot.id}
                className={cx('row-body', styles.row)}
              >
                {canEdit && (
                  <RightClickMenu
                    elementQuery={`#bookingPlayersGrid-${slot.id}`}
                    options={[
                      {
                        label: t('sentences.makePlayerBookingOwner'),
                        onClick: () => changeBookingOwnerTo(slot),
                        disabled: playerIsOwner,
                      },
                      {
                        label: t('sentences.replacePlayer'),
                        onClick: () => handleAddPlayerModal(slot),
                        disabled: !canEdit || playerIsOwner,
                      },
                    ]}
                  />
                )}
                <div
                  className={cx(styles.toggle, 'fixed-left')}
                  onClick={() => {
                    toggleOpenedItem(item.uuid)
                    setWithFocusEndprice(false)
                  }}
                >
                  <CaretDown
                    className={cx(styles.icon, {
                      [styles.open]: hasOpenItem(item.uuid),
                    })}
                  />
                </div>

                <div className={cx(styles.name)}>
                  <div
                    className={cx(styles.infoText)}
                    ref={(ref) => (fakeInputsRefs.current[slot.id] = ref)}
                  >
                    <PlayerName slot={slot} />
                  </div>
                </div>

                <div className={cx(styles.type)}>
                  {playerIsOwner && <OwnerIcon className={cx(styles.typeIcon)} />}
                  <div className={`${playerIsOwner ? '' : 'ml-[30px]'} flex items-center`}>
                    {playerIsPartner ? (
                      <PartnerIcon className={cx(styles.typeIcon)} />
                    ) : (
                      <React.Fragment>
                        {!playerIsStub &&
                          (playerIsMember ? (
                            <OnHover
                              text={getMembershipName(slot)}
                              className={cx(styles.onHoverContainer)}
                            >
                              <MemberIcon className={cx(styles.typeIcon)} />
                            </OnHover>
                          ) : (
                            <GuestIcon className={cx(styles.typeIcon)} />
                          ))}
                      </React.Fragment>
                    )}

                    {playerIsStub && !playerIsPartner && (
                      <StubIcon className={cx(styles.typeIcon)} />
                    )}
                    {playerIsSweetspot && (
                      <i className="fa-kit fa-ss-icon-solid mr-[10px] text-xl" />
                    )}
                  </div>
                </div>
                {!isPartnerPortal && (
                  <div className={cx(styles.arWrapper)}>
                    <Checkbox
                      containerClassName={cx(styles.inputWrapperClassName)}
                      value={hasAR(slot)}
                      disabled={isCancelled}
                      onChange={(value) => handleRegisterAr(slot, value)}
                    />
                  </div>
                )}
                <div className={cx(styles.hcp)}>
                  <p className={cx(styles.infoText)}>{player?.hcp || '-'}</p>
                </div>
                <div className={cx(styles.golfId)}>
                  <p className={cx(styles.infoText)}>{getFederationId(hasCdhNumber, player)}</p>
                </div>
                <div className={cx(styles.phone)}>
                  <p className={cx(styles.infoText)}>
                    {playerIsStub ? slot?.stub_player?.phone || '-' : player?.phone || '-'}
                  </p>
                </div>
                <div className={cx(styles.homeClub)}>
                  <div className={cx(styles.infoText)}>
                    <EllipsedText
                      id="booking-modal-ellipsed-text"
                      text={player?.home_club?.name || '-'}
                    />
                  </div>
                </div>
                {!isPartnerPortal && playerIsOwner && (
                  <div className={cx(styles.membershipsWrapper, 'relative')}>
                    <AddSubscriptionSelect
                      booking={booking}
                      onRequestBookingUpdate={onRequestBookingUpdate}
                      loading={
                        loadingItems.includes(slot.uuid) || loadingSlotsProp.includes(slot.uuid)
                      }
                      slot={slot}
                      removeLoadingItem={removeLoadingItem}
                      addLoadingItem={addLoadingItem}
                    />
                  </div>
                )}
                {!playerIsOwner && <div className={cx(styles.membershipsWrapper)} />}
                {!isPartnerPortal && (
                  <div className={cx(styles.golfCartWrapper)}>
                    <Button
                      disabled={isCancelled}
                      className={cx(styles.dropdown)}
                      size="default"
                      theme="default-outline"
                      dropdownStyle="simple"
                      dropdownValue={getCurrentGolfCartValue(slot)}
                      dropdownOptions={getGolfCartDropdownOptions(slot)}
                      dropdownOptionOnClick={(item) => onSelectGolfCart(item, slot)}
                    />
                  </div>
                )}
                {!isPartnerPortal && (
                  <div className={cx(styles.partnerTypeWrapper)}>
                    <Button
                      className={cx(styles.dropdown)}
                      size="default"
                      theme="gray-outline"
                      dropdownStyle="simple"
                      dropdownValue={getCurrentTagValue(slot).label}
                      dropdownOptions={getTagDropdownOptions(slot)}
                      dropdownOptionOnClick={(item) => onSelectTag(item, slot)}
                      // disabled={!canEdit}
                      loading={tagsLoading}
                      loaderStyle="pulse"
                    />
                  </div>
                )}
                <div className={cx(styles.gitHomeClub)}>
                  <div className={styles.infoText}>
                    <EllipsedText
                      id="booking-modal-ellipsed-text"
                      text={getGitHomeclubName(item)}
                    />
                  </div>
                </div>
                <p
                  className={cx(styles.price, styles.withToggle, 'fixed-right')}
                  onClick={() => {
                    toggleOpenedItem(item.uuid)
                    setWithFocusEndprice(false)
                  }}
                >
                  {isClubPortal &&
                    accessTable.PRICE_ADJUSTMENT &&
                    canEdit &&
                    !isBookingItemPaid(item) && (
                      <EditPen
                        onClick={(e) => {
                          e.stopPropagation()
                          addOpenedItem(item.uuid)
                          setWithFocusEndprice(true)
                        }}
                        className={cx(styles.editPen)}
                      />
                    )}{' '}
                  {toLocal(itemPrice)}
                </p>
                <div className={cx(styles.removeContainer, 'fixed-right')}>
                  <div className={cx(styles.removeWrapper)}>
                    {bookingItemCanBeRemoved(booking, item) && (
                      <DeleteIcon
                        className={cx(styles.deleteIcon, { [styles.disabled]: playerIsOwner })}
                        onClick={() => (!playerIsOwner ? handleOnDeleteSlot(slot) : () => {})}
                      />
                    )}
                  </div>
                </div>
              </div>
            )
          }),
          addingExtraSlot && (
            <div key="loading-extra-slot" className={cx(styles.loaderRow, 'row-body')}>
              <Skeleton width="100%" height="25px" />
            </div>
          ),
          renderCartHeader(bookingCartItems),
          ...bookingCartItems.map((item) => {
            const itemPrice = getItemTotal(item, bookingRefunds)
            const isOpen = hasOpenItem(item.uuid)

            const fromTimeConverted = item.hold_from
              ? format(utcToZonedTime(new Date(item.hold_from), timezone), 'HH:mm')
              : ''
            let returnTimeConverted = ''
            if (item.hold_to) {
              const returnZonedTime = utcToZonedTime(new Date(item.hold_to), timezone)

              if (isAfter(item.hold_to, item.hold_from)) {
                returnTimeConverted = `${DateHelpers.toDateMonthShorten(
                  returnZonedTime
                )} ${returnZonedTime}`
              } else {
                returnTimeConverted = format(returnZonedTime, 'HH:mm')
              }
            }

            if (loadingItems.includes(item.uuid) || loadingSlotsProp.includes(item.uuid)) {
              return (
                <div className={cx(styles.loaderRow, 'row-body')} key={item.id}>
                  <Skeleton width="100%" height="25px" />
                </div>
              )
            }
            return (
              <div key={item.id} className={cx('row-body', styles.row)}>
                <div
                  className={cx(styles.toggle, 'fixed-left')}
                  onClick={() => toggleOpenedItem(item.uuid)}
                >
                  <CaretDown
                    className={cx(styles.icon, {
                      [styles.open]: isOpen,
                    })}
                  />
                </div>
                <div className={cx(styles['name'])}>
                  <GolfCartTypeIcon />
                  <p className={cx(styles['infoText'])}>
                    {getGolfCartName(item.product_variant.uuid)}
                  </p>
                </div>
                <div className={cx(styles.cartTime)}>
                  <p className={cx(styles.infoText)}>{fromTimeConverted || ''}</p>
                </div>
                <div className={cx(styles.cartTime)}>
                  <p className={cx(styles.infoText)}>{returnTimeConverted || ''}</p>
                </div>
                <p
                  className={cx(styles.price, styles.withToggle, 'fixed-right')}
                  onClick={() => toggleOpenedItem(item.uuid)}
                >
                  {toLocal(itemPrice)}
                </p>
                <div className={cx(styles.removeContainer, 'fixed-right')}>
                  <div className={cx(styles.removeWrapper)}>
                    {bookingItemCanBeRemoved(booking, item) && (
                      <DeleteIcon
                        className={cx(styles.deleteIcon)}
                        onClick={() => handleOnDeleteCart(item)}
                      />
                    )}
                  </div>
                </div>
              </div>
            )
          }),
          addingGolfCart && (
            <div key="loading-extra-slot" className={cx(styles.loaderRow, 'row-body')}>
              <Skeleton width="100%" height="25px" />
            </div>
          ),
          renderVouchers(assignedPromotionCoupons),
        ]
          .filter((el) => el && el)
          .map((el) => el)}
      </SimpleGrid>
      <ConfirmPopup
        visible={showMustChangeOwnerPopup}
        title={t('sentences.canNotRemoveBookingOwner')}
        text={
          t('sentences.youMustChangeBookingOwnerBeforeRemovingSlot') +
          ' (' +
          t('sentences.rightClickOnPlayerToChangeOwner') +
          ')'
        }
        onAccept={() => setShowMustChangeOwnerPopup(false)}
        onClose={null}
        acceptTheme="default"
        acceptText={t('words.ok')}
      />
      {playerToConfirmAssign && isPartnerPortal && (
        <ConfirmPopup
          visible={!!playerToConfirmAssign}
          title={t('words.confirm')}
          text={t('sentences.thisPlayerHasReachedMaximumSimultaneousBookings', {
            name: getPlayerName(playerToConfirmAssign?.player),
          })}
          acceptTheme="default"
          rejectText={t('words.cancel')}
          acceptText={t('words.confirm')}
          onAccept={() => {
            handleAssignPlayerToSlot(tempSlotUuid, playerToConfirmAssign)
            setPlayerToConfirmAssign(null)
            setTempSlotUuid('')
          }}
          onReject={() => handleCloseConfirmAssign()}
          onClose={() => handleCloseConfirmAssign()}
        />
      )}
      {!!addPlayerSlotTarget && (
        <SelectPlayerPopup
          hasCdhNumber={hasCdhNumber}
          club={booking?.club}
          attachToElementRef={fakeInputsRefs?.current[addPlayerSlotTarget?.id]}
          context={isPartnerPortal ? 'PARTNER_BOOKING' : 'BOOKING'}
          visible={!playerToConfirmAssign && !bookingViolations.length}
          isShowAdditionalFields={true}
          availableTabs={isPartnerPortal ? ['all', 'manual'] : ['all', 'members', 'manual']}
          onSelectPlayer={(player) => handleAssignPlayer(addPlayerSlotTarget, player)}
          onRequestClose={handleAddPlayerModal}
        />
      )}
      <ConfirmPopup
        visible={playerToConfirmAssign && bookingViolations.length && !isPartnerPortal}
        title={t('sentences.confirmPlayer', {
          name: getPlayerName(playerToConfirmAssign?.player) || '',
        })}
        text={t('sentences.youHaveUnconfirmedBookingsThatWillCancel', {
          count: 1,
        })}
        titleIcon={WarningIcon}
        overrideIconColor={false}
        acceptText={t('words.confirm')}
        acceptTheme="default"
        rejectText={t('words.cancel')}
        rejectTheme="none"
        onAccept={() => {
          setBookingViolations([])
          handleAssignPlayerToSlot(tempSlotUuid, playerToConfirmAssign)
          setPlayerToConfirmAssign(null)
          setTempSlotUuid('')
        }}
        onReject={() => handleCloseConfirmAssign()}
        onClose={() => handleCloseConfirmAssign()}
      >
        <div className={cx(styles.confirmOverrideContainer)}>
          <p className={cx(styles.membershipName)}>
            {bookingViolations ? bookingViolations[0]?.membership_name : ''}
          </p>
          {bookingViolations.map((violation) => (
            <div key={violation.code} className={cx(styles.policyContainer)}>
              <span className={cx(styles.policyName)}>{violation?.reservation_policy_name}</span>
              <br />
              <ul>
                <li className={cx(styles.violation)}>
                  <span>{violation?.message || ''}</span>
                </li>
              </ul>
            </div>
          ))}
          <p className={cx(styles.confirmOverride)}>{t('sentences.confirmToOverride')}</p>
        </div>
      </ConfirmPopup>
    </React.Fragment>
  )
}

BookingPlayersGrid.propTypes = {
  isCancelled: PropTypes.bool,
  booking: PropTypes.shape({
    id: PropTypes.number.isRequired,
    uuid: PropTypes.string.isRequired,
    booking: PropTypes.object,
    muteNotification: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        slot: PropTypes.shape({
          player: PropTypes.shape({
            id: PropTypes.number,
          }),
        }),
      })
    ),
  }),
  bookingCartItems: PropTypes.array,
  bookingSlotItems: PropTypes.array,
  addingExtraSlot: PropTypes.bool,
  addingGolfCart: PropTypes.bool,
  tags: PropTypes.array,
  tagsLoading: PropTypes.bool,
  loading: PropTypes.bool,
  onRequestBookingUpdate: PropTypes.func,
  canEdit: PropTypes.bool,
  bookingChar: PropTypes.string,
  onExpandBooking: PropTypes.func,
  onCloseBooking: PropTypes.func,
  loadingItems: PropTypes.array,
  loadingSlots: PropTypes.array,
  onRequestOpenPaymentFold: PropTypes.func,
  timezone: PropTypes.string,
  hasCdhNumber: PropTypes.bool,
}

BookingPlayersGrid.defaultProps = {
  canEdit: true,
}

export default BookingPlayersGrid
