import {
  useCallback,
  createContext,
  useMemo,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'
import useExtras from '@sweetspot/club-portal-legacy/hooks/useExtras'
import useExtrasSpaces from '@sweetspot/club-portal-legacy/hooks/useExtrasSpaces'

import { ManageExtrasSpacesContextValue, SpaceSideBarMode } from './types'

export const ManageExtrasSpaces = createContext<ManageExtrasSpacesContextValue>({
  areExtrasLoading: false,
  extras: [],
  areSpacesLoading: false,
  areSpacesFetching: false,
  spaces: [],
  openSpaceSideBar: false,
  openDiscardDialog: false,
  setSelectedSpace: () => {
    return
  },
  toggleSpaceSideBar: () => {
    return
  },
  refetchSpaces: () => {
    return
  },
  changeSpaceSideBarMode: () => {
    return
  },
  setOpenDiscardDialog: () => {
    return
  },
  refetchExtras: () => {
    return
  },
  updateSpace: () => {
    return Promise.resolve()
  },
  createSpace: () => {
    return Promise.resolve()
  },
  deleteSpace: () => {
    return Promise.resolve()
  },
})

export const ManageExtrasSpacesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openSpaceSideBar, setOpenSpaceSideBar] = useState(false)
  const [spaceSideBarMode, setSpaceSideBarMode] = useState<SpaceSideBarMode | undefined>()
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false)
  const { extras, areExtrasLoading, selectedExtra, setSelectedExtra, refetchExtras } = useExtras()
  const {
    spaces,
    areSpacesLoading,
    setSelectedSpace,
    selectedSpace,
    refetch: refetchSpaces,
    areSpacesFetching,
    updateSpace,
    createSpace,
    deleteSpace,
    checkForErrors,
  } = useExtrasSpaces({
    selectedExtraId: selectedExtra?.id,
  })

  const toggleSpaceSideBar = useCallback(
    (mode?: SpaceSideBarMode) => {
      setSpaceSideBarMode(openSpaceSideBar ? undefined : mode)
      setOpenSpaceSideBar((prev) => !prev)
    },
    [openSpaceSideBar]
  )

  const value = useMemo(
    () => ({
      extras,
      areExtrasLoading,
      selectedExtra,
      setSelectedExtra,
      refetchExtras,
      spaces,
      areSpacesLoading,
      areSpacesFetching,
      setSelectedSpace,
      selectedSpace,
      openSpaceSideBar,
      toggleSpaceSideBar,
      refetchSpaces,
      spaceSideBarMode,
      changeSpaceSideBarMode: setSpaceSideBarMode,
      openDiscardDialog,
      setOpenDiscardDialog,
      updateSpace,
      createSpace,
      deleteSpace,
      checkForErrors,
    }),
    [
      areExtrasLoading,
      extras,
      selectedExtra,
      setSelectedExtra,
      refetchExtras,
      spaces,
      areSpacesFetching,
      areSpacesLoading,
      setSelectedSpace,
      selectedSpace,
      openSpaceSideBar,
      toggleSpaceSideBar,
      refetchSpaces,
      spaceSideBarMode,
      setSpaceSideBarMode,
      openDiscardDialog,
      setOpenDiscardDialog,
      updateSpace,
      createSpace,
      deleteSpace,
      checkForErrors,
    ]
  )

  return <ManageExtrasSpaces.Provider value={value}>{children}</ManageExtrasSpaces.Provider>
}

export const useManageExtrasSpacesContext = () => useContext(ManageExtrasSpaces)
