import {
  InputBase,
  InputContainer,
  InputLeadingContainer,
  InputSelect,
  InputSelectContentHeader,
  InputSelectSearchableContent,
  InputSelectSearchableItem,
  InputSelectSearchableTrigger,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  ListItem,
  ListItemLeading,
  ListItemMainContent,
  ListItemParagraph,
  ListItemTrailing,
} from '@sweetspot/scramble-ds'
import { cn } from '@sweetspot/scramble-ds/utils'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBadgeCheck, faTicketPerforated } from '@fortawesome/pro-light-svg-icons'
import {
  assignMembership,
  removeMembership,
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import { useMutation } from 'react-query'
import { useToasts } from 'react-toast-notifications'
import { Booking, MembershipCard, Slot } from '@sweetspot/shared/types'
import { useEligibleMembershipCards } from '@sweetspot/sweetspot-js/common/react-query/hooks/useMembershipCard'
import { isBookingConsideredPaid, isBookingPartiallyPaid } from '../../functions/utils'

interface AddSubscriptionSelectProps {
  booking: Booking
  onRequestBookingUpdate: (bookingId: string) => Promise<void>
  loading: boolean
  slot: Slot
  removeLoadingItem: (slotUuid: string) => void
  addLoadingItem: (slotUuid: string) => void
}

export const AddSubscriptionSelect = ({
  booking,
  onRequestBookingUpdate,
  loading,
  slot,
  removeLoadingItem,
  addLoadingItem,
}: AddSubscriptionSelectProps) => {
  const { data: eligiblePlayerSubscriptions } = useEligibleMembershipCards(booking?.uuid, {
    enabled: !!booking?.uuid,
  })

  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [selectedSubscription, setSelectedSubscription] = useState<MembershipCard | null>(null)

  useEffect(() => {
    if (slot?.membership) {
      setSelectedSubscription({
        ...slot?.membership,
        membership: slot?.membership,
      } as unknown as MembershipCard)
    }
  }, [slot?.membership])

  const assignMembershipMutation = useMutation({
    mutationFn: (membershipCard: string) => assignMembership({ membershipCard }, booking.uuid),
    onSuccess: async () => {
      addToast(t('editPeriodsNew.updated'), { appearance: 'success' })
      onRequestBookingUpdate(booking.uuid).then(() => {
        removeLoadingItem(slot.uuid)
      })
    },
    onError: () => {
      addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
    },
  })

  const removeMembershipMutation = useMutation({
    mutationFn: () => removeMembership(booking.uuid),
    onSuccess: async () => {
      addToast(t('editPeriodsNew.updated'), { appearance: 'success' })
      onRequestBookingUpdate(booking.uuid).then(() => {
        removeLoadingItem(slot.uuid)
      })
    },
    onError: () => {
      addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
    },
  })

  const memberships = eligiblePlayerSubscriptions?.filter(
    (sub) => sub.membership.type === 'membership'
  )
  const passes = eligiblePlayerSubscriptions?.filter((sub) => sub.membership.type === 'pass')

  const handleSelectSubscription = (selectedMembership: MembershipCard | null) => {
    setSelectedSubscription(selectedMembership)

    if (!selectedMembership || !selectedMembership.membership.name) {
      removeMembershipMutation.mutate()
      return
    }

    assignMembershipMutation.mutate(selectedMembership.uuid)
  }

  const isDisabled = isBookingConsideredPaid(booking) || isBookingPartiallyPaid(booking)

  return (
    <InputSelect withSearch>
      <InputBase className="w-[170px]" disabled={loading || isDisabled}>
        <InputSelectSearchableTrigger asChild>
          <InputContainer className="ring-border-stroke-subtle">
            {selectedSubscription ? (
              selectedSubscription?.membership?.type === 'membership' ? (
                <InputLeadingContainer className="left-2">
                  <FontAwesomeIcon icon={faBadgeCheck} className="mt-1 h-[14px] w-[14px]" />
                </InputLeadingContainer>
              ) : (
                <InputLeadingContainer className="left-2">
                  <FontAwesomeIcon icon={faTicketPerforated} className="mt-1 h-3 w-3" />
                </InputLeadingContainer>
              )
            ) : null}
            <InputSelectStatefulInput
              placeholder={t('memberships.assignMemberships.select')}
              className={cn(
                'h-6 text-ellipsis border pr-8 text-sm',
                selectedSubscription && 'pl-7'
              )}
              value={selectedSubscription?.membership.name || t('words.none')}
              disabled={loading || isDisabled}
              readOnly
            />
            <InputSelectTrailingContainer />
          </InputContainer>
        </InputSelectSearchableTrigger>
      </InputBase>
      <InputSelectSearchableContent
        usePortal={false}
        className="z-[10] max-h-full w-full min-w-[300px] max-w-[600px] overflow-hidden"
      >
        {(memberships ?? []).length > 0 && (
          <>
            <InputSelectContentHeader>{t('words.membership_other')}</InputSelectContentHeader>
            {memberships?.map((membership) => (
              <InputSelectSearchableItem
                key={membership.id}
                onSelect={() => {
                  if (selectedSubscription?.membership.name === membership?.membership.name) return
                  addLoadingItem(slot.uuid)
                  handleSelectSubscription(membership)
                }}
              >
                <ListItem className="ml-2 h-full w-full min-w-fit justify-between gap-2">
                  <ListItemLeading className="px-0">
                    <FontAwesomeIcon icon={faBadgeCheck} className="h-[18px] w-[18px]" />
                  </ListItemLeading>
                  <ListItemMainContent className="justify-center py-1">
                    <ListItemParagraph className="text-content-base">
                      {membership.membership.name}
                    </ListItemParagraph>
                  </ListItemMainContent>
                  {selectedSubscription?.membership.name === membership?.membership.name && (
                    <ListItemTrailing>
                      <i className="fa-regular fa-check mr-3 h-4 w-4" />
                    </ListItemTrailing>
                  )}
                </ListItem>
              </InputSelectSearchableItem>
            ))}
          </>
        )}
        {(passes ?? []).length > 0 && (
          <>
            <InputSelectContentHeader>{t('words.pass_other')}</InputSelectContentHeader>
            {passes?.map((pass) => (
              <InputSelectSearchableItem
                key={pass.id}
                onSelect={() => {
                  if (selectedSubscription?.membership.name === pass?.membership.name) return
                  addLoadingItem(slot.uuid)
                  handleSelectSubscription(pass)
                }}
              >
                <ListItem className="ml-2 h-full !w-full !min-w-full justify-between gap-2">
                  <ListItemLeading className="mt-1.5 px-0">
                    <FontAwesomeIcon icon={faTicketPerforated} className="h-5 w-5" />
                  </ListItemLeading>
                  <ListItemMainContent className="justify-center py-1">
                    <ListItemParagraph className="text-content-base">
                      {pass.membership.name}
                    </ListItemParagraph>
                  </ListItemMainContent>
                  {selectedSubscription?.membership.name === pass?.membership.name && (
                    <ListItemTrailing>
                      <i className="fa-regular fa-check mr-3 h-4 w-4" />
                    </ListItemTrailing>
                  )}
                </ListItem>
              </InputSelectSearchableItem>
            ))}
          </>
        )}
        <InputSelectSearchableItem
          onSelect={() => {
            if (!selectedSubscription) return
            addLoadingItem(slot.uuid)
            handleSelectSubscription(null)
          }}
        >
          <ListItem className="ml-3 h-full !w-full !min-w-full justify-between gap-2">
            <ListItemMainContent className="justify-center py-1">
              <ListItemParagraph className="text-content-base">{t('words.none')}</ListItemParagraph>
            </ListItemMainContent>
            {!selectedSubscription && (
              <ListItemTrailing>
                <i className="fa-regular fa-check mr-5 h-4 w-4" />
              </ListItemTrailing>
            )}
          </ListItem>
        </InputSelectSearchableItem>
      </InputSelectSearchableContent>
    </InputSelect>
  )
}
