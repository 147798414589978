import { Control, Controller, FieldError } from 'react-hook-form'
import { Input, InputBase, InputContainer, InputLabelContainer } from '@sweetspot/scramble-ds'
import { Hint } from '@sweetspot/scramble-ds/atoms'
import { useTranslation } from 'react-i18next'

interface InputFieldProps {
  name: string
  label: string
  control: Control<any>
  error: FieldError | undefined
  required?: boolean
  className?: string
  placeholder?: string
  disabled?: boolean
}

export const InputField = ({
  name,
  label,
  control,
  error,
  required,
  className,
  placeholder,
  disabled,
}: InputFieldProps) => {
  const { t } = useTranslation()

  return (
    <InputBase required={required} error={!!error} className={className} disabled={disabled}>
      <InputLabelContainer>{label}</InputLabelContainer>
      <InputContainer>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              rounding="rectangular"
              type="text"
              className="text-content-base mt-1"
              placeholder={placeholder}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
      </InputContainer>
      {error?.message && <Hint variant="error" className="mt-1" hintText={t(error.message)} />}
    </InputBase>
  )
}
