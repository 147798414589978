import { useTranslation } from 'react-i18next'

import { GolfCourseTypes } from '@sweetspot/shared/types'
import { EmptyStateTitle } from '@sweetspot/scramble-ds'

type EmptyVenueTitleProps = {
  courseType: GolfCourseTypes
}

export const EmptyVenueTitle = ({ courseType }: EmptyVenueTitleProps) => {
  const { t } = useTranslation()
  switch (courseType) {
    case GolfCourseTypes.SIMULATOR:
      return (
        <EmptyStateTitle>
          {t('settings.noCoursesCreated', { type: t('words.simulator_one').toLowerCase() })}
        </EmptyStateTitle>
      )
    case GolfCourseTypes.DRIVING_RANGE:
      return (
        <EmptyStateTitle>
          {t('settings.noCoursesCreated', { type: t('words.range_one').toLowerCase() })}
        </EmptyStateTitle>
      )
    case GolfCourseTypes.PRO:
      return (
        <EmptyStateTitle>
          {t('settings.noCoursesCreated', { type: t('words.academy').toLowerCase() })}
        </EmptyStateTitle>
      )
    default:
      return null
  }
}
