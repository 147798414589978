import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  InputSelect,
  InputLeadingContainer,
} from '@sweetspot/scramble-ds'
import { Tooltip } from '@sweetspot/scramble-ds/atoms'
import { PeriodFormSelectFieldOption } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { useAvailabilityAndPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/AvailabilityAndPricingProvider'
import { faSquare } from '@fortawesome/pro-solid-svg-icons'

type CategoriesProps = {
  category?: number
  onCategoryChange: (category: number) => void
  tooltipContent?: string
}

const Categories = ({ category, onCategoryChange, tooltipContent }: CategoriesProps) => {
  const { categories } = useAvailabilityAndPricingContext()
  const { t } = useTranslation()

  const categoriesOptions: (PeriodFormSelectFieldOption & { color: string })[] = useMemo(() => {
    return categories?.map((categoryData) => ({
      value: String(categoryData.id),
      label: categoryData.name,
      color: categoryData.color,
    }))
  }, [categories])

  const selectedCategory = useMemo(
    () => categoriesOptions.find((option) => +option.value === category),
    [category, categoriesOptions]
  )
  return (
    <div className="flex items-end justify-between">
      <InputBase>
        <InputLabelContainer>{t('words.category')}</InputLabelContainer>
        <InputSelect value={String(category)} onValueChange={(value) => onCategoryChange(+value)}>
          <InputSelectTrigger asChild>
            <InputContainer>
              <InputLeadingContainer className="left-2 p-2">
                <FontAwesomeIcon icon={faSquare} color={selectedCategory?.color || '#D1D5DB'} />
              </InputLeadingContainer>
              <InputSelectStatefulInput
                className={'text-content-base h-8 border-[1px] py-1 pl-10'}
                value={selectedCategory?.label}
                placeholder={t('words.select')}
              />
              <InputSelectTrailingContainer className="right-2" />
            </InputContainer>
          </InputSelectTrigger>
          <InputSelectContent>
            <InputSelectContentView>
              {categoriesOptions?.map((option) => (
                <InputSelectItem key={option.value} value={option.value}>
                  <ListItem className="ml-2 h-full justify-center gap-2">
                    <ListItemMainContent className="justify-center">
                      <ListItemParagraph className="text-content-base">
                        <div className="flex items-center gap-2">
                          <div className="p-2">
                            <FontAwesomeIcon icon={faSquare} color={option.color} />
                          </div>
                          {option.label}
                        </div>
                      </ListItemParagraph>
                    </ListItemMainContent>
                  </ListItem>
                </InputSelectItem>
              ))}
            </InputSelectContentView>
          </InputSelectContent>
        </InputSelect>
      </InputBase>
      {!!tooltipContent && (
        <Tooltip.Provider key="categoriesTooltip">
          <Tooltip.Root>
            <React.Fragment key="categoriesTooltip">
              <Tooltip.Trigger asChild>
                <div className="flex h-8 w-8 items-center justify-end p-1">
                  <FontAwesomeIcon icon={faCircleInfo} className="text-text-subtle" />
                </div>
              </Tooltip.Trigger>
              <Tooltip.Content>
                <div className="flex w-[263px] flex-col gap-4 py-2">
                  <p className="text-content-sm text-text-light font-bold">{t('words.category')}</p>
                  <p className="text-content-sm font-regular text-text-light">
                    <Trans i18nKey={tooltipContent} components={{ br: <br /> }} />
                  </p>
                </div>
                <Tooltip.Arrow />
              </Tooltip.Content>
            </React.Fragment>
          </Tooltip.Root>
        </Tooltip.Provider>
      )}
    </div>
  )
}

export default Categories
