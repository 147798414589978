import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Input,
  InputBase,
  InputContainer,
  InputHint,
  InputLabelContainer,
} from '@sweetspot/scramble-ds'
import { Divider } from '@sweetspot/scramble-ds/atoms'

type Props = {
  name: string
  setName: (value: string) => void
  description?: string
  setDescription: (value: string) => void
  floor?: number
  setFloor: (value: number) => void
}

const DetailsForm = ({ name, setName, description, setDescription, floor, setFloor }: Props) => {
  const { t } = useTranslation()

  const handleSetFloor = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFloor(Math.min(+event.target.value || 0, 999))
    },
    [setFloor]
  )

  return (
    <div className="flex w-full flex-col gap-4">
      <InputBase>
        <InputLabelContainer>{t('settings.name')}</InputLabelContainer>
        <InputContainer>
          <Input
            className="text-content-base h-8 border"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </InputContainer>
        <InputHint
          className="[&_i]:text-content-xs items-center [&_:first-child]:w-auto"
          variant="default"
          hintText={t('settings.nameHint', { type: t('words.space_one').toLowerCase() })}
        />
      </InputBase>

      <InputBase>
        <InputLabelContainer>{t('words.description')}</InputLabelContainer>
        <InputContainer>
          <Input
            className="text-content-base h-8 border"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </InputContainer>
        <InputHint
          className="[&_i]:text-content-xs items-center [&_:first-child]:w-auto"
          variant="default"
          hintText={t('settings.descriptionHint', { type: t('words.space_one').toLowerCase() })}
        />
      </InputBase>

      <Divider />

      <InputBase>
        <InputLabelContainer>{t('words.floor')}</InputLabelContainer>
        <InputContainer>
          <Input
            value={floor}
            onChange={handleSetFloor}
            className="pr-md border-border-stroke-clean text-content-base h-8 w-full border-[1px] pl-2"
            placeholder={t('settings.spaces.floorPlaceholder')}
          />
        </InputContainer>
      </InputBase>
    </div>
  )
}

export default DetailsForm
