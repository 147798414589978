import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import cx from 'classnames'
import { t } from 'i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import Alert from '@sweetspot/club-portal-legacy/components/Alert'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import OverlayContainer from '@sweetspot/sweetspot-js/common/components/OverlayContainer'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import RemoveButton from '@sweetspot/club-portal-legacy/components/RemoveButton'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'
import ImageUploading from 'react-images-uploading'
import { Button } from '@sweetspot/scramble-ds/atoms'
import { cn } from '@sweetspot/scramble-ds/utils'

import {
  getCourseGuideImages,
  getCourseGuide,
  createCourseGuide,
  importCourseGuideFromCaddee,
  updateCourseGuide,
  uploadCourseGuideImage,
} from '@sweetspot/club-portal-legacy/store/actions'
import { deleteGuideImage, setGuideImageHole } from '@sweetspot/shared/data-access/api-platform'

class CourseGuide extends Component {
  constructor(props) {
    super(props)

    this.state = {
      courseGuide: null,
      noOfHoles: 18,
      holes: [1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
      isLoading: true,
      pictures: [],
      holesWithImage: [],
      selectedImage: null,
      selectedHoleForImage: 1,
      selectedGolfCourse: null,
      showAlert: false,
      showConfirm: false,
      is_active: false,
    }

    this.onDrop = this.onDrop.bind(this)
    this.importFromCaddee = this.importFromCaddee.bind(this)
  }

  componentDidMount() {
    const { golfCourses } = this.props
    if (golfCourses && golfCourses[0]) this.getCourseGuide(golfCourses[0])
  }

  getCourseGuide(course) {
    const { token, getCourseGuide } = this.props

    if (course && course.id) {
      getCourseGuide(token, course.id)
        .then((courseGuide) => {
          this.setState({
            courseGuide,
            selectedGolfCourse: course,
            is_active: courseGuide.is_active,
          })
          this.setNumOFHoles(courseGuide, courseGuide.number_of_holes, course)
          this.fetchCourseGuideImages(courseGuide.id)
        })
        .catch((err) => {
          if (err?.status === 404) {
            this.createCourseGuide(course)
          } else {
            this.setState({ isLoading: false })
          }
        })
    }
  }

  createCourseGuide(course) {
    const { token, createCourseGuide } = this.props

    const payload = {
      holes: 18,
      is_active: false,
      course: course.id,
    }
    createCourseGuide(token, payload)
      .then(() => {
        this.getCourseGuide(course)
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  fetchCourseGuideImages(courseGuide) {
    const { token, getCourseGuideImages } = this.props

    getCourseGuideImages(token, courseGuide).then((images) => {
      const holesWithImage = images.filter((x) => !!x.hole && x.hole <= this.state.holes.length)
      this.setState({ pictures: images, holesWithImage, isLoading: false })
    })
  }

  onDrop(uploadPictures) {
    const { token, uploadCourseGuideImage } = this.props
    const { courseGuide, pictures, noOfHoles } = this.state

    if (!uploadPictures?.[0]?.file) return

    this.setState({ isLoading: true })
    uploadCourseGuideImage(
      token,
      courseGuide.id,
      uploadPictures.map((img) => img.file),
      noOfHoles
    )
      .then((data) => {
        this.setState({
          pictures: pictures.concat({ ...data, url: data.content_url }),
          isLoading: false,
        })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  importFromCaddee() {
    const { token, importCourseGuideFromCaddee } = this.props

    this.setState({ isLoading: true, showConfirm: false })
    importCourseGuideFromCaddee(token, this.state.selectedGolfCourse.id)
      .then(() => this.getCourseGuide(this.state.selectedGolfCourse))
      .catch(() => this.setState({ isLoading: false }))
  }

  handleCourseGuideStatus(status) {
    const { token, updateCourseGuide } = this.props
    const { noOfHoles, holes, holesWithImage, courseGuide } = this.state

    if (status && holesWithImage.length < holes.length) {
      this.setState({ showAlert: true })
    } else {
      const payload = {
        holes: parseInt(noOfHoles),
        is_active: status,
      }
      updateCourseGuide(token, courseGuide.id, payload)
      this.setState({ courseGuide: { ...courseGuide, is_active: status } })
    }
  }

  setNumOFHoles(courseGuide, number, course) {
    const num = parseInt(number)
    const { is_active } = this.state
    const { token, updateCourseGuide } = this.props
    const holes = []

    if (num < 0 || num > 36) return

    for (let i = 1; i <= num; i++) {
      holes.push({ id: i, name: i, file: null, url: '' })
    }
    this.setState({ holes, noOfHoles: num })

    if (num !== courseGuide.number_of_holes) {
      const payload = {
        holes: num,
        is_active,
      }
      updateCourseGuide(token, courseGuide.id, payload).then(() => {
        this.getCourseGuide(course)
      })
    }
  }

  selectGolfCourse(course) {
    this.setState({ isLoading: true })
    const selectedGolfCourse = this.props.golfCourses.find((x) => x.id === course)
    this.setState({ selectedGolfCourse })
    this.getCourseGuide(selectedGolfCourse)
  }

  addImageToHole() {
    const { pictures, selectedImage, selectedHoleForImage, courseGuide } = this.state

    this.setState({ isLoading: true })
    // Remove current image if there is one

    const currentImage = pictures.find((x) => x.hole === selectedHoleForImage)
    if (currentImage) this.removeImageFromHole(currentImage)

    setGuideImageHole(selectedImage.id, selectedHoleForImage).then(() => {
      this.closeModal()
      this.fetchCourseGuideImages(courseGuide.id)
    })
  }

  changeHole(nextOrPrev, image) {
    const pictures = this.state.pictures
    const nextHoleNo = nextOrPrev === 'next' ? image.hole + 1 : image.hole - 1
    let nextPicture

    nextPicture = pictures.find((x) => x.hole === nextHoleNo)
    if (!nextPicture) nextPicture = { ...image, hole: nextHoleNo, url: '' }

    this.setState({ selectedImage: nextPicture })
  }

  removeImageFromBank(image) {
    let pictures = [...this.state.pictures]

    deleteGuideImage(image.id).then(() => {
      pictures.splice(pictures.indexOf(image), 1)
      this.setState({ pictures })
      this.closeModal()
    })
  }

  removeImageFromHole(image) {
    const { courseGuide } = this.state

    setGuideImageHole(image.id, null).then(() => {
      this.closeModal()
      this.fetchCourseGuideImages(courseGuide.id)
    })
  }

  closeModal() {
    this.setState({ selectedImage: null })
  }

  toggleConfirm() {
    this.setState({ showConfirm: !this.state.showConfirm })
  }

  renderSideMenu() {
    const { courseGuide, selectedGolfCourse, pictures } = this.state
    const { golfCourses } = this.props

    return (
      <div className={style.sideMenu}>
        {golfCourses.length > 1 && (
          <div className={style.courseSelect}>
            <div className={style.dropDown}>
              <DropdownSelect
                initialId={selectedGolfCourse ? selectedGolfCourse.id : 1}
                width={200}
                onSelect={(course) => this.selectGolfCourse(course)}
                values={golfCourses}
                largeText
                largeArrow
                readOnly
              />
            </div>
          </div>
        )}
        <div className={style.holeSelect}>
          <Text textId="settings.courseGuide.noOfHoles" />
          <div className={style.dropDown}>
            <input
              className="ss-input"
              type="text"
              value={this.state.noOfHoles}
              onChange={(event) =>
                this.setNumOFHoles(courseGuide, event.target.value, selectedGolfCourse)
              }
            />
          </div>
        </div>
        <div className={style.statusToggle}>
          <div className="ss-btn-group">
            <button
              className={cx(
                'system-button md-32',
                courseGuide && courseGuide.is_active ? 'primary' : 'primary-outline'
              )}
              onClick={() => this.handleCourseGuideStatus(true)}
            >
              {t('settings.active')}
            </button>
            <button
              className={cx(
                'system-button md-32',
                courseGuide && !courseGuide.is_active ? 'warning' : 'primary-outline'
              )}
              onClick={() => this.handleCourseGuideStatus(false)}
            >
              {t('settings.inactive')}
            </button>
          </div>
        </div>
        <div className={style.addImages}>
          <div className={style.label}>
            <Text textId="settings.courseGuide.addImages" />
          </div>
          <button
            className="system-button primary-outline md-32"
            onClick={() => this.toggleConfirm()}
          >
            {t('settings.courseGuide.importImages')}
          </button>

          <div className={cn(style.imageUpload, 'mt-4')}>
            <ImageUploading
              maxNumber={1}
              onChange={this.onDrop}
              maxFileSize={5242880}
              acceptType={['jpg', 'jpeg', 'png']}
            >
              {({ onImageUpload, dragProps }) => (
                <Button
                  size="small"
                  variant="primary"
                  className="mb-4"
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  {this.props.lang === 'en' ? 'Upload image' : 'Ladda upp bild'}
                </Button>
              )}
            </ImageUploading>
            <Text textId="settings.courseGuide.imageBank" />
            {pictures && this.renderImageBank()}
          </div>
        </div>
      </div>
    )
  }

  renderImageBank() {
    const images = this.state.pictures.map((image, index) => {
      const selectedBorder = image.hole ? '2px solid#2FC182' : 'none'
      return (
        <div key={index} className={style.courseImage}>
          <img
            style={{ width: '45px', height: '80px', border: selectedBorder }}
            src={image.content_url}
            onClick={() => this.setState({ selectedImage: image })}
            alt=""
          />
        </div>
      )
    })
    return <div className={style.imageBank}>{images}</div>
  }

  renderImageModal() {
    const { selectedImage, selectedHoleForImage, holes } = this.state

    return (
      <OverlayContainer>
        <div className={style.imageModal}>
          <div className={style.modalHeader}>
            {!selectedImage.hole ? (
              <React.Fragment>
                <Text textId="settings.courseGuide.selectHole" />
                <DropdownSelect
                  values={holes}
                  width={65}
                  initialId={1}
                  onSelect={(id) => this.setState({ selectedHoleForImage: id })}
                />
                <button
                  className="system-button info-outline md-32"
                  onClick={() => this.addImageToHole()}
                  disabled={!selectedHoleForImage}
                >
                  {t('add')}
                </button>
              </React.Fragment>
            ) : (
              <div className={style.holeNumber}>
                <Text textId="settings.courseGuide.hole" />
                &nbsp;
                {selectedImage.hole}
              </div>
            )}
            <RemoveButton className={style.removeButton} onClick={() => this.closeModal()} />
          </div>
          <div className={style.mainContent}>
            {selectedImage.hole > 1 && (
              <div
                className={style.arrowContainer}
                onClick={() => this.changeHole('prev', selectedImage)}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </div>
            )}
            <img src={selectedImage.content_url} alt="" />
            {selectedImage.hole && selectedImage.hole < holes.length && (
              <div
                className={style.arrowContainer}
                onClick={() => this.changeHole('next', selectedImage)}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            )}
          </div>
          {selectedImage.url ? (
            !selectedImage.hole ? (
              <button
                className="system-button danger-outline md-32"
                onClick={() => this.removeImageFromBank(selectedImage)}
              >
                {t('settings.courseGuide.deleteImage')}
              </button>
            ) : (
              <button
                className="system-button danger-outline md-32"
                onClick={() => this.removeImageFromHole(selectedImage)}
              >
                {t('settings.courseGuide.removeImageFromHole')}
              </button>
            )
          ) : (
            ''
          )}
        </div>
      </OverlayContainer>
    )
  }

  render() {
    const { isLoading, holes, pictures, holesWithImage, showAlert, showConfirm, selectedImage } =
      this.state
    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <div className={style.label}>
            <Text textId="settings.courseGuide.courseGuide" />
          </div>
          <PulseLoader showIf={isLoading} />
        </div>
        <div className={style.content}>
          {this.renderSideMenu()}
          <HoleGrid
            holes={holes}
            pictures={pictures}
            holesWithImage={holesWithImage}
            onImageClick={(image) => this.setState({ selectedImage: image })}
          />
          {selectedImage && this.renderImageModal()}
          <Alert
            showIf={showAlert}
            onClick={() => {
              this.setState({
                showAlert: false,
              })
            }}
          >
            <Text textId="settings.courseGuide.activateGuideError" />
          </Alert>
          <ConfirmPopup
            visible={showConfirm}
            rejectTextKey="cancel"
            acceptTextKey="import"
            onReject={() => {
              this.setState({
                showConfirm: false,
              })
            }}
            onClose={() => {
              this.setState({
                showConfirm: false,
              })
            }}
            onAccept={() => {
              this.importFromCaddee()
            }}
            textKey="settings.courseGuide.confirmImportText"
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    golfCourses: state.golfCourse.list,
    token: state.auth.token,
    lang: state.auth.me.lang,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getCourseGuideImages: (token, id) => dispatch(getCourseGuideImages(token, id)),
    getCourseGuide: (token, id) => dispatch(getCourseGuide(token, id)),
    createCourseGuide: (token, payload) => dispatch(createCourseGuide(token, payload)),
    importCourseGuideFromCaddee: (token, id) => dispatch(importCourseGuideFromCaddee(token, id)),
    updateCourseGuide: (token, id, payload) => dispatch(updateCourseGuide(token, id, payload)),
    uploadCourseGuideImage: (token, id, image, hole) =>
      dispatch(uploadCourseGuideImage(token, id, image, hole)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CourseGuide))

CourseGuide.propTypes = {
  token: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  golfCourses: PropTypes.array.isRequired,
  getCourseGuide: PropTypes.func.isRequired,
  createCourseGuide: PropTypes.func.isRequired,
  getCourseGuideImages: PropTypes.func.isRequired,
  importCourseGuideFromCaddee: PropTypes.func.isRequired,
  uploadCourseGuideImage: PropTypes.func.isRequired,
  updateCourseGuide: PropTypes.func.isRequired,
}

const HoleGrid = (props) => {
  const { holes, holesWithImage, onImageClick } = props
  const holesGrid = []

  for (let i = 1; i <= holes.length; i++) {
    let image
    if (holesWithImage) image = holesWithImage.find((img) => img.hole === i)
    holesGrid.push(
      <div key={i}>
        <div className={style.holeNumber}>
          <Text textId="settings.courseGuide.hole" />
          &nbsp;
          {i}
        </div>
        <div className={style.holeImage}>
          {image && <img src={image.content_url} onClick={() => onImageClick(image)} alt="" />}
        </div>
      </div>
    )
  }
  return <div className={style.holeGrid}>{holesGrid}</div>
}

HoleGrid.propTypes = {
  holesWithImage: PropTypes.array.isRequired,
  holes: PropTypes.array.isRequired,
  onImageClick: PropTypes.func.isRequired,
  pictures: PropTypes.array.isRequired,
}
