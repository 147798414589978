import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import { useTimeZone } from '@sweetspot/sweetspot-js/common/react-query/hooks/useTimeZone'
import {
  Input,
  InputBase,
  InputContainer,
  InputHint,
  InputLabelContainer,
  InputLeadingContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectSearchableContent,
  InputSelectSearchableItem,
  InputSelectSearchableTrigger,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  Textarea,
  TextareaContainer,
} from '@sweetspot/scramble-ds'
import { Button, Divider, Toggle } from '@sweetspot/scramble-ds/atoms'
import { GolfCourse, Membership, Timezone } from '@sweetspot/shared/types'
import { useMembership } from '@sweetspot/sweetspot-js/common/react-query/hooks/useMembership'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import Pill from '@sweetspot/sweetspot-js/common/components/Pill'
import ButtonDock from '@sweetspot/sweetspot-js/common/components/ButtonDock/ButtonDock'
import useRoutingBlock from '@sweetspot/club-portal-legacy/hooks/useRoutingBlock'
import UploadImage from '@sweetspot/club-portal-legacy/components/UploadImage/UploadImage'

import { UpdateAcademyData, FormatedTimeZone } from '../../types'
import useUpdateAcademy from '../../hooks/useUpdateAcademy'
import { useManageAcademyContext } from '../../ManageAcademyProvider'

type GeneralFormFields = Pick<
  UpdateAcademyData,
  'name' | 'description' | 'timezone' | 'latitude' | 'longitude' | 'memberships' | 'imageId'
>

type GeneralTabProps = {
  currentCourse?: GolfCourse
  refetchCourses: () => void
}

const GeneralTab = ({ currentCourse, refetchCourses }: GeneralTabProps) => {
  const { t } = useTranslation()
  const { timezones } = useTimeZone()
  const { addToast } = useToasts()
  const {
    updateAcademy,
    generalInitialData: initialData,
    getImage,
    uploadImage,
    isImageLoading,
  } = useUpdateAcademy({ currentCourse })
  const { setDiscardDialogCallbacks, setOpenDiscardDialog } = useManageAcademyContext()
  const [image, setImage] = useState<string>()
  const [defaultImage, setDefaultImage] = useState<string>()
  const [isMembershipEnabled, setIsMembershipEnabled] = useState<boolean>(
    !!initialData.memberships?.length
  )
  const [membershipsOptions, setMembershipsOptions] = useState<Membership[]>([])
  const [timezoneSearch, setTimezoneSearch] = useState('')
  const currentClubId = useSelector((state: RootState) => state?.golfClub?.selectedId || 0)
  const { data: allMemberships } = useMembership({
    clubId: currentClubId,
    state: 'published',
    totalFee: 0,
    sortOrder: 'asc',
    isActive: true,
    enabled: true,
  })
  const { register, watch, setValue, formState, reset } = useForm<GeneralFormFields>({
    defaultValues: initialData,
  })
  const formValues = watch()
  const isDirty = formState?.isDirty

  const onSubmit = useCallback(() => {
    updateAcademy(formValues)?.then(() => {
      addToast(t('toast.updateAcademySuccess'), { appearance: 'success' })
      reset(formValues)
      refetchCourses()
    })
  }, [formValues, updateAcademy, reset, refetchCourses, addToast, t])

  const handleFormReset = useCallback(() => {
    reset()
    setIsMembershipEnabled(!!initialData.memberships?.length)
    setImage(defaultImage)
  }, [setIsMembershipEnabled, initialData.memberships?.length, reset, defaultImage])

  // block navigation when form is dirty
  useRoutingBlock({
    isDirty,
    onReset: handleFormReset,
    onSubmit,
    setDiscardDialogCallbacks,
    setOpenDiscardDialog,
  })

  useEffect(() => {
    if (currentCourse?.id) {
      getImage().then((res) => {
        setImage(res)
        setDefaultImage(res)
      })
    }
  }, [currentCourse?.id, getImage])

  useEffect(() => {
    setMembershipsOptions(
      (allMemberships as Membership[])?.filter(
        (item) => !formValues.memberships?.find?.((uuid) => item.uuid === uuid)
      )
    )
  }, [allMemberships, formValues.memberships])

  // reset form initialData
  useEffect(() => {
    reset(initialData)
  }, [initialData, reset])

  useEffect(() => {
    setIsMembershipEnabled(!!initialData.memberships?.length)
  }, [initialData.memberships?.length])

  const formatedTimeZones: FormatedTimeZone[] = useMemo(() => {
    if (timezones) {
      const filteredData = timezones.map((zone: Timezone) => {
        const offset = Math.floor(zone.offset / 3600)
        const offsetString = offset > 0 ? `+${offset}` : offset
        return {
          id: zone.timezone_id,
          name: `${zone.timezone_id} (UTC ${offsetString}) `,
          ...zone,
        }
      })

      return filteredData
    }
    return []
  }, [timezones])

  const timezonesToRender = useMemo(
    () =>
      formatedTimeZones.filter((zone: FormatedTimeZone) =>
        zone.name.toLowerCase().includes(timezoneSearch.toLowerCase())
      ),
    [formatedTimeZones, timezoneSearch]
  )

  const sortByName = useCallback((a: string, b: string) => {
    const aName = a.toUpperCase()
    const bName = b.toUpperCase()
    if (aName < bName) return -1
    if (aName === bName) return 0
    if (aName > bName) return 1
    return 0
  }, [])

  const toggleMembershipEnabled = useCallback(
    (newValue: boolean) => {
      setIsMembershipEnabled(newValue)
      setValue('memberships', [], { shouldDirty: true })
    },
    [setValue]
  )

  const handleMembershipChange = useCallback(
    (uuid: string, action: 'add' | 'remove') => {
      // Add
      if (action === 'add') {
        const newMembership = membershipsOptions?.find((item) => item.uuid === uuid)

        if (newMembership) {
          const newMemberships = [...(formValues.memberships || []), uuid]
          setMembershipsOptions(membershipsOptions?.filter((item) => item.uuid !== uuid))
          setValue('memberships', newMemberships, { shouldDirty: true })
        }
      }
      // Remove
      else if (action === 'remove') {
        const tempMembership = (allMemberships as Membership[])?.find((item) => item.uuid === uuid)
        const tempMemberships = membershipsOptions?.slice()
        tempMembership && tempMemberships.push(tempMembership)
        tempMemberships.sort((a, b) => sortByName(a.name, b.name))
        setMembershipsOptions(tempMemberships)
        setValue(
          'memberships',
          formValues.memberships?.filter((membershipUuid) => membershipUuid !== uuid),
          { shouldDirty: true }
        )
      }
    },
    [allMemberships, formValues.memberships, membershipsOptions, setValue, sortByName]
  )

  const handleValueChange = useCallback(
    (newValue: string) => {
      setValue('timezone', newValue, { shouldDirty: true })
      setTimezoneSearch('')
    },
    [setValue]
  )

  return (
    <form onSubmit={(e) => e.preventDefault()} className="flex max-w-[456px] flex-col gap-6">
      <InputBase>
        <InputLabelContainer className="mb-0.5">{t('settings.name')}</InputLabelContainer>
        <InputContainer>
          <Input {...register('name')} className="text-content-base h-8  border" />
        </InputContainer>
        <InputHint
          className="[&_i]:text-content-xs [&_:first-child]:-mt-[1px] [&_:first-child]:w-auto"
          variant="default"
          hintText={t('settings.updateNameHint', { type: t('words.academy').toLowerCase() })}
        />
      </InputBase>

      <Divider />

      <InputBase>
        <InputLabelContainer className="mb-0.5">{t('settings.description')}</InputLabelContainer>
        <TextareaContainer>
          <Textarea
            {...register('description')}
            className="text-content-base h-[94px] border"
            min={undefined}
            max={undefined}
          />
        </TextareaContainer>
        <InputHint
          className="[&_i]:text-content-xs [&_:first-child]:-mt-[1px] [&_:first-child]:w-auto"
          variant="default"
          hintText={t('settings.updateDescriptionHint', { type: t('words.academy').toLowerCase() })}
        />
      </InputBase>

      <Divider />

      <UploadImage
        setImage={setImage}
        setValue={(imageId) => setValue('imageId', imageId, { shouldDirty: true })}
        image={image}
        courseText={t('words.academy').toLowerCase()}
        placeholder={t('settings.academy.academyImage')}
        uploadImage={uploadImage}
        isImageLoading={isImageLoading}
      />

      <Divider />

      <InputSelect withSearch>
        <InputBase>
          <InputLabelContainer className="mb-0.5">{t('words.timezone')}</InputLabelContainer>
          <InputSelectSearchableTrigger asChild>
            <InputContainer>
              <InputLeadingContainer>
                <i className="fa-regular fa-globe" />
              </InputLeadingContainer>
              <InputSelectStatefulInput
                name="timezone"
                className="text-content-base h-8 border pl-12"
                onClick={() => setTimezoneSearch('')}
                value={
                  formatedTimeZones.find((zone) => zone.id === formValues.timezone)?.name || ''
                }
              />
            </InputContainer>
          </InputSelectSearchableTrigger>
          <InputHint
            className="[&_i]:text-content-xs [&_:first-child]:-mt-[1px] [&_:first-child]:w-auto"
            variant="default"
            hintText={t('settings.updateTimezoneHint', { type: t('words.academy').toLowerCase() })}
          />
        </InputBase>

        <InputSelectSearchableContent>
          <div className="bg-background-mono-lighter">
            <InputBase className="w-full p-4">
              <InputContainer>
                <InputLeadingContainer>
                  <i className="fa-regular fa-magnifying-glass" />
                </InputLeadingContainer>
                <Input
                  rounding="pill"
                  className="px-10"
                  placeholder={t('search')}
                  value={timezoneSearch}
                  onChange={(e) => setTimezoneSearch(e.target.value)}
                />
              </InputContainer>
            </InputBase>
          </div>
          {timezonesToRender.map((zone) => (
            <InputSelectSearchableItem key={zone.id} value={zone.id} onSelect={handleValueChange}>
              <ListItem className="ml-2 h-full justify-center gap-2">
                <ListItemMainContent className="justify-center">
                  <ListItemParagraph className="text-content-base">{zone.name}</ListItemParagraph>
                </ListItemMainContent>
              </ListItem>
            </InputSelectSearchableItem>
          ))}
        </InputSelectSearchableContent>
      </InputSelect>

      <Divider />

      <InputBase>
        <InputLabelContainer className="mb-0.5">{t('settings.latitude')}</InputLabelContainer>
        <InputContainer>
          <Input {...register('latitude')} className="text-content-base h-8  border" />
        </InputContainer>
        <InputHint
          className="[&_i]:text-content-xs [&_:first-child]:-mt-[1px] [&_:first-child]:w-auto"
          variant="default"
          hintText={t('settings.updateLatitudeHint', { type: t('words.academy').toLowerCase() })}
        />
      </InputBase>

      <Divider />

      <InputBase>
        <InputLabelContainer className="mb-0.5">{t('settings.longitude')}</InputLabelContainer>
        <InputContainer>
          <Input {...register('longitude')} className="text-content-base h-8  border" />
        </InputContainer>
        <InputHint
          className="[&_i]:text-content-xs [&_:first-child]:-mt-[1px] [&_:first-child]:w-auto"
          variant="default"
          hintText={t('settings.updateLongitudeHint', { type: t('words.academy').toLowerCase() })}
        />
      </InputBase>

      <Divider />

      <div className="flex min-h-12 items-center gap-4 [&_input]:hidden">
        <div>
          <label className="text-text-dark text-content-sm px-md mb-0.5 font-bold">
            {t('settings.allowSignupDuringBooking')}
          </label>
          <div className="text-text-subtle text-content-sm pl-2">
            {t('settings.updateMembershipHint')}
          </div>
        </div>
        <Toggle checked={isMembershipEnabled} onCheckedChange={toggleMembershipEnabled} />
      </div>

      {isMembershipEnabled && (
        <InputSelect
          onValueChange={(selectedValue) => handleMembershipChange(selectedValue, 'add')}
        >
          <InputBase>
            <InputLabelContainer className="mb-0.5">
              {t('settings.allowedMembership')}
            </InputLabelContainer>
            <InputSelectTrigger asChild disabled={!membershipsOptions?.length}>
              <InputContainer>
                <InputSelectStatefulInput
                  className="text-content-base h-8 border"
                  placeholder={t('words.select')}
                />
                <InputSelectTrailingContainer />
              </InputContainer>
            </InputSelectTrigger>
          </InputBase>
          <InputSelectContent>
            <InputSelectContentView>
              {membershipsOptions?.map((membership) => (
                <InputSelectItem key={membership.id} value={membership.uuid}>
                  <ListItem className="ml-2 h-full justify-center gap-2">
                    <ListItemMainContent className="justify-center">
                      <ListItemParagraph className="text-content-base">
                        {membership.name}
                      </ListItemParagraph>
                    </ListItemMainContent>
                  </ListItem>
                </InputSelectItem>
              ))}
            </InputSelectContentView>
          </InputSelectContent>
          <div className="-mt-4 flex flex-wrap gap-2">
            {formValues.memberships?.map((uuid) => {
              const membership = (allMemberships as Membership[])?.find(
                (option) => option.uuid === uuid
              )
              if (!membership) return null
              return (
                <Pill
                  key={membership.id}
                  text={membership.name}
                  useCloseButton={true}
                  onClose={() => handleMembershipChange(uuid, 'remove')}
                  className="!w-fit"
                />
              )
            })}
          </div>
        </InputSelect>
      )}

      <ButtonDock className="max-w-[480px]" dockClassName="left-[15px] px-0" isShown={isDirty}>
        <Button variant="ghost-dark" size="small" type="button" onClick={handleFormReset}>
          {t('words.cancel')}
        </Button>
        <Button variant="primary" size="small" onClick={onSubmit}>
          {t('words.save')}
        </Button>
      </ButtonDock>
    </form>
  )
}

export default GeneralTab
