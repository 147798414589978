import createClient, { type Client } from 'openapi-fetch'
import type { paths } from './openapi.d.ts'
import { getApiPlatformBaseUrl, isJson } from '@sweetspot/shared/util/functions'
import fetchRetry from 'fetch-retry'
import { apiPlatformAuthMiddleware } from '@sweetspot/shared/data-access/api-platform-auth'
import {
  addEmptyBodyToPutPostRequestsMiddleware,
  checkForEmptyBodyMiddleware,
  sunsetMiddleware,
} from './middlewares'
import i18n from 'i18next'

const memoizedClients = new Map<string, Client<paths>>()

interface Options<T> {
  acceptContentType?: T
  retry?: boolean
  requestContentType?: string
  acceptLanguage?: string
  baseUrl?: string
  useSunsetMiddleware?: boolean
  useEmptyBodyToPutPostMiddleware?: boolean
  useAuthMiddleware?: boolean
  useCheckForEmptyBodyMiddleware?: boolean
}

/**
 * Creates an API Platform client with the given options
 *
 * @param options Options for the client
 * @returns An API Platform client
 */
export const apiPlatformClient = <T extends `${string}/${string}` = 'application/json'>(
  options: Options<T> = {}
) => {
  const {
    acceptContentType = 'application/json' as T,
    retry = true,
    requestContentType = 'application/json',
    acceptLanguage = i18n?.language || 'en',
    baseUrl = getApiPlatformBaseUrl()?.replace('/api', ''),
    useSunsetMiddleware = true,
    useEmptyBodyToPutPostMiddleware = true,
    useAuthMiddleware = true,
    useCheckForEmptyBodyMiddleware = true,
  } = options

  // Memoize the client to avoid creating multiple clients with the same options
  const key = JSON.stringify({
    acceptContentType,
    retry,
    requestContentType,
    acceptLanguage,
    baseUrl,
    useSunsetMiddleware,
    useEmptyBodyToPutPostMiddleware,
    useAuthMiddleware,
    useCheckForEmptyBodyMiddleware,
  })
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (memoizedClients.has(key)) return memoizedClients.get(key)! as Client<paths, T>

  // Create client
  const client = createClient<paths, T>({
    baseUrl: baseUrl,
    fetch: retry
      ? fetchRetry(fetch, {
          retries: 5,
          retryDelay: 1000,
          retryOn: async (attempt, error, response) => {
            if (response?.status === 409) return true
            const responseCopy = response?.clone()
            if (responseCopy?.body) {
              try {
                const json = await responseCopy?.json()
                if (json?.detail === 'The optimistic lock on an entity failed.') return true
              } catch (error) {
                return false
              }
            }
            return false
          },
        })
      : fetch,
    headers: {
      Accept: acceptContentType,
      'Accept-Language': acceptLanguage,
      ...(requestContentType && requestContentType !== 'multipart/form-data'
        ? { 'Content-Type': requestContentType }
        : {}),
    },
    bodySerializer(body: unknown) {
      if (body) {
        if (isJson(body as string) || body instanceof FormData) {
          return body
        } else {
          return JSON.stringify(body)
        }
      }
    },
  })

  // Apply middlewares
  if (useSunsetMiddleware) client.use(sunsetMiddleware)
  if (useEmptyBodyToPutPostMiddleware) client.use(addEmptyBodyToPutPostRequestsMiddleware)
  if (useAuthMiddleware) client.use(apiPlatformAuthMiddleware)
  if (useCheckForEmptyBodyMiddleware) client.use(checkForEmptyBodyMiddleware)

  // Save the client in memo
  memoizedClients.set(key, client)
  return client
}

/**
 * @deprecated Please use `apiPlatformClient` instead, this is ONLY to support legacy services and is simply a syntax wrapper for `apiPlatformClient`
 */
export const request = async (
  url: string,
  options?: {
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
    contentType?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: any
    accept?: `${string}/${string}`
    acceptLanguage?: string
    headers?: Record<string, string>
    isLogin?: boolean
    retryConflict?: boolean
    rawResponse?: boolean
    signal?: AbortSignal
  },
  delayHeadersHandler?: (
    obj: {
      xDelay: string
      xDelayRenewalEndpoint: string
      xDelayRenewalToken: string
      xDelayTo: string
    },
    data: object
  ) => void
) => {
  const {
    method,
    contentType,
    body,
    accept,
    acceptLanguage,
    headers,
    retryConflict,
    rawResponse,
    signal,
  } = {
    method: options?.method?.toUpperCase() || 'GET',
    contentType: options?.contentType || 'application/json',
    body: options?.body || null,
    accept: options?.accept || '*/*',
    acceptLanguage: options?.acceptLanguage || i18n?.language || 'en',
    headers: options?.headers || {},
    retryConflict: options?.retryConflict ?? true,
    rawResponse: options?.rawResponse ?? false,
    signal: options?.signal ?? null,
  }

  const client = apiPlatformClient({
    acceptContentType: accept,
    retry: retryConflict,
    requestContentType: contentType,
    acceptLanguage,
  })

  // @ts-expect-error-next-line
  const { data, error, response } = await client[method](
    url.replace(getApiPlatformBaseUrl() as string, '/api'),
    {
      ...(body ? { body } : {}),
      headers: {
        ...headers,
      },
      parseAs: rawResponse ? 'stream' : 'json',
      signal,
    }
  )

  if (error) {
    throw { ...error, status: response?.status }
  }

  if (delayHeadersHandler) {
    const xDelay = response.headers.get('X-Delay')
    const xDelayRenewalEndpoint = response.headers.get('X-Delay-Renewal-Endpoint')
    const xDelayRenewalToken = response.headers.get('X-Delay-Renewal-Token')
    const xDelayTo = response.headers.get('X-Delay-To')
    if (xDelay && xDelayRenewalEndpoint && xDelayRenewalToken && xDelayTo) {
      delayHeadersHandler(
        {
          xDelay,
          xDelayRenewalEndpoint,
          xDelayRenewalToken,
          xDelayTo,
        },
        data
      )
    }
  }

  if (rawResponse) {
    return response
  }

  return data
}
