import React, { useCallback, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

import { ReactComponent as UnPaidIcon } from '@sweetspot/club-portal-legacy/resources/images/TeeTimeIcons/not-paid-icon.svg'

import { colorShade } from '@sweetspot/sweetspot-js/common/functions/utils'
import { hexToRgba } from '@sweetspot/scramble-ds/utils'
import { priceToLocal } from '@sweetspot/shared/util/functions'
import moment from 'moment'
import { TEE_TIME_TEXT_COLORS } from '@sweetspot/shared/util/constants'
import BookingPreviewBox from '../BookingPreviewBox'
import { useHistory } from 'react-router'
import {
  isBookingConfirmed,
  isBookingConsideredPaid,
} from '@sweetspot/sweetspot-js/features/bookings/functions/utils'
import { faCommentExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BookingBox = ({
  booking,
  teeTime,
  height,
  venue,
  editingTeetimes,
  selectionInProgress,
  onMouseEnterBookingBox,
  onMouseLeaveBookingBox,
  shouldHoverStyle,
  space,
  bays,
  isBay,
}) => {
  const { t } = useTranslation()
  const [previewVisible, setPreviewVisible] = useState(false)
  const boxRef = useRef()
  let history = useHistory()

  const handlePreview = useCallback(
    (previewState) => {
      if (editingTeetimes) setPreviewVisible(false)
      if (!editingTeetimes) setPreviewVisible(previewState)
    },
    [editingTeetimes]
  )

  const spaceTotal = useMemo(() => {
    if (!space || !booking?.booking?.booking_participants?.length) return ''

    const total = booking.booking.booking_participants.reduce((accu, participant) => {
      if (
        participant?.tee_time?.space?.uuid === space.id ||
        participant?.tee_time?.space?.id === space.id
      ) {
        return accu + participant.price
      } else {
        return accu
      }
    }, 0)

    return priceToLocal(total, booking?.currency_code, true)
  }, [booking?.booking?.booking_participants, booking?.currency_code, space])

  const isPaid = useMemo(() => {
    return isBookingConsideredPaid(booking)
  }, [booking])

  const categoryColor = useMemo(() => {
    return teeTime.category.color
  }, [teeTime])

  const venueTimeZone = useMemo(() => {
    return venue?.timezone || null
  }, [venue])

  const customer = useMemo(() => {
    return booking?.customer || null
  }, [booking])

  const stubName = useMemo(() => {
    if (!booking?.stub_player) return null
    const { name, email, phone } = booking.stub_player
    return name || email || phone || null
  }, [booking])

  const isReserved = useMemo(() => {
    return (
      (booking?.customer || stubName) &&
      !isBookingConfirmed(booking) &&
      !isBookingConsideredPaid(booking)
    )
  }, [booking, stubName])

  const showExtra = useMemo(() => {
    return height > 54
  }, [height])

  const onMouseEnterStyling = () => {
    if (selectionInProgress) return
    onMouseEnterBookingBox(booking.id)
  }
  const onMouseLeaveStyling = () => {
    onMouseLeaveBookingBox(booking.id)
  }

  const openBooking = () => {
    if (!editingTeetimes) history.push(`/simulators-ranges/orders/${booking.uuid}`)
  }

  return (
    <div
      className={cx(styles.container, selectionInProgress && styles.selectionInProgress)}
      style={{
        height: height,
        backgroundColor: selectionInProgress
          ? `rgba(${hexToRgba(colorShade(categoryColor, 80), 1)})`
          : `rgba(${hexToRgba(categoryColor, 0)})`,
      }}
    >
      <div
        ref={boxRef}
        className={cx(styles.inner, shouldHoverStyle && styles.hoverStyle)}
        style={{
          backgroundColor: shouldHoverStyle
            ? `rgba(${hexToRgba(categoryColor, 0.9)})`
            : categoryColor,
        }}
        onMouseEnter={onMouseEnterStyling}
        onMouseLeave={onMouseLeaveStyling}
        onClick={() => handlePreview(!previewVisible)}
        onDoubleClick={openBooking}
      >
        <div className={cx(styles.headerSection)}>
          <div className={cx(styles.header)}>
            {showExtra && (
              <p
                className={cx(styles.time, 'text-content-xs')}
                style={{ color: TEE_TIME_TEXT_COLORS[categoryColor] }}
              >
                {moment(booking.booking.start_time).tz(venueTimeZone).format('HH:mm')} -{' '}
                {moment(booking.booking.end_time).tz(venueTimeZone).format('HH:mm')}
              </p>
            )}
            <div className={cx(styles.iconsContainer)}>
              {booking.has_notes && (
                <FontAwesomeIcon
                  icon={faCommentExclamation}
                  style={{ color: TEE_TIME_TEXT_COLORS[categoryColor] }}
                />
              )}
              {!isPaid && <UnPaidIcon className={styles.image} />}
            </div>
          </div>
          {showExtra && (
            <p
              className={cx(styles.name, 'text-content-xs font-bold')}
              style={{ color: TEE_TIME_TEXT_COLORS[categoryColor] }}
            >
              {!customer && !stubName && t('sentences.reservedWithoutOwner')}
              {isReserved && `${t('words.reserved_one')}: `}
              {customer && `${customer?.first_name || ''} ${customer?.last_name || ''}`}
              {!customer && stubName && stubName}
            </p>
          )}
        </div>
        <div className={cx(styles.footer, 'text-content-xs font-bold')}>
          <p className={cx(styles.price)} style={{ color: TEE_TIME_TEXT_COLORS[categoryColor] }}>
            {spaceTotal}
          </p>
        </div>

        <BookingPreviewBox
          containerRef={boxRef}
          bookingUuid={booking?.uuid}
          venue={venue}
          visible={previewVisible}
          onClosePreview={() => handlePreview(false)}
          isBay={isBay}
          bays={bays}
        />
      </div>
    </div>
  )
}

BookingBox.propTypes = {
  booking: PropTypes.object,
  teeTime: PropTypes.object,
  height: PropTypes.number,
  venue: PropTypes.object,
  selectionInProgress: PropTypes.bool,
  onMouseEnterBookingBox: PropTypes.func,
  onMouseLeaveBookingBox: PropTypes.func,
  shouldHoverStyle: PropTypes.bool,
}

BookingBox.defaultProps = {
  shouldHoverStyle: false,
  onMouseEnterBookingBox: () => {},
  onMouseLeaveBookingBox: () => {},
}

export default BookingBox
