import { ReactNode, useEffect, useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { faClock, faCopy, faPen } from '@fortawesome/pro-regular-svg-icons'
import { utcToZonedTime, format } from 'date-fns-tz'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { HeaderSettingsOption } from '../../types'

import {
  ListItem,
  ListItemLeading,
  ListItemMainContainer,
  ListItemMainContent,
  ListItemMainLabel,
  ListItemParagraph,
} from '@sweetspot/scramble-ds'
import { Popover } from '@sweetspot/scramble-ds/atoms'
import { Venue } from '@sweetspot/shared/types'
import { getShareLink } from './utils'
import { track } from '@amplitude/analytics-browser'
import { AMPLITUDE_EVENTS } from '@sweetspot/shared/util/constants'
import { useSelector } from 'react-redux'
import {
  getFirstMatchingRole,
  hasAccess,
} from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'

interface CourseHeaderSettingsProps {
  toggleEditTeeTime: () => void
  timezone?: string
  selectedGolfCourse: Venue
  currentDate: Date
}

const CourseHeaderSettings = ({
  toggleEditTeeTime,
  timezone,
  selectedGolfCourse,
  currentDate,
}: CourseHeaderSettingsProps) => {
  const { t, i18n } = useTranslation()
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [shareLink, setShareLink] = useState<string>('')
  const role = useSelector((state) => getFirstMatchingRole(state.auth.roles))

  useEffect(() => {
    let isSubscribed = true
    if (searchValue) setSearchValue('')
    if (selectedGolfCourse) {
      const assignShareLink = async () => {
        const link = await getShareLink(selectedGolfCourse, currentDate, i18n.language)
        if (link && isSubscribed) {
          const baseUrl = window.location.origin
          setShareLink(`${baseUrl}/tee-sheet-links/${link}`)
        }
      }

      assignShareLink()
    }
    return () => {
      isSubscribed = false
    }
  }, [selectedGolfCourse?.uuid, currentDate, i18n.language, searchValue])

  const date = utcToZonedTime(new Date().toISOString(), timezone as string)
  const tz = {
    timeZone: timezone,
  }

  const HEADER_SETTINGS_OPTIONS: HeaderSettingsOption[] = [
    {
      key: 'localTime',
      icon: faClock,
      label: `${t('sentences.localTimeAtVenue')} ${format(date, 'HH:mm', tz)}`,
      subLabel: `(${format(date, 'zzz', tz)}) ${timezone}`,
    },
    ...(hasAccess(ACCESS_KEYS.PAGES.TEE_SHEET.EDIT_TEE_TIMES, role?.value)
      ? [
          {
            key: 'editCategories',
            icon: faPen,
            label: `${t('words.edit')} ${t('sentences.teeTimeCategories').toLowerCase()}`,
            onClick: () => {
              toggleEditTeeTime()
              setIsDropdownOpen(false)
              track(AMPLITUDE_EVENTS.EDIT_TEE_TIMES_TAPPED)
            },
          },
        ]
      : []),

    {
      key: 'copyLink',
      icon: faCopy,
      copyText: shareLink,
      label: isCopied ? t('teeSheet.copied') : t('teeSheet.copyLink'),
      onClick: () => {
        track(AMPLITUDE_EVENTS.COPY_LINK_TO_TEE_SHEET_TAPPED)
      },
    },
  ]

  const maybeWrapWithCopy = (children: ReactNode, option: HeaderSettingsOption) => {
    if (option.copyText) {
      return (
        <CopyToClipboard text={option.copyText} onCopy={() => setIsCopied(true)}>
          {children}
        </CopyToClipboard>
      )
    }
    return children
  }

  return (
    <div className="relative flex h-8 items-center justify-center">
      <Popover.Root open={isDropdownOpen}>
        <Popover.Trigger asChild>
          <div className="cursor-pointer px-4" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <FontAwesomeIcon icon={faEllipsis} size="1x" />
          </div>
        </Popover.Trigger>
        <Popover.Content
          align="end"
          className="p-2 drop-shadow"
          onInteractOutside={() => setIsDropdownOpen(false)}
        >
          {HEADER_SETTINGS_OPTIONS.map((option) =>
            maybeWrapWithCopy(
              <ListItem
                key={option.key}
                className={cx(
                  'text-text-dark w-full min-w-fit cursor-pointer gap-5 px-2 hover:bg-gray-50',
                  option.subLabel ? 'py-2' : 'py-3'
                )}
                onClick={option.onClick}
              >
                <ListItemLeading className="px-0">
                  <FontAwesomeIcon icon={option.icon} />
                </ListItemLeading>
                <ListItemMainContainer>
                  <ListItemMainContent className="py-0">
                    <ListItemMainLabel className="h-auto min-h-6 font-bold">
                      {option.label}
                    </ListItemMainLabel>
                    {option.subLabel && (
                      <ListItemParagraph className="text-text-subtle">
                        {option.subLabel}
                      </ListItemParagraph>
                    )}
                  </ListItemMainContent>
                </ListItemMainContainer>
              </ListItem>,
              option
            )
          )}
          <Popover.Arrow className="fill-white" width={16} height={10} />
        </Popover.Content>
      </Popover.Root>
    </div>
  )
}

export default CourseHeaderSettings
