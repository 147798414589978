import { useEffect } from 'react'
import { getAllFeatureFlags } from '@sweetspot/shared/data-access/api-platform'
import { useFlagsStore } from './useFlagsStore'

export const REFRESH_INTERVAL_MS = 60000 // Refresh interval in milliseconds

/**
 * This hook is used to load the feature flags from the API and store them in the store.
 * It also sets up an interval to refresh the flags every minute.
 * Should only be used once in the app at root level.
 */
export const useFeatureFlagsLoader = () => {
  const { setFlags, setAreFlagsLoading } = useFlagsStore()

  useEffect(() => {
    const fetchFlags = async () => {
      setAreFlagsLoading(true)
      try {
        const data = await getAllFeatureFlags()
        setFlags(data)
      } catch (error) {
        console.error('Error fetching feature flags:', error)
      }
      setAreFlagsLoading(false)
    }

    // Initial fetch
    fetchFlags()

    // Set up the interval for refetching flags every minute
    const intervalId = setInterval(fetchFlags, REFRESH_INTERVAL_MS)

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId)
  }, [setFlags, setAreFlagsLoading]) // Make sure the dependency array is empty to avoid re-setting the interval unnecessarily
}
