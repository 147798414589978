import { EmptyState, EmptyStateTitle } from '@sweetspot/scramble-ds'
import { useTranslation } from 'react-i18next'

export const LoadingView = () => {
  const { t } = useTranslation()

  return (
    <div className="align-center flex h-full w-full flex-row justify-center">
      <EmptyState
        iconClassName="fa-light !bg-background-mono-lighter mb-4 flex rounded-full p-4"
        loading
      >
        <EmptyStateTitle>{t('words.loading')}...</EmptyStateTitle>
      </EmptyState>
    </div>
  )
}
