import {
  createContext,
  useMemo,
  FC,
  PropsWithChildren,
  useContext,
  useState,
  useCallback,
} from 'react'
import {
  AvailabilityAndPricingContextValue,
  SideBarMode,
} from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/types'
import useRanges from '@sweetspot/club-portal-legacy/hooks/useRanges'
import usePeriods from './hooks/usePeriods'
import usePeriodsPricing from './hooks/usePeriodsPricing'
import usePeriodsAdjustments from './hooks/usePeriodsAdjustments'
import { useSelector } from 'react-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import { useQuery } from 'react-query'
import { getTranslatedTeeTimeCategories } from '@sweetspot/sweetspot-js/features/teeTimeCategories/js/getTranslatedTeeTimeCategories'
import useBays from '../Bays/hook/useBays'

export const AvailabilityAndPricingContext = createContext<AvailabilityAndPricingContextValue>({
  categories: [],
  areRangesLoading: false,
  ranges: [],
  refetchRanges: () => {
    return
  },
  arePeriodsLoading: false,
  arePeriodsFetching: false,
  periods: [],
  refetchPeriods: () => {
    return
  },
  selectedPeriod: null,
  setSelectedPeriod: () => {
    return
  },
  selectPeriodByUuid: () => {
    return
  },
  currentCourse: null,
  isSideBarOpen: false,
  toggleSideBar: () => {
    return
  },
  toggleAllPeriodsSideBar: () => {
    return
  },
  toggleCreatePeriodSideBar: () => {
    return
  },
  toggleCreateAdjustmentSideBar: () => {
    return
  },
  changeSideBarMode: () => {
    return
  },
  groupOptions: [],
  adjustments: [],
  bays: [],
  selectedBayIds: [],
  setSelectedBayIds: () => {
    return
  },
  price: 0,
  setPrice: () => {
    return
  },
  pricePerExtraPlayer: 0,
  setPricePerExtraPlayer: () => {
    return
  },
  selectedCells: new Set(),
  allCheckbox: null,
  DAYS_OF_WEEK: [],
  setActivePeriodTab: () => {
    return
  },
  weekPeriodOptions: [],
  timeSlots: [],
  tableData: {},
  daysMap: {},
  onAllCheckboxChange: () => {
    return
  },
  onColumnClick: () => {
    return
  },
  selectedCols: null,
  onRowClick: () => {
    return
  },
  selectedRows: null,
  onCellClick: () => {
    return
  },
  isPricingFetching: false,
  isPricingLoading: false,
  hasPricings: false,
  onPricesApply: () => {
    return
  },
  canSavePrices: false,
  onPricesSubmit: () => {
    return
  },
  onPricesDiscard: () => {
    return
  },
  arePeriodsAdjustmentsLoading: false,
  refetchPeriodAdjustments: () => {
    return
  },
  deletePeriod: () => {
    return Promise.resolve()
  },
  isDeletingPeriod: false,
  isPricingUpdating: false,
})

export const AvailabilityAndPricingProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false)
  const [sideBarMode, setSideBarMode] = useState<SideBarMode | undefined>()
  const [categories, setCategories] = useState([])
  const { ranges, areRangesLoading, selectedRange, setSelectedRange, refetchRanges } = useRanges()
  const { bays } = useBays({ selectedRangeId: selectedRange?.id })
  const golfCourses = useSelector((state: RootState) => state?.golfCourse?.list)

  const currentClubId = useSelector((state: RootState) => state?.golfClub?.selectedId || 0)

  useQuery(['TEE-TIME-CATEGORIES', currentClubId], () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    getTranslatedTeeTimeCategories({ allPages: true, clubId: currentClubId }).then((categories) => {
      setCategories(categories)
    })
  )

  const currentCourse = useMemo(
    () =>
      (!!selectedRange?.external_reference &&
        golfCourses?.find((course) => course?.uuid === selectedRange.external_reference)) ||
      null,
    [golfCourses, selectedRange?.external_reference]
  )

  const {
    periods,
    arePeriodsLoading,
    arePeriodsFetching,
    selectedPeriod,
    setSelectedPeriod,
    refetchPeriods,
    deletePeriod,
    isDeletingPeriod,
  } = usePeriods({ currentCourse })
  const {
    groupOptions,
    selectedBayIds,
    setSelectedBayIds,
    price,
    setPrice,
    pricePerExtraPlayer,
    setPricePerExtraPlayer,
    selectedCells,
    allCheckbox,
    onAllCheckboxChange,
    DAYS_OF_WEEK,
    activePeriodTab,
    setActivePeriodTab,
    weekPeriodOptions,
    timeSlots,
    tableData,
    daysMap,
    onColumnClick,
    selectedCols,
    onRowClick,
    selectedRows,
    onCellClick,
    isPricingFetching,
    isPricingLoading,
    hasPricings,
    onPricesApply,
    canSavePrices,
    onPricesSubmit,
    onPricesDiscard,
    isPricingUpdating,
  } = usePeriodsPricing({
    selectedPeriod,
    currentCourse,
    bays,
    setSelectedPeriod,
    refetchPeriods,
    selectedRange,
  })

  const {
    adjustments,
    arePeriodsAdjustmentsLoading,
    refetch: refetchPeriodAdjustments,
  } = usePeriodsAdjustments({ selectedPeriod })

  const selectPeriodByUuid = useCallback(
    (periodUuid: string) => {
      const period = periods?.find((period) => period.uuid === periodUuid)
      if (period) {
        setSelectedPeriod(period)
      }
    },
    [periods, setSelectedPeriod]
  )

  const toggleSideBar = useCallback(
    (mode?: SideBarMode) => {
      setSideBarMode(isSideBarOpen ? undefined : mode)
      setIsSideBarOpen((prev) => !prev)
    },
    [isSideBarOpen]
  )
  const toggleAllPeriodsSideBar = useCallback(
    () => toggleSideBar(SideBarMode.ALL_PERIODS),
    [toggleSideBar]
  )

  const toggleCreatePeriodSideBar = useCallback(
    () => toggleSideBar(SideBarMode.CREATE_PERIOD),
    [toggleSideBar]
  )

  const toggleCreateAdjustmentSideBar = useCallback(
    () => toggleSideBar(SideBarMode.CREATE_ADJUSTMENT),
    [toggleSideBar]
  )

  const value = useMemo(
    () => ({
      ranges,
      areRangesLoading,
      selectedRange,
      setSelectedRange,
      refetchRanges,
      periods,
      arePeriodsLoading,
      arePeriodsFetching,
      selectedPeriod,
      setSelectedPeriod,
      selectPeriodByUuid,
      refetchPeriods,
      currentCourse,
      isSideBarOpen,
      toggleSideBar,
      toggleAllPeriodsSideBar,
      toggleCreatePeriodSideBar,
      toggleCreateAdjustmentSideBar,
      sideBarMode,
      changeSideBarMode: setSideBarMode,
      categories,
      groupOptions,
      adjustments,
      bays,
      selectedBayIds,
      setSelectedBayIds,
      price,
      setPrice,
      pricePerExtraPlayer,
      setPricePerExtraPlayer,
      selectedCells,
      allCheckbox,
      onAllCheckboxChange,
      DAYS_OF_WEEK,
      activePeriodTab,
      setActivePeriodTab,
      weekPeriodOptions,
      timeSlots,
      tableData,
      daysMap,
      onColumnClick,
      selectedCols,
      onRowClick,
      selectedRows,
      onCellClick,
      isPricingFetching,
      isPricingLoading,
      hasPricings,
      onPricesApply,
      canSavePrices,
      onPricesSubmit,
      onPricesDiscard,
      arePeriodsAdjustmentsLoading,
      refetchPeriodAdjustments,
      deletePeriod,
      isDeletingPeriod,
      isPricingUpdating,
    }),
    [
      ranges,
      areRangesLoading,
      selectedRange,
      setSelectedRange,
      refetchRanges,
      periods,
      arePeriodsLoading,
      arePeriodsFetching,
      selectedPeriod,
      setSelectedPeriod,
      selectPeriodByUuid,
      setSideBarMode,
      refetchPeriods,
      currentCourse,
      isSideBarOpen,
      toggleSideBar,
      toggleAllPeriodsSideBar,
      toggleCreatePeriodSideBar,
      toggleCreateAdjustmentSideBar,
      sideBarMode,
      categories,
      groupOptions,
      adjustments,
      bays,
      selectedBayIds,
      setSelectedBayIds,
      price,
      setPrice,
      pricePerExtraPlayer,
      setPricePerExtraPlayer,
      selectedCells,
      allCheckbox,
      onAllCheckboxChange,
      DAYS_OF_WEEK,
      activePeriodTab,
      setActivePeriodTab,
      weekPeriodOptions,
      timeSlots,
      tableData,
      daysMap,
      onColumnClick,
      selectedCols,
      onRowClick,
      selectedRows,
      onCellClick,
      isPricingFetching,
      isPricingLoading,
      hasPricings,
      onPricesApply,
      canSavePrices,
      onPricesSubmit,
      onPricesDiscard,
      arePeriodsAdjustmentsLoading,
      refetchPeriodAdjustments,
      deletePeriod,
      isDeletingPeriod,
      isPricingUpdating,
    ]
  )

  return (
    <AvailabilityAndPricingContext.Provider value={value}>
      {children}
    </AvailabilityAndPricingContext.Provider>
  )
}

export const useAvailabilityAndPricingContext = () => useContext(AvailabilityAndPricingContext)
