import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

import {
  Input,
  InputBase,
  InputContainer,
  InputHint,
  InputLabelContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  InputTrailingContainer,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  Textarea,
  TextareaContainer,
  TextareaCounter,
} from '@sweetspot/scramble-ds'
import { Button, Divider, Toggle } from '@sweetspot/scramble-ds/atoms'
import {
  getNonConfirmedBookingDurationOptions,
  getUnpaidBookingDurationOptions,
  LAST_CANCELLATION_POINT,
} from '@sweetspot/sweetspot-js/common/constants/autoCancelSettingsOptions'
import ButtonDock from '@sweetspot/sweetspot-js/common/components/ButtonDock/ButtonDock'
import { GolfCourse } from '@sweetspot/shared/types'
import useRoutingBlock from '@sweetspot/club-portal-legacy/hooks/useRoutingBlock'
import { MAX_CHARS_IMPORTANT_BOOKING_INFO } from '@sweetspot/shared/util/constants'

import { ExtrasTabs, UpdateExtraData } from '../../types'
import useUpdateExtras from '../../hooks/useUpdateExtras'
import { useManageExtrasContext } from '../../ManageExtrasProvider'

type BookingFormFields = Pick<
  UpdateExtraData,
  | 'displayTeeTimesDays'
  | 'bookingCancellationLimitHours'
  | 'nonConfirmedBookingAutoCancellationEnabled'
  | 'nonConfirmedBookingAutoCancellationDuration'
  | 'unpaidBookingAutoCancellationEnabled'
  | 'unpaidBookingAutoCancellationDuration'
  | 'unpaidBookingAutoCancellationDays'
  | 'bookingInformation'
  | 'importantBookingInformation'
  | 'customEmailInformation'
>

type BookingTabProps = {
  currentCourse?: GolfCourse
  refetchCourses: () => void
}

const BookingTab = ({ currentCourse, refetchCourses }: BookingTabProps) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const {
    updateExtras,
    bookingInitialData: initialData,
    checkForErrors,
  } = useUpdateExtras({ currentCourse })
  const { setDiscardDialogCallbacks, setOpenDiscardDialog } = useManageExtrasContext()
  const { register, watch, setValue, formState, reset } = useForm<BookingFormFields>({
    defaultValues: initialData,
  })
  const formValues = watch()
  const isDirty = formState?.isDirty

  const onSubmit = useCallback(() => {
    const errorText = checkForErrors(ExtrasTabs.BOOKING, formValues)
    if (errorText) {
      addToast(t(errorText), { appearance: 'error' })
    } else {
      updateExtras(formValues)?.then(() => {
        addToast(t('toast.courseUpdated', { type: t('words.extras_one') }), {
          appearance: 'success',
        })
        refetchCourses()
        reset(formValues)
      })
    }
  }, [formValues, updateExtras, reset, checkForErrors, t, addToast, refetchCourses])

  // block navigation when form is dirty
  useRoutingBlock({
    isDirty,
    onReset: reset,
    onSubmit,
    setDiscardDialogCallbacks,
    setOpenDiscardDialog,
  })

  // reset form initialData
  useEffect(() => {
    reset(initialData)
  }, [initialData, reset])

  const NON_CONFIRMED_BOOKING_DURATION_OPTIONS = useMemo(
    () => getNonConfirmedBookingDurationOptions(),
    []
  )
  const UNPAID_BOOKING_DURATION_OPTIONS = useMemo(() => getUnpaidBookingDurationOptions(), [])
  const unpaidBookingAutoCancellationDays = useMemo(() => {
    const durationValue = String(formValues.unpaidBookingAutoCancellationDays).split('.')[0]
    return durationValue
  }, [formValues.unpaidBookingAutoCancellationDays])
  const lastCancelPointOptions: { value: number; label: string }[] = useMemo(() => {
    return LAST_CANCELLATION_POINT.map((option) => ({
      ...option,
      label: t(option.label.translation, option.label.options),
    }))
  }, [t])

  const handleUnpaidBookingAutoCancellationDurationChange = useCallback(
    (newValue: string) => {
      if (newValue) {
        setValue('unpaidBookingAutoCancellationDays', '', { shouldDirty: true })
      }
      setValue('unpaidBookingAutoCancellationDuration', +newValue, { shouldDirty: true })
    },
    [setValue]
  )

  const handleUnpaidBookingAutoCancellationDaysChange = useCallback(
    (value: string) => {
      let durationValue = value.split('.')[0]
      if (+durationValue > 90) {
        durationValue = '90'
      }
      setValue('unpaidBookingAutoCancellationDays', durationValue, { shouldDirty: true })
      setValue('unpaidBookingAutoCancellationDuration', 0, { shouldDirty: true })
    },
    [setValue]
  )

  const handleUnpaidBookingAutoCancellationDaysBlur = useCallback(() => {
    const durationValue = formValues.unpaidBookingAutoCancellationDays || ''
    setValue('unpaidBookingAutoCancellationDays', Math.max(1, +durationValue), {
      shouldDirty: true,
    })
  }, [formValues.unpaidBookingAutoCancellationDays, setValue])

  return (
    <form onSubmit={(e) => e.preventDefault()} className="flex max-w-[456px] flex-col gap-6">
      <InputBase>
        <InputLabelContainer className="mb-0.5">
          {t('settings.displayDaysLabel')}
        </InputLabelContainer>
        <InputContainer>
          <Input {...register('displayTeeTimesDays')} className="text-content-base h-8 border" />
        </InputContainer>
        <InputHint
          className="[&_i]:text-content-xs [&_:first-child]:-mt-[1px] [&_:first-child]:w-auto"
          variant="default"
          hintText={t('settings.displayDaysHint')}
        />
      </InputBase>

      <Divider />

      <InputSelect
        onValueChange={(newValue) =>
          setValue('bookingCancellationLimitHours', +newValue, {
            shouldDirty: true,
          })
        }
      >
        <InputBase>
          <InputLabelContainer className="mb-0.5">
            {t('settings.lastPointOfCancellationLabel')}
          </InputLabelContainer>
          <InputSelectTrigger asChild>
            <InputContainer>
              <InputSelectStatefulInput
                className="text-content-base h-8 border"
                value={
                  lastCancelPointOptions.find(
                    (option) =>
                      String(option.value) === String(formValues.bookingCancellationLimitHours)
                  )?.label
                }
              />
              <InputSelectTrailingContainer />
            </InputContainer>
          </InputSelectTrigger>
          <InputHint
            className="[&_i]:text-content-xs [&_:first-child]:-mt-[1px] [&_:first-child]:w-auto"
            variant="default"
            hintText={t('settings.lastPointOfCancellationHint')}
          />
        </InputBase>

        <InputSelectContent>
          <InputSelectContentView>
            {lastCancelPointOptions.map((option) => (
              <InputSelectItem key={option.value} value={String(option.value)}>
                <ListItem className="ml-2 h-full justify-center gap-2">
                  <ListItemMainContent className="justify-center">
                    <ListItemParagraph className="text-content-base">
                      {option.label}
                    </ListItemParagraph>
                  </ListItemMainContent>
                </ListItem>
              </InputSelectItem>
            ))}
          </InputSelectContentView>
        </InputSelectContent>
      </InputSelect>

      <Divider />

      <div className="flex min-h-12 items-center gap-4">
        <div>
          <label className="text-text-dark text-content-sm px-md font-bold">
            {t('settings.autoCancellationUnconfirmedBookngsLabel')}
          </label>
          <div className="text-text-subtle text-content-sm pl-2">
            {t('settings.autoCancellationUnconfirmedBookngsHint')}
          </div>
        </div>
        <Toggle
          checked={formValues.nonConfirmedBookingAutoCancellationEnabled}
          onCheckedChange={(checked) =>
            setValue('nonConfirmedBookingAutoCancellationEnabled', checked, { shouldDirty: true })
          }
        />
      </div>

      {formValues.nonConfirmedBookingAutoCancellationEnabled && (
        <InputSelect
          onValueChange={(newValue) =>
            setValue('nonConfirmedBookingAutoCancellationDuration', +newValue, {
              shouldDirty: true,
            })
          }
        >
          <InputBase>
            <InputLabelContainer className="mb-0.5">
              {t('settings.autoCancellationTimerLabel')}
            </InputLabelContainer>
            <InputSelectTrigger asChild>
              <InputContainer>
                <InputSelectStatefulInput
                  className="text-content-base h-8 border"
                  value={
                    NON_CONFIRMED_BOOKING_DURATION_OPTIONS.find(
                      (x) =>
                        String(x.value) ===
                        String(formValues.nonConfirmedBookingAutoCancellationDuration)
                    )?.label || ''
                  }
                />
                <InputSelectTrailingContainer />
              </InputContainer>
            </InputSelectTrigger>
          </InputBase>

          <InputSelectContent>
            <InputSelectContentView>
              {NON_CONFIRMED_BOOKING_DURATION_OPTIONS.map((option) => (
                <InputSelectItem key={option.value} value={String(option.value || 0)}>
                  <ListItem className="ml-2 h-full justify-center gap-2">
                    <ListItemMainContent className="justify-center">
                      <ListItemParagraph className="text-content-base">
                        {option.label}
                      </ListItemParagraph>
                    </ListItemMainContent>
                  </ListItem>
                </InputSelectItem>
              ))}
            </InputSelectContentView>
          </InputSelectContent>
        </InputSelect>
      )}

      <Divider />

      <div className="flex min-h-12 items-center gap-4">
        <div>
          <label className="text-text-dark text-content-sm px-md font-bold">
            {t('settings.autoCancellationUnpaidBookngsLabel')}
          </label>
          <div className="text-text-subtle text-content-sm pl-2">
            {t('settings.autoCancellationUnpaidBookngsHint')}
          </div>
        </div>
        <Toggle
          checked={formValues.unpaidBookingAutoCancellationEnabled}
          onCheckedChange={(checked) =>
            setValue('unpaidBookingAutoCancellationEnabled', checked, { shouldDirty: true })
          }
        />
      </div>

      {formValues.unpaidBookingAutoCancellationEnabled && (
        <>
          <InputSelect onValueChange={handleUnpaidBookingAutoCancellationDurationChange}>
            <InputBase>
              <InputLabelContainer className="mb-0.5">
                {t('settings.autoCancellationTimerLabel')}
              </InputLabelContainer>
              <InputSelectTrigger asChild>
                <InputContainer>
                  <InputSelectStatefulInput
                    className="text-content-base h-8 border"
                    value={
                      UNPAID_BOOKING_DURATION_OPTIONS.find(
                        (x) =>
                          String(x.value) ===
                          String(formValues.unpaidBookingAutoCancellationDuration)
                      )?.label || t('dateTime.words.custom')
                    }
                  />
                  <InputSelectTrailingContainer />
                </InputContainer>
              </InputSelectTrigger>
            </InputBase>

            <InputSelectContent>
              <InputSelectContentView>
                {UNPAID_BOOKING_DURATION_OPTIONS.map((option) => (
                  <InputSelectItem key={option.value} value={String(option.value || 0)}>
                    <ListItem className="ml-2 h-full justify-center gap-2">
                      <ListItemMainContent className="justify-center">
                        <ListItemParagraph className="text-content-base">
                          {option.label}
                        </ListItemParagraph>
                      </ListItemMainContent>
                    </ListItem>
                  </InputSelectItem>
                ))}
              </InputSelectContentView>
            </InputSelectContent>
          </InputSelect>

          {!formValues.unpaidBookingAutoCancellationDuration && (
            <InputBase>
              <InputLabelContainer className="mb-0.5">
                {t('settings.autoCancellationCustomTimerLabel')}
              </InputLabelContainer>
              <InputContainer>
                <Input
                  type="number"
                  className="text-content-base h-8 border"
                  value={unpaidBookingAutoCancellationDays}
                  onChange={(event) =>
                    handleUnpaidBookingAutoCancellationDaysChange(event.target.value)
                  }
                  onBlur={handleUnpaidBookingAutoCancellationDaysBlur}
                  min={1}
                  max={90}
                />
                <InputTrailingContainer>
                  {unpaidBookingAutoCancellationDays === '1'
                    ? t('dateTime.words.day_one')
                    : t('days')}
                </InputTrailingContainer>
              </InputContainer>
              <InputHint
                className="[&_i]:text-content-xs [&_:first-child]:-mt-[1px] [&_:first-child]:w-auto"
                variant="default"
                hintText={t('settings.autoCancellationCustomTimerHint')}
              />
            </InputBase>
          )}
        </>
      )}

      <Divider />

      <InputBase>
        <InputLabelContainer className="mb-0.5">{t('settings.bookingInfo')}</InputLabelContainer>
        <TextareaContainer>
          <Textarea
            {...register('bookingInformation')}
            max={undefined}
            min={undefined}
            className="text-content-base border"
          />
        </TextareaContainer>
        <InputHint
          className="[&_i]:text-content-xs [&_:first-child]:-mt-[1px] [&_:first-child]:w-auto"
          variant="default"
          hintText={t('settings.bookingInformationHint', {
            type: t('words.extras_one').toLowerCase(),
          })}
        />
      </InputBase>

      <Divider />

      <InputBase>
        <InputLabelContainer className="mb-0.5">
          {t('settings.importantBookingInfo')}
        </InputLabelContainer>
        <TextareaContainer maxCharacters={MAX_CHARS_IMPORTANT_BOOKING_INFO}>
          <TextareaCounter />
          <Textarea
            {...register('importantBookingInformation')}
            max={MAX_CHARS_IMPORTANT_BOOKING_INFO}
            min={0}
            className="text-content-base border"
            value={formValues.importantBookingInformation}
            strictLength={false}
          />
        </TextareaContainer>
        <InputHint
          className="[&_i]:text-content-xs [&_:first-child]:-mt-[1px] [&_:first-child]:w-auto"
          variant="default"
          hintText={t('settings.importantBookingInformationHint')}
        />
      </InputBase>

      <Divider />

      <InputBase>
        <InputLabelContainer className="mb-0.5">
          {t('settings.customBookingInformationEmailLabel')}
        </InputLabelContainer>
        <TextareaContainer>
          <Textarea
            {...register('customEmailInformation')}
            max={undefined}
            min={undefined}
            className="text-content-base border"
          />
        </TextareaContainer>
        <InputHint
          className="[&_i]:text-content-xs [&_:first-child]:-mt-[1px] [&_:first-child]:w-auto"
          variant="default"
          hintText={t('settings.customBookingInformationEmailHint')}
        />
      </InputBase>

      <ButtonDock className="max-w-[480px]" dockClassName="left-[15px] px-0" isShown={isDirty}>
        <Button variant="ghost-dark" size="small" type="button" onClick={() => reset()}>
          {t('words.cancel')}
        </Button>
        <Button variant="primary" size="small" onClick={onSubmit}>
          {t('words.save')}
        </Button>
      </ButtonDock>
    </form>
  )
}

export default BookingTab
