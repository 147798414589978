import { getLang } from '@sweetspot/club-portal-legacy/languages'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'

import { sortMemberships } from './utils'
import { Facts, Player } from '@sweetspot/shared/types'
import { i18n } from 'i18next'
import { GolfClubState } from '@sweetspot/club-portal-legacy/store/types'
import { TablePlayer } from './types'

export const mapPlayersData = (
  playersData: Player[],
  i18n: i18n,
  golfClubs: GolfClubState
): TablePlayer[] => {
  if (!playersData.length) {
    return [{ id: -1, name: getLang(i18n.language)['noSearchResults'], hcp: 0 }]
  }

  const findPlayersFacts = (facts: Facts[]) => {
    if (!facts || !facts.length) return null
    return facts.find((x) => x.club.id === golfClubs.selectedId) || null
  }

  return playersData.map((player) => {
    const name = `${player.first_name} ${player.last_name}`
    const membership = sortMemberships(player.shown_memberships)?.filter(
      (m) => m?.membership?.club?.id === golfClubs.selectedId
    )

    const federationMembership = player.show_player_membership_to_assign
      ? player.show_player_membership_to_assign.membership_name
      : '-'
    const homeClub = player.home_club ? player.home_club.name : null
    const facts = findPlayersFacts(player.facts)
    const lastPlayed = facts?.last_played
      ? DateHelpers.onlyDateString(new Date(facts.last_played))
      : '-'

    return {
      id: player.id,
      uuid: player.uuid,
      name,
      email: player.email,
      phone: player.phone,
      golfId: player.golf_id ? player.golf_id : '-',
      cdhId: player.cdh_id ? player.cdh_id : '-',
      age: player.age ? player.age.toString() : '-',
      hcp: Utilities.parseHCP(player.hcp),
      lockState: player?.first_usable_membership_by_priority?.is_locked,
      membership,
      federationMembership,
      playRight: player.has_play_right
        ? getLang(i18n.language)['yes']
        : getLang(i18n.language)['no'],
      homeClub,
      isRegistered: player.is_registered
        ? getLang(i18n.language)['yes']
        : getLang(i18n.language)['no'],
      gender: player.gender ? getLang(i18n.language)['settings']['playerCard'][player.gender] : '-',
      playValue: facts ? Math.round(facts.play_value / 100) : '-',
      numberOfRounds: facts ? facts.number_of_rounds : '-',
      paidValue: facts ? Math.round(facts.paid_value / 100) : '-',
      lastPlayed,
    }
  })
}
