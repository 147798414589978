import { useQuery } from 'react-query'
import { _getPeriodOverrides } from '@sweetspot/club-portal-legacy/services/bookingPeriodApi'
import { Period } from '@sweetspot/shared/types'

type UsePeriodsAdjustmentsProps = {
  selectedPeriod: Period | null
}

const usePeriodsAdjustments = ({ selectedPeriod }: UsePeriodsAdjustmentsProps) => {
  const {
    data: adjustments = [],
    isLoading: arePeriodsAdjustmentsLoading,
    refetch,
  } = useQuery(
    ['PERIOD_OVERRIDES', selectedPeriod?.id],
    () => _getPeriodOverrides(selectedPeriod?.id, 'limit=1000&order[created_at]=desc'),
    {
      enabled: !!selectedPeriod?.id,
    }
  )

  return {
    adjustments,
    arePeriodsAdjustmentsLoading,
    refetch,
  }
}

export default usePeriodsAdjustments
