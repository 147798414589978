import { Button } from '@sweetspot/scramble-ds/atoms'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { useIsMobile } from '../../../../hooks/useIsMobile'
import { useResourcesStore } from '../../../../hooks/resources/useResourcesStore'

const BackButton = () => {
  const isMobile = useIsMobile()
  const { selectedResource, setSelectedResource } = useResourcesStore()
  if (!isMobile || !selectedResource) return null

  return (
    <Button className="p-4" variant="link-dark" onClick={() => setSelectedResource(null)}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </Button>
  )
}

export default BackButton
