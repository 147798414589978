import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { track } from '@amplitude/analytics-browser'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import { useHistory, useParams } from 'react-router'
import { useQuery } from 'react-query'
import { differenceInHours, format, isPast, isValid } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import {
  cancelOrderBooking,
  confirmOrderBooking,
  removeSpacesFromBooking,
  setBookingOwner,
  markBookingAsPaid,
  updateBookingPayOnSiteStatus,
  addStubPlayerToOrder,
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import AnimatedLine from '@sweetspot/sweetspot-js/common/components/AnimatedLine'
import OrderSummaryBox from './components/OrderSummaryBox'
import OrderSummaryFooter from './components/OrderSummaryFooter'
import SummaryOrderRows from './components/SummaryOrderRows'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import produce from 'immer'
import {
  isBookingCancelled,
  isBookingConfirmed,
  isBookingConsideredPaid,
  getPlayerName,
  isBookingPartiallyPaid,
  areTeeTimesWithPPPModified,
  hasPromotionApplied,
  isAnyPaymentProcessingState,
} from '@sweetspot/sweetspot-js/features/bookings/functions/utils'
import { useToasts } from 'react-toast-notifications'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import ModalSimple from '@sweetspot/sweetspot-js/ui-kit/components/ModalSimple'
import ConfirmCancelModalWrapper from './components/ConfirmCancelModalWrapper'
import { getSessionStorage, clearSessionStorage } from '@sweetspot/shared/util/session-storage'
import { throttle } from 'lodash'
import { renewDelayedMessage } from '@sweetspot/sweetspot-js/features/messages/services/api-platform'
import QTAccessCode from './components/QTAccessCode'
import getBookingWithRelations from '@sweetspot/sweetspot-js/features/bookings/functions/getBookingWithRelations'
import {
  BOOKING_GROUPS,
  BOOKING_RELATIONS,
} from '@sweetspot/sweetspot-js/features/bookings/constants/bookingRelations'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'
import { ReactComponent as WarningIcon } from '@sweetspot/sweetspot-js/assets/svgs/warning-icon.svg'
import { ReactComponent as TrashIcon } from '@sweetspot/sweetspot-js/assets/svgs/trash-icon.svg'
import { ITEM_GROUPS } from '@sweetspot/sweetspot-js/features/bookings/constants/itemRelations'
import { BookingTitles, SpacesStep, DetailsStep } from './components/BookingSteps'
import { MoveBookingStep } from './components/BookingSteps/components/steps/Move'
import {
  getCourseById,
  getMoveAvailability,
  moveBooking,
  setNumberOfExtraPlayers,
  createBookingDispense,
  queryBookingDispenses,
} from '@sweetspot/shared/data-access/api-platform'
import { buttonsTypes } from '@sweetspot/club-portal-legacy/constants/config'
import AddButtonsList from '@sweetspot/club-portal-legacy/pages/SimulatorsRangesOrder/components/AddButtonsList'
import AppliedActionList from '@sweetspot/club-portal-legacy/pages/SimulatorsRangesOrder/components/AppliedActionList'
import { AMPLITUDE_EVENTS } from '@sweetspot/shared/util/constants'
import {
  ActionType,
  ExtraPlayerProvider,
  useExtraPlayerContext,
  useExtraPlayerDispatch,
  useExtraPlayerState,
} from '@sweetspot/sweetspot-js/features/bookings/functions/extraPlayerProvider'
import PropTypes from 'prop-types'
import { ConfirmChangeModal } from '@sweetspot/sweetspot-js/features/bookings/components/ConfirmChangeModal'
import { BookingSteps } from '@sweetspot/club-portal-legacy/pages/SimulatorsRangesOrder/types'
import { Button } from '@sweetspot/scramble-ds/atoms'
import { useSelector } from 'react-redux'
import { QUERY_KEYS } from '@sweetspot/shared/util/constants'
import { queryRanges } from '@sweetspot/sweetspot-js/features/ranges/services'
import { queryBays } from '@sweetspot/sweetspot-js/features/bays/services'
import QRCodeSideBar from './components/QRCodeSideBar/QRCodeSideBar'
import BallDispensedDialog from './components/BallDispensedDialog/BallDispensedDialog'
import { FlagNames, useFlag } from '@sweetspot/shared/util/feature-flag'
import { AddSubscriptionSelect } from './components/AddSubscriptionSelect'

let mouseMoveThrottle = null

const SimulatorsRangesOrder = ({ bookingUuid }) => {
  /** --------------------- */
  /** Hooks */
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const history = useHistory()
  const isIncludeBallsInBookingEnabled = useFlag(FlagNames.IncludeBallsInBooking)

  /** --------------------- */
  /** States */
  const [state, setState] = useMergeState({
    selectedSpaces: [],
    isAssigningOwner: false,
    isConfirming: false,
    isCanceling: false,
    isMarkingAsPaid: false,
    isCheckingAvailability: false,
    isMoving: false,
  })
  const [addedSpaces, setSpaces] = useState([])
  const [playerData, setPlayerData] = useState({})
  const [bookingViolations, setBookingViolations] = useState([])
  const [showCancelAndRefundConfirm, setShowCancelAndRefundConfirm] = useState(false)
  const [showPaymentOrRefundRequiredConfirm, setShowPaymentOrRefundRequiredConfirm] =
    useState(false)
  const [showUnsavedChangesAlertModal, setShowUnsavedChangesAlertModal] = useState(false)
  const [removingSpace, setRemoveingSpace] = useState(false)
  const [activeBookingStep, setActiveBookingStep] = useState(BookingSteps.BOOKING_DETAILS)
  const [isBookSpaceClicked, setBookSpaceBtnState] = useState(false)
  const [selectedSpacesFromEditTeeTime, setNewSelectedSpaces] = useState([])
  const [editBookingState, setEditBookingState] = useState('notActive')
  const [isBookSpaceBtnDisabled, setBookSpaceBtnDisabled] = useState(true)
  const [bookSpaceBtnLoading, setBookSpaceBtnLoadingState] = useState(false)
  const [showConfirmCancelBooking, setShowConfirmCancelBooking] = useState(false)
  const [date, setDate] = useState(new Date())
  const [time, setTime] = useState('')
  const [availableSpaces, setAvailableSpaces] = useState([])
  const [selectedSpacesForMoving, setSelectedSpacesForMoving] = useState([])
  const [availabilityErrors, setAvailabilityErrors] = useState([])
  const [moveBookingErrors, setMoveBookingErrors] = useState([])
  const [showQRCodeSidebar, setShowQRCodeSidebar] = useState(false)
  const [showBallDispensedDialog, setShowBallDispensedDialog] = useState(false)
  const [bookingDispense, setBookingDispense] = useState({})
  const extraPlayerState = useExtraPlayerState()
  const extraPlayerContext = useExtraPlayerContext()
  const extraPlayerDispatch = useExtraPlayerDispatch()
  const golfClub = useSelector((state) =>
    state?.golfClub?.list?.find((club) => club.id === state?.golfClub?.selectedId)
  )

  /** --------------------- */
  /** Queries */
  const {
    data: orderBooking,
    isFetching: orderBookingIsFetching,
    isError: orderBookingIsError,
    refetch: orderBookingRefetch,
    status: orderBookingStatus,
  } = useQuery(
    [CLUB_QUERIES.ORDER_BOOKING, bookingUuid],
    () =>
      getBookingWithRelations({
        bookingUuid,
        deserialize: true,
        includedRelations: [BOOKING_RELATIONS.ALL],
        includedBookingGroups: [BOOKING_GROUPS.ALL],
        includedItemGroups: [ITEM_GROUPS.ALL],
      }),
    {
      enabled: !!bookingUuid,
      onSuccess: (data) => {
        if (data?.spaces?.length && !state.selectedSpaces?.length) {
          setState(
            produce((draft) => {
              draft.selectedSpaces = data.spaces.map((space) => space.id)
            })
          )
        }

        if (editBookingState && editBookingState !== 'notActive') {
          setBookSpaceBtnLoadingState(false)
          setActiveBookingStep(BookingSteps.BOOKING_DETAILS)
          addToast(t('Booking updated successfully'), { appearance: 'success' })
        }
      },
      refetchOnWindowFocus: true,
    }
  )

  const {
    data: venue,
    isFetching: venueIsFetching,
    status: venueStatus,
    isFetched: isVenueFetched,
  } = useQuery(
    [CLUB_QUERIES.COURSE, orderBooking?.course.id],
    () => getCourseById(orderBooking?.course.id),
    {
      enabled: !!orderBooking,
    }
  )

  const { data: bayRange, isFetched: isBayRangesFetched } = useQuery(
    [QUERY_KEYS.RANGES, golfClub?.id],
    async () => {
      return queryRanges({ organizationId: golfClub.uuid })
    },
    {
      enabled: !!golfClub?.uuid && !!venue?.belongs_to_range_context,
      select: (data) => {
        return data?.ranges.find((r) => r.external_reference === venue?.uuid)
      },
    }
  )

  const isNewRange = useMemo(() => {
    return !!bayRange
  }, [bayRange])

  const isDispenseComplete = useMemo(() => {
    return isIncludeBallsInBookingEnabled && bookingDispense?.isCompleted
  }, [bookingDispense, isIncludeBallsInBookingEnabled])

  const { data: bays, isFetched: isBaysFetched } = useQuery(
    [CLUB_QUERIES.BAYS, { course: venue?.id }],
    () => queryBays({ drivingRangeId: bayRange?.id, bookable: true }),
    {
      enabled: !!venue?.id && !!isBayRangesFetched && !!isNewRange && !!isVenueFetched,
      select: (data) =>
        data?.data?.map((item) => ({
          ...item,
          name: `${t('words.bay')} ${item.bay_number}`,
          uuid: item.id,
        })),
    }
  )

  const orderBays = useMemo(() => {
    return bays?.filter((bay) => orderBooking?.spaces.find((space) => bay.uuid === space.uuid))
  }, [bays, orderBooking?.spaces])

  const orderBookingSpaces = useMemo(() => {
    if (orderBays?.length) {
      return orderBooking?.spaces?.map((space) => ({
        ...space,
        name: orderBays.find((bay) => bay.uuid === space.uuid)?.name,
      }))
    }
    return orderBooking?.spaces
  }, [orderBays, orderBooking?.spaces])

  const handleOrderBookingRefetch = async () => {
    orderBookingRefetch()
    extraPlayerContext?.calculatePrice()
  }

  /** --------------------- */
  /** Memos, effects etc */
  const hasCustomer = useMemo(() => {
    return orderBooking?.customer ? true : false
  }, [orderBooking])

  const hasStubCustomer = useMemo(() => {
    if (!orderBooking?.stub_player) return false

    const { name, email, phone } = orderBooking.stub_player

    return name || email || phone ? true : false
  }, [orderBooking])

  const isConfirmed = useMemo(() => {
    if (orderBooking) {
      return isBookingConfirmed(orderBooking)
    }
    return false
  }, [orderBooking])

  const isConsideredPaid = useMemo(() => {
    if (orderBooking) {
      return isBookingConsideredPaid(orderBooking)
    }
    return false
  }, [orderBooking])

  const isPartiallyPaid = useMemo(() => {
    if (orderBooking) return isBookingPartiallyPaid(orderBooking)
    return null
  }, [orderBooking])

  const isCancelled = useMemo(() => {
    if (orderBooking) {
      return isBookingCancelled(orderBooking)
    }
    return false
  }, [orderBooking])

  const isProcessing = useMemo(
    () => isAnyPaymentProcessingState(orderBooking?.payments),
    [orderBooking?.payments]
  )

  const bookingCancellationLimitHours = useMemo(() => {
    return isConsideredPaid || isConfirmed
      ? differenceInHours(
          new Date(orderBooking?.booking?.start_time),
          new Date(orderBooking?.booking?.last_cancellation_point_at)
        )
      : orderBooking?.course.booking_cancellation_limit_hours
  }, [isConfirmed, isConsideredPaid, orderBooking])

  const getSelectedSpaces = () => {
    if (selectedSpacesFromEditTeeTime.length) {
      const spaceIds = selectedSpacesFromEditTeeTime.map((space) => space.id)
      return spaceIds
    } else {
      return state.selectedSpaces
    }
  }

  useEffect(() => {
    setSpaces(orderBookingSpaces)
    if (isConsideredPaid) setRemoveingSpace(true)
  }, [isConsideredPaid, orderBookingSpaces])

  useEffect(() => {
    if (isConfirmed) setActiveBookingStep(BookingSteps.BOOKING_DETAILS)
  }, [isConfirmed])

  useEffect(() => {
    if (activeBookingStep === BookingSteps.BOOKING_DETAILS) setBookSpaceBtnState(false)
    if (activeBookingStep === BookingSteps.EDIT_SPACES) setEditBookingState('notActive')

    setBookSpaceBtnLoadingState(false)
  }, [activeBookingStep])

  useEffect(() => {
    if (editBookingState !== 'notActive') {
      if (editBookingState) {
        // set sapces for details step if only new (added or removed) space(s) confirmed
        if (selectedSpacesFromEditTeeTime.length) {
          setSpaces(selectedSpacesFromEditTeeTime)
        }
      } else {
        addToast(t('sentences.failedToReserveBooking'), { appearance: 'error' })
        setEditBookingState('notActive')
      }
    }
  }, [editBookingState])

  useEffect(() => {
    if (!isBookSpaceBtnDisabled) {
      setBookSpaceBtnState(false)
    }
  }, [isBookSpaceBtnDisabled])

  // Fetch booking dispense if belongs_to_range_context and free balls are counfigured
  useEffect(() => {
    const fetchDispense = async () => {
      const [dispenseRes] = await to(
        queryBookingDispenses({ orderId: orderBooking.uuid, rangeId: bayRange.id })
      )
      if (dispenseRes?.booking_dispense) {
        setBookingDispense({
          qrCode: dispenseRes.booking_dispense.qr_code,
          isCompleted: dispenseRes.booking_dispense.is_completed,
        })
      }
    }

    if (
      isIncludeBallsInBookingEnabled &&
      isConfirmed &&
      orderBooking?.course?.belongs_to_range_context &&
      bayRange?.id &&
      bayRange?.free_balls_count
    ) {
      fetchDispense()
    }
  }, [isConfirmed, orderBooking, bayRange, isIncludeBallsInBookingEnabled])

  /** --------------------- */
  /** Functions */
  const goBack = (force = false) => {
    if (!force && extraPlayerState?.calculatedPriceResult?.is_modified) {
      setShowUnsavedChangesAlertModal(true)
    } else {
      handleGoBack()
    }
  }

  const handleGoBack = () => {
    setActiveBookingStep('')
    if (showUnsavedChangesAlertModal) {
      setShowUnsavedChangesAlertModal(false)
    }
    history.goBack()
  }

  const handleRemoveSpace = async (spaceId, spaceUuid) => {
    if (addedSpaces.length > 1) {
      setRemoveingSpace(true)
      const [, err] = await to(
        removeSpacesFromBooking(orderBooking.booking.id, {
          data: [{ space_uuid: spaceUuid }],
        })
      )

      if (!err) {
        const tempState = addedSpaces.filter((space) => space.id !== spaceId)
        if (tempState.length > 1) {
          tempState.sort((space1, space2) => {
            if (space1.id > space2.id) {
              return 1
            } else {
              return -1
            }
          })
        }
        setSpaces(tempState)
        setRemoveingSpace(false)
        handleOrderBookingRefetch()
      }
    }
  }

  const handleSelectedSpaces = (spaces) => setNewSelectedSpaces(spaces)
  const assignOwner = async (player) => {
    if (state.isAssigningOwner) return

    let res, err

    if (player?.type === 'stub') {
      const { name, email, phone } = player

      if (!name && !email && !phone) {
        addToast(t('sentences.canNotAssignThisPlayerAsOwner'), { appearance: 'error' })
        return
      }

      setState({ isAssigningOwner: true })
      ;[res, err] = await to(addStubPlayerToOrder(orderBooking.uuid, player))
      if (err || !res) {
        const { violations } = err
        if (violations?.length) {
          violations.forEach((violation) => {
            addToast(violation.message, { appearance: 'error' })
          })
        }
      }
    } else {
      if (!player?.uuid) {
        addToast(t('sentences.canNotAssignThisPlayerAsOwner'), { appearance: 'error' })
        return
      }

      setState({ isAssigningOwner: true })
      ;[res, err] = await to(setBookingOwner(orderBooking.booking.id, player.uuid))
      if (err || !res) {
        const { violations } = err
        if (violations && violations.length) {
          const overridableViolations = violations.filter((violation) => violation.is_overridable)
          setPlayerData(player)
          setBookingViolations(overridableViolations)
        }
      }
    }

    if (res) {
      addToast(t('sentences.bookingOwnerAssigned'), { appearance: 'success' })
      setPlayerData(player)
    }
    handleOrderBookingRefetch()
    setState({ isAssigningOwner: false })
  }

  const confirmViolation = async () => {
    const [res] = await to(setBookingOwner(orderBooking.booking.id, playerData.uuid, true, true))

    if (res) {
      addToast(t('sentences.bookingOwnerAssigned'), { appearance: 'success' })
    }
    handleOrderBookingRefetch()
    setState({ isAssigningOwner: false })
  }

  const setNumberOfPlayers = useCallback(async () => {
    if (!orderBooking?.uuid) {
      return
    }

    const [, err] = await to(
      setNumberOfExtraPlayers(orderBooking.uuid, { data: extraPlayerState.tee_times })
    )
    if (err) {
      if (
        err?.message === 'Number of rounds is out of limit' ||
        err?.detail === 'Number of rounds is out of limit'
      ) {
        addToast(t('sentences.maxNumberOfRoundsReached'), { appearance: 'error' })
      } else if (
        err?.message === 'Play value is out of limit' ||
        err?.detail === 'Play value is out of limit'
      ) {
        addToast(t('sentences.maxPlayValueReached'), { appearance: 'error' })
      } else {
        addToast(t('sentences.couldNotReserveBooking'), { appearance: 'error' })
      }
    }
  }, [orderBooking, extraPlayerState])

  const manageBookingDispense = async () => {
    // Create booking dispense if belongs_to_range_context and free balls are counfigured
    if (
      isIncludeBallsInBookingEnabled &&
      orderBooking?.course?.belongs_to_range_context &&
      bayRange?.id &&
      !bookingDispense?.qrCode &&
      bayRange?.free_balls_count
    ) {
      const [dispenseRes] = await to(
        createBookingDispense({ orderId: orderBooking.uuid, rangeId: bayRange.id })
      )
      if (dispenseRes?.booking_dispense) {
        setBookingDispense({
          qrCode: dispenseRes.booking_dispense.qr_code,
          isCompleted: dispenseRes.booking_dispense.is_completed,
        })
      }
    }
  }

  const confirmOrder = async () => {
    track({
      event_type: AMPLITUDE_EVENTS.CONFIRM_BOOKING_TAPPED,
      event_properties: {
        [AMPLITUDE_EVENTS.PLAYERS_ADDED]: orderBooking?.booking?.booking_participants.length,
      },
    })
    if (state.isConfirming) return

    setState({ isConfirming: true })

    await setNumberOfPlayers()
    const [res] = await to(confirmOrderBooking(orderBooking.uuid))

    if (res) {
      await manageBookingDispense()
      addToast(t('sentences.bookingConfirmed'), { appearance: 'success' })
    } else {
      addToast(t('sentences.couldNotConfirmBookingClean'), { appearance: 'error' })
    }
    handleOrderBookingRefetch()
    setState({ isConfirming: false })
  }

  const handleCancelBooking = (confirmed = false) => {
    const { booking } = orderBooking

    const overridePolicyViolations =
      isPast(new Date(booking?.last_cancellation_point_at)) &&
      isPast(new Date(booking?.grace_period_end))
        ? true
        : undefined

    if (overridePolicyViolations) {
      setShowConfirmCancelBooking(true)
    } else {
      cancelOrder(confirmed)
    }
  }

  const cancelOrder = async (confirmed = false) => {
    setShowConfirmCancelBooking(false)
    if (state.isCanceling) return
    if (!confirmed) {
      setShowCancelAndRefundConfirm(true)
      return
    } else {
      setShowCancelAndRefundConfirm(false)
    }

    setState({ isCanceling: true })

    const { booking } = orderBooking

    const overridePolicyViolations =
      isPast(new Date(booking?.last_cancellation_point_at)) &&
      isPast(new Date(booking?.grace_period_end))
        ? true
        : undefined

    const [, err] = await to(cancelOrderBooking(orderBooking.uuid, false, overridePolicyViolations))
    setState({ isCanceling: false })
    if (err) {
      orderBookingRefetch()
      addToast(t('sentences.couldNotCancelBookingClean'), { appearance: 'error' })
    } else {
      clearSessionStorage(`cancel-${orderBooking.uuid}`)
      addToast(t('sentences.bookingsCancelled'), { appearance: 'success' })
      goBack(true)
    }
  }

  const handleCancelMoving = () => {
    setActiveBookingStep(BookingSteps.BOOKING_DETAILS)
  }

  const markAsPaid = async () => {
    if (state.isMarkingAsPaid) return

    setState({ isMarkingAsPaid: true })

    const [resOne, errOne] = await to(updateBookingPayOnSiteStatus(orderBooking.uuid, true))
    if (!resOne || errOne) {
      addToast(t('sentences.couldNotMarkAsPaid'), { appearance: 'error' })
      setState({ isMarkingAsPaid: false })
      return
    }

    const [resTwo, errTwo] = await to(markBookingAsPaid(orderBooking.uuid))
    if (!resTwo || errTwo) {
      addToast(t('sentences.couldNotMarkAsPaid'), { appearance: 'error' })
      setState({ isMarkingAsPaid: false })
      return
    }

    await manageBookingDispense()
    handleOrderBookingRefetch()
    setState({ isMarkingAsPaid: false })
  }

  const renewBooking = () => {
    if (!orderBooking || isCancelled || isConfirmed) {
      if (mouseMoveThrottle) {
        mouseMoveThrottle.cancel()
      }
      return
    }

    const renew = async () => {
      try {
        const headers = JSON.parse(getSessionStorage(`cancel-${orderBooking.uuid}`))
        const { xDelayRenewalToken } = headers
        if (xDelayRenewalToken) {
          const [, err] = await to(renewDelayedMessage(xDelayRenewalToken, 30000))
          if (err) {
            handleOrderBookingRefetch()
          }
        }
      } catch (error) {
        handleOrderBookingRefetch()
        // Nothing to do
      }
    }

    if (mouseMoveThrottle) {
      mouseMoveThrottle()
      return
    }

    mouseMoveThrottle = throttle(renew, 30000, {
      trailing: false,
    })
  }

  const handleBookSpaceBtn = () => {
    setBookSpaceBtnState(true)
    setBookSpaceBtnLoadingState(true)
  }
  const handleEditSpacesButton = () => {
    setActiveBookingStep(BookingSteps.EDIT_SPACES)
  }

  const handleSelectSpace = (space, index) => {
    setSelectedSpacesForMoving((prevState) => {
      const spaces = [...prevState]
      spaces[index] = space
      return spaces
    })
  }

  // payload used for move endpoints
  const timeWithOffset = useMemo(
    () => time + formatInTimeZone(new Date(), venue?.timezone, 'xxx'),
    [time, venue?.timezone]
  )

  const dateFormatted = useMemo(() => {
    if (isValid(new Date(date))) {
      return format(new Date(date), 'yyyy-MM-dd')
    }
    return ''
  }, [date])

  const buttonsList = useMemo(() => {
    return [
      {
        type: buttonsTypes.VOUCHER,
        booking: orderBooking,
        onUpdateBooking: handleOrderBookingRefetch,
        buttonText: t('vouchers.voucher'),
        disabled:
          isConsideredPaid ||
          orderBookingIsFetching ||
          (orderBooking?.partnership && orderBooking?.partnership?.id) ||
          !orderBooking?.customer,
        paid: isConsideredPaid,
      },
      {
        type: buttonsTypes.PARTNERSHIP,
        booking: orderBooking,
        onUpdateBooking: handleOrderBookingRefetch,
        buttonText: t('words.partnership_one'),
        disabled:
          isConsideredPaid ||
          orderBookingIsFetching ||
          (orderBooking?.partnership && orderBooking?.partnership?.id) ||
          (!orderBooking?.customer &&
            !orderBooking?.stub_player.name &&
            !orderBooking?.stub_player.phone &&
            !orderBooking?.stub_player.email),
      },
    ]
  }, [orderBooking, orderBookingIsFetching, isConsideredPaid, t])

  const handleCheckAvailability = () => {
    if (state.isCheckingAvailability) return
    setState({ isCheckingAvailability: true })
    setAvailabilityErrors([])
    setMoveBookingErrors([])

    getMoveAvailability(orderBooking.uuid, {
      date: dateFormatted,
      time: encodeURIComponent(timeWithOffset),
    })
      .then((res) => {
        const available_spaces = res?.available_spaces?.map((space) => {
          if (isNewRange) {
            const bay = orderBays.find((bay) => bay.uuid === space.uuid)
            return {
              ...space,
              name: bay ? `${t('words.bay_one')} ${bay.bay_number}` : space.name,
            }
          }
          return space
        })
        setAvailableSpaces(available_spaces)

        // default selected values
        const spaces = []
        for (let index = 0; index < orderBooking?.spaces?.length; index++) {
          const originalSpace = available_spaces.find(
            (space) => space.uuid === orderBooking.spaces[index].uuid
          )
          if (originalSpace || available_spaces[index]) {
            spaces[index] = originalSpace || available_spaces[index]
          }
        }
        setSelectedSpacesForMoving([...new Set(spaces)])
        setState({ isCheckingAvailability: false })
      })
      .catch((err) => {
        setState({ isCheckingAvailability: false })
        if (Array.isArray(err.violations)) {
          setAvailabilityErrors(err.violations)
        }
        setAvailableSpaces([])
        setSelectedSpacesForMoving([])
      })
  }

  const handleMoveBooking = () => {
    if (state.isMoving) return
    setState({ isMoving: true })
    setMoveBookingErrors([])

    moveBooking(orderBooking.uuid, {
      date: dateFormatted,
      time: timeWithOffset,
      to_spaces: selectedSpacesForMoving.map((space) => space.uuid),
    })
      .then((res) => {
        setState({ isMoving: false })
        setActiveBookingStep(BookingSteps.BOOKING_DETAILS)
        handleOrderBookingRefetch()
      })
      .catch((err) => {
        setState({ isMoving: false })
        if (Array.isArray(err.violations)) {
          setMoveBookingErrors(err.violations)
        }
      })
  }

  const handleRevertChanges = useCallback(() => {
    extraPlayerDispatch({
      type: ActionType.ClearAction,
    })
  }, [extraPlayerDispatch])

  const setExtraPlayers = useCallback(async () => {
    setState({ isConfirming: true })
    if (showPaymentOrRefundRequiredConfirm) {
      setShowPaymentOrRefundRequiredConfirm(false)
    }

    await setNumberOfPlayers()
    handleOrderBookingRefetch()
    setState({ isConfirming: false })
  }, [handleOrderBookingRefetch, setNumberOfPlayers, setState, showPaymentOrRefundRequiredConfirm])

  const areTeeTimesWithPPPModifiedResult = useMemo(
    () =>
      areTeeTimesWithPPPModified(
        isConfirmed,
        orderBooking,
        extraPlayerState.calculatedPriceResult?.tee_times
      ),
    [isConfirmed, orderBooking, extraPlayerState.calculatedPriceResult?.tee_times]
  )

  const bookingHasPromotionApplied = useMemo(
    () => hasPromotionApplied(extraPlayerState.calculatedPriceResult?.order_items),
    [extraPlayerState.calculatedPriceResult?.order_items]
  )

  const spaceItems = useMemo(() => {
    let tempSpacesAndItems = {}
    if (!orderBooking) return
    orderBooking.spaces.forEach((space) => {
      tempSpacesAndItems[space.id] = orderBooking?.items?.filter(
        (elem) => elem.slot?.tee_time?.space?.uuid === space.uuid
      )
    })
    return tempSpacesAndItems
  }, [orderBooking])

  const teeTimes = useMemo(() => {
    return addedSpaces?.reduce((acc, space) => {
      const items = spaceItems[space.id]
      const uniqueTeeTimeMap =
        items?.reduce((accumulator, currentItem) => {
          const { uuid } = currentItem.slot.tee_time
          if (!accumulator.has(uuid)) {
            accumulator.set(uuid, {
              ...currentItem.slot.tee_time,
              currentItemUnitPrice: currentItem.unit_price,
            }) // Store tee_time details using uuid as key
          }
          return accumulator
        }, new Map()) || new Map()

      return {
        ...acc,
        [space.id]: Array.from(uniqueTeeTimeMap.values()),
      } // Convert the Map values back into an array
    }, {})
  }, [addedSpaces, spaceItems])

  const totalNrOfBalls = useMemo(() => {
    if (!teeTimes) return bayRange?.free_balls_count
    const flatTeeTimes = Object.values(teeTimes).reduce(
      (flatArray, item) => flatArray.concat(item),
      []
    )
    return bayRange?.free_balls_count * flatTeeTimes.length
  }, [teeTimes, bayRange?.free_balls_count])

  const venueTimeZone = useMemo(() => {
    return venue?.timezone || null
  }, [venue])

  const formattedStartDate = useMemo(() => {
    if (!orderBooking?.booking?.start_time) return ''
    return formatInTimeZone(
      new Date(orderBooking?.booking.start_time),
      venueTimeZone,
      'EEE, dd MMMM yyyy'
    )
  }, [venueTimeZone, orderBooking])

  const bookingTime = useMemo(() => {
    if (!orderBooking?.booking?.start_time) return ''
    return `${formatInTimeZone(
      new Date(orderBooking?.booking.start_time),
      venueTimeZone,
      'HH:mm'
    )} - ${formatInTimeZone(new Date(orderBooking?.booking.end_time), venueTimeZone, 'HH:mm')}`
  }, [orderBooking, venueTimeZone])

  const handleSaveChanges = useCallback(() => {
    if (state.isConfirming) return

    if (
      !(
        bookingHasPromotionApplied &&
        !extraPlayerState.calculatedPriceResult?.to_pay_total &&
        !extraPlayerState.calculatedPriceResult?.to_refund_total &&
        !extraPlayerState.calculatedPriceResult?.calculated_total
      ) &&
      (isConsideredPaid || isPartiallyPaid) &&
      areTeeTimesWithPPPModifiedResult
    ) {
      setShowPaymentOrRefundRequiredConfirm(true)
      return
    }

    setExtraPlayers()
  }, [
    state.isConfirming,
    isConsideredPaid,
    isPartiallyPaid,
    areTeeTimesWithPPPModifiedResult,
    setExtraPlayers,
  ])

  /** --------------------- */
  /** Render */
  if (orderBookingIsError) {
    return (
      <div className={cx(styles.centeredContainer)}>
        <p>{t('sentences.couldNotLoadBooking')}</p>
      </div>
    )
  }

  if (
    (orderBookingStatus === 'loading' && venueStatus === 'loading') ||
    !venue ||
    !orderBooking ||
    (venue?.belongs_to_range_context && !isBaysFetched)
  ) {
    return (
      <div className={cx(styles.centeredContainer)}>
        <PulseLoader showIf={true} />
      </div>
    )
  }

  if (isCancelled) {
    return (
      <div className={cx(styles.centeredContainer)}>
        <p>{t('sentences.thisBookingIsCancelled')}</p>
      </div>
    )
  }

  return (
    <div className={cx(styles.container)} onMouseMove={renewBooking}>
      <div className={cx(styles.header)}>
        <Button
          variant={'ghost-dark'}
          size={'small'}
          onClick={() => goBack()}
          disabled={
            activeBookingStep === BookingSteps.MOVE_BOOKING ||
            activeBookingStep === BookingSteps.EDIT_SPACES
          }
          className={'px-lg text-content-base border-stroke-stone gap-2 font-medium'}
        >
          <i className="fa-regular fa-arrow-left" />
          {t('words.back')}
        </Button>
        <BookingTitles activeBookingStep={activeBookingStep} />
        <AnimatedLine
          className={cx(styles.loadingLine)}
          show={
            orderBookingIsFetching ||
            venueIsFetching ||
            state.isAssigningOwner ||
            state.isConfirming ||
            state.isCanceling ||
            state.isMarkingAsPaid
          }
        />
      </div>
      <div
        className={cx(
          styles.left1,
          activeBookingStep !== BookingSteps.EDIT_SPACES ? styles.extraPadding : ''
        )}
      >
        {activeBookingStep === BookingSteps.EDIT_SPACES && (
          <SpacesStep
            isBookSpaceClicked={isBookSpaceClicked}
            venue={venue}
            orderBooking={orderBooking}
            bookingUuid={bookingUuid}
            setBookSpaceBtnLoadingState={setBookSpaceBtnLoadingState}
            bookSpaceBtnLoading={bookSpaceBtnLoading}
            setNewSelectedSpaces={handleSelectedSpaces}
            setBookSpaceBtnState={setBookSpaceBtnState}
            setEditBookingState={setEditBookingState}
            orderBookingRefetch={handleOrderBookingRefetch}
            handleBookSpaceBtnDisabledState={setBookSpaceBtnDisabled}
            isBay={isNewRange}
            bays={orderBays}
          />
        )}
        {activeBookingStep === BookingSteps.BOOKING_DETAILS && (
          <DetailsStep
            orderBooking={orderBooking}
            hasCustomer={hasCustomer}
            hasStubCustomer={hasStubCustomer}
            isAssigningOwner={state.isAssigningOwner}
            addedSpaces={addedSpaces}
            handleRemoveSpace={handleRemoveSpace}
            assignOwner={assignOwner}
            removingSpace={removingSpace}
            handleEditSpacesButton={handleEditSpacesButton}
            isBookingConfirmed={isConfirmed}
            isConsideredPaid={isConsideredPaid}
            orderBookingIsFetching={orderBookingIsFetching}
            timezone={venue?.timezone}
            teeTimes={teeTimes}
            spaceItems={spaceItems}
            freeBallsCount={bayRange?.free_balls_count}
            totalNrOfBalls={totalNrOfBalls}
          />
        )}
        {activeBookingStep === BookingSteps.MOVE_BOOKING && (
          <MoveBookingStep
            orderBooking={orderBooking}
            venue={venue}
            date={date}
            setDate={setDate}
            dateFormatted={dateFormatted}
            time={time}
            setTime={setTime}
            availableSpaces={availableSpaces}
            selectedSpaces={selectedSpacesForMoving}
            handleCheckAvailability={handleCheckAvailability}
            handleSelectSpace={handleSelectSpace}
            errors={[...availabilityErrors, ...moveBookingErrors]}
            isChecking={state.isCheckingAvailability}
          />
        )}
      </div>
      <div className={cx(styles.right)}>
        <OrderSummaryBox
          isConfirmed={isConfirmed}
          isConsideredPaid={isConsideredPaid}
          orderBooking={orderBooking}
          venue={venue}
          isDispenseComplete={isDispenseComplete}
          isProcessing={isProcessing}
          formattedStartDate={formattedStartDate}
          bookingTime={bookingTime}
        >
          {isConfirmed && <QTAccessCode accessCode={orderBooking?.access_code} />}
          <div className="flex w-full flex-col items-center  gap-2 px-7 pb-2 pt-4 lg:flex-row">
            <AddSubscriptionSelect
              orderBooking={orderBooking}
              handleOrderBookingRefetch={handleOrderBookingRefetch}
              disabled={
                state.isCanceling ||
                state.isConfirming ||
                state.isMarkingAsPaid ||
                orderBookingIsFetching ||
                isPartiallyPaid ||
                isConsideredPaid
              }
            />
            <AddButtonsList buttons={buttonsList} />
          </div>
          <AppliedActionList
            orderBooking={orderBooking}
            orderBookingRefetch={handleOrderBookingRefetch}
            paid={isConsideredPaid}
          />
          <SummaryOrderRows
            venue={venue}
            hasOwner={hasCustomer || hasStubCustomer}
            orderBooking={orderBooking}
            extraPlayerState={extraPlayerState}
            selectedSpaces={getSelectedSpaces()}
            orderBookingRefetch={handleOrderBookingRefetch}
            isConfirmed={isConfirmed}
            isPaid={isConsideredPaid}
            isPartiallyPaid={isPartiallyPaid}
            orderBookingSpaces={orderBookingSpaces}
          />
          <OrderSummaryFooter
            showConfirm={!isConfirmed}
            showMarkAsPaid={
              isConfirmed &&
              !isConsideredPaid &&
              !extraPlayerState?.calculatedPriceResult?.is_modified
            }
            showCancel={
              !isConsideredPaid &&
              (!isConfirmed || !extraPlayerState?.calculatedPriceResult?.is_modified)
            }
            showSaveChanges={isConfirmed && extraPlayerState?.calculatedPriceResult?.is_modified}
            onSaveChanges={handleSaveChanges}
            onRevertChanges={handleRevertChanges}
            showMove={isConfirmed && !extraPlayerState?.calculatedPriceResult?.is_modified}
            currentStep={activeBookingStep}
            onBookSpaces={handleBookSpaceBtn}
            bookSpacesBtnLoading={bookSpaceBtnLoading}
            showCancelRefund={
              isConsideredPaid &&
              activeBookingStep !== BookingSteps.MOVE_BOOKING &&
              !extraPlayerState?.calculatedPriceResult?.is_modified
            }
            onConfirm={confirmOrder}
            onCancel={() => handleCancelBooking(true)}
            onCancelRefund={() =>
              isDispenseComplete ? setShowBallDispensedDialog(true) : cancelOrder(false)
            }
            onMarkAsPaid={markAsPaid}
            handleEditSpacesButton={handleEditSpacesButton}
            showEditSpacesButtons={activeBookingStep === BookingSteps.EDIT_SPACES}
            onCancelEditSpacesChanges={() => setActiveBookingStep(BookingSteps.BOOKING_DETAILS)}
            showMoveBooking={activeBookingStep === BookingSteps.MOVE_BOOKING}
            handleMoveBookingButtonClick={() => setActiveBookingStep(BookingSteps.MOVE_BOOKING)}
            onMoveBooking={handleMoveBooking}
            onCancelMove={handleCancelMoving}
            cancelDisabled={
              state.isCanceling ||
              state.isConfirming ||
              state.isMarkingAsPaid ||
              orderBookingIsFetching
            }
            cancelRefundDisabled={
              state.isCanceling ||
              state.isConfirming ||
              state.isMarkingAsPaid ||
              orderBookingIsFetching
            }
            markAsPaidDisabled={
              state.isCanceling ||
              state.isConfirming ||
              state.isMarkingAsPaid ||
              orderBookingIsFetching
            }
            confirmDisabled={
              !state.selectedSpaces?.length ||
              (!hasCustomer && !hasStubCustomer) ||
              state.isConfirming ||
              orderBookingIsFetching
            }
            bookSpacesDisabled={
              !selectedSpacesFromEditTeeTime.length ||
              state.isConfirming ||
              orderBookingIsFetching ||
              isBookSpaceBtnDisabled
            }
            revertChangesDisabled={state.isConfirming || state.isMoving || orderBookingIsFetching}
            saveChangesDisabled={state.isConfirming || state.isMoving || orderBookingIsFetching}
            cancelMoveDisabled={state.isMoving || orderBookingIsFetching}
            showQRCode={
              isIncludeBallsInBookingEnabled &&
              isConfirmed &&
              bayRange?.free_balls_count &&
              bookingDispense?.qrCode
            }
            handleQRCodeClick={() => setShowQRCodeSidebar(true)}
          />
        </OrderSummaryBox>
      </div>
      <ConfirmPopup
        visible={bookingViolations.length > 0}
        title={t('sentences.confirmPlayer', {
          name: getPlayerName(playerData) || '',
        })}
        text={t('sentences.youHaveUnconfirmedBookingsThatWillCancel', {
          count: 1,
        })}
        titleIcon={WarningIcon}
        overrideIconColor={false}
        acceptText={t('words.confirm')}
        acceptTheme="default"
        rejectText={t('words.cancel')}
        rejectTheme="none"
        onAccept={() => {
          setBookingViolations([])
          setPlayerData(null)
          confirmViolation()
        }}
        onReject={() => setBookingViolations([])}
        onClose={() => setBookingViolations([])}
      >
        <div className={cx(styles.confirmOverrideContainer)}>
          <p className={cx(styles.membershipName)}>
            {bookingViolations ? bookingViolations[0]?.membership_name : ''}
          </p>
          {bookingViolations.map((violation) => (
            <div key={violation.code} className={cx(styles.policyContainer)}>
              <span className={cx(styles.policyName)}>{violation?.reservation_policy_name}</span>
              <br />
              <ul>
                <li className={cx(styles.violation)}>
                  <span>{violation?.message || ''}</span>
                </li>
              </ul>
            </div>
          ))}
          <p className={cx(styles.confirmOverride)}>{t('sentences.confirmToOverride')}</p>
        </div>
      </ConfirmPopup>
      <ConfirmPopup
        visible={!!showConfirmCancelBooking}
        onClose={() => setShowConfirmCancelBooking(false)}
        onReject={() => setShowConfirmCancelBooking(false)}
        title={t('sentences.confirmCancellation')}
        titleIcon={TrashIcon}
        text={t('sentences.lastCancellationPointPassed', {
          bookingCancellationLimit: bookingCancellationLimitHours,
        })}
        acceptText={t('words.confirm')}
        onAccept={() => cancelOrder(true)}
        rejectText={t('words.abort')}
      />
      <ConfirmCancelModalWrapper
        orderBooking={orderBooking}
        venue={venue}
        render={(totalToPay, discountTotal, getSpaceTotal) => (
          <ModalSimple
            visible={showCancelAndRefundConfirm}
            onClose={() => setShowCancelAndRefundConfirm(false)}
            className={cx(styles.confirmCancelModal)}
            headerChildren={<p>{t('sentences.cancelAndRefund')}</p>}
          >
            <p className={cx(styles.modalText)}>
              {t('sentences.thisActionWillRefundThisMuch', { value: totalToPay })}.{' '}
              {t('sentences.bookingOwnerWillBeNotified')}.
            </p>
            <div className={cx(styles.table)}>
              <div className={cx(styles.row)}>
                <p className={cx(styles.text, styles.black, styles.size16)}>
                  {t('words.booking_one')} #{orderBooking.uuid}
                </p>
              </div>
              <hr />
              {orderBookingSpaces.map((space) => (
                <div className={cx(styles.row)} key={space.id}>
                  <p className={cx(styles.text)}>{space.name}</p>
                  <p className={cx(styles.text)}>{getSpaceTotal(space)}</p>
                </div>
              ))}
              <div className={cx(styles.row)}>
                <p className={cx(styles.text)}>{t('sentences.discountTotal')}</p>
                <p className={cx(styles.text)}>{discountTotal}</p>
              </div>
              <hr />
              <div className={cx(styles.row)}>
                <p className={cx(styles.text, styles.black)}>{t('sentences.refundTotal')}</p>
                <p className={cx(styles.text, styles.black)}>{totalToPay}</p>
              </div>
            </div>
            <div className={cx(styles.buttonsRow)}>
              <button
                onClick={() => setShowCancelAndRefundConfirm(false)}
                className={cx('system-button primary-outline md-32')}
              >
                {t('words.cancel')}
              </button>
              <button
                onClick={() => cancelOrder(true)}
                className={cx('system-button primary md-32')}
              >
                {t('words.confirm')}
              </button>
            </div>
          </ModalSimple>
        )}
      />
      {showPaymentOrRefundRequiredConfirm && (
        <ConfirmChangeModal
          isPaid={isConsideredPaid}
          isPartiallyPaid={isPartiallyPaid}
          isConfirmed={isConfirmed}
          booking={orderBooking}
          onConfirm={setExtraPlayers}
          onCancel={() => setShowPaymentOrRefundRequiredConfirm(false)}
        />
      )}
      <ModalSimple
        visible={showUnsavedChangesAlertModal}
        onClose={() => setShowUnsavedChangesAlertModal(false)}
        className={cx(styles.confirmPaymentOrRefundModal)}
        headerChildren={<p className={'font-bold'}>{t('sentences.unsavedChangesDetected')}</p>}
      >
        <div className={'mb-5 mt-4 flex'}>{t('sentences.unsavedChangesDetectedDescription')}</div>
        <div className={'flex w-full justify-end gap-4'}>
          <Button variant={'line-dark'} onClick={() => setShowUnsavedChangesAlertModal(false)}>
            {t('words.cancel')}
          </Button>
          <Button onClick={handleGoBack}>{t('words.confirm')}</Button>
        </div>
      </ModalSimple>
      {isIncludeBallsInBookingEnabled && (
        <>
          <QRCodeSideBar
            open={showQRCodeSidebar}
            handleOpenChange={setShowQRCodeSidebar}
            qrCode={bookingDispense.qrCode}
            bays={orderBays}
            rangeName={bayRange?.name}
            totalNrOfBalls={totalNrOfBalls}
            formattedStartDate={formattedStartDate}
            bookingTime={bookingTime}
          />
          <BallDispensedDialog
            open={showBallDispensedDialog}
            onClose={() => setShowBallDispensedDialog(false)}
            onSave={() => {
              setShowBallDispensedDialog(false)
              cancelOrder(false)
            }}
          />
        </>
      )}
    </div>
  )
}

SimulatorsRangesOrder.propTypes = {
  bookingUuid: PropTypes.string,
}

SimulatorsRangesOrder.defaultProps = {}

const SimulatorsRangesOrderWrapper = () => {
  const { uuid: bookingUuid } = useParams()

  return (
    <ExtraPlayerProvider bookingUuid={bookingUuid}>
      <SimulatorsRangesOrder bookingUuid={bookingUuid} />
    </ExtraPlayerProvider>
  )
}

export default SimulatorsRangesOrderWrapper
