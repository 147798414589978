import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeaderLeftIcon,
  SheetTitle,
  SheetHeader,
  SheetCustomContent,
  SheetClose,
  SheetFooter,
} from '@sweetspot/scramble-ds'
import { Button } from '@sweetspot/scramble-ds/atoms'

import { useMutation, useQueryClient } from 'react-query'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'

import { useForm } from 'react-hook-form'
import { CreateGitClubCredentialSchema } from './validation'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useState } from 'react'
import { InputField } from '../InputField'
import { createFederationCredential } from '@sweetspot/shared/data-access/api-platform'
import { CreateFederationCredentialPayload } from '@sweetspot/shared/data-access-api-platform/src/clubs/types'
import { QUERY_KEYS } from '@sweetspot/shared/util/constants'

interface CreateGitClubCredentialSheetProps {
  children: React.ReactNode
}

export const CreateGitClubCredentialSheet = ({ children }: CreateGitClubCredentialSheetProps) => {
  const [isSheetOpen, setIsSheetOpen] = useState(false)

  const { t } = useTranslation()
  const { addToast } = useToasts()

  const golfClub = useSelector(({ golfClub }: RootState) =>
    golfClub?.list.find((club) => club?.id === golfClub?.selectedId)
  )

  const queryClient = useQueryClient()

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<z.infer<typeof CreateGitClubCredentialSchema>>({
    resolver: zodResolver(CreateGitClubCredentialSchema),
    defaultValues: {
      user: '',
      password: '',
      clubGuid: '',
    },
    mode: 'onSubmit',
  })

  const createFederationCredentialMutation = useMutation(
    ({ clubId, payload }: { clubId: number; payload: CreateFederationCredentialPayload }) =>
      createFederationCredential(clubId, payload),
    {
      onSuccess: () => {
        setIsSheetOpen(false)
        reset()
        addToast(t('settings.federationClubApi.gitCredentialAddSuccess'), { appearance: 'success' })
        queryClient.invalidateQueries([QUERY_KEYS.FEDERATION_CREDENTIALS])
      },
      onError: () => {
        addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
      },
    }
  )

  const onSubmit = (data: z.infer<typeof CreateGitClubCredentialSchema>) => {
    if (!golfClub?.id) return

    const payload = {
      federation_name: 'git',
      configuration: {
        user: data.user,
        password: data.password,
      },
      external_identifier: data.clubGuid,
    }

    createFederationCredentialMutation.mutate({ clubId: golfClub.id, payload })
  }

  const formValues = watch()

  const isFormFilled = Object.values(formValues).every((value) => value !== '')

  return (
    <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
      <SheetTrigger asChild className="cursor-pointer">
        {children}
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetClose asChild>
            <SheetHeaderLeftIcon>
              <i className="fa-regular fa-close" />
            </SheetHeaderLeftIcon>
          </SheetClose>
          <SheetTitle className="text-lg">
            {t('settings.federationClubApi.gitCredentialCreate')}
          </SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-4">
          <InputField
            name="user"
            label={t('sentences.userName')}
            control={control}
            error={errors.user}
            required={watch('user') === ''}
          />
          <InputField
            name="password"
            type="password"
            label={t('words.password')}
            control={control}
            error={errors.password}
            required={watch('password') === ''}
          />

          <InputField
            name="clubGuid"
            label={t('settings.federationClubApi.clubGuid')}
            control={control}
            error={errors.clubGuid}
            required={watch('clubGuid') === ''}
          />
        </SheetCustomContent>
        <SheetFooter className="pt-0">
          <Button
            className="w-full"
            variant="primary"
            disabled={
              !isFormFilled ||
              Object.keys(errors).length > 0 ||
              createFederationCredentialMutation.isLoading
            }
            onClick={() => {
              handleSubmit(onSubmit)()
            }}
          >
            {createFederationCredentialMutation.isLoading ? (
              <div className="mr-2 animate-[spin_3s_linear_infinite]">
                <i className="fa-regular text-text-dark text-brand-xl fa-loader" />
              </div>
            ) : (
              t('words.create')
            )}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}
