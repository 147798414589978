import { useMemo } from 'react'
import { useManageCourseContext } from '../ManageCourseProvider'
import CourseSelector from './CourseSelector'

const FilterToolbar = () => {
  const { courses } = useManageCourseContext()
  const hasCourses = useMemo(() => courses?.length > 0, [courses])

  if (!hasCourses) return null

  return (
    <div className={'flex items-end justify-between px-6 py-4'}>
      <div className={'w-[398px]'}>
        <CourseSelector />
      </div>
    </div>
  )
}

export default FilterToolbar
