import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatInTimeZone } from 'date-fns-tz'
import { Button, Popover } from '@sweetspot/scramble-ds/atoms'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { Resource } from '../../../../types'
import { ActionItem } from '../components'

type ResourceDetailsProps = {
  resource: Resource | null
  timezone: string
}

const ResourceDetails = ({ resource, timezone }: ResourceDetailsProps) => {
  const { t } = useTranslation()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  if (!resource) return null

  return (
    <div className="bg-background-base-clean flex-1 px-6 md:px-0">
      <div className="border-border-stroke-clean hidden items-center justify-between border-b px-6 py-4 md:flex">
        <p className="text-content-xl text-text-dark overflow-hidden text-ellipsis whitespace-nowrap font-bold">
          {resource.name}
        </p>
        <Popover.Root open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
          <Popover.Trigger asChild>
            <Button variant="link-dark" className="h-auto cursor-pointer outline-0">
              <FontAwesomeIcon icon={faEllipsis} />
            </Button>
          </Popover.Trigger>
          <Popover.Content align="end" className="z-50 w-fit p-2 px-4 drop-shadow">
            <ActionItem
              iconName="edit"
              label="words.edit"
              handleClick={() => setIsDropdownOpen(false)}
            />
            <ActionItem
              iconName="clone"
              label="words.duplicate"
              handleClick={() => setIsDropdownOpen(false)}
            />
            <ActionItem
              iconName="box-archive"
              label="words.archive"
              color="text-danger"
              handleClick={() => setIsDropdownOpen(false)}
            />
          </Popover.Content>
        </Popover.Root>
      </div>

      {resource.lastModifiedBy && (
        <div className="border-border-stroke-clean border-b px-0 py-4 md:px-6 md:py-2">
          <p className="text-content-sm text-text-dark overflow-hidden text-ellipsis whitespace-nowrap font-bold">
            {t('sentences.lastEditedBy')} {resource.lastModifiedBy}
          </p>
          {resource.lastModifiedAt && (
            <p className="text-content-sm text-text-subtle font-regular overflow-hidden text-ellipsis whitespace-nowrap">
              {formatInTimeZone(resource.lastModifiedAt, timezone, 'EEEE, MMMM d yyyy - HH:mm')}
            </p>
          )}
        </div>
      )}

      {resource.editHistory?.length ? (
        <div className="border-border-stroke-clean border-b px-0 py-4 md:px-6">
          <p className="text-content-sm text-text-dark overflow-hidden text-ellipsis whitespace-nowrap font-bold">
            {t('words.history')}:
          </p>
          {resource.editHistory.map((history) => (
            <div key={history.timestamp} className="py-2">
              <p className="text-content-sm text-text-dark font-regular overflow-hidden text-ellipsis whitespace-nowrap">
                {history.actionType}, {history.actor}
              </p>
              <p className="text-content-xs text-text-subtle font-regular overflow-hidden text-ellipsis whitespace-nowrap">
                {formatInTimeZone(history.timestamp, timezone, 'EEEE, MMMM d yyyy - HH:mm')}
              </p>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default ResourceDetails
