import { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import { Button } from '@sweetspot/scramble-ds/atoms'
import {
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { GolfCourseTypes } from '@sweetspot/shared/types'
import {
  DEFAULT_BOOKING_CANCEL_POINT,
  DEFAULT_BOOKING_DISPLAY_TEE_TIME_DAYS,
} from '@sweetspot/shared/util/constants'

import { useManageCourseContext } from '../ManageCourseProvider'
import Details from './Details'
import useCreateCourseMutation from '../hooks/useCreateCourseMutation'
import { CreateCoursePayload } from '../types'

const DEFAULT_COURSE_SETTINGS = {
  isActive: false,
  isCanPay: true,
  isArrivalRegistration: false,
  isArrivalRegistrationAfterSchedule: false,
  nonConfirmedBookingAutoCancellationEnabled: false,
  nonConfirmedBookingAutoCancellationDuration: null,
  unpaidBookingAutoCancellationEnabled: false,
  isStubPlayersEnabled: false,
  isPayOnSiteEnabled: false,
  is_enabled: false,
  isUseDynamicPricing: false,
  bookingType: -1,
  membershipSignUpSettings: {
    isEnabled: false,
    memberships: [],
  },
  displayTeeTimeDays: DEFAULT_BOOKING_DISPLAY_TEE_TIME_DAYS,
  bookingCancellationLimitHours: DEFAULT_BOOKING_CANCEL_POINT,
  type: GolfCourseTypes.COURSE,
}

const CreateCourseSideBar = () => {
  const { t } = useTranslation()
  const [name, setName] = useState<string>('')
  const [vat, setVat] = useState<string>('')
  const { addToast } = useToasts()
  const { toggleCreateCourseSideBar, openCreateCourseSidebar } = useManageCourseContext()
  const createCourseMutation = useCreateCourseMutation()
  const currentClubUuid = useSelector((state: RootState) => {
    const clubUuId = state?.golfClub?.list?.find(
      (club) => club.id === state?.golfClub?.selectedId || ''
    )?.uuid
    return clubUuId as string
  })

  const resetForm = useCallback(() => {
    setName('')
    setVat('')
  }, [])

  useEffect(() => {
    // reset form when the sidebar is closed
    resetForm()
  }, [openCreateCourseSidebar, resetForm])

  const checkForErrors = useCallback(
    ({ name, vat }: Pick<CreateCoursePayload, 'name' | 'vat'>): string => {
      if (name?.trim() === '') return 'settings.nameRequired'
      if (!vat && vat !== 0) {
        return `${t('words.vat')} ${t('errors.fieldRequired').toLowerCase()}`
      }
      if (vat > 100) {
        return `${t('words.vat')}: ${t('errors.valueMustBeLowerThanThis', {
          this: 100,
        })}`
      }
      return ''
    },
    [t]
  )

  const handleCreateCourse = useCallback(async () => {
    const payload: CreateCoursePayload = {
      organizationId: currentClubUuid,
      name,
      vat: parseFloat(vat),
      ...DEFAULT_COURSE_SETTINGS,
    }
    const errorText = checkForErrors(payload)

    if (errorText) {
      addToast(t(errorText), { appearance: 'error' })
    } else {
      createCourseMutation.mutate(payload)
    }
  }, [name, vat, currentClubUuid, addToast, t, checkForErrors, createCourseMutation])

  return (
    <Sheet open={openCreateCourseSidebar} onOpenChange={toggleCreateCourseSideBar}>
      <SheetContent>
        <SheetHeader>
          <SheetHeaderLeftIcon onClick={toggleCreateCourseSideBar}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>
            {t('settings.createNewCourse', { type: t('words.course_one').toLowerCase() })}
          </SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-6">
          <Details name={name} setName={setName} vat={vat} setVat={setVat} />
        </SheetCustomContent>
        <SheetFooter>
          <Button onClick={handleCreateCourse} className="min-h-touch-height-lg w-full">
            {t('create')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

export default CreateCourseSideBar
