import { useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import moment from 'moment'

import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
// @ts-expect-error fetchBookings is not in typescript
import { getTranslatedTeeTimeCategories } from '@sweetspot/sweetspot-js/features/teeTimeCategories/js/getTranslatedTeeTimeCategories'
import {
  GolfCourseTypes,
  Period,
  Space,
  TeeTime,
  TeeTimeCategory,
  Venue,
} from '@sweetspot/shared/types'
import {
  addBookingPeriodOverride,
  getBookingPeriodsByGolfCourseId,
  // @ts-expect-error fetchBookings is not in typescript
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
// @ts-expect-error fetchBookings is not in typescript
import DateHelpers from '@sweetspot/sweetspot-js/common/functions/DateHelpers'
import { FlagNames, useFlag } from '@sweetspot/shared/util/feature-flag'

import {
  MergedTimeSlot,
  TimeSlotCategory,
  UpdateTimeSlotsMutationProps,
  UpdateTimeSlotsProps,
} from '../types'

type UseTimeSlotsEditProps = {
  currentVenue: Venue
  isShown: boolean
  selectedFrom: number
  selectedTo: number
  selectedSpaces: Space[]
}

export const useTimeSlotsEdit = ({
  currentVenue,
  isShown,
  selectedFrom,
  selectedTo,
  selectedSpaces,
}: UseTimeSlotsEditProps) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [categories, setCategories] = useState<TimeSlotCategory[]>([])
  const isNewTeeTimePeriodsEnabled = useFlag(FlagNames.RangeTeeTimePeriods)

  const primeTimeOptions = useMemo(
    () => [
      { id: 0, label: t('yes'), value: true },
      { id: 1, label: t('no'), value: false },
    ],
    [t]
  )

  const initialFormValues: MergedTimeSlot = useMemo(
    () => ({
      category: { value: undefined, isMixed: false },
      maxPlayers: { value: '', isMixed: false },
      primeTime: { value: undefined, isMixed: false },
    }),
    []
  )

  const { isFetching: isFetchingCategories } = useQuery(
    [CLUB_QUERIES.CATEGORIES, { clubId: currentVenue?.club?.id }],
    () =>
      getTranslatedTeeTimeCategories({
        clubId: currentVenue?.club?.id,
        allPages: true,
      }),
    {
      enabled: !!currentVenue && !!isShown,
      onSuccess: (data) => {
        const categories = data?.map((category: TeeTimeCategory) => {
          return { label: category.name, icon: true, iconColor: category.color, ...category }
        })
        setCategories(categories)
      },
    }
  )

  const updateTimeSlotsMutation = useMutation(
    ({ activePeriodUuid, ...data }: UpdateTimeSlotsMutationProps): Promise<Period> =>
      addBookingPeriodOverride(activePeriodUuid, data)
  )

  const getPeriodsMutation = useMutation<Period[]>(() =>
    getBookingPeriodsByGolfCourseId(currentVenue?.id)
  )

  const updateTimeSlots = useCallback(
    ({ category, primeTime, maxPlayers }: UpdateTimeSlotsProps): Promise<Period[]> =>
      new Promise((resolve, reject) => {
        getPeriodsMutation
          .mutateAsync()
          .then((periodsRes) => {
            const date = new Date(selectedFrom * 1000)
            date.setHours(0)
            date.setMinutes(0)
            date.setSeconds(0)
            const ISODate = DateHelpers.toISODateTime(date)

            const activePeriod = periodsRes.find(
              (period: Period) => period.start_date <= ISODate && period.end_date >= ISODate
            )

            if (!activePeriod) {
              reject()
              return
            }

            const promises = []
            for (const space of selectedSpaces) {
              const _selectedTo =
                isNewTeeTimePeriodsEnabled && currentVenue.type === GolfCourseTypes.DRIVING_RANGE
                  ? selectedTo
                  : selectedTo - activePeriod.interval * 60
              const payload = {
                activePeriodUuid: activePeriod.uuid,
                repeat_on: 'every_day',
                start_date: moment
                  .unix(selectedFrom)
                  .tz(currentVenue?.timezone)
                  .format('YYYY-MM-DD'),
                end_date: moment.unix(selectedFrom).tz(currentVenue?.timezone).format('YYYY-MM-DD'),
                start_time_from: moment
                  .unix(selectedFrom)
                  .tz(currentVenue?.timezone)
                  .format('HH:mm:ss'),
                start_time_to: moment
                  .unix(_selectedTo)
                  .tz(currentVenue?.timezone)
                  .format('HH:mm:ss'),
                category_id: category,
                slots: maxPlayers,
                is_golf_id_required: null,
                is_prime_time: primeTime,
                space: {
                  uuid: space.uuid,
                },
              }
              promises.push(updateTimeSlotsMutation.mutateAsync(payload))
            }

            Promise.all(promises)
              .then((data) => {
                addToast(t('toast.timeSlotsUpdatedSuccess'), { appearance: 'success' })
                resolve(data)
              })
              .catch(() => {
                reject()
              })
          })
          .catch(() => {
            reject()
          })
      }),
    [
      addToast,
      t,
      currentVenue?.timezone,
      selectedFrom,
      selectedTo,
      selectedSpaces,
      getPeriodsMutation,
      updateTimeSlotsMutation,
      isNewTeeTimePeriodsEnabled,
      currentVenue?.type,
    ]
  )

  const mergeTimeSlots = useCallback(
    (selectedTeeTimes: TeeTime[]) => {
      const mixedValue = { value: undefined, isMixed: true }
      const initialValues = {
        category: { value: selectedTeeTimes[0].category.id, isMixed: false },
        maxPlayers: { value: selectedTeeTimes[0].max_slots, isMixed: false },
        primeTime: { value: selectedTeeTimes[0].is_prime_time, isMixed: false },
      }

      const { category, maxPlayers, primeTime } = selectedTeeTimes.reduce<{
        [key: string]: { value?: string | boolean | number; isMixed: boolean }
      }>((acc, teeTime) => {
        return {
          category: acc.category.value === teeTime.category.id ? acc.category : mixedValue,
          maxPlayers: acc.maxPlayers.value === teeTime.max_slots ? acc.maxPlayers : mixedValue,
          primeTime: acc.primeTime.value === teeTime.is_prime_time ? acc.primeTime : mixedValue,
        }
      }, initialValues)

      return {
        category: {
          ...category,
          value: categories.find((cat) => cat.id === (category.value as number)),
        },
        maxPlayers: {
          ...maxPlayers,
          value: String(maxPlayers.value),
        },
        primeTime: {
          ...primeTime,
          value: primeTimeOptions.find((time) => time.value === (primeTime.value as boolean)),
        },
      }
    },
    [categories, primeTimeOptions]
  )

  return {
    isFetchingCategories,
    categories,
    initialFormValues,
    mergeTimeSlots,
    primeTimeOptions,
    updateTimeSlots,
  }
}
