import { FC } from 'react'
import { useFeatureFlagsLoader } from './useFeatureFlagsLoader'

/**
 * This component is used to load the feature flags from the API and store them in the store.
 * This is a wrapper around the useFeatureFlagsLoader hook for apps that still use class components.
 * Should only be used once in the app at root level.
 */
export const FeatureFlagsLoader: FC = () => {
  useFeatureFlagsLoader()
  return null
}
