import { FilterType } from './types'

export const filters = [
  {
    id: 2,
    name: 'status',
    label: 'status',
    options: [
      { value: 'ACTIVE', label: 'active' },
      { value: 'INACTIVE', label: 'inActive' },
      { value: 'DRAFT', label: 'draft' },
    ],
  },
  {
    id: 1,
    name: 'type',
    label: 'type',
    options: [
      { value: 'MEMBERSHIP', label: 'words.membership_one' },
      { value: 'PASS', label: 'words.pass_one' },
    ],
  },
]

export const FILTER_QUERIES: Record<FilterType, string> = {
  ACTIVE: '&isActive[]=true&state[]=published',
  INACTIVE: '&isActive[]=false&state[]=published',
  DRAFT: '&state[]=draft',
  MEMBERSHIP: '&type[]=membership',
  PASS: '&type[]=pass',
}
