import { useTranslation } from 'react-i18next'

import { EmptyState, EmptyStateTitle } from '@sweetspot/scramble-ds'
import { cn } from '@sweetspot/scramble-ds/utils'

import { useAvailabilityAndPricingContext } from '../../../AvailabilityAndPricingProvider'
import PricingTable from './PricingTable'
import PricingTableInputs from './PricingTableInputs'
import PriceChangeButtonDock from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/Period/PricingTab/PricingPeriodTabContent/PriceChangeButtonDock'

const PricingPeriodTabContent = () => {
  const { t } = useTranslation()
  const { isPricingLoading, canSavePrices } = useAvailabilityAndPricingContext()

  if (isPricingLoading) {
    return (
      <EmptyState
        key="loading"
        className="mx-auto mt-10"
        iconName="fa-light fa-flip-both fa-loader bg-background-mono-lighter mb-4 flex rounded-full p-4"
        loading
      >
        <EmptyStateTitle>{t('words.loading')}</EmptyStateTitle>
      </EmptyState>
    )
  }

  return (
    <div className={cn('mt-10 flex h-full flex-col gap-4', canSavePrices ? 'mb-20' : 'mb-10')}>
      <PricingTableInputs />
      <PricingTable />
      <PriceChangeButtonDock />
    </div>
  )
}

export default PricingPeriodTabContent
