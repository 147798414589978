import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputLeadingContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemLeading,
  ListItemMainContent,
  ListItemParagraph,
} from '@sweetspot/scramble-ds'
import { cn } from '@sweetspot/scramble-ds/utils'

import { PrimeTime, TimeSlotCategory } from '../../types'
import PopoverHint from './PopoverHint'

type EditTimeSlotsFormProps = {
  category?: TimeSlotCategory
  isCategoryMixed: boolean
  setCategory: (category: string) => void
  categoryOptions: TimeSlotCategory[]
  maxPlayers?: string
  isMaxPlayersMixed: boolean
  setMaxPlayers: (maxPlayers: string) => void
  primeTime?: PrimeTime
  isPrimeTimeMixed: boolean
  setPrimeTime: (primeTime: string) => void
  primeTimeOptions: PrimeTime[]
}

const maxPlayersOptions = Array.from({ length: 4 }, (_, i) => String(i + 1))

const EditTimeSlotsForm = ({
  category,
  isCategoryMixed,
  setCategory,
  categoryOptions,
  maxPlayers,
  isMaxPlayersMixed,
  setMaxPlayers,
  primeTime,
  isPrimeTimeMixed,
  setPrimeTime,
  primeTimeOptions,
}: EditTimeSlotsFormProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex w-full flex-col gap-4">
      <InputBase>
        <InputLabelContainer>{t('words.category')}</InputLabelContainer>
        <InputSelect value={String(category?.id)} onValueChange={setCategory}>
          <InputSelectTrigger asChild>
            <InputContainer>
              {isCategoryMixed && <PopoverHint />}
              {!isCategoryMixed && category?.icon && (
                <InputLeadingContainer className="leading-[0]">
                  <i
                    key={`category-icon-${category?.iconColor}`}
                    className="fa-solid fa-square"
                    style={{ color: category?.iconColor }}
                  />
                </InputLeadingContainer>
              )}
              <InputSelectStatefulInput
                className={cn('text-content-base h-8 border-[1px] py-1', {
                  'pl-11': isCategoryMixed || category?.icon,
                })}
                value={isCategoryMixed ? t('words.mixed') : category?.label}
                placeholder={t('words.select')}
              />
              <InputSelectTrailingContainer className="right-2" />
            </InputContainer>
          </InputSelectTrigger>
          <InputSelectContent>
            <InputSelectContentView>
              {categoryOptions.map((option) => (
                <InputSelectItem key={option.id} value={String(option.id)}>
                  <ListItem className="ml-2 h-full justify-center gap-2">
                    {option.icon && (
                      <ListItemLeading className="w-6">
                        <i className="fa-solid fa-square" style={{ color: option.iconColor }} />
                      </ListItemLeading>
                    )}
                    <ListItemMainContent className="justify-center">
                      <ListItemParagraph className="text-content-base">
                        {option.label}
                      </ListItemParagraph>
                    </ListItemMainContent>
                  </ListItem>
                </InputSelectItem>
              ))}
            </InputSelectContentView>
          </InputSelectContent>
        </InputSelect>
      </InputBase>

      <InputBase>
        <InputLabelContainer>{t('sentences.maxPlayers')}</InputLabelContainer>
        <InputSelect value={maxPlayers} onValueChange={setMaxPlayers}>
          <InputSelectTrigger asChild>
            <InputContainer>
              {isMaxPlayersMixed && <PopoverHint />}
              <InputSelectStatefulInput
                className={cn('text-content-base h-8 border-[1px] py-1', {
                  'pl-11': isMaxPlayersMixed,
                })}
                value={isMaxPlayersMixed ? t('words.mixed') : maxPlayers}
                placeholder={t('words.select')}
              />
              <InputSelectTrailingContainer className="right-2" />
            </InputContainer>
          </InputSelectTrigger>
          <InputSelectContent>
            <InputSelectContentView>
              {maxPlayersOptions.map((option) => (
                <InputSelectItem key={option} value={option}>
                  <ListItem className="ml-2 h-full justify-center gap-2">
                    <ListItemMainContent className="justify-center">
                      <ListItemParagraph className="text-content-base">{option}</ListItemParagraph>
                    </ListItemMainContent>
                  </ListItem>
                </InputSelectItem>
              ))}
            </InputSelectContentView>
          </InputSelectContent>
        </InputSelect>
      </InputBase>

      <InputBase>
        <InputLabelContainer>{t('sentences.primeTime')}</InputLabelContainer>
        <InputSelect value={String(primeTime?.id)} onValueChange={setPrimeTime}>
          <InputSelectTrigger asChild>
            <InputContainer>
              {isPrimeTimeMixed && <PopoverHint />}
              <InputSelectStatefulInput
                className={cn('text-content-base h-8 border-[1px] py-1', {
                  'pl-11': isPrimeTimeMixed,
                })}
                value={isPrimeTimeMixed ? t('words.mixed') : primeTime?.label}
                placeholder={t('words.select')}
              />
              <InputSelectTrailingContainer className="right-2" />
            </InputContainer>
          </InputSelectTrigger>
          <InputSelectContent>
            <InputSelectContentView>
              {primeTimeOptions.map((option) => (
                <InputSelectItem key={option.id} value={String(option.id)}>
                  <ListItem className="ml-2 h-full justify-center gap-2">
                    <ListItemMainContent className="justify-center">
                      <ListItemParagraph className="text-content-base">
                        {option.label}
                      </ListItemParagraph>
                    </ListItemMainContent>
                  </ListItem>
                </InputSelectItem>
              ))}
            </InputSelectContentView>
          </InputSelectContent>
        </InputSelect>
      </InputBase>
    </div>
  )
}

export default EditTimeSlotsForm
