import { MembershipItem } from '@sweetspot/club-portal-legacy/store/types'
import { DataTableColumnHeader, TableSortItem } from '@sweetspot/scramble-ds'
import { Tag } from '@sweetspot/scramble-ds/atoms'
import { ColumnDef } from '@tanstack/react-table'

export const columns = (
  t: (key: string) => string,
  hasCdhNumber: boolean,
  sortItems: TableSortItem[],
  setSortItems: (items: TableSortItem[]) => void
): ColumnDef<MembershipItem>[] => {
  return [
    {
      id: 'move',
      header: () => <i className="fa-regular fa-grip-dots text-base" />,
      enableSorting: false,
    },
    {
      accessorKey: 'status',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('status') },
      cell: ({ row }) => (
        <Tag.Root variant={row.getValue('status')?.variant} className="mt-1 w-max px-2">
          {t(row.getValue('status')?.text)}
        </Tag.Root>
      ),

      enableSorting: false,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id))
      },
    },
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} sortItems={sortItems} setSortItems={setSortItems} />
      ),
      meta: { title: t('name') },
      cell: ({ row }) => row.getValue('name'),
    },
    {
      accessorKey: 'isSellMembershipEnabled',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('membership.overview.isSellMembershipEnabled') },
      cell: ({ row }) => {
        const isSellMembershipEnabled = row.getValue('isSellMembershipEnabled') as string

        if (isSellMembershipEnabled === 'no') {
          return t(isSellMembershipEnabled)
        }

        return `${t(isSellMembershipEnabled)}/${t(`words.${row.original.public}`)}`
      },
      enableSorting: false,
    },
    {
      accessorKey: 'type',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('membership.overview.type') },
      cell: ({ row }) => t(row.getValue('type')),
      enableSorting: false,
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id))
      },
    },
    {
      accessorKey: 'age_limit',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('membership.overview.ageRange') },
      cell: ({ row }) => row.getValue('age_limit'),
      enableSorting: false,
    },
    {
      accessorKey: 'seats_limit',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('membership.overview.seats') },
      cell: ({ row }) => t(row.getValue('seats_limit')),
      enableSorting: false,
    },
    {
      accessorKey: 'totalPriceFormatted',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('membership.overview.totalPrice') },
      cell: ({ row }) => t(row.getValue('totalPriceFormatted')),
      enableSorting: false,
    },
    {
      accessorKey: 'totalMonthlyFormatted',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('membership.overview.totalMonthlyPrice') },
      cell: ({ row }) => t(row.getValue('totalMonthlyFormatted')),
      enableSorting: false,
    },
    {
      accessorKey: 'duration',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('membership.overview.duration') },
      cell: ({ row }) => {
        const durations = row.getValue('duration')
        if (!Array.isArray(durations)) return ''

        return durations.map((duration) => t(duration)).join(', ')
      },

      enableSorting: false,
    },
    {
      accessorKey: 'gitSync',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: {
        title: hasCdhNumber ? t('membership.overview.cdhSync') : t('membership.overview.gitSync'),
      },
      cell: ({ row }) => {
        const gitSync = row.getValue('gitSync') as string
        if (gitSync === '-') return gitSync
        return (
          <Tag.Root variant="success-filled" className="mt-1 w-max px-2">
            {t(gitSync)}
          </Tag.Root>
        )
      },
      enableSorting: false,
    },
    {
      accessorKey: 'playRights',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('membership.overview.playRights') },
      cell: ({ row }) => t(row.getValue('playRights')),
      enableSorting: false,
    },
  ]
}
