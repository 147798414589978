import { CONSTANTS } from '../constants'
import { addToast } from '@sweetspot/club-portal-legacy/store/actions/appStateActions'
import {
  _addGuideImage,
  _deleteGolfCourse,
  _getCourseGuideImages,
  _getCourseGuide,
  _getGolfCourseImages,
  _getSpecificGolfCourse,
  _importCourseGuideFromCaddee,
  _updateGolfCourse,
  _uploadGolfCourseImage,
  _updateCourseGuide,
  _getBookings,
  _createCourseGuide,
  _createTeeTimeCategory,
  _updateTeeTimeCategory,
  _getReservationPolicy,
  _createReservationPolicy,
  _updateReservationPolicy,
  _removeReservationPolicy,
  _getTeeTimeCategory,
} from '@sweetspot/club-portal-legacy/services/golfCourseApi'
import { getBookingPeriodsByGolfcourse } from '@sweetspot/club-portal-legacy/store/actions/bookingPeriodActions'

import { executePaginatedActionForPlatform } from '@sweetspot/club-portal-legacy/store/actions/executeAction'
import { saveData } from '@sweetspot/club-portal-legacy/store/storage'
import { queryTeeTimes } from '@sweetspot/sweetspot-js/features/teeTimes/services/api-platform'

export function getSpecificGolfCourse(token, id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _getSpecificGolfCourse(id)
        .then((res) => {
          dispatch({
            type: CONSTANTS.GOLF_COURSE.GET_SPECIFIC,
            payload: res,
          })

          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('fetchSpecificGolfcourseError', 'error'))

          return reject(err)
        })
    })
  }
}

export function updateGolfCourse(token, courseId, payload) {
  return (dispatch, getState) => {
    const { list: golfCourseList } = getState().golfCourse
    const activeCourse = golfCourseList.find((course) => course.id === courseId)
    return new Promise((resolve, reject) => {
      _updateGolfCourse(courseId, payload)
        .then((res) => {
          dispatch({
            type: CONSTANTS.GOLF_COURSE.UPDATE,
            payload: { ...activeCourse, ...payload },
          })
          dispatch(addToast('golfcourseModified', 'success'))

          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('golfcourseModifyError', 'error'))

          return reject(err)
        })
    })
  }
}

export function deleteGolfCourse(token, id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _deleteGolfCourse(id)
        .then(() => {
          dispatch({
            type: CONSTANTS.GOLF_COURSE.DELETE,
            payload: id,
          })
          dispatch(addToast('golfcourseDeleted', 'success'))

          return resolve(id)
        })
        .catch((err) => {
          dispatch(addToast('golfcourseDeleteError', 'error'))

          return reject(err)
        })
    })
  }
}

/**
 *
 * @param {string} courseUuid
 * @param {string} startIso
 * @param {string} endIso
 * @returns
 */
export function getTeeTimesForDayFromGolfCourse(courseUuid, startIso, endIso) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      queryTeeTimes({
        'course.uuid': courseUuid,
        'from[after]': startIso,
        'from[before]': endIso,
        limit: 999,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          if (courseUuid > 1) dispatch(addToast('fetchTeeTimesError', 'error'))
          return reject(err)
        })
    })
  }
}

export function selectGolfCourse(id) {
  return (dispatch, getState) => {
    saveData('selectedGolfCourse', id)

    dispatch({
      type: CONSTANTS.GOLF_COURSE.SELECT,
      payload: id,
    })
  }
}

export function uploadGolfCourseImage(token, id, image) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _uploadGolfCourseImage(id, image)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('uploadImageError', 'error'))
          return reject(err)
        })
    })
  }
}

export function getGolfCourseImages(id) {
  return () => {
    return new Promise((resolve, reject) => {
      _getGolfCourseImages(id)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          return reject(err)
        })
    })
  }
}

export function createCourseGuide(token, payload) {
  return () => {
    return new Promise((resolve, reject) => {
      _createCourseGuide(payload)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          return reject(err)
        })
    })
  }
}

export function getCourseGuide(token, id) {
  return () => {
    return new Promise((resolve, reject) => {
      _getCourseGuide(id)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          return reject(err)
        })
    })
  }
}

export function uploadCourseGuideImage(token, id, image, hole) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _addGuideImage(id, image, hole)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('uploadImageError', 'error'))
          return reject(err)
        })
    })
  }
}

export function getCourseGuideImages(token, id) {
  return () => {
    return new Promise((resolve, reject) => {
      _getCourseGuideImages(id)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          return reject(err)
        })
    })
  }
}

export function importCourseGuideFromCaddee(token, id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _importCourseGuideFromCaddee(id)
        .then((res) => {
          dispatch(addToast('caddeeImportSuccess', 'success'))
          return resolve(res)
        })
        .catch((err) => {
          if (err?.message && err?.status === 404) {
            switch (err.message) {
              case 'has_no_images':
                dispatch(addToast('noCourseGuideFoundError', 'error'))
                break
              case 'has_no_match_in_caddee':
                dispatch(addToast('noCourseFoundError', 'error'))
                break
              default:
                dispatch(addToast('noCourseFoundError', 'error'))
                break
            }
          } else {
            dispatch(addToast('caddeeImportError', 'error'))
          }
          return reject()
        })
    })
  }
}

export function updateCourseGuide(token, id, payload) {
  return () => {
    return new Promise((resolve, reject) => {
      _updateCourseGuide(id, payload)
        .then(() => {
          return resolve(id)
        })
        .catch((err) => {
          return reject(err)
        })
    })
  }
}

export function getBookings(token, query) {
  return () => {
    return new Promise((resolve, reject) => {
      executePaginatedActionForPlatform(_getBookings, query)
        .then((data) => {
          return resolve(data)
        })
        .catch(reject)
    })
  }
}

export function getTeeTimeCategory(token, id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _getTeeTimeCategory(id)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('getCategoryError', 'error'))
          return reject(err)
        })
    })
  }
}

export function createTeeTimeCategory(token, payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _createTeeTimeCategory(payload)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          let errMsg = 'createCategoryError'
          if (err?.detail === 'Name is already used for some category') {
            errMsg = 'existingCategoryNameError'
          }
          if (
            err.violations?.length &&
            err.violations[0].message === 'This value should not be blank.'
          ) {
            errMsg = 'categoryNameRequired'
          }
          dispatch(addToast(errMsg, 'error'))
          return reject(err)
        })
    })
  }
}

export function updateTeeTimeCategory(token, payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _updateTeeTimeCategory(payload)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          let errMsg = 'updateCategoryError'
          if (err?.detail === 'Name is already used for some category') {
            errMsg = 'existingCategoryNameError'
          }
          if (
            err.violations?.length &&
            err.violations[0].message === 'This value should not be blank.'
          ) {
            errMsg = 'categoryNameRequired'
          }
          dispatch(addToast(errMsg, 'error'))
          return reject(err)
        })
    })
  }
}

export function getReservationPolicy(token, policyUuid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _getReservationPolicy(policyUuid)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('updateCategoryError', 'error'))
          return reject(err)
        })
    })
  }
}

export function createReservationPolicy(token, policyUuid, payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _createReservationPolicy(policyUuid, payload)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('createReservationPolicyError', 'error'))
          return reject(err)
        })
    })
  }
}

export function updateReservationPolicy(token, checkerUuid, payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _updateReservationPolicy(checkerUuid, payload)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('updateReservationPolicyError', 'error'))
          return reject(err)
        })
    })
  }
}

export function removeReservationPolicy(token, checkerUuid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _removeReservationPolicy(checkerUuid)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('removeReservationPolicyError', 'error'))
          return reject(err)
        })
    })
  }
}
