import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Venue, ViolationError } from '@sweetspot/shared/types'
import { queryCourses } from '@sweetspot/sweetspot-js/features/courses/services/api-platform'
import { CONSTANTS } from '@sweetspot/club-portal-legacy/store/constants'
import { isViolationError } from '@sweetspot/shared/util/validators'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'

import { useManageExtrasContext } from '../ManageExtrasProvider'
import { _addGolfCourseToGolfClub } from '@sweetspot/club-portal-legacy/services/golfClubApi'
import { CreateExtraPayload } from '../types'

const useCreateExtrasMutation = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const history = useHistory()
  const currentClubId = useSelector((state: RootState) => state.golfClub.selectedId)
  const { toggleCreateExtrasSideBar, refetchExtras } = useManageExtrasContext()

  const createExtrasMutation = useMutation(
    (data: CreateExtraPayload) => _addGolfCourseToGolfClub(currentClubId, data),
    {
      onSuccess: async (response) => {
        addToast(t('toast.courseCreated', { type: t('words.extras_one') }), {
          appearance: 'success',
        })
        toggleCreateExtrasSideBar()
        const path = history.location.pathname
        history.push(`${path}#${response?.id}`)
        // the corresponding course needs a bit time to be created after the extra is created
        setTimeout(async () => {
          refetchExtras()
          const courses: Venue[] = await queryCourses({ uuid: response.uuid })

          if (courses?.length) {
            dispatch({
              type: CONSTANTS.GOLF_COURSE.CREATE,
              payload: courses[0],
            })
          }
        }, 2000)
      },
      onError: (error: { error: string } | ViolationError) => {
        if (isViolationError(error)) {
          addToast(t('toast.defaultError'), { appearance: 'error' })
        } else {
          if (error?.error?.includes('name: Name should be unique per golf club')) {
            addToast(t('sentences.academyNameAlreadyExists'), { appearance: 'error' })
          } else {
            addToast(t('toast.defaultError'), { appearance: 'error' })
          }
        }
      },
    }
  )

  return createExtrasMutation
}

export default useCreateExtrasMutation
