import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

import { getGolfClubById } from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'

import { RootState } from '../store/types'

const useClubs = () => {
  const currentClubId = useSelector((state: RootState) => state.golfClub.selectedId)

  const { data: currentClub, refetch: fetchClub } = useQuery(
    [CLUB_QUERIES.CLUBS, currentClubId],
    () => getGolfClubById(currentClubId),
    {
      enabled: !!currentClubId,
    }
  )

  return {
    currentClub,
    fetchClub,
  }
}

export default useClubs
