import {
  BayTypeV2,
  Period,
  Range,
  TeeTime,
  TeeTimeCategory,
  ViolationError,
} from '@sweetspot/shared/types'
import { GolfCourseItem } from '@sweetspot/club-portal-legacy/store/types'
import { UseMutateAsyncFunction } from 'react-query'
import React from 'react'

export enum SideBarMode {
  CREATE_PERIOD = 'CREATE PERIOD',
  ALL_PERIODS = 'ALL PERIODS',
  CREATE_ADJUSTMENT = 'CREATE ADJUSTMENT',
}

export type AvailabilityAndPricingContextValue = {
  areRangesLoading: boolean
  selectedRange?: Range
  setSelectedRange?: (range: Range) => void
  ranges: Range[]
  refetchRanges: () => void
  periods: Period[]
  arePeriodsLoading: boolean
  arePeriodsFetching: boolean
  refetchPeriods: () => void
  selectedPeriod: Period | null
  setSelectedPeriod: (period: Period) => void
  selectPeriodByUuid: (periodUuid: string) => void
  currentCourse: GolfCourseItem | null
  isSideBarOpen: boolean
  toggleSideBar: (mode?: SideBarMode) => void
  toggleAllPeriodsSideBar: () => void
  toggleCreatePeriodSideBar: () => void
  toggleCreateAdjustmentSideBar: () => void
  changeSideBarMode: (mode?: SideBarMode) => void
  sideBarMode?: SideBarMode
  categories: TeeTimeCategory[]
  groupOptions: string[]
  adjustments: PeriodAdjustment[]
  bays: BayTypeV2[]
  selectedBayIds: string[]
  setSelectedBayIds: React.Dispatch<React.SetStateAction<string[]>>
  price: number
  setPrice: (price: number) => void
  pricePerExtraPlayer: number
  setPricePerExtraPlayer: (pricePerExtraPlayer: number) => void
  selectedCells: Set<string>
  allCheckbox: AllCheckboxValues | null
  onAllCheckboxChange: (value: boolean) => void
  DAYS_OF_WEEK: string[]
  activePeriodTab?: number
  setActivePeriodTab: (tab: number) => void
  weekPeriodOptions: WeekPeriodOption[]
  timeSlots: PeriodPricingTimeSlots
  tableData: PeriodPricingTableData
  daysMap: PeriodPricingDaysMap
  onColumnClick: (index: number, isSelected?: boolean) => void
  selectedCols: boolean[] | null
  onRowClick: (index: number, isSelected?: boolean) => void
  selectedRows: boolean[] | null
  onCellClick: (cellId: string, isSelected?: boolean) => void
  isPricingFetching: boolean
  isPricingLoading: boolean
  hasPricings: boolean
  onPricesApply: () => void
  canSavePrices: boolean
  onPricesSubmit: (isAllChanges?: boolean) => void
  onPricesDiscard: () => void
  arePeriodsAdjustmentsLoading: boolean
  refetchPeriodAdjustments: () => void
  deletePeriod: UseMutateAsyncFunction<any, ViolationError, string>
  isDeletingPeriod: boolean
  isPricingUpdating: boolean
}

export type PeriodFormSelectFieldOption = {
  label: string
  value: string
}

export const bookingDurationOptions: PeriodFormSelectFieldOption[] = [
  { label: '30 min', value: '30' },
  { label: '60 min', value: '60' },
  { label: '90 min', value: '90' },
  { label: '120 min', value: '120' },
]

export const slotOptions: PeriodFormSelectFieldOption[] = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
]

export const weekDaysOptions: PeriodFormSelectFieldOption[] = [
  { label: 'dateTime.words.allDays', value: 'every_day' },
  { label: 'dateTime.longDays.monday_one', value: 'monday' },
  { label: 'dateTime.longDays.tuesday_one', value: 'tuesday' },
  { label: 'dateTime.longDays.wednesday_one', value: 'wednesday' },
  { label: 'dateTime.longDays.thursday_one', value: 'thursday' },
  { label: 'dateTime.longDays.friday_one', value: 'friday' },
  { label: 'dateTime.longDays.saturday_one', value: 'saturday' },
  { label: 'dateTime.longDays.sunday_one', value: 'sunday' },
]

export type PeriodFormValue = {
  name?: string
  startDate?: string
  endDate?: string
  startTimeFrom?: string
  startTimeTo?: string
  interval?: number
}

export type PeriodFormError = {
  name?: string
  openingHours?: string
  dateRange?: string
}

export type CreatePeriodPayload = {
  name: string
  start_date: string
  category_id: number
  end_date: string
  start_time_from: string
  start_time_to: string
  interval: number
  slots: number
  is_golf_id_required: boolean
  is_prime_time: boolean
}

export type CreateAdjustmentPayload = {
  name: string
  repeat_on: string
  start_date: string
  category_id: number
  end_date: string
  start_time_from: string
  start_time_to: string
  slots: number
  is_golf_id_required: boolean
  is_prime_time: boolean
  space?: {
    uuid: string
  }
}

export enum PeriodTabsEnum {
  PRICING = 'PRICING',
  ADJUSTMENTS = 'ADJUSTMENTS',
}

export enum PricingBayType {
  GROUP = 'GROUP',
  BAYS = 'BAYS',
}

export type WeekPeriodOption = {
  id: number
  name: string
  fromDate: Date
  toDate: Date
}

export enum AllCheckboxValues {
  ALL = 'all',
  SOME = 'some',
}

export type PeriodPricingTableDataPrice = {
  amount: number
  currency: string
} | null

export type PeriodPricingTableDataTimeSlot = Omit<TeeTime, 'price'> & {
  price: PeriodPricingTableDataPrice
}

export type PeriodPricingTimeSlots = string[]

export type PeriodPricingDaysMap = { [key: string]: string }

export type PeriodPricingTableData = {
  [key: string]: {
    [key: string]: PeriodPricingTableDataTimeSlot[]
  }
}

export type PeriodAdjustment = {
  id: number
  created_at: string
  created_by: string
  name: string
  start_date: string
  end_date: string
  start_time_from: string
  start_time_to: string
  slots: number
  is_golf_id_required: boolean
  is_prime_time: boolean
  repeat_on: string
  category: TeeTimeCategory
  space?: {
    uuid: string
  }
}

export type AllTableData = {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        [key: string]: boolean | (PeriodPricingTableDataTimeSlot & { hasChanged: boolean })[]
      }
    }
  }
}

export type PriceTableDataFlatSlot = Pick<
  PeriodPricingTableDataTimeSlot,
  'uuid' | 'price' | 'price_per_extra_player'
>

export type PriceFormState = {
  isAllChanges?: boolean
  hasUpdated: boolean
  price: number
  price_per_extra_player: number
  space: string
  week: number
}

export const WHOLE_PERIOD_TABLE_DATA_KEY = 'all'
