import { useTranslation } from 'react-i18next'

import { Button } from '@sweetspot/scramble-ds/atoms'
import {
  EmptyState,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'

import { useAvailabilityAndPricingContext } from '../../AvailabilityAndPricingProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import React from 'react'
import AdjustmentsTable from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/Period/AdjustmentsTab/AdjustmentsTable'

const AdjustmentsTab = () => {
  const { t } = useTranslation()
  const { adjustments, toggleCreateAdjustmentSideBar } = useAvailabilityAndPricingContext()

  if (!adjustments.length) {
    return (
      <EmptyState
        key="no-adjustments"
        iconName="fa-regular fa-calendar-pen"
        className="max-w-[372px] px-6"
        iconClassName={
          'mb-lg bg-background-mono-lighter rounded-full p-4 w-[92px] h-[92px] flex justify-center items-center'
        }
      >
        <EmptyStateTitle>{t('sentences.noPeriodAdjustments')}</EmptyStateTitle>
        <EmptyStateDescription>
          <div className="mb-4 text-center">{t('sentences.noPeriodAdjustmentsDescription')}</div>
        </EmptyStateDescription>
        <EmptyStateButton
          className="text-content-sm font-medium"
          onClick={toggleCreateAdjustmentSideBar}
        >
          {t('sentences.createAdjustment')}
        </EmptyStateButton>
      </EmptyState>
    )
  }

  return (
    <div className="w-full">
      <Button
        rounding="rectangular"
        size="small"
        variant="clear-dark"
        onClick={toggleCreateAdjustmentSideBar}
        className="text-content-sm text-text-dark absolute -top-6 right-0 mt-7 overflow-hidden text-ellipsis whitespace-nowrap pt-0.5 font-medium"
      >
        <FontAwesomeIcon icon={faPlus} />
        {t('words.adjustment')}
      </Button>
      <AdjustmentsTable />
    </div>
  )
}

export default AdjustmentsTab
