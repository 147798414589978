import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import { InputLeadingContainer } from '@sweetspot/scramble-ds'
import { Popover } from '@sweetspot/scramble-ds/atoms'

const PopoverHint = () => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <Popover.Root open={open}>
      <Popover.Trigger asChild>
        <InputLeadingContainer
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <i key="category-mixed" className="fa-regular fa-arrows-cross text-text-warning" />
        </InputLeadingContainer>
      </Popover.Trigger>
      <Popover.Content
        align="center"
        className="text-content-sm bg-base-black text-base-white z-[99] w-[306px] px-4 py-6"
      >
        <Trans
          i18nKey="sentences.mixedValuesHint"
          components={{ strong: <strong />, br: <br /> }}
        />
        <Popover.Arrow className="fill-black" />
      </Popover.Content>
    </Popover.Root>
  )
}

export default PopoverHint
