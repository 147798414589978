import { Resource as ResourceAPI } from '@sweetspot/shared/data-access/api-platform'

export enum SideBarMode {
  CREATE_RESOURCE = 'CREATE RESOURCE',
  ABOUT_RESOURCES = 'ABOUT RESOURCES',
  RESOURCES_ACTION_ITEMS = 'RESOURCES ACTION ITEMS',
  RESOURCE_ACTION_ITEMS = 'RESOURCE ACTION ITEMS',
}

export interface Resource extends ResourceAPI {
  isNew?: boolean
}
