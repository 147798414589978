import { useEffect } from 'react'

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@sweetspot/scramble-ds'

import { useAvailabilityAndPricingContext } from '../../AvailabilityAndPricingProvider'
import PricingPeriodTabContent from './PricingPeriodTabContent/PricingPeriodTabContent'

const PricingPeriodTabs = () => {
  const { activePeriodTab, setActivePeriodTab, weekPeriodOptions, selectedPeriod } =
    useAvailabilityAndPricingContext()

  // set active tab to the first one
  useEffect(() => {
    if (weekPeriodOptions.length && !activePeriodTab) {
      if (!selectedPeriod?.period_price_status) {
        setActivePeriodTab(weekPeriodOptions[0].id)
      } else {
        setActivePeriodTab(weekPeriodOptions[1].id)
      }
    }
  }, [weekPeriodOptions, setActivePeriodTab, activePeriodTab, selectedPeriod?.period_price_status])

  return (
    <div className="flex w-full pt-8">
      <Tabs
        value={String(activePeriodTab)}
        onValueChange={(tab) => setActivePeriodTab(+tab)}
        className="h-full w-full"
      >
        <TabsList className="justify-start rounded-none border-b">
          {weekPeriodOptions.map((option) => (
            <TabsTrigger
              key={`${option.id}-trigger`}
              value={`${option.id}`}
              className="-mb-1.5 w-auto px-4"
              disabled={
                (option.id !== -1 && !selectedPeriod?.period_price_status) ||
                (option.id === -1 && !!selectedPeriod?.period_price_status)
              }
            >
              {option.name}
            </TabsTrigger>
          ))}
        </TabsList>
        {weekPeriodOptions.map((option) => (
          <TabsContent
            key={`${option.id}-content`}
            value={`${option.id}`}
            className="h-[calc(100%-48px)]"
          >
            <PricingPeriodTabContent />
          </TabsContent>
        ))}
      </Tabs>
    </div>
  )
}

export default PricingPeriodTabs
