import { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

import { Button } from '@sweetspot/scramble-ds/atoms'
import {
  ListItem,
  ListItemLeading,
  ListItemMainContent,
  ListItemMainLabel,
  ListItemParagraph,
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { BayTypeV2, TrackingTech } from '@sweetspot/shared/types'
import { capitalize } from '@sweetspot/sweetspot-js/common/functions/utils'
import { BayTechLogo } from '@sweetspot/club-portal/feature/venues'
import { connectNumbers } from '@sweetspot/shared/util/number'

type QRCodeSideBarProps = {
  open: boolean
  handleOpenChange: (open?: boolean) => void
  qrCode: string
  bays: BayTypeV2[]
  rangeName: string
  totalNrOfBalls: number
  formattedStartDate: string
  bookingTime: string
}

const QRCodeSideBar = ({
  open,
  handleOpenChange,
  qrCode,
  bays,
  rangeName,
  totalNrOfBalls,
  formattedStartDate,
  bookingTime,
}: QRCodeSideBarProps) => {
  const { t } = useTranslation()
  const contentToPrintRef = useRef(null)
  const handlePrint = useReactToPrint({ content: () => contentToPrintRef.current })

  const bayTechs = useMemo(
    () =>
      bays?.reduce<TrackingTech[]>((acc, bay) => {
        const tech = bay.ball_tracking_technology.tracking_provider
        if (tech && !acc.includes(tech)) {
          return [...acc, tech]
        }
        return acc
      }, []),
    [bays]
  )

  const bayTechLogo = useMemo(
    () => (bayTechs?.includes(TrackingTech.TOPTRACER) ? TrackingTech.TOPTRACER : TrackingTech.NONE),
    [bayTechs]
  )

  const bayTechText = useMemo(
    () =>
      bayTechs
        ?.map((tech) => (tech === TrackingTech.NONE ? t('words.standard') : capitalize(tech)))
        .join(' & '),
    [bayTechs, t]
  )

  const bayNumbers = useMemo(() => {
    const numbers = bays?.map((bay) => bay.bay_number).sort((a, b) => a - b)
    return `${capitalize(t('words.bay_one'))} ${connectNumbers(numbers)}`
  }, [bays, t])

  const bayFloors = useMemo(() => {
    const floors = bays
      ?.map((bay) => bay.floor)
      .filter((floor, index, array) => array.indexOf(floor) === index && typeof floor === 'number')
      .sort((a, b) => (a && b ? a - b : 1))
    let floorsText = ''
    if (floors?.length === 1) {
      floorsText = `${floors[0]}`
    } else if (floors?.length > 1) {
      const lastFloor = floors.pop()
      floorsText = [floors.join(', '), lastFloor].join(' & ')
    }
    return `${capitalize(t('words.floor'))} ${floorsText}`
  }, [bays, t])

  return (
    <Sheet open={open} onOpenChange={handleOpenChange}>
      <SheetContent className="!max-w-[480px]">
        <SheetHeader>
          <SheetHeaderLeftIcon onClick={() => handleOpenChange(false)}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>{t('words.qrCode')}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent>
          <div
            ref={contentToPrintRef}
            className="margin flex flex-col items-center justify-center gap-6 text-center"
          >
            <div className="border-border-stroke-clean flex h-fit max-w-[343px] flex-col items-center rounded-md border px-12 py-4">
              <div className="text-content-base pb-7 font-bold">
                {t('sentences.ballDispenserQR')}
              </div>
              <img className="w-[247px]" src={qrCode} alt="qr-code" />
              <div className="text-content-sm pt-6 font-medium">
                {t('sentences.ballDispenserQRHint')}
              </div>
            </div>
            <div className="border-border-stroke-clean flex h-fit w-full max-w-[343px] flex-col rounded-md border px-2 pb-4 pt-5">
              <div className="text-content-lg text-text-dark px-2 pb-1 text-left font-bold">
                {rangeName}
              </div>
              <ListItem>
                <ListItemLeading className="px-0">
                  <BayTechLogo className="w-4" technology={bayTechLogo} />
                </ListItemLeading>
                <ListItemMainContent className="border-b">
                  <ListItemMainLabel className="text-text-dark">{bayTechText}</ListItemMainLabel>
                </ListItemMainContent>
              </ListItem>
              <ListItem>
                <ListItemLeading className="w-6">
                  <i className="fa-regular fa-location-dot" />
                </ListItemLeading>
                <ListItemMainContent className="border-b">
                  <ListItemMainLabel className="text-text-dark">{bayNumbers}</ListItemMainLabel>
                  <ListItemParagraph className="text-text-subtle">{bayFloors}</ListItemParagraph>
                </ListItemMainContent>
              </ListItem>
              <ListItem>
                <ListItemLeading className="w-6">
                  <i className="fa-regular fa-ball-pile" />
                </ListItemLeading>
                <ListItemMainContent className="border-b">
                  <ListItemMainLabel className="text-text-dark">
                    {totalNrOfBalls} {t('words.balls', { count: totalNrOfBalls }).toLowerCase()}
                  </ListItemMainLabel>
                </ListItemMainContent>
              </ListItem>
              <ListItem>
                <ListItemLeading className="w-6">
                  <i className="fa-regular fa-clock" />
                </ListItemLeading>
                <ListItemMainContent className="border-b">
                  <ListItemMainLabel className="text-text-dark">{bookingTime}</ListItemMainLabel>
                </ListItemMainContent>
              </ListItem>
              <ListItem>
                <ListItemLeading className="w-6">
                  <i className="fa-regular fa-calendar" />
                </ListItemLeading>
                <ListItemMainContent>
                  <ListItemMainLabel className="text-text-dark">
                    {formattedStartDate}
                  </ListItemMainLabel>
                </ListItemMainContent>
              </ListItem>
            </div>
          </div>
        </SheetCustomContent>
        <SheetFooter>
          <Button onClick={handlePrint} className="min-h-touch-height-lg w-full">
            <i className="fa-regular fa-print" />
            {t('words.print')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

export default QRCodeSideBar
