import { useTranslation } from 'react-i18next'
import { useResourcesStore } from '../../../../hooks/resources/useResourcesStore'
import { useIsMobile } from '../../../../hooks/useIsMobile'

const Title = () => {
  const { selectedResource } = useResourcesStore()
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  if (isMobile && selectedResource) {
    return (
      <p className="text-content-lg text-text-dark overflow-hidden text-ellipsis whitespace-nowrap font-bold">
        {selectedResource.name}
      </p>
    )
  }

  return <p className="text-text-dark text-content-lg font-bold">{t('words.resources')}</p>
}
export default Title
