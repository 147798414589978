import { Tag } from '@sweetspot/scramble-ds/atoms'
import { PartialPaymentTagProps } from '../types'

export const PartialPaymentTag = ({ condition, variant, text }: PartialPaymentTagProps) => {
  return condition ? (
    <Tag.Root variant={variant} className="text-text-dark mt-1 w-max px-2 font-semibold">
      {text}
    </Tag.Root>
  ) : null
}
