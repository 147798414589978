import { type Column } from '@tanstack/react-table'
import React from 'react'
import { cn } from '../../../../utils'
import { Button, Popover } from '../../../atoms'
import { TableSortItem } from '../types'

interface DataTableColumnHeaderProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>
  sortItems?: TableSortItem[]
  setSortItems?: (items: TableSortItem[]) => void
}

export const DataTableColumnHeader = <TData, TValue>({
  column,
  className,
  setSortItems,
  sortItems,
}: DataTableColumnHeaderProps<TData, TValue>) => {
  const [isPopoverOpen, setPopoverOpen] = React.useState(false)

  const columnTitle = column.columnDef.meta?.title.toUpperCase()
  const columnKey = column.columnDef.accessorKey as string

  const handleSort = (sortOrder: 'asc' | 'desc') => {
    if (setSortItems) {
      const sortItem = sortItems?.find((item) => item.name === columnKey)
      const currentSort = sortItem?.type

      let newSortType: 'asc' | 'desc' | undefined

      if (currentSort === sortOrder) {
        newSortType = undefined
      } else {
        newSortType = sortOrder
      }

      const newSortItems = newSortType ? [{ name: columnKey, type: newSortType }] : []
      setSortItems(newSortItems)
    } else {
      const isCurrentlySorted = column.getIsSorted()

      if (isCurrentlySorted) {
        column.toggleSorting(isCurrentlySorted === 'asc')
      } else {
        column.toggleSorting(false)
      }
    }
  }

  const isSortedAsc = sortItems?.find((item) => item.name === columnKey && item.type === 'asc')
  const isSortedDesc = sortItems?.find((item) => item.name === columnKey && item.type === 'desc')

  if (!column.getCanSort()) {
    return <span className={cn('line-clamp-2 font-bold', className)}>{columnTitle}</span>
  }

  const togglePopover = () => {
    setPopoverOpen((prev) => !prev)
  }

  return (
    <div className={cn('flex items-center gap-4', className)}>
      <span className="line-clamp-2 font-bold">{columnTitle}</span>
      <Popover.Root open={isPopoverOpen} onOpenChange={setPopoverOpen}>
        <Popover.Trigger asChild>
          <Button
            size="medium"
            variant="clear-dark"
            rounding="pill"
            className="hover:!text-text-dark hover:bg-transparent hover:shadow-none"
            onClick={togglePopover}
          >
            <i className="fa-regular fa-ellipsis-vertical cursor-pointer text-base" />
          </Button>
        </Popover.Trigger>
        <Popover.Content className="flex w-[100px] flex-col gap-1 p-2">
          <Button
            variant="clear-dark"
            size="small"
            className={cn({
              'bg-surface-primary-hover text-text-light shadow-lg':
                isSortedAsc || column.getIsSorted() === 'asc',
            })}
            onClick={() => handleSort('asc')}
          >
            <i className="fa-regular fa-arrow-up mr-auto" />
            Asc
          </Button>
          <Button
            variant="clear-dark"
            size="small"
            className={cn({
              'bg-surface-primary-hover text-text-light shadow-lg':
                isSortedDesc || column.getIsSorted() === 'desc',
            })}
            onClick={() => handleSort('desc')}
          >
            <i className="fa-regular fa-arrow-down mr-auto" />
            Desc
          </Button>
          <Popover.Arrow className="fill-white" />
        </Popover.Content>
      </Popover.Root>
    </div>
  )
}
