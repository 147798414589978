import {
  _getPlayers,
  _exportPlayers,
  _getPlayerCard,
  _getPlayerRight,
  _getFederationInfo,
  _unlinkGolfId,
} from '@sweetspot/club-portal-legacy/services/playerApi'

import { addToast } from '@sweetspot/club-portal-legacy/store/actions/appStateActions'
import {
  deletePlayerById,
  patchPlayerById,
} from '@sweetspot/sweetspot-js/features/players/services/api-platform'

export function updatePlayer(payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      patchPlayerById(payload.id, payload)
        .then((res) => {
          dispatch(addToast('modifiedUser', 'success'))

          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('modifiedUserError', 'error'))

          return reject(err)
        })
    })
  }
}

export function deletePlayer(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      deletePlayerById(id)
        .then((res) => {
          dispatch(addToast('deletedUser', 'success'))
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('deleteUserError', 'error'))
          return reject(err)
        })
    })
  }
}

export function getPlayers(token, queryString) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _getPlayers(queryString)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('getClubMembersError', 'error'))
          return reject(err)
        })
    })
  }
}

export function exportPlayers(clubId, filterQuery) {
  return () => {
    return new Promise((resolve, reject) => {
      _exportPlayers(clubId, filterQuery)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          return reject(err)
        })
    })
  }
}

export function getPlayerCard(playerId) {
  return new Promise((resolve, reject) => {
    _getPlayerCard(playerId)
      .then((res) => {
        return resolve(res)
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

export function getPlayerRight(golfClubId, playerId) {
  return new Promise((resolve, reject) => {
    _getPlayerRight(golfClubId, playerId)
      .then((res) => {
        return resolve(res)
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

export function getFederationInfo(playerId, gitId) {
  return new Promise((resolve, reject) => {
    _getFederationInfo(playerId, gitId)
      .then((res) => {
        return resolve(res)
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

export function unlinkGolfId(token, playerId, federation) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _unlinkGolfId(playerId, federation)
        .then((res) => {
          dispatch(
            addToast(
              federation === 'git' ? 'unlinkGolfIdSuccess' : 'unlinkCdhNumberSuccess',
              'success'
            )
          )
          return resolve(res)
        })
        .catch((err) => {
          dispatch(
            addToast(federation === 'git' ? 'unlinkGolfIdError' : 'unlinkCdhNumberError', 'error')
          )
          return reject(err)
        })
    })
  }
}

export const getPlayerData = async (playerId, gitId, selectedId) => {
  const promises = [
    getPlayerCard(playerId),
    getPlayerRight(selectedId, playerId),
    getFederationInfo(playerId, gitId),
  ]

  const [cardInfo, playRight, federationInfo] = await Promise.all(promises)

  return { cardInfo, playRight, federationInfo }
}
