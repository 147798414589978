import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import style from './style.module.scss'
import i18next from 'i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'

import MiniCalendar from '../MiniCalendar'
import DOMHelpers from '../../helpers/DOMHelpers'
import { Button } from '@sweetspot/scramble-ds/atoms'
import { faArrowLeft, faArrowRight, faCalendar } from '@fortawesome/pro-regular-svg-icons'
import cx from 'classnames'

/**
 * This component creates date input with a dropdown calendar
 */
export default class CalendarDropdown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }

    this.getDate = this.getDate.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.setInputValue = this.setInputValue.bind(this)
    this.handleLocaleChange = this.handleLocaleChange.bind(this)
  }

  componentDidMount() {
    // Open dropdown on focus
    this._input.onfocus = () => {
      this.setState({
        isOpen: true,
      })
    }
    // Disable spellcheck
    this._input.setAttribute('spellcheck', false)
    // Handle click
    document.addEventListener('click', this.handleClick)

    this.setInputValue()
    i18next.on('languageChanged', this.handleLocaleChange)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick)
    i18next.off('languageChanged', this.handleLocaleChange)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      this.handleLocaleChange(this.props.lang)
    }
  }

  handleLocaleChange(language) {
    moment.locale(language)
    this.setInputValue()
  }

  /**
   * Sets the selected date
   *
   * @param {Date} date
   * @public
   */
  setDate(date) {
    if (this._calendar) {
      this._calendar.setDate(date)

      if (this._input) {
        setTimeout(() => {
          if (this.props.type !== 'input' && this.props.newDesign) {
            let formatedDate = moment(this._calendar.getDate()).format('dddd, D MMM')
            const today = moment()
            const tomorrow = today.clone().add(1, 'day')
            if (moment(this._calendar.getDate()).isSame(today, 'day')) {
              formatedDate = `${i18next.t('dateTime.words.today')}, ${moment(
                this._calendar.getDate()
              ).format('D MMM')}`
            } else if (moment(this._calendar.getDate()).isSame(tomorrow, 'day')) {
              formatedDate = `${i18next.t('dateTime.words.tomorrow')}, ${moment(
                this._calendar.getDate()
              ).format('D MMM')}`
            }
            this._input.value = formatedDate
          } else {
            this._input.value = this._calendar.getDateString()
          }
        }, 100)
      }
    }
  }

  /**
   * Sets the input date
   *
   * @returns {Date} selected date
   * @public
   */
  setInputValue() {
    if (this.props.type === 'input') {
      this._input.value = moment(this.getDate()).format('YYYY-MM-DD')
    } else if (this.props.newDesign) {
      let formatedDate = moment(this.getDate()).format('dddd, MMM MM')
      const today = moment()
      if (moment(this.getDate()).isSame(today, 'day')) {
        formatedDate = `${i18next.t('dateTime.words.today')}, ${moment(this.getDate()).format(
          'D MMM'
        )}`
      }
      this._input.value = formatedDate
    } else {
      this._input.value = this._calendar.getDateString()
    }
  }

  /**
   * Returns the selected date
   *
   * @returns {Date} selected date
   * @public
   */
  getDate() {
    if (this._calendar) {
      return this._calendar.getDate()
    }
  }

  /**
   * Closes the dropdown if the user clicks outside of the CalendarDropdown
   *
   * @param {MouseEvent} event
   */
  handleClick(event) {
    if (
      !DOMHelpers.didClickInsideArray([this._input, this._dateBtn, this._calendarContainer], event)
    ) {
      this.setState({
        isOpen: false,
      })
    }
  }

  /**
   * Closes and blurs the CalendarDropdown if the user presses Tab or Enter
   *
   * @param {KeyboardEvent} event
   */
  handleKeyDown(event) {
    if (event.key === 'Tab' || event.key === 'Enter') {
      this.setState({
        isOpen: false,
      })
      this._input.blur()
    } else {
      event.preventDefault()
    }
  }

  /**
   * Runs when the date changes
   *
   * @param {Date} date
   * @param {String} dateString
   */
  handleDateChange(date, dateString) {
    if (this.props.startDate && !moment(date).isSameOrAfter(this.props.startDate, 'day')) return
    // Update input text value
    this._input.value = dateString
    if (this.props.type === 'input') this._input.value = moment(date).format('YYYY-MM-DD')
    if (this.props.type !== 'input' && this.props.newDesign) {
      let formatedDate = moment(date).format('dddd, D MMM')
      const today = moment()
      const tomorrow = today.clone().add(1, 'day')
      if (moment(date).isSame(today, 'day')) {
        formatedDate = `${i18next.t('dateTime.words.today')}, ${moment(date).format('D MMM')}`
      } else if (moment(date).isSame(tomorrow, 'day')) {
        formatedDate = `${i18next.t('dateTime.words.tomorrow')}, ${moment(date).format('D MMM')}`
      }
      this._input.value = formatedDate
    }
    // Run prop function with new date value
    if (this.props.onDateChange) this.props.onDateChange(date)
    // Close the dropdown
    this.setState({
      isOpen: false,
    })
  }

  renderInput(type) {
    let classNames = [style.inputContainer]
    if (this.props.fixedWidth) classNames.push(style.fixedWidth)
    if (this.props.fixedWidth && this.props.newDesign) classNames.push(style.fixedWidthNewDesign)
    if (this.props.fullWidth) classNames.push('w-full')
    if (this.state.isOpen) classNames.push(style.open)
    if (this.props.type === 'input') classNames.push(style.inputForm)

    return (
      <div className={classNames.join(' ')}>
        <input
          className={cx('ss-input', this.props.newDesign && style.inputNewDesign)}
          defaultValue={this._input && this._input.value}
          ref={(input) => (this._input = input)}
          onKeyDown={this.handleKey}
        />
        {this.props.newDesign && this.props.type !== 'input' && (
          <Button
            ref={(dateBtn) => (this._dateBtn = dateBtn)}
            onClick={() => {
              this.setState({
                isOpen: true,
              })
            }}
            variant="clear-dark"
            size="small"
            className="hover:!text-text-dark w-full gap-x-3 bg-transparent px-0 !text-base outline-0 hover:bg-transparent hover:shadow-none"
          >
            <FontAwesomeIcon icon={faCalendar} />
            {this._input && this._input.value}
          </Button>
        )}
        {this.renderCalendar()}
      </div>
    )
  }

  renderCalendar() {
    let _style = {}

    if (!this.state.isOpen) {
      _style.height = 0
      _style.overflow = 'hidden'
    }
    if (this.props.position === 'top') {
      _style.top = 'unset'
      _style.bottom = 30
    }
    if (this.props.isCentered) {
      _style.left = '65%'
      _style.transform = 'translate(-50%, 0%)'
    }

    return (
      <div
        className={style.calendarContainer}
        style={_style}
        ref={(div) => (this._calendarContainer = div)}
      >
        <MiniCalendar
          key={this.props.lang}
          newDesign={this.props.newDesign}
          initialDate={this.props.defaultDate}
          returnDateFormat={this.props.returnDateFormat}
          startDate={this.props.startDate}
          onDateChange={this.handleDateChange}
          ref={(calendar) => (this._calendar = calendar)}
          blueStyle={this.props.blueStyle}
        />
      </div>
    )
  }

  render() {
    const { type, newDesign = false, onPrevClick, onNextClick } = this.props

    return (
      <div className={style.container}>
        {type !== 'input' && !newDesign && (
          <div className={style.content} onClick={onPrevClick}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
        )}
        {type !== 'input' && newDesign && (
          <Button
            onClick={onPrevClick}
            rounding="pill"
            variant="clear-dark"
            size={'small'}
            className="hover:text-text-dark p-2 hover:bg-transparent hover:shadow-none focus:!outline-none"
          >
            <FontAwesomeIcon className="px-[1px] text-base" icon={faArrowLeft} />
          </Button>
        )}
        {this.renderInput(type)}
        {type !== 'input' && !newDesign && (
          <div className={style.content} onClick={onNextClick}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        )}
        {type !== 'input' && newDesign && (
          <Button
            onClick={onNextClick}
            rounding="pill"
            variant="clear-dark"
            size={'small'}
            className="hover:text-text-dark p-2 hover:bg-transparent hover:shadow-none focus:!outline-none"
          >
            <FontAwesomeIcon className="px-[1px] text-base" icon={faArrowRight} />
          </Button>
        )}
      </div>
    )
  }
}

CalendarDropdown.propTypes = {
  /**
   * Date to determine default date of a calendar
   */
  defaultDate: PropTypes.object,
  /**
   * Function to run when the date changes
   *
   * @param {Date} date selected date
   */
  onDateChange: PropTypes.func,
  /**
   * Function to run when the left arrow clicks
   */
  onPrevClick: PropTypes.func,
  /**
   * Function to run when the right arrow clicks
   */
  onNextClick: PropTypes.func,
  /**
   * Determines if the input should have a fixed width equal to the width of the calendar
   */
  fixedWidth: PropTypes.bool,
  /**
   * Determines if the input should fill available width
   */
  fullWidth: PropTypes.bool,
  /**
   * Determines if blue colors should be used for months and days and set background to white in MiniCalendar
   * **This props does not need to be provided**
   */
  blueStyle: PropTypes.bool,
  /**
   * String to determine type of a calendar
   */
  type: PropTypes.string,
  /**
   * String to determine position a calendar
   */
  position: PropTypes.string,
  /**
   * String to determine format of return date
   */
  returnDateFormat: PropTypes.string,
  /**
   * Determines if the calender should be centered with the input
   */
  isCentered: PropTypes.bool,
  /**
   * Determines if the calender should have a start date (all dates before this date vill not be visible)
   */
  startDate: PropTypes.object,
}

CalendarDropdown.defaultProps = {
  blueStyle: true,
  position: 'bottom',
  isCentered: true,
}
