import { Button, Tooltip } from '@sweetspot/scramble-ds/atoms'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface CopyCellProps {
  value: string
}

export const CopyCell = ({ value }: CopyCellProps) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const { t } = useTranslation()

  const handleCopy = () => {
    navigator.clipboard.writeText(value)
    setShowTooltip(true)
    setTimeout(() => setShowTooltip(false), 2000)
  }

  if (!value) return <span className="ml-4">-</span>

  return (
    <Tooltip.Provider delayDuration={2000}>
      <Tooltip.Root open={showTooltip}>
        <Tooltip.Trigger asChild>
          <Button
            className="hover:!text-text-dark w-[40px] hover:bg-transparent hover:shadow-none focus:!outline-none"
            variant="clear-dark"
            size="small"
            onClick={handleCopy}
          >
            <i className="fa-regular fa-copy" />
          </Button>
        </Tooltip.Trigger>
        <Tooltip.Content
          align="center"
          side="bottom"
          className="z-[100] rounded-sm bg-black text-white"
        >
          {t('words.copied')}
          <Tooltip.Arrow className="mr-3 mt-[-5px]" />
        </Tooltip.Content>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}
