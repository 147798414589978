import { useTranslation } from 'react-i18next'

import {
  EmptyState,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'

const EmptyStateComponent = () => {
  const { t } = useTranslation()
  return (
    <EmptyState
      iconName="fa-light fa-shapes"
      iconClassName="mb-0 p-4"
      className="flex min-w-80 max-w-[480px]"
    >
      <EmptyStateTitle className="mb-0 mt-6">{t('sentences.noResourcesYet')}</EmptyStateTitle>
      <EmptyStateDescription>
        <div className="mb-6 text-center">{t('sentences.noResourcesYetDescription')}</div>
      </EmptyStateDescription>
      <EmptyStateButton className="text-content-sm m-0 py-4 font-medium">
        <i className="fa-regular fa-plus mr-2" />
        {t('sentences.newResource')}
      </EmptyStateButton>
    </EmptyState>
  )
}

export default EmptyStateComponent
