import * as React from 'react'
import { cn } from '../../../utils'
import { Button } from '../../atoms'
import { Input, InputBase } from '../InputBase'

interface StepperProps extends React.HTMLAttributes<HTMLDivElement> {
  value: string
  increment: () => void
  decrement: () => void
  readOnly?: boolean
  disabled?: boolean
  inputClassName?: string
}

const Stepper = React.forwardRef<HTMLDivElement, StepperProps>(
  (
    {
      className,
      children,
      value = 1,
      defaultValue,
      increment,
      decrement,
      onChange,
      readOnly,
      disabled,
      inputClassName,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={cn('relative w-full', className)}
        data-testid="input-container"
        {...props}
        ref={ref}
      >
        <InputBase className="flex-row" disabled={disabled}>
          <Button
            className="hover:text-text-dark absolute top-1/2 -translate-y-1/2 transform border-transparent shadow-none hover:bg-transparent focus:!outline-none focus:ring-0"
            variant="clear-dark"
            onClick={decrement}
            data-testid="decrement-button"
            disabled={disabled}
          >
            <i className="fa-regular fa-minus" />
          </Button>
          <Input
            type="text"
            onChange={onChange}
            defaultValue={defaultValue}
            readOnly={readOnly}
            rounding="rectangular"
            value={value}
            className={cn(
              'focus:border-border-stroke-clean h-touch-height-md text-center text-sm font-semibold',
              inputClassName
            )}
          />
          <Button
            className="hover:text-text-dark absolute right-0 top-1/2 -translate-y-1/2 transform border-transparent shadow-none hover:bg-transparent focus:!outline-none focus:ring-0"
            variant="clear-dark"
            onClick={increment}
            data-testid="increment-button"
            disabled={disabled}
          >
            <i className="fa-regular fa-plus" />
          </Button>
        </InputBase>
      </div>
    )
  }
)
Stepper.displayName = 'Stepper'

export { Stepper }
