import { Button } from '@sweetspot/scramble-ds/atoms'
import {
  Section,
  SectionContent,
  SectionHeader,
  SectionTitle,
  SectionActionButtons,
} from '@sweetspot/scramble-ds'
import { useTranslation } from 'react-i18next'
import { GitClubCredentialsTable } from './components/GitClubCredentialsTable/GitClubCredentialsTable'
import { CreateGitClubCredentialSheet } from './components/CreateGitClubCredential/CreateGitClubCredentialSheet'

const GitClubCredentials = () => {
  const { t } = useTranslation()

  return (
    <Section className="flex flex-1 flex-col">
      <SectionHeader>
        <SectionTitle>{t('settings.federationClubApi.gitCredential')}</SectionTitle>
        <SectionActionButtons>
          <CreateGitClubCredentialSheet>
            <Button
              variant="ghost-dark"
              size="small"
              onClick={() => null}
              className="focus:!ring-2"
            >
              {t('words.create')}
            </Button>
          </CreateGitClubCredentialSheet>
        </SectionActionButtons>
      </SectionHeader>
      <SectionContent className="flex flex-1 pt-6">
        <GitClubCredentialsTable />
      </SectionContent>
    </Section>
  )
}

export default GitClubCredentials
