import React, { useContext, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import produce from 'immer'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'
import {
  ACTION_TYPES_OBJ,
  FILTER_TYPES,
} from '@sweetspot/sweetspot-js/features/promotions/constants/actions'
import NestedRowWrapper from '@sweetspot/sweetspot-js/common/components/NestedRowWrapper'
import ButtonBasic from '@sweetspot/sweetspot-js/common/components/ButtonBasic'
import DropdownBox from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/DropdownBox'
import SlotsFilter from '../Filters/SlotsFilter'
import BallDispensersRule from '@sweetspot/sweetspot-js/features/promotionsReservationsCommon/Rules/BallDispensersRule'
import { FlagNames, useFlag } from '@sweetspot/shared/util/feature-flag'
import PromotionsContext from '../../../containers/PromotionsManager/PromotionsContext'

const FiltersAndQueriesContainer = ({ action, onSetAction, onSaveAction, disabled }) => {
  const { t } = useTranslation()
  const newFilterDropdownRef = useRef(null)
  const isDispenserPromotionsEnabled = useFlag(FlagNames.DispenserPromotions)
  const { values } = useContext(PromotionsContext)

  const filteredFilterTypes =
    values.context === 'membership' && isDispenserPromotionsEnabled
      ? FILTER_TYPES
      : FILTER_TYPES.filter((type) => type.id !== 3) // Remove id: 3 (dispense_filter) if the flag is false

  const hasFilter = useMemo(() => {
    return (
      action?.configuration?.filter?.slot_filter ||
      action?.configuration?.filter?.golf_cart_filter ||
      action?.configuration?.filter?.dispense_filter
    )
  }, [action])

  const [showSelectFilter, setShowSelectFilter] = useState(false)

  useOnClickOutside(newFilterDropdownRef, () => {
    setShowSelectFilter(false)
  })

  const removeFilter = async (value) => {
    const newAction = produce(action, (draft) => {
      delete draft.configuration.filter[value]
    })

    onSetAction(newAction)
    onSaveAction(newAction)
  }

  const addNewFilter = async (item) => {
    const { value } = item

    const newAction = produce(action, (draft) => {
      draft.configuration.filter = {
        ...draft.configuration.filter,
        [value]: value === 'dispense_filter' ? [] : {},
      }
    })

    onSetAction(newAction)
    onSaveAction(newAction)
    setShowSelectFilter(false)
  }

  return (
    <div className={cx(styles.filterQueriesContainer)}>
      <p className={cx(styles.innerTitle)}>{t('words.filter_one')}</p>

      {!hasFilter && <p className={styles.noFiltersText}>{t('sentences.noFilter_one')}</p>}

      {hasFilter &&
        Object.keys(action.configuration.filter)?.map((key) => (
          <NestedRowWrapper
            key={key}
            onRemove={() => removeFilter(key)}
            title={t(FILTER_TYPES.find((x) => x.value === key)?.label)}
          >
            {key === 'dispense_filter' &&
              isDispenserPromotionsEnabled &&
              values.context === 'membership' && (
                <BallDispensersRule
                  rule={action}
                  onSaveRule={onSaveAction}
                  onSetRule={onSetAction}
                  disabled={disabled}
                  isFromAction
                />
              )}
            {key === 'slot_filter' && (
              <SlotsFilter
                action={action}
                onSaveAction={onSaveAction}
                onSetAction={onSetAction}
                disabled={disabled}
              />
            )}
          </NestedRowWrapper>
        ))}

      <div className={cx(styles.newFilterContainer)}>
        <ButtonBasic
          icon="plus"
          className={cx(styles.addRow)}
          text={t('words.filter_one')}
          onClick={() => setShowSelectFilter(true)}
          disabled={disabled}
        />
        <div className={cx(styles.dropdownContainer)}>
          <DropdownBox
            ref={newFilterDropdownRef}
            dropdownOpen={!disabled && showSelectFilter}
            options={filteredFilterTypes.map((type) => {
              const { value } = type
              if (action?.configuration?.filter?.[value]) {
                return {
                  ...type,
                  disabled: true,
                }
              }
              return type
            })}
            className={styles.newFilterDropdown}
            onOptionClick={(item) => !disabled && addNewFilter(item)}
          />
        </div>
      </div>
    </div>
  )
}

FiltersAndQueriesContainer.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    type: PropTypes.oneOf([
      'item_percentage_discount',
      'age_percentage_discount',
      'booking_creator_percentage_dicount',
      ACTION_TYPES_OBJ.PERCENTAGE,
      ACTION_TYPES_OBJ.PERCENTAGE_USES,
      ACTION_TYPES_OBJ.PERCENTAGE_VALUE,
    ]),
    configuration: PropTypes.object,
    limitation: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  onSetAction: PropTypes.func,
  onSaveAction: PropTypes.func,
}

FiltersAndQueriesContainer.defaultProps = {}

export default FiltersAndQueriesContainer
