import { useTranslation } from 'react-i18next'
import { COUNTRY_OPTIONS, MERCHANT_ACCOUNT_TYPE_OPTIONS } from '../../consts'
import { InputField } from '../shared/InputField'
import { SelectField } from '../shared/SelectFields'
import { Control, FieldError, UseFormWatch } from 'react-hook-form'
import { z } from 'zod'
import { StoreSchema } from './validation'

interface StoreFormFieldsProps {
  control: Control<z.infer<typeof StoreSchema>>
  errors: Record<keyof z.infer<typeof StoreSchema>, FieldError | undefined>
  watch: UseFormWatch<z.infer<typeof StoreSchema>>
  isFromEditSheet?: boolean
}

export const StoreFormFields = ({
  control,
  errors,
  watch,
  isFromEditSheet,
}: StoreFormFieldsProps) => {
  const { t } = useTranslation()
  return (
    <>
      <InputField
        name="store_code"
        label={t('settings.billing.storeCode')}
        control={control}
        error={errors.store_code}
        required={watch('store_code') === ''}
        placeholder={t('settings.billing.storeCodePlaceholder')}
        disabled={isFromEditSheet}
      />
      <InputField
        name="shopper_statement"
        label={t('settings.billing.shopperStatement')}
        control={control}
        error={errors.shopper_statement}
        required={watch('shopper_statement') === ''}
        placeholder={t('settings.billing.shopperStatementPlaceholder')}
        disabled={isFromEditSheet}
      />
      <InputField
        name="phone_number"
        label={t('settings.homeClub.phoneNum')}
        control={control}
        error={errors.phone_number}
        required={watch('phone_number') === ''}
      />
      <InputField
        name="address_line1"
        label={t('settings.billing.addressLine', { number: 1 })}
        control={control}
        error={errors.address_line1}
        required={watch('address_line1') === ''}
        placeholder={t('settings.billing.addressLine1Example')}
      />
      <div className="flex gap-4">
        <InputField
          name="address_line2"
          label={t('settings.billing.addressLine', { number: 2 })}
          control={control}
          error={errors.address_line2}
          placeholder={t('settings.billing.addressLine2Example')}
        />
        <InputField
          name="address_line3"
          label={t('settings.billing.addressLine', { number: 3 })}
          control={control}
          error={errors.address_line3}
          placeholder={t('settings.billing.addressLine3Example')}
        />
      </div>

      <div className="flex gap-4">
        <InputField
          name="address_city"
          label={t('players.city')}
          control={control}
          error={errors.address_city}
          required={watch('address_city') === ''}
        />
        <InputField
          name="address_postal_code"
          label={t('settings.billing.postalCode')}
          control={control}
          error={errors.address_postal_code}
          required={watch('address_postal_code') === ''}
        />
      </div>
      <SelectField
        name="merchant_account_type"
        label={t('settings.billing.merchantAccountType')}
        control={control}
        options={MERCHANT_ACCOUNT_TYPE_OPTIONS}
        required={watch('merchant_account_type') === ''}
        error={errors.merchant_account_type}
        disabled={isFromEditSheet}
      />
      <SelectField
        name="address_country"
        label={t('words.country')}
        control={control}
        options={COUNTRY_OPTIONS}
        required={watch('address_country') === ''}
        error={errors.address_country}
        disabled={isFromEditSheet}
      />
    </>
  )
}
