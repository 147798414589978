import CreateSpace from './CreateSpace'
import UpdateSpace from './UpdateSpace'
import SpaceDetails from './SpaceDetails'

const SpacesSideBar = () => (
  <>
    <SpaceDetails />
    <CreateSpace />
    <UpdateSpace />
  </>
)

export default SpacesSideBar
