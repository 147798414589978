import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  getAccountHolder as fetchAccountHolder,
  getAdyenStores,
} from '@sweetspot/sweetspot-js/features/billingSettings/services/api-platform'
import {
  Section,
  SectionContent,
  SectionHeader,
  SectionTitle,
  SectionActionButtons,
  DataTable,
} from '@sweetspot/scramble-ds'
import { useQuery } from 'react-query'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import { CreateAccountHolder } from './components/CreateAccountHolder/CreateAccountHolder'
import { QUERY_KEYS } from '@sweetspot/shared/util/constants'
import { AccountHolder, AdyenStore } from './types'
import { CreateStoreSheet } from './components/Store/CreateStoreSheet'
import { CreateStore } from './components/Store/CreateStore'
import { columns } from './components/Store/columns'
import { LoadingView } from './components/shared/Loading'
import { Button } from '@sweetspot/scramble-ds/atoms'

export const Stores = () => {
  const { t } = useTranslation()

  const golfClub = useSelector(({ golfClub }: RootState) =>
    golfClub?.list.find((club) => club?.id === golfClub?.selectedId)
  )

  const { data: stores, isLoading: isLoadingStores } = useQuery<AdyenStore[]>(
    [QUERY_KEYS.STORES, golfClub?.id],
    () => getAdyenStores(golfClub?.id),
    { refetchOnWindowFocus: true }
  )

  const { data: accountHolder, isLoading: isLoadingAccountHolder } = useQuery<AccountHolder | null>(
    [QUERY_KEYS.ACCOUNT_HOLDER, golfClub?.id],
    async () => {
      const { adyen_account_holder } = await fetchAccountHolder(golfClub?.id)
      return adyen_account_holder ?? null
    },
    {
      enabled: !isLoadingStores,
    }
  )

  const showAccountHolderCreate = !accountHolder && !isLoadingAccountHolder
  const showStoresCreate = !stores?.length && !isLoadingStores && !!accountHolder
  const showStoresTable = (stores?.length ?? 0) > 0 && !isLoadingStores && !!accountHolder

  return (
    <Section className="flex flex-1 flex-col">
      <SectionHeader>
        <SectionTitle>{t('settings.billing.stores')}</SectionTitle>
        {showStoresTable && (
          <SectionActionButtons>
            <CreateStoreSheet>
              <Button variant="ghost-dark" size="small" className="focus:!ring-2">
                {t('settings.billing.createStore')}
              </Button>
            </CreateStoreSheet>
          </SectionActionButtons>
        )}
      </SectionHeader>
      <SectionContent className="h-full w-full">
        {isLoadingStores || isLoadingAccountHolder ? (
          <LoadingView />
        ) : (
          <>
            {showAccountHolderCreate && (
              <div className="flex h-full w-full items-center justify-center">
                <CreateAccountHolder fromStore />
              </div>
            )}
            {showStoresCreate && (
              <div className="flex h-full w-full items-center justify-center">
                <CreateStore />
              </div>
            )}
            {showStoresTable && <DataTable columns={columns(t)} data={stores} hideTableControls />}
          </>
        )}
      </SectionContent>
    </Section>
  )
}
