import { useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import { Controller } from 'react-hook-form'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FIRST_PAYMENT_PAY } from '../../consts'
import {
  Input,
  InputBase,
  InputContainer,
  InputLeadingContainer,
  InputTrailingContainer,
} from '@sweetspot/scramble-ds'
import { RadioGroup } from '@sweetspot/scramble-ds/atoms'
import { calculateAmount, calculatePercentage } from '../../utils'
import { FirstPaymentPercentageAndAmountProps, SettingsFormValues } from '../types'

export const FirstPaymentPercentageAndAmount = ({
  control,
  bookingFields,
  setValue,
  hideAmount,
}: FirstPaymentPercentageAndAmountProps) => {
  const { t } = useTranslation()
  const [showPercentageApproxSign, setShowPercentageApproxSign] = useState<boolean>(false)
  const [showAmountApproxSign, setShowAmountApproxSign] = useState<boolean>(false)

  const firstPaymentPay = useWatch({
    control,
    name: 'first_payment_pay',
  })

  const percentage = useWatch({
    control,
    name: FIRST_PAYMENT_PAY.PERCENTAGE as keyof SettingsFormValues,
  })

  const amount = useWatch({
    control,
    name: FIRST_PAYMENT_PAY.AMOUNT as keyof SettingsFormValues,
  })

  const updatePercentage = useCallback(() => {
    const newPercentage = calculatePercentage(bookingFields?.amount || 1000, amount)
    setShowPercentageApproxSign(newPercentage % 1 !== 0)
    setValue(FIRST_PAYMENT_PAY.PERCENTAGE, newPercentage || '')
  }, [amount, bookingFields?.amount, setValue])

  const updateAmount = useCallback(() => {
    const newAmountNum = calculateAmount(bookingFields?.amount || 1000, percentage)
    setShowAmountApproxSign(newAmountNum % 1 !== 0)
    setValue(FIRST_PAYMENT_PAY.AMOUNT, newAmountNum || '')
  }, [percentage, bookingFields?.amount, setValue])

  useEffect(() => {
    if (firstPaymentPay === FIRST_PAYMENT_PAY.AMOUNT) {
      updatePercentage()
    }
    if (firstPaymentPay === FIRST_PAYMENT_PAY.PERCENTAGE) {
      updateAmount()
    }
  }, [firstPaymentPay, percentage, amount, bookingFields?.amount])

  const handleInputChange = (field, upperLimit) => (e) => {
    const { value } = e.target
    if (/^\d*$/.test(value)) {
      const numValue = Number(value)
      field.onChange(
        numValue === 0 ? '' : numValue > 0 && numValue < upperLimit ? numValue : field.value
      )
    }
  }

  return (
    <div className="flex">
      <Controller
        name="first_payment_pay"
        control={control}
        render={({ field }) => (
          <RadioGroup.Root
            className="border-stroke-pale py-md px-sm h-full w-1/2 items-start border-b-2"
            onValueChange={field.onChange}
            defaultValue={field.value}
          >
            <RadioGroup.Item
              value={FIRST_PAYMENT_PAY.PERCENTAGE}
              id={FIRST_PAYMENT_PAY.PERCENTAGE}
              label={t('words.percentage')}
              isActive={field.value === FIRST_PAYMENT_PAY.PERCENTAGE}
              className={!hideAmount ? `mb-2` : 'mb-0'}
            />
            {!hideAmount && (
              <RadioGroup.Item
                value={FIRST_PAYMENT_PAY.AMOUNT}
                id={FIRST_PAYMENT_PAY.AMOUNT}
                label={t('words.fixed')}
                isActive={field.value === FIRST_PAYMENT_PAY.AMOUNT}
              />
            )}
          </RadioGroup.Root>
        )}
      />

      <div className="p-md border-stroke-pale w-1/2 items-start border-b-2">
        <InputBase
          className={cx('w-full', {
            'text-text-medium bg-background-mono-lighter focus:border-border-stroke-clean':
              firstPaymentPay === FIRST_PAYMENT_PAY.AMOUNT,
            'mb-2': !hideAmount,
          })}
        >
          <InputContainer>
            {showPercentageApproxSign && firstPaymentPay === FIRST_PAYMENT_PAY.AMOUNT && (
              <InputLeadingContainer className={`${percentage < 10 ? 'left-[90px]' : 'left-20'}`}>
                ~
              </InputLeadingContainer>
            )}
            <Controller
              name={FIRST_PAYMENT_PAY.PERCENTAGE}
              control={control}
              render={({ field }) => (
                <Input
                  rounding="rectangular"
                  className="text-content-base pr-9 text-right "
                  type="number"
                  value={field.value}
                  readOnly={firstPaymentPay === FIRST_PAYMENT_PAY.AMOUNT}
                  onChange={handleInputChange(field, 100)}
                />
              )}
            />
            <InputTrailingContainer>
              <i className="fa-regular fa-percentage"></i>
            </InputTrailingContainer>
          </InputContainer>
        </InputBase>
        {!hideAmount && (
          <InputBase
            className={cx('w-full', {
              'text-text-medium bg-background-mono-lighter focus:border-border-stroke-clean':
                firstPaymentPay === FIRST_PAYMENT_PAY.PERCENTAGE,
            })}
          >
            <InputContainer>
              {showAmountApproxSign && firstPaymentPay === FIRST_PAYMENT_PAY.PERCENTAGE && (
                <InputLeadingContainer>~</InputLeadingContainer>
              )}
              <Controller
                name={FIRST_PAYMENT_PAY.AMOUNT}
                control={control}
                render={({ field }) => (
                  <Input
                    rounding="rectangular"
                    className={`text-content-base ${showAmountApproxSign ? 'pl-7' : 'pl4'}`}
                    type="number"
                    value={field.value}
                    readOnly={firstPaymentPay === FIRST_PAYMENT_PAY.PERCENTAGE}
                    onChange={handleInputChange(field, bookingFields?.amount)}
                  />
                )}
              />
              <InputTrailingContainer>
                <p className="text-content-xs font-semibold">{bookingFields?.currency || 'SEK'}</p>
              </InputTrailingContainer>
            </InputContainer>
          </InputBase>
        )}
      </div>
    </div>
  )
}
