import { DiscardDialogCallbacks } from '@sweetspot/club-portal-legacy/Partials/Dialogs/DiscardDialog/types'
import { GolfCourse, GolfCourseTypes } from '@sweetspot/shared/types'

export type ManageAcademyContextValue = {
  areAcademiesLoading: boolean
  selectedAcademy?: GolfCourse
  setSelectedAcademy?: (academy: GolfCourse) => void
  academies: GolfCourse[]
  refetchAcademies: () => void
  openCreateAcademySidebar: boolean
  toggleCreateAcademySideBar: () => void
  openDiscardDialog?: boolean
  setOpenDiscardDialog?: (open: boolean) => void
  discardDialogCallbacks?: DiscardDialogCallbacks
  setDiscardDialogCallbacks?: (callbacks: DiscardDialogCallbacks) => void
}

export enum AcademyTabs {
  GENERAL = 'general',
  BOOKING = 'booking',
  PAYMENT = 'payment',
}

export type FormatedTimeZone = {
  id: string
  name: string
  offset: number
  timezone_id: string
}

export type CreateAcademyPayload = {
  organizationId: string
  name: string
  vat: number
  displayTeeTimeDays: number
  isActive: boolean
  isCanPay: boolean
  isArrivalRegistration: boolean
  isArrivalRegistrationAfterSchedule: boolean
  bookingCancellationLimitHours: number | null
  nonConfirmedBookingAutoCancellationEnabled: boolean
  nonConfirmedBookingAutoCancellationDuration: number | null
  unpaidBookingAutoCancellationEnabled: boolean
  isStubPlayersEnabled: boolean
  isPayOnSiteEnabled: boolean
  is_enabled: boolean
  membershipSignUpSettings: {
    isEnabled: boolean
    memberships: string[]
  }
  type: GolfCourseTypes
  isUseDynamicPricing: boolean
  bookingType: number
}

export type UpdateAcademyPayload = {
  cdh_id?: string
  git_id?: string | null
  name: string
  longitude: number | string | null
  latitude: number | string | null
  description: string
  display_tee_time_days: number | string | null
  custom_email_information: string
  important_booking_information: string
  booking_information: string
  is_active: boolean
  is_can_pay: boolean
  is_arrival_registration: boolean
  is_arrival_registration_after_schedule: boolean
  is_stub_players_enabled: boolean
  is_pay_on_site_enabled: boolean
  pay_on_site_title?: string
  pay_on_site_description?: string
  timezone?: string
  membership_sign_up_settings: {
    is_enabled: boolean
    memberships: string[]
  }
  type: GolfCourseTypes
  vat: number
  non_confirmed_booking_auto_cancellation_enabled?: boolean
  non_confirmed_booking_auto_cancellation_duration?: number | null
  unpaid_booking_auto_cancellation_enabled?: boolean
  unpaid_booking_auto_cancellation_duration?: number | null
  unpaid_booking_auto_cancellation_days?: string | number | null
  booking_cancellation_limit_hours?: number | null
  booking_type: number
  is_use_dynamic_pricing: boolean
}

export type UpdateAcademyData = {
  id: string
  organizationId: string
  name: string
  description: string
  displayTeeTimesDays: number | string | null
  bookingInformation: string
  importantBookingInformation: string
  customEmailInformation: string
  timezone?: string
  latitude: number | string
  longitude: number | string
  vat: number
  isActive: boolean
  isCanPay: boolean
  isArrivalRegistration: boolean
  isArrivalRegistrationAfterSchedule: boolean
  bookingCancellationLimitHours: number | null
  nonConfirmedBookingAutoCancellationEnabled: boolean
  nonConfirmedBookingAutoCancellationDuration: number | null
  unpaidBookingAutoCancellationEnabled: boolean
  unpaidBookingAutoCancellationDuration: number | null
  isPayOnSiteEnabled: boolean
  payOnSiteTitle: string | null
  payOnSiteDescription: string | null
  is_enabled: boolean
  membershipSignUpSettings: {
    isEnabled: boolean
    memberships: string[]
  }
  unpaidBookingAutoCancellationDays: string | number | null
  type: GolfCourseTypes
  memberships?: string[]
  imageId?: number
  isUseDynamicPricing: boolean
}
