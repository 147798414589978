import { Control, Controller } from 'react-hook-form'
import {
  InputSelect,
  InputBase,
  InputSelectTrigger,
  InputContainer,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  ListItemTrailing,
  ListItemLeading,
  InputLeadingContainer,
  InputLabelContainer,
} from '@sweetspot/scramble-ds'
import { cn } from '@sweetspot/scramble-ds/utils'
import { useTranslation } from 'react-i18next'

type Option = {
  name: string
  color?: string
  value: string | number | boolean | null
}

interface EditTeeTimesInputSelectProps {
  name: string
  control: Control<any>
  options: Option[]
  placeholder: string
  disabled?: boolean
  label: string
}

export const EditTeeTimesInputSelect = ({
  name,
  control,
  options,
  placeholder,
  disabled = false,
  label,
}: EditTeeTimesInputSelectProps) => {
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputSelect value={field.value} onValueChange={field.onChange}>
          <InputBase disabled={disabled}>
            <InputLabelContainer>{label}</InputLabelContainer>
            <InputSelectTrigger asChild className="w-full opacity-100" disabled={disabled}>
              <InputContainer className="ring-border-stroke-subtle">
                <InputLeadingContainer>
                  {options.find((item) => item.value === field.value)?.color && (
                    <div
                      className="h-4 w-4 rounded-sm"
                      style={{
                        backgroundColor: options.find((item) => item.value === field.value)?.color,
                      }}
                    />
                  )}
                </InputLeadingContainer>

                <InputSelectStatefulInput
                  placeholder={t(placeholder)}
                  className={cn('text-content-base text-ellipsis pl-4 pr-8', {
                    'pl-10': options.find((item) => item.value === field.value)?.color,
                  })}
                  value={options.find((item) => item.value === field.value)?.name || ''}
                />
                <InputSelectTrailingContainer />
              </InputContainer>
            </InputSelectTrigger>
          </InputBase>
          <InputSelectContent className="max-h-72">
            <InputSelectContentView>
              {options.map((item) => (
                <InputSelectItem value={item.value} key={item.value}>
                  <ListItem className="ml-2 h-full w-[var(--radix-select-trigger-width)] min-w-[var(--radix-select-trigger-width)] justify-center gap-2">
                    {item?.color && (
                      <ListItemLeading>
                        <div
                          className="h-4 w-4 rounded-sm"
                          style={{ backgroundColor: item?.color }}
                        />
                      </ListItemLeading>
                    )}
                    <ListItemMainContent className="justify-center py-1">
                      <ListItemParagraph className="text-content-base text-ellipsis">
                        {item?.name}
                      </ListItemParagraph>
                    </ListItemMainContent>
                    {field.value === item.value && (
                      <ListItemTrailing className="pr-5">
                        <i className="fa-regular fa-check h-4 w-4" />
                      </ListItemTrailing>
                    )}
                  </ListItem>
                </InputSelectItem>
              ))}
            </InputSelectContentView>
          </InputSelectContent>
        </InputSelect>
      )}
    />
  )
}
