export enum VerificationStatus {
  VALID = 'Valid',
  INVALID = 'Invalid',
  PENDING = 'Pending',
  REJECTED = 'Rejected',
}

export type EntityData = {
  name: string
  email: string
  type: 'Individual' | 'Business'
  address: string
}

export type BalanceAccountData = {
  id: string
  name: string
  payout_frequency: string
  schedule_type: string | '-'
  next_payment_due: string | null
  trigger_amount: string | '-'
  min_amount: string | '-'
  total_amount: string
}

export type BalanceAccount = {
  adyen_balance_account_id: string
  uuid: string
  id: number
}

export type AccountHolder = {
  adyen_account_holder_id: string
  adyen_legal_entity_id: string
  adyen_verification_status: string
  adyen_status: string
  balance_accounts: BalanceAccount[]
  uuid: string
  id: number
}

export type AdyenStore = {
  id: number
  uuid: string
  adyen_business_line_id: string
  adyen_store_code: string
  adyen_store_id: string
  merchant_account_type: string
}
