import { IUniversalIdentifier, LonLat } from '../../common'
import { Club } from '../golfClub'

export type Image = {
  id: number
  url: string
}

// NOTE: not complete
export interface GolfCourse extends IUniversalIdentifier {
  booking_information: string
  booking_settings: CourseBookingSettings
  booking_type: number
  club: Club
  custom_email_information: string
  description: string
  display_tee_time_days: number
  git_id: string
  id: number
  images: Image[]
  important_booking_information: string | null
  is_active: boolean
  is_arrival_registration: boolean
  is_arrival_registration_after_schedule: boolean
  is_can_pay: boolean
  is_pay_on_site_enabled: boolean
  is_stub_players_enabled: boolean
  is_use_dynamic_pricing: boolean
  lonlat: LonLat
  membership_sign_up_settings: CourseMembershipSignUpSettings
  name: string
  pay_on_site_description: string | null
  pay_on_site_title: string | null
  search_field: string
  tee_time_source: string
  timezone: string
  type: GolfCourseTypes
  uuid: string
  vat: number
  booking_cancellation_limit_hours: number
  cdh_id?: string
  course_tees: CourseTee[]
}

export enum GolfCourseTypes {
  COURSE = 'course',
  SIMULATOR = 'simulator',
  DRIVING_RANGE = 'driving_range',
  PRO = 'pro',
  OTHER = 'other',
}

export type CourseTee = {
  course_rating: string
  slope_value: number
  gender: number
  color: string
  tee_alias: string
  tee_id: string
  id: number
  uuid: string
}

export type CourseMembershipSignUpSettings = {
  is_enabled: boolean
  memberships: string[]
}

export type BookingSettings = {
  is_enabled?: boolean
  enabled?: boolean
  duration: number
}
export type BookingAutoCancellationSettings = {
  non_confirmed_booking_settings: BookingSettings
  unpaid_booking_settings: BookingSettings
}

export type CourseBookingSettings = {
  auto_cancellation_settings: BookingAutoCancellationSettings
}

export type Venue = {
  git_id: string
  club: Club
  name: string
  description: string
  lonlat: LonLat
  booking_information: string
  is_active: boolean
  is_use_dynamic_pricing: boolean
  booking_type: number
  tee_time_source: string
  is_can_pay: boolean
  is_arrival_registration: boolean
  display_tee_time_days: number
  booking_settings: CourseBookingSettings
  is_stub_players_enabled: boolean
  is_pay_on_site_enabled: boolean
  search_field: string
  is_arrival_registration_after_schedule: boolean
  custom_email_information: string
  timezone: string
  pay_on_site_title: string
  pay_on_site_description: string
  important_booking_information: string
  course_tees: CourseTee[]
  type: GolfCourseTypes
  vat: number
  membership_sign_up_settings: CourseMembershipSignUpSettings
  uuid: string
  id: number
  script_fields?: {
    distance: number[]
  }
  booking_cancellation_limit_hours: number
}
