import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  getAccountHolder as fetchAccountHolder,
  forceSweepsVerification,
  getEditAccountHolderLink,
} from '@sweetspot/sweetspot-js/features/billingSettings/services/api-platform'
import {
  Section,
  SectionContent,
  SectionHeader,
  SectionTitle,
  SectionActionButtons,
} from '@sweetspot/scramble-ds'
import { useMutation, useQuery } from 'react-query'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import { CreateAccountHolder } from './components/CreateAccountHolder/CreateAccountHolder'
import { QUERY_KEYS } from '@sweetspot/shared/util/constants'
import { AccountHolderPage } from './components/AccountHolderPage/AccountHolderPage'
import { useToasts } from 'react-toast-notifications'
import { EditAccountHolderPayload } from '@sweetspot/sweetspot-js/features/billingSettings/types'
import { AccountHolder } from './types'
import { Button } from '@sweetspot/scramble-ds/atoms'
import { CreateStoreSheet } from './components/Store/CreateStoreSheet'

export const BillingSettings = () => {
  const [forceSweepsDisabled, setForceSweepsDisabled] = useState<boolean>(false)
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const currentUser = useSelector((state: RootState) => state.auth)

  const golfClub = useSelector(({ golfClub }: RootState) =>
    golfClub?.list.find((club) => club?.id === golfClub?.selectedId)
  )

  const {
    data: accountHolder,
    isLoading,
    refetch: refetchAccountHolder,
  } = useQuery<AccountHolder | null>([QUERY_KEYS.ACCOUNT_HOLDER, golfClub?.id], async () => {
    const { adyen_account_holder } = await fetchAccountHolder(golfClub?.id)
    return adyen_account_holder ?? null
  })

  const editAccountMutation = useMutation(
    async ({ clubId, payload }: { clubId: number; payload: EditAccountHolderPayload }) =>
      getEditAccountHolderLink({ clubId, payload }),
    {
      onSuccess: (response) => {
        if (response?.onboardingUrl) {
          window.open(response.onboardingUrl, '_blank')
        }
      },
      onError: (error) => {
        addToast(error?.detail || t('errors.somethingWentWrongNoId'), { appearance: 'error' })
      },
    }
  )

  const forceSweepsVerificationMutation = useMutation(
    async (clubId: number) => forceSweepsVerification(clubId),
    {
      onSuccess: () => {
        addToast(t('settings.sweepsVerifiedSuccessfully'), { appearance: 'success' })
        refetchAccountHolder()
      },
      onError: (error) => {
        addToast(error?.detail || t('errors.somethingWentWrongNoId'), { appearance: 'error' })
      },
    }
  )

  const handleEditAccountBtn = () => {
    if (!golfClub?.id) return

    const domainName = window.origin
    const payload: EditAccountHolderPayload = {
      redirect_url: `${domainName}/settings/billing-settings`,
    }

    editAccountMutation.mutate({ clubId: golfClub.id, payload })
  }

  const showAccountHolderCreationView = !accountHolder && !isLoading
  const showAccontHolderTableView = !!accountHolder && !isLoading

  const showActionButtons = accountHolder && !isLoading

  return (
    <Section className="flex flex-1 flex-col">
      <SectionHeader>
        <SectionTitle>{t('settings.billing.title')}</SectionTitle>
        {showActionButtons && (
          <SectionActionButtons>
            {currentUser?.isSuperAdmin && (
              <Button
                variant="ghost-dark"
                size="small"
                onClick={() => forceSweepsVerificationMutation.mutate(golfClub.id)}
                disabled={forceSweepsDisabled}
                className="focus:!ring-2"
              >
                {t('settings.forceSweepsVerification')}
              </Button>
            )}
            <Button
              variant="ghost-dark"
              size="small"
              onClick={handleEditAccountBtn}
              className="focus:!ring-2"
            >
              {t('sentences.editAccount')}
            </Button>
            <CreateStoreSheet>
              <Button variant="ghost-dark" size="small" className="focus:!ring-2">
                {t('settings.billing.createStore')}
              </Button>
            </CreateStoreSheet>
          </SectionActionButtons>
        )}
      </SectionHeader>
      <SectionContent className="flex flex-1 p-6">
        {showAccountHolderCreationView && <CreateAccountHolder />}
        {showAccontHolderTableView && (
          <AccountHolderPage
            accountHolder={accountHolder}
            setForceSweepsDisabled={setForceSweepsDisabled}
          />
        )}
      </SectionContent>
    </Section>
  )
}
