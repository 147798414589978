import { type Table } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../../atoms'
import {
  InputBase,
  InputContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  ListItemTrailing,
} from '../../../../molecules'

interface DataTablePaginationProps<TData> {
  table: Table<TData>
  pageSizeOptions?: number[]
  onPageChange?: (pageIndex: number) => void
  onPageSizeChange?: (pageSize: number) => void
  isBackendPagination: boolean
}

export const DataTablePagination = <TData,>({
  table,
  pageSizeOptions = [5, 10, 20, 30, 40, 50],
  onPageChange,
  onPageSizeChange,
  isBackendPagination,
}: DataTablePaginationProps<TData>) => {
  const { t } = useTranslation()

  const { pageIndex } = table.getState().pagination
  const { enableRowSelection } = table.options

  return (
    <div className="border-border-stroke-pale sticky bottom-0 z-10 mt-4 flex items-center justify-between border-t bg-white px-4 py-2">
      <div className="text-muted-foreground flex-1 text-sm">
        {enableRowSelection && (
          <>
            {table.getFilteredSelectedRowModel().rows.length} {t('of')}{' '}
            {table.getFilteredRowModel().rows.length} {t('rowsSelected')}.
          </>
        )}
      </div>
      <div className="flex items-center space-x-2 lg:space-x-4">
        <div className="flex items-center space-x-2">
          <p className="text-content-sm">{t('rowsPerPage')}</p>
          <InputSelect
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value) => {
              const newPageSize = Number(value)
              if (isBackendPagination) {
                onPageSizeChange?.(newPageSize)
              } else {
                table.setPageSize(newPageSize)
              }
            }}
          >
            <InputBase className="w-20">
              <InputSelectTrigger asChild>
                <InputContainer>
                  <InputSelectStatefulInput
                    value={table.getState().pagination.pageSize}
                    className="text-content-sm h-10"
                  />
                  <InputSelectTrailingContainer />
                </InputContainer>
              </InputSelectTrigger>
            </InputBase>
            <InputSelectContent>
              <InputSelectContentView>
                {pageSizeOptions.map((pageSize) => (
                  <InputSelectItem value={`${pageSize}`}>
                    <ListItem className="ml-2 h-full w-[52px] min-w-full justify-center">
                      <ListItemMainContent className="justify-center py-0">
                        <ListItemParagraph className="text-content-sm">
                          {pageSize}
                        </ListItemParagraph>
                      </ListItemMainContent>
                      {table.getState().pagination.pageSize === pageSize && (
                        <ListItemTrailing>
                          <i className="fa-regular fa-check h-4 w-4" />
                        </ListItemTrailing>
                      )}
                    </ListItem>
                  </InputSelectItem>
                ))}
              </InputSelectContentView>
            </InputSelectContent>
          </InputSelect>
        </div>
        <div className="flex w-[100px] items-center justify-center text-sm">
          {t('page')} {pageIndex + 1} {t('of')} {table.getPageCount()}
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant="clear-dark"
            size="small"
            onClick={() => {
              if (isBackendPagination) {
                onPageChange?.(0)
              } else {
                table.setPageIndex(0)
              }
            }}
            disabled={isBackendPagination ? pageIndex === 0 : !table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to first page</span>
            <i className="fa-regular fa-chevrons-left" />
          </Button>
          <Button
            variant="clear-dark"
            size="small"
            onClick={() => {
              if (isBackendPagination) {
                onPageChange?.(pageIndex - 1)
              } else {
                table.previousPage()
              }
            }}
            disabled={isBackendPagination ? pageIndex === 0 : !table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to previous page</span>
            <i className="fa-regular fa-chevron-left" />
          </Button>
          <Button
            variant="clear-dark"
            size="small"
            onClick={() => {
              if (isBackendPagination) {
                onPageChange?.(pageIndex + 1)
              } else {
                table.nextPage()
              }
            }}
            disabled={
              isBackendPagination ? pageIndex >= table.getPageCount() - 1 : !table.getCanNextPage()
            }
          >
            <span className="sr-only">Go to next page</span>
            <i className="fa-regular fa-chevron-right" />
          </Button>
          <Button
            variant="clear-dark"
            size="small"
            onClick={() => {
              if (isBackendPagination) {
                onPageChange?.(table.getPageCount() - 1)
              } else {
                table.setPageIndex(table.getPageCount() - 1)
              }
            }}
            disabled={
              isBackendPagination ? pageIndex >= table.getPageCount() - 1 : !table.getCanNextPage()
            }
          >
            <span className="sr-only">Go to last page</span>
            <i className="fa-regular fa-chevrons-right" />
          </Button>
        </div>
      </div>
    </div>
  )
}
