import { useEffect, useCallback, useState, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { localizedDaysOfWeek } from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import { cn } from '@sweetspot/scramble-ds/utils'
import { Check } from '@sweetspot/scramble-ds/atoms'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'

import { useAvailabilityAndPricingContext } from '../../../../AvailabilityAndPricingProvider'
import { AllCheckboxValues } from '../../../../types'
import PriceCell from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/Period/PricingTab/PricingPeriodTabContent/PricingTable/PriceCell'
import SpinnerLoader from '@sweetspot/club-portal-legacy/components/SpinnerLoader/SpinnerLoader'

const PRICE_TABLE_CELL_HEIGHT = 40
// const PRICE_TABLE_OFFSET = 50
const DEFAULT_PRICE_BODY_CONTAINER_HEIGHT = 400

const PricingTable = ({ className }: { className?: string }) => {
  const { t } = useTranslation()
  const containerRef = useRef<HTMLInputElement>(null)
  const lang = useSelector((state: RootState) => state.auth.me.lang)
  const [priceTableBodyContainerHeight, setPriceTableBodyContainerHeight] = useState<
    number | string | undefined
  >()
  const {
    activePeriodTab,
    allCheckbox,
    onAllCheckboxChange,
    DAYS_OF_WEEK,
    timeSlots,
    tableData,
    daysMap,
    selectedCols,
    onColumnClick,
    selectedRows,
    onRowClick,
    isPricingUpdating,
    isPricingFetching,
  } = useAvailabilityAndPricingContext()

  const DAYS_OF_WEEK_LOCALE = localizedDaysOfWeek(lang, 1)

  const daysWithData: string[] = useMemo(() => {
    const firstBayTableData = tableData && Object.values(tableData) && Object.values(tableData)[0]

    return firstBayTableData ? Object.keys(firstBayTableData) : []
  }, [tableData])

  const updateContainerHeight = useCallback(() => {
    setPriceTableBodyContainerHeight(
      containerRef.current?.offsetTop
        ? Math.max(
            window.innerHeight - containerRef.current?.offsetTop - 195,
            DEFAULT_PRICE_BODY_CONTAINER_HEIGHT
          )
        : DEFAULT_PRICE_BODY_CONTAINER_HEIGHT
    )
  }, [])

  useEffect(() => {
    const containerElement = containerRef.current

    const resizeObserver = new ResizeObserver(() => {
      updateContainerHeight()
    })

    if (containerElement) {
      resizeObserver.observe(containerElement)
    }

    return () => {
      if (containerElement) {
        resizeObserver.unobserve(containerElement)
      }
    }
  }, [updateContainerHeight])

  return (
    <div
      ref={containerRef}
      className={cn('relative flex w-[100%] flex-1 overflow-x-auto', className)}
    >
      {(isPricingFetching || isPricingUpdating) && (
        <SpinnerLoader className="absolute" text={''} isOverlay />
      )}

      <div className="relative flex flex-col justify-stretch">
        <div>
          <table className="border-separate border-spacing-0">
            <thead>
              <tr>
                <th
                  className={cn(
                    'bg-background-mono-lighter w-[96px] min-w-[65px] cursor-pointer',
                    'border-border-stroke-pale rounded-tl-md border text-center '
                  )}
                  style={{ height: PRICE_TABLE_CELL_HEIGHT }}
                >
                  <Check
                    onCheckedChange={onAllCheckboxChange}
                    label={t('words.all')}
                    checked={allCheckbox === AllCheckboxValues.ALL}
                    labelClassName="!mt-0 text-text-dark"
                    className="ml-auto mr-auto h-auto items-center gap-2 px-0"
                    indeterminate={allCheckbox === AllCheckboxValues.SOME}
                  />
                </th>
                {DAYS_OF_WEEK_LOCALE.map((day, dayIndex) => {
                  return (
                    <th
                      className={cn(
                        'bg-background-mono-lighter text-text-dark w-[154px] min-w-[100px] cursor-pointer text-center',
                        'border-border-stroke-pale border border-l-0 transition-colors last:rounded-tr-md',
                        selectedCols?.[dayIndex] &&
                          daysWithData.includes(day) &&
                          'bg-surface-secondary text-white'
                      )}
                      style={{ height: PRICE_TABLE_CELL_HEIGHT }}
                      key={day}
                      onClick={() => onColumnClick(dayIndex, selectedCols?.[dayIndex])}
                      data-disabled={!tableData?.[day]}
                    >
                      <div className="flex flex-col items-center justify-center">
                        <span className="text-content-sm font-semibold uppercase">
                          {day.substring(0, 3)}
                        </span>
                        {activePeriodTab !== -1 && (
                          <span className="text-content-xs font-normal">{daysMap?.[day]}</span>
                        )}
                      </div>
                    </th>
                  )
                })}
              </tr>
            </thead>
          </table>
        </div>
        <div
          className="scrollbar-hide overflow-y-auto"
          style={{ height: priceTableBodyContainerHeight }}
        >
          <table className="border-separate border-spacing-0">
            <thead>
              <tr>
                {new Array(8).fill(null).map((_, idx) => (
                  <th key={idx}></th>
                ))}
              </tr>
            </thead>
            <tbody>
              {timeSlots?.map((timeSlot, timeSlotIndex) => {
                const isLastRow = timeSlotIndex === timeSlots.length - 1

                return (
                  <tr key={timeSlotIndex}>
                    <th
                      onClick={() => onRowClick(timeSlotIndex, selectedRows?.[timeSlotIndex])}
                      className={cn(
                        'bg-background-mono-lighter text-text-dark w-[96px] min-w-[65px] cursor-pointer',
                        'border-border-stroke-pale border border-t-0 text-center transition-colors',
                        isLastRow && 'rounded-bl-md',
                        selectedRows?.[timeSlotIndex] && 'bg-surface-secondary text-white'
                      )}
                    >
                      <span className="text-content-sm font-semibold">{timeSlot}</span>
                    </th>
                    {DAYS_OF_WEEK.map((day, dayIndex) => (
                      <PriceCell
                        key={dayIndex}
                        dayIndex={dayIndex}
                        isLastRow={isLastRow}
                        day={day}
                        timeSlotIndex={timeSlotIndex}
                      />
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default PricingTable
