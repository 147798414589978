export const COUNTRY_OPTIONS = [
  { id: 'SE', name: 'Sweden' },
  { id: 'DK', name: 'Denmark' },
  { id: 'GB', name: 'United Kingdom' },
  { id: 'DE', name: 'Germany' },
  { id: 'FI', name: 'Finland' },
  { id: 'NO', name: 'Norway' },
  { id: 'AT', name: 'Austria' },
  { id: 'CH', name: 'Switzerland' },
  { id: 'BE', name: 'Belgium' },
  { id: 'NL', name: 'The Netherlands' },
  { id: 'IE', name: 'Ireland' },
  { id: 'FR', name: 'France' },
  { id: 'ES', name: 'Spain' },
  { id: 'IT', name: 'Italy' },
  { id: 'LT', name: 'Lithuania' },
  { id: 'US', name: 'United States of America' },
  { id: 'CA', name: 'Canada' },
]

export const MERCHANT_ACCOUNT_TYPE_OPTIONS = [
  { id: 'pos', name: 'POS' },
  { id: 'ecom', name: 'ECOM' },
]
