import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@sweetspot/scramble-ds'

import AdjustmentsTab from './AdjustmentsTab/AdjustmentsTab'
import PricingTab from './PricingTab/PricingTab'
import { PeriodTabsEnum } from '../types'

const PeriodTabs = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [activeTab, setActiveTab] = useState<PeriodTabsEnum>()

  // set active tab based on hash
  useEffect(() => {
    const hash = (history.location.hash?.slice(1) as PeriodTabsEnum) || PeriodTabsEnum.PRICING
    if (hash && Object.values(PeriodTabsEnum).includes(hash)) setActiveTab(hash)
  }, [history.location.hash])

  return (
    <div className="relative mt-8 h-full w-full">
      <Tabs
        value={activeTab}
        onValueChange={(tab) => setActiveTab(tab as PeriodTabsEnum)}
        className="h-full"
      >
        <TabsList className="-mx-6 w-[calc(100%+48px)] justify-start rounded-none border-b px-6">
          <TabsTrigger value={PeriodTabsEnum.PRICING} className="-mb-1.5 w-auto px-4">
            {t('editPeriodsNew.priceListTab')}
          </TabsTrigger>
          <TabsTrigger value={PeriodTabsEnum.ADJUSTMENTS} className="-mb-1.5 w-auto px-4">
            {t('words.adjustments')}
          </TabsTrigger>
        </TabsList>
        <TabsContent value={PeriodTabsEnum.PRICING} className="h-[calc(100%-48px)]">
          <div className="flex h-full justify-center">
            <PricingTab />
          </div>
        </TabsContent>
        <TabsContent value={PeriodTabsEnum.ADJUSTMENTS} className="h-[calc(100%-48px)]">
          <div className="flex h-full justify-center">
            <AdjustmentsTab />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default PeriodTabs
