import { ReactComponent as FixedDiscountIcon } from '@sweetspot/sweetspot-js/assets/svgs/fixed-discount.svg'
import { ReactComponent as FixedPriceIcon } from '@sweetspot/sweetspot-js/assets/svgs/fixed-price.svg'
import { ReactComponent as PercentageIcon } from '@sweetspot/sweetspot-js/assets/svgs/percentage.svg'

import { ReactComponent as FixedDiscountIconDisabled } from '@sweetspot/sweetspot-js/assets/svgs/fixed-discount-disabled.svg'
import { ReactComponent as FixedPriceIconDisabled } from '@sweetspot/sweetspot-js/assets/svgs/fixed-price-disabled.svg'
import { ReactComponent as PercentageIconDisabled } from '@sweetspot/sweetspot-js/assets/svgs/percentage-disabled.svg'

export const ACTION_TYPES_OBJ = {
  PERCENTAGE: 'item_percentage_discount',
  PERCENTAGE_USES: 'item_percentage_discount_with_uses_limitation',
  PERCENTAGE_VALUE: 'item_percentage_discount_with_discounted_value_limitation',
  FIXED_DISCOUNT: 'item_fixed_discount',
  FIXED_DISCOUNT_USES: 'item_fixed_discount_with_uses_limitation',
  FIXED_DISCOUNT_VALUE: 'item_fixed_discount_with_discounted_value_limitation',
}

export const LIMITATION_TYPES_OJB = {
  USES: 'uses',
  DISCOUNTED_VALUE: 'discounted-value',
  NONE: 'none',
}

export const ACTION_TYPES_OBJ_VALUES = {
  [ACTION_TYPES_OBJ.PERCENTAGE]: {
    id: 1,
    value: ACTION_TYPES_OBJ.PERCENTAGE,
    discountType: 'percentage',
    limitationType: LIMITATION_TYPES_OJB.NONE,
    configurationKey: null,
    defaultFields: {
      percentage_coefficient: '',
      minimum_fee_total_amount: null,
    },
  },
  [ACTION_TYPES_OBJ.PERCENTAGE_USES]: {
    id: 2,
    value: ACTION_TYPES_OBJ.PERCENTAGE_USES,
    discountType: 'percentage',
    limitationType: LIMITATION_TYPES_OJB.USES,
    configurationKey: 'percentage_with_uses',
    defaultFields: {
      uses_to: null,
      percentage_coefficient: '',
      minimum_fee_total_amount: null,
    },
  },
  [ACTION_TYPES_OBJ.PERCENTAGE_VALUE]: {
    id: 3,
    value: ACTION_TYPES_OBJ.PERCENTAGE_VALUE,
    discountType: 'percentage',
    limitationType: LIMITATION_TYPES_OJB.DISCOUNTED_VALUE,
    configurationKey: 'percentage_with_discounted_value',
    defaultFields: {
      discounted_value_to: null,
      percentage_coefficient: '',
      minimum_fee_total_amount: null,
    },
  },
  [ACTION_TYPES_OBJ.FIXED_DISCOUNT]: {
    id: 4,
    value: ACTION_TYPES_OBJ.FIXED_DISCOUNT,
    discountType: 'fixed-discount',
    limitationType: LIMITATION_TYPES_OJB.NONE,
    configurationKey: null,
    defaultFields: {
      discount_amount: '',
      minimum_fee_total_amount: null,
    },
  },
  [ACTION_TYPES_OBJ.FIXED_DISCOUNT_USES]: {
    id: 5,
    value: ACTION_TYPES_OBJ.FIXED_DISCOUNT_USES,
    discountType: 'fixed-discount',
    limitationType: LIMITATION_TYPES_OJB.USES,
    configurationKey: 'fixed_discount_with_uses',
    defaultFields: {
      discount_amount: '',
      minimum_fee_total_amount: null,
      uses_to: null,
    },
  },
  [ACTION_TYPES_OBJ.FIXED_DISCOUNT_VALUE]: {
    id: 6,
    value: ACTION_TYPES_OBJ.FIXED_DISCOUNT_VALUE,
    discountType: 'fixed-discount',
    limitationType: LIMITATION_TYPES_OJB.DISCOUNTED_VALUE,
    configurationKey: 'fixed_discount_with_discounted_value',
    defaultFields: {
      discount_amount: '',
      minimum_fee_total_amount: null,
      discounted_value_to: null,
    },
  },
}

export const ACTION_TYPES = [
  ACTION_TYPES_OBJ_VALUES[ACTION_TYPES_OBJ.PERCENTAGE],
  ACTION_TYPES_OBJ_VALUES[ACTION_TYPES_OBJ.PERCENTAGE_USES],
  ACTION_TYPES_OBJ_VALUES[ACTION_TYPES_OBJ.PERCENTAGE_VALUE],
  ACTION_TYPES_OBJ_VALUES[ACTION_TYPES_OBJ.FIXED_DISCOUNT],
  ACTION_TYPES_OBJ_VALUES[ACTION_TYPES_OBJ.FIXED_DISCOUNT_USES],
  ACTION_TYPES_OBJ_VALUES[ACTION_TYPES_OBJ.FIXED_DISCOUNT_VALUE],
]

export const DISCOUNT_TYPES = [
  {
    name: 'percentage',
    id: 1,
    label: 'words.percentage',
    value: 'percentage',
    icon: PercentageIcon,
    iconDisabled: PercentageIconDisabled,
  },
  {
    name: 'fixed-discount',
    id: 2,
    label: 'sentences.fixedDiscount',
    value: 'fixed-discount',
    icon: FixedDiscountIcon,
    iconDisabled: FixedDiscountIconDisabled,
  },
  {
    name: 'fixed-price',
    id: 3,
    label: 'sentences.fixedPrice',
    value: 'fixed-price',
    disabled: true,
    icon: FixedPriceIcon,
    iconDisabled: FixedPriceIconDisabled,
  },
]

export const LIMITATION_TYPES = [
  {
    id: 1,
    name: LIMITATION_TYPES_OJB.USES,
    value: LIMITATION_TYPES_OJB.USES,
    label: 'sentences.usesAndTimeSlots',
  },
  {
    id: 2,
    name: LIMITATION_TYPES_OJB.DISCOUNTED_VALUE,
    value: LIMITATION_TYPES_OJB.DISCOUNTED_VALUE,
    label: 'words.value',
  },
  {
    id: 3,
    name: LIMITATION_TYPES_OJB.NONE,
    value: LIMITATION_TYPES_OJB.NONE,
    label: 'words.none',
  },
]

export const FILTER_TYPES = [
  {
    id: 1,
    value: 'golf_cart_filter',
    label: 'sentences.golfCarts',
  },
  {
    id: 2,
    value: 'slot_filter',
    label: 'sentences.teeTimeSlot_other',
  },
  {
    id: 3,
    value: 'dispense_filter',
    label: 'sentences.ballDispenser',
  },
]

export const SLOT_FILTER_QUERIES = [
  {
    id: 1,
    value: 'age_query',
    label: 'words.age',
  },
  {
    id: 2,
    value: 'git_home_club_query',
    label: 'sentences.gitHomeclub',
  },
  {
    id: 3,
    value: 'slot_type_query',
    label: 'sentences.slotType_one',
  },
  {
    id: 4,
    value: 'cooperation_query',
    label: 'cooperationFilter.title',
  },
]

export const SLOT_TYPES = [
  {
    id: 1,
    value: 'guest',
    label: 'words.guest',
    context: ['club'],
  },
  {
    id: 2,
    value: 'member',
    label: 'words.member_one',
    context: ['club'],
  },
  {
    id: 3,
    value: 'booking_owner',
    label: 'sentences.bookingOwner',
    context: ['club'],
  },
  {
    id: 4,
    value: 'booking_creator',
    label: 'sentences.bookingCreator',
    context: ['club'],
  },
  {
    id: 5,
    value: 'holder',
    label: 'words.holder',
    context: ['membership'],
  },
  {
    id: 6,
    value: 'guest_to_holder',
    label: 'sentences.guestToHolder',
    context: ['membership'],
  },
]
