export const DataTableMovableCell = ({
  dragHandleProps,
}: {
  dragHandleProps: React.HTMLAttributes<HTMLElement>
}) => {
  return (
    <span
      {...dragHandleProps}
      style={{ cursor: 'grab' }}
      className="fa-regular fa-grip-dots cursor-grab focus:ring-0"
    />
  )
}
