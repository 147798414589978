import React from 'react'
import { ReactComponent as PrimeTimeLight } from '@sweetspot/sweetspot-js/assets/svgs/prime-time-white.svg'
import { ReactComponent as PrimeTimeDark } from '@sweetspot/sweetspot-js/assets/svgs/prime-time-black.svg'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@sweetspot/scramble-ds/atoms'

interface PrimeTimeProps {
  iconColor: 'light' | 'dark'
}

const PrimeTime = ({ iconColor }: PrimeTimeProps) => {
  const { t } = useTranslation()

  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={0}>
        <React.Fragment>
          <Tooltip.Trigger asChild>
            {iconColor === 'light' ? (
              <PrimeTimeLight className="w-3" />
            ) : (
              <PrimeTimeDark className="w-3" />
            )}
          </Tooltip.Trigger>
          <Tooltip.Content side="bottom">
            <div className="text-white">{t('primeTime')}</div>
            <Tooltip.Arrow />
          </Tooltip.Content>
        </React.Fragment>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

export default PrimeTime
