import { forwardRef, HTMLAttributes } from 'react'
import { cn } from '@sweetspot/scramble-ds/utils'
import Dock from './Dock'

const ButtonDock = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    dockClassName?: string
    isShown?: boolean
    isExpandable?: boolean
    expandableContent?: React.ReactNode
    collapsedContent?: React.ReactNode
  }
>(
  (
    {
      className,
      dockClassName,
      isShown,
      isExpandable,
      expandableContent,
      collapsedContent,
      ...props
    },
    ref
  ) => (
    <Dock
      className={dockClassName}
      isShown={isShown}
      isExpandable={isExpandable}
      expandableContent={expandableContent}
      collapsedContent={collapsedContent}
    >
      <div
        ref={ref}
        className={cn(
          'flex flex-col-reverse gap-2 md:flex-row md:justify-end md:gap-0 md:space-x-2',
          className
        )}
        {...props}
      />
    </Dock>
  )
)

export default ButtonDock
