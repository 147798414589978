import React from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Column } from '@tanstack/react-table'
import { TableCell } from '../Table'
import { Toggle } from '../../../../atoms'
import { DataTableMovableCell } from '../DataTableMovableCell'
import { cn } from '../../../../../utils'

interface DataTableColumnListEditorProps<TData, TValue> {
  index: number
  column: Column<TData, TValue>
}

export const DataTableColumnListEditor = <TData, TValue>({
  column,
  index,
}: DataTableColumnListEditorProps<TData, TValue>) => {
  const { setNodeRef, transform, isDragging, listeners, attributes } = useSortable({
    id: column.id,
  })

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: isDragging ? 'none' : 'opacity 0.2s ease',
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  }

  const isFirstColumn = index === 0

  return (
    <TableCell
      ref={!isFirstColumn ? setNodeRef : undefined}
      style={!isFirstColumn ? style : undefined}
      className={cn('border-border-stroke-clean flex gap-8 border-b py-2.5', {
        'text-text-pale': isFirstColumn,
      })}
    >
      <DataTableMovableCell dragHandleProps={{ ...attributes, ...listeners }} />
      {column.columnDef.meta?.title}

      <Toggle
        checked={column.getIsVisible()}
        disabled={isFirstColumn}
        onCheckedChange={(value) => column.toggleVisibility(!!value)}
        className="ml-auto"
      />
    </TableCell>
  )
}
