import { useCallback, useState } from 'react'
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
  SheetCustomContent,
} from '@sweetspot/scramble-ds'
import { useTranslation } from 'react-i18next'
import { Button } from '@sweetspot/scramble-ds/atoms'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { useResourcesStore } from '../../../../../../hooks/resources/useResourcesStore'
import { SideBarMode } from '../../../../../../types'
import { ActionItem } from '../../../components'

const ActionItemsBottomBar = () => {
  const { t } = useTranslation()
  const { toggleSideBar } = useResourcesStore()
  const [isSheetOpen, setIsSheetOpen] = useState(false)

  const toggleBottomBar = useCallback(() => {
    setIsSheetOpen(!isSheetOpen)
    toggleSideBar(SideBarMode.RESOURCES_ACTION_ITEMS)
  }, [isSheetOpen, toggleSideBar])

  const handleAboutResourcesItemClick = useCallback(() => {
    toggleBottomBar()
    toggleSideBar(SideBarMode.ABOUT_RESOURCES)
  }, [toggleBottomBar, toggleSideBar])

  return (
    <>
      <Button variant="link-dark" className="cursor-pointer outline-0" onClick={toggleBottomBar}>
        <FontAwesomeIcon icon={faEllipsis} />
      </Button>
      <Sheet open={isSheetOpen} onOpenChange={toggleBottomBar}>
        <SheetContent side="bottom" className="h-auto rounded-b-none rounded-t-xl pb-12">
          <SheetHeader className="rounded-b-none rounded-t-xl">
            <SheetHeaderLeftIcon onClick={toggleBottomBar}>
              <i className="fa-regular fa-close" />
            </SheetHeaderLeftIcon>
            <SheetTitle>{t('words.resources')}</SheetTitle>
          </SheetHeader>
          <SheetCustomContent className="text-content-base text-text-dark font-regular">
            <ActionItem
              iconName="plus"
              label="sentences.newResource"
              handleClick={toggleBottomBar}
            />
            <ActionItem
              iconName="circle-info"
              label="sentences.aboutResources"
              handleClick={handleAboutResourcesItemClick}
            />
          </SheetCustomContent>
        </SheetContent>
      </Sheet>
    </>
  )
}

export default ActionItemsBottomBar
