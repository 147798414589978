import { useState } from 'react'
import ButtonDock from '@sweetspot/sweetspot-js/common/components/ButtonDock/ButtonDock'
import { Button } from '@sweetspot/scramble-ds/atoms'
import ConfirmDialog from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/Period/PricingTab/PricingPeriodTabContent/ConfirmDialog'
import DiscardDialog from '@sweetspot/club-portal-legacy/Partials/Dialogs/DiscardDialog/DiscardDialog'
import { useAvailabilityAndPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/AvailabilityAndPricingProvider'
import { useTranslation } from 'react-i18next'

const PriceChangeButtonDock = () => {
  const { t } = useTranslation()
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  const { activePeriodTab, canSavePrices, onPricesSubmit, onPricesDiscard } =
    useAvailabilityAndPricingContext()

  const handleSave = () => {
    if (activePeriodTab === -1) {
      setOpenConfirmDialog(true)
    } else {
      onPricesSubmit()
    }
  }

  return (
    <>
      <ButtonDock
        className="md:justify-center"
        dockClassName="fixed pl-6 pr-6 left-[67px]"
        isShown={canSavePrices}
      >
        <Button
          variant="ghost-dark"
          size="small"
          type="button"
          onClick={() => setOpenDiscardDialog(true)}
        >
          {t('words.cancel')}
        </Button>
        <Button variant="primary" size="small" onClick={handleSave}>
          {t('words.save')}
        </Button>
      </ButtonDock>
      <ConfirmDialog
        open={openConfirmDialog}
        onCancel={() => setOpenConfirmDialog(false)}
        onSave={() => {
          onPricesSubmit(true)
          setOpenConfirmDialog(false)
        }}
      />
      <DiscardDialog
        open={openDiscardDialog}
        onClose={() => setOpenDiscardDialog(false)}
        onDiscard={() => {
          onPricesDiscard()
          setOpenDiscardDialog(false)
        }}
        onSave={() => {
          setOpenConfirmDialog(true)
          setOpenDiscardDialog(false)
        }}
      />
    </>
  )
}

export default PriceChangeButtonDock
