import React, { useCallback } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import { hexToRgb } from '@sweetspot/scramble-ds/utils'
import moment from 'moment'
import { priceToLocal } from '@sweetspot/shared/util/functions'
import BookingBox from './components/BookingBox'
import SelectableTeeTime from './components/SelectableTeeTime'
import { getPriceObject } from '@sweetspot/sweetspot-js/features/teeTimes/js/utils'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'
import { BayTechLogo } from '@sweetspot/club-portal/feature/venues'

const SpaceColumn = ({
  space,
  teeTimes,
  slotHeight,
  slotWidth,
  slotsPerHour,
  onSelectFrom,
  onSelectTo,
  onSelectSpace,
  onSelectPotentialSpace,
  selectedFrom,
  selectedTo,
  potentialFrom,
  potentialTo,
  onPotentialToUpdate,
  onPotentialFromUpdate,
  isSpaceSelected,
  isSpacePotentialSelected,
  bookings,
  intervalMinutes,
  venue,
  onMouseEnterBookingBox,
  onMouseLeaveBookingBox,
  hoveredOrderIds,
  editingCategory,
  shouldAnimate,
  isTeetimeEditable,
  editableBookingUUID,
  isBay,
  bays,
  hoveredTeeTime,
  setHoveredTeeTime,
  onResetSelectPotentialSpaces,
}) => {
  const { t } = useTranslation()

  const [currencyCode] = useClubCurrency()

  const onTeeTimeHover = (teeTime) => {
    if (!teeTime) {
      setHoveredTeeTime(null)
      onPotentialToUpdate(null)
      onPotentialFromUpdate(null)
      onResetSelectPotentialSpaces()
      return
    }
    if (teeTime.uuid === hoveredTeeTime?.uuid) return
    setHoveredTeeTime(teeTime)

    const isHoveredTeeTimeSelected = isTeeTimeSelected(teeTime)

    const { from_unix: deselectedFrom, to_unix: deselectedTo } = teeTime

    if (!isHoveredTeeTimeSelected) return false

    if (selectedFrom === deselectedFrom) {
      onSelectPotentialSpace(space)
    }

    if (selectedTo === deselectedTo) {
      onPotentialToUpdate(deselectedFrom)
    } else if (deselectedFrom < selectedFrom) {
      onPotentialFromUpdate(deselectedFrom)
    } else if (deselectedFrom > selectedFrom) {
      onPotentialToUpdate(deselectedFrom)
    }
  }

  const getTeeTimePrice = (teeTime) => {
    const priceObject = getPriceObject(teeTime, venue?.is_use_dynamic_pricing)
    const priceValue = priceToLocal(priceObject?.amount || 0, currencyCode, true)
    if (priceObject?.amount !== 0 && !priceObject?.amount) return t('words.priceMissing')
    return priceValue
  }

  const isTeeTimeSelected = (currentTeeTime) => {
    if (!venue?.is_use_dynamic_pricing && currentTeeTime?.price === null) return false
    const { from_unix: from, to_unix: to } = currentTeeTime
    return isSpaceSelected && selectedFrom && selectedTo && from >= selectedFrom && to <= selectedTo
  }

  const isPreviousTeeTimeSelected = (currentIndex) => {
    const previousTeeTime = teeTimes?.[currentIndex - 1]
    if (previousTeeTime) {
      return isTeeTimeSelected(previousTeeTime)
    }
    return false
  }

  const isNextTeeTimeSelected = (currentIndex) => {
    const nextTeeTime = teeTimes?.[currentIndex + 1]
    if (nextTeeTime) {
      return isTeeTimeSelected(nextTeeTime)
    }
    return false
  }

  const isTeeTimeSuggested = (teeTime) => {
    if (
      !isSpaceSelected &&
      selectedFrom &&
      selectedTo &&
      teeTime.from_unix >= selectedFrom &&
      teeTime.to_unix <= selectedTo
    )
      return true
    return false
  }

  const isPreviousTeeSuggested = (currentIndex) => {
    const previousTeeTime = teeTimes?.[currentIndex - 1]
    if (previousTeeTime) {
      return isTeeTimeSuggested(previousTeeTime)
    }
    return false
  }

  const isNextTeeTimeSuggested = (currentIndex) => {
    const nextTeeTime = teeTimes?.[currentIndex + 1]
    if (nextTeeTime) {
      return isTeeTimeSuggested(nextTeeTime)
    }
    return false
  }

  const isTeeTimeDisabled = (teeTime) => {
    return !venue?.is_use_dynamic_pricing && teeTime?.price === null
  }

  const onSelectHandler = (teeTime) => {
    if (isTeeTimeDisabled(teeTime)) return
    const { from_unix: from, to_unix: to } = teeTime

    if (!isSpaceSelected) onSelectSpace(space)
    if (!selectedFrom || from < selectedFrom) {
      onSelectFrom(from)
    }
    if (!selectedTo || to > selectedTo) {
      onSelectTo(to)
    }
  }

  const onDeselectHandler = (teeTime) => {
    const { from_unix: from, to_unix: to } = teeTime

    if (selectedFrom === from) {
      onSelectSpace(space)
    } else if (selectedTo === to) {
      onSelectTo(from)
    } else if (from < selectedFrom) {
      onSelectFrom(from)
    } else if (from > selectedFrom) {
      onSelectTo(from)
    }
  }

  const isTeeTimePossiblyDeselected = (currentTeeTime) => {
    if (!hoveredTeeTime) return false

    const isHoveredTeeTimeSelected = isTeeTimeSelected(hoveredTeeTime)

    if (!isHoveredTeeTimeSelected) return false

    const { from_unix: currentFrom, to_unix: currentTo } = currentTeeTime

    return (
      !isSpacePotentialSelected ||
      currentFrom < (potentialFrom || selectedFrom) ||
      currentTo > (potentialTo || selectedTo)
    )
  }

  const teeTimeIsFullHour = (teeTime) => {
    return moment.unix(teeTime.from_unix).minutes() === 0
  }

  const getTeeTimeBooking = (teeTime) => {
    return bookings.find((booking) => {
      const bookingStart = new Date(booking.booking.start_time),
        bookingEnd = new Date(booking.booking.end_time),
        teeTimeStart = new Date(teeTime.from),
        teeTimeEnd = new Date(teeTime.to)

      return (
        bookingStart.getTime() === teeTimeStart.getTime() ||
        bookingEnd.getTime() === teeTimeEnd.getTime() ||
        (teeTimeStart.getTime() > bookingStart.getTime() &&
          teeTimeEnd.getTime() < bookingEnd.getTime())
      )
    })
  }

  const getBookingHeight = (booking) => {
    const { start_time, end_time } = booking.booking
    const diffMinutes = moment.duration(moment(end_time).diff(moment(start_time))).asMinutes()
    return (diffMinutes / intervalMinutes) * slotHeight || 0
  }

  const isPreviousTeeTimeInSameBooking = (booking, currentIndex) => {
    if (currentIndex === 0) return false

    const previousTeeTime = teeTimes?.[currentIndex - 1]
    const previousBooking = getTeeTimeBooking(previousTeeTime)
    if (previousBooking && previousBooking.id === booking.id) {
      return true
    }
    return false
  }

  const isTeeTimeHovered = (teeTime) => {
    return teeTime.uuid === hoveredTeeTime?.uuid
  }

  const getPriceTextStyles = useCallback((isHovered, isPossiblySelect, isSelected) => {
    if (isHovered || isPossiblySelect || isSelected) {
      return ['text-content-xs', 'font-semibold']
    }
    return ['text-content-xs', 'font-regular']
  }, [])

  return (
    <div className={cx(styles.container)} style={{ width: slotWidth, maxWidth: slotWidth }}>
      <div className={cx(styles.header, { isBay: 'gap-1' })} style={{ width: slotWidth - 1 }}>
        <div className={cx(styles.spaceNameContainer)}>
          {isBay && (
            <BayTechLogo
              technology={space.ball_tracking_technology?.tracking_provider}
              noneClassName="text-xl"
              className="h-5 w-5"
            />
          )}
          <p className={cx(styles.spaceName, isBay ? 'w-auto pl-2' : 'w-full')}>
            {isBay ? space?.bay_number : space?.name || ''}
          </p>
        </div>
        <p className={cx(styles.spaceFloor)}>
          {space?.floor ? (
            <React.Fragment>
              {t('words.floor')} {space.floor}
            </React.Fragment>
          ) : (
            ''
          )}
        </p>
      </div>
      {/* <div className={cx(styles.headerFill)}></div> */}
      {teeTimes && slotsPerHour && space ? (
        <React.Fragment>
          {/** Tee times */}
          {teeTimes.map((teeTime, index) => {
            const isHovered = isTeeTimeHovered(teeTime)
            const isPossiblySelect =
              isNextTeeTimeSelected(index) ||
              isPreviousTeeTimeSelected(index) ||
              isTeeTimeSuggested(teeTime)
            const isSelected = isTeeTimeSelected(teeTime)
            const [priceTextStyle, priceFontStyle] = getPriceTextStyles(
              isHovered,
              isPossiblySelect,
              isSelected
            )
            return (
              <div
                name="teetime"
                style={{
                  '--startColor': hexToRgb(teeTime.category.previousColor),
                  '--endColor': hexToRgb(teeTime.category.color),
                  height: slotHeight,
                }}
                key={teeTime.id}
                className={cx(styles.teeTime, {
                  [styles.categoryAnim]: teeTime?.category?.playAnimation && shouldAnimate,
                })}
              >
                <div className={cx(styles.innerHeaderContainer)}>
                  <div
                    className={cx(styles.rowContainer, !editingCategory && styles.rowEndPosition)}
                  >
                    {editingCategory ? (
                      <p className={cx(styles.categoryName)}>{teeTime.category.name}</p>
                    ) : null}
                    {teeTime.is_prime_time === true ? (
                      <span
                        className={cx('material-icons', 'md-light', 'md-12')}
                        style={{ color: teeTime.category.color, paddingTop: '2px' }}
                      >
                        star
                      </span>
                    ) : null}
                  </div>
                  {!editingCategory && !getTeeTimeBooking(teeTime) ? (
                    <div className={cx(styles.priceContainer)}>
                      <p
                        className={cx(
                          styles.price,
                          `${priceTextStyle} ${priceFontStyle} text-text-dark ${
                            !isTeeTimeDisabled(teeTime) && 'cursor-pointer'
                          }`
                        )}
                        onMouseEnter={() => onTeeTimeHover(teeTime)}
                        onMouseLeave={() => onTeeTimeHover(null)}
                        onClick={() =>
                          isTeeTimeSelected(teeTime)
                            ? onDeselectHandler(teeTime)
                            : onSelectHandler(teeTime)
                        }
                      >
                        {`${
                          !!teeTime?.price_per_extra_player && teeTime?.price_per_extra_player > 0
                            ? t('words.from_short')
                            : ''
                        } ${getTeeTimePrice(teeTime)}`}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            )
          })}

          {/** Bookings and selectors */}
          {/** Is absolute positioned on top of tee times with higher z-index */}
          <div className={cx(styles.bookingsContainer)}>
            <div className={cx(styles.headerFiller)}></div>
            {teeTimes.map((teeTime, index) => {
              const booking = getTeeTimeBooking(teeTime)
              const showTopBorder = teeTimeIsFullHour(teeTime) && index !== 0
              if (isTeetimeEditable) {
                if (booking) {
                  if (editableBookingUUID !== booking.uuid) {
                    if (isPreviousTeeTimeInSameBooking(booking, index)) {
                      return null
                    }
                    return (
                      <BookingBox
                        key={booking.id}
                        booking={booking}
                        teeTime={teeTime}
                        height={getBookingHeight(booking)}
                        venue={venue}
                        selectionInProgress={false}
                        editingTeetimes={true}
                        onMouseEnterBookingBox={onMouseEnterBookingBox}
                        onMouseLeaveBookingBox={onMouseLeaveBookingBox}
                        shouldHoverStyle={hoveredOrderIds.includes(booking.id)}
                        space={space}
                        isBay={isBay}
                        bays={bays}
                      />
                    )
                  }

                  if (editableBookingUUID === booking.uuid) {
                    return (
                      <SelectableTeeTime
                        key={teeTime.id}
                        teeTime={teeTime}
                        setHoveredTeeTime={onTeeTimeHover}
                        showTopBorder={showTopBorder}
                        isHovered={isTeeTimeHovered(teeTime)}
                        isPossiblyDeselected={isTeeTimePossiblyDeselected(teeTime)}
                        isInBooking={false}
                        isSelected={isTeeTimeSelected(teeTime)}
                        isNextSelected={isNextTeeTimeSelected(index)}
                        isPreviousSelected={isPreviousTeeTimeSelected(index)}
                        isPreviousSuggested={isPreviousTeeSuggested(index)}
                        isNextSuggested={isNextTeeTimeSuggested(index)}
                        isSuggested={isTeeTimeSuggested(teeTime)}
                        isDisabled={isTeeTimeDisabled(teeTime)}
                        isFirstInSelectedGroup={teeTime.from_unix === selectedFrom}
                        onSelect={onSelectHandler}
                        onDeselect={onDeselectHandler}
                        height={slotHeight}
                      />
                    )
                  }
                } else {
                  return (
                    <SelectableTeeTime
                      key={teeTime.id}
                      teeTime={teeTime}
                      setHoveredTeeTime={onTeeTimeHover}
                      showTopBorder={showTopBorder}
                      isHovered={isTeeTimeHovered(teeTime)}
                      isPossiblyDeselected={isTeeTimePossiblyDeselected(teeTime)}
                      isInBooking={false}
                      isSelected={isTeeTimeSelected(teeTime)}
                      isNextSelected={isNextTeeTimeSelected(index)}
                      isPreviousSelected={isPreviousTeeTimeSelected(index)}
                      isPreviousSuggested={isPreviousTeeSuggested(index)}
                      isNextSuggested={isNextTeeTimeSuggested(index)}
                      isSuggested={isTeeTimeSuggested(teeTime)}
                      isDisabled={isTeeTimeDisabled(teeTime)}
                      isFirstInSelectedGroup={teeTime.from_unix === selectedFrom}
                      onSelect={onSelectHandler}
                      onDeselect={onDeselectHandler}
                      height={slotHeight}
                    />
                  )
                }
              } else {
                if (booking) {
                  if (isPreviousTeeTimeInSameBooking(booking, index)) {
                    return null
                  }
                  return (
                    <BookingBox
                      key={booking.id}
                      booking={booking}
                      teeTime={teeTime}
                      height={getBookingHeight(booking)}
                      venue={venue}
                      selectionInProgress={!!(selectedFrom || selectedTo)}
                      onMouseEnterBookingBox={onMouseEnterBookingBox}
                      onMouseLeaveBookingBox={onMouseLeaveBookingBox}
                      shouldHoverStyle={hoveredOrderIds.includes(booking.id)}
                      space={space}
                      isBay={isBay}
                      bays={bays}
                    />
                  )
                } else {
                  return (
                    <SelectableTeeTime
                      key={teeTime.id}
                      teeTime={teeTime}
                      isInBooking={!!booking}
                      setHoveredTeeTime={onTeeTimeHover}
                      isPossiblyDeselected={isTeeTimePossiblyDeselected(teeTime)}
                      showTopBorder={showTopBorder}
                      isHovered={isTeeTimeHovered(teeTime)}
                      isSelected={isTeeTimeSelected(teeTime)}
                      isNextSelected={isNextTeeTimeSelected(index)}
                      isPreviousSelected={isPreviousTeeTimeSelected(index)}
                      isPreviousSuggested={isPreviousTeeSuggested(index)}
                      isNextSuggested={isNextTeeTimeSuggested(index)}
                      isSuggested={isTeeTimeSuggested(teeTime)}
                      isDisabled={isTeeTimeDisabled(teeTime)}
                      isFirstInSelectedGroup={teeTime.from_unix === selectedFrom}
                      onSelect={onSelectHandler}
                      onDeselect={onDeselectHandler}
                      height={slotHeight}
                    />
                  )
                }
              }
              return null
            })}
          </div>
        </React.Fragment>
      ) : (
        <div className={cx(styles.loaderContainer)}>
          <PulseLoader showIf={true} />
        </div>
      )}
    </div>
  )
}

SpaceColumn.propTypes = {
  space: PropTypes.object,
  teeTimes: PropTypes.array,
  slotHeight: PropTypes.number,
  slotWidth: PropTypes.number,
  slotsPerHour: PropTypes.number,
  onSelectFrom: PropTypes.func,
  onSelectTo: PropTypes.func,
  onSelectSpace: PropTypes.func,
  selectedFrom: PropTypes.number,
  selectedTo: PropTypes.number,
  isSpaceSelected: PropTypes.bool,
  bookings: PropTypes.array,
  earliestTeeTime: PropTypes.object,
  intervalMinutes: PropTypes.number,
  venue: PropTypes.object,
  onMouseEnterBookingBox: PropTypes.func,
  onMouseLeaveBookingBox: PropTypes.func,
  hoveredOrderIds: PropTypes.array,
}

SpaceColumn.defaultProps = {
  space: {},
  teeTimes: [],
  slotHeight: 35,
  slotWidth: 145,
  slotsPerHour: 0,
  onSelectFrom: () => {},
  onSelectTo: () => {},
  onSelectSpace: () => {},
  selectedFrom: null,
  selectedTo: null,
  isSpaceSelected: false,
  bookings: [],
  venue: null,
  onMouseEnterBookingBox: () => {},
  onMouseLeaveBookingBox: () => {},
  hoveredOrderIds: [],
}

export default SpaceColumn
