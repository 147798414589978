import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import Grid from '@sweetspot/club-portal-legacy/components/Grid'
import Text from '@sweetspot/club-portal-legacy/components/Text'

import PartnershipWizardModal from '@sweetspot/club-portal-legacy/modals/PartnershipWizardModal'
import { getPartnerships } from '@sweetspot/club-portal-legacy/store/actions'
import NoPartnerships from './components/NoPartnerships'
import headers from './headers.json'

const actions = [
  {
    id: 1,
    label: 'createNew',
    action: 'createNewPartnership',
  },
]

const filters = [
  {
    id: 1,
    name: '.status',
    options: [
      { id: 1, name: '.active', filter: 'ACTIVE' },
      { id: 2, name: '.inActive', filter: 'INACTIVE' },
      { id: 3, name: '.expired', filter: 'EXPIRED' },
      { id: 4, name: '.canceled', filter: 'CANCELED' },
    ],
  },
  {
    id: 2,
    name: '.partnerAdmin.partnerships',
    options: [
      { id: 1, name: '.prepay', filter: 'PREPAY' },
      { id: 2, name: '.postpay', filter: 'POSTPAY' },
    ],
  },
]

class Partnerships extends Component {
  state = {
    activeFilters: [],
    sortItems: [],
    isLoading: false,
    noSearchResult: false,
    searchString: '',
    currentPage: 1,
  }

  componentDidMount = () => {
    this.setState({ activeFilters: ['ACTIVE'] }, () =>
      this.getPartnershipList(this.state.activeFilters, true)
    )
  }

  componentDidUpdate = (prevProps) => {
    const { golfClubs, location } = this.props
    const { state } = prevProps.location

    if (golfClubs.selectedId !== prevProps.golfClubs.selectedId) {
      this.setState({ searchString: '', noSearchResult: false })
    }

    if (location && location.state && location.state.shouldReload && !state) {
      this.getPartnershipList(this.state.activeFilters)
    }
  }

  handleSearch = (value = '') => {
    this.setState({ searchString: value })
  }

  handleSetCurrentPage = (pageNum = 1) => {
    this.setState({ currentPage: pageNum }, () => {
      this.getPartnershipList(this.state.activeFilters)
    })
  }

  handleActionConfirm = (action) => {
    if (action.action === 'createNewPartnership') this.onCreateNewPartnership()
  }

  getPartnershipList = (activeFilters, initalLoad) => {
    const { searchString, currentPage, sortItems } = this.state
    const {
      token,
      golfClubs: { selectedId },
      getPartnerships,
    } = this.props

    let query = `?page=${currentPage}&club.id[]=${selectedId}`

    const orderingOptions = {
      name: 'name',
      startDate: 'startsAt',
      endDate: 'expiresAt',
      price: 'priceIncludedVat',
    }

    sortItems.forEach((sort) => {
      const sortName = orderingOptions[sort.name] || ''
      query += `&order[${sortName}]=${sort.type}`
    })

    if (searchString) query += `&search=${searchString}`

    this.setState({ isLoading: true })
    getPartnerships(token, query, activeFilters).then((data) => {
      this.setState({
        isLoading: false,
        noSearchResult: !data['hydra:member'].length && !!searchString,
      })

      // --- COMMENT THIS FOR INITAL PAYMENT LINKS RELEASE, WILL GET ADDED BACK LATER

      // if (initalLoad) {
      //   const partnerships = data['hydra:member']
      //   const totalCount = partnerships.length
      //   const trueCount = partnerships?.filter((p) => p?.automatic_payment_link)?.length
      //   const trackingString = `${trueCount}/${totalCount}`

      //   if (partnerships.length > 0) {
      //     updateUserProperty(
      //       AMPLITUDE_USER_PROPERTIES.ACTIVE_PARTNERSHIPS_WITH_PAYMENT_LINKS,
      //       trackingString
      //     )
      //   }
      // }
    })
  }

  onCreateNewPartnership = () => {
    this.props.history.push('/partnerships/new')
  }

  onEditMembership = (row) => {
    const { _id } = row
    this.props.history.push(`/partnerships/${_id}`)
  }

  handleSort = (sortItems) => {
    this.setState({ sortItems }, () => this.getPartnershipList(this.state.activeFilters))
  }

  handleFilters = (filter) => {
    if (!filter) {
      this.setState({ activeFilters: [], searchString: '' }, () =>
        this.getPartnershipList(this.state.activeFilters)
      )

      return null
    }
    this.handleSetCurrentPage(1)
    let activeFilters = [...this.state.activeFilters]

    if (!activeFilters.includes(filter)) {
      activeFilters.push(filter)
    } else activeFilters.splice(activeFilters.indexOf(filter), 1)
    this.setState({ activeFilters }, () => {
      this.handleUpdatePartnershipsToDisplay(activeFilters)
    })
  }

  handleUpdatePartnershipsToDisplay = (filters) => {
    if (!filters) {
      return
    }
    let queryString = ''
    if (this.state.activeFilters) {
      filters.forEach((filters) => {
        switch (filters) {
          case 'ACTIVE':
            queryString += `&status[]=active`
            break
          case 'INACTIVE':
            queryString += `&status[]=future`
            break
          case 'EXPIRED':
            queryString += `&status[]=expired`
            break
          case 'CANCELED':
            queryString += `&status[]=canceled`
            break
          case 'PREPAY':
            queryString += `&type[]=prepay`
            break
          case 'POSTPAY':
            queryString += `&type[]=postpay`
            break
          default:
            break
        }
      })
    }

    this.setState({ searchString: queryString }, () => {
      this.getPartnershipList(this.state.activeFilters)
    })
  }

  render() {
    const { isLoading, noSearchResult } = this.state
    let {
      partnerships,
      totalPages,
      totalItems,
      itemsPerPage,
      location: { pathname },
      match: { path },
    } = this.props

    return (
      <React.Fragment>
        {!isLoading &&
        !partnerships.length &&
        !noSearchResult &&
        this.state.activeFilters.length === 0 ? (
          <NoPartnerships onClick={this.onCreateNewPartnership} />
        ) : (
          <div className={'relative h-full p-10'}>
            <Grid
              filterStyles={'w-auto'}
              activeFilters={this.state.activeFilters}
              handleFilters={this.handleFilters}
              filters={filters}
              filtersEnabled
              filtersTitle={'partnerAdmin.partnershipsFilter'}
              actions={actions}
              actionsEnabled
              isLoading={isLoading}
              values={partnerships}
              pageTitle={'partnerAdmin.partnerships'}
              headers={headers}
              searchEnabled
              searchPlaceholder={'.partnerAdmin.searchPartnerships'}
              pagination
              totalPages={totalPages}
              rowsPerPage={itemsPerPage}
              totalRows={totalItems}
              displayRangeOfRows
              onRowClick={this.onEditMembership}
              setCurrentPage={this.handleSetCurrentPage}
              onActionConfirm={this.handleActionConfirm}
              onSearchChange={this.handleSearch}
              onSearch={() => this.getPartnershipList()}
              onHeaderClick={this.handleSort}
            />
            {!isLoading && noSearchResult && pathname === '/partnerships' && (
              <div>
                <Text textId="noSearchResults" />
              </div>
            )}
          </div>
        )}
        <Route path={`${path}/:partnershipId`} component={PartnershipWizardModal} />
      </React.Fragment>
    )
  }
}

Partnerships.propTypes = {
  token: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  golfClubs: PropTypes.object.isRequired,
  partnerships: PropTypes.array.isRequired,
  totalPages: PropTypes.number,
  itemsPerPage: PropTypes.number,
  totalItems: PropTypes.number,
  getPartnerships: PropTypes.func.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  lang: state.auth.me.lang,
  golfClubs: state.golfClub,
  partnerships: state.partnership.list,
  totalPages: state.partnership.totalPages,
  itemsPerPage: state.partnership.itemsPerPage,
  totalItems: state.partnership.totalItems,
})

const mapDispatchToProps = (dispatch) => ({
  getPartnerships: (token, query, activeFilters) =>
    dispatch(getPartnerships(token, query, activeFilters)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Partnerships)
