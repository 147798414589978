import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@sweetspot/scramble-ds/atoms'
import {
  Input,
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputTrailingContainer,
} from '@sweetspot/scramble-ds'
import { GolfCourseTypes } from '@sweetspot/shared/types'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'

import { useAvailabilityAndPricingContext } from '../../../AvailabilityAndPricingProvider'

const MIN_PRICE = 0
const MAX_PRICE = 99999
const MIN_PRICE_PER_EXTRA_PLAYER = 0
const MAX_PRICE_PER_EXTRA_PLAYER = 9999

const PricingTableInputs = () => {
  const { t } = useTranslation()
  const [currency] = useClubCurrency()
  const {
    currentCourse,
    price,
    setPrice,
    pricePerExtraPlayer,
    setPricePerExtraPlayer,
    selectedCells,
    onPricesApply,
  } = useAvailabilityAndPricingContext()
  const [priceInput, setPriceInput] = useState(price.toString())
  const [priceExtraInput, setPriceExtraInput] = useState(pricePerExtraPlayer.toString())

  useEffect(() => {
    setPriceInput(price.toString())
  }, [price])

  useEffect(() => {
    setPriceExtraInput(pricePerExtraPlayer.toString())
  }, [pricePerExtraPlayer])

  const hasExtraPlayers = useMemo(
    () => currentCourse?.type !== GolfCourseTypes.COURSE,
    [currentCourse?.type]
  )

  const validateAndClamp = useCallback((value: string, min: number, max: number): number | null => {
    const parsed = parseFloat(value)
    if (isNaN(parsed)) {
      return null
    }
    return Math.max(min, Math.min(max, parsed))
  }, [])

  const isPriceValid = useMemo(() => {
    const parsed = parseFloat(priceInput)
    return priceInput !== '' && !isNaN(parsed) && parsed >= MIN_PRICE && parsed <= MAX_PRICE
  }, [priceInput])

  const isPriceExtraValid = useMemo(() => {
    if (!hasExtraPlayers) return true
    const parsed = parseFloat(priceExtraInput)
    return (
      priceExtraInput !== '' &&
      !isNaN(parsed) &&
      parsed >= MIN_PRICE_PER_EXTRA_PLAYER &&
      parsed <= MAX_PRICE_PER_EXTRA_PLAYER
    )
  }, [priceExtraInput, hasExtraPlayers])

  const isApplyDisabled = useMemo(
    () => selectedCells.size === 0 || !isPriceValid || !isPriceExtraValid,
    [selectedCells, isPriceValid, isPriceExtraValid]
  )

  const preventedSymbols = useMemo(() => ['e', 'E', '+', '-'], [])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const isPreventedSymbol = preventedSymbols.includes(e.key)
      if (isPreventedSymbol) {
        e.preventDefault()
      }
    },
    [preventedSymbols]
  )

  const handlePriceInputChange = useCallback((value: string) => {
    setPriceInput(value)
  }, [])

  const handlePriceExtraInputChange = useCallback((value: string) => {
    setPriceExtraInput(value)
  }, [])

  const handlePriceBlur = useCallback(() => {
    const clamped = validateAndClamp(priceInput, MIN_PRICE, MAX_PRICE)
    if (clamped !== null) {
      setPrice(Number(clamped.toFixed(2)))
      setPriceInput(clamped.toString())
    }
  }, [priceInput, setPrice, validateAndClamp])

  const handlePriceExtraBlur = useCallback(() => {
    const clamped = validateAndClamp(
      priceExtraInput,
      MIN_PRICE_PER_EXTRA_PLAYER,
      MAX_PRICE_PER_EXTRA_PLAYER
    )
    if (clamped !== null) {
      setPricePerExtraPlayer(Number(clamped.toFixed(2)))
      setPriceExtraInput(clamped.toString())
    }
  }, [priceExtraInput, setPricePerExtraPlayer, validateAndClamp])

  const handleApply = useCallback(() => {
    const clampedPrice = validateAndClamp(priceInput, MIN_PRICE, MAX_PRICE)
    if (clampedPrice !== null) {
      setPrice(Number(clampedPrice.toFixed(2)))
      setPriceInput(clampedPrice.toString())
    }
    if (hasExtraPlayers) {
      const clampedExtra = validateAndClamp(
        priceExtraInput,
        MIN_PRICE_PER_EXTRA_PLAYER,
        MAX_PRICE_PER_EXTRA_PLAYER
      )
      if (clampedExtra !== null) {
        setPricePerExtraPlayer(Number(clampedExtra.toFixed(2)))
        setPriceExtraInput(clampedExtra.toString())
      }
    }
    onPricesApply()
  }, [
    priceInput,
    priceExtraInput,
    hasExtraPlayers,
    setPrice,
    setPricePerExtraPlayer,
    onPricesApply,
    validateAndClamp,
  ])

  return (
    <div className="flex items-end gap-4">
      <InputBase className="w-[146px]">
        <InputLabelContainer>{t('editPeriodsNew.price')}</InputLabelContainer>
        <InputContainer>
          <Input
            value={priceInput}
            onChange={(e) => handlePriceInputChange(e.target.value)}
            onKeyDown={handleKeyDown}
            onBlur={handlePriceBlur}
            className="text-content-base border-border-stroke-clean h-8 border-[1px] px-4"
            type="number"
            min={MIN_PRICE}
            max={MAX_PRICE}
          />
          <InputTrailingContainer className="text-content-sm text-text-dark right-4 font-medium">
            <p>{currency}</p>
          </InputTrailingContainer>
        </InputContainer>
      </InputBase>

      {hasExtraPlayers && (
        <InputBase className="w-[146px]">
          <InputLabelContainer>{t('editPeriodsNew.pricePerExtraPlayer')}</InputLabelContainer>
          <InputContainer>
            <Input
              value={priceExtraInput}
              onChange={(e) => handlePriceExtraInputChange(e.target.value)}
              onKeyDown={handleKeyDown}
              onBlur={handlePriceExtraBlur}
              className="text-content-base border-border-stroke-clean h-8 border-[1px] px-4"
              type="number"
              min={MIN_PRICE_PER_EXTRA_PLAYER}
              max={MAX_PRICE_PER_EXTRA_PLAYER}
            />
            <InputTrailingContainer className="text-content-sm text-text-dark right-4 font-medium">
              <p>{currency}</p>
            </InputTrailingContainer>
          </InputContainer>
        </InputBase>
      )}

      <Button
        variant="ghost-dark"
        className="h-8 w-[146px]"
        disabled={isApplyDisabled}
        onClick={handleApply}
      >
        {t('words.apply')}
      </Button>
    </div>
  )
}

export default PricingTableInputs
