import { MembershipItem } from '@sweetspot/club-portal-legacy/store/types'
import _ from 'lodash'
import { FilterType } from './types'
import { TableFilter } from '@sweetspot/scramble-ds'
import { MembershipCard } from '@sweetspot/shared/types'

export const sortMemberships = (membershipList: MembershipCard[]) => {
  // Sorting logic based on priority: active > upcoming > others
  const sortedMemberships = membershipList.sort((a, b) => {
    const statusPriority = { active: 3, upcoming: 2, default: 1 }
    return (statusPriority[b.status] || 1) - (statusPriority[a.status] || 1)
  })

  return [...sortedMemberships]
}

export const getExportFilterQuery = (
  clubId: number,
  searchString: string,
  activeFilters: FilterType[]
): { [key: string]: any } => {
  const filters: {
    relatedGolfClub: number
    golfClub: number
    search?: string
    type?: { members?: number; guests?: number }
    membershipPaid?: { paid?: number; not_paid?: number }
    gitOnlyMembership?: number
    membership?: { [key: string]: any; golf_club_id: number }
  } = {
    relatedGolfClub: clubId,
    golfClub: clubId,
  }

  if (searchString) filters.search = searchString

  const memberships: { [key: string]: FilterType } = {}

  const filterMappings: { [key in FilterType]: () => void } = {
    MEMBER: () => (filters.type = { ...filters.type, members: clubId }),
    GUEST: () => (filters.type = { ...filters.type, guests: clubId }),
    PAID: () => (filters.membershipPaid = { ...filters.membershipPaid, paid: clubId }),
    UNPAID: () => (filters.membershipPaid = { ...filters.membershipPaid, not_paid: clubId }),
    GIT_MEMBERS: () => (filters.gitOnlyMembership = clubId),
  }

  activeFilters.forEach((filter) => {
    filterMappings[filter]
      ? filterMappings[filter]()
      : (memberships[Object.keys(memberships).length] = filter)
  })

  filters.membership = { ...memberships, golf_club_id: clubId }

  return filters
}

export const formatFilters = (
  memberships: MembershipItem[],
  federationMemberships: MembershipItem[],
  hasCdhNumber: boolean
): TableFilter[] => {
  const filteredMemberships = memberships.filter((membership) => membership.state !== 'draft')

  const clubMemberships = filteredMemberships
    .filter((membership) => membership.type === 'words.membership_one')
    .map((membership) => ({ label: membership.name, value: membership.id.toString() }))

  const clubPasses = filteredMemberships
    .filter((membership) => membership.type === 'words.pass_one')
    .map((membership) => ({ label: membership.name, value: membership.id.toString() }))

  const filters: TableFilter[] = [
    {
      id: 1,
      label: 'players.playerType',
      options: [
        { label: 'players.member', value: 'MEMBER' },
        { label: 'players.guest', value: 'GUEST' },
      ],
    },
    {
      id: 2,
      label: 'membership.paymentStatus.title',
      options: [
        { label: 'players.paid', value: 'PAID' },
        { label: 'players.notPaid', value: 'UNPAID' },
      ],
    },
    ...(clubMemberships.length > 0
      ? [
          {
            id: 3,
            accordion: true,
            label: 'words.membership_other',
            options: _.orderBy(clubMemberships, 'label', 'asc'),
          },
        ]
      : []),
    ...(clubPasses.length > 0
      ? [
          {
            id: 4,
            accordion: true,
            label: 'words.pass_other',
            options: _.orderBy(clubPasses, 'label', 'asc'),
          },
        ]
      : []),
    {
      id: 5,
      accordion: true,
      label: hasCdhNumber ? 'players.cdhFilter' : 'players.gitFilter',
      options: [
        {
          label: hasCdhNumber ? 'players.cdhMembers' : 'players.gitMembers',
          value: 'GIT_MEMBERS',
        },
      ],
      divider: true,
    },
    ...(federationMemberships.length > 0
      ? [
          {
            id: 6,
            accordion: true,
            label: hasCdhNumber ? 'players.cdhMembership' : 'players.gitMembership',
            options: _.orderBy(
              federationMemberships.map((item) => ({
                label: item.name,
                value: {
                  name: 'FEDERATION_MEMBERSHIP',
                  value: item.id,
                },
              })),
              'name',
              'asc'
            ),
          },
        ]
      : []),
  ]

  return filters
}
