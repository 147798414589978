/* eslint-disable react-hooks/rules-of-hooks */
import {
  DataTableColumnHeader,
  ListItem,
  ListItemLeading,
  ListItemMainContainer,
  ListItemMainContent,
  ListItemMainLabel,
} from '@sweetspot/scramble-ds'
import { ColumnDef } from '@tanstack/react-table'
import { AdyenStore } from '../../types'
import { EditStoreSheet } from './EditStoreSheet'
import { useState } from 'react'
import { Popover } from '@sweetspot/scramble-ds/atoms'

export const columns = (t: (key: string) => string): ColumnDef<AdyenStore>[] => {
  return [
    {
      accessorKey: 'adyen_store_code',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('Store code') },
      cell: ({ row }) => t(row.getValue('adyen_store_code')),
    },
    {
      accessorKey: 'adyen_business_line_id',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('Business line id') },
      cell: ({ row }) => row.getValue('adyen_business_line_id'),
      enableSorting: false,
    },
    {
      accessorKey: 'adyen_store_id',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('Store id') },
      cell: ({ row }) => row.getValue('adyen_store_id'),
      enableSorting: false,
    },
    {
      id: 'edit',
      header: () => null,
      enableSorting: false,
      cell: ({ row }) => {
        const [isPopoverOpen, setIsPopoverOpen] = useState(false)

        return (
          <div>
            <Popover.Root open={isPopoverOpen}>
              <Popover.Trigger asChild>
                <div
                  className="cursor-pointer px-4"
                  onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                >
                  <i className="fa-regular fa-ellipsis-vertical text-base" />
                </div>
              </Popover.Trigger>
              <Popover.Content
                className="p-0 drop-shadow"
                align="end"
                onInteractOutside={() => setIsPopoverOpen(false)}
              >
                <EditStoreSheet
                  storeId={row.original.id}
                  accountType={row.original.merchant_account_type}
                  setIsPopoverOpen={setIsPopoverOpen}
                >
                  <ListItem className="w-full min-w-fit cursor-pointer">
                    <ListItemLeading className="ml-3">
                      <i className="fa-regular fa-edit text-base" />
                    </ListItemLeading>
                    <ListItemMainContainer>
                      <ListItemMainContent>
                        <ListItemMainLabel className="font-normal">
                          {t('settings.billing.editStore')}
                        </ListItemMainLabel>
                      </ListItemMainContent>
                    </ListItemMainContainer>
                  </ListItem>
                </EditStoreSheet>
                <Popover.Arrow className="fill-white" width={20} height={10} />
              </Popover.Content>
            </Popover.Root>
          </div>
        )
      },
    },
  ]
}
