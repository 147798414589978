import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import {
  EmptyState,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'

import { useAvailabilityAndPricingContext } from '../../AvailabilityAndPricingProvider'
import PricingForm from './PricingForm'

const PricingTab = () => {
  const [isCreating, setIsCreating] = useState(false)
  const { t } = useTranslation()
  const { hasPricings } = useAvailabilityAndPricingContext()

  if (!hasPricings && !isCreating) {
    return (
      <EmptyState
        key="no-pricing"
        iconName="fa-regular fa-money-bill-wave"
        className="max-w-[372px] px-6"
        iconClassName={
          'mb-lg bg-background-mono-lighter rounded-full p-4 w-[92px] h-[92px] flex justify-center items-center'
        }
      >
        <EmptyStateTitle>{t('sentences.noPricingSet')}</EmptyStateTitle>
        <EmptyStateDescription>
          <div className="mb-4 text-center">{t('sentences.noPricingSetDescription')}</div>
        </EmptyStateDescription>
        <EmptyStateButton
          className="text-content-sm font-medium"
          onClick={() => setIsCreating(true)}
        >
          {t('sentences.createPricing')}
        </EmptyStateButton>
      </EmptyState>
    )
  }

  return <PricingForm />
}

export default PricingTab
