import { Tag, Tooltip } from '@sweetspot/scramble-ds/atoms'
import { MembershipCard } from '@sweetspot/shared/types'
import { useTranslation } from 'react-i18next'

interface SubscriptionTooltipProps {
  subscriptions: MembershipCard[]
  tagIcon: string
}

export const SubscriptionTooltip = ({ subscriptions, tagIcon }: SubscriptionTooltipProps) => {
  const { t } = useTranslation()

  if (!subscriptions?.length) return null

  return (
    <Tooltip.Root delayDuration={0}>
      <Tooltip.Trigger asChild>
        <Tag.Root variant="inverted" size="large" className="px-2 py-0.5 text-xs font-bold">
          <i className={`${tagIcon} mr-1 text-lg leading-none`} />
          {subscriptions.length}
        </Tag.Root>
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content side="right" className="flex flex-col space-y-1.5" align="start">
          {subscriptions.map((subscription, index) => {
            const isActive = subscription?.status === 'active'
            const isUpcoming =
              subscription?.status === 'upcoming' || subscription?.status === 'future'

            const isNotPaid =
              subscription?.status === 'future' ||
              subscription?.status === 'not_paid' ||
              subscription?.status === 'paritally_paid' ||
              subscription?.status === 'init'

            const isPaid = subscription?.state === 'paid' && subscription?.status === 'upcoming'

            return (
              <div key={index} className="flex items-center gap-2">
                <p className="max-w-[200px] overflow-hidden truncate whitespace-nowrap">
                  {subscription?.membership?.name}
                </p>
                {isActive && (
                  <Tag.Root variant="success-filled" className="px-2 font-normal">
                    {t('words.active')}
                  </Tag.Root>
                )}
                {isUpcoming && (
                  <Tag.Root variant="inverted" className="px-2 font-normal">
                    {t('words.upcoming')}
                  </Tag.Root>
                )}
                {isPaid && (
                  <Tag.Root variant="success-filled" className="px-2 font-normal">
                    {t('words.paid')}
                  </Tag.Root>
                )}
                {isNotPaid && (
                  <Tag.Root variant="danger-filled" className="px-2 font-normal">
                    {t('words.unpaid')}
                  </Tag.Root>
                )}
              </div>
            )
          })}
          <Tooltip.Arrow width={20} height={10} />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  )
}

export default SubscriptionTooltip
