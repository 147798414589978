import React, { ReactNode, useMemo } from 'react'
import TimePicker from 'rc-time-picker'
import moment from 'moment/moment'
import { Check, Tooltip } from '@sweetspot/scramble-ds/atoms'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { Trans, useTranslation } from 'react-i18next'

type PeriodTimePickerProps = {
  time: string
  onChange: (newTime: string) => void
  disabled?: boolean
}

const PeriodTimePicker = ({ time, onChange, disabled }: PeriodTimePickerProps) => {
  const value = useMemo(() => (time ? moment(time, 'HH:mm') : undefined), [time])
  return (
    <TimePicker
      className="[&>input]:placeholder:text-text-medium [&>input]:placeholder:text-content-base [&>input]:text-content-base [&>input]:font-regular [&_input]:text-text-dark [&>input]:h-8 [&>input]:pb-0 [&>input]:pl-4 [&>input]:pr-2 [&>input]:pt-[1px] [&>input]:leading-normal [&>input]:placeholder:italic"
      showSecond={false}
      disabled={disabled}
      value={value}
      onChange={(newTime) => onChange(newTime.format('HH:mm:ss'))}
      minuteStep={1}
      popupClassName="pointer-events-auto [&>div>.rc-time-picker-panel-input-wrap]:pl-0 [&>div>.rc-time-picker-panel-input-wrap>input]:pl-4 [&>div>.rc-time-picker-panel-combobox]:flex [&>div>.rc-time-picker-panel-combobox>div]:flex [&>div>.rc-time-picker-panel-combobox>div]:flex-1 [&>div]:text-content-base !max-w-none [&>div>.rc-time-picker-panel-combobox>div>ul>li]:p-0 [&>div>.rc-time-picker-panel-combobox>div>ul>li]:text-center"
      clearIcon={<div></div>}
      placeholder="hh:mm"
      // getPopupContainer is missing from TimePickerProps, thus throwing a ts error
      // BUT we need it to enable scrolling of the timepicker if inside the Sheet:
      // https://github.com/react-component/time-picker/issues/64#issuecomment-343506191
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getPopupContainer={(triggerNode: { parentNode: ReactNode }) => triggerNode.parentNode}
      focusOnOpen
    />
  )
}

type PeriodOpeningHoursProps = {
  startTimeFrom: string
  onStartTimeFromChange: (newTime: string) => void
  startTimeTo: string
  onStartTimeToChange: (newTime: string) => void
  isOpeningHoursChecked?: boolean
  readOnly?: boolean
  showOpeningHoursOption?: boolean
  onOpeningHoursChange?: (value: boolean) => void
  tooltipContent?: string
}
const PeriodOpeningHours = ({
  startTimeFrom,
  onStartTimeFromChange,
  startTimeTo,
  onStartTimeToChange,
  showOpeningHoursOption,
  isOpeningHoursChecked,
  onOpeningHoursChange,
  readOnly,
  tooltipContent,
}: PeriodOpeningHoursProps) => {
  const { t } = useTranslation()
  return (
    <div className="p-md bg-background-base-neutral gap-1 rounded-md border-0 ">
      <div className="px-sm flex justify-between gap-4 py-2">
        <p className="text-text-dark text-content-base overflow-hidden text-ellipsis whitespace-nowrap font-bold">
          {t('sentences.openingHours')}
        </p>
        {tooltipContent && (
          <div className="flex h-6 w-6 items-center justify-end p-1">
            <Tooltip.Provider key="datesTooltip">
              <Tooltip.Root>
                <React.Fragment key="datesTooltip">
                  <Tooltip.Trigger asChild>
                    <FontAwesomeIcon icon={faCircleInfo} className="text-text-subtle" />
                  </Tooltip.Trigger>
                  <Tooltip.Content>
                    <div className="flex w-[263px] flex-col gap-4 py-2">
                      <p className="text-content-sm text-text-light font-bold">
                        {t('sentences.openingHours')}
                      </p>
                      <p className="text-content-sm font-regular text-text-light">
                        <Trans
                          i18nKey={tooltipContent}
                          components={{ strong: <strong />, br: <br /> }}
                        />
                      </p>
                    </div>
                    <Tooltip.Arrow />
                  </Tooltip.Content>
                </React.Fragment>
              </Tooltip.Root>
            </Tooltip.Provider>
          </div>
        )}
      </div>
      <div className="itemc-center flex justify-between gap-4">
        <div className="flex flex-col gap-0.5">
          <p
            className={`text-text-${
              readOnly ? 'pale' : 'dark'
            } text-content-xs flex h-5 items-end px-2 font-bold`}
          >
            {t('words.from')}
          </p>
          <PeriodTimePicker
            disabled={readOnly}
            time={startTimeFrom}
            onChange={onStartTimeFromChange}
          />
        </div>
        <div className="flex flex-col gap-0.5">
          <p
            className={`text-text-${
              readOnly ? 'pale' : 'dark'
            } text-content-xs flex h-5 items-end px-2 font-bold`}
          >
            {t('words.to')}
          </p>
          <PeriodTimePicker disabled={readOnly} time={startTimeTo} onChange={onStartTimeToChange} />
        </div>
      </div>
      {showOpeningHoursOption && (
        <div className="mt-1 flex h-12 items-center">
          <Check
            className="h-auto gap-2 p-2 py-0"
            checked={!!isOpeningHoursChecked}
            label={t(`sentences.openingHours`)}
            labelClassName="text-text-dark text-content-base font-regular"
            onCheckedChange={onOpeningHoursChange}
          />
        </div>
      )}
    </div>
  )
}

export default PeriodOpeningHours
