import {
  useCallback,
  createContext,
  useMemo,
  FC,
  PropsWithChildren,
  useContext,
  useState,
  useEffect,
} from 'react'
import { useHistory } from 'react-router-dom'
import { ManageExtrasContextValue } from './types'
import useExtras from '@sweetspot/club-portal-legacy/hooks/useExtras'
import { DiscardDialogCallbacks } from '@sweetspot/club-portal-legacy/Partials/Dialogs/DiscardDialog/types'

export const ManageExtrasContext = createContext<ManageExtrasContextValue>({
  areExtrasLoading: false,
  extras: [],
  openCreateExtrasSidebar: false,
  refetchExtras: () => {
    return
  },
  toggleCreateExtrasSideBar: () => {
    return
  },
})

export const ManageExtrasProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openCreateExtrasSidebar, setOpenBaySideBar] = useState(false)
  const [openDiscardDialog, setOpenDiscardDialog] = useState<boolean>(false)
  const [discardDialogCallbacks, setDiscardDialogCallbacks] = useState<DiscardDialogCallbacks>()
  const { extras, areExtrasLoading, selectedExtra, setSelectedExtra, refetchExtras } = useExtras()
  const history = useHistory()

  // set openCreateExtrasSidebar tab based on hash
  useEffect(() => {
    const hash = history.location.hash
    setOpenBaySideBar(hash === '#new')
  }, [history.location.hash])

  const toggleCreateExtrasSideBar = useCallback(() => {
    const isOpen = !openCreateExtrasSidebar
    const path = history.location.pathname
    history.push(isOpen ? `${path}#new` : path)
  }, [openCreateExtrasSidebar, history])

  const value = useMemo(
    () => ({
      areExtrasLoading,
      extras,
      selectedExtra,
      setSelectedExtra,
      refetchExtras,
      openCreateExtrasSidebar,
      toggleCreateExtrasSideBar,
      openDiscardDialog,
      setOpenDiscardDialog,
      discardDialogCallbacks,
      setDiscardDialogCallbacks,
    }),
    [
      areExtrasLoading,
      extras,
      selectedExtra,
      setSelectedExtra,
      refetchExtras,
      openCreateExtrasSidebar,
      toggleCreateExtrasSideBar,
      openDiscardDialog,
      setOpenDiscardDialog,
      discardDialogCallbacks,
      setDiscardDialogCallbacks,
    ]
  )

  return <ManageExtrasContext.Provider value={value}>{children}</ManageExtrasContext.Provider>
}

export const useManageExtrasContext = () => useContext(ManageExtrasContext)
