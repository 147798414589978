import React, { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@sweetspot/scramble-ds/atoms'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  ListItem,
  ListItemLeading,
  ListItemMainContainer,
  ListItemMainContent,
  ListItemMainLabel,
} from '@sweetspot/scramble-ds'
import { useAvailabilityAndPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/AvailabilityAndPricingProvider'

type DeleteDialogProps = {
  periodUuid: string
  toggleDropdown: (e: MouseEvent<HTMLButtonElement>) => void
}

const DeleteActionItem = ({ periodUuid, toggleDropdown }: DeleteDialogProps) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const { t } = useTranslation()

  const { deletePeriod: deletePeriodMutation, isDeletingPeriod } =
    useAvailabilityAndPricingContext()

  const onDeleteClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDeleteDialogOpen(true)
  }

  const deletePeriod = async (e: MouseEvent<HTMLButtonElement>) => {
    toggleDropdown(e)
    await deletePeriodMutation(periodUuid)

    setIsDeleteDialogOpen(false)
  }
  return (
    <>
      <ListItem
        onClick={onDeleteClick}
        className="text-text-dark text-content-sm h-8 min-h-8 w-full min-w-fit cursor-pointer gap-2 p-1 font-medium"
      >
        <ListItemLeading className="px-0">
          <i className="fa-regular fa-trash-can [&>svg]:h-4 [&>svg]:w-4" />
        </ListItemLeading>
        <ListItemMainContainer>
          <ListItemMainContent className="py-0">
            <ListItemMainLabel className="h-auto min-h-6 w-[73px]">
              {t('words.delete')}
            </ListItemMainLabel>
          </ListItemMainContent>
        </ListItemMainContainer>
      </ListItem>
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent variant="default">
          <DialogHeader>
            <DialogTitle>{t('sentences.deletePeriod')}</DialogTitle>
            <DialogDescription>
              {t('settings.deleteCourseTitle', { type: t('words.period').toLowerCase() })}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <DialogClose asChild>
              <Button
                variant="ghost-dark"
                type="button"
                size="large"
                disabled={isDeletingPeriod}
                onClick={() => setIsDeleteDialogOpen(false)}
              >
                {t('words.cancel')}
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button
                disabled={isDeletingPeriod}
                variant="primary"
                size="large"
                onClick={deletePeriod}
              >
                {t('words.delete')} {t('words.period').toLowerCase()}
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DeleteActionItem
