import CreateBay from './CreateBay'
import UpdateBay from './UpdateBay'
import BayDetails from './BayDetails'
import UpdateBayBulk from './UpdateBayBulk'

const BaySideBar = () => (
  <>
    <BayDetails />
    <CreateBay />
    <UpdateBay />
    <UpdateBayBulk />
  </>
)

export default BaySideBar
