import { useEffect } from 'react'
import { useResourcesStore } from './useResourcesStore'
import { useIsMobile } from '../useIsMobile'

export const useSelectedResourceSync = () => {
  const { selectedResource, setSelectedResource, resources } = useResourcesStore()
  const isMobile = useIsMobile()

  useEffect(() => {
    if (!isMobile && resources.length > 0 && !selectedResource) {
      setSelectedResource(resources[0])
    }
  }, [resources, setSelectedResource, isMobile, selectedResource])

  useEffect(() => {
    if (isMobile) {
      setSelectedResource(null)
    }
  }, [isMobile, setSelectedResource])
}
