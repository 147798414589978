import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'

import PlayerCardModal from '@sweetspot/club-portal-legacy/modals/PlayerCard'
import ExternalPlayerModal from '@sweetspot/club-portal-legacy/Partials/Dialogs/ExternalPlayerModal'

import {
  getPlayers,
  exportPlayers,
  getMemberships,
} from '@sweetspot/club-portal-legacy/store/actions'
import {
  getFirstMatchingRole,
  hasAccess,
} from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'
import {
  DataTable,
  DataTableFilters,
  Input,
  InputBase,
  InputContainer,
  InputLeadingContainer,
  ListItem,
  Section,
  SectionContent,
  SectionHeader,
  SectionTitle,
  ListItemLeading,
  ListItemMainContainer,
  ListItemMainContent,
  ListItemMainLabel,
  ListItemParagraph,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  TableFilter,
  TableSortItem,
} from '@sweetspot/scramble-ds'

import { columns } from './columns'
import { useTranslation } from 'react-i18next'
import { Button, Popover, Tooltip } from '@sweetspot/scramble-ds/atoms'
import { mapPlayersData } from './mappers'
import { useDebounce } from '@sweetspot/sweetspot-js/common/hooks/useDebounce'
import { formatFilters, getExportFilterQuery } from './utils'
import { GolfClubState, RootState } from '@sweetspot/club-portal-legacy/store/types'
import { FilterType, GetQueryParams, TablePlayer } from './types'
import { Player } from '@sweetspot/shared/types'
import { cn } from '@sweetspot/scramble-ds/utils'

const Players = () => {
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [filters, setFilters] = useState<TableFilter[]>([])
  const [totalItems, setTotalItems] = useState(1)
  const [searchString, setSearchString] = useState('')
  const [pageSize, setPageSize] = useState(50)
  const [isLoading, setIsLoading] = useState(false)
  const [players, setPlayers] = useState<TablePlayer[]>([])
  const [externalPlayers, setExternalPlayers] = useState<TablePlayer[]>([])
  const [showExternalPlayerModal, setShowExternalPlayerModal] = useState(false)
  const [sortItems, setSortItems] = useState<TableSortItem[]>([])
  const [showExportSuccessModal, setShowExportSuccessModal] = useState(false)
  const golfClubs = useSelector((state: RootState) => state?.golfClub)
  const memberships = useSelector((state: RootState) => state?.membership.list)
  const federationMemberships = useSelector(
    (state: RootState) => state?.membership.federationMemberships
  )

  const calculateHasCdhNumber = (golfClubs: GolfClubState, selectedId: number) => {
    const currentGolfClub = golfClubs.list.find((club) => club.id === selectedId)
    return !!currentGolfClub?.cdh_id
  }

  const debouncedSearchString = useDebounce(searchString, 500)
  const hasCdhNumber = calculateHasCdhNumber(golfClubs, golfClubs.selectedId)
  const role = useSelector((state: RootState) => getFirstMatchingRole(state.auth.roles))
  const clubId = useSelector((state: RootState) => state?.golfClub?.selectedId)
  const history = useHistory()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const location = useLocation() as { state: { shouldReload?: boolean } }

  const formattedFilters = useMemo(
    () => formatFilters(memberships, federationMemberships, hasCdhNumber),
    [memberships, federationMemberships, hasCdhNumber]
  )
  useEffect(() => {
    setFilters(formattedFilters)
  }, [formattedFilters])

  useEffect(() => {
    const fetchData = async () => {
      if (memberships?.length) {
        fetchPlayers({ searchQuery: debouncedSearchString, page: currentPage || 0, pageSize })
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberships?.length, activeFilters, currentPage, pageSize, debouncedSearchString, sortItems])

  useEffect(() => {
    const fetchMemberships = async () => {
      await dispatch(getMemberships(`?limit=300&isActive[]=true&club.id=${clubId}`))
    }
    fetchMemberships()
  }, [])

  useEffect(() => {
    if (location?.state?.shouldReload) {
      fetchPlayers({ page: currentPage, searchQuery: debouncedSearchString, pageSize })
    }
  }, [location])

  useEffect(() => setShowExternalPlayerModal(false), [location?.pathname])

  const fetchPlayers = ({ page = currentPage, searchQuery = '', pageSize = 50 } = {}) => {
    fetchPlayersData(getQueryString({ page, searchQuery, pageSize }))
  }

  const fetchExternalPlayers = ({ page = currentPage, searchQuery = '' } = {}) => {
    fetchPlayersData(getExternalQueryString({ page, searchQuery }, clubId))
  }

  const fetchPlayersData = async (queryString: string) => {
    setIsLoading(true)
    try {
      const response = (await dispatch(getPlayers('', queryString))) as unknown as {
        ['hydra:totalItems']: number
        ['hydra:member']: Player[]
      }

      const { 'hydra:totalItems': totalItems, 'hydra:member': playersData } = response

      const playerList = playersData.length ? mapPlayersData(playersData, i18n, golfClubs) : []

      showExternalPlayerModal ? setExternalPlayers(playerList) : setPlayers(playerList)
      setTotalItems(totalItems)
    } catch (error) {
      console.error('Error fetching players:', error)
      setPlayers([])
      setTotalItems(1)
    } finally {
      setIsLoading(false)
    }
  }

  const handleExportPlayers = async () => {
    try {
      const filterQuery = getExportFilterQuery(clubId, searchString, activeFilters)
      await dispatch(exportPlayers(golfClubs.selectedId, filterQuery))
      setShowExportSuccessModal(true)
    } catch (error) {
      console.error(error)
    }
  }

  const getQueryString = ({ page, searchQuery, pageSize }: GetQueryParams) => {
    const params = new URLSearchParams({
      relatedGolfClub: String(clubId),
      page: String(page + 1),
      limit: String(pageSize),
    })

    if (activeFilters?.length) applyFilters(params)
    applySorting(params)
    if (searchQuery) params.append('search', searchQuery)
    return `?${params.toString()}`
  }

  const getExternalQueryString = ({ page, searchQuery }: GetQueryParams, clubId: number) =>
    `?${new URLSearchParams({
      page: String(page),
      search: searchQuery ?? '',
      externalPlayer: String(clubId),
    })}`

  const applyFilters = (params: URLSearchParams) => {
    activeFilters.forEach((filter) => {
      const filterMap: { [key in FilterType]: [string, number] } = {
        MEMBER: ['type[members]', clubId],
        GUEST: ['type[guests]', clubId],
        PAID: ['membershipPaid[paid]', clubId],
        UNPAID: ['membershipPaid[not_paid]', clubId],
        GIT_MEMBERS: ['gitOnlyMembership', clubId],
      }

      if (filterMap[filter]) {
        params.append(filterMap[filter][0], filterMap[filter][1].toString())
      } else if (filter.name === 'FEDERATION_MEMBERSHIP') {
        params.append('federationMembership[]', filter?.value)
      } else {
        if (!params.has('membership[golf_club_id]')) {
          params.append('membership[golf_club_id]', clubId.toString())
        }
        params.append('membership[]', filter)
      }
    })
  }

  const applySorting = (params: URLSearchParams) => {
    sortItems.forEach(({ name, type }) => {
      if (type) {
        const sortKey = name === 'homeClub' ? 'homeClub.name' : name === 'name' ? 'fullName' : name
        params.append(`order[${sortKey}]`, type)
      }
    })
  }

  const handleRowClick = (player: Player | string | number) => {
    const playerId = typeof player === 'object' && player !== null ? player.id : player
    history.push(`players/${playerId}`)
  }
  const onFilterChange = (filter: string) => {
    setCurrentPage(0)
    if (!filter) {
      setActiveFilters([])
      return
    }

    setActiveFilters((prevFilters: FilterType[]) => {
      const updatedFilters = prevFilters.includes(filter as FilterType)
        ? prevFilters.filter((item) => item !== (filter as FilterType))
        : [...prevFilters, filter as FilterType]

      return updatedFilters
    })
  }

  const toggleExternalPlayerModal = () => {
    const modalOpen = showExternalPlayerModal
    setShowExternalPlayerModal(!modalOpen)
    setExternalPlayers([])
  }

  return (
    <Section className="overflow-x-hidden">
      <SectionHeader>
        <SectionTitle>{t('playersText')}</SectionTitle>
      </SectionHeader>
      <SectionContent>
        <Tooltip.Provider>
          <DataTable
            columns={columns(t, hasCdhNumber, sortItems, setSortItems)}
            sortItems={sortItems}
            data={players}
            defaultColumnVisibility={{
              playRight: false,
              numberOfRounds: false,
              playValue: false,
              paidValue: false,
              lastPlayed: false,
            }}
            onRowClick={handleRowClick}
            inputSearch={() => (
              <div className="flex w-2/5 flex-row">
                <InputBase className="flex w-full flex-row gap-2 px-4 py-6">
                  <InputContainer>
                    <InputLeadingContainer>
                      <i className="fa-regular fa-magnifying-glass" />
                    </InputLeadingContainer>
                    <Input
                      rounding="pill"
                      className="h-10 px-10"
                      placeholder={t('players.searchPlayer')}
                      value={searchString}
                      onChange={(e) => setSearchString(e.target.value)}
                    />
                  </InputContainer>
                </InputBase>
                <div className="flex w-[20px] items-center justify-center ">
                  <i
                    className={cn(
                      'fa-regular fa-loader animate-[spin_3s_linear_infinite] text-xl opacity-0',
                      {
                        'opacity-100': isLoading,
                      }
                    )}
                  />
                </div>
              </div>
            )}
            extraControls={(table) => (
              <>
                <DataTableFilters
                  table={table}
                  filters={filters}
                  onFilterChange={onFilterChange}
                  activeFilters={activeFilters}
                />
                {hasAccess(ACCESS_KEYS.PAGES.PLAYERS.EXPORT, role?.value) && (
                  <Popover.Root>
                    <Popover.Trigger asChild>
                      <div className="cursor-pointer px-4">
                        <i className="fa-regular fa-ellipsis text-base" />
                      </div>
                    </Popover.Trigger>
                    <Popover.Content className="p-2 drop-shadow" align="end" side="bottom">
                      <ListItem
                        className="text-text-dark w-full min-w-fit cursor-pointer gap-5 px-2 py-2"
                        onClick={() => handleExportPlayers()}
                      >
                        {/*Players export*/}
                        <ListItemLeading className="px-0">
                          <i className="fa-regular fa-inbox-out" />
                        </ListItemLeading>
                        <ListItemMainContainer>
                          <ListItemMainContent className="py-0">
                            <ListItemMainLabel className="h-auto min-h-6 font-bold">
                              {t('players.export')}
                            </ListItemMainLabel>
                            <ListItemParagraph className="text-text-subtle">
                              {t('Export to CSV file to an email')}
                            </ListItemParagraph>
                          </ListItemMainContent>
                        </ListItemMainContainer>
                      </ListItem>

                      {/*Add external player*/}
                      <ListItem
                        className="text-text-dark w-full min-w-fit cursor-pointer gap-5 px-2 py-3"
                        onClick={() => toggleExternalPlayerModal()}
                      >
                        <ListItemLeading className="px-0">
                          <i className="fa-regular fa-user-plus" />
                        </ListItemLeading>
                        <ListItemMainContainer>
                          <ListItemMainContent className="py-0">
                            <ListItemMainLabel className="h-auto min-h-6 font-bold">
                              {t('players.addExternalPlayer')}
                            </ListItemMainLabel>
                          </ListItemMainContent>
                        </ListItemMainContainer>
                      </ListItem>
                      <Popover.Arrow className="fill-white" width={20} height={10} />
                    </Popover.Content>
                  </Popover.Root>
                )}
              </>
            )}
            pagination={{
              currentPage,
              pageSize,
              totalRows: totalItems,
              onPageChange: (pageIndex: number) => setCurrentPage(pageIndex),
              onPageSizeChange: (size: number) => {
                setCurrentPage(0)
                setPageSize(size)
              },
            }}
          />
        </Tooltip.Provider>
        <Dialog
          open={showExportSuccessModal}
          onOpenChange={(open) => {
            if (!open) {
              setShowExportSuccessModal(false)
            }
          }}
        >
          <DialogContent variant="bottom">
            <DialogHeader>
              <DialogDescription>
                <span className="text-lg font-bold">{totalItems}</span> {t('players.exportSuccess')}
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <DialogClose asChild>
                <Button variant="primary" size="large">
                  {t('words.close')}
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        {showExternalPlayerModal && (
          <ExternalPlayerModal
            lang={i18n.language}
            onClose={toggleExternalPlayerModal}
            onRowClick={handleRowClick}
            onSearch={fetchExternalPlayers}
            isLoading={isLoading}
            players={externalPlayers}
            hasCdhNumber={hasCdhNumber}
          />
        )}
      </SectionContent>
      <Switch>
        <Route exact path="/players/:id" component={PlayerCardModal} />
      </Switch>
    </Section>
  )
}

export default Players
