import { useState, useEffect, useCallback } from 'react'

/**
 * @description Breakpoints for device types, based on the design system which is based on tailwind breakpoints
 * https://v3.tailwindcss.com/docs/responsive-design
 */
export enum DeviceBreakpoints {
  'xs' = 320,
  'sm' = 640,
  'md' = 768,
  'lg' = 1024,
  'xl' = 1280,
  '2xl' = 1536,
  '3xl' = 1920,
}

export enum DeviceType {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  LAPTOP = 'laptop',
  DESKTOP = 'desktop',
}

export const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState<DeviceType>(DeviceType.LAPTOP)
  const [deviceWidth, setDeviceWidth] = useState<number>(window.innerWidth)
  const { userAgent } = window.navigator

  /**
   * @description Set the device type based on the current window width
   */
  useEffect(() => {
    const onScreenSizeChange = () => {
      let baseDeviceType = DeviceType.MOBILE

      if (window.innerWidth >= DeviceBreakpoints.md) baseDeviceType = DeviceType.TABLET
      if (window.innerWidth >= DeviceBreakpoints.lg) baseDeviceType = DeviceType.LAPTOP
      /** Disabled for now, breaks some implementations */
      // if (window.innerWidth >= DeviceBreakpoints['2xl']) baseDeviceType = DeviceType.DESKTOP
      setDeviceType(baseDeviceType)
      setDeviceWidth(window.innerWidth)
    }
    onScreenSizeChange()

    window.addEventListener('resize', onScreenSizeChange)

    return () => {
      window.removeEventListener('resize', onScreenSizeChange)
    }
  }, [userAgent])

  /**
   * @description Check if the current window width is higher than the breakpoint
   * @param breakpoint - The breakpoint to check against
   * @returns boolean
   */
  const isWiderThanBreakpoint = useCallback(
    (breakpoint: DeviceBreakpoints) => {
      return deviceWidth >= breakpoint
    },
    [deviceWidth]
  )

  /**
   * @description Check if the current window width is smaller than the breakpoint
   * @param breakpoint - The breakpoint to check against
   * @returns boolean
   */
  const isSmallerThanBreakpoint = useCallback(
    (breakpoint: DeviceBreakpoints) => {
      return deviceWidth < breakpoint
    },
    [deviceWidth]
  )

  return {
    deviceType,
    isWiderThanBreakpoint,
    isSmallerThanBreakpoint,
  }
}
