import { useTranslation } from 'react-i18next'

import { GolfCourseTypes } from '@sweetspot/shared/types'

type EmptyVenueTextProps = {
  courseType: GolfCourseTypes
  currentClubName: string
}

export const EmptyVenueText = ({ courseType, currentClubName }: EmptyVenueTextProps) => {
  const { t } = useTranslation()
  switch (courseType) {
    case GolfCourseTypes.SIMULATOR:
      return t('settings.createAtLeastOneCourse', {
        name: currentClubName,
        type: t('words.simulator_one').toLowerCase(),
      })
    case GolfCourseTypes.DRIVING_RANGE:
      return t('settings.createAtLeastOneCourse', {
        name: currentClubName,
        type: t('words.range_one').toLowerCase(),
      })
    case GolfCourseTypes.PRO:
      return t('settings.createAtLeastOneCourse', {
        name: currentClubName,
        type: t('words.academy').toLowerCase(),
      })
    default:
      return t('sentences.noOtherDefaultPage')
  }
}
