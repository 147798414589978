import { request } from '@sweetspot/shared/data-access/api-platform'
import { getApiPlatformBaseUrl } from '@sweetspot/shared/util/functions'
import {
  AvailabilityPayload,
  AvailableSpaces,
  CalculatePricePayload,
  CalculatePriceResponse,
  ExtraPlayersPayload,
  MovePayload,
  type WebTeeSheetBooking,
} from './types'
import { apiPlatformClient } from '@sweetspot/shared/data-access/api-platform'
import isString from 'lodash/isString'

export const getMoveAvailability = (
  bookingId: string,
  payload: AvailabilityPayload
): Promise<AvailableSpaces> => {
  const URL = `${getApiPlatformBaseUrl()}/bookings/${bookingId}/move/availability?date=${
    payload.date
  }&time=${payload.time}`

  return request(URL)
}

export const moveBooking = (bookingId: string, payload: MovePayload): Promise<void> => {
  const URL = `${getApiPlatformBaseUrl()}/bookings/${bookingId}/move/spaces`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

/**
 * Makes a simulated price calculation for a booking (does not update the actual booking data)
 * @param bookingUuid
 * @param @see {CalculatePricePayload}
 * @returns @see {CalculatePriceResponse}
 */
export const calculatePrice = (
  bookingUuid: string,
  payload: CalculatePricePayload
): Promise<CalculatePriceResponse> => {
  const URL = `${getApiPlatformBaseUrl()}/bookings/${bookingUuid}/calculate-price`

  return request(URL, {
    method: 'POST',
    body: payload,
  })
}

/**
 * Makes a simulated price calculation for a booking (does not update the actual booking data)
 * @param bookingUuid
 * @param @see {CalculatePricePayload}
 * @returns @see {CalculatePriceResponse}
 */
export const setNumberOfExtraPlayers = (
  bookingUuid: string,
  payload: ExtraPlayersPayload
): Promise<void> => {
  const URL = `${getApiPlatformBaseUrl()}/bookings/${bookingUuid}/players`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

export const getBookingsForWebTeeSheet = async (options: {
  courseUuid: string
  customerUuid?: string
  startDate: string | Date
  endDate: string | Date
}): Promise<WebTeeSheetBooking[]> => {
  const { courseUuid, startDate, endDate, customerUuid } = options

  const formattedStartDate = isString(startDate) ? startDate : startDate.toISOString()
  const formattedEndDate = isString(endDate) ? endDate : endDate.toISOString()

  const { data, error } = await apiPlatformClient().GET('/api/tee-sheet/web/bookings', {
    params: {
      query: {
        // @ts-expect-error-next-line
        'course.uuid': courseUuid,
        'booking.start_time[after]': formattedStartDate,
        'booking.start_time[before]': formattedEndDate,
        ...(customerUuid ? { 'customer.uuid': customerUuid } : {}),
      },
    },
  })

  if (error) {
    throw new Error(error)
  }
  if (!data) {
    throw new Error('No data returned')
  }

  // Temporary serialization
  const newData = data.map((booking) => ({
    uuid: booking.uuid,
    // @ts-expect-error-next-line
    customer: booking.customer,
    // @ts-expect-error-next-line
    ...booking.booking,
  }))

  return newData
}
