import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import {
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { cn } from '@sweetspot/scramble-ds/utils'
import { Button, Divider } from '@sweetspot/scramble-ds/atoms'
import { useBallPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/BallPricing/BallPricingProvider'
import SpinnerLoader from '@sweetspot/club-portal-legacy/components/SpinnerLoader/SpinnerLoader'

import OpeningHours from './OpeningHours/OpeningHours'
import BucketSizes from './BucketSizes/BucketSizes'
import BallPrice from './BallPrice/BallPrice'
import PriceRounding from './PriceRounding/PriceRounding'

type BucketConfigurationProps = {
  open: boolean
  onOpenChange: (forceClose?: boolean) => void
}
const BucketConfiguration = ({ open, onOpenChange }: BucketConfigurationProps) => {
  const { t } = useTranslation()
  const {
    selectedRange,
    bucketConfiguration,
    demandModel: { refetchRangeSchedule, refetchDemandModel },
  } = useBallPricingContext()
  const { onSave, isFetching, isUpdating } = bucketConfiguration

  const isLoading = useMemo(() => isFetching || isUpdating, [isFetching, isUpdating])
  const loaderText = useMemo(
    () => (isFetching ? t('sentences.fetchingPrices') : t('sentences.updatingPrices')),
    [isFetching, t]
  )
  const loaderDescription = useMemo(
    () =>
      isFetching
        ? t('sentences.fetchingPricesDescription')
        : t('sentences.updatingPricesDescription'),
    [isFetching, t]
  )

  const handleOnSave = useCallback(async () => {
    onSave?.(() => {
      onOpenChange(true)
      refetchRangeSchedule?.()
      if (selectedRange?.demand_model_id) {
        refetchDemandModel?.()
      }
    })
  }, [
    onSave,
    onOpenChange,
    refetchRangeSchedule,
    refetchDemandModel,
    selectedRange?.demand_model_id,
  ])

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        className={cn('bg-background-base-neutral !max-w-[360px]', {
          'overflow-hidden': isLoading,
        })}
      >
        {isLoading && (
          <SpinnerLoader
            className="fixed left-[calc(100%-360px)]"
            text={loaderText}
            description={loaderDescription}
            isOverlay
            button={{
              children: t('words.cancel'),
              onClick: () => onOpenChange(),
              variant: 'inverted',
            }}
          />
        )}
        <SheetHeader className={cx({ 'blur-sm': isLoading })}>
          <SheetHeaderLeftIcon onClick={() => onOpenChange()}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>{selectedRange?.name}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent className={cx('flex flex-col gap-4 pb-0', { 'blur-sm': isLoading })}>
          <OpeningHours />
          <Divider className="mb-2" />
          <BucketSizes />
          <Divider className="mb-2" />
          <BallPrice />
          <Divider className="mb-2" />
          <PriceRounding />
        </SheetCustomContent>
        <SheetFooter className={cx({ 'blur-sm': isLoading })}>
          <Button onClick={handleOnSave} className="w-full">
            {t('sentences.updatePricingRules')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

export default BucketConfiguration
