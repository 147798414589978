import { formatInTimeZone } from 'date-fns-tz'
import {
  ListItem,
  ListItemMainContainer,
  ListItemMainContent,
  ListItemMainLabel,
  ListItemParagraph,
  ListItemTrailing,
} from '@sweetspot/scramble-ds'
import { Tag } from '@sweetspot/scramble-ds/atoms'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { Resource } from '../../../../types'
import { useResourcesStore } from '../../../../hooks/resources/useResourcesStore'
import { useIsMobile } from '../../../../hooks/useIsMobile'

type ResourcesListProps = {
  handleResourceSelect: (resource: Resource) => void
  selectedResource: Resource | null
}

type SelectedListItemTrailingComponentProps = {
  isSelected: boolean
  isNew?: boolean
  isMobile: boolean
}

const SelectedListItemTrailingComponent = ({
  isSelected,
  isNew = false,
  isMobile,
}: SelectedListItemTrailingComponentProps) => {
  if (isMobile || isSelected) {
    if (isNew) {
      return <Tag.Root>New</Tag.Root>
    }

    return <FontAwesomeIcon icon={faChevronRight} />
  }

  return null
}

const ResourcesList = ({ handleResourceSelect, selectedResource }: ResourcesListProps) => {
  const { resources, timezone } = useResourcesStore()
  const isMobile = useIsMobile()

  return (
    <div className="flex-1">
      {resources.map((resource) => {
        const isSelected = selectedResource?.resourceId === resource.resourceId

        return (
          <ListItem
            key={`resource-${resource.resourceId}`}
            onClick={() => handleResourceSelect(resource)}
            className="w-full cursor-pointer px-6 md:px-0"
          >
            <ListItemMainContainer
              showDivider
              className={`${
                isSelected ? 'md:[&>div]:border-r-text-subtle md:[&>div]:border-r-4 ' : ''
              } md:[&>div]:px-6`}
            >
              <ListItemMainContent className="md:py-2">
                <ListItemMainLabel className="overflow-hidden text-ellipsis whitespace-nowrap">
                  {resource.name}
                </ListItemMainLabel>
                <ListItemParagraph className="text-text-subtle overflow-hidden text-ellipsis whitespace-nowrap">
                  {formatInTimeZone(resource.createdAt, timezone, 'EEEE, MMMM d yyyy')}
                </ListItemParagraph>
              </ListItemMainContent>
              <ListItemTrailing className="px-2 py-1">
                <SelectedListItemTrailingComponent
                  isSelected={isSelected}
                  isNew={resource.isNew}
                  isMobile={isMobile}
                />
              </ListItemTrailing>
            </ListItemMainContainer>
          </ListItem>
        )
      })}
    </div>
  )
}

export default ResourcesList
