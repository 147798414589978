import { useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'

import Grid from '@sweetspot/club-portal-legacy/components/Grid'
import { HEADERS } from './headers'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import { useMembershipSignups } from '@sweetspot/club-portal/feature/memberships'
import MembershipSignupsModal from '@sweetspot/club-portal-legacy/modals/MembershipSignupsModal'
import { GridFilter } from '@sweetspot/club-portal-legacy/components/Grid/types'
import { updateMemebershipSignupCompletion } from '@sweetspot/shared/data-access/api-platform'

export interface MembershipSignupsGridProps {
  pageLimit?: number
}

enum Filter {
  TODO = 'TODO',
  DONE = 'DONE',
}

const filters: GridFilter[] = [
  {
    name: '.status',
    options: [
      { name: '.words.toDo', filter: Filter.TODO },
      { name: '.words.done', filter: Filter.DONE },
    ],
  },
]

export const MembershipSignupsGrid: React.FC<MembershipSignupsGridProps> = ({ pageLimit = 25 }) => {
  const history = useHistory()
  const match = useRouteMatch()
  const { addToast } = useToasts()
  const { t } = useTranslation()
  /* Use mutually exclusive filters. Only one can be active at a time.
   * Radios would suit better, but we use checkboxes everywhere. */
  const [activeFilter, setActiveFilter] = useState<Filter | null>(null)
  const { path } = match
  const [currentPage, setCurrentPage] = useState(1)

  const isDone = useMemo(() => {
    if (!activeFilter || activeFilter === Filter.TODO) {
      return
    }

    return Number(activeFilter === Filter.DONE)
  }, [activeFilter])

  const { data, isFetching } = useMembershipSignups({
    query: { page: currentPage, limit: pageLimit, isDone },
    queryOptions: {
      keepPreviousData: true,
      retry: false,
    },
  })

  const { mutate: updateMemebershipSignupCompletionMutation } = useMutation(
    ({ uuid }: { uuid: string }) => updateMemebershipSignupCompletion(uuid)
  )
  const toggleIsDone = (uuid: string) => {
    updateMemebershipSignupCompletionMutation(
      {
        uuid,
      },
      {
        onSuccess: async () => {
          addToast(t('toast.membershipUpdated'), { appearance: 'success' })
        },
        onError: () => {
          addToast(t('toast.membershipUpdatedError'), { appearance: 'error' })
        },
      }
    )
  }

  const gridData = useMemo(() => {
    const result = []
    const dataForGrid = data?.['hydra:member'] || []
    console.log('data', dataForGrid)
    dataForGrid.forEach((item) => {
      const row = {
        id: item.id,
        name: item.name,
        email: item.email,
        birthDate: item.date_of_birth,
        membershipName: item.membership,
        signUpDate: item.signup_date,
        gitClub: item.git_club,
        isDone: {
          checked: item?.is_done,
          onChange: (value) => {
            toggleIsDone(item.id, value)
          },
        },
      }
      result.push(row)
    })
    return result
  }, [data])

  const totalPages = useMemo(() => {
    const { 'hydra:member': members, 'hydra:totalItems': totalItems } = data ?? {}
    if (members?.length && totalItems) {
      return Math.ceil(totalItems / members?.length)
    }
    return 0
  }, [data])

  const rowsPerPage = useMemo(() => {
    const { 'hydra:member': members, 'hydra:totalItems': totalItems } = data ?? {}
    if (members?.length && totalItems) {
      return members?.length > totalItems ? totalItems : members?.length
    }
    return 0
  }, [data])

  const handleRowClick = (row) => {
    history.push(`${path}/${row.id}`)
  }

  return (
    <div className="relative grid h-full gap-8 p-[10px] text-base">
      <Grid
        filtersEnabled
        activeFilters={[activeFilter]}
        handleFilters={setActiveFilter}
        filters={filters}
        filtersTitle={'words.filter_one'}
        allowFunctionValues
        values={gridData}
        pageTitle={'Pending membership signups'}
        headers={HEADERS}
        isLoading={isFetching}
        pagination
        showHandleData
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        onRowClick={handleRowClick}
        totalRows={data?.['hydra:totalItems'] ?? 0}
        displayRangeOfRows
        setCurrentPage={(val: number) => setCurrentPage(val)}
      />
      <Route path={`${path}/:id`} component={MembershipSignupsModal} />
    </div>
  )
}
