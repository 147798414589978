import { useState, useMemo, useCallback, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Input,
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputLeadingContainer,
  InputMultiSelectInput,
  InputSelect,
  InputSelectSearchableContent,
  InputSelectSearchableItem,
  InputSelectSearchableTrigger,
  InputSelectTrailingContainer,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  ListItemTrailing,
} from '@sweetspot/scramble-ds'
import { Check, Divider, Tag } from '@sweetspot/scramble-ds/atoms'
import { capitalize } from '@sweetspot/sweetspot-js/common/functions/utils'
import { useAvailabilityAndPricingContext } from '../../AvailabilityAndPricingProvider'

type BaysSelectProps = {
  selectedBayIds: string[]
  setSelectedBayIds: Dispatch<SetStateAction<string[]>>
}

const BaysSelect = ({ selectedBayIds, setSelectedBayIds }: BaysSelectProps) => {
  const [search, setSearch] = useState('')
  const { t } = useTranslation()
  const { bays, hasPricings } = useAvailabilityAndPricingContext()

  const bayOptions = useMemo(
    () =>
      bays.map((bay) => ({
        value: bay.id,
        label: capitalize(`${t('words.bay_one')} ${bay?.bay_number}`),
      })),
    [bays, t]
  )

  const handleRemoveSelectedBay = useCallback(
    (value: string) => {
      setSelectedBayIds((prev: string[]) => prev.filter((bayId) => bayId !== value))
    },
    [setSelectedBayIds]
  )

  const handleBayCheckChange = useCallback(
    (checked: string | boolean, bayId: string) => {
      if (checked) {
        setSelectedBayIds((prev: string[]) => [...prev, bayId])
      } else {
        handleRemoveSelectedBay(bayId)
      }
    },
    [handleRemoveSelectedBay, setSelectedBayIds]
  )

  const baysToRender = useMemo(() => {
    const normalizedSearch = search.toLowerCase().replace(/\s+/g, '')

    return bayOptions.filter((bay) => {
      const normalizedLabel = bay.label.toLowerCase().replace(/\s+/g, '')

      return normalizedLabel.includes(normalizedSearch)
    })
  }, [search, bayOptions])

  const handleSelectAllBaysCheckboxChange = useCallback(
    (checked: string | boolean) => {
      if (checked) {
        setSelectedBayIds(bayOptions.map((bay) => bay.value))
      } else {
        setSelectedBayIds([])
      }
    },
    [bayOptions, setSelectedBayIds]
  )

  const areAllBaysSelected = useMemo(
    () => selectedBayIds.length === bayOptions.length,
    [selectedBayIds.length, bayOptions.length]
  )

  const canRemoveSelectedBay = useMemo(() => selectedBayIds?.length > 1, [selectedBayIds?.length])

  return (
    <InputSelect withSearch>
      <InputBase disabled={!hasPricings}>
        <InputLabelContainer>{capitalize(t('words.bay_other'))}</InputLabelContainer>
        <InputSelectSearchableTrigger asChild>
          <InputContainer className="w-full">
            <InputMultiSelectInput
              className="z-50 min-h-8 border py-1"
              placeholder={t('words.select')}
            >
              {selectedBayIds.map((bayId) => {
                const bay = bayOptions.find((b) => b.value === bayId)
                return (
                  <Tag.Root key={bayId} size="large" variant="primary" className="gap-2 px-2">
                    <i className="fa-solid fa-coin" />
                    {bay?.label}
                    {canRemoveSelectedBay && (
                      <i
                        className="fa-solid fa-close cursor-pointer"
                        role="button"
                        aria-label="Remove tag"
                        tabIndex={0}
                        onClick={(e) => {
                          e.stopPropagation()
                          handleRemoveSelectedBay(bayId)
                        }}
                      />
                    )}
                  </Tag.Root>
                )
              })}
            </InputMultiSelectInput>
            <InputSelectTrailingContainer className="right-2" />
          </InputContainer>
        </InputSelectSearchableTrigger>
      </InputBase>
      <InputSelectSearchableContent>
        <div className="bg-background-mono-lighter">
          <InputBase className="w-full p-4">
            <InputContainer>
              <InputLeadingContainer>
                <i className="fa-regular fa-magnifying-glass" />
              </InputLeadingContainer>
              <Input
                rounding="pill"
                className="h-8 px-10"
                placeholder={t('words.search')}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputContainer>
          </InputBase>
        </div>
        <div className="flex flex-col px-4">
          <ListItem className="h-full w-full justify-between gap-2">
            <ListItemMainContent className="flex-none justify-center">
              <ListItemParagraph className="text-content-base">
                {t('sentences.allBays')}
              </ListItemParagraph>
            </ListItemMainContent>
            <ListItemTrailing onClick={(e) => e.stopPropagation()}>
              <Check
                checked={areAllBaysSelected}
                onCheckedChange={(checked) => handleSelectAllBaysCheckboxChange(checked)}
              />
            </ListItemTrailing>
          </ListItem>
          <Divider />
        </div>

        {baysToRender.map((option) => (
          <InputSelectSearchableItem
            className={'px-4'}
            key={option.value}
            value={option.value}
            onSelect={() => {
              if (!selectedBayIds.some((bayId) => bayId === option.value)) {
                handleBayCheckChange(true, option.value)
              }
            }}
          >
            <ListItem className="text-content-base font-regular h-full w-full justify-between gap-2">
              <ListItemMainContent className="flex-none justify-center">
                <ListItemParagraph className="text-content-base">{option?.label}</ListItemParagraph>
              </ListItemMainContent>
              <ListItemTrailing onClick={(e) => e.stopPropagation()}>
                <Check
                  checked={selectedBayIds.some((bayId) => bayId === option.value)}
                  onCheckedChange={(checked) => handleBayCheckChange(checked, option.value)}
                />
              </ListItemTrailing>
            </ListItem>
          </InputSelectSearchableItem>
        ))}
      </InputSelectSearchableContent>
    </InputSelect>
  )
}

export default BaysSelect
