import { useTranslation } from 'react-i18next'

import { Button } from '@sweetspot/scramble-ds/atoms'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@sweetspot/scramble-ds'

type DeleteCourseDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  onDelete: () => void
  courseText: string
}

const DeleteCourseDialog = ({ open, setOpen, onDelete, courseText }: DeleteCourseDialogProps) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent variant="default">
        <DialogHeader>
          <DialogTitle>{t('settings.deleteCourseTitle', { type: courseText })}</DialogTitle>
          <DialogDescription>{t('settings.deleteCourseText')}</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="ghost-dark" type="button" size="large" onClick={() => setOpen(false)}>
              {t('words.cancel')}
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="primary" size="large" onClick={onDelete}>
              {t('words.delete')}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteCourseDialog
