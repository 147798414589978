import { MouseEvent, useState } from 'react'
import { Popover } from '@sweetspot/scramble-ds/atoms'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import DeleteActionItem from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/PeriodActionsPopover/DeleteActionItem'

type PeriodActionsPopoverProps = {
  periodUuid: string
}
const PeriodActionsPopover = ({ periodUuid }: PeriodActionsPopoverProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const toggleDropdown = (e: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsDropdownOpen(!isDropdownOpen)
  }

  return (
    <Popover.Root open={isDropdownOpen}>
      <Popover.Trigger onClick={(e) => e.preventDefault()} asChild>
        <div className="cursor-pointer" onClick={toggleDropdown}>
          <FontAwesomeIcon icon={faEllipsis} />
        </div>
      </Popover.Trigger>
      <Popover.Content
        align="end"
        className="z-50 w-fit p-2 drop-shadow"
        onInteractOutside={() => setIsDropdownOpen(false)}
      >
        <DeleteActionItem toggleDropdown={toggleDropdown} periodUuid={periodUuid} />
        <Popover.Arrow className="fill-white" width={16} height={10} />
      </Popover.Content>
    </Popover.Root>
  )
}

export default PeriodActionsPopover
