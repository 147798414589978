import PricingPeriodTabs from './PricingPeriodTabs'
import BaysSelect from './BaysSelect'

const PricingForm = () => {
  return (
    <div className="w-full pt-8">
      {/* TODO: Add BaysRadio once the bay groups feature is implemented
          <BaysRadio />
      */}
      <BaysSelect />
      <PricingPeriodTabs />
    </div>
  )
}

export default PricingForm
