import React, { useEffect, useMemo, useRef } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import styles from './styles.module.scss'
import MultiSelect from '@sweetspot/sweetspot-js/common/components/MultiSelect'

import produce from 'immer'
import { useInfiniteQuery, useQueries } from 'react-query'
import { CLUB_QUERIES, QUERY_KEYS } from '@sweetspot/shared/util/constants'

import { queryRanges } from '@sweetspot/sweetspot-js/features/ranges/services'
import { queryBallDispensers } from '@sweetspot/shared/data-access/api-platform'

const formatOptions = (array) => {
  return array.map((range) => ({
    ...range,
    uuid: range.id,
    label: range.name,
  }))
}

const BallDispenserMini = ({ ballDispensers, header }) => {
  const dispenserNames = ballDispensers.map((dispenser) => dispenser.name).join(', ')

  return (
    <div className={styles.miniContainer}>
      <p className={styles.miniHeader}>{header}</p>
      <div className={styles.miniRow}>
        <p>{dispenserNames}</p>
      </div>
    </div>
  )
}

const BallDispensersRule = ({
  rule,
  onSetRule,
  onSaveRule,
  disabled,
  loading,
  golfClubID,
  voucherDisabled,
  isFromAction,
  isMini,
}) => {
  const { t } = useTranslation()

  const isMounted = useRef(false)
  const club = useSelector((state) => {
    return state?.golfClub?.list?.find?.((club) => club.id === state?.golfClub?.selectedId)
  })

  useEffect(() => {
    isMounted.current = true
    return () => (isMounted.current = false)
  }, [])

  const clubId = useMemo(() => {
    return golfClubID || club?.id
  }, [golfClubID, club])

  const {
    data: ranges,
    fetchNextPage: loadMoreRanges,
    isFetching: isFetchingRanges,
    isFetchingNextPage: isFetchingNextPageOfRanges,
    status: rangesStatus,
  } = useInfiniteQuery(
    [QUERY_KEYS.RANGES, { clubId, page: 1, limit: 50 }],
    ({ pageParam = 1 }) =>
      queryRanges({
        organizationId: club?.uuid,
        page: pageParam,
        limit: 50,
      }),
    {
      enabled: !!club?.uuid,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.ranges?.length < 50) return undefined
        return pages.length + 1
      },
      select: (data) => {
        return formatOptions(data.pages.flatMap((page) => page?.ranges || []))
      },
    }
  )

  const rawBallDispensers = useQueries(
    ranges?.map((range) => ({
      queryKey: [CLUB_QUERIES.BALL_DISPENSERS, { club: clubId, range: range?.id }],
      queryFn: async () => {
        let data = await queryBallDispensers(range?.id)
        return data?.range?.dispensers || []
      },
      enabled: !!clubId,
    })) || []
  )

  const ballDispensers = formatOptions(
    rawBallDispensers
      ?.map((query) => query?.data || [])
      ?.flat()
      ?.filter((dispenser) => dispenser && Object.keys(dispenser).length > 0)
  )

  const selectedDispensers = ballDispensers?.filter((option) =>
    isFromAction
      ? rule?.configuration?.filter?.dispense_filter?.includes(option.id)
      : rule?.configuration?.range_dispenser?.includes(option.id)
  )

  if (isMini) {
    return (
      <BallDispenserMini
        ballDispensers={selectedDispensers}
        header={t('sentences.ballDispensers')}
      />
    )
  }

  return (
    <div className={cx(styles.container)}>
      <MultiSelect
        noSelectedLabel={
          isFetchingRanges
            ? t('sentences.loadingBallDispencers')
            : t('sentences.noBallDispencersSelected')
        }
        width="full"
        buttonLabel={t('sentences.ballDispensers')}
        options={ballDispensers}
        selectedOptions={selectedDispensers}
        handleChangeSelected={(newSelected) => {
          const updatedRule = produce(rule, (draft) => {
            if (isFromAction) {
              draft.configuration.filter.dispense_filter = newSelected.map((x) => x.id)
            } else {
              draft.configuration.range_dispenser = newSelected.map((x) => x.id)
            }
          })

          onSetRule(updatedRule)
          onSaveRule(updatedRule)
        }}
        dropdownProps={{
          loading: rangesStatus === 'loading' || isFetchingRanges || isFetchingNextPageOfRanges,
          loadMorePages: () => loadMoreRanges(),
        }}
        disabled={disabled}
        loading={loading || isFetchingRanges}
        readOnly={voucherDisabled}
        voucherDisabled={voucherDisabled}
      />
    </div>
  )
}

BallDispensersRule.propTypes = {
  rule: PropTypes.object,
  onSetRule: PropTypes.func,
  onSaveRule: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
}

BallDispensersRule.defaultProps = {
  loading: false,
}

export default BallDispensersRule
