import Header from './Header'
import CreateCourseSideBar from './CreateCourseSideBar/CreateCourseSideBar'
import { ManageCourseProvider } from './ManageCourseProvider'
import FilterToolbar from './FilterToolbar/FilterToolbar'
import CourseTabsContainer from './CourseTabsContainer/CourseTabsContainer'

const ManageCourses = () => {
  return (
    <ManageCourseProvider>
      <CreateCourseSideBar />
      <div className="flex h-full flex-col">
        <Header />
        <FilterToolbar />
        <div className={'flex flex-1 items-center justify-center'}>
          <CourseTabsContainer />
        </div>
      </div>
    </ManageCourseProvider>
  )
}

export default ManageCourses
