import { useCallback, useState, useMemo, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'

import { useSelector } from 'react-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { Period, ViolationError } from '@sweetspot/shared/types'
import { queryPeriods } from '@sweetspot/sweetspot-js/features/period/services'
import { GolfCourseItem } from '@sweetspot/club-portal-legacy/store/types'
import { _deleteBookingPeriod } from '@sweetspot/club-portal-legacy/services/bookingPeriodApi'
import { isWithinInterval, parseISO } from 'date-fns'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'

type UsePeriodsProps = {
  currentCourse: GolfCourseItem | null
}

const usePeriods = ({ currentCourse }: UsePeriodsProps) => {
  const [selectedPeriod, setSelectedPeriod] = useState<Period | null>(null)
  const { lang } = useSelector((state: RootState) => state.auth.me)
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const {
    data,
    isLoading: arePeriodsLoading,
    isFetching: arePeriodsFetching,
    refetch: refetchPeriods,
  } = useQuery(
    [
      CLUB_QUERIES.PERIODS,
      {
        courseId: currentCourse?.id,
      },
    ],
    () =>
      queryPeriods({
        courseId: currentCourse?.id as number,
        withIsFrozen: true,
      }),
    {
      enabled: !!currentCourse?.id,
      onSuccess: (data) => {
        autoSelectPeriod(data?.data)
      },
    }
  )

  const periods = useMemo(() => data?.data || [], [data?.data])

  const autoSelectPeriod = useCallback(
    (newPeriods?: Period[]) => {
      const now = new Date()
      const _periods = newPeriods || periods

      const defaultPeriod = _periods.find((period) => period.id === selectedPeriod?.id)

      // Find the current period where now is between start_date and end_date
      const currentPeriod =
        _periods.find((period) => {
          const startDate = parseISO(period.start_date)
          const endDate = parseISO(period.end_date)
          return isWithinInterval(now, { start: startDate, end: endDate })
        }) || _periods[0]

      setSelectedPeriod(defaultPeriod || currentPeriod)
    },
    [periods, setSelectedPeriod, selectedPeriod]
  )

  useEffect(() => {
    const defaultPeriod = periods.find((period) => period.id === selectedPeriod?.id)
    if (!defaultPeriod && !!periods?.length) {
      autoSelectPeriod()
    }
  }, [autoSelectPeriod, selectedPeriod, periods])

  const { mutateAsync: deletePeriodMutation, isLoading: isDeletingPeriod } = useMutation(
    (periodUuid: string) => _deleteBookingPeriod(periodUuid, lang),
    {
      onSuccess: async () => {
        // Wait 2 seconds
        await new Promise((resolve) => setTimeout(resolve, 2000))
        // Then refetch periods
        await refetchPeriods()
        // And then autoSelectPeriod
        autoSelectPeriod()
      },
      onError: (err: ViolationError) => {
        let errMsg = 'deletePeriodError'
        if (err?.violations?.length) {
          errMsg = err.violations[0].message
        }
        addToast(t(errMsg), { appearance: 'error' })
      },
    }
  )

  return {
    periods,
    arePeriodsLoading,
    arePeriodsFetching,
    selectedPeriod,
    setSelectedPeriod,
    refetchPeriods,
    deletePeriod: deletePeriodMutation,
    isDeletingPeriod,
  }
}

export default usePeriods
