import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@sweetspot/scramble-ds/atoms'
import {
  EmptyState,
  EmptyStateTitle,
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { cn } from '@sweetspot/scramble-ds/utils'
import { Period, Space, TeeTime, Venue } from '@sweetspot/shared/types'

import { MergedTimeSlot, PrimeTime, TimeSlotCategory } from '../../types'
import EditTimeSlotsForm from './EditTimeSlotsForm'
import { useTimeSlotsEdit } from '../../hooks'
import Loader from './Loader'
import { useToasts } from 'react-toast-notifications'

interface EditTimeSlotsSideBarProps {
  isShown: boolean
  handleClose: () => void
  selectedTeeTimes: TeeTime[]
  selectedFrom: number
  selectedTo: number
  selectedSpaces: Space[]
  currentVenue: Venue
  resetSheet: () => void
  onTimeSlotsUpdate: (data: Period[]) => void
}

const EditTimeSlotsSideBar = ({
  isShown,
  handleClose,
  selectedFrom,
  selectedTo,
  selectedSpaces,
  currentVenue,
  selectedTeeTimes,
  resetSheet,
  onTimeSlotsUpdate,
}: EditTimeSlotsSideBarProps) => {
  const { t } = useTranslation()
  const {
    categories,
    initialFormValues,
    mergeTimeSlots,
    isFetchingCategories,
    primeTimeOptions,
    updateTimeSlots,
  } = useTimeSlotsEdit({
    currentVenue,
    isShown,
    selectedFrom,
    selectedTo,
    selectedSpaces,
  })
  const { addToast } = useToasts()

  const [category, setCategory] = useState<MergedTimeSlot['category']>(initialFormValues.category)
  const [maxPlayers, setMaxPlayers] = useState<MergedTimeSlot['maxPlayers']>(
    initialFormValues.maxPlayers
  )
  const [primeTime, setPrimeTime] = useState<MergedTimeSlot['primeTime']>(
    initialFormValues.primeTime
  )
  // Used to undirtify fields if the user switches back to the initial value
  const [initialValuesReference, setInitialValuesReference] = useState<MergedTimeSlot>()
  const [dirtyFields, setDirtyFields] = useState<string[]>([])
  const [isSaving, setIsSaving] = useState(false)

  // update form values with every tee time selection
  useEffect(() => {
    if (isShown && selectedTeeTimes.length > 0) {
      const mergedTimeSlots = mergeTimeSlots(selectedTeeTimes)

      setCategory(mergedTimeSlots.category)
      setMaxPlayers(mergedTimeSlots.maxPlayers)
      setPrimeTime(mergedTimeSlots.primeTime)
      setInitialValuesReference(mergedTimeSlots)
    }
  }, [selectedTeeTimes, mergeTimeSlots, isShown])

  const canUpdate = useMemo(
    () =>
      dirtyFields.length > 0 && selectedTeeTimes?.length > 0 && !isFetchingCategories && !isSaving,
    [dirtyFields, selectedTeeTimes, isFetchingCategories, isSaving]
  )

  const filteredCategories = useMemo(() => {
    return categories.filter((cat) => cat.id !== category?.value?.id)
  }, [categories, category])

  const filteredPrimeTimes = useMemo(() => {
    return primeTimeOptions.filter((pt) => pt.id !== primeTime?.value?.id)
  }, [primeTimeOptions, primeTime])

  const handleOnUpdate = async () => {
    setIsSaving(true)
    updateTimeSlots({
      category: category?.value?.id,
      primeTime: primeTime?.value?.value,
      maxPlayers: maxPlayers?.value ? parseInt(maxPlayers?.value) : undefined,
    })
      .then((data) => {
        onTimeSlotsUpdate(data)
        resetSheet()
        setDirtyFields([])
      })
      .catch((err) => {
        addToast(t('sentences.failedToSaveChanges'), { appearance: 'error' })
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  const handleOnClose = async () => {
    setDirtyFields([])
    handleClose()
  }

  const dirtify = useCallback(
    <T,>(field: 'category' | 'primeTime' | 'maxPlayers', setter: (value: T) => void, value: T) => {
      setter(value)

      // if user selects No Change,
      // or if the user selects the inital value
      // remove the field from dirtyFields
      const isNoChangeSelected =
        (value as MergedTimeSlot['category'] | MergedTimeSlot['primeTime'])?.value?.id === -1
      const newValue =
        (value as MergedTimeSlot['category'] | MergedTimeSlot['primeTime'])?.value?.id ||
        (value as MergedTimeSlot['maxPlayers'])?.value
      const initialValue =
        (
          initialValuesReference?.[field] as
            | MergedTimeSlot['category']
            | MergedTimeSlot['primeTime']
        )?.value?.id || initialValuesReference?.[field]?.value
      const isInitialValue = initialValue === newValue

      if (isNoChangeSelected || isInitialValue) {
        setDirtyFields((prevDirtyFields) =>
          prevDirtyFields.filter((dirtyField) => dirtyField !== field)
        )
        return
      }
      // if user selects a new value, add the field to dirtyFields
      if (!dirtyFields.includes(field)) {
        setDirtyFields((prevDirtyFields) => [...prevDirtyFields, field])
      }
    },
    [dirtyFields, initialValuesReference]
  )

  const handleSetCategory = useCallback(
    (value: string) => {
      const newCategory = categories.find(
        (category) => category.id === parseInt(value)
      ) as TimeSlotCategory

      return dirtify<MergedTimeSlot['category']>('category', setCategory, {
        isMixed: false,
        value: newCategory,
      })
    },
    [dirtify, categories]
  )

  const handleSetPrimeTime = useCallback(
    (value: string) => {
      const newPrimeTime = primeTimeOptions.find(
        (primeTime) => primeTime.id === parseInt(value)
      ) as PrimeTime

      return dirtify<MergedTimeSlot['primeTime']>('primeTime', setPrimeTime, {
        isMixed: false,
        value: newPrimeTime,
      })
    },
    [dirtify, primeTimeOptions]
  )

  return (
    <Sheet open={isShown} modal={false}>
      <SheetContent className={cn(isSaving && 'overflow-hidden')}>
        {isSaving && <Loader />}
        <SheetHeader>
          <SheetHeaderLeftIcon onClick={handleOnClose}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>{t('teeSheet.editTimeSlots')}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-6">
          {selectedTeeTimes.length === 0 && (
            <EmptyState
              key="no-time-slots"
              iconName="fa-light fa-edit bg-background-mono-lighter mb-4 flex rounded-full p-[22px]"
              className="my-auto max-w-full"
            >
              <EmptyStateTitle>{t('teeSheet.noTimeSlotsText')}</EmptyStateTitle>
            </EmptyState>
          )}
          {selectedTeeTimes.length > 0 && (
            <EditTimeSlotsForm
              category={category.value}
              isCategoryMixed={category.isMixed}
              setCategory={handleSetCategory}
              categoryOptions={filteredCategories}
              maxPlayers={maxPlayers.value}
              isMaxPlayersMixed={maxPlayers.isMixed}
              setMaxPlayers={(value) =>
                dirtify<MergedTimeSlot['maxPlayers']>('maxPlayers', setMaxPlayers, {
                  isMixed: false,
                  value,
                })
              }
              primeTime={primeTime.value}
              isPrimeTimeMixed={primeTime.isMixed}
              setPrimeTime={handleSetPrimeTime}
              primeTimeOptions={filteredPrimeTimes}
            />
          )}
        </SheetCustomContent>
        {selectedTeeTimes.length > 0 && (
          <SheetFooter className="flex flex-col gap-4">
            <Button
              onClick={handleOnUpdate}
              className="min-h-touch-height-lg w-full"
              disabled={!canUpdate}
            >
              {t('update')}
            </Button>
            <Button
              onClick={handleOnClose}
              className="min-h-touch-height-lg w-full"
              variant="clear-dark"
              disabled={isSaving}
            >
              {t('cancel')}
            </Button>
          </SheetFooter>
        )}
      </SheetContent>
    </Sheet>
  )
}

export default EditTimeSlotsSideBar
