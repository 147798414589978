import React from 'react'
import { useAvailabilityAndPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/AvailabilityAndPricingProvider'
import { EmptyState, EmptyStateDescription, EmptyStateTitle } from '@sweetspot/scramble-ds'
import { useTranslation } from 'react-i18next'
import { Button } from '@sweetspot/scramble-ds/atoms'
import SelectedPeriod from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/Period/SelectedPeriod'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faList } from '@fortawesome/pro-regular-svg-icons'

import PeriodTabs from './PeriodTabs'
import SpinnerLoader from '@sweetspot/club-portal-legacy/components/SpinnerLoader/SpinnerLoader'

const Period = () => {
  const { t } = useTranslation()
  const {
    selectedPeriod,
    toggleAllPeriodsSideBar,
    toggleCreatePeriodSideBar,
    isSideBarOpen,
    isDeletingPeriod,
  } = useAvailabilityAndPricingContext()

  if (!selectedPeriod) {
    return (
      <EmptyState
        key={'noSelectedPeriod'}
        iconName="fa-regular fa-calendar-range"
        className="max-w-[372px] px-6"
        iconClassName={
          'mb-lg bg-background-mono-lighter rounded-full p-4 w-[92px] h-[92px] flex justify-center items-center'
        }
      >
        <EmptyStateTitle>{t('sentences.noPeriodSelected')}</EmptyStateTitle>
        <EmptyStateDescription>
          <div className="mb-4 text-center">{t('sentences.noPeriodsSelectedDescription')}</div>
        </EmptyStateDescription>
      </EmptyState>
    )
  }

  return (
    <div className="relative flex h-full flex-1 flex-col">
      {!isSideBarOpen && isDeletingPeriod && (
        <SpinnerLoader className="absolute" text={t('sentences.deletingPeriod')} isOverlay />
      )}
      <div className="flex w-full gap-4">
        <SelectedPeriod />
        <Button
          rounding="rectangular"
          size="small"
          variant="clear-dark"
          onClick={toggleCreatePeriodSideBar}
          className="text-content-sm text-text-dark mt-7 overflow-hidden text-ellipsis whitespace-nowrap pt-0.5 font-medium"
        >
          <FontAwesomeIcon icon={faPlus} />
          {t('words.period')}
        </Button>
        <Button
          rounding="rectangular"
          size="small"
          variant="clear-dark"
          onClick={toggleAllPeriodsSideBar}
          className="text-content-sm text-text-dark mt-7 overflow-hidden text-ellipsis whitespace-nowrap pt-0.5 font-medium"
        >
          <FontAwesomeIcon icon={faList} />
          {t('sentences.allPeriods')}
        </Button>
      </div>
      <PeriodTabs />
    </div>
  )
}

export default Period
