import { TeeTime, Venue } from '@sweetspot/shared/types'

import VenuesSelect from '../VenuesSelect/VenuesSelect'
import RefreshButton from '../RefreshButton/RefreshButton'
import SheetHeaderDateSelect from '@sweetspot/sweetspot-js/common/components/SheetHeaderDateSelect/SheetHeaderDateSelect'
import { FilteredBooking } from '../../types'
import moment from 'moment'
import SheetHeaderSearch from '../SheetHeaderSearch/SheetHeaderSearch'
import { track } from '@amplitude/analytics-browser'
import { AMPLITUDE_EVENTS } from '@sweetspot/shared/util/constants'
import { CourseFilter } from '../CourseFIlter/CourseFilter'
import COURSE_TYPES from '@sweetspot/sweetspot-js/features/courses/constants/courseTypes'
import { CourseFilterType } from '../CourseFIlter/types'
import CourseHeaderSettings from '../CourseHeaderSettings/CourseHeaderSettings'
import { cn } from '@sweetspot/scramble-ds/utils'

interface CourseHeaderProps {
  venues: Venue[]
  currentVenue: Venue
  setVenue: (venue: Venue | undefined) => void
  toggleEditTeeTime: () => void
  onRefreshTeeTimes: () => void
  selectedGolfCourse: Venue
  handleSearchResultClick: (teeTime: TeeTime) => void
  state: {
    currentDate: Date
    venuesIsLoading: boolean
    teeTimes: TeeTime[]
    filters: CourseFilterType
  }
  setFilters: (filters: CourseFilterType) => void
  setDate: () => void
}

const CourseHeader = ({
  venues,
  currentVenue,
  setVenue,
  onRefreshTeeTimes,
  selectedGolfCourse,
  handleSearchResultClick,
  state,
  setFilters,
  setDate,
  toggleEditTeeTime,
}: CourseHeaderProps) => {
  const hasCdhNumber = selectedGolfCourse?.cdh_id
  const { currentDate, venuesIsLoading, teeTimes, filters } = state

  const handleTableRowClick = (booking: FilteredBooking) => {
    if (hasCdhNumber && !booking?.cdhId) return
    if (!booking || !booking.startTime || !booking?.courseUuid) return

    const timestamp = moment.utc(booking.startTime).unix()
    const teeTime = teeTimes.find((time) => moment.utc(time.from).unix() === timestamp)

    if (!teeTime) return
    handleSearchResultClick(teeTime)
  }

  return (
    <div className="flex items-center justify-between py-[26px] pl-6 pr-4">
      <div className="flex items-center gap-6">
        <VenuesSelect
          venues={venues}
          setVenue={setVenue}
          currentVenueName={currentVenue?.name}
          courseType={COURSE_TYPES.COURSE.value}
        />
        <SheetHeaderDateSelect
          currentDate={currentDate}
          handleSetDate={setDate}
          timezone={currentVenue?.timezone}
          onTodayClick={() => track(AMPLITUDE_EVENTS.TODAY_BUTTON_TAPPED)}
        />
      </div>
      <div className="flex items-center gap-2">
        <RefreshButton isLoading={venuesIsLoading} handleRefresh={onRefreshTeeTimes} />
        <div className="hidden md:block">
          <SheetHeaderSearch
            onOptionSelect={handleTableRowClick}
            course={currentVenue}
            currentDate={currentDate}
          />
        </div>
        <CourseFilter filters={filters} setFilters={setFilters} />

        <CourseHeaderSettings
          toggleEditTeeTime={toggleEditTeeTime}
          timezone={currentVenue?.timezone}
          selectedGolfCourse={selectedGolfCourse}
          currentDate={currentDate}
        />
      </div>
    </div>
  )
}

export default CourseHeader
