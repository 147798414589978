import { useMemo, useCallback } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import { isBookingConsideredPaid } from '@sweetspot/sweetspot-js/features/bookings/functions/utils'
import useRoles from '@sweetspot/sweetspot-js/common/hooks/useRoles'
import {
  getFirstMatchingRole,
  hasAccess,
} from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'
import { SpacesAccordion } from '../SpaceAccordion'
import { TotalCalculation } from '../TotalCalcutlation/TotalCalculation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBadgeCheck, faMinusCircle, faTicketPerforated } from '@fortawesome/pro-solid-svg-icons'
import { useMutation } from 'react-query'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { removeMembership } from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'

const SummaryOrderRows = ({
  orderBooking,
  orderBookingRefetch,
  venue,
  extraPlayerState,
  hasOwner,
  isPaid,
  isPartiallyPaid,
  isConfirmed,
  orderBookingSpaces,
}) => {
  const roles = useRoles()
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const accessTable = useMemo(() => {
    const role = getFirstMatchingRole(roles)
    if (!role)
      return {
        PRICE_ADJUSTMENT: false,
      }
    return {
      PRICE_ADJUSTMENT: hasAccess(ACCESS_KEYS.FEATURES.BOOKING.EDIT.PRICE_ADJUSTMENT, role?.value),
    }
  }, [roles])

  const venueTimeZone = useMemo(() => {
    return venue?.timezone || null
  }, [venue])

  const isConsideredPaid = useMemo(() => {
    if (orderBooking) {
      return isBookingConsideredPaid(orderBooking)
    }
    return false
  }, [orderBooking])

  const removeMembershipMutation = useMutation({
    mutationFn: () => removeMembership(orderBooking.uuid),
    onSuccess: async () => {
      addToast(t('editPeriodsNew.updated'), { appearance: 'success' })
      orderBookingRefetch()
    },
    onError: () => {
      addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
    },
  })

  const renderSpace = useCallback(
    (space, isLastSpace) => {
      const items = orderBooking.items.filter(
        (item) => item?.slot?.tee_time?.space?.id === space.id
      )
      return (
        <div className="w-full" key={space.id}>
          <SpacesAccordion
            hasOwner={hasOwner}
            disableAdjustment={isConsideredPaid || !accessTable.PRICE_ADJUSTMENT}
            space={space}
            items={items}
            timezone={venueTimeZone}
            orderBookingRefetch={orderBookingRefetch}
            currencyCode={orderBooking.currency_code}
            isPaid={isPaid || isPartiallyPaid}
            isConfirmed={isConfirmed}
          />
          {!isLastSpace && <hr className={'mt-2.5'} />}
        </div>
      )
    },
    [
      accessTable.PRICE_ADJUSTMENT,
      isConsideredPaid,
      orderBooking.currency_code,
      orderBooking.items,
      orderBookingRefetch,
      venueTimeZone,
    ]
  )

  const userMembership = orderBooking?.items?.[0]?.slot?.membership

  return (
    <>
      {!!userMembership && (
        <div className={cx(styles.container, 'flex flex-row items-center justify-start gap-2')}>
          {userMembership?.type === 'membership' ? (
            <FontAwesomeIcon icon={faBadgeCheck} className="h-4 w-4" />
          ) : (
            <FontAwesomeIcon icon={faTicketPerforated} className="mt-0.5 h-[18px] w-[18px]" />
          )}
          <span className="ml-1 text-sm font-semibold">{userMembership?.name}</span>
          {!(isPaid || isPartiallyPaid) && (
            <FontAwesomeIcon
              icon={faMinusCircle}
              className="ml-auto h-3.5 w-3.5 cursor-pointer"
              onClick={() => removeMembershipMutation.mutate(userMembership?.uuid)}
            />
          )}
        </div>
      )}
      <div className={cx(styles.container)}>
        {orderBookingSpaces.map((space, index) =>
          renderSpace(space, index === orderBooking.spaces.length - 1)
        )}
      </div>
      <TotalCalculation booking={orderBooking} extraPlayerState={extraPlayerState} />
    </>
  )
}

SummaryOrderRows.propTypes = {
  orderBooking: PropTypes.object.isRequired,
  selectedSpaces: PropTypes.array,
  orderBookingRefetch: PropTypes.func,
}

SummaryOrderRows.defaultProps = {
  orderBooking: null,
  selectedSpaces: [],
  orderBookingRefetch: () => {},
}

export default SummaryOrderRows
