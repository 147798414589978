import { useTranslation } from 'react-i18next'

import {
  EmptyStateDescription,
  EmptyState,
  EmptyStateButton,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'
import { CreateAccountHolderSheet } from './CreateAccountHolderSheet'

interface CreateAccountHolderProps {
  fromStore?: boolean
}

export const CreateAccountHolder = ({ fromStore }: CreateAccountHolderProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex w-full items-center justify-center">
      <EmptyState
        className="mb-10 max-w-[480px]"
        iconName={`fa-light fa-money-check-dollar bg-background-mono-lighter mb-4 flex rounded-full p-[22px]`}
      >
        <EmptyStateTitle>
          {fromStore
            ? t('settings.billing.createAccountHolderFromStore')
            : t('settings.billing.createAccountHolder')}
        </EmptyStateTitle>
        <EmptyStateDescription>{t('settings.billing.posStoreRequirement')}</EmptyStateDescription>
        <CreateAccountHolderSheet>
          <EmptyStateButton className="text-content-lg h-touch-height-md font-medium hover:no-underline focus:no-underline">
            {t('settings.billing.createAccountHolder')}
          </EmptyStateButton>
        </CreateAccountHolderSheet>
      </EmptyState>
    </div>
  )
}
