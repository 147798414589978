import { ReactComponent as GuestSlotIcon } from '@sweetspot/sweetspot-js/assets/svgs/guest_slot_asset.svg'
import { ReactComponent as MemberSlotIcon } from '@sweetspot/sweetspot-js/assets/svgs/member_slot_asset.svg'
import { ReactComponent as PartnerSlotIcon } from '@sweetspot/sweetspot-js/assets/svgs/partner_slot_asset.svg'
import { ReactComponent as ReservedSlotIcon } from '@sweetspot/sweetspot-js/assets/svgs/reserved_slot_asset_18.svg'
import { ReactComponent as AnonSlotIcon } from '@sweetspot/sweetspot-js/assets/svgs/anonymous-booked-18x18.svg'
import { PlayerTypeValue, UnbookedSlotValue } from './types'

export const UNBOOKED_SLOT_VALUES: UnbookedSlotValue[] = [
  { value: 1, label: 'oneAvailable' },
  { value: 2, label: 'twoAvailable' },
  { value: 3, label: 'threeAvailable' },
  { value: 4, label: 'fourAvailable' },
]

export const PLAYER_TYPES_VALUES: PlayerTypeValue[] = [
  { value: 'member', icon: MemberSlotIcon },
  { value: 'partner', icon: PartnerSlotIcon },
  { value: 'guest', icon: GuestSlotIcon },
  { value: 'reserved', icon: ReservedSlotIcon },
  { value: 'anonymous', icon: AnonSlotIcon },
]
