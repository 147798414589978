import Header from './Header'
import CreateExtrasSideBar from './CreateExtrasSideBar/CreateExtrasSideBar'
import { ManageExtrasProvider } from './ManageExtrasProvider'
import FilterToolbar from './FilterToolbar/FilterToolbar'
import ExtrasTabsContainer from './ExtrasTabsContainer/ExtrasTabsContainer'

const ManageExtras = () => (
  <ManageExtrasProvider>
    <CreateExtrasSideBar />
    <div className="flex h-full flex-col">
      <Header />
      <FilterToolbar />
      <div className={'flex flex-1 items-center justify-center'}>
        <ExtrasTabsContainer />
      </div>
    </div>
  </ManageExtrasProvider>
)

export default ManageExtras
