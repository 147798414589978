import ExtrasSelector from './ExtrasSelector'

const FilterToolbar = () => {
  return (
    <div className={'flex items-end justify-between px-6'}>
      <div className={'w-[398px]'}>
        <ExtrasSelector />
      </div>
    </div>
  )
}

export default FilterToolbar
