import { useTranslation } from 'react-i18next'
import { Divider } from '@sweetspot/scramble-ds/atoms'

export const Header = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="bg-background-base-clean flex h-[60px] items-center justify-between px-8 py-3">
        <h4 className="itmes-start text-content-lg font-bold"> {t('words.payment_other')}</h4>
      </div>
      <Divider variant="basic" />
    </>
  )
}
