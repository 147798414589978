import { useResourcesStore } from '../../../../hooks/resources/useResourcesStore'
import { SideBarMode } from '../../../../types'
import AboutResources from './AboutResources'

const SideBar = () => {
  const { sideBarMode, isSideBarOpen } = useResourcesStore()

  if (!isSideBarOpen) {
    return null
  }

  if (sideBarMode === SideBarMode.ABOUT_RESOURCES) {
    return <AboutResources />
  }

  return null
}
export default SideBar
