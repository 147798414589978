import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@sweetspot/scramble-ds/atoms'
import {
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { useToasts } from 'react-toast-notifications'

import { useAvailabilityAndPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/AvailabilityAndPricingProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'

import PeriodName from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/PeriodFormComponents/Name'
import PeriodDates from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/PeriodFormComponents/Dates'
import PeriodOpeningHours from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/PeriodFormComponents/OpeningHours'
import BookingDuration from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/CreatePeriod/BookingDuration'
import MaxPlayers from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/PeriodFormComponents/MaxPlayers'
import Categories from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/PeriodFormComponents/Categories'
import validateForm from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/SideBar/PeriodFormComponents/validateForm'
import {
  CreatePeriodPayload,
  PeriodFormError,
  SideBarMode,
} from '@sweetspot/club-portal-legacy/pages/Settings/components/AvailabilityAndPricing/types'
import { _createBookingPeriod } from '@sweetspot/club-portal-legacy/services/bookingPeriodApi'
import { useMutation } from 'react-query'
import { ViolationError } from '@sweetspot/shared/types'
import SpinnerLoader from '@sweetspot/club-portal-legacy/components/SpinnerLoader/SpinnerLoader'

const CreatePeriod = () => {
  const [name, setName] = useState<string>('')
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [startTimeFrom, setStartTimeFrom] = useState<string>('')
  const [startTimeTo, setStartTimeTo] = useState<string>('')
  const [interval, setInterval] = useState<number>()
  const [category, setCategory] = useState<number>()
  const [slots, setSlots] = useState<number>()

  const { addToast } = useToasts()

  const { t } = useTranslation()
  const { isSideBarOpen, toggleCreatePeriodSideBar, currentCourse, refetchPeriods, sideBarMode } =
    useAvailabilityAndPricingContext()

  // reset state
  useEffect(() => {
    if (sideBarMode === SideBarMode.CREATE_PERIOD && isSideBarOpen) {
      setName('')
      setStartDate('')
      setEndDate('')
      setStartTimeFrom('')
      setStartTimeTo('')
      setInterval(undefined)
      setCategory(undefined)
      setSlots(undefined)
    }
  }, [sideBarMode, isSideBarOpen])

  const timezone = currentCourse?.timezone || 'UTC'

  const { mutateAsync: createNewPeriod, isLoading: isCreatingPeriod } = useMutation(
    (payload: CreatePeriodPayload) => _createBookingPeriod(currentCourse?.id, payload),
    {
      onSuccess: () => {
        refetchPeriods()
        toggleCreatePeriodSideBar()
        addToast(t('sentences.periodCreatedSuccessfully'), { appearance: 'success' })
      },
      onError: (error: ViolationError) => {
        let errMsg = 'createPeriodError'
        if (error?.violations?.length) {
          errMsg = error.violations[0].message
        }
        addToast(t(errMsg), { appearance: 'error' })
      },
    }
  )

  const isCreatePeriodDisabled = useMemo(() => {
    return (
      isCreatingPeriod ||
      !name ||
      !startDate ||
      !endDate ||
      !startTimeFrom ||
      !startTimeTo ||
      !interval ||
      !category ||
      !slots
    )
  }, [
    isCreatingPeriod,
    category,
    endDate,
    interval,
    name,
    slots,
    startDate,
    startTimeFrom,
    startTimeTo,
  ])

  const handleCreatePeriod = useCallback(() => {
    if (isCreatePeriodDisabled) return
    const newErrors: PeriodFormError = validateForm({
      name,
      startDate,
      endDate,
      startTimeFrom,
      startTimeTo,
      interval,
    })

    if (Object.keys(newErrors).length > 0) {
      addToast(t(Object.values(newErrors)[0]), { appearance: 'error' })
      return
    }

    const newPeriodPayload: CreatePeriodPayload = {
      name,
      start_date: startDate,
      end_date: endDate,
      start_time_from: startTimeFrom,
      start_time_to: startTimeTo,
      interval: interval as number,
      category_id: category as number,
      slots: slots as number,
      is_golf_id_required: false,
      is_prime_time: false,
    }

    createNewPeriod(newPeriodPayload)
  }, [
    addToast,
    category,
    createNewPeriod,
    endDate,
    interval,
    isCreatePeriodDisabled,
    name,
    slots,
    startDate,
    startTimeFrom,
    startTimeTo,
    t,
  ])

  return (
    <Sheet
      open={sideBarMode === SideBarMode.CREATE_PERIOD && isSideBarOpen}
      onOpenChange={toggleCreatePeriodSideBar}
    >
      <SheetContent>
        {isCreatingPeriod && (
          <SpinnerLoader
            className="fixed left-[calc(100%-384px)]"
            text={t('sentences.creatingPeriod')}
            isOverlay
          />
        )}
        <SheetHeader>
          <SheetHeaderLeftIcon onClick={toggleCreatePeriodSideBar}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>{t('sentences.newPeriod')}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-6 pb-0 pt-2">
          <div className="p-lg border-border-stroke-pale bg-background-mono-lighter flex gap-2  rounded-md border">
            <div className="p-1">
              <FontAwesomeIcon icon={faCircleInfo} className="text-text-dark" />
            </div>
            <p className="text-content-sm font-regular text-text-dark">
              {t('sentences.newPeriodAlert')}
            </p>
          </div>
          <PeriodName
            name={name}
            onChange={setName}
            label={t('sentences.nameOfPeriod')}
            placeholder={t('sentences.nameOfPeriodPlaceholder')}
          />
          <PeriodDates
            startDate={startDate}
            endDate={endDate}
            timezone={timezone}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            tooltipContent={'sentences.periodDatesTooltipDescription'}
          />
          <PeriodOpeningHours
            startTimeFrom={startTimeFrom}
            onStartTimeFromChange={setStartTimeFrom}
            startTimeTo={startTimeTo}
            onStartTimeToChange={setStartTimeTo}
            tooltipContent={'sentences.periodOpeningHoursTooltipDescription'}
          />
          <BookingDuration bookingDuration={interval} onBookingDurationChange={setInterval} />
          <Categories
            category={category}
            onCategoryChange={setCategory}
            tooltipContent={'sentences.periodCategoryTooltipDescription'}
          />
          <MaxPlayers slots={slots} onSlotChange={setSlots} />
        </SheetCustomContent>
        <SheetFooter>
          <Button disabled={isCreatePeriodDisabled} onClick={handleCreatePeriod}>
            {t('words.create')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

export default CreatePeriod
