import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Input,
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputLeadingContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
} from '@sweetspot/scramble-ds'
import { cn } from '@sweetspot/scramble-ds/utils'
import { Check, Divider } from '@sweetspot/scramble-ds/atoms'
import { capitalize } from '@sweetspot/sweetspot-js/common/functions/utils'
import {
  BayScreenDisplay,
  BayScreenMode,
  TrackingTech,
  Stance,
  Allocation,
} from '@sweetspot/shared/types'

import { trackingTechnologies } from '../../constants'
import PopoverHint from './PopoverHint'
import { useBaysContext } from '../../BaysProvider'

type Props = {
  floor?: number
  isFloorMixed?: boolean
  setFloor?: (value: number) => void
  stance?: Stance
  isStanceMixed?: boolean
  setStance?: (value: Stance) => void
  allocation?: Allocation
  isAllocationMixed?: boolean
  setAllocation?: (value: Allocation) => void
  roof?: boolean | null
  isRoofMixed?: boolean
  setRoof?: (value: boolean) => void
  trackingTech?: TrackingTech | null
  isTrackingTechMixed?: boolean
  setTrackingTech?: (value: TrackingTech) => void
  showDisplay?: boolean
  display?: BayScreenDisplay | null
  isDisplayMixed?: boolean
  setDisplay?: (value: BayScreenDisplay) => void
  showScreenMode?: boolean
  screenMode?: BayScreenMode | null
  isScreenModeMixed?: boolean
  setScreenMode?: (value: BayScreenMode) => void
  siteId?: string
  setSiteId?: (value: string) => void
  trmsNumbers?: { [key: number]: string | null | undefined }
  setTrmsNumbers?: (value: string, bayNumber: number) => void
}

const DetailsForm = ({
  floor,
  isFloorMixed,
  setFloor,
  stance,
  isStanceMixed,
  setStance,
  allocation,
  isAllocationMixed,
  setAllocation,
  roof,
  isRoofMixed,
  setRoof,
  trackingTech,
  isTrackingTechMixed,
  setTrackingTech,
  showDisplay,
  display,
  isDisplayMixed,
  setDisplay,
  showScreenMode,
  screenMode,
  isScreenModeMixed,
  setScreenMode,
  siteId,
  setSiteId,
  trmsNumbers,
  setTrmsNumbers,
}: Props) => {
  const { t } = useTranslation()
  const { getAllocationLabel } = useBaysContext()

  const displays = useMemo(
    () => [
      {
        value: BayScreenDisplay.TOPTRACER_SCREEN,
        label: t('settings.bays.toptracerScreen'),
      },
      {
        value: BayScreenDisplay.PLAYER_DEVICE,
        label: t('settings.bays.playerDevice'),
      },
    ],
    [t]
  )

  const handleOnFloorFocus = useCallback(() => {
    if (isFloorMixed) {
      setFloor?.(0)
    }
  }, [isFloorMixed, setFloor])

  const handleSetFloor = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFloor?.(Math.min(+event.target.value || 0, 999))
    },
    [setFloor]
  )

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-row gap-4">
        <InputBase>
          <InputLabelContainer>{t('words.floor')}</InputLabelContainer>
          <InputContainer>
            {isFloorMixed && (
              <InputLeadingContainer>
                <i className="fa-regular fa-arrows-cross text-text-warning" />
              </InputLeadingContainer>
            )}
            <Input
              value={isFloorMixed ? t('words.mixed') : floor}
              onChange={handleSetFloor}
              onFocus={handleOnFloorFocus}
              className={cn(
                'pr-md border-border-stroke-clean text-content-base h-8 w-full border-[1px]',
                isFloorMixed ? 'pl-11' : 'pl-2'
              )}
              placeholder={t('settings.bays.floorPlaceholder')}
            />
          </InputContainer>
        </InputBase>
        <InputBase>
          <InputLabelContainer>{t('settings.bays.stance')}</InputLabelContainer>
          <InputSelect value={stance} onValueChange={setStance}>
            <InputSelectTrigger asChild>
              <InputContainer>
                {isStanceMixed && (
                  <InputLeadingContainer>
                    <i className="fa-regular fa-arrows-cross text-text-warning" />
                  </InputLeadingContainer>
                )}
                <InputSelectStatefulInput
                  className={cn('text-content-base h-8 border-[1px] py-1', {
                    'pl-11': isStanceMixed,
                  })}
                  value={isStanceMixed ? t('words.mixed') : stance && t(`settings.bays.${stance}`)}
                  placeholder={t('words.select')}
                />
                <InputSelectTrailingContainer className="right-2" />
              </InputContainer>
            </InputSelectTrigger>
            <InputSelectContent>
              <InputSelectContentView>
                {Object.values(Stance)?.map((option) => (
                  <InputSelectItem key={option} value={option}>
                    <ListItem className="ml-2 h-full justify-center gap-2">
                      <ListItemMainContent className="justify-center">
                        <ListItemParagraph className="text-content-base">
                          {t(`settings.bays.${option}`)}
                        </ListItemParagraph>
                      </ListItemMainContent>
                    </ListItem>
                  </InputSelectItem>
                ))}
              </InputSelectContentView>
            </InputSelectContent>
          </InputSelect>
        </InputBase>
      </div>

      <InputBase className="w-[160px]">
        <InputLabelContainer>{t('settings.bays.allocation')}</InputLabelContainer>
        <InputSelect value={allocation} onValueChange={setAllocation}>
          <InputSelectTrigger asChild>
            <InputContainer>
              {isAllocationMixed && (
                <InputLeadingContainer>
                  <i className="fa-regular fa-arrows-cross text-text-warning" />
                </InputLeadingContainer>
              )}
              <InputSelectStatefulInput
                className={cn('text-content-base h-8 border-[1px] py-1', {
                  'pl-11': isAllocationMixed,
                })}
                value={
                  isAllocationMixed
                    ? t('words.mixed')
                    : allocation && getAllocationLabel(allocation)
                }
                placeholder={t('words.select')}
              />
              <InputSelectTrailingContainer className="right-2" />
            </InputContainer>
          </InputSelectTrigger>
          <InputSelectContent>
            <InputSelectContentView>
              {Object.values(Allocation)?.map((option) => (
                <InputSelectItem key={option} value={option}>
                  <ListItem className="ml-2 h-full justify-center gap-2">
                    <ListItemMainContent className="justify-center">
                      <ListItemParagraph className="text-content-base">
                        {getAllocationLabel(option)}
                      </ListItemParagraph>
                    </ListItemMainContent>
                  </ListItem>
                </InputSelectItem>
              ))}
            </InputSelectContentView>
          </InputSelectContent>
        </InputSelect>
      </InputBase>

      <div className="bg-background-base-neutral w-full rounded-md px-4 py-3">
        <div className="text-content-sm font-bold">{t('words.amenities')}</div>
        <Check
          onCheckedChange={setRoof}
          id={'roof'}
          label={t('settings.bays.roof')}
          checked={roof || false}
          className={cn('h-auto px-0 pt-2', { '[&_svg]:text-text-warning': isRoofMixed })}
          indeterminate={isRoofMixed}
        />
      </div>

      <Divider />

      <div className="bg-background-base-neutral flex w-full flex-col gap-4 rounded-md px-2 py-3">
        <div className="text-text-dark text-content-base ml-2 font-bold">
          {t('settings.bays.ballTrackingTechnology')}
        </div>
        <InputSelect onValueChange={setTrackingTech} value={trackingTech as string}>
          <InputBase>
            <InputLabelContainer>{t('settings.bays.trackingProvider')}</InputLabelContainer>
            <InputSelectTrigger asChild>
              <InputContainer>
                {isTrackingTechMixed && (
                  <InputLeadingContainer>
                    <i className="fa-regular fa-arrows-cross text-text-warning" />
                  </InputLeadingContainer>
                )}
                <InputSelectStatefulInput
                  className={cn('text-content-base h-8 border-[1px]', {
                    'pl-12': isTrackingTechMixed,
                  })}
                  value={
                    isTrackingTechMixed
                      ? t('words.mixed')
                      : trackingTech
                      ? capitalize(trackingTech)
                      : ''
                  }
                />
                <InputSelectTrailingContainer />
              </InputContainer>
            </InputSelectTrigger>
          </InputBase>

          <InputSelectContent>
            <InputSelectContentView>
              {trackingTechnologies.map((trackingTech) => (
                <InputSelectItem key={trackingTech} value={trackingTech}>
                  <ListItem className="ml-2 h-full justify-center gap-2">
                    <ListItemMainContent className="justify-center">
                      <ListItemParagraph className="text-content-base">
                        {capitalize(trackingTech)}
                      </ListItemParagraph>
                    </ListItemMainContent>
                  </ListItem>
                </InputSelectItem>
              ))}
            </InputSelectContentView>
          </InputSelectContent>
        </InputSelect>

        {showDisplay && (
          <>
            <InputSelect onValueChange={setDisplay} value={display || undefined}>
              <InputBase>
                <InputLabelContainer>{t('settings.bays.display')}</InputLabelContainer>
                <div className="flex w-full gap-2">
                  <InputSelectTrigger asChild>
                    <InputContainer>
                      {isDisplayMixed && (
                        <InputLeadingContainer>
                          <i className="fa-regular fa-arrows-cross text-text-warning" />
                        </InputLeadingContainer>
                      )}
                      <InputSelectStatefulInput
                        className={cn('text-content-base h-8 border-[1px]', {
                          'pl-12': isDisplayMixed,
                        })}
                        value={
                          isDisplayMixed
                            ? t('words.mixed')
                            : displays.find(({ value }) => value === display)?.label
                        }
                        placeholder={t('words.select')}
                      />
                      <InputSelectTrailingContainer />
                    </InputContainer>
                  </InputSelectTrigger>
                  <PopoverHint i18nKey={'settings.bays.displayHint'} />
                </div>
              </InputBase>

              <InputSelectContent>
                <InputSelectContentView>
                  {displays.map(({ value, label }) => (
                    <InputSelectItem key={value} value={value}>
                      <ListItem className="ml-2 h-full justify-center gap-2">
                        <ListItemMainContent className="justify-center">
                          <ListItemParagraph className="text-content-base">
                            {label}
                          </ListItemParagraph>
                        </ListItemMainContent>
                      </ListItem>
                    </InputSelectItem>
                  ))}
                </InputSelectContentView>
              </InputSelectContent>
            </InputSelect>

            {showScreenMode && (
              <>
                <InputSelect onValueChange={setScreenMode} value={screenMode as string}>
                  <InputBase>
                    <InputLabelContainer>{t('settings.bays.screenMode')}</InputLabelContainer>
                    <div className="flex w-full gap-2">
                      <InputSelectTrigger asChild>
                        <InputContainer>
                          {isScreenModeMixed && (
                            <InputLeadingContainer>
                              <i className="fa-regular fa-arrows-cross text-text-warning" />
                            </InputLeadingContainer>
                          )}
                          <InputSelectStatefulInput
                            className={cn('text-content-base h-8 border-[1px]', {
                              'pl-12': isScreenModeMixed,
                            })}
                            value={
                              isScreenModeMixed
                                ? t('words.mixed')
                                : screenMode === BayScreenMode.OPEN
                                ? t('words.unlocked')
                                : screenMode === BayScreenMode.LOCKED
                                ? t('words.locked')
                                : ''
                            }
                            placeholder={t('words.select')}
                          />
                          <InputSelectTrailingContainer />
                        </InputContainer>
                      </InputSelectTrigger>
                      <PopoverHint i18nKey={'settings.bays.screenModeHint'} />
                    </div>
                  </InputBase>

                  <InputSelectContent>
                    <InputSelectContentView>
                      {Object.values(BayScreenMode).map((mode) => (
                        <InputSelectItem key={mode} value={mode}>
                          <ListItem className="ml-2 h-full justify-center gap-2">
                            <ListItemMainContent className="justify-center">
                              <ListItemParagraph className="text-content-base">
                                {mode === BayScreenMode.OPEN
                                  ? t('words.unlocked')
                                  : t('words.locked')}
                              </ListItemParagraph>
                            </ListItemMainContent>
                          </ListItem>
                        </InputSelectItem>
                      ))}
                    </InputSelectContentView>
                  </InputSelectContent>
                </InputSelect>
                <div className="flex flex-col gap-2">
                  <div className="text-text-dark text-content-sm ml-2 font-bold">
                    {t('settings.bays.apiDetails')}
                  </div>

                  <InputBase>
                    <InputLabelContainer className="mb-0.5">
                      {t('settings.bays.siteId')}
                    </InputLabelContainer>
                    <div className="flex w-full gap-2">
                      <InputContainer>
                        <InputLeadingContainer className="left-2">
                          <i className="fa-regular fa-link" />
                        </InputLeadingContainer>
                        <Input
                          value={siteId}
                          onChange={(event) => setSiteId?.(event.target.value)}
                          className="text-content-base h-8 border pl-10"
                          placeholder={t('settings.bays.siteIdPlaceholder')}
                        />
                      </InputContainer>
                      <PopoverHint i18nKey={'settings.bays.siteIdHint'} />
                    </div>
                  </InputBase>
                </div>

                <Divider />

                {Object.entries(trmsNumbers || {}).map(([bayNumber, trmsNumber]) => (
                  <InputBase>
                    <InputLabelContainer className="mb-0.5">
                      {`${t('settings.bays.bay')} ${bayNumber} - ${t(
                        'settings.bays.trmsBayNumber'
                      )}`}
                    </InputLabelContainer>
                    <div className="flex w-full gap-2">
                      <InputContainer>
                        <InputLeadingContainer className="left-2">
                          <i className="fa-regular fa-link" />
                        </InputLeadingContainer>
                        <Input
                          value={trmsNumber as string}
                          onChange={(event) =>
                            setTrmsNumbers?.(event.target.value, parseInt(bayNumber))
                          }
                          className="text-content-base h-8 border pl-10"
                          placeholder={t('sentences.typeValue')}
                        />
                      </InputContainer>
                      <PopoverHint i18nKey={'settings.bays.trmsBayNumberHint'} />
                    </div>
                  </InputBase>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DetailsForm
