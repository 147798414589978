import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DataTableColumnHeader } from '@sweetspot/scramble-ds'
import { Tooltip } from '@sweetspot/scramble-ds/atoms'
import { ColumnDef } from '@tanstack/react-table'
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons'
import { BalanceAccountData } from '../../types'

export const columns = (t: (key: string) => string): ColumnDef<BalanceAccountData>[] => {
  return [
    {
      accessorKey: 'name',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('name') },
      cell: ({ row }) => row.getValue('name'),
    },
    {
      accessorKey: 'payout_frequency',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('sentences.payoutFrequency') },
      cell: ({ row }) => t(row.getValue('payout_frequency')),
      enableSorting: false,
    },
    {
      accessorKey: 'next_payment_due',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('sentences.nextPaymentDue') },
      cell: ({ row }) => {
        if (!row.getValue('next_payment_due')) {
          return (
            <Tooltip.Provider delayDuration={0}>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <FontAwesomeIcon
                    data-tooltip-id="grid-tooltip"
                    className="text-text-danger"
                    icon={faCircleExclamation}
                    size="lg"
                  />
                </Tooltip.Trigger>
                <Tooltip.Content
                  align="center"
                  side="top"
                  className="ounded-sm bg-black p-2 text-white"
                >
                  <Tooltip.Arrow />
                  {t('sentences.payoutCantBeDone')}
                </Tooltip.Content>
              </Tooltip.Root>
            </Tooltip.Provider>
          )
        }

        return t(row.getValue('next_payment_due'))
      },
      enableSorting: false,
    },
    {
      accessorKey: 'trigger_amount',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('sentences.triggerAmount') },
      cell: ({ row }) => t(row.getValue('trigger_amount')),
      enableSorting: false,
    },
    {
      accessorKey: 'min_amount',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('sentences.minAmount') },
      cell: ({ row }) => t(row.getValue('min_amount')),
      enableSorting: false,
    },
    {
      accessorKey: 'total_amount',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      meta: { title: t('sentences.balance') },
      cell: ({ row }) => t(row.getValue('total_amount')),
      enableSorting: false,
    },
  ]
}
