import { useEffect, useState } from 'react'
import { TimezoneInfo } from '@sweetspot/club-portal-legacy/components/TimezoneInfo'
import { GolfCourseItem } from '@sweetspot/club-portal-legacy/store/types'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'

import style from './style.module.scss'

interface GolfCourseSelectProps {
  courses: GolfCourseItem[]
  selectedCourse: GolfCourseItem
  onSelectCourse: (id: string) => void
}

const GolfCourseSelect = ({ courses, selectedCourse, onSelectCourse }: GolfCourseSelectProps) => {
  const [course, setCourse] = useState<GolfCourseItem | null>(null)
  const [courseNameLength, setCourseNameLength] = useState<number>(0)

  useEffect(() => {
    if (selectedCourse) {
      setCourse(selectedCourse)
      setCourseNameLength(selectedCourse.name?.length)
    }
  }, [selectedCourse])

  if (!courses || courses.length === 0) return null

  return (
    <div className={style.container}>
      <div className={style.nameContainer}>
        <div className={style.courseText}>
          <div className={style.textContainer}>
            <DropdownSelect
              hideArrow
              values={courses}
              initialId={course?.id}
              onSelect={(id) => onSelectCourse(id)}
              width={courseNameLength ? 100 + courseNameLength * 11 : 300}
              largeText
            />
          </div>

          <TimezoneInfo timezone={selectedCourse?.timezone ?? 'Europe/Stockholm'} />
        </div>
      </div>
    </div>
  )
}

export default GolfCourseSelect
